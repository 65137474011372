import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import Filter from '~/src/common/components/Filter';
import { Stroke } from '~/src/types/Types';
import jsonStrokes from '../../../config/strokes.json';
import MyContext from '../../../services/context';
import RedDot from '~/src/common/components/RedDot';

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
const cardWidth = 200;
const cardHeight = 140;
const cardMargin = 5;
const transitionTime = 500;
const maxCardNumber = 800;

export default function StrokePlain() {
  const context = useContext(MyContext);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [currentCardCount, setCardCount] = useState(0);
  const [sceneWidth, setSceneWidth] = useState(0);
  const [sceneHeight, setSceneHeight] = useState(0);
  const [allCards, setAllCards] = useState<Stroke[]>([]);
  const [activeCards, setActiveCards] = useState<Stroke[]>([]);
  const [allCardIds, setAllCardIds] = useState();
  const [activeCardIds, setActiveCardIds] = useState<number[]>([]);
  const [availableIds, setAvailableIds] = useState<number[]>([
    ...Array(maxCardNumber).keys(),
  ]);

  const createCards = async (countToCreate: number) => {
    const toCutIds = availableIds;
    const listIds = [];
    for (let i = 0; i < countToCreate; i++) {
      const random = Math.floor(Math.random() * toCutIds.length);
      const selected = toCutIds.splice(random, 1)[0];

      // const newCard = card.cloneNode(true);
      // scene.appendChild(newCard);
      // newCard.id = 'card' + i;
      // newCard.addEventListener("mouseenter", function (e) {
      //   newCard.classList.add("lifted");
      //   newCard.style.zIndex = "150";
      //   let child = newCard.querySelector(".card__face");
      //   child.textContent = "150";
      // });
      // newCard.addEventListener("mouseleave", function (e) {
      //   let child = newCard.querySelector(".card__face");
      //   child.textContent = "130";
      //   newCard.classList.remove("lifted");
      //   newCard.style.zIndex = "130";
      //   const st = setTimeout(function () {
      //     clearTimeout(st);
      //     newCard.style.zIndex = "100";
      //     let child = newCard.querySelector(".card__face");
      //     child.textContent = "100";
      //   }, transitionTime);
      // });
      // newCard.addEventListener("click", function (e) {
      //   const t = e.currentTarget;
      //   let child = t.querySelector(".card__face");
      //   child.textContent = totalCount;
      // });
      // allCards.push(newCard);
      listIds.push(selected);
    }

    const activeIds = activeCardIds.concat(listIds);
    setActiveCardIds(activeIds);
    setAvailableIds(availableIds);
    // const activeStrokes = allCards.filter(
    //   (x) => activeIds.indexOf(x.order_number) != -1,
    // );
    let activeStrokes = [];
    activeIds.forEach((id) => {
      const card = allCards.find((x) => x.order_number === id);
      activeStrokes.push(card);
    });
    setActiveCards(activeStrokes);
  };

  const resizeHandler = (w: number, h: number) => {
    const cardCountWidth = Math.floor(w / (cardWidth + cardMargin)) + 8;
    const cardCountHeight = Math.floor(h / (cardHeight + cardMargin)) + 8;

    const sceneWidthLocal =
      (cardWidth + cardMargin * 2) * cardCountWidth + cardMargin;
    setSceneWidth(sceneWidthLocal);
    const sceneHeightLocal =
      (cardHeight + cardMargin * 2) * cardCountHeight + cardMargin;
    setSceneHeight(sceneHeightLocal);
    const maxCountForAreaSurface = cardCountWidth * cardCountHeight;
    setCardCount(maxCountForAreaSurface);
    const countDiff = maxCountForAreaSurface - currentCardCount;
    const countToCreate = Math.max(0, countDiff);
    const countToDelete = countDiff < 0 ? Math.abs(countDiff) : 0;
    createCards(countToCreate);
  };

  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      resizeHandler(window.innerWidth, window.innerHeight);
    }, transitionTime);

    window.addEventListener('resize', debouncedHandleResize);

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  const getAllStrokes = async () => {
    // const q = query(
    //   collection(db, 'strokes'),
    //   where('order_number', '<', maxCardNumber),
    // );
    // const pigmSS = (await getDocs(q)) as QuerySnapshot<Stroke>;
    // const strokesL = fbDocs2objs(pigmSS);

    setAllCards(jsonStrokes);
    const ids = jsonStrokes.map((x) => x.order_number);
    const lessThan800 = ids.filter((x) => x <= 800);
    setAvailableIds(lessThan800);
  };

  useEffect(() => {
    getAllStrokes();
  }, []);

  useEffect(() => {
    if (allCards.length > 0) {
      resizeHandler(dimensions.width, dimensions.height);
    }
  }, [allCards]);

  // const deleteCards = (countToDelete) => {
  //   for (let i = countToDelete; i > 0; i--) {
  //     const toRemoveCard = allCards.at(-1);
  //     if (toRemoveCard) {
  //       toRemoveCard.remove();
  //     }
  //   }
  // };

  let url = 'http://streaming.tdiradio.com:8000/house.mp3';
  let audio = new Audio(url);
  const handlePlay = () => {
    // audio.play();
  };

  const handlePause = () => {
    audio.pause();
  };

  const mouseEnterHandler = (e) => {
    e.currentTarget.classList.add('lifted');
    e.currentTarget.style.zIndex = '150';
    // handlePlay();
  };

  const mouseLeaveHandler = (e) => {
    const newCard = e.currentTarget;
    newCard.classList.remove('lifted');
    newCard.style.zIndex = '130';
    const st = setTimeout(function () {
      clearTimeout(st);
      newCard.style.zIndex = '100';
    }, transitionTime);
    // handlePause();
  };

  return (
    <Wrapper
      cardWidth={cardWidth}
      cardHeight={cardHeight}
      cardMargin={cardMargin}
      sceneWidth={sceneWidth}
      windowWidth={dimensions.width}
      style={{ display: context.showStrokePlain ? 'flex' : 'none' }}
    >
      <>
        <Scene sceneWidth={sceneWidth} sceneHeight={sceneHeight}>
          {activeCards.map((card) => (
            <Card
              key={card.id + Math.random() * 100000}
              width={cardWidth}
              height={cardHeight}
              margin={cardMargin}
              transition={transitionTime}
              onMouseEnter={mouseEnterHandler}
              onMouseLeave={mouseLeaveHandler}
            >
              <FrontFace to={`/stroke/${card.order_number}`}>
                <img
                  src={card?.photos?.thumb_600x600?.download_url}
                  // src={`https://via.placeholder.com/${cardWidth}x${cardHeight}`}
                  style={{
                    width: cardWidth,
                    height: cardHeight,
                    margin: 0,
                    padding: 0,
                  }}
                />
                {card?.purchase?.status === 'bought' && (
                  <RedDotWrapper>
                    <RedDot size="16px" color="red" />
                  </RedDotWrapper>
                )}
                {card?.purchase?.status === 'reserved' && (
                  <RedDotWrapper>
                    <RedDot size="16px" color="orange" />
                  </RedDotWrapper>
                )}
              </FrontFace>
              <BackFace to="http://hello.com">back</BackFace>
            </Card>
          ))}
        </Scene>
        <Filter />
      </>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // border: 3px solid green;
  overflow: hidden;
  margin: 0;
  padding: 0;
  z-index: 0;
`;

interface SceneProps {
  sceneWidth: number;
  sceneHeight: number;
}
const Scene = styled.div<SceneProps>`
  width: ${(props) => props.sceneWidth}px;
  height:${(props) => props.sceneHeight}px;
  margin: 0;
  margin-top: -${350}px;

  // Perspective shit
  perspective: 600px;
  transform: perspective(${(props) => props.sceneWidth + 100}px) rotateX(30deg);
  // transform: rotateX(30deg);
  overflow: hidden;

  // Children
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content center;
  
  // centering to parent
  position: relative;
  position: absolute;
  left: 50%;
  margin-left: -${(props) => props.sceneWidth / 2}px; 

  // debugging:
  // background-color: pink;
  border: 3px solid red;
`;

interface CardProps {
  width: number;
  height: number;
  margin: number;
  transition: number;
}
const Card = styled.div<CardProps>`
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin: ${(props) => props.margin}px;
  cursor: pointer;
  transform-style: preserve-3d;
  transform-origin: center right;
  transition: transform ${(props) => props.transition / 1000}s;
  -webkit-box-shadow: 5px 6px 3px -1px rgba(0, 0, 0, 0.37);
  box-shadow: 5px 6px 3px -1px rgba(0, 0, 0, 0.37);
  z-index: 100;
  // border: 2px solid black;
  display: block;
  background-color: black;
`;

const FrontFace = styled(Link)`
  position: absolute;
  // width: 100%;
  // height: 100%;
  // line-height: 70px;
  color: white;
  text-align: center;
  // font-weight: bold;
  // font-size: 40px;
  backface-visibility: hidden;
  display: block;
  // background-color: red;
`;

const BackFace = styled(FrontFace)`
  background: blue;
  transform: rotateY(180deg);
`;

const RedDotWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
`;
