import React, {useState} from 'react';
import styled from 'styled-components';
import styles from '../styles';
import {ButtonGroup, Button, Col, Row} from 'react-bootstrap';

const FilterColors = (props) => {
    const firstPartColors = ['#FFFFFF', '#a0a0a0', '#000000', '#d60119', '#f68307'];
    const secondPartcColors = ['#F9C508', '#47AC0B', '#009DED', '#1C09FF', '#7707FF'];

  return (
<div>
<ButtonGroup style={{flexWrap: 'wrap'}} aria-label="Basic example">
{firstPartColors.map(color => (
    <Button style={{padding: 0, marginBottom: 5, backgroundColor: color, marginLeft: 5, width: 20, height: 20, borderRadius: '50%', border: 'none'}} variant="secondary" />
))}
{secondPartcColors.map(color => (
    <Button style={{backgroundColor: color, marginLeft: 5, width: 20, height: 20, borderRadius: '50%', padding: 0, border: 'none'}} variant="secondary" />
))}
</ButtonGroup>
<div>  
<Row className='mt-3'>
<Col sm={1}>
  #
</Col>
<Col sm={9} className='px-0'>
  <ColorInput />
</Col>
</Row></div>
</div>
  );
};

const ColorInput = styled.input`
background-color: ${styles.colors.brandGrayDark};
border: 1px solid #585858;
width: 100%
`;


export default FilterColors;
