import React from 'react';
import styled from 'styled-components';
import ButtonComponent from './forms/ButtonComponent';

interface PropTypes {
  Heading?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  title: string;
  onLeftHandler?: () => void;
  onRightHandler?: () => void;
  titleLeft?: string;
  titleRight?: string;
  btnSizeLeft?: string;
  btnSizeRight?: string;
  disabledRight?: boolean;
}

export default function TitleComponent({
  Heading = 'h1',
  title,
  onLeftHandler,
  onRightHandler,
  titleLeft,
  titleRight,
  btnSizeLeft,
  btnSizeRight,
  disabledRight,
}: PropTypes): JSX.Element {
  return (
    <Heading>
      {onLeftHandler && (
        <StyledButtonLeft>
          <ButtonComponent
            size={btnSizeLeft}
            onClick={onLeftHandler}
            title={titleLeft}
          />
        </StyledButtonLeft>
      )}
      {onRightHandler && (
        <StyledButtonRight>
          <ButtonComponent
            size={btnSizeRight}
            onClick={onRightHandler}
            title={titleRight}
            disabled={disabledRight}
          />
        </StyledButtonRight>
      )}
      <StyledSpan>{title}</StyledSpan>
    </Heading>
  );
}

const StyledSpan = styled.span`
  white-space: pre-line;
`;

const StyledButtonLeft = styled.div`
  & button {
    float: left;
  }
`;

const StyledButtonRight = styled.div`
  & button {
    float: right;
  }
`;
