import React from 'react';
import styled from 'styled-components';
import styles from '../../../common/styles';
import DataItemCouple from './DataItemCouple';

import brush from '../../../static/paint_pic.png';
import {
  PAINT_TYPES,
  QUALITY_TYPES,
  OPACITY_TYPES,
} from '../../../common/constants';

import { Paint } from '../../../types/Types';

interface PaintRow {
  paint: Paint;
}

const PaintRow = ({ paint }: PaintRow) => {
  let pigmentString = '--';
  if (paint.pigments != null && paint.pigments.length > 0) {
    pigmentString = '';
    for (const pigmentPaint of paint.pigments) {
      pigmentString += pigmentPaint.name + ', ';
    }
    pigmentString = pigmentString.substring(0, pigmentString.length - 2);
  }

  return (
    <Wrapper>
      {paint && (
        <>
          <ImageWrapper>
            <Image src={brush} style={{ marginRight: 5 }} />
          </ImageWrapper>
          <DataWrapper>
            <DataColumn>
              <DataItemCouple label="Type" info={paint.paint_type?.name} />
              <DataItemCouple
                hexCode={paint?.color?.hex}
                label="Color"
                info={paint?.color?.name}
              />
              <DataItemCouple label="Name" info={paint.name} />
            </DataColumn>
            <DataColumn>
              <DataItemCouple label="Brand" info={paint?.brand?.name} />
              <DataItemCouple label="Model" info={paint?.brand_model?.name} />
              <DataItemCouple label="RefPaint" info={paint?.ref} />
              <DataItemCouple
                label="RefBrandColor"
                info={
                  paint?.ref_brand_color === null ? '--' : paint.ref_brand_color
                }
              />
            </DataColumn>
            <DataColumn>
              <DataItemCouple label="Granulating" info={paint.granulating} />
              <DataItemCouple label="Staining" info={paint.staining} />
              <DataItemCouple
                label="Opacity"
                info={`
            ${paint.opacity_number === null ? '--' : paint.opacity_number}
            ${paint.opacity_type ? paint.opacity_type.name : ''} `}
              />
              <DataItemCouple label="Pigments" info={pigmentString} />
            </DataColumn>
            <DataColumn>
              <DataItemCouple label="Quality" info={paint?.quality} />
              <DataItemCouple
                label="Price"
                info={paint.price === null ? '--' : `$${paint.price}`}
              />
              <DataItemCouple
                label="Shop"
                info={paint.store === null ? '--' : paint?.store?.url}
              />
            </DataColumn>
          </DataWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default PaintRow;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media ${styles.device.laptop} {
    flex-direction: row;
    height: 300px;
    padding-bottom: 15px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  @media ${styles.device.laptop} {
    width: 477px;
  }
`;

const Image = styled.img`
  width: 100%;
  @media ${styles.device.laptop} {
    width: 477px;
  }
`;

const DataWrapper = styled.div`
  background-color: ${styles.colors.brandGrayLight};
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: left;
  padding-left: 20px;
  width: 100%;

  @media ${styles.device.laptop} {
    flex-direction: row;
    border-left: 3px ${styles.colors.white} solid;
  }
`;

const DataColumn = styled.div`
  flex-grow: 1;
  padding-left: 20px;

  @media ${styles.device.laptop} {
    border-right: 1px ${styles.colors.lightGray} solid;
  }
`;
