import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import styles from '../../styles';

import Loader from '../Loader';

interface Props {
  size?: 'sm' | 'lg';
  onClick?: () => void;
  title?: string;
  isSubmitting?: boolean;
  active?: boolean;
  disabled?: boolean;
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'link';
  type?: 'button' | 'reset' | 'submit';
  inline?: boolean;
}

export default function ButtonComponent({
  size,
  onClick,
  title,
  isSubmitting = false,
  active = false,
  disabled = false,
  variant = 'secondary',
  type = 'button',
  inline = false,
}: Props): JSX.Element {
  return (
    <StyledButton
      className={`ak-bg-btn ${inline === true ? 'mb-2' : ''}`}
      disabled={disabled || isSubmitting}
      variant={variant}
      type={type}
      active={active}
      size={size}
      onClick={onClick}
    >
      {isSubmitting ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        title
      )}
    </StyledButton>
  );
}

const StyledButton = styled(Button)`
  &.btn-secondary {
    background: ${styles.colors.lightBlue};
    &:hover,
    &:active {
      background-color: ${styles.colors.lightBlueHover};
      border-color: ${styles.colors.lightBlueHover};
    }
  }
`;
