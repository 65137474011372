import React from 'react';
import styled from 'styled-components';
import WhiteButton from './WhiteButton';
import styles from '../styles';
import { Row, Col } from 'react-bootstrap';
import BrandBox from './BrandBox';
import fbLogo from '../../static/fb.png';
import instagramLogo from '../../static/instagram.png';
import logo from '../../static/logo-white.svg';
import { Link, useNavigate } from 'react-router-dom';

export default function Footer() {
  const year = new Date().getFullYear();
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Wrapper>
      <div
        style={{
          maxWidth: 1800,
          display: 'block',
          margin: '0 auto',
          overflow: 'hidden',
        }}
      >
        <Row style={{ paddingRight: 70, paddingLeft: 70 }}>
          <LogoColumn onClick={scrollToTop} md={12} lg={3}>
            <Logo src={logo} />
          </LogoColumn>
          <InfoColumn md={12} lg={3}>
            <FooterLink
              onClick={scrollToTop}
              style={{ color: 'white' }}
              to="/paper-collection"
            >
              Paper Collection
            </FooterLink>
            <FooterLink
              onClick={scrollToTop}
              style={{ color: 'white' }}
              to="/experimental-projects"
            >
              Experimental Projects
            </FooterLink>
          </InfoColumn>
          <PrivacyColumn md={12} lg={3}>
            <FooterLink
              onClick={scrollToTop}
              style={{ color: 'white' }}
              to="/about"
            >
              About
            </FooterLink>
            <FooterLink
              onClick={scrollToTop}
              style={{ color: 'white' }}
              to="/get-involved"
            >
              Get Involved
            </FooterLink>
          </PrivacyColumn>
          <SubscribeColumn md={12} lg={3}>
            <Row>
              <Col>
                <WhiteText>Subscribe to our newsletter</WhiteText>
              </Col>
            </Row>
            <Row>
              <Col>
                <WhiteButton
                  onClick={() => {
                    navigate('/subscribe');
                  }}
                  text="Subscribe"
                />
              </Col>
            </Row>
          </SubscribeColumn>
        </Row>
        <Row style={{ paddingRight: 70, paddingLeft: 70, overflow: 'hidden' }}>
          <AllRightsWrapper>
            <Col>
              <AllRightsText>
                {year} &copy; Perfect Stroke. All rights reserved.
              </AllRightsText>
            </Col>
            <Col>
              <IconsWrapper>
                <a
                  href="https://www.facebook.com/perfectstrokeproject/"
                  target="_blank"
                >
                  <img style={{ paddingRight: 50 }} src={fbLogo} />
                </a>
                <a
                  href="https://www.instagram.com/perfectstrokeproject/"
                  target="_blank"
                >
                  <img src={instagramLogo} />
                </a>
              </IconsWrapper>
            </Col>
          </AllRightsWrapper>
        </Row>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${styles.colors.brandGrayDark};
  padding-top: ${styles.space.margin100}px;
  z-index: 1000;
  position: relative;
  overflow: hidden;
`;

const LogoColumn = styled(Col)`
  text-align: left;
  margin-bottom: 40px;

  @media ${styles.device.laptop} {
    margin-bottom: 0;
  }
`;

const InfoColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media ${styles.device.laptop} {
    margin-bottom: 0;
  }
`;

const PrivacyColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media ${styles.device.laptop} {
    margin-bottom: 0;
  }
`;

const SubscribeColumn = styled(Col)``;

const WhiteText = styled.div`
  color: ${styles.colors.white};
  float: left;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 12px;
  padding-bottom: 20px;
`;

const AllRightsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${styles.space.margin20}px;
  padding-bottom: ${styles.space.margin20}px;
  flex-grow: 1;
  padding-top: ${styles.space.margin100}px;
`;

const IconsWrapper = styled.div`
  float: right;
`;

const AllRightsText = styled.div`
  color: ${styles.colors.gray};
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 20px;
  font-size: 10px;
`;

const Logo = styled.img`
  height: 40px;
`;

const FooterLink = styled(Link)`
  color: white;
  text-decoration: none;
  float: left;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 12px;
  padding-bottom: 20px;
`;
