import React from 'react';
import styled from 'styled-components';
import styles from '../../../common/styles';
import DataItemCouple from './DataItemCouple';

import brush from '../../../static/paper_pic.png';

const PaperRow = ({ paper }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image src={brush}  />
      </ImageWrapper>
      <DataWrapper>
        <DataColumn>
          <DataItemCouple
            label="Material"
            info={paper.paperMaterial ? paper.paperMaterial.name : '--'}
          />
          <DataItemCouple label="Gsm" info={paper.gsm} />
          <DataItemCouple
            label="Surface"
            info={paper.paperSurface ? paper.paperSurface.name : '--'}
          />
          <DataItemCouple
          hexCode={paper.color.hexCode}
            label="Color"
            info={paper.color ? paper.color.name : '--'}
          />
        </DataColumn>
        <DataColumn>
          <DataItemCouple
            label="Brand"
            info={paper.brand ? paper.brand.name : '--'}
          />
          <DataItemCouple
            label="Model"
            info={paper.brandModel ? paper.brandModel.name : '--'}
          />
          <DataItemCouple label="Ref" info={paper.ref} />
        </DataColumn>
        <DataColumn>
          <DataItemCouple label="Size as purchased" info={paper.originalSize} />
          <DataItemCouple
            label="Price"
            info={paper.price ? `$${paper.price}` : '--'}
          />
          <DataItemCouple
            label="Shop"
            info={paper.store ? paper.store.name : '--'}
          />
        </DataColumn>
      </DataWrapper>
    </Wrapper>
  );
};

export default PaperRow;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  /* height: 200px; */

  @media ${styles.device.laptop} { 
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
      width: 100%;
    @media ${styles.device.laptop} {
      width: 477px;
  }
`;

const Image = styled.img`
    width: 100%;
    @media ${styles.device.laptop} {
      width: 477px;
  }
`;

const DataWrapper = styled.div`
  background-color: ${styles.colors.brandGrayLight};
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: left;
padding-left: 20px;
width: 100%;

@media ${styles.device.laptop} {
    flex-direction: row;
    min-height: 240px;
    border-left: 3px ${styles.colors.white} solid;
  }
`;

const DataColumn = styled.div`
  flex-grow: 1;
  padding-left: 20px;


  @media ${styles.device.laptop} {
    border-right: 1px ${styles.colors.lightGray} solid;
  }
`;
