import React, { useState } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import './App.css';

import Navigation from './common/components/Navigation';

import styles from './common/styles';
import AppRoutes from './routing/AppRoutes';
import StrokePlain from './scenes/strokePlain/screens/StrokePlain';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import { auth } from './config/firebase';
import { signInAnonymously } from 'firebase/auth';
import MyContext from './services/context';
import Footer from './common/components/Footer';
import Image from './scenes/tutorials/Image';

const App = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <BrowserRouter>
      <AnimationApp width={width} />
    </BrowserRouter>
  );
};

const AnimationApp = ({ width }) => {
  const location = useLocation();
  useEffect(() => {
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  }, []);
  const [showStrokePlain, setShowStrokePlain] = useState(false);
  const [title, setTitle] = useState('My Title');
  const [canClick, setCanClick] = useState(false);

  useEffect(() => {
    if (location.pathname === '/') {
      setCanClick(true);
    } else {
      setCanClick(true);
    }
  }, [location]);
  return (
    <div>
      <MyContext.Provider
        value={{ showStrokePlain, setShowStrokePlain, title, setTitle }}
      >
        <Helmet>
          <title>{title}</title>
          <meta name="description" content="My page description" />
        </Helmet>

        {width > 600 && <StrokePlain />}

        {showStrokePlain && width <= 600 && <Image canClick={canClick} />}
        <Navigation />
        <AppRoutes />
        {!showStrokePlain && <Footer />}
      </MyContext.Provider>
    </div>
  );
};

export default App;

const styles = {};

styles.fill = {
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};

styles.content = {
  ...styles.fill,
  top: '40px',
  textAlign: 'center',
};

styles.nav = {
  padding: 0,
  margin: 0,
  position: 'absolute',
  top: 0,
  height: '40px',
  width: '100%',
  display: 'flex',
};

styles.navItem = {
  textAlign: 'center',
  flex: 1,
  listStyleType: 'none',
  padding: '10px',
};

styles.hsl = {
  ...styles.fill,
  color: 'white',
  paddingTop: '20px',
  fontSize: '30px',
};

styles.rgb = {
  ...styles.fill,
  color: 'white',
  paddingTop: '20px',
  fontSize: '30px',
};
