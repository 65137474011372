const colors = {
  brandGrayDark: '#373737',
  brandGrayLight: '#F4F4F4',
  brandRed: '#373737',
  white: '#ffffff',
  black: '#000000',
  gray: '#7A7A7A',
  lightGray: '#E8E8E8',
  mineShaft: '#282828',

  darkBlue: '#0b2e4d',
  lightBlue: '#04a3d7',
  lightBlueHover: '#0484af',
  veryLightBlue: '#bce0fd',
  red: '#f20a05',
  redOrange: '#ff2526',
  cosmos: '#ffdbdb',

  svDarkRed: '#9b3232',
  svRed: '#d82a2d',
  nRed: '#eb4034',
  svOrange: '#ef7f1a',
  svDarkOrange: '#e86b47',
  softOrange: '#e3cab3',
  white: '#ffffff',
  softGreen: '#acd6a5',
  nGreen: '#dbffee',
  lightYellow: '#Fbf8e5',

  pastelLightOrange: '#FFDABA',
  pastelDarkOrange: '#FAB894',
  pastelLightPurple: '#CB99C8',
  pastelDarkPurple: '#A087C3',
};

const sizing = {
  brandBoxSize: 75,
};

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

const space = {
  margin100: 100,
  margin20: 20,
};

const texts = {
  regular: {
    color: colors.black,
    fontSize: 18,
  },
  label: {
    color: colors.gray,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  title: {
    color: colors.black,
    textTransform: 'uppercase',
    fontSize: 55,
  },
};

export default {
  colors,
  sizing,
  texts,
  space,
  size,
  device,
};
