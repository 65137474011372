import React from 'react';
import styled from 'styled-components';
import styles from '../styles';

const WhiteButton = ({ onClick, text }) => {
    return (

        <Button onClick={onClick}>
            {text}
        </Button>
  
    );
};

const Button = styled.button`
    background-color: ${styles.colors.white};
    padding: 10px 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    border:0;
`;

export default WhiteButton;