import React, {useState} from 'react';
import styled from 'styled-components';
import styles from '../styles';
import {Accordion, Card} from 'react-bootstrap';
import FilterDropdownSubmenu from './FilterDropdownSubmenu';
import { Plus, Minus } from '@styled-icons/feather';

const FilterDropdown = (props) => {
  const [isFullHeight, setIsFullHeigth] = useState(false)
    const toggleAccordion = () => {
setIsFullHeigth(!isFullHeight)
    }
  return (
  <Accordion>
  <Card style={headerWrapper}>
    <Accordion.Toggle onClick={toggleAccordion} style={header} as={Card.Header} eventKey="0">
    {props.dropdownHeader}
    {isFullHeight ? (<MinusIcon/>):(<PlusIcon />)}
    </Accordion.Toggle>
    <div style={isFullHeight ? {paddingBottom: 20} : {paddingBottom: 0}}>
    {props.dropdownItems.map(item =>(
        <Accordion.Collapse style={collapseWrapper} eventKey="0">
        <div style={cardBodyWrapper}>
        <Card.Body style={cardBody}><FilterDropdownSubmenu item={item} /></Card.Body>
        </div>
      </Accordion.Collapse>
    )
    )}
    </div>
  </Card>
</Accordion>
  );
};



const header = {
color: styles.colors.white,
borderBottom: '1px solid #585858',
marginBottom: 0, 
cursor: 'pointer',
paddingLeft: 20
}

const PlusIcon = styled(Plus)`
width: 20px;
height: 20px;
float: right;
color: #7a7a7a;
`;

const MinusIcon = styled(Minus)`
width: 20px;
height: 20px;
float: right;
color: #7a7a7a;
`;

const cardBody = {
  padding: '5px 0',
 
}

const cardBodyWrapper = {
  //paddingLeft:  30,

}

const headerWrapper = {
    backgroundColor: styles.colors.brandGrayDark,
    border: 'none'
}

const collapseWrapper = {
    color: styles.colors.white,
    backgroundColor: styles.colors.brandGrayDark,
}


export default FilterDropdown;
