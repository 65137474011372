import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MyContext from '../../../services/context';
import TitleComponent from '~/src/common/components/TitleComponent';
import WhitePad from '~/src/common/components/WhitePad';

export default function GetInvolved() {
  const context = useContext(MyContext);
  context.setShowStrokePlain(false);
  context.setTitle('Get Involved');
  return (
    <WhitePad>
      <TitleComponent title="Get Involved" />
      <p>
        Come back later to find out how you can help another human being through
        our Perfect Stroke Project non-profit foundation.
      </p>

      <p>
        Alternatively just signup for our minimal newsletter (3-4 emails per
        year) to know major developments around this project.
      </p>
      <Link to="/subscribe">Subscribe</Link>
    </WhitePad>
  );
}
