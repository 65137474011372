import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import MyContext from '../../../services/context';
import TitleComponent from '~/src/common/components/TitleComponent';
import WhitePad from '~/src/common/components/WhitePad';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
const url = '//xxxx.us13.list-manage.com/subscribe/post?u=zefzefzef&id=fnfgn';

export default function Subscribe() {
  const context = useContext(MyContext);
  context.setShowStrokePlain(false);
  context.setTitle('Subscribe');
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  useEffect(() => {
    return function unmount() {};
  }, []);

  const url =
    'https://perfectstrokeproject.us9.list-manage.com/subscribe/post?u=73fe9487d6efc16e8591e4f06&amp;id=bc5ab0a840&amp;f_id=00e310e1f0';

  const CustomForm = ({ status, message, onValidated }) => {
    let email, FNAME, LNAME;
    const submit = () =>
      email &&
      FNAME &&
      LNAME &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value,
        FNAME: FNAME.value,
        LNAME: LNAME.value,
      });

    return (
      <div
        style={{
          background: '#efefef',
          borderRadius: 2,
          padding: 10,
          display: 'inline-block',
        }}
      >
        {status === 'sending' && (
          <div style={{ color: 'blue' }}>sending...</div>
        )}
        {status === 'error' && (
          <div
            style={{ color: 'red' }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === 'success' && (
          <div
            style={{ color: 'green' }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <input
          style={{ fontSize: '2em', padding: 5 }}
          ref={(node) => (FNAME = node)}
          type="text"
          placeholder="Your First Name"
        />
        <br />
        <input
          style={{ fontSize: '2em', padding: 5 }}
          ref={(node) => (LNAME = node)}
          type="text"
          placeholder="Your Last Name"
        />
        <br />
        <input
          style={{ fontSize: '2em', padding: 5 }}
          ref={(node) => (email = node)}
          type="email"
          placeholder="Your email"
        />
        <br />
        <button style={{ fontSize: '2em', padding: 5 }} onClick={submit}>
          Submit
        </button>
      </div>
    );
  };

  return (
    <WhitePad>
      <TitleComponent title="Subscribe for News" />
      <p>
        Hi, if you are interested in learning more about it and keeping up to
        date for major events (ex. Exhibition coming up) , feel free to signup
        for our newsletter. There will be no regular emails or spam of any kind,
        just 3 or 4 mails per year.
      </p>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </WhitePad>
  );
}

const Img100 = styled.img`
  width: 100%;
`;
