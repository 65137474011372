[
  {
    "order_id": 0,
    "tool": null,
    "description": "<p>I want to make it look even more like a soundwave (!) Fantastic. I like it so much, I will make one exactly like it</p>",
    "paper": {
      "brand": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {},
      "name": null,
      "ref": null,
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "gsm": null,
      "original_size": "A4"
    },
    "name": "Sound Wave For Real White",
    "photos": {
      "original": {
        "reference": "/stroke_photos/00150067-1d83-4fd8-ad1e-cb5bc57ea23d/12e98ec6-8bb4-4e9a-846d-3de1abdddb4b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F00150067-1d83-4fd8-ad1e-cb5bc57ea23d%2F12e98ec6-8bb4-4e9a-846d-3de1abdddb4b?alt=media&token=cf7d14af-9f02-46d9-ab29-31c98238433d"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/00150067-1d83-4fd8-ad1e-cb5bc57ea23d/600x600/12e98ec6-8bb4-4e9a-846d-3de1abdddb4b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F00150067-1d83-4fd8-ad1e-cb5bc57ea23d%2F600x600%2F12e98ec6-8bb4-4e9a-846d-3de1abdddb4b_600x600?alt=media&token=51ffa8d3-ce06-411f-9ea3-250fc9cf7a31"
      }
    },
    "order_number": 508,
    "id": "00150067-1d83-4fd8-ad1e-cb5bc57ea23d"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1052,
    "id": "0015f282-e2fe-45bc-b385-286b18af6e43"
  },
  {
    "name": "",
    "order_number": 1104,
    "description": "",
    "id": "0038b731-fa5f-4ed7-8c9f-528281f66f39"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1201,
    "id": "0064fac2-ce91-4764-81a6-f46b051e3f47"
  },
  {
    "order_id": 0,
    "description": "",
    "name": "Blank",
    "order_number": 900,
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "4005329065767",
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "price": 1,
      "original_size": "A4",
      "name": null,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "name": "Elephant Skin",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        }
      },
      "gsm": "110",
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      }
    },
    "id": "00704f04-fd15-440b-aae2-7f7e5d793943"
  },
  {
    "order_number": 1135,
    "description": "",
    "name": "",
    "id": "00c52604-ba5c-4857-9147-d6886593de82"
  },
  {
    "description": "",
    "order_number": 203,
    "photos": {
      "original": {
        "reference": "/stroke_photos/00e3e667-a34a-4726-8977-5edf3bb3f705/1d6dc185-7947-40ab-bc88-53f7115cb663",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F00e3e667-a34a-4726-8977-5edf3bb3f705%2F1d6dc185-7947-40ab-bc88-53f7115cb663?alt=media&token=4d061dbf-dea1-4412-a456-2ed3d9db343b"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F00e3e667-a34a-4726-8977-5edf3bb3f705%2F600x600%2F1d6dc185-7947-40ab-bc88-53f7115cb663_600x600?alt=media&token=a99d4d6d-e6ac-4959-8377-3182e518a03c",
        "reference": "stroke_photos/00e3e667-a34a-4726-8977-5edf3bb3f705/600x600/1d6dc185-7947-40ab-bc88-53f7115cb663_600x600"
      }
    },
    "tool": null,
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "ref": "77725080001",
      "price": 1.35,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "original_size": "A7",
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "gsm": "250",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      }
    },
    "name": "Less Paint But Better Skill",
    "id": "00e3e667-a34a-4726-8977-5edf3bb3f705",
    "order_id": 0
  },
  {
    "description": "",
    "tool": null,
    "order_id": 0,
    "order_number": 222,
    "name": "Just a Thing",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/00f3b1ec-67ae-481e-9812-f909a73d1dd8/600x600/4eec6706-74c3-4899-a5a5-2685d5dbae92_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F00f3b1ec-67ae-481e-9812-f909a73d1dd8%2F600x600%2F4eec6706-74c3-4899-a5a5-2685d5dbae92_600x600?alt=media&token=fd4c11f6-d6a7-4af7-85ee-29b9f57ca3a9"
      },
      "original": {
        "reference": "/stroke_photos/00f3b1ec-67ae-481e-9812-f909a73d1dd8/4eec6706-74c3-4899-a5a5-2685d5dbae92",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F00f3b1ec-67ae-481e-9812-f909a73d1dd8%2F4eec6706-74c3-4899-a5a5-2685d5dbae92?alt=media&token=aaa4ed99-e681-49f1-9537-c7f7c3edb7f7"
      }
    },
    "id": "00f3b1ec-67ae-481e-9812-f909a73d1dd8",
    "paper": {
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "gsm": "250",
      "price": 1.35,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "77725080001",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      }
    }
  },
  {
    "name": "Blank",
    "id": "010e9532-ef50-4df6-8815-c2ef727e14ab",
    "paper": {
      "brand_model": {},
      "name": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "price": null,
      "brand": {},
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl",
        "hex": "#f5f5f5"
      },
      "ref": "70025010030",
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      }
    },
    "order_number": 839,
    "description": "",
    "order_id": 0
  },
  {
    "order_number": 1050,
    "description": "",
    "name": "",
    "id": "01692302-be27-4d5e-8a92-9a9f0c73e235"
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0186785f-4951-4f04-9cbe-ce9ac8988a21%2F600x600%2Fa47f4bb2-993f-444b-9e9a-fe716e4d3fe8_600x600?alt=media&token=8e91d096-e8ef-4a3d-9af4-d008c1a87598",
        "reference": "stroke_photos/0186785f-4951-4f04-9cbe-ce9ac8988a21/600x600/a47f4bb2-993f-444b-9e9a-fe716e4d3fe8_600x600"
      },
      "original": {
        "reference": "/stroke_photos/0186785f-4951-4f04-9cbe-ce9ac8988a21/a47f4bb2-993f-444b-9e9a-fe716e4d3fe8",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0186785f-4951-4f04-9cbe-ce9ac8988a21%2Fa47f4bb2-993f-444b-9e9a-fe716e4d3fe8?alt=media&token=a1c049b6-f171-48cf-bb53-91b0c3742e20"
      }
    },
    "description": "",
    "order_number": 112,
    "name": "Variable Smudges",
    "paper": {
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "price": null,
      "original_size": "A7",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": "20180107",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250"
    },
    "id": "0186785f-4951-4f04-9cbe-ce9ac8988a21",
    "order_id": 0,
    "tool": null
  },
  {
    "order_id": 0,
    "id": "018bd0d2-31f3-40c4-baf2-8fffdf5afe0f",
    "name": "Blank",
    "photos": {
      "original": {
        "reference": "/stroke_photos/018bd0d2-31f3-40c4-baf2-8fffdf5afe0f/f235a72d-6114-44d1-b391-fc8a6f323bd8",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F018bd0d2-31f3-40c4-baf2-8fffdf5afe0f%2Ff235a72d-6114-44d1-b391-fc8a6f323bd8?alt=media&token=e2cc3df5-16cc-4b3e-ac62-6b2d985b5ea0"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/018bd0d2-31f3-40c4-baf2-8fffdf5afe0f/600x600/f235a72d-6114-44d1-b391-fc8a6f323bd8_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F018bd0d2-31f3-40c4-baf2-8fffdf5afe0f%2F600x600%2Ff235a72d-6114-44d1-b391-fc8a6f323bd8_600x600?alt=media&token=adcc2ecc-e9ac-4743-93ab-ce9a58280577"
      }
    },
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "ref": "20170703",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": 0.4,
      "gsm": "250",
      "original_size": "A7",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      }
    },
    "tool": null,
    "description": "",
    "order_number": 564
  },
  {
    "order_number": 778,
    "paper": {
      "gsm": "250",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": 1.35,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "name": null,
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "original_size": "A7",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "ref": "77725080001"
    },
    "name": "Blank",
    "tool": null,
    "id": "018e5e7b-cc92-4f5e-98bf-9ee07b1bcdde",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F018e5e7b-cc92-4f5e-98bf-9ee07b1bcdde%2F600x600%2F0c0fdf98-b149-44f0-a55c-2962d0f7c05d_600x600?alt=media&token=5b0cbcd3-fdbb-42df-b0eb-7c258767c5a3",
        "reference": "stroke_photos/018e5e7b-cc92-4f5e-98bf-9ee07b1bcdde/600x600/0c0fdf98-b149-44f0-a55c-2962d0f7c05d_600x600"
      },
      "original": {
        "reference": "/stroke_photos/018e5e7b-cc92-4f5e-98bf-9ee07b1bcdde/0c0fdf98-b149-44f0-a55c-2962d0f7c05d",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F018e5e7b-cc92-4f5e-98bf-9ee07b1bcdde%2F0c0fdf98-b149-44f0-a55c-2962d0f7c05d?alt=media&token=84271a93-ee02-4e83-96a9-e4d33e85229d"
      }
    },
    "description": "",
    "order_id": 0
  },
  {
    "order_number": 715,
    "photos": {
      "original": {
        "reference": "/stroke_photos/019cf701-be1e-44a6-bd07-9d0e64cef5db/e0aef869-78e1-462d-a9cc-f572e6ae4179",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F019cf701-be1e-44a6-bd07-9d0e64cef5db%2Fe0aef869-78e1-462d-a9cc-f572e6ae4179?alt=media&token=c0c0f2a0-45a9-4af1-b30d-80ad52c00264"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F019cf701-be1e-44a6-bd07-9d0e64cef5db%2F600x600%2Fe0aef869-78e1-462d-a9cc-f572e6ae4179_600x600?alt=media&token=43613bfc-d075-47d0-890e-a1edaa56547d",
        "reference": "stroke_photos/019cf701-be1e-44a6-bd07-9d0e64cef5db/600x600/e0aef869-78e1-462d-a9cc-f572e6ae4179_600x600"
      }
    },
    "order_id": 0,
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": 0.4,
      "ref": "20170703",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "name": null,
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "brand": {},
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b"
    },
    "description": "",
    "tool": null,
    "id": "019cf701-be1e-44a6-bd07-9d0e64cef5db",
    "name": "Blank"
  },
  {
    "name": "Italics",
    "description": "",
    "paper": {
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "original_size": "A7",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "price": 1.35,
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "gsm": "250",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "ref": "77725080001",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    },
    "order_id": 0,
    "order_number": 221,
    "tool": null,
    "id": "01e751f0-e876-4cc2-b472-2fbf12eb0ae1",
    "photos": {
      "original": {
        "reference": "/stroke_photos/01e751f0-e876-4cc2-b472-2fbf12eb0ae1/0904291c-4294-4b6f-9b22-6514bc9ec68f",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F01e751f0-e876-4cc2-b472-2fbf12eb0ae1%2F0904291c-4294-4b6f-9b22-6514bc9ec68f?alt=media&token=1575c199-d597-4e3d-bbc8-d813ed66c3d7"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F01e751f0-e876-4cc2-b472-2fbf12eb0ae1%2F600x600%2F0904291c-4294-4b6f-9b22-6514bc9ec68f_600x600?alt=media&token=d49a9eef-17a6-4d9f-b53a-877c7b1bcdd3",
        "reference": "stroke_photos/01e751f0-e876-4cc2-b472-2fbf12eb0ae1/600x600/0904291c-4294-4b6f-9b22-6514bc9ec68f_600x600"
      }
    }
  },
  {
    "id": "022891ab-d5b8-47bc-8ced-e788edb625d1",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F022891ab-d5b8-47bc-8ced-e788edb625d1%2Ffecaf391-fe8e-44cc-9934-03d9ea30388e?alt=media&token=0b361b28-a089-47c1-991d-201aff73854a",
        "reference": "/stroke_photos/022891ab-d5b8-47bc-8ced-e788edb625d1/fecaf391-fe8e-44cc-9934-03d9ea30388e"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/022891ab-d5b8-47bc-8ced-e788edb625d1/600x600/fecaf391-fe8e-44cc-9934-03d9ea30388e_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F022891ab-d5b8-47bc-8ced-e788edb625d1%2F600x600%2Ffecaf391-fe8e-44cc-9934-03d9ea30388e_600x600?alt=media&token=56be723d-a167-49e4-9929-657197570a1c"
      }
    },
    "tool": null,
    "order_id": 0,
    "paper": {
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "ref": "77725080001",
      "price": 1.35,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "original_size": "A7",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    },
    "description": "I put a lot of paint to make it black, but it left lumps on the side of the road. No more that much more.",
    "name": "Black Series 2",
    "order_number": 230
  },
  {
    "order_id": 0,
    "tool": null,
    "name": "Upsde Swirly 2",
    "description": "<p>Another shot at the ultimate swirly, and god is it far...</p>",
    "paper": {
      "name": null,
      "price": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {},
      "original_size": "A7",
      "ref": "20180107",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250"
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/022fc369-0026-43af-a7cc-c2caa1ae961a/3ef1011e-6e73-4d83-88ae-462e99ab9772",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F022fc369-0026-43af-a7cc-c2caa1ae961a%2F3ef1011e-6e73-4d83-88ae-462e99ab9772?alt=media&token=95742e62-c80f-47a3-b2dc-fada234b4c1b"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/022fc369-0026-43af-a7cc-c2caa1ae961a/600x600/3ef1011e-6e73-4d83-88ae-462e99ab9772_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F022fc369-0026-43af-a7cc-c2caa1ae961a%2F600x600%2F3ef1011e-6e73-4d83-88ae-462e99ab9772_600x600?alt=media&token=ac72c16c-46f2-4da0-902d-a41f70f5439f"
      }
    },
    "id": "022fc369-0026-43af-a7cc-c2caa1ae961a",
    "order_number": 327
  },
  {
    "order_number": 793,
    "name": "Blank",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F02699cb3-e889-4eb9-bb02-d79748eccc03%2F48ea6d93-397e-49fd-a1fd-c811ffe8235f?alt=media&token=22bdc07d-44bf-4691-8f3b-60fef054f197",
        "reference": "/stroke_photos/02699cb3-e889-4eb9-bb02-d79748eccc03/48ea6d93-397e-49fd-a1fd-c811ffe8235f"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/02699cb3-e889-4eb9-bb02-d79748eccc03/600x600/48ea6d93-397e-49fd-a1fd-c811ffe8235f_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F02699cb3-e889-4eb9-bb02-d79748eccc03%2F600x600%2F48ea6d93-397e-49fd-a1fd-c811ffe8235f_600x600?alt=media&token=a8f6dbb6-ffaf-4092-8869-59ed92c29899"
      }
    },
    "order_id": 0,
    "id": "02699cb3-e889-4eb9-bb02-d79748eccc03",
    "description": "",
    "paper": {
      "name": null,
      "ref": "77725080001",
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": 1.35,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "gsm": "250",
      "original_size": "A7",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      }
    },
    "tool": null
  },
  {
    "name": "Blank",
    "id": "02725fa5-c64c-4ef4-9af3-ba8663c191c0",
    "paper": {
      "original_size": "A7",
      "gsm": "250",
      "ref": "77725080001",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "name": null,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "price": 1.35,
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      }
    },
    "order_id": 0,
    "description": "",
    "order_number": 404
  },
  {
    "order_id": 0,
    "tool": null,
    "order_number": 242,
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "original_size": "A7",
      "ref": "77725080001",
      "gsm": "250",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "price": 1.35,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      }
    },
    "name": "Musically 7",
    "description": "Not as good as the previous one",
    "photos": {
      "original": {
        "reference": "/stroke_photos/02e1db46-75ce-4af6-8110-743dbaf1e587/e83b294a-96ab-455f-bacb-e1612e201b29",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F02e1db46-75ce-4af6-8110-743dbaf1e587%2Fe83b294a-96ab-455f-bacb-e1612e201b29?alt=media&token=593c64d1-46d0-457a-a317-347df48849cf"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/02e1db46-75ce-4af6-8110-743dbaf1e587/600x600/e83b294a-96ab-455f-bacb-e1612e201b29_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F02e1db46-75ce-4af6-8110-743dbaf1e587%2F600x600%2Fe83b294a-96ab-455f-bacb-e1612e201b29_600x600?alt=media&token=43b80984-2001-4b1e-a160-1ff66af4995c"
      }
    },
    "id": "02e1db46-75ce-4af6-8110-743dbaf1e587"
  },
  {
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F02ebc2fc-1504-4976-9bc5-43d948b5dc76%2F600x600%2Fbeffd2b7-f753-4107-b8f1-1407867fd0c3_600x600?alt=media&token=e7abbacc-6f69-4e9d-9fe6-d1eadd7037cb",
        "reference": "stroke_photos/02ebc2fc-1504-4976-9bc5-43d948b5dc76/600x600/beffd2b7-f753-4107-b8f1-1407867fd0c3_600x600"
      },
      "original": {
        "reference": "/stroke_photos/02ebc2fc-1504-4976-9bc5-43d948b5dc76/beffd2b7-f753-4107-b8f1-1407867fd0c3",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F02ebc2fc-1504-4976-9bc5-43d948b5dc76%2Fbeffd2b7-f753-4107-b8f1-1407867fd0c3?alt=media&token=050c3276-7904-4078-9b5b-6f247df0ae8e"
      }
    },
    "name": "Blank",
    "paper": {
      "ref": "70025010030",
      "price": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand": {},
      "name": null,
      "gsm": "250",
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "brand_model": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "id": "02ebc2fc-1504-4976-9bc5-43d948b5dc76",
    "order_number": 588,
    "tool": null,
    "description": ""
  },
  {
    "order_number": 547,
    "tool": null,
    "name": "Silver-On-Black Dim One",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F030437f6-34ea-4ce1-bc78-9b7771acc941%2F600x600%2F38cba977-de96-469e-ac1d-3e1fda8dbed8_600x600?alt=media&token=ae840703-75d1-4955-b7ca-fd016b848497",
        "reference": "stroke_photos/030437f6-34ea-4ce1-bc78-9b7771acc941/600x600/38cba977-de96-469e-ac1d-3e1fda8dbed8_600x600"
      },
      "original": {
        "reference": "/stroke_photos/030437f6-34ea-4ce1-bc78-9b7771acc941/38cba977-de96-469e-ac1d-3e1fda8dbed8",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F030437f6-34ea-4ce1-bc78-9b7771acc941%2F38cba977-de96-469e-ac1d-3e1fda8dbed8?alt=media&token=9b4c1613-82b2-43ac-bab7-af7fd6be15e5"
      }
    },
    "id": "030437f6-34ea-4ce1-bc78-9b7771acc941",
    "paper": {
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "brand": {},
      "brand_model": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "name": null,
      "price": null,
      "gsm": null
    },
    "description": "<p>One without much paint (!) Soooo goood. Must be one of my favorites</p>"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1140,
    "id": "0347e6fe-4803-407f-9a2c-e3b7b9ef5301"
  },
  {
    "name": "Blank",
    "id": "03565e4d-9ffd-4ee5-bff0-ff4b88ef867e",
    "order_number": 930,
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "ref": null,
      "gsm": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand_model": {},
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "original_size": "A4",
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "description": "",
    "order_id": 0
  },
  {
    "name": "Blank",
    "order_number": 919,
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": null,
      "brand_model": {},
      "gsm": null,
      "brand": {},
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "original_size": "A4",
      "price": null
    },
    "id": "037cf462-3730-4a1d-8d82-d16bdc23ff63",
    "description": "",
    "order_id": 0
  },
  {
    "name": "",
    "description": "",
    "order_number": 1024,
    "id": "0394a4cf-dc76-42be-8407-5090022aef59"
  },
  {
    "name": "",
    "order_number": 1076,
    "description": "",
    "id": "0395d23f-bfec-4f14-9983-eac86b78e6b3"
  },
  {
    "order_number": 1025,
    "name": "",
    "description": "",
    "id": "03acd8cc-95ab-424f-9e7c-33ffd8674400"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/03c984c6-f1a4-47ac-a91e-e58ca7e92db9/d46cf6a5-0bc7-486a-b8ee-1088b6b99460",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F03c984c6-f1a4-47ac-a91e-e58ca7e92db9%2Fd46cf6a5-0bc7-486a-b8ee-1088b6b99460?alt=media&token=e7242530-6d10-427c-b6f5-617bcb018103"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/03c984c6-f1a4-47ac-a91e-e58ca7e92db9/600x600/d46cf6a5-0bc7-486a-b8ee-1088b6b99460_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F03c984c6-f1a4-47ac-a91e-e58ca7e92db9%2F600x600%2Fd46cf6a5-0bc7-486a-b8ee-1088b6b99460_600x600?alt=media&token=63f07ec8-24d8-4dcb-a03e-337b5b78ec86"
      }
    },
    "order_id": 0,
    "name": "Accidental Split",
    "id": "03c984c6-f1a4-47ac-a91e-e58ca7e92db9",
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "gsm": "250",
      "ref": "20180107",
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "price": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    },
    "tool": null,
    "description": "<p>Love it! Should do more of that some day</p>",
    "order_number": 281
  },
  {
    "name": "",
    "description": "",
    "order_number": 1161,
    "id": "03cc93f2-891e-43bf-b7aa-1a737d6c5b3b"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/0446cd7b-0007-45ba-815c-bfc804ded5b5/8ca089b5-6a9b-4ca1-adb3-3ddad2862d75",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0446cd7b-0007-45ba-815c-bfc804ded5b5%2F8ca089b5-6a9b-4ca1-adb3-3ddad2862d75?alt=media&token=fae9c853-f2cb-4311-9232-233012dde144"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0446cd7b-0007-45ba-815c-bfc804ded5b5%2F600x600%2F8ca089b5-6a9b-4ca1-adb3-3ddad2862d75_600x600?alt=media&token=1b88d006-2324-4cb6-b9b2-0be6558ea055",
        "reference": "stroke_photos/0446cd7b-0007-45ba-815c-bfc804ded5b5/600x600/8ca089b5-6a9b-4ca1-adb3-3ddad2862d75_600x600"
      }
    },
    "tool": null,
    "id": "0446cd7b-0007-45ba-815c-bfc804ded5b5",
    "description": "",
    "name": "S No Good",
    "paper": {
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "price": null,
      "gsm": "200",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "ref": "44202129",
      "original_size": "A4",
      "paper_surface": {},
      "name": ""
    },
    "order_number": 63,
    "order_id": 0
  },
  {
    "paper": {
      "gsm": "250",
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand": {},
      "original_size": "A7",
      "ref": "70025010030",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {},
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      }
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F044d87b9-2cd6-4445-aa90-69c2c7f38b92%2F600x600%2F7eaee442-c619-46f4-acd2-4d2b738c14de_600x600?alt=media&token=68d6ceed-9718-4b62-b41f-9b1992206603",
        "reference": "stroke_photos/044d87b9-2cd6-4445-aa90-69c2c7f38b92/600x600/7eaee442-c619-46f4-acd2-4d2b738c14de_600x600"
      },
      "original": {
        "reference": "/stroke_photos/044d87b9-2cd6-4445-aa90-69c2c7f38b92/7eaee442-c619-46f4-acd2-4d2b738c14de",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F044d87b9-2cd6-4445-aa90-69c2c7f38b92%2F7eaee442-c619-46f4-acd2-4d2b738c14de?alt=media&token=5dac5138-827d-4811-8524-6fff79d0a96b"
      }
    },
    "order_id": 0,
    "description": "",
    "id": "044d87b9-2cd6-4445-aa90-69c2c7f38b92",
    "tool": null,
    "name": "Blank",
    "order_number": 579
  },
  {
    "description": "",
    "order_number": 1195,
    "name": "",
    "id": "0477903e-cca3-4170-b141-4d656857266e"
  },
  {
    "id": "04d1137f-1fe7-47da-8885-b40696ea6cfb",
    "description": "<p>Let's focus back on the mosaics with lot's of paint but no zig-zag (!) It appears like broken up. Very original!</p>",
    "order_id": 0,
    "tool": null,
    "name": "Back To Straght White",
    "paper": {
      "brand": {},
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {},
      "gsm": null,
      "original_size": "A4",
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/04d1137f-1fe7-47da-8885-b40696ea6cfb/f4052d64-c800-438a-99e0-f247232fb378",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F04d1137f-1fe7-47da-8885-b40696ea6cfb%2Ff4052d64-c800-438a-99e0-f247232fb378?alt=media&token=5dce7299-473e-426a-824a-253be693bf42"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F04d1137f-1fe7-47da-8885-b40696ea6cfb%2F600x600%2Ff4052d64-c800-438a-99e0-f247232fb378_600x600?alt=media&token=dc191087-7a04-4bb4-9d7b-846657a1fd40",
        "reference": "stroke_photos/04d1137f-1fe7-47da-8885-b40696ea6cfb/600x600/f4052d64-c800-438a-99e0-f247232fb378_600x600"
      }
    },
    "order_number": 501
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F04f549e0-56c6-4e83-9a09-fb52fb498891%2F600x600%2F98edbc1a-1a36-4c57-a409-7afc14568748_600x600?alt=media&token=cd950b7e-e06b-48bc-8118-a116c9004577",
        "reference": "stroke_photos/04f549e0-56c6-4e83-9a09-fb52fb498891/600x600/98edbc1a-1a36-4c57-a409-7afc14568748_600x600"
      },
      "original": {
        "reference": "/stroke_photos/04f549e0-56c6-4e83-9a09-fb52fb498891/98edbc1a-1a36-4c57-a409-7afc14568748",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F04f549e0-56c6-4e83-9a09-fb52fb498891%2F98edbc1a-1a36-4c57-a409-7afc14568748?alt=media&token=2dad6f91-d4ea-48d2-8d7d-ddb1a2479182"
      }
    },
    "description": "<p>Something is off with the paint, I will try to get a nice gradient over the next few ones</p>",
    "id": "04f549e0-56c6-4e83-9a09-fb52fb498891",
    "name": "Skinny 2",
    "tool": null,
    "order_number": 298,
    "order_id": 0,
    "paper": {
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "ref": "20180107",
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      }
    }
  },
  {
    "id": "0535bb67-4f65-4c29-ace4-f3c16ba49a23",
    "paper": {
      "original_size": "A4",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "paper_surface": {},
      "ref": "44202129",
      "price": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "200",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": ""
    },
    "description": "",
    "tool": null,
    "order_id": 0,
    "order_number": 88,
    "name": "WOE HOT 3",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0535bb67-4f65-4c29-ace4-f3c16ba49a23%2F600x600%2Fe5e5956a-f4cd-4a8d-8222-a40247ac9421_600x600?alt=media&token=dafaab0d-847c-466a-a54a-96e491a0dd2f",
        "reference": "stroke_photos/0535bb67-4f65-4c29-ace4-f3c16ba49a23/600x600/e5e5956a-f4cd-4a8d-8222-a40247ac9421_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0535bb67-4f65-4c29-ace4-f3c16ba49a23%2Fe5e5956a-f4cd-4a8d-8222-a40247ac9421?alt=media&token=0605488f-2b01-4750-b628-2241e2846504",
        "reference": "/stroke_photos/0535bb67-4f65-4c29-ace4-f3c16ba49a23/e5e5956a-f4cd-4a8d-8222-a40247ac9421"
      }
    }
  },
  {
    "name": "Blank",
    "paper": {
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": "tinto",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "gsm": "640",
      "original_size": "75x105cm",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "price": 19.3,
      "ref": "30075105",
      "brand_model": {
        "name": "Artistico",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      }
    },
    "tool": null,
    "order_id": 0,
    "order_number": 686,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/06862197-be32-4303-99f0-9a4461d60434/600x600/33ca1df2-57c2-46f9-b504-e8553e74b971_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F06862197-be32-4303-99f0-9a4461d60434%2F600x600%2F33ca1df2-57c2-46f9-b504-e8553e74b971_600x600?alt=media&token=c46dad34-fb54-4fac-9172-dfa34c1272d9"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F06862197-be32-4303-99f0-9a4461d60434%2F33ca1df2-57c2-46f9-b504-e8553e74b971?alt=media&token=18cc3449-5817-4322-b6f3-f788177bec99",
        "reference": "/stroke_photos/06862197-be32-4303-99f0-9a4461d60434/33ca1df2-57c2-46f9-b504-e8553e74b971"
      }
    },
    "description": "",
    "id": "06862197-be32-4303-99f0-9a4461d60434"
  },
  {
    "tool": null,
    "name": "Blank",
    "paper": {
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "ref": "77725080001",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "original_size": "A7",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "name": null,
      "gsm": "250"
    },
    "description": "",
    "id": "06a9b923-d02e-4725-83e6-d2a9d986398e",
    "order_number": 174,
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F06a9b923-d02e-4725-83e6-d2a9d986398e%2F600x600%2Ffb509ad4-9e68-42b2-a79a-e0bf85e45a49_600x600?alt=media&token=05a5ec6b-7a5e-484a-aaf9-20b6c736e49b",
        "reference": "stroke_photos/06a9b923-d02e-4725-83e6-d2a9d986398e/600x600/fb509ad4-9e68-42b2-a79a-e0bf85e45a49_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F06a9b923-d02e-4725-83e6-d2a9d986398e%2Ffb509ad4-9e68-42b2-a79a-e0bf85e45a49?alt=media&token=09bfddb4-0034-49a2-9bbd-805ee202aa73",
        "reference": "/stroke_photos/06a9b923-d02e-4725-83e6-d2a9d986398e/fb509ad4-9e68-42b2-a79a-e0bf85e45a49"
      }
    }
  },
  {
    "description": "",
    "order_number": 1164,
    "name": "",
    "id": "06b6cb40-b93a-4885-950c-2b5e455c9ab4"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/06c7a36b-7313-4982-a9c6-2947dd7cc21c/ef3aed44-a5d5-41cc-82fb-5a5f8c08b35d",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F06c7a36b-7313-4982-a9c6-2947dd7cc21c%2Fef3aed44-a5d5-41cc-82fb-5a5f8c08b35d?alt=media&token=39404393-6003-4824-8fb1-caf6a2f7d933"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F06c7a36b-7313-4982-a9c6-2947dd7cc21c%2F600x600%2Fef3aed44-a5d5-41cc-82fb-5a5f8c08b35d_600x600?alt=media&token=9b1e47e7-6020-4663-81d1-f00a9f465d1f",
        "reference": "stroke_photos/06c7a36b-7313-4982-a9c6-2947dd7cc21c/600x600/ef3aed44-a5d5-41cc-82fb-5a5f8c08b35d_600x600"
      }
    },
    "description": "<p>So I normally don't like messiness, but it's time for exprimentation and mix black and silver into one thing. Hm, too dark.</p>",
    "order_id": 0,
    "name": "Mixer",
    "order_number": 391,
    "id": "06c7a36b-7313-4982-a9c6-2947dd7cc21c",
    "paper": {
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "20180107",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "name": null,
      "gsm": "250",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top"
      }
    },
    "tool": null
  },
  {
    "name": "Great White One",
    "order_id": 0,
    "id": "06edc193-4c26-46d9-9c5a-7d713e8a863c",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F06edc193-4c26-46d9-9c5a-7d713e8a863c%2F600x600%2Ffa38285e-dcb6-4a88-9a87-7b93a0a13b0b_600x600?alt=media&token=9414fa6e-d453-4690-86fe-32f17afd60a9",
        "reference": "stroke_photos/06edc193-4c26-46d9-9c5a-7d713e8a863c/600x600/fa38285e-dcb6-4a88-9a87-7b93a0a13b0b_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F06edc193-4c26-46d9-9c5a-7d713e8a863c%2Ffa38285e-dcb6-4a88-9a87-7b93a0a13b0b?alt=media&token=1d5cb8c6-c7a7-4a15-b687-de415722885f",
        "reference": "/stroke_photos/06edc193-4c26-46d9-9c5a-7d713e8a863c/fa38285e-dcb6-4a88-9a87-7b93a0a13b0b"
      }
    },
    "order_number": 502,
    "description": "<p>Not bad, very pleasant one. Like a zebra again</p>",
    "paper": {
      "price": null,
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand_model": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": null,
      "brand": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "original_size": "A4",
      "gsm": null,
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      }
    },
    "tool": null
  },
  {
    "id": "073e7aa2-0c25-4f37-b734-ea5a8dd3be8d",
    "description": "",
    "name": "Blank",
    "paper": {
      "ref": "20170703",
      "original_size": "A7",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": 0.4,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "brand": {},
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null
    },
    "order_id": 0,
    "order_number": 818
  },
  {
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "110",
      "name": null,
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "name": "Elephant Skin",
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        }
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "original_size": "A4",
      "price": 1,
      "ref": "4005329065767",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701"
    },
    "order_number": 888,
    "id": "0746bd5f-ed97-4d9a-aaff-11c33ee2d6d0",
    "description": "",
    "order_id": 0,
    "name": "Blank"
  },
  {
    "description": "",
    "order_number": 1073,
    "name": "",
    "id": "074ad96f-73f7-4f50-bf36-e26582e00cbd"
  },
  {
    "order_number": 251,
    "paper": {
      "ref": "20180107",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "original_size": "A7",
      "name": null,
      "gsm": "250"
    },
    "id": "076069c4-29f7-42c8-87fc-9e561d34d20d",
    "description": "Back to black",
    "name": "Another Black",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F076069c4-29f7-42c8-87fc-9e561d34d20d%2F5063b639-24d7-448d-a9a1-8e2f575c98d8?alt=media&token=5304b24e-44e5-440f-bcef-66091502984a",
        "reference": "/stroke_photos/076069c4-29f7-42c8-87fc-9e561d34d20d/5063b639-24d7-448d-a9a1-8e2f575c98d8"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F076069c4-29f7-42c8-87fc-9e561d34d20d%2F600x600%2F5063b639-24d7-448d-a9a1-8e2f575c98d8_600x600?alt=media&token=aca8cf16-303f-4af4-a0b8-bb9e08381fc8",
        "reference": "stroke_photos/076069c4-29f7-42c8-87fc-9e561d34d20d/600x600/5063b639-24d7-448d-a9a1-8e2f575c98d8_600x600"
      }
    },
    "tool": null,
    "order_id": 0
  },
  {
    "paper": {
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top"
      },
      "price": null,
      "name": null,
      "ref": "20180107",
      "brand": {}
    },
    "name": "Sideways Braid Even Less Paint",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/07796751-c4bc-4cd5-836b-46786cbc979e/600x600/f7e6d6de-15ef-4f47-b278-5c86a67d1ee7_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F07796751-c4bc-4cd5-836b-46786cbc979e%2F600x600%2Ff7e6d6de-15ef-4f47-b278-5c86a67d1ee7_600x600?alt=media&token=f275c168-9152-4a34-a1b0-8353bf795c74"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F07796751-c4bc-4cd5-836b-46786cbc979e%2Ff7e6d6de-15ef-4f47-b278-5c86a67d1ee7?alt=media&token=602b2e14-d143-45a7-83bd-2dee37664521",
        "reference": "/stroke_photos/07796751-c4bc-4cd5-836b-46786cbc979e/f7e6d6de-15ef-4f47-b278-5c86a67d1ee7"
      }
    },
    "id": "07796751-c4bc-4cd5-836b-46786cbc979e",
    "order_id": 0,
    "order_number": 143,
    "description": "",
    "tool": null
  },
  {
    "description": "",
    "order_number": 1005,
    "name": "",
    "id": "07ea3d24-0efd-4e32-9b63-c17bfe572e8d"
  },
  {
    "order_number": 1196,
    "name": "",
    "description": "",
    "id": "084c57de-7c9f-4df0-8cba-f0093939ff81"
  },
  {
    "name": "",
    "order_number": 1076,
    "description": "",
    "id": "08e582aa-b715-4857-94f8-cbb0549e4f4b"
  },
  {
    "order_number": 1069,
    "name": "",
    "description": "",
    "id": "0905aa64-e4c1-4305-8a75-0dfd8dabee8d"
  },
  {
    "id": "093d10da-cde1-4d6d-9fcd-fab4f17c10c1",
    "paper": {
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Artistico"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "name": "tinto",
      "original_size": "75x105cm",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "ref": "30075105",
      "price": 19.3,
      "gsm": "640"
    },
    "description": "",
    "order_id": 0,
    "order_number": 983,
    "name": "Blank"
  },
  {
    "order_number": 1100,
    "name": "",
    "description": "",
    "id": "097fbda8-efbb-4e38-8aa3-f38db8920ade"
  },
  {
    "description": "This time I decided to put more paint so I don't get that gap in the middle.",
    "tool": null,
    "order_number": 54,
    "name": "PH More Point",
    "id": "0993a017-9f73-449b-83d2-4ea80a61b48b",
    "order_id": 0,
    "paper": {
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4",
      "price": null,
      "gsm": "200",
      "paper_surface": {},
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": "",
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "ref": "44202129",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4"
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0993a017-9f73-449b-83d2-4ea80a61b48b%2F600x600%2F0c8d3338-b2a6-4e4b-96f3-256da814a6fd_600x600?alt=media&token=289c8fd6-9d32-46b3-bce3-036fe36f02a6",
        "reference": "stroke_photos/0993a017-9f73-449b-83d2-4ea80a61b48b/600x600/0c8d3338-b2a6-4e4b-96f3-256da814a6fd_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0993a017-9f73-449b-83d2-4ea80a61b48b%2F0c8d3338-b2a6-4e4b-96f3-256da814a6fd?alt=media&token=facc3609-2f3a-461a-8156-c38a288000d7",
        "reference": "/stroke_photos/0993a017-9f73-449b-83d2-4ea80a61b48b/0c8d3338-b2a6-4e4b-96f3-256da814a6fd"
      }
    }
  },
  {
    "id": "09bce901-87b3-457f-a983-418515faf8c3",
    "description": "",
    "name": "Blank",
    "order_id": 0,
    "paper": {
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "price": 0.4,
      "ref": "20170703",
      "brand": {},
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A7",
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "gsm": "250",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper"
      }
    },
    "order_number": 816
  },
  {
    "description": "",
    "order_number": 1130,
    "name": "",
    "id": "0a0ca9c3-0ac3-40c7-8211-20ad94026632"
  },
  {
    "order_id": 0,
    "paper": {
      "name": null,
      "price": 1,
      "ref": "4005329065767",
      "original_size": "A4",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "name": "Elephant Skin",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2"
      },
      "gsm": "110",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701"
    },
    "name": "Blank",
    "order_number": 877,
    "id": "0acd4f7b-5f36-4b7d-9ea4-86d93f229c1d",
    "description": ""
  },
  {
    "description": "",
    "name": "",
    "order_number": 1148,
    "id": "0ad67c13-e5de-43ee-9935-9c1dc45cca54"
  },
  {
    "tool": null,
    "description": "<p>Add even more silver</p>",
    "id": "0af2d519-4f24-45e0-95a3-614f9c6413c5",
    "name": "Mixer 3",
    "photos": {
      "original": {
        "reference": "/stroke_photos/0af2d519-4f24-45e0-95a3-614f9c6413c5/bbab3d2c-d40c-4298-9ff1-0a031e59caad",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0af2d519-4f24-45e0-95a3-614f9c6413c5%2Fbbab3d2c-d40c-4298-9ff1-0a031e59caad?alt=media&token=fd557949-dd02-400e-9d57-474ec173ab9a"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0af2d519-4f24-45e0-95a3-614f9c6413c5%2F600x600%2Fbbab3d2c-d40c-4298-9ff1-0a031e59caad_600x600?alt=media&token=53f49c28-450f-439f-9ee0-441e99038b1f",
        "reference": "stroke_photos/0af2d519-4f24-45e0-95a3-614f9c6413c5/600x600/bbab3d2c-d40c-4298-9ff1-0a031e59caad_600x600"
      }
    },
    "order_number": 393,
    "paper": {
      "ref": "20180107",
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "name": null,
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      }
    },
    "order_id": 0
  },
  {
    "description": "",
    "order_number": 1176,
    "name": "",
    "id": "0b827df8-ec45-46f4-b171-33a216a1b2b9"
  },
  {
    "description": "",
    "order_number": 1223,
    "name": "",
    "id": "0b888ecb-783d-4f97-8283-ef70003aa14e"
  },
  {
    "name": "",
    "order_number": 1180,
    "description": "",
    "id": "0be5180c-2c85-4724-9332-8ad06623d753"
  },
  {
    "name": "Blank",
    "description": "",
    "paper": {
      "gsm": null,
      "brand": {},
      "price": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A4",
      "name": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {}
    },
    "order_id": 0,
    "id": "0be801f5-7256-4994-bd64-36b4a3acadb1",
    "order_number": 957
  },
  {
    "id": "0be820dc-6ff8-4893-861a-adfb656e2d41",
    "name": "Sound Wave No Arc",
    "order_number": 507,
    "description": "<p>I want to do the same, but less paint and arc (!) ...</p>",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0be820dc-6ff8-4893-861a-adfb656e2d41%2F5f5fce7a-ed21-4ca9-95ea-219ae4d54f7d?alt=media&token=e1115418-c0ca-462d-ad86-f3b345087b38",
        "reference": "/stroke_photos/0be820dc-6ff8-4893-861a-adfb656e2d41/5f5fce7a-ed21-4ca9-95ea-219ae4d54f7d"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0be820dc-6ff8-4893-861a-adfb656e2d41%2F600x600%2F5f5fce7a-ed21-4ca9-95ea-219ae4d54f7d_600x600?alt=media&token=8ce55594-59e4-4ee3-99bd-8e62ea230a26",
        "reference": "stroke_photos/0be820dc-6ff8-4893-861a-adfb656e2d41/600x600/5f5fce7a-ed21-4ca9-95ea-219ae4d54f7d_600x600"
      }
    },
    "tool": null,
    "paper": {
      "gsm": null,
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "brand_model": {},
      "original_size": "A4",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null
    },
    "order_id": 0
  },
  {
    "order_number": 577,
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "ref": "20170703",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "price": 0.4,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "name": null,
      "brand": {}
    },
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/0c19c961-0f5f-4675-afd9-d8a8c2e23e27/ca1e4a15-2473-47ba-bfb0-a0719b1fa7ab",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0c19c961-0f5f-4675-afd9-d8a8c2e23e27%2Fca1e4a15-2473-47ba-bfb0-a0719b1fa7ab?alt=media&token=6e95f84a-211c-4720-b492-45a6d7721c30"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/0c19c961-0f5f-4675-afd9-d8a8c2e23e27/600x600/ca1e4a15-2473-47ba-bfb0-a0719b1fa7ab_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0c19c961-0f5f-4675-afd9-d8a8c2e23e27%2F600x600%2Fca1e4a15-2473-47ba-bfb0-a0719b1fa7ab_600x600?alt=media&token=3aad5387-3c2e-4e4f-9125-d09e679da5e0"
      }
    },
    "name": "Blank",
    "tool": null,
    "description": "",
    "id": "0c19c961-0f5f-4675-afd9-d8a8c2e23e27"
  },
  {
    "order_number": 1172,
    "name": "",
    "description": "",
    "id": "0c1de040-aefb-4472-815e-8f066bfc6bc6"
  },
  {
    "description": "",
    "order_number": 946,
    "name": "Blank",
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "original_size": "A4",
      "brand": {},
      "name": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "brand_model": {}
    },
    "id": "0c3ae2f1-1cf6-4e71-ae36-ab1d2ee13006",
    "order_id": 0
  },
  {
    "name": "Blank",
    "id": "0c59449e-4b26-4b3f-a7b5-3861c12edfb8",
    "order_number": 557,
    "paper": {
      "name": null,
      "original_size": "A7",
      "price": 0.4,
      "ref": "20170703",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "250"
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/0c59449e-4b26-4b3f-a7b5-3861c12edfb8/600x600/0ce665c6-fcda-41a0-9ab1-84007e978ce7_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0c59449e-4b26-4b3f-a7b5-3861c12edfb8%2F600x600%2F0ce665c6-fcda-41a0-9ab1-84007e978ce7_600x600?alt=media&token=0dc158da-a388-4105-8756-b96affa9ad9a"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0c59449e-4b26-4b3f-a7b5-3861c12edfb8%2F0ce665c6-fcda-41a0-9ab1-84007e978ce7?alt=media&token=8b54578f-2bde-4bba-a34d-1e6fd2583df2",
        "reference": "/stroke_photos/0c59449e-4b26-4b3f-a7b5-3861c12edfb8/0ce665c6-fcda-41a0-9ab1-84007e978ce7"
      }
    },
    "tool": null,
    "description": "",
    "order_id": 0
  },
  {
    "name": "Two Points 1",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/0c768c8b-7287-4b8d-992f-8af5bdd2e846/600x600/a2dd6bb3-d283-46dc-ad72-2d4b23955684_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0c768c8b-7287-4b8d-992f-8af5bdd2e846%2F600x600%2Fa2dd6bb3-d283-46dc-ad72-2d4b23955684_600x600?alt=media&token=c8a41473-7ac5-45a8-bf2e-0e48ad14a31b"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0c768c8b-7287-4b8d-992f-8af5bdd2e846%2Fa2dd6bb3-d283-46dc-ad72-2d4b23955684?alt=media&token=4f279233-0e4f-4a5a-8b6d-e26bf62d8a96",
        "reference": "/stroke_photos/0c768c8b-7287-4b8d-992f-8af5bdd2e846/a2dd6bb3-d283-46dc-ad72-2d4b23955684"
      }
    },
    "description": "",
    "order_id": 0,
    "tool": null,
    "id": "0c768c8b-7287-4b8d-992f-8af5bdd2e846",
    "order_number": 118,
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "original_size": "A7",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "brand": {},
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "name": null,
      "ref": "20180107",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    }
  },
  {
    "description": "",
    "order_number": 1053,
    "name": "",
    "id": "0cc1e0f3-1284-4b35-bcfd-66abe8845ff7"
  },
  {
    "description": "",
    "id": "0ce28e55-8114-4ef4-85d6-581411a42cca",
    "paper": {
      "price": 19.3,
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "gsm": "640",
      "original_size": "75x105cm",
      "ref": "30075105",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "name": "tinto"
    },
    "tool": null,
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/0ce28e55-8114-4ef4-85d6-581411a42cca/600x600/542e7b2e-5523-4b13-b7a9-4801fcf95700_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0ce28e55-8114-4ef4-85d6-581411a42cca%2F600x600%2F542e7b2e-5523-4b13-b7a9-4801fcf95700_600x600?alt=media&token=4ff9ee14-651d-4188-9379-aa8af02e0be7"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0ce28e55-8114-4ef4-85d6-581411a42cca%2F542e7b2e-5523-4b13-b7a9-4801fcf95700?alt=media&token=15429844-b01a-4937-9638-bf03ec927a1e",
        "reference": "/stroke_photos/0ce28e55-8114-4ef4-85d6-581411a42cca/542e7b2e-5523-4b13-b7a9-4801fcf95700"
      }
    },
    "name": "Blank",
    "order_number": 675
  },
  {
    "tool": null,
    "description": "",
    "name": "Blank",
    "id": "0ce33b3c-55ef-4c71-97a0-e67805e6a906",
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "name": "tinto",
      "brand_model": {
        "name": "Artistico",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "ref": "30075105",
      "gsm": "640",
      "price": 19.3,
      "original_size": "75x105cm",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      }
    },
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/0ce33b3c-55ef-4c71-97a0-e67805e6a906/50a3c165-f71c-4444-8644-c1c91a14dee5",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0ce33b3c-55ef-4c71-97a0-e67805e6a906%2F50a3c165-f71c-4444-8644-c1c91a14dee5?alt=media&token=79fd8598-296d-49c1-9549-974a92946271"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/0ce33b3c-55ef-4c71-97a0-e67805e6a906/600x600/50a3c165-f71c-4444-8644-c1c91a14dee5_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0ce33b3c-55ef-4c71-97a0-e67805e6a906%2F600x600%2F50a3c165-f71c-4444-8644-c1c91a14dee5_600x600?alt=media&token=305b7079-4869-4dfa-bde9-1839b3724758"
      }
    },
    "order_number": 658
  },
  {
    "name": "An Absolute With Fluffy Tail",
    "paper": {
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "ref": "77725080001",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "price": 1.35,
      "gsm": "250",
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "original_size": "A7"
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0d5d1984-f511-4621-8fc1-7f4b1d97edc2%2F6693c2bf-207e-405b-9944-c5b43fc462da?alt=media&token=ad0f3a90-27b0-4a48-b64f-4899663d40b5",
        "reference": "/stroke_photos/0d5d1984-f511-4621-8fc1-7f4b1d97edc2/6693c2bf-207e-405b-9944-c5b43fc462da"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/0d5d1984-f511-4621-8fc1-7f4b1d97edc2/600x600/6693c2bf-207e-405b-9944-c5b43fc462da_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0d5d1984-f511-4621-8fc1-7f4b1d97edc2%2F600x600%2F6693c2bf-207e-405b-9944-c5b43fc462da_600x600?alt=media&token=5cdb3b01-d4d1-4ed3-8857-328ff7c7b15d"
      }
    },
    "id": "0d5d1984-f511-4621-8fc1-7f4b1d97edc2",
    "description": "",
    "order_id": 0,
    "order_number": 206,
    "tool": null
  },
  {
    "order_number": 1074,
    "description": "",
    "name": "",
    "id": "0d82efe1-a170-4666-ab38-c65aed14f6a4"
  },
  {
    "description": "<p>Still so far...</p>",
    "order_id": 0,
    "name": "Upside Swirly 3",
    "id": "0d8c67d0-71e8-4650-b488-ed521c981a3e",
    "order_number": 328,
    "paper": {
      "price": null,
      "gsm": "250",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A7",
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "brand": {},
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      }
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0d8c67d0-71e8-4650-b488-ed521c981a3e%2F600x600%2Ffd709095-f627-49cc-b011-0d6a3cde7341_600x600?alt=media&token=f76db1ed-34a4-4984-b16e-7ab739eae4cc",
        "reference": "stroke_photos/0d8c67d0-71e8-4650-b488-ed521c981a3e/600x600/fd709095-f627-49cc-b011-0d6a3cde7341_600x600"
      },
      "original": {
        "reference": "/stroke_photos/0d8c67d0-71e8-4650-b488-ed521c981a3e/fd709095-f627-49cc-b011-0d6a3cde7341",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0d8c67d0-71e8-4650-b488-ed521c981a3e%2Ffd709095-f627-49cc-b011-0d6a3cde7341?alt=media&token=fef9c722-31b9-4f53-ae75-e54368a803a8"
      }
    },
    "tool": null
  },
  {
    "paper": {
      "original_size": "A7",
      "color": {
        "hex": "#f5f5f5",
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "gsm": "250",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "ref": "70025010030",
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {},
      "price": null
    },
    "name": "Blank",
    "order_number": 612,
    "id": "0dc24b3e-326d-4fa8-8b04-ba1f54bf7586",
    "description": "",
    "tool": null,
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/0dc24b3e-326d-4fa8-8b04-ba1f54bf7586/600x600/8fa2ff82-56d4-4cf7-82cf-7c8083413f4b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0dc24b3e-326d-4fa8-8b04-ba1f54bf7586%2F600x600%2F8fa2ff82-56d4-4cf7-82cf-7c8083413f4b_600x600?alt=media&token=afc4516c-1c2d-4782-a5fc-6eaef5bab17d"
      },
      "original": {
        "reference": "/stroke_photos/0dc24b3e-326d-4fa8-8b04-ba1f54bf7586/8fa2ff82-56d4-4cf7-82cf-7c8083413f4b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0dc24b3e-326d-4fa8-8b04-ba1f54bf7586%2F8fa2ff82-56d4-4cf7-82cf-7c8083413f4b?alt=media&token=bf0d4578-421c-4d87-b404-2d4a8e374946"
      }
    }
  },
  {
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "ref": "20170703",
      "original_size": "A7",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "gsm": "250",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "price": 0.4,
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    },
    "description": "",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0dc4e1c9-4d30-4602-90b1-ceed03dd00cb%2Fe040f9e8-7780-4b2a-8f45-25fa5fb65edf?alt=media&token=a8589568-2fbf-479f-9813-ea82cbc7a992",
        "reference": "/stroke_photos/0dc4e1c9-4d30-4602-90b1-ceed03dd00cb/e040f9e8-7780-4b2a-8f45-25fa5fb65edf"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0dc4e1c9-4d30-4602-90b1-ceed03dd00cb%2F600x600%2Fe040f9e8-7780-4b2a-8f45-25fa5fb65edf_600x600?alt=media&token=a31ca5b6-05f9-4280-9d30-10236aa8193a",
        "reference": "stroke_photos/0dc4e1c9-4d30-4602-90b1-ceed03dd00cb/600x600/e040f9e8-7780-4b2a-8f45-25fa5fb65edf_600x600"
      }
    },
    "order_number": 712,
    "name": "Blank",
    "order_id": 0,
    "id": "0dc4e1c9-4d30-4602-90b1-ceed03dd00cb",
    "tool": null
  },
  {
    "order_id": 0,
    "order_number": 642,
    "photos": {
      "original": {
        "reference": "/stroke_photos/0ddea3c7-2f43-4949-a6a7-5239ed23221d/b36da623-42eb-415e-b1e1-7ee0d499f15f",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0ddea3c7-2f43-4949-a6a7-5239ed23221d%2Fb36da623-42eb-415e-b1e1-7ee0d499f15f?alt=media&token=42663f38-c22c-40f0-aa91-994ed1361058"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0ddea3c7-2f43-4949-a6a7-5239ed23221d%2F600x600%2Fb36da623-42eb-415e-b1e1-7ee0d499f15f_600x600?alt=media&token=aaccad4c-25a7-47cd-94cb-0f0bacfb0578",
        "reference": "stroke_photos/0ddea3c7-2f43-4949-a6a7-5239ed23221d/600x600/b36da623-42eb-415e-b1e1-7ee0d499f15f_600x600"
      }
    },
    "id": "0ddea3c7-2f43-4949-a6a7-5239ed23221d",
    "name": "Blank",
    "tool": null,
    "paper": {
      "ref": "70025010030",
      "gsm": "250",
      "brand_model": {},
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "name": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "original_size": "A7",
      "price": null,
      "color": {
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl"
      }
    },
    "description": ""
  },
  {
    "description": "<p>I knew it would look tiny but didn't think it would be that ugly</p>",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/0df51352-f7ab-4e67-9c24-b2a3a6cfa604/600x600/43f12cd3-a637-43f2-a7a5-3cdcc637835b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0df51352-f7ab-4e67-9c24-b2a3a6cfa604%2F600x600%2F43f12cd3-a637-43f2-a7a5-3cdcc637835b_600x600?alt=media&token=5aa0bc47-d3c4-4839-a54e-72116aaf2903"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0df51352-f7ab-4e67-9c24-b2a3a6cfa604%2F43f12cd3-a637-43f2-a7a5-3cdcc637835b?alt=media&token=eeba80a2-f6a2-4cb7-afca-0ea385c561a3",
        "reference": "/stroke_photos/0df51352-f7ab-4e67-9c24-b2a3a6cfa604/43f12cd3-a637-43f2-a7a5-3cdcc637835b"
      }
    },
    "purchase": {
      "buyer": {
        "email": "nikola.b.petrov@gmail.com",
        "lastName": "Petrov",
        "firstName": "Nikola"
      },
      "anonymous": false,
      "internalDescription": "",
      "date": {
        "seconds": 1677878120,
        "nanoseconds": 840000000
      },
      "status": "bought"
    },
    "name": "Tiny Swirly",
    "paper": {
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "brand": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "name": null,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "price": null,
      "original_size": "A7",
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "id": "0df51352-f7ab-4e67-9c24-b2a3a6cfa604",
    "order_number": 329,
    "order_id": 0
  },
  {
    "order_number": 526,
    "order_id": 0,
    "description": "<p>(!) Now that's another story. Phew, I was wondering what is going on</p>",
    "tool": null,
    "id": "0e53730a-32bf-40a3-8b49-09c7db6d02db",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0e53730a-32bf-40a3-8b49-09c7db6d02db%2F600x600%2F045a14c2-064a-46d9-9f47-a96d8ebd7b51_600x600?alt=media&token=fec25110-9f84-4596-b84e-7f75b27d350c",
        "reference": "stroke_photos/0e53730a-32bf-40a3-8b49-09c7db6d02db/600x600/045a14c2-064a-46d9-9f47-a96d8ebd7b51_600x600"
      },
      "original": {
        "reference": "/stroke_photos/0e53730a-32bf-40a3-8b49-09c7db6d02db/045a14c2-064a-46d9-9f47-a96d8ebd7b51",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0e53730a-32bf-40a3-8b49-09c7db6d02db%2F045a14c2-064a-46d9-9f47-a96d8ebd7b51?alt=media&token=47079031-03bb-44b9-a137-ae44a7972ed3"
      }
    },
    "name": "Silver-On-Black Regular 6",
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "name": null,
      "brand": {},
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": null,
      "brand_model": {},
      "ref": null
    }
  },
  {
    "tool": null,
    "order_id": 0,
    "name": "Water",
    "paper": {
      "ref": "77725080001",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso"
      },
      "price": 1.35,
      "original_size": "A7",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "name": null,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      }
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0e6d27b9-0e62-4f19-aac6-9cee63378c61%2F864aa765-3d28-41f0-b225-6c8c79b9607e?alt=media&token=7fabb0f0-b60c-485d-b183-171e91f0b681",
        "reference": "/stroke_photos/0e6d27b9-0e62-4f19-aac6-9cee63378c61/864aa765-3d28-41f0-b225-6c8c79b9607e"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0e6d27b9-0e62-4f19-aac6-9cee63378c61%2F600x600%2F864aa765-3d28-41f0-b225-6c8c79b9607e_600x600?alt=media&token=699fafcd-35bf-4753-b673-dc25350c7fe9",
        "reference": "stroke_photos/0e6d27b9-0e62-4f19-aac6-9cee63378c61/600x600/864aa765-3d28-41f0-b225-6c8c79b9607e_600x600"
      }
    },
    "id": "0e6d27b9-0e62-4f19-aac6-9cee63378c61",
    "order_number": 414,
    "description": "It seems the paint is dry. Time to experiment with watered down paint."
  },
  {
    "order_number": 1029,
    "description": "",
    "name": "",
    "id": "0e9f261e-33b1-41bb-95dc-6f64ddb636ed"
  },
  {
    "id": "0ec0f93c-d43a-46dd-af76-b8d3d89da1c1",
    "name": "Blank",
    "order_id": 0,
    "paper": {
      "gsm": "250",
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A7",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "20170703",
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": 0.4
    },
    "order_number": 805,
    "description": ""
  },
  {
    "order_number": 1007,
    "description": "",
    "name": "",
    "id": "0ee340cf-d82c-4ed4-8466-e87810f910e9"
  },
  {
    "order_id": 0,
    "id": "0f3b74ac-1635-484c-bd6a-3f47190895fd",
    "paper": {
      "gsm": "250",
      "brand": {},
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "name": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A7",
      "ref": "70025010030",
      "brand_model": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null
    },
    "order_number": 615,
    "tool": null,
    "description": "",
    "name": "Blank",
    "photos": {
      "original": {
        "reference": "/stroke_photos/0f3b74ac-1635-484c-bd6a-3f47190895fd/e1c812a7-c615-4208-b1cd-d74e2dc12efd",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0f3b74ac-1635-484c-bd6a-3f47190895fd%2Fe1c812a7-c615-4208-b1cd-d74e2dc12efd?alt=media&token=571baedb-cdc7-45e9-ac84-9dce3b508311"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/0f3b74ac-1635-484c-bd6a-3f47190895fd/600x600/e1c812a7-c615-4208-b1cd-d74e2dc12efd_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0f3b74ac-1635-484c-bd6a-3f47190895fd%2F600x600%2Fe1c812a7-c615-4208-b1cd-d74e2dc12efd_600x600?alt=media&token=513a878a-29ca-437f-8e48-19e833345a35"
      }
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1033,
    "id": "0f61bf5f-e11a-4158-874d-9bafaadb293d"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1080,
    "id": "0f8b28ab-3fe9-4d3c-9da2-6e4096dc3ad0"
  },
  {
    "id": "0f93e7a8-92cf-4802-9d71-d9f4d35331e9",
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "gsm": "250",
      "price": 1.35,
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "ref": "77725080001",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      }
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0f93e7a8-92cf-4802-9d71-d9f4d35331e9%2Fd7793f16-9a35-4ee7-8f0d-cb0e1626b1ee?alt=media&token=4247783b-62d1-462f-9b7a-f5abd90f2e63",
        "reference": "/stroke_photos/0f93e7a8-92cf-4802-9d71-d9f4d35331e9/d7793f16-9a35-4ee7-8f0d-cb0e1626b1ee"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0f93e7a8-92cf-4802-9d71-d9f4d35331e9%2F600x600%2Fd7793f16-9a35-4ee7-8f0d-cb0e1626b1ee_600x600?alt=media&token=82c18f94-7c52-4258-9222-d16cead2af2d",
        "reference": "stroke_photos/0f93e7a8-92cf-4802-9d71-d9f4d35331e9/600x600/d7793f16-9a35-4ee7-8f0d-cb0e1626b1ee_600x600"
      }
    },
    "description": "",
    "name": "Blank",
    "order_number": 181,
    "order_id": 0,
    "tool": null
  },
  {
    "paper": {
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl",
        "hex": "#f5f5f5"
      },
      "name": null,
      "gsm": "250",
      "brand_model": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "70025010030",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A7",
      "brand": {},
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "order_number": 632,
    "tool": null,
    "description": "",
    "order_id": 0,
    "id": "0fcd1368-4809-44d5-ab96-0e7f36e34fe3",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0fcd1368-4809-44d5-ab96-0e7f36e34fe3%2F8e2f8417-cb9c-4e90-966b-afe39292ef19?alt=media&token=009a289d-9802-49ad-a812-a37b0dd18d96",
        "reference": "/stroke_photos/0fcd1368-4809-44d5-ab96-0e7f36e34fe3/8e2f8417-cb9c-4e90-966b-afe39292ef19"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/0fcd1368-4809-44d5-ab96-0e7f36e34fe3/600x600/8e2f8417-cb9c-4e90-966b-afe39292ef19_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0fcd1368-4809-44d5-ab96-0e7f36e34fe3%2F600x600%2F8e2f8417-cb9c-4e90-966b-afe39292ef19_600x600?alt=media&token=f56bb057-9d81-4015-a5fd-6d0e720340c1"
      }
    },
    "name": "Blank"
  },
  {
    "order_id": 0,
    "id": "0fcd9a56-82cb-4aa8-8e42-8747871190cd",
    "paper": {
      "paper_surface": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "price": null,
      "ref": "44202129",
      "name": "",
      "original_size": "A4",
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "gsm": "200"
    },
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/0fcd9a56-82cb-4aa8-8e42-8747871190cd/809621dd-90b6-42e1-9df0-32c17356629c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0fcd9a56-82cb-4aa8-8e42-8747871190cd%2F809621dd-90b6-42e1-9df0-32c17356629c?alt=media&token=85bac9cd-28dc-4d56-b5a3-b8081fc363b8"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F0fcd9a56-82cb-4aa8-8e42-8747871190cd%2F600x600%2F809621dd-90b6-42e1-9df0-32c17356629c_600x600?alt=media&token=e320e61a-af61-4fb2-9c55-67e3ed2b7ea0",
        "reference": "stroke_photos/0fcd9a56-82cb-4aa8-8e42-8747871190cd/600x600/809621dd-90b6-42e1-9df0-32c17356629c_600x600"
      }
    },
    "tool": {},
    "order_number": 9,
    "name": "Double Stroke"
  },
  {
    "order_number": 286,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/102a643b-85a0-4d42-9c9e-4b6f168808ba/600x600/0e20ffd2-9a75-4d35-a438-4513f4e3bb1a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F102a643b-85a0-4d42-9c9e-4b6f168808ba%2F600x600%2F0e20ffd2-9a75-4d35-a438-4513f4e3bb1a_600x600?alt=media&token=4d7bca6b-44be-4476-a81a-879025b91886"
      },
      "original": {
        "reference": "/stroke_photos/102a643b-85a0-4d42-9c9e-4b6f168808ba/0e20ffd2-9a75-4d35-a438-4513f4e3bb1a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F102a643b-85a0-4d42-9c9e-4b6f168808ba%2F0e20ffd2-9a75-4d35-a438-4513f4e3bb1a?alt=media&token=da4ac472-af3f-4d96-8196-d2a53ddd8f0d"
      }
    },
    "tool": null,
    "paper": {
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "gsm": "250",
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "original_size": "A7",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "20180107",
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86"
    },
    "id": "102a643b-85a0-4d42-9c9e-4b6f168808ba",
    "order_id": 0,
    "name": "Heavy but Pointing to the Sky",
    "description": "<p>Curve is off. Too black too</p>"
  },
  {
    "tool": null,
    "description": "<p>Back to making double press</p>",
    "id": "10758dee-5bbc-44d7-b30c-7cb072758067",
    "order_number": 308,
    "name": "Not Okay",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F10758dee-5bbc-44d7-b30c-7cb072758067%2F600x600%2F38bfb3f0-2fde-43fe-923f-c2ba69386286_600x600?alt=media&token=cd11bf46-1a84-407c-bb9e-58730b97121c",
        "reference": "stroke_photos/10758dee-5bbc-44d7-b30c-7cb072758067/600x600/38bfb3f0-2fde-43fe-923f-c2ba69386286_600x600"
      },
      "original": {
        "reference": "/stroke_photos/10758dee-5bbc-44d7-b30c-7cb072758067/38bfb3f0-2fde-43fe-923f-c2ba69386286",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F10758dee-5bbc-44d7-b30c-7cb072758067%2F38bfb3f0-2fde-43fe-923f-c2ba69386286?alt=media&token=f0d7c38e-2b1d-4a02-b390-82894a524b3b"
      }
    },
    "paper": {
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20180107",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": null,
      "gsm": "250",
      "name": null,
      "original_size": "A7",
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    }
  },
  {
    "order_number": 345,
    "tool": null,
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/10aacece-aed9-4912-95b1-68a7624bc2dc/600x600/448a9bd4-8df8-460a-98eb-940f8271c7aa_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F10aacece-aed9-4912-95b1-68a7624bc2dc%2F600x600%2F448a9bd4-8df8-460a-98eb-940f8271c7aa_600x600?alt=media&token=16b681eb-652b-4503-83b7-7259f1a523c5"
      },
      "original": {
        "reference": "/stroke_photos/10aacece-aed9-4912-95b1-68a7624bc2dc/448a9bd4-8df8-460a-98eb-940f8271c7aa",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F10aacece-aed9-4912-95b1-68a7624bc2dc%2F448a9bd4-8df8-460a-98eb-940f8271c7aa?alt=media&token=5e97df5f-8edf-44e4-8ce1-1e2d325d6ae7"
      }
    },
    "description": "<p>I wanted to to variability but something got messed up really much</p>",
    "name": "Variable Attempt Messup",
    "paper": {
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "ref": "20180107",
      "gsm": "250",
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "price": null,
      "brand": {},
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "id": "10aacece-aed9-4912-95b1-68a7624bc2dc"
  },
  {
    "description": "<p>Just really fading</p>",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/10cfe1e5-1def-4a16-a221-adf5434849e0/600x600/831e30f5-ad3c-445c-82cb-67afb27622e2_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F10cfe1e5-1def-4a16-a221-adf5434849e0%2F600x600%2F831e30f5-ad3c-445c-82cb-67afb27622e2_600x600?alt=media&token=463f443f-9ede-46ce-a3fc-85b55f5b8ad4"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F10cfe1e5-1def-4a16-a221-adf5434849e0%2F831e30f5-ad3c-445c-82cb-67afb27622e2?alt=media&token=9141c61d-7ca0-4efb-8a2d-6d340fecdf06",
        "reference": "/stroke_photos/10cfe1e5-1def-4a16-a221-adf5434849e0/831e30f5-ad3c-445c-82cb-67afb27622e2"
      }
    },
    "order_number": 303,
    "id": "10cfe1e5-1def-4a16-a221-adf5434849e0",
    "tool": null,
    "name": "Hungry Sis",
    "order_id": 0,
    "paper": {
      "original_size": "A7",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "ref": "20180107",
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250"
    }
  },
  {
    "order_id": 0,
    "name": "Blank",
    "tool": null,
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null,
      "brand_model": {},
      "original_size": "A7",
      "brand": {},
      "name": null,
      "gsm": "250",
      "ref": "70025010030",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    },
    "order_number": 581,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F10d5badf-3df2-4b2d-90b5-37abe9ad52b1%2Fb8af5820-90a1-4665-84bd-8b833aea8d7d?alt=media&token=a411b234-cb7b-4480-b97e-a920ed6fb2ff",
        "reference": "/stroke_photos/10d5badf-3df2-4b2d-90b5-37abe9ad52b1/b8af5820-90a1-4665-84bd-8b833aea8d7d"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/10d5badf-3df2-4b2d-90b5-37abe9ad52b1/600x600/b8af5820-90a1-4665-84bd-8b833aea8d7d_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F10d5badf-3df2-4b2d-90b5-37abe9ad52b1%2F600x600%2Fb8af5820-90a1-4665-84bd-8b833aea8d7d_600x600?alt=media&token=b7f1f2c1-76af-4760-8909-635bae211b62"
      }
    },
    "description": "",
    "id": "10d5badf-3df2-4b2d-90b5-37abe9ad52b1"
  },
  {
    "id": "10df8c6e-34bd-464c-b44b-b1b0dfd0520e",
    "order_id": 0,
    "name": "Blank",
    "photos": {
      "original": {
        "reference": "/stroke_photos/10df8c6e-34bd-464c-b44b-b1b0dfd0520e/41f41052-d09e-4eae-928e-c66db540d8ad",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F10df8c6e-34bd-464c-b44b-b1b0dfd0520e%2F41f41052-d09e-4eae-928e-c66db540d8ad?alt=media&token=8a18912b-7db0-488c-8a46-c50211146ca0"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F10df8c6e-34bd-464c-b44b-b1b0dfd0520e%2F600x600%2F41f41052-d09e-4eae-928e-c66db540d8ad_600x600?alt=media&token=b62ac6a1-2b72-4201-8924-a835c6de5e00",
        "reference": "stroke_photos/10df8c6e-34bd-464c-b44b-b1b0dfd0520e/600x600/41f41052-d09e-4eae-928e-c66db540d8ad_600x600"
      }
    },
    "paper": {
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "brand_model": {},
      "original_size": "A7",
      "price": null,
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "ref": "70025010030",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "order_number": 622,
    "description": "",
    "tool": null
  },
  {
    "name": "",
    "order_number": 1022,
    "description": "",
    "id": "118501c2-3086-43d5-aaa5-7d9886ac78b8"
  },
  {
    "description": "",
    "order_number": 1102,
    "name": "",
    "id": "11bc2182-1804-4896-8716-45d3c4891896"
  },
  {
    "name": "",
    "order_number": 1220,
    "description": "",
    "id": "11c8664a-a2d2-47ff-a4e3-cf06f18429f7"
  },
  {
    "name": "",
    "order_number": 1027,
    "description": "",
    "id": "11d43b36-80bc-4560-823a-d8b9bf10888c"
  },
  {
    "order_id": 0,
    "order_number": 954,
    "description": "",
    "id": "121918e2-5ca9-431d-ad50-b3332e3b809c",
    "name": "Blank",
    "paper": {
      "name": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A4",
      "brand": {},
      "price": null,
      "brand_model": {},
      "ref": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "gsm": null
    }
  },
  {
    "order_id": 0,
    "paper": {
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso"
      },
      "price": 1.35,
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "original_size": "A7",
      "name": null
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F122d31f4-1f54-4d13-a7a3-ed145d70060c%2F600x600%2F6a24df1c-6f65-4a46-b2ac-34678ded0fe7_600x600?alt=media&token=80f1b6d7-f542-47d7-801d-8e44500771e9",
        "reference": "stroke_photos/122d31f4-1f54-4d13-a7a3-ed145d70060c/600x600/6a24df1c-6f65-4a46-b2ac-34678ded0fe7_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F122d31f4-1f54-4d13-a7a3-ed145d70060c%2F6a24df1c-6f65-4a46-b2ac-34678ded0fe7?alt=media&token=f80385b0-9cc0-4a30-9f77-b39beff21e74",
        "reference": "/stroke_photos/122d31f4-1f54-4d13-a7a3-ed145d70060c/6a24df1c-6f65-4a46-b2ac-34678ded0fe7"
      }
    },
    "order_number": 425,
    "id": "122d31f4-1f54-4d13-a7a3-ed145d70060c",
    "tool": null,
    "description": "What happens if I roll the round brush around the base in a circle? Splodge with hole.",
    "name": "Holed Splodge"
  },
  {
    "paper": {
      "brand_model": {},
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl"
      },
      "original_size": "A7",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "70025010030",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {}
    },
    "id": "123a7d26-9729-4507-a4f0-74f555e612bf",
    "order_number": 846,
    "name": "Blank",
    "order_id": 0,
    "description": ""
  },
  {
    "id": "12add86f-7831-4f92-816f-f2d58e95b043",
    "name": "Piece by Piece",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F12add86f-7831-4f92-816f-f2d58e95b043%2Fbb3511ad-3a67-47ae-9b36-aeacc8238ca6?alt=media&token=f8048348-3fc7-4341-9f49-11cab0adcbc4",
        "reference": "/stroke_photos/12add86f-7831-4f92-816f-f2d58e95b043/bb3511ad-3a67-47ae-9b36-aeacc8238ca6"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F12add86f-7831-4f92-816f-f2d58e95b043%2F600x600%2Fbb3511ad-3a67-47ae-9b36-aeacc8238ca6_600x600?alt=media&token=d1a7e01b-575d-43ea-b7cd-d1fb69003dec",
        "reference": "stroke_photos/12add86f-7831-4f92-816f-f2d58e95b043/600x600/bb3511ad-3a67-47ae-9b36-aeacc8238ca6_600x600"
      }
    },
    "description": "<p>How is this possible? I didn't expect to be so bad</p>",
    "tool": null,
    "order_id": 0,
    "paper": {
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "name": null,
      "brand": {},
      "gsm": "250",
      "ref": "20180107",
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "order_number": 309
  },
  {
    "name": "",
    "order_number": 1204,
    "description": "",
    "id": "12bc0592-df72-47d8-b0c3-601be493ca00"
  },
  {
    "name": "Working on Ending",
    "order_number": 84,
    "description": "",
    "paper": {
      "original_size": "A4",
      "paper_surface": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": null,
      "gsm": "200",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "44202129",
      "name": "",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand_model": {
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    },
    "order_id": 0,
    "id": "12bfd74d-db56-4f54-970d-0daa9b0f99bd",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/12bfd74d-db56-4f54-970d-0daa9b0f99bd/600x600/ffb9aead-5770-47ac-b035-3c95efbbd454_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F12bfd74d-db56-4f54-970d-0daa9b0f99bd%2F600x600%2Fffb9aead-5770-47ac-b035-3c95efbbd454_600x600?alt=media&token=a7657caa-1b04-4685-bb8e-c31cbe4bb673"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F12bfd74d-db56-4f54-970d-0daa9b0f99bd%2Fffb9aead-5770-47ac-b035-3c95efbbd454?alt=media&token=cea77b40-9437-4d8a-8af5-202f8ed96ccb",
        "reference": "/stroke_photos/12bfd74d-db56-4f54-970d-0daa9b0f99bd/ffb9aead-5770-47ac-b035-3c95efbbd454"
      }
    }
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/12c6b2ee-1168-42ef-861c-b1d341e313a3/90b71204-07fd-4f3e-b82f-8fbe9589fef2",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F12c6b2ee-1168-42ef-861c-b1d341e313a3%2F90b71204-07fd-4f3e-b82f-8fbe9589fef2?alt=media&token=f765ebf1-910f-41bf-8f48-45ab8d1767a4"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/12c6b2ee-1168-42ef-861c-b1d341e313a3/600x600/90b71204-07fd-4f3e-b82f-8fbe9589fef2_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F12c6b2ee-1168-42ef-861c-b1d341e313a3%2F600x600%2F90b71204-07fd-4f3e-b82f-8fbe9589fef2_600x600?alt=media&token=2e4faad5-3d7c-4a6a-bfe8-faa1e1ecd572"
      }
    },
    "paper": {
      "price": 0.4,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "20170703",
      "original_size": "A7",
      "gsm": "250",
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      }
    },
    "order_number": 558,
    "description": "",
    "order_id": 0,
    "id": "12c6b2ee-1168-42ef-861c-b1d341e313a3",
    "name": "Blank",
    "tool": null
  },
  {
    "description": "",
    "name": "",
    "order_number": 1206,
    "id": "12f6b07e-3c00-4b12-b2c1-191e9b366d34"
  },
  {
    "order_number": 1028,
    "description": "",
    "name": "",
    "id": "13280585-0f2e-4d4a-8a21-bcdb8daa8207"
  },
  {
    "name": "Blank",
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "price": 1.35,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "original_size": "A7"
    },
    "id": "135da6ae-5e57-4345-aac7-6abe7016d06d",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F135da6ae-5e57-4345-aac7-6abe7016d06d%2F600x600%2F6544d7a5-0f7b-4bd5-b861-7b26332b5c8d_600x600?alt=media&token=3505a436-64c1-4b04-84b4-e32e42584d08",
        "reference": "stroke_photos/135da6ae-5e57-4345-aac7-6abe7016d06d/600x600/6544d7a5-0f7b-4bd5-b861-7b26332b5c8d_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F135da6ae-5e57-4345-aac7-6abe7016d06d%2F6544d7a5-0f7b-4bd5-b861-7b26332b5c8d?alt=media&token=de423c01-339b-4bbd-8bd8-a5578e13ff5c",
        "reference": "/stroke_photos/135da6ae-5e57-4345-aac7-6abe7016d06d/6544d7a5-0f7b-4bd5-b861-7b26332b5c8d"
      }
    },
    "tool": null,
    "order_number": 165,
    "description": ""
  },
  {
    "name": "",
    "description": "",
    "order_number": 1039,
    "id": "13a9cbe9-ccf3-4214-bbf2-ecdfad8c3c48"
  },
  {
    "order_id": 0,
    "id": "1441530f-d3af-4d9f-be56-4bc400f4d0ba",
    "tool": null,
    "name": "Blank",
    "paper": {
      "original_size": "A7",
      "price": null,
      "brand": {},
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "70025010030",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "name": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {}
    },
    "order_number": 594,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1441530f-d3af-4d9f-be56-4bc400f4d0ba%2F156f2e73-2de9-4cc6-adca-016890636e5b?alt=media&token=10536cd4-b2ed-4c9a-87b7-141b773c12e4",
        "reference": "/stroke_photos/1441530f-d3af-4d9f-be56-4bc400f4d0ba/156f2e73-2de9-4cc6-adca-016890636e5b"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1441530f-d3af-4d9f-be56-4bc400f4d0ba%2F600x600%2F156f2e73-2de9-4cc6-adca-016890636e5b_600x600?alt=media&token=fd41fcaf-0288-40f4-adfe-55439719f60b",
        "reference": "stroke_photos/1441530f-d3af-4d9f-be56-4bc400f4d0ba/600x600/156f2e73-2de9-4cc6-adca-016890636e5b_600x600"
      }
    },
    "description": ""
  },
  {
    "order_number": 1170,
    "description": "",
    "name": "",
    "id": "14c8b88d-8605-4657-929c-a4383c0423dd"
  },
  {
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F14fe43af-ad6e-4fe1-a4b3-0b985936694f%2F600x600%2Fb4e3c22c-457c-497f-a135-800627cd8a0b_600x600?alt=media&token=4200c90f-b922-46b8-8b52-3cd9652acc29",
        "reference": "stroke_photos/14fe43af-ad6e-4fe1-a4b3-0b985936694f/600x600/b4e3c22c-457c-497f-a135-800627cd8a0b_600x600"
      },
      "original": {
        "reference": "/stroke_photos/14fe43af-ad6e-4fe1-a4b3-0b985936694f/b4e3c22c-457c-497f-a135-800627cd8a0b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F14fe43af-ad6e-4fe1-a4b3-0b985936694f%2Fb4e3c22c-457c-497f-a135-800627cd8a0b?alt=media&token=3d079268-42d9-4d5a-b453-a4c17a315a8c"
      }
    },
    "paper": {
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "gsm": "200",
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "44202129",
      "paper_surface": {},
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4",
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "name": "",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      }
    },
    "order_number": 12,
    "tool": {},
    "id": "14fe43af-ad6e-4fe1-a4b3-0b985936694f",
    "description": "",
    "name": "Correct Brush Line"
  },
  {
    "order_id": 0,
    "order_number": 162,
    "name": "Blank",
    "tool": null,
    "paper": {
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "name": null,
      "gsm": "250",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "ref": "77725080001",
      "price": 1.35
    },
    "id": "15230ec9-42c0-4a22-8b92-4f290e0778b9",
    "description": "",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F15230ec9-42c0-4a22-8b92-4f290e0778b9%2F600x600%2F6c761ae2-1c0a-404c-ac21-cf3a982ff551_600x600?alt=media&token=46ea4c35-5f33-4a2a-932a-7db4139327e0",
        "reference": "stroke_photos/15230ec9-42c0-4a22-8b92-4f290e0778b9/600x600/6c761ae2-1c0a-404c-ac21-cf3a982ff551_600x600"
      },
      "original": {
        "reference": "/stroke_photos/15230ec9-42c0-4a22-8b92-4f290e0778b9/6c761ae2-1c0a-404c-ac21-cf3a982ff551",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F15230ec9-42c0-4a22-8b92-4f290e0778b9%2F6c761ae2-1c0a-404c-ac21-cf3a982ff551?alt=media&token=a8df35df-15e2-486f-a85f-965512f7f77d"
      }
    }
  },
  {
    "name": "POOTT One More",
    "photos": {
      "original": {
        "reference": "/stroke_photos/1535ccf3-76a0-4e50-ba3e-aa8ae3c499a9/d7447ffd-7610-429a-be7d-da3ae22e5e4c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1535ccf3-76a0-4e50-ba3e-aa8ae3c499a9%2Fd7447ffd-7610-429a-be7d-da3ae22e5e4c?alt=media&token=459e7c94-358d-4285-9c2e-94faff0e81c7"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/1535ccf3-76a0-4e50-ba3e-aa8ae3c499a9/600x600/d7447ffd-7610-429a-be7d-da3ae22e5e4c_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1535ccf3-76a0-4e50-ba3e-aa8ae3c499a9%2F600x600%2Fd7447ffd-7610-429a-be7d-da3ae22e5e4c_600x600?alt=media&token=223ce428-63e4-4a15-b792-fc12e5dc0b87"
      }
    },
    "order_number": 51,
    "description": "Another attempt by using paint only on the tip. You can see how the unevenness of the brush creates emptiness",
    "id": "1535ccf3-76a0-4e50-ba3e-aa8ae3c499a9",
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": null,
      "name": "",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_surface": {},
      "original_size": "A4",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "gsm": "200",
      "ref": "44202129"
    },
    "order_id": 0,
    "tool": null
  },
  {
    "description": "",
    "name": "",
    "order_number": 1183,
    "id": "15799d2a-9c7e-443f-b92d-e7c31d46cae7"
  },
  {
    "order_id": 0,
    "id": "159d0d93-d81f-43c9-8a1d-105117efd60e",
    "description": "",
    "name": "Pfsmpsitb Less Paint",
    "paper": {
      "original_size": "A4",
      "ref": "44202129",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "name": "",
      "paper_surface": {},
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "200",
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "price": null
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F159d0d93-d81f-43c9-8a1d-105117efd60e%2F693bd83e-b9c8-4f3c-b332-e05141b27a0f?alt=media&token=01f2e51b-9c73-4844-b13e-ef2cbfe41fe0",
        "reference": "/stroke_photos/159d0d93-d81f-43c9-8a1d-105117efd60e/693bd83e-b9c8-4f3c-b332-e05141b27a0f"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F159d0d93-d81f-43c9-8a1d-105117efd60e%2F600x600%2F693bd83e-b9c8-4f3c-b332-e05141b27a0f_600x600?alt=media&token=6f1424ef-a7a9-4837-96e4-1c7e21098d9d",
        "reference": "stroke_photos/159d0d93-d81f-43c9-8a1d-105117efd60e/600x600/693bd83e-b9c8-4f3c-b332-e05141b27a0f_600x600"
      }
    },
    "tool": null,
    "order_number": 40
  },
  {
    "order_number": 1096,
    "name": "",
    "description": "",
    "id": "1633cbb6-44e5-4e43-b2f8-7f9e27a5e70e"
  },
  {
    "tool": null,
    "name": "Blank",
    "description": "",
    "order_id": 0,
    "order_number": 186,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/1651005f-28e3-4d78-ac13-9ad0a4b9af58/600x600/d9a8c4c3-3dad-48a1-a2a6-29026f54f198_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1651005f-28e3-4d78-ac13-9ad0a4b9af58%2F600x600%2Fd9a8c4c3-3dad-48a1-a2a6-29026f54f198_600x600?alt=media&token=ac11854d-b035-42cd-8ee8-23b9f62bfcd9"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1651005f-28e3-4d78-ac13-9ad0a4b9af58%2Fd9a8c4c3-3dad-48a1-a2a6-29026f54f198?alt=media&token=3ec826a8-8871-4563-ab89-e49f43372331",
        "reference": "/stroke_photos/1651005f-28e3-4d78-ac13-9ad0a4b9af58/d9a8c4c3-3dad-48a1-a2a6-29026f54f198"
      }
    },
    "paper": {
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "ref": "77725080001",
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "price": 1.35,
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      }
    },
    "id": "1651005f-28e3-4d78-ac13-9ad0a4b9af58"
  },
  {
    "description": "",
    "order_id": 0,
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1655213d-f167-460f-adec-9e01d1382ded%2F600x600%2F98b1649b-c5e6-44c8-a24a-76c5459ef165_600x600?alt=media&token=d75ad113-048c-4469-afff-0dbd56db4da5",
        "reference": "stroke_photos/1655213d-f167-460f-adec-9e01d1382ded/600x600/98b1649b-c5e6-44c8-a24a-76c5459ef165_600x600"
      },
      "original": {
        "reference": "/stroke_photos/1655213d-f167-460f-adec-9e01d1382ded/98b1649b-c5e6-44c8-a24a-76c5459ef165",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1655213d-f167-460f-adec-9e01d1382ded%2F98b1649b-c5e6-44c8-a24a-76c5459ef165?alt=media&token=0fa3dc0f-d792-45a3-b021-46553dc82e51"
      }
    },
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "ref": "20170703",
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250",
      "price": 0.4,
      "original_size": "A7",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      }
    },
    "order_number": 720,
    "name": "Blank",
    "id": "1655213d-f167-460f-adec-9e01d1382ded"
  },
  {
    "paper": {
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "gsm": "640",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "name": "tinto",
      "price": 19.3,
      "ref": "30075105",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "75x105cm",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "id": "1678aae7-ea04-45b0-88e5-d5cce89b257d",
    "name": "Blank",
    "order_id": 0,
    "order_number": 995,
    "description": ""
  },
  {
    "description": "<p>Just a bit, to see how it looks</p>",
    "order_number": 325,
    "tool": null,
    "name": "Twisted",
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "name": null,
      "original_size": "A7",
      "price": null,
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20180107"
    },
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F169c9001-715d-4ffc-8a0b-401ca5e0975b%2F600x600%2Fae99b0b1-30ce-460d-aeb9-2eb91d68dc9f_600x600?alt=media&token=30ebeb00-a55a-4c81-9cd1-01bdb8fc9913",
        "reference": "stroke_photos/169c9001-715d-4ffc-8a0b-401ca5e0975b/600x600/ae99b0b1-30ce-460d-aeb9-2eb91d68dc9f_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F169c9001-715d-4ffc-8a0b-401ca5e0975b%2Fae99b0b1-30ce-460d-aeb9-2eb91d68dc9f?alt=media&token=4b6b50b9-20e4-4012-864b-127bc8af1fd4",
        "reference": "/stroke_photos/169c9001-715d-4ffc-8a0b-401ca5e0975b/ae99b0b1-30ce-460d-aeb9-2eb91d68dc9f"
      }
    },
    "id": "169c9001-715d-4ffc-8a0b-401ca5e0975b"
  },
  {
    "tool": null,
    "paper": {
      "original_size": "A4",
      "gsm": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {},
      "ref": null,
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "price": null
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F16a6ca4e-7499-4ab5-8171-b84a22ed1fbf%2F82c492d6-67ce-404d-a538-4a299b498e17?alt=media&token=ee810008-d7da-43ff-b4ec-e663486f023b",
        "reference": "/stroke_photos/16a6ca4e-7499-4ab5-8171-b84a22ed1fbf/82c492d6-67ce-404d-a538-4a299b498e17"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F16a6ca4e-7499-4ab5-8171-b84a22ed1fbf%2F600x600%2F82c492d6-67ce-404d-a538-4a299b498e17_600x600?alt=media&token=f95affe5-08a4-41f2-9632-c39b8d8229ef",
        "reference": "stroke_photos/16a6ca4e-7499-4ab5-8171-b84a22ed1fbf/600x600/82c492d6-67ce-404d-a538-4a299b498e17_600x600"
      }
    },
    "id": "16a6ca4e-7499-4ab5-8171-b84a22ed1fbf",
    "name": "Silver-On-Black Flat Short Line",
    "order_number": 538,
    "description": "<p>I will make short flat thick line (!) Very strange ending. The paint made itself a little wall</p>",
    "order_id": 0
  },
  {
    "id": "16e974ce-c1f3-4bc8-a76d-91772e8245bb",
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "name": "",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {},
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "ref": "44202129",
      "gsm": "200",
      "price": null,
      "original_size": "A4"
    },
    "tool": null,
    "order_number": 53,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F16e974ce-c1f3-4bc8-a76d-91772e8245bb%2F60c944f2-c432-4a40-8677-9b2991bbfbea?alt=media&token=7e3c74b5-f901-437c-982d-45bfa1fe1560",
        "reference": "/stroke_photos/16e974ce-c1f3-4bc8-a76d-91772e8245bb/60c944f2-c432-4a40-8677-9b2991bbfbea"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/16e974ce-c1f3-4bc8-a76d-91772e8245bb/600x600/60c944f2-c432-4a40-8677-9b2991bbfbea_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F16e974ce-c1f3-4bc8-a76d-91772e8245bb%2F600x600%2F60c944f2-c432-4a40-8677-9b2991bbfbea_600x600?alt=media&token=969ca752-aa09-43d7-b07a-9f4943598b5e"
      }
    },
    "description": "<p>Decided to see what happens when you press the brush hard. Would the paint be thicker, will it last till the end? <br></p>\n<p>Reflection: Interesting. I expected it would be all thick and black. Instead, the very tip was blackened only. Let's try that again...</p>",
    "order_id": 0,
    "name": "Press Hard With Little Paint"
  },
  {
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/1706b1ce-8371-4ad5-aa53-8b079ace11b5/600x600/022d6524-292c-498e-8768-90e456e55967_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1706b1ce-8371-4ad5-aa53-8b079ace11b5%2F600x600%2F022d6524-292c-498e-8768-90e456e55967_600x600?alt=media&token=89ad8e76-ed76-421f-a48f-476ea5ad475d"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1706b1ce-8371-4ad5-aa53-8b079ace11b5%2F022d6524-292c-498e-8768-90e456e55967?alt=media&token=08cf2d9b-d766-4d6b-8a02-3f6721fdb647",
        "reference": "/stroke_photos/1706b1ce-8371-4ad5-aa53-8b079ace11b5/022d6524-292c-498e-8768-90e456e55967"
      }
    },
    "id": "1706b1ce-8371-4ad5-aa53-8b079ace11b5",
    "order_id": 0,
    "order_number": 792,
    "paper": {
      "price": 1.35,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "ref": "77725080001",
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "gsm": "250",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "original_size": "A7"
    },
    "name": "Blank",
    "tool": null
  },
  {
    "name": "",
    "order_number": 1124,
    "description": "",
    "id": "170edd41-d12f-4268-8469-8b675c83d536"
  },
  {
    "id": "174408e2-5a99-4859-94a8-9a9fa65a3edf",
    "order_number": 835,
    "paper": {
      "name": null,
      "price": null,
      "original_size": "A7",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand": {},
      "color": {
        "hex": "#f5f5f5",
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "ref": "70025010030",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "order_id": 0,
    "name": "Blank",
    "description": ""
  },
  {
    "tool": null,
    "order_number": 476,
    "id": "176a8eab-b861-448f-a848-41ba3fe2c374",
    "name": "Wavy White Upside Down",
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "original_size": "A4",
      "gsm": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand": {},
      "brand_model": {}
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F176a8eab-b861-448f-a848-41ba3fe2c374%2F600x600%2F3c7cb823-5083-4547-abaf-fd6a5545b19c_600x600?alt=media&token=fa690397-0e76-452b-a5ca-354e8974ef7d",
        "reference": "stroke_photos/176a8eab-b861-448f-a848-41ba3fe2c374/600x600/3c7cb823-5083-4547-abaf-fd6a5545b19c_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F176a8eab-b861-448f-a848-41ba3fe2c374%2F3c7cb823-5083-4547-abaf-fd6a5545b19c?alt=media&token=977e0348-0966-45bd-8873-356bbcacf183",
        "reference": "/stroke_photos/176a8eab-b861-448f-a848-41ba3fe2c374/3c7cb823-5083-4547-abaf-fd6a5545b19c"
      }
    },
    "description": "<p>Crap, still slanted! I must be slanted myself</p>",
    "order_id": 0
  },
  {
    "order_number": 1147,
    "description": "",
    "name": "",
    "id": "176efa1b-c091-4f73-994c-ea4c363642ac"
  },
  {
    "id": "17766423-da66-4455-a099-6124124ea597",
    "description": "",
    "tool": {},
    "order_id": 0,
    "paper": {
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "paper_surface": {},
      "name": "",
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "44202129",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "gsm": "200",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "original_size": "A4"
    },
    "name": "Fast Stroke More Paint",
    "order_number": 30,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F17766423-da66-4455-a099-6124124ea597%2Ffa7c5288-86a9-4df7-a62a-d292067f8cd2?alt=media&token=4ca1150a-ec31-47f2-81f8-5907acb1cf84",
        "reference": "/stroke_photos/17766423-da66-4455-a099-6124124ea597/fa7c5288-86a9-4df7-a62a-d292067f8cd2"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/17766423-da66-4455-a099-6124124ea597/600x600/fa7c5288-86a9-4df7-a62a-d292067f8cd2_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F17766423-da66-4455-a099-6124124ea597%2F600x600%2Ffa7c5288-86a9-4df7-a62a-d292067f8cd2_600x600?alt=media&token=0cb6241e-47ad-429b-b311-154f0a761234"
      }
    }
  },
  {
    "paper": {
      "gsm": "250",
      "original_size": "A7",
      "brand": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20170703",
      "price": 0.4,
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      }
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F17fcda79-18ea-4b77-bf77-954ada603aca%2F6807648e-187f-46a4-a5c9-d79d1429c09d?alt=media&token=a9ad082e-d1c3-4bef-ab98-d180924612ad",
        "reference": "/stroke_photos/17fcda79-18ea-4b77-bf77-954ada603aca/6807648e-187f-46a4-a5c9-d79d1429c09d"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/17fcda79-18ea-4b77-bf77-954ada603aca/600x600/6807648e-187f-46a4-a5c9-d79d1429c09d_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F17fcda79-18ea-4b77-bf77-954ada603aca%2F600x600%2F6807648e-187f-46a4-a5c9-d79d1429c09d_600x600?alt=media&token=d096ad3e-af2e-4f7f-aec4-a7e5fed5102a"
      }
    },
    "order_number": 738,
    "id": "17fcda79-18ea-4b77-bf77-954ada603aca",
    "name": "Blank",
    "order_id": 0,
    "description": "",
    "tool": null
  },
  {
    "order_number": 1071,
    "name": "",
    "description": "",
    "id": "18183948-0f79-4f6e-848d-6bdb8256d7c1"
  },
  {
    "order_number": 1105,
    "description": "",
    "name": "",
    "id": "184033e5-571c-40be-927a-92172d3c6d87"
  },
  {
    "order_number": 1002,
    "name": "",
    "description": "",
    "id": "1842c38d-d435-4830-bce9-c974fde3dd51"
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F185d0fc8-8628-426f-908f-b74761b27ed8%2Fc28df595-c031-4ed9-8152-61c58ac6a5a3?alt=media&token=06848861-b7a5-4f28-918d-1baf5828b366",
        "reference": "/stroke_photos/185d0fc8-8628-426f-908f-b74761b27ed8/c28df595-c031-4ed9-8152-61c58ac6a5a3"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F185d0fc8-8628-426f-908f-b74761b27ed8%2F600x600%2Fc28df595-c031-4ed9-8152-61c58ac6a5a3_600x600?alt=media&token=7bf13877-34c1-4859-b564-5734e4ad1b63",
        "reference": "stroke_photos/185d0fc8-8628-426f-908f-b74761b27ed8/600x600/c28df595-c031-4ed9-8152-61c58ac6a5a3_600x600"
      }
    },
    "tool": null,
    "description": "<p>...and again...</p>",
    "order_id": 0,
    "order_number": 462,
    "name": "Reusing White Paint 2",
    "id": "185d0fc8-8628-426f-908f-b74761b27ed8",
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "gsm": null,
      "name": null,
      "price": null,
      "original_size": "A4",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {},
      "brand": {}
    }
  },
  {
    "name": "",
    "order_number": 1221,
    "description": "",
    "id": "1865fb83-49ab-4220-8d86-b52dcfd24d18"
  },
  {
    "id": "18bfd6cf-6e82-4fd5-b1f0-643b7c1332c4",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/18bfd6cf-6e82-4fd5-b1f0-643b7c1332c4/600x600/af9e0a12-a330-4ebc-9546-c23604d7b442_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F18bfd6cf-6e82-4fd5-b1f0-643b7c1332c4%2F600x600%2Faf9e0a12-a330-4ebc-9546-c23604d7b442_600x600?alt=media&token=bb5b7be5-1742-48d4-afa4-d9b7330a1461"
      },
      "original": {
        "reference": "/stroke_photos/18bfd6cf-6e82-4fd5-b1f0-643b7c1332c4/af9e0a12-a330-4ebc-9546-c23604d7b442",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F18bfd6cf-6e82-4fd5-b1f0-643b7c1332c4%2Faf9e0a12-a330-4ebc-9546-c23604d7b442?alt=media&token=4e231af8-d1b4-463d-a375-4315b05850f6"
      }
    },
    "tool": null,
    "order_number": 570,
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": 0.4,
      "brand": {},
      "name": null,
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper"
      },
      "ref": "20170703"
    },
    "name": "Blank",
    "description": ""
  },
  {
    "description": "",
    "name": "",
    "order_number": 1178,
    "id": "18c8b58f-18df-4992-8dd7-eea58fe15224"
  },
  {
    "description": "",
    "paper": {
      "ref": null,
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A4",
      "brand_model": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "brand": {},
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": null
    },
    "id": "1900f417-a1ab-4309-839f-7bdfab73c3c4",
    "name": "Blank",
    "order_number": 961,
    "order_id": 0
  },
  {
    "id": "192a1d90-caff-45ad-a755-d30d901b8042",
    "description": "",
    "name": "Blank",
    "order_number": 945,
    "order_id": 0,
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {},
      "ref": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "brand": {},
      "gsm": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "original_size": "A4",
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      }
    }
  },
  {
    "name": "",
    "description": "",
    "order_number": 1003,
    "id": "192ad0ad-0a03-4d7b-b04c-867aee44cef7"
  },
  {
    "order_id": 0,
    "id": "192d8d68-34df-40fa-84c9-0b1c1659209f",
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "price": null,
      "ref": "20180107"
    },
    "description": "<p>One more with silver paint.Artistic.</p>",
    "name": "Dry Quickie",
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F192d8d68-34df-40fa-84c9-0b1c1659209f%2Fa2dde65a-1da7-4acd-839f-f8757e199444?alt=media&token=8bdab4e6-199f-4917-bb3c-5d4a33b74928",
        "reference": "/stroke_photos/192d8d68-34df-40fa-84c9-0b1c1659209f/a2dde65a-1da7-4acd-839f-f8757e199444"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/192d8d68-34df-40fa-84c9-0b1c1659209f/600x600/a2dde65a-1da7-4acd-839f-f8757e199444_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F192d8d68-34df-40fa-84c9-0b1c1659209f%2F600x600%2Fa2dde65a-1da7-4acd-839f-f8757e199444_600x600?alt=media&token=6723001c-f1f5-4578-bcdd-0a9d31c62be5"
      }
    },
    "order_number": 363
  },
  {
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": "",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "price": null,
      "original_size": "A4",
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "gsm": "200",
      "paper_surface": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": "44202129",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      }
    },
    "id": "192eef8b-b070-4ae9-aa74-73bb9bf6bc12",
    "description": "",
    "order_id": 0,
    "order_number": 29,
    "tool": {},
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/192eef8b-b070-4ae9-aa74-73bb9bf6bc12/600x600/34d7b84d-6b80-4bdd-a350-2d8436360ffb_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F192eef8b-b070-4ae9-aa74-73bb9bf6bc12%2F600x600%2F34d7b84d-6b80-4bdd-a350-2d8436360ffb_600x600?alt=media&token=a064f2b5-089d-494b-8c9d-d4da543f30ff"
      },
      "original": {
        "reference": "/stroke_photos/192eef8b-b070-4ae9-aa74-73bb9bf6bc12/34d7b84d-6b80-4bdd-a350-2d8436360ffb",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F192eef8b-b070-4ae9-aa74-73bb9bf6bc12%2F34d7b84d-6b80-4bdd-a350-2d8436360ffb?alt=media&token=037d484e-b7b3-402d-b67b-d62c1b111254"
      }
    },
    "name": "Fast Stroke"
  },
  {
    "id": "1940ff15-3f8b-4b40-8354-c26565c1959c",
    "paper": {
      "price": 1.35,
      "name": null,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "original_size": "A7",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "ref": "77725080001",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    },
    "order_id": 0,
    "order_number": 182,
    "name": "Blank",
    "description": "",
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/1940ff15-3f8b-4b40-8354-c26565c1959c/adcb67d8-dc83-468b-a997-b704c47a1965",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1940ff15-3f8b-4b40-8354-c26565c1959c%2Fadcb67d8-dc83-468b-a997-b704c47a1965?alt=media&token=c94b5c8e-6a5b-47e7-a86d-df0956136d2e"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1940ff15-3f8b-4b40-8354-c26565c1959c%2F600x600%2Fadcb67d8-dc83-468b-a997-b704c47a1965_600x600?alt=media&token=1921f0f1-5947-4af2-9504-11974afa0856",
        "reference": "stroke_photos/1940ff15-3f8b-4b40-8354-c26565c1959c/600x600/adcb67d8-dc83-468b-a997-b704c47a1965_600x600"
      }
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1085,
    "id": "19686e1b-7234-4904-927e-2a10a7facaa2"
  },
  {
    "paper": {
      "price": 1.35,
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "gsm": "250",
      "ref": "77725080001",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "original_size": "A7",
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      }
    },
    "name": "Smoothie Somehow DIfferent",
    "photos": {
      "original": {
        "reference": "/stroke_photos/19845af9-4568-46c7-8d95-da14c8f5239d/3949ebfa-3788-41ac-bd84-fecc9f9a8bd0",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F19845af9-4568-46c7-8d95-da14c8f5239d%2F3949ebfa-3788-41ac-bd84-fecc9f9a8bd0?alt=media&token=f387aaef-9017-436b-95ba-18b53e29159f"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F19845af9-4568-46c7-8d95-da14c8f5239d%2F600x600%2F3949ebfa-3788-41ac-bd84-fecc9f9a8bd0_600x600?alt=media&token=89d055bc-068a-492d-818e-07248b0189a2",
        "reference": "stroke_photos/19845af9-4568-46c7-8d95-da14c8f5239d/600x600/3949ebfa-3788-41ac-bd84-fecc9f9a8bd0_600x600"
      }
    },
    "id": "19845af9-4568-46c7-8d95-da14c8f5239d",
    "order_number": 202,
    "description": "",
    "order_id": 0,
    "tool": null
  },
  {
    "paper": {
      "name": "",
      "gsm": "200",
      "paper_surface": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "ref": "44202129",
      "original_size": "A4",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "price": null,
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      }
    },
    "order_number": 52,
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1b24f3b6-795a-4f51-a101-0cb3e51a44ac%2F600x600%2F99715964-efb7-4ea1-9531-262e44730dd5_600x600?alt=media&token=ddccb431-523f-404a-82c1-d08c024b3c56",
        "reference": "stroke_photos/1b24f3b6-795a-4f51-a101-0cb3e51a44ac/600x600/99715964-efb7-4ea1-9531-262e44730dd5_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1b24f3b6-795a-4f51-a101-0cb3e51a44ac%2F99715964-efb7-4ea1-9531-262e44730dd5?alt=media&token=f7861b1d-47e9-40d0-8407-ba20b80d5715",
        "reference": "/stroke_photos/1b24f3b6-795a-4f51-a101-0cb3e51a44ac/99715964-efb7-4ea1-9531-262e44730dd5"
      }
    },
    "description": "After pressing the brush to get its hair together and avoid emptiness in the middle, I still got uneven strange and unnatural body. Seems that this is not the way to perfect.",
    "name": "POOTT One More",
    "order_id": 0,
    "id": "1b24f3b6-795a-4f51-a101-0cb3e51a44ac"
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1b5cb25d-86c3-4671-a4c6-bdbad330f1bc%2F648c87ed-4e78-4c4d-8a30-c1f00ffaea05?alt=media&token=b64b45aa-8bed-4115-bfb7-2bab5a684ad9",
        "reference": "/stroke_photos/1b5cb25d-86c3-4671-a4c6-bdbad330f1bc/648c87ed-4e78-4c4d-8a30-c1f00ffaea05"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/1b5cb25d-86c3-4671-a4c6-bdbad330f1bc/600x600/648c87ed-4e78-4c4d-8a30-c1f00ffaea05_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1b5cb25d-86c3-4671-a4c6-bdbad330f1bc%2F600x600%2F648c87ed-4e78-4c4d-8a30-c1f00ffaea05_600x600?alt=media&token=3f0698db-fea7-48c0-a18f-7d40d3b73dca"
      }
    },
    "description": "...cut short",
    "order_number": 248,
    "name": "Stroke...",
    "id": "1b5cb25d-86c3-4671-a4c6-bdbad330f1bc",
    "paper": {
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "20180107",
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "gsm": "250",
      "name": null,
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "original_size": "A7",
      "price": null
    },
    "tool": null,
    "order_id": 0
  },
  {
    "id": "1b5f9854-89f7-4ca7-b0ff-ed9c53a21b94",
    "description": "",
    "order_number": 980,
    "order_id": 0,
    "paper": {
      "original_size": "75x105cm",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "ref": "30075105",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "640",
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "name": "tinto",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "price": 19.3
    },
    "name": "Blank"
  },
  {
    "id": "1b82233f-dd4c-4de7-97a0-248abe243ff0",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/1b82233f-dd4c-4de7-97a0-248abe243ff0/600x600/5d57f2e0-e511-4612-ba16-49c8d36deabb_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1b82233f-dd4c-4de7-97a0-248abe243ff0%2F600x600%2F5d57f2e0-e511-4612-ba16-49c8d36deabb_600x600?alt=media&token=6d20d9c2-e69e-4cce-bd7c-40760b4a0dca"
      },
      "original": {
        "reference": "/stroke_photos/1b82233f-dd4c-4de7-97a0-248abe243ff0/5d57f2e0-e511-4612-ba16-49c8d36deabb",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1b82233f-dd4c-4de7-97a0-248abe243ff0%2F5d57f2e0-e511-4612-ba16-49c8d36deabb?alt=media&token=48851e87-7ad2-47c4-8f8e-fea83928baa1"
      }
    },
    "order_number": 124,
    "name": "Arc to Be Corrected",
    "tool": null,
    "description": "",
    "paper": {
      "gsm": "250",
      "name": null,
      "brand": {},
      "price": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "order_id": 0
  },
  {
    "name": "",
    "description": "",
    "order_number": 1012,
    "id": "1bc78eff-ecde-47ca-937c-ae9b7d2c2123"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1001,
    "id": "1c4cc909-2c8e-4cf5-bcbf-fea1f3ca4754"
  },
  {
    "paper": {
      "brand_model": {},
      "brand": {},
      "ref": "70025010030",
      "color": {
        "hex": "#f5f5f5",
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "price": null,
      "original_size": "A7",
      "name": null,
      "gsm": "250"
    },
    "description": "",
    "id": "1c525cbe-96e6-4f58-a195-5a32fdc7ca60",
    "order_id": 0,
    "name": "Blank",
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/1c525cbe-96e6-4f58-a195-5a32fdc7ca60/6b05ff80-8fa3-4032-bdcf-23392f31ba3f",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1c525cbe-96e6-4f58-a195-5a32fdc7ca60%2F6b05ff80-8fa3-4032-bdcf-23392f31ba3f?alt=media&token=a1cab4e2-06e8-44a6-ad48-2afb1d98fc0d"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1c525cbe-96e6-4f58-a195-5a32fdc7ca60%2F600x600%2F6b05ff80-8fa3-4032-bdcf-23392f31ba3f_600x600?alt=media&token=ce8ef892-a1b1-4f4e-a0ca-5e10c7ef94d8",
        "reference": "stroke_photos/1c525cbe-96e6-4f58-a195-5a32fdc7ca60/600x600/6b05ff80-8fa3-4032-bdcf-23392f31ba3f_600x600"
      }
    },
    "order_number": 592
  },
  {
    "description": "",
    "name": "",
    "order_number": 1181,
    "id": "1c645b5c-f5b4-4eb5-b047-91d5751dc92d"
  },
  {
    "description": "I wish I didn't run out of paint at the very end...",
    "name": "Vaflichki 4",
    "tool": null,
    "order_id": 0,
    "paper": {
      "price": 1.35,
      "gsm": "250",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "ref": "77725080001",
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      }
    },
    "id": "1c77465e-0d55-4be9-9e42-b05d08471f1d",
    "photos": {
      "original": {
        "reference": "/stroke_photos/1c77465e-0d55-4be9-9e42-b05d08471f1d/6ddd011f-c45b-46ef-85c1-8ca4251048f6",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1c77465e-0d55-4be9-9e42-b05d08471f1d%2F6ddd011f-c45b-46ef-85c1-8ca4251048f6?alt=media&token=4e9645fe-c9ba-4443-84fe-550a402d3b3d"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1c77465e-0d55-4be9-9e42-b05d08471f1d%2F600x600%2F6ddd011f-c45b-46ef-85c1-8ca4251048f6_600x600?alt=media&token=5e671562-d23a-4735-bb47-3b314cc6b56e",
        "reference": "stroke_photos/1c77465e-0d55-4be9-9e42-b05d08471f1d/600x600/6ddd011f-c45b-46ef-85c1-8ca4251048f6_600x600"
      }
    },
    "order_number": 228
  },
  {
    "id": "1c7a0ce5-56d3-4813-9e7f-3a998641f4c5",
    "description": "",
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/1c7a0ce5-56d3-4813-9e7f-3a998641f4c5/42c2ad1d-a988-4114-927a-96c06b45dbee",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1c7a0ce5-56d3-4813-9e7f-3a998641f4c5%2F42c2ad1d-a988-4114-927a-96c06b45dbee?alt=media&token=a6acfe0b-cef0-45fb-8312-e7d3f5b3d50a"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/1c7a0ce5-56d3-4813-9e7f-3a998641f4c5/600x600/42c2ad1d-a988-4114-927a-96c06b45dbee_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1c7a0ce5-56d3-4813-9e7f-3a998641f4c5%2F600x600%2F42c2ad1d-a988-4114-927a-96c06b45dbee_600x600?alt=media&token=55241db0-82b7-468a-b475-3a8955ae7108"
      }
    },
    "order_id": 0,
    "paper": {
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "name": null,
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": "20180107",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": null
    },
    "name": "More Points in the Beginning Than End",
    "order_number": 134
  },
  {
    "description": "<p>What would happen if I turn the brush sideways? Loads of paint at the bottom.</p>",
    "id": "1c8700c3-6a91-4930-9c44-4532ab05dc81",
    "name": "Sideways Silver",
    "order_id": 0,
    "tool": null,
    "order_number": 364,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1c8700c3-6a91-4930-9c44-4532ab05dc81%2F600x600%2F98371d0d-787e-4633-8c9b-a42afc5c979b_600x600?alt=media&token=65b63a80-3b9b-4255-9f6a-08537b0cc192",
        "reference": "stroke_photos/1c8700c3-6a91-4930-9c44-4532ab05dc81/600x600/98371d0d-787e-4633-8c9b-a42afc5c979b_600x600"
      },
      "original": {
        "reference": "/stroke_photos/1c8700c3-6a91-4930-9c44-4532ab05dc81/98371d0d-787e-4633-8c9b-a42afc5c979b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1c8700c3-6a91-4930-9c44-4532ab05dc81%2F98371d0d-787e-4633-8c9b-a42afc5c979b?alt=media&token=de2f8d54-fda5-410b-9c84-cb571f9207d0"
      }
    },
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "price": null,
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20180107",
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    }
  },
  {
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1c920830-e854-4856-b7d7-3183acd9997d%2F600x600%2Fc01a5adc-9a1d-46b6-bcda-885b98cf4245_600x600?alt=media&token=522fbfee-eb9b-4f6e-98fb-28d7fb07305f",
        "reference": "stroke_photos/1c920830-e854-4856-b7d7-3183acd9997d/600x600/c01a5adc-9a1d-46b6-bcda-885b98cf4245_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1c920830-e854-4856-b7d7-3183acd9997d%2Fc01a5adc-9a1d-46b6-bcda-885b98cf4245?alt=media&token=a7994a05-bfe2-41db-8f4b-d66dc671f13c",
        "reference": "/stroke_photos/1c920830-e854-4856-b7d7-3183acd9997d/c01a5adc-9a1d-46b6-bcda-885b98cf4245"
      }
    },
    "paper": {
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "ref": "77725080001",
      "price": 1.35,
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "gsm": "250",
      "original_size": "A7"
    },
    "description": "",
    "tool": null,
    "id": "1c920830-e854-4856-b7d7-3183acd9997d",
    "order_number": 800,
    "name": "Blank"
  },
  {
    "name": "Blank",
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A4",
      "price": null,
      "gsm": null,
      "brand": {},
      "brand_model": {},
      "ref": null
    },
    "order_number": 952,
    "description": "",
    "order_id": 0,
    "id": "1cad7018-232e-4de3-a335-2196d37e7be2"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/1cc63336-bc75-4fb1-8773-33c33712c4ae/eb756aea-ecf2-4801-9ed6-8e8446d8d52e",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1cc63336-bc75-4fb1-8773-33c33712c4ae%2Feb756aea-ecf2-4801-9ed6-8e8446d8d52e?alt=media&token=1913ff5a-d0b6-4422-ab49-331042e2697e"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/1cc63336-bc75-4fb1-8773-33c33712c4ae/600x600/eb756aea-ecf2-4801-9ed6-8e8446d8d52e_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1cc63336-bc75-4fb1-8773-33c33712c4ae%2F600x600%2Feb756aea-ecf2-4801-9ed6-8e8446d8d52e_600x600?alt=media&token=1870ee74-62a8-4218-84c7-b2d948f49037"
      }
    },
    "name": "Btns Reload",
    "paper": {
      "ref": "44202129",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {},
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "price": null,
      "name": "",
      "original_size": "A4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "200"
    },
    "id": "1cc63336-bc75-4fb1-8773-33c33712c4ae",
    "order_number": 48,
    "description": "",
    "order_id": 0,
    "tool": null
  },
  {
    "order_id": 0,
    "id": "1cdb8ccb-4d41-475b-8830-a2936bcb6893",
    "order_number": 854,
    "name": "Blank",
    "description": "",
    "paper": {
      "gsm": "110",
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      },
      "ref": "4005329065767",
      "original_size": "A4",
      "brand_model": {
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "name": "Elephant Skin"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": 1,
      "name": null
    }
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/1ce271b3-5e07-4adc-87e6-5d09daf4bb83/c787097b-123d-4b65-b0c2-bfb340448aca",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1ce271b3-5e07-4adc-87e6-5d09daf4bb83%2Fc787097b-123d-4b65-b0c2-bfb340448aca?alt=media&token=bdaf7d86-a136-4d76-961b-28a5c39cff51"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/1ce271b3-5e07-4adc-87e6-5d09daf4bb83/600x600/c787097b-123d-4b65-b0c2-bfb340448aca_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1ce271b3-5e07-4adc-87e6-5d09daf4bb83%2F600x600%2Fc787097b-123d-4b65-b0c2-bfb340448aca_600x600?alt=media&token=dc7e49c0-334c-407b-9162-478864f9aa74"
      }
    },
    "order_id": 0,
    "description": "<p>Seems like the end is like a punk hair. Also the angle is corny</p>",
    "name": "Thick Shit",
    "order_number": 282,
    "tool": null,
    "paper": {
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "20180107",
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "brand": {},
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "original_size": "A7"
    },
    "id": "1ce271b3-5e07-4adc-87e6-5d09daf4bb83"
  },
  {
    "order_number": 1194,
    "description": "",
    "name": "",
    "id": "1cec3fff-6958-43b8-9a7f-314dd1e66a41"
  },
  {
    "name": "Blank",
    "order_id": 0,
    "description": "",
    "paper": {
      "gsm": "110",
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "name": null,
      "brand_model": {
        "name": "Elephant Skin",
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": 1,
      "ref": "4005329065767",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "original_size": "A4"
    },
    "id": "1cfcea75-7d88-480d-80e7-d2f8b029f644",
    "order_number": 864
  },
  {
    "order_number": 1159,
    "description": "",
    "name": "",
    "id": "1d737863-1647-4bbe-b921-164aeecf64b3"
  },
  {
    "description": "<p>Better. Movement without wrist</p>",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/1d7b5cc5-d5bb-4d44-a51c-0a743173ba8b/600x600/679dbd35-cfcb-4f26-a05c-6be9d98078da_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1d7b5cc5-d5bb-4d44-a51c-0a743173ba8b%2F600x600%2F679dbd35-cfcb-4f26-a05c-6be9d98078da_600x600?alt=media&token=bcd46573-7498-431c-bcd6-2e6dc5de3d35"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1d7b5cc5-d5bb-4d44-a51c-0a743173ba8b%2F679dbd35-cfcb-4f26-a05c-6be9d98078da?alt=media&token=8a8eb198-818f-4ecd-92f4-cdff4833224b",
        "reference": "/stroke_photos/1d7b5cc5-d5bb-4d44-a51c-0a743173ba8b/679dbd35-cfcb-4f26-a05c-6be9d98078da"
      }
    },
    "order_id": 0,
    "paper": {
      "ref": null,
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": null,
      "price": null,
      "brand": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {}
    },
    "name": "Another Diagonal White",
    "tool": null,
    "order_number": 458,
    "id": "1d7b5cc5-d5bb-4d44-a51c-0a743173ba8b"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1067,
    "id": "1e1553ba-f277-4391-964f-331b15b8bb36"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1054,
    "id": "1e24ae67-82ad-418f-9151-7e187473b8e3"
  },
  {
    "order_number": 1175,
    "name": "",
    "description": "",
    "id": "1e9f4c73-8af1-4c4d-95bb-2e3018e82ed3"
  },
  {
    "tool": null,
    "paper": {
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "gsm": "640",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "name": "tinto",
      "ref": "30075105",
      "price": 19.3,
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "75x105cm",
      "brand_model": {
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      }
    },
    "name": "Blank",
    "order_number": 687,
    "description": "",
    "order_id": 0,
    "id": "1ebf7f88-b294-4042-943c-7599df028bae",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1ebf7f88-b294-4042-943c-7599df028bae%2F600x600%2F17b2dfd9-d94f-4fba-bcbc-8c7e03840ec1_600x600?alt=media&token=3a6210e0-3192-4fdb-97d0-e783cff49143",
        "reference": "stroke_photos/1ebf7f88-b294-4042-943c-7599df028bae/600x600/17b2dfd9-d94f-4fba-bcbc-8c7e03840ec1_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1ebf7f88-b294-4042-943c-7599df028bae%2F17b2dfd9-d94f-4fba-bcbc-8c7e03840ec1?alt=media&token=79c042b4-9478-4540-96c3-9965b83c0b5b",
        "reference": "/stroke_photos/1ebf7f88-b294-4042-943c-7599df028bae/17b2dfd9-d94f-4fba-bcbc-8c7e03840ec1"
      }
    }
  },
  {
    "description": "",
    "id": "1ec8741a-37cd-4f7d-be73-d55435bedfa7",
    "order_number": 151,
    "paper": {
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "original_size": "A7",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "gsm": "250",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "price": 1.35,
      "ref": "77725080001"
    },
    "tool": null,
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1ec8741a-37cd-4f7d-be73-d55435bedfa7%2F600x600%2Fbd8d6c39-950e-4cd5-a04e-9ad2d422446a_600x600?alt=media&token=6b5b3857-0dbb-42e2-a233-5186b72fa818",
        "reference": "stroke_photos/1ec8741a-37cd-4f7d-be73-d55435bedfa7/600x600/bd8d6c39-950e-4cd5-a04e-9ad2d422446a_600x600"
      },
      "original": {
        "reference": "/stroke_photos/1ec8741a-37cd-4f7d-be73-d55435bedfa7/bd8d6c39-950e-4cd5-a04e-9ad2d422446a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1ec8741a-37cd-4f7d-be73-d55435bedfa7%2Fbd8d6c39-950e-4cd5-a04e-9ad2d422446a?alt=media&token=0b4bdedf-57be-45cf-81f1-45586650f4c7"
      }
    },
    "name": "Blank"
  },
  {
    "description": "",
    "order_number": 1209,
    "name": "",
    "id": "1eea01f2-2d80-403c-8b44-873466490ef1"
  },
  {
    "description": "",
    "order_number": 1203,
    "name": "",
    "id": "1f20e102-2927-4d97-9e7f-70f1b6f63301"
  },
  {
    "description": "",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1f67d639-6979-4548-a87a-a69b77f5b85c%2Fc8d74b64-d979-431a-bfdf-8149ea11d171?alt=media&token=d0de0a19-a47d-490d-bb71-876679b5ab2e",
        "reference": "/stroke_photos/1f67d639-6979-4548-a87a-a69b77f5b85c/c8d74b64-d979-431a-bfdf-8149ea11d171"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1f67d639-6979-4548-a87a-a69b77f5b85c%2F600x600%2Fc8d74b64-d979-431a-bfdf-8149ea11d171_600x600?alt=media&token=9b23d713-4495-4c96-9eb8-f04bcba5f8f5",
        "reference": "stroke_photos/1f67d639-6979-4548-a87a-a69b77f5b85c/600x600/c8d74b64-d979-431a-bfdf-8149ea11d171_600x600"
      }
    },
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "gsm": "250",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "ref": "77725080001",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      }
    },
    "tool": null,
    "name": "Blank",
    "order_id": 0,
    "order_number": 780,
    "id": "1f67d639-6979-4548-a87a-a69b77f5b85c"
  },
  {
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/1f68bbb1-8a52-43e3-a8b3-5aa1b2ea00f4/600x600/02efde21-c437-4a45-8575-05fd475b2bd5_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1f68bbb1-8a52-43e3-a8b3-5aa1b2ea00f4%2F600x600%2F02efde21-c437-4a45-8575-05fd475b2bd5_600x600?alt=media&token=15d9a602-f171-4529-9b33-658073758bd3"
      },
      "original": {
        "reference": "/stroke_photos/1f68bbb1-8a52-43e3-a8b3-5aa1b2ea00f4/02efde21-c437-4a45-8575-05fd475b2bd5",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F1f68bbb1-8a52-43e3-a8b3-5aa1b2ea00f4%2F02efde21-c437-4a45-8575-05fd475b2bd5?alt=media&token=58bdd5d1-727d-4189-8edf-459b0455f9cd"
      }
    },
    "order_id": 0,
    "description": "",
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": "640",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "ref": "30075105",
      "brand_model": {
        "name": "Artistico",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "name": "tinto",
      "original_size": "75x105cm",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "price": 19.3
    },
    "name": "Blank",
    "id": "1f68bbb1-8a52-43e3-a8b3-5aa1b2ea00f4",
    "order_number": 668,
    "tool": null
  },
  {
    "id": "1fce52c8-346a-48d8-8be3-cf3590416de8",
    "paper": {
      "ref": null,
      "name": null,
      "brand": {},
      "original_size": "A4",
      "gsm": null,
      "brand_model": {},
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "order_number": 901,
    "order_id": 0,
    "name": "Blank",
    "description": ""
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F200da306-dbcb-48b1-a330-611cbdfe4a9d%2F342f6596-f5a4-41e8-9fcd-fc7a48b230b3?alt=media&token=dc3c9671-716d-48da-bf48-ff67b5078dfe",
        "reference": "/stroke_photos/200da306-dbcb-48b1-a330-611cbdfe4a9d/342f6596-f5a4-41e8-9fcd-fc7a48b230b3"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/200da306-dbcb-48b1-a330-611cbdfe4a9d/600x600/342f6596-f5a4-41e8-9fcd-fc7a48b230b3_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F200da306-dbcb-48b1-a330-611cbdfe4a9d%2F600x600%2F342f6596-f5a4-41e8-9fcd-fc7a48b230b3_600x600?alt=media&token=773e3adc-2799-494d-b520-58dbe6e8bed6"
      }
    },
    "order_number": 576,
    "paper": {
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A7",
      "ref": "20170703",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand": {},
      "price": 0.4,
      "gsm": "250",
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "id": "200da306-dbcb-48b1-a330-611cbdfe4a9d",
    "name": "Blank",
    "description": "",
    "order_id": 0,
    "tool": null
  },
  {
    "order_id": 0,
    "paper": {
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": null,
      "ref": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "original_size": "A4",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand_model": {},
      "gsm": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand": {}
    },
    "order_number": 472,
    "id": "2039e06a-ca93-497f-a9d0-b01fca8d418b",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/2039e06a-ca93-497f-a9d0-b01fca8d418b/600x600/858874e0-2613-49c8-a134-dbd62cd242f5_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2039e06a-ca93-497f-a9d0-b01fca8d418b%2F600x600%2F858874e0-2613-49c8-a134-dbd62cd242f5_600x600?alt=media&token=800dbd5c-b22d-43e8-850a-86dba79d0ece"
      },
      "original": {
        "reference": "/stroke_photos/2039e06a-ca93-497f-a9d0-b01fca8d418b/858874e0-2613-49c8-a134-dbd62cd242f5",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2039e06a-ca93-497f-a9d0-b01fca8d418b%2F858874e0-2613-49c8-a134-dbd62cd242f5?alt=media&token=83a35773-115c-453b-a8a3-2f1a57895ff2"
      }
    },
    "description": "<p>Interesting, you can see it's \"ribs\" so to say</p>",
    "name": "Repeat Variable Pressure White Less Paint",
    "tool": null
  },
  {
    "tool": null,
    "order_number": 268,
    "order_id": 0,
    "name": "Wide Stroke BIggering Mini Overdoing",
    "paper": {
      "original_size": "A7",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "name": null,
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "gsm": "250"
    },
    "id": "20830907-713a-4ac7-8205-7325f47e5df5",
    "description": "<p>Overdone</p>",
    "photos": {
      "original": {
        "reference": "/stroke_photos/20830907-713a-4ac7-8205-7325f47e5df5/0c5fc608-e90c-48f6-aa09-662ddbffc462",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F20830907-713a-4ac7-8205-7325f47e5df5%2F0c5fc608-e90c-48f6-aa09-662ddbffc462?alt=media&token=fa9b2cfe-5adc-440a-a02e-482e207f7878"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/20830907-713a-4ac7-8205-7325f47e5df5/600x600/0c5fc608-e90c-48f6-aa09-662ddbffc462_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F20830907-713a-4ac7-8205-7325f47e5df5%2F600x600%2F0c5fc608-e90c-48f6-aa09-662ddbffc462_600x600?alt=media&token=201f8497-c45e-4464-abe4-d2f5636dc7a7"
      }
    }
  },
  {
    "order_number": 326,
    "name": "Upside Swirly",
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107"
    },
    "description": "<p>Head is not round... So many other problems</p>",
    "id": "20bd7ee2-a0d5-4f34-abc1-ed8ab01288e9",
    "tool": null,
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/20bd7ee2-a0d5-4f34-abc1-ed8ab01288e9/600x600/e5466ab0-9793-4c3c-ae5e-da3988b0e34e_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F20bd7ee2-a0d5-4f34-abc1-ed8ab01288e9%2F600x600%2Fe5466ab0-9793-4c3c-ae5e-da3988b0e34e_600x600?alt=media&token=8a925780-0a79-497a-985f-a6430d50f1a5"
      },
      "original": {
        "reference": "/stroke_photos/20bd7ee2-a0d5-4f34-abc1-ed8ab01288e9/e5466ab0-9793-4c3c-ae5e-da3988b0e34e",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F20bd7ee2-a0d5-4f34-abc1-ed8ab01288e9%2Fe5466ab0-9793-4c3c-ae5e-da3988b0e34e?alt=media&token=9082c4fc-63ba-49d2-aa49-2854398cbf1a"
      }
    }
  },
  {
    "order_number": 489,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F20eca9d2-717d-4d2c-bf51-b50dbd24c764%2Fdfbd2336-975c-4716-ad81-0cd7d858d510?alt=media&token=7102d7ed-06b1-4786-8c3d-830be5160a16",
        "reference": "/stroke_photos/20eca9d2-717d-4d2c-bf51-b50dbd24c764/dfbd2336-975c-4716-ad81-0cd7d858d510"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/20eca9d2-717d-4d2c-bf51-b50dbd24c764/600x600/dfbd2336-975c-4716-ad81-0cd7d858d510_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F20eca9d2-717d-4d2c-bf51-b50dbd24c764%2F600x600%2Fdfbd2336-975c-4716-ad81-0cd7d858d510_600x600?alt=media&token=f0045f7d-5ff4-44b5-b0ae-0868ca1f22d0"
      }
    },
    "paper": {
      "name": null,
      "gsm": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "brand": {},
      "original_size": "A4",
      "brand_model": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": null
    },
    "id": "20eca9d2-717d-4d2c-bf51-b50dbd24c764",
    "description": "<p>I want to make something that looks less of a hook more of a parenthesis-shape.</p>",
    "tool": null,
    "order_id": 0,
    "name": "Parenthesis White"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/21382759-f1a7-4e75-baad-bfdd5ab3e5a1/f9c67c04-bb33-4548-a3b5-17ef75a1ad54",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F21382759-f1a7-4e75-baad-bfdd5ab3e5a1%2Ff9c67c04-bb33-4548-a3b5-17ef75a1ad54?alt=media&token=df3d63ff-3341-4933-a4a2-f9f88c7ce267"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/21382759-f1a7-4e75-baad-bfdd5ab3e5a1/600x600/f9c67c04-bb33-4548-a3b5-17ef75a1ad54_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F21382759-f1a7-4e75-baad-bfdd5ab3e5a1%2F600x600%2Ff9c67c04-bb33-4548-a3b5-17ef75a1ad54_600x600?alt=media&token=6c96e4eb-7b96-4f9a-beec-2ceac880ad71"
      }
    },
    "order_number": 79,
    "tool": null,
    "paper": {
      "price": null,
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "gsm": "200",
      "brand_model": {
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "original_size": "A4",
      "ref": "44202129",
      "paper_surface": {},
      "name": "",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "name": "QP 3",
    "order_id": 0,
    "description": "",
    "id": "21382759-f1a7-4e75-baad-bfdd5ab3e5a1"
  },
  {
    "id": "213a32c9-740f-4237-bf51-f36b58c1bdc9",
    "order_number": 235,
    "name": "First Wipeoff The Back",
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/213a32c9-740f-4237-bf51-f36b58c1bdc9/989c18e5-a5dd-4f7d-a7ef-d6cbfaa4def4",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F213a32c9-740f-4237-bf51-f36b58c1bdc9%2F989c18e5-a5dd-4f7d-a7ef-d6cbfaa4def4?alt=media&token=69df022f-7c3b-4658-837f-32e41869f493"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/213a32c9-740f-4237-bf51-f36b58c1bdc9/600x600/989c18e5-a5dd-4f7d-a7ef-d6cbfaa4def4_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F213a32c9-740f-4237-bf51-f36b58c1bdc9%2F600x600%2F989c18e5-a5dd-4f7d-a7ef-d6cbfaa4def4_600x600?alt=media&token=ed3eb5ea-cf68-491a-b7c3-789e198b7211"
      }
    },
    "paper": {
      "original_size": "A7",
      "ref": "77725080001",
      "gsm": "250",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      }
    },
    "tool": null,
    "description": ""
  },
  {
    "description": "",
    "name": "Blank",
    "id": "215cb3a5-64e0-4e3f-91d4-cc5f3b0764f3",
    "order_id": 0,
    "paper": {
      "gsm": "250",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "brand": {},
      "name": null,
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper"
      },
      "ref": "20170703",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": 0.4
    },
    "order_number": 812
  },
  {
    "order_number": 1017,
    "description": "",
    "name": "",
    "id": "215f5814-acdd-49c1-bb7c-ab2dd169aae6"
  },
  {
    "description": "Smoother but terrible arch",
    "id": "21725d6f-0b0d-4eda-8499-6a2755028367",
    "order_number": 239,
    "order_id": 0,
    "paper": {
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "ref": "77725080001",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "gsm": "250",
      "original_size": "A7",
      "price": 1.35
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F21725d6f-0b0d-4eda-8499-6a2755028367%2F600x600%2F8da2ab53-26f1-49f1-973f-023b55e333aa_600x600?alt=media&token=42160a65-2522-492e-a2de-3cc0f4d3414a",
        "reference": "stroke_photos/21725d6f-0b0d-4eda-8499-6a2755028367/600x600/8da2ab53-26f1-49f1-973f-023b55e333aa_600x600"
      },
      "original": {
        "reference": "/stroke_photos/21725d6f-0b0d-4eda-8499-6a2755028367/8da2ab53-26f1-49f1-973f-023b55e333aa",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F21725d6f-0b0d-4eda-8499-6a2755028367%2F8da2ab53-26f1-49f1-973f-023b55e333aa?alt=media&token=7cfa79c7-90ce-43b8-8951-15e0abe91b6d"
      }
    },
    "tool": null,
    "name": "Musically 4"
  },
  {
    "order_number": 1026,
    "description": "",
    "name": "",
    "id": "2179b384-eeb6-4880-bca2-42053bc45a5e"
  },
  {
    "tool": null,
    "order_id": 0,
    "order_number": 617,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/218164a8-e4d1-4579-bd83-ff5adb54e61b/600x600/4d9860f2-798a-4bb6-84a5-47dcef44d9bd_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F218164a8-e4d1-4579-bd83-ff5adb54e61b%2F600x600%2F4d9860f2-798a-4bb6-84a5-47dcef44d9bd_600x600?alt=media&token=42a1c509-b9bf-4213-8075-95453c1c55c8"
      },
      "original": {
        "reference": "/stroke_photos/218164a8-e4d1-4579-bd83-ff5adb54e61b/4d9860f2-798a-4bb6-84a5-47dcef44d9bd",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F218164a8-e4d1-4579-bd83-ff5adb54e61b%2F4d9860f2-798a-4bb6-84a5-47dcef44d9bd?alt=media&token=e8d3d7f9-0946-4c98-85d5-448260abbff8"
      }
    },
    "paper": {
      "brand": {},
      "ref": "70025010030",
      "color": {
        "hex": "#f5f5f5",
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "brand_model": {},
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "original_size": "A7",
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea"
    },
    "id": "218164a8-e4d1-4579-bd83-ff5adb54e61b",
    "description": "",
    "name": "Blank"
  },
  {
    "order_number": 599,
    "name": "Blank",
    "paper": {
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {},
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "70025010030",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "gsm": "250",
      "brand": {}
    },
    "id": "2182767b-8372-47d8-9b63-cf75713cef49",
    "tool": null,
    "order_id": 0,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2182767b-8372-47d8-9b63-cf75713cef49%2F600x600%2Fd0b11990-234f-4b24-9dc6-ab224302a28b_600x600?alt=media&token=db934227-b308-42f6-bb70-2b2ce7b04392",
        "reference": "stroke_photos/2182767b-8372-47d8-9b63-cf75713cef49/600x600/d0b11990-234f-4b24-9dc6-ab224302a28b_600x600"
      },
      "original": {
        "reference": "/stroke_photos/2182767b-8372-47d8-9b63-cf75713cef49/d0b11990-234f-4b24-9dc6-ab224302a28b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2182767b-8372-47d8-9b63-cf75713cef49%2Fd0b11990-234f-4b24-9dc6-ab224302a28b?alt=media&token=c3cc19da-7cc2-4a71-8ec4-32fd76bdb096"
      }
    }
  },
  {
    "paper": {
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "20180107",
      "original_size": "A7",
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "name": null,
      "gsm": "250",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      }
    },
    "order_number": 110,
    "name": "Attempt for Smoothness",
    "description": "",
    "tool": null,
    "id": "2184782b-1ad7-4290-ac0d-8661de50441e",
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2184782b-1ad7-4290-ac0d-8661de50441e%2F68732231-24a6-4ab4-8c37-5cff9f82832d?alt=media&token=e2d3943c-de8c-49aa-8bba-a7de5adafbae",
        "reference": "/stroke_photos/2184782b-1ad7-4290-ac0d-8661de50441e/68732231-24a6-4ab4-8c37-5cff9f82832d"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/2184782b-1ad7-4290-ac0d-8661de50441e/600x600/68732231-24a6-4ab4-8c37-5cff9f82832d_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2184782b-1ad7-4290-ac0d-8661de50441e%2F600x600%2F68732231-24a6-4ab4-8c37-5cff9f82832d_600x600?alt=media&token=6b0a1faf-f964-4404-b54e-18b189fa500d"
      }
    }
  },
  {
    "order_number": 159,
    "name": "Blank",
    "order_id": 0,
    "tool": null,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F21ad1ff3-772f-4096-b11a-a00d15fe1377%2F600x600%2Fdcc680ce-68d6-4716-9a70-fee1dd91d273_600x600?alt=media&token=3e3d8ee0-a4e6-4541-bbb1-fd196bbbc993",
        "reference": "stroke_photos/21ad1ff3-772f-4096-b11a-a00d15fe1377/600x600/dcc680ce-68d6-4716-9a70-fee1dd91d273_600x600"
      },
      "original": {
        "reference": "/stroke_photos/21ad1ff3-772f-4096-b11a-a00d15fe1377/dcc680ce-68d6-4716-9a70-fee1dd91d273",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F21ad1ff3-772f-4096-b11a-a00d15fe1377%2Fdcc680ce-68d6-4716-9a70-fee1dd91d273?alt=media&token=0afdf406-cf65-4fad-953f-7540291524d9"
      }
    },
    "id": "21ad1ff3-772f-4096-b11a-a00d15fe1377",
    "paper": {
      "original_size": "A7",
      "price": 1.35,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "gsm": "250",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "ref": "77725080001",
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    }
  },
  {
    "tool": null,
    "id": "221c89b4-2677-478d-897b-066bab75f10d",
    "order_id": 0,
    "description": "<p>So far off...</p>",
    "photos": {
      "original": {
        "reference": "/stroke_photos/221c89b4-2677-478d-897b-066bab75f10d/a8bc9075-d800-41c2-af0e-4e7496ba5d6d",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F221c89b4-2677-478d-897b-066bab75f10d%2Fa8bc9075-d800-41c2-af0e-4e7496ba5d6d?alt=media&token=f7b6604d-acb0-4f48-88a0-c91b686a6cc8"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F221c89b4-2677-478d-897b-066bab75f10d%2F600x600%2Fa8bc9075-d800-41c2-af0e-4e7496ba5d6d_600x600?alt=media&token=cea2722a-4f7e-47fc-8fae-ec24b0a1df11",
        "reference": "stroke_photos/221c89b4-2677-478d-897b-066bab75f10d/600x600/a8bc9075-d800-41c2-af0e-4e7496ba5d6d_600x600"
      }
    },
    "paper": {
      "price": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "20180107",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top"
      },
      "name": null,
      "brand": {},
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "gsm": "250",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "name": "Another Weird Ensoishness",
    "order_number": 380
  },
  {
    "name": "Blank",
    "paper": {
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "250",
      "brand_model": {},
      "original_size": "A7",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl",
        "hex": "#f5f5f5"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "ref": "70025010030",
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {}
    },
    "order_id": 0,
    "order_number": 834,
    "id": "22466dd4-3dc2-4c6d-b25d-21294bd3b8e5",
    "description": ""
  },
  {
    "order_id": 0,
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "original_size": "A7",
      "name": null,
      "price": 0.4,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "ref": "20170703",
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b"
    },
    "order_number": 728,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/22606d2a-dd67-4091-a03d-6ca2fe74a35b/600x600/60c14415-23cd-43de-8227-c11bda9ef214_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F22606d2a-dd67-4091-a03d-6ca2fe74a35b%2F600x600%2F60c14415-23cd-43de-8227-c11bda9ef214_600x600?alt=media&token=56c56b04-4789-4538-b319-cf9412c253b8"
      },
      "original": {
        "reference": "/stroke_photos/22606d2a-dd67-4091-a03d-6ca2fe74a35b/60c14415-23cd-43de-8227-c11bda9ef214",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F22606d2a-dd67-4091-a03d-6ca2fe74a35b%2F60c14415-23cd-43de-8227-c11bda9ef214?alt=media&token=00b329d3-30ce-419e-899d-085bfcc6df0e"
      }
    },
    "name": "Blank",
    "tool": null,
    "description": "",
    "id": "22606d2a-dd67-4091-a03d-6ca2fe74a35b"
  },
  {
    "description": "<p>I like it when there is improvement</p>",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/2269d7fd-e59a-454b-9ee0-f16ef5fbf0b8/600x600/fdf9b780-853a-433b-a274-d36b15d5e787_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2269d7fd-e59a-454b-9ee0-f16ef5fbf0b8%2F600x600%2Ffdf9b780-853a-433b-a274-d36b15d5e787_600x600?alt=media&token=0cdd54c2-1a48-40ad-a02a-96a1cd0f29c5"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2269d7fd-e59a-454b-9ee0-f16ef5fbf0b8%2Ffdf9b780-853a-433b-a274-d36b15d5e787?alt=media&token=9703c2d1-ba6b-499d-a8d7-55e8282cadb1",
        "reference": "/stroke_photos/2269d7fd-e59a-454b-9ee0-f16ef5fbf0b8/fdf9b780-853a-433b-a274-d36b15d5e787"
      }
    },
    "order_number": 267,
    "name": "Wide Stroke Bigger Mini",
    "paper": {
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": null,
      "original_size": "A7",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "20180107",
      "gsm": "250",
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "order_id": 0,
    "id": "2269d7fd-e59a-454b-9ee0-f16ef5fbf0b8"
  },
  {
    "name": "Blank",
    "order_id": 0,
    "description": "",
    "paper": {
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": 0.4,
      "name": null,
      "ref": "20170703",
      "gsm": "250",
      "original_size": "A7",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      }
    },
    "id": "2286828f-6acf-4f68-8ac7-7063e9313370",
    "order_number": 573,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2286828f-6acf-4f68-8ac7-7063e9313370%2F5dcffbca-25e7-4a8c-a2ea-e59741db01a1?alt=media&token=79f5d52d-a177-4d37-ace6-744791ed9c92",
        "reference": "/stroke_photos/2286828f-6acf-4f68-8ac7-7063e9313370/5dcffbca-25e7-4a8c-a2ea-e59741db01a1"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/2286828f-6acf-4f68-8ac7-7063e9313370/600x600/5dcffbca-25e7-4a8c-a2ea-e59741db01a1_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2286828f-6acf-4f68-8ac7-7063e9313370%2F600x600%2F5dcffbca-25e7-4a8c-a2ea-e59741db01a1_600x600?alt=media&token=da956ec2-0fb5-45d8-9570-d5df22334168"
      }
    },
    "tool": null
  },
  {
    "name": "Blank",
    "paper": {
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "gsm": "110",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "name": "Elephant Skin"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "ref": "4005329065767",
      "original_size": "A4",
      "price": 1,
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "name": null
    },
    "order_number": 895,
    "description": "",
    "order_id": 0,
    "id": "22ba351e-8a31-45fd-9b7f-bee759816a37"
  },
  {
    "id": "22bf0a05-856a-4106-9387-6517b1049844",
    "name": "White Splodge Touch 8",
    "photos": {
      "original": {
        "reference": "/stroke_photos/22bf0a05-856a-4106-9387-6517b1049844/b58be688-4198-4adc-8eb6-5062ce0135e6",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F22bf0a05-856a-4106-9387-6517b1049844%2Fb58be688-4198-4adc-8eb6-5062ce0135e6?alt=media&token=f564ddca-f395-4333-a026-e8ab4096f565"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F22bf0a05-856a-4106-9387-6517b1049844%2F600x600%2Fb58be688-4198-4adc-8eb6-5062ce0135e6_600x600?alt=media&token=2cd961f5-8ea0-4491-9fe8-a2eb7e7ec6d5",
        "reference": "stroke_photos/22bf0a05-856a-4106-9387-6517b1049844/600x600/b58be688-4198-4adc-8eb6-5062ce0135e6_600x600"
      }
    },
    "order_id": 0,
    "order_number": 518,
    "description": "<p>keep adding those, and I still see the paint</p>",
    "tool": null,
    "paper": {
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "original_size": "A4",
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": null,
      "gsm": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {},
      "price": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      }
    }
  },
  {
    "order_number": 1011,
    "description": "",
    "name": "",
    "id": "22eb5771-a541-4a85-8f0e-10d7434875ae"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/22ebbdca-4fd9-4223-946b-c4d5b88be046/b14298fc-c5f4-4163-ba2b-f0f4dec31152",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F22ebbdca-4fd9-4223-946b-c4d5b88be046%2Fb14298fc-c5f4-4163-ba2b-f0f4dec31152?alt=media&token=62636609-7717-4f6f-ac2a-0e7f12be5f4f"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F22ebbdca-4fd9-4223-946b-c4d5b88be046%2F600x600%2Fb14298fc-c5f4-4163-ba2b-f0f4dec31152_600x600?alt=media&token=292488a6-d23c-4eb0-b9b2-019c09728e26",
        "reference": "stroke_photos/22ebbdca-4fd9-4223-946b-c4d5b88be046/600x600/b14298fc-c5f4-4163-ba2b-f0f4dec31152_600x600"
      }
    },
    "paper": {
      "gsm": "250",
      "price": null,
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "name": null,
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      }
    },
    "id": "22ebbdca-4fd9-4223-946b-c4d5b88be046",
    "description": "<p>Love it. If I put more paint, but don't press the bursh much, I get this patchy texture of goodness</p>",
    "order_id": 0,
    "name": "Patchy",
    "tool": null,
    "order_number": 382
  },
  {
    "paper": {
      "ref": "30075105",
      "name": "tinto",
      "gsm": "640",
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico"
      },
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": 19.3,
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "original_size": "75x105cm",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163"
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2363a71c-677d-4d2e-9b1f-3fdb8054220a%2F2d37c5a3-4f2c-4b5f-8d1e-215c21c8136d?alt=media&token=b1154f6d-c26c-4439-a588-8e2b00a00824",
        "reference": "/stroke_photos/2363a71c-677d-4d2e-9b1f-3fdb8054220a/2d37c5a3-4f2c-4b5f-8d1e-215c21c8136d"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/2363a71c-677d-4d2e-9b1f-3fdb8054220a/600x600/2d37c5a3-4f2c-4b5f-8d1e-215c21c8136d_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2363a71c-677d-4d2e-9b1f-3fdb8054220a%2F600x600%2F2d37c5a3-4f2c-4b5f-8d1e-215c21c8136d_600x600?alt=media&token=1e17c6ae-c27d-4bc3-ba2d-a8303ad46d64"
      }
    },
    "tool": null,
    "order_id": 0,
    "order_number": 672,
    "name": "Blank",
    "id": "2363a71c-677d-4d2e-9b1f-3fdb8054220a",
    "description": ""
  },
  {
    "order_number": 1147,
    "name": "",
    "description": "",
    "id": "23be460b-0b0b-41e3-96e2-f8107d1ccee5"
  },
  {
    "id": "23ef3214-b5a6-4969-b54f-d4fd38d8d69c",
    "name": "Brushing SIdeways With Leftover Paint",
    "description": "There are some weird marks on it, almost as if it was scratched post-birth",
    "order_number": 411,
    "tool": null,
    "order_id": 0,
    "paper": {
      "gsm": "250",
      "price": 1.35,
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "original_size": "A7",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "ref": "77725080001",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F23ef3214-b5a6-4969-b54f-d4fd38d8d69c%2F600x600%2F4e5a13fb-079d-4d8f-995d-66e223be7b8a_600x600?alt=media&token=825e3c83-1ebd-4bd5-a323-97132bea6dd2",
        "reference": "stroke_photos/23ef3214-b5a6-4969-b54f-d4fd38d8d69c/600x600/4e5a13fb-079d-4d8f-995d-66e223be7b8a_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F23ef3214-b5a6-4969-b54f-d4fd38d8d69c%2F4e5a13fb-079d-4d8f-995d-66e223be7b8a?alt=media&token=ff0d15c7-8492-494c-96a3-48e2b196f812",
        "reference": "/stroke_photos/23ef3214-b5a6-4969-b54f-d4fd38d8d69c/4e5a13fb-079d-4d8f-995d-66e223be7b8a"
      }
    }
  },
  {
    "name": "",
    "order_number": 1124,
    "description": "",
    "id": "242dec56-23b7-427f-99c9-563dc692c46b"
  },
  {
    "id": "24c9c176-73a4-43ea-b334-a49cd62b08c4",
    "order_number": 372,
    "tool": null,
    "order_id": 0,
    "name": "A Bit Less Paint And Bam",
    "description": "<p>Quit artsy. Love it</p>",
    "photos": {
      "original": {
        "reference": "/stroke_photos/24c9c176-73a4-43ea-b334-a49cd62b08c4/7ac51b22-c1d6-4a6f-988a-df80b4f684d5",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F24c9c176-73a4-43ea-b334-a49cd62b08c4%2F7ac51b22-c1d6-4a6f-988a-df80b4f684d5?alt=media&token=bc3cd3d2-6e14-4d43-a170-96de6b1e379d"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/24c9c176-73a4-43ea-b334-a49cd62b08c4/600x600/7ac51b22-c1d6-4a6f-988a-df80b4f684d5_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F24c9c176-73a4-43ea-b334-a49cd62b08c4%2F600x600%2F7ac51b22-c1d6-4a6f-988a-df80b4f684d5_600x600?alt=media&token=a9674d46-2828-423c-b0d6-f5ea1e0b2039"
      }
    },
    "paper": {
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "ref": "20180107",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "name": null,
      "original_size": "A7",
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250"
    }
  },
  {
    "description": "",
    "id": "24d27663-40bb-4637-a7fb-e4f792ac23b1",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/24d27663-40bb-4637-a7fb-e4f792ac23b1/600x600/37a27db1-313b-4784-a04b-a863e6072109_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F24d27663-40bb-4637-a7fb-e4f792ac23b1%2F600x600%2F37a27db1-313b-4784-a04b-a863e6072109_600x600?alt=media&token=f0b32033-685c-4042-b42a-c3b56092d405"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F24d27663-40bb-4637-a7fb-e4f792ac23b1%2F37a27db1-313b-4784-a04b-a863e6072109?alt=media&token=f46f17ab-1c0a-4836-9420-d72df08d0549",
        "reference": "/stroke_photos/24d27663-40bb-4637-a7fb-e4f792ac23b1/37a27db1-313b-4784-a04b-a863e6072109"
      }
    },
    "order_id": 0,
    "name": "Blank",
    "tool": null,
    "order_number": 653,
    "paper": {
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "ref": "30075105",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "75x105cm",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "price": 19.3,
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "gsm": "640",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "name": "tinto",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163"
    }
  },
  {
    "order_number": 942,
    "description": "",
    "name": "Blank",
    "order_id": 0,
    "id": "24d49ce1-df80-4109-ae27-ee90b1e35078",
    "paper": {
      "ref": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "original_size": "A4",
      "brand_model": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "gsm": null,
      "brand": {}
    }
  },
  {
    "id": "259aa6aa-379b-429c-9394-1e8acfd3921d",
    "order_number": 904,
    "name": "Blank",
    "description": "",
    "order_id": 0,
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "gsm": null,
      "original_size": "A4",
      "brand": {},
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "price": null,
      "brand_model": {},
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    }
  },
  {
    "order_number": 992,
    "order_id": 0,
    "description": "",
    "id": "25bba2b5-8e85-4976-bde8-75a9f803c757",
    "paper": {
      "brand_model": {
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "price": 19.3,
      "original_size": "75x105cm",
      "ref": "30075105",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "gsm": "640",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": "tinto",
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      }
    },
    "name": "Blank"
  },
  {
    "name": "Popf Less Paint",
    "order_number": 22,
    "id": "25cef7f1-d5d5-4e40-98c0-55bb9ace89c9",
    "order_id": 0,
    "tool": {},
    "description": "",
    "paper": {
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "gsm": "200",
      "original_size": "A4",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "44202129",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": "",
      "paper_surface": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": null
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F25cef7f1-d5d5-4e40-98c0-55bb9ace89c9%2F53eb97aa-5d57-483e-ad8c-d9f03a4dd887?alt=media&token=28b420c5-5719-4906-9bad-3bf09ffdf440",
        "reference": "/stroke_photos/25cef7f1-d5d5-4e40-98c0-55bb9ace89c9/53eb97aa-5d57-483e-ad8c-d9f03a4dd887"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/25cef7f1-d5d5-4e40-98c0-55bb9ace89c9/600x600/53eb97aa-5d57-483e-ad8c-d9f03a4dd887_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F25cef7f1-d5d5-4e40-98c0-55bb9ace89c9%2F600x600%2F53eb97aa-5d57-483e-ad8c-d9f03a4dd887_600x600?alt=media&token=ca721c10-4c36-4746-ac80-15433a78fb29"
      }
    }
  },
  {
    "order_number": 810,
    "paper": {
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "name": null,
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "20170703",
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": 0.4
    },
    "description": "",
    "order_id": 0,
    "id": "25f370ae-e2aa-49b1-99e0-51688af257b1",
    "name": "Blank"
  },
  {
    "description": "",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/26096dee-17d7-42ce-8172-df72d3ce34c5/600x600/96de4ef3-7c93-4d23-b731-6ef77fdb088c_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F26096dee-17d7-42ce-8172-df72d3ce34c5%2F600x600%2F96de4ef3-7c93-4d23-b731-6ef77fdb088c_600x600?alt=media&token=e8856fde-1e51-4af5-a436-ed7a93bffbcf"
      },
      "original": {
        "reference": "/stroke_photos/26096dee-17d7-42ce-8172-df72d3ce34c5/96de4ef3-7c93-4d23-b731-6ef77fdb088c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F26096dee-17d7-42ce-8172-df72d3ce34c5%2F96de4ef3-7c93-4d23-b731-6ef77fdb088c?alt=media&token=3c4eac8d-2a22-474d-8289-36bdee8411d0"
      }
    },
    "paper": {
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "brand": {},
      "original_size": "A7",
      "name": null,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "ref": "20170703",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "250",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": 0.4,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "name": "Blank",
    "order_number": 713,
    "tool": null,
    "id": "26096dee-17d7-42ce-8172-df72d3ce34c5"
  },
  {
    "name": "",
    "order_number": 1040,
    "description": "",
    "id": "2625a263-cc9f-448b-beaf-a65a634bb9e7"
  },
  {
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": null,
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {},
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "name": null,
      "gsm": null,
      "brand": {}
    },
    "order_id": 0,
    "id": "2636a90b-fa57-4802-acf8-0dfcc5bcf87d",
    "description": "",
    "order_number": 948,
    "name": "Blank"
  },
  {
    "name": "",
    "order_number": 1192,
    "description": "",
    "id": "264e997a-eee4-4bd0-b8cd-d5c6c4f4b4f5"
  },
  {
    "id": "26628b8b-55a8-4641-a26c-2cef70a70a22",
    "order_number": 532,
    "order_id": 0,
    "name": "Silver-On-Black Regular 7",
    "paper": {
      "gsm": null,
      "brand": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "brand_model": {},
      "price": null,
      "ref": null,
      "original_size": "A4"
    },
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F26628b8b-55a8-4641-a26c-2cef70a70a22%2F600x600%2Fa516b92b-2cd8-4985-8efb-d3fae71fef05_600x600?alt=media&token=0d274970-5c0c-4585-81b1-02417d31a0e0",
        "reference": "stroke_photos/26628b8b-55a8-4641-a26c-2cef70a70a22/600x600/a516b92b-2cd8-4985-8efb-d3fae71fef05_600x600"
      },
      "original": {
        "reference": "/stroke_photos/26628b8b-55a8-4641-a26c-2cef70a70a22/a516b92b-2cd8-4985-8efb-d3fae71fef05",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F26628b8b-55a8-4641-a26c-2cef70a70a22%2Fa516b92b-2cd8-4985-8efb-d3fae71fef05?alt=media&token=881b6ffb-518e-443d-adea-fac0110c5437"
      }
    },
    "description": "<p>Back to standard ones (!) yuk. Lost the arc, the direction, the gradient, the everything</p>"
  },
  {
    "paper": {
      "gsm": null,
      "brand_model": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {},
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "ref": null
    },
    "name": "White Splodge Touch 10",
    "order_number": 520,
    "order_id": 0,
    "tool": null,
    "id": "266cde5a-204b-4c02-a488-904e4743b3a3",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F266cde5a-204b-4c02-a488-904e4743b3a3%2F600x600%2F8e8837ee-d4ae-4e9b-9c7d-b401caa86178_600x600?alt=media&token=f1255c51-4ffe-4b8d-800d-a216a07f18f0",
        "reference": "stroke_photos/266cde5a-204b-4c02-a488-904e4743b3a3/600x600/8e8837ee-d4ae-4e9b-9c7d-b401caa86178_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F266cde5a-204b-4c02-a488-904e4743b3a3%2F8e8837ee-d4ae-4e9b-9c7d-b401caa86178?alt=media&token=566ee352-531c-4660-8d4a-f05c6198c4c9",
        "reference": "/stroke_photos/266cde5a-204b-4c02-a488-904e4743b3a3/8e8837ee-d4ae-4e9b-9c7d-b401caa86178"
      }
    },
    "description": "<p>Barely visible. Very very nice. I am happy with results!</p>"
  },
  {
    "tool": null,
    "name": "Retry 2",
    "order_number": 274,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F26945832-b349-435f-8372-e9766f1737d2%2F600x600%2Fa2de06e9-198f-435e-a612-26e411760540_600x600?alt=media&token=08a1d196-6360-4f3b-9202-5196f37dea91",
        "reference": "stroke_photos/26945832-b349-435f-8372-e9766f1737d2/600x600/a2de06e9-198f-435e-a612-26e411760540_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F26945832-b349-435f-8372-e9766f1737d2%2Fa2de06e9-198f-435e-a612-26e411760540?alt=media&token=56028610-06d2-44ff-9755-e1f1e7ed9e2a",
        "reference": "/stroke_photos/26945832-b349-435f-8372-e9766f1737d2/a2de06e9-198f-435e-a612-26e411760540"
      }
    },
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "price": null,
      "brand": {},
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "name": null,
      "ref": "20180107"
    },
    "id": "26945832-b349-435f-8372-e9766f1737d2",
    "order_id": 0,
    "description": "<p>Somehow my hend gets glued for the desk, I have to lift it up.</p>"
  },
  {
    "order_number": 1132,
    "description": "",
    "name": "",
    "id": "26e02f72-d990-471d-bc74-ca93455d8065"
  },
  {
    "description": "",
    "order_number": 1108,
    "name": "",
    "id": "26e27f85-e5ab-4560-aa86-ab412664355e"
  },
  {
    "tool": null,
    "order_number": 735,
    "paper": {
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "original_size": "A7",
      "name": null,
      "brand": {},
      "gsm": "250",
      "ref": "20170703",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": 0.4
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/26e56114-7c08-4938-95dd-70021ea5029b/600x600/b622c638-6dc2-4f30-adc0-dc96aa770c94_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F26e56114-7c08-4938-95dd-70021ea5029b%2F600x600%2Fb622c638-6dc2-4f30-adc0-dc96aa770c94_600x600?alt=media&token=514a2956-4d17-4cef-9f7b-10dba58cd7d5"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F26e56114-7c08-4938-95dd-70021ea5029b%2Fb622c638-6dc2-4f30-adc0-dc96aa770c94?alt=media&token=7885e723-97ec-4525-a967-365acb7502ad",
        "reference": "/stroke_photos/26e56114-7c08-4938-95dd-70021ea5029b/b622c638-6dc2-4f30-adc0-dc96aa770c94"
      }
    },
    "description": "",
    "name": "Blank",
    "order_id": 0,
    "id": "26e56114-7c08-4938-95dd-70021ea5029b"
  },
  {
    "description": "",
    "order_number": 1201,
    "name": "",
    "id": "2701a7c8-e9a9-4032-8e0f-479204a797e9"
  },
  {
    "description": "",
    "order_number": 1055,
    "name": "",
    "id": "273174cb-908d-43ff-888e-1ce975be8711"
  },
  {
    "tool": null,
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "name": null,
      "ref": "20180107",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "price": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand": {},
      "gsm": "250",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      }
    },
    "order_id": 0,
    "order_number": 302,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2742813b-f30a-4b3f-8271-2cf7be38fdbb%2F740d4340-7680-4f46-b0e5-aa084131b17c?alt=media&token=a86c7ac7-79ff-407c-b9d5-43c7959bb826",
        "reference": "/stroke_photos/2742813b-f30a-4b3f-8271-2cf7be38fdbb/740d4340-7680-4f46-b0e5-aa084131b17c"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2742813b-f30a-4b3f-8271-2cf7be38fdbb%2F600x600%2F740d4340-7680-4f46-b0e5-aa084131b17c_600x600?alt=media&token=51db9fa2-ab81-47c5-b2d7-4b7f026b6b61",
        "reference": "stroke_photos/2742813b-f30a-4b3f-8271-2cf7be38fdbb/600x600/740d4340-7680-4f46-b0e5-aa084131b17c_600x600"
      }
    },
    "purchase": {
      "status": "bought",
      "date": {
        "seconds": 1677878115,
        "nanoseconds": 78000000
      },
      "buyer": {
        "firstName": "Nikola",
        "email": "nikola.b.petrov@gmail.com",
        "lastName": "Petrov"
      },
      "anonymous": false,
      "internalDescription": ""
    },
    "name": "Hungry",
    "description": "<p>Something about it I like, but not sure what it is. One more</p>",
    "id": "2742813b-f30a-4b3f-8271-2cf7be38fdbb"
  },
  {
    "id": "27841a83-2a7c-444b-b931-227e4171d8e8",
    "name": "Blank",
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/27841a83-2a7c-444b-b931-227e4171d8e8/0a206a1a-b632-4e4e-a2ef-95c4bde68970",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F27841a83-2a7c-444b-b931-227e4171d8e8%2F0a206a1a-b632-4e4e-a2ef-95c4bde68970?alt=media&token=b5561b59-dbc8-4aea-bee3-82e753f15c52"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F27841a83-2a7c-444b-b931-227e4171d8e8%2F600x600%2F0a206a1a-b632-4e4e-a2ef-95c4bde68970_600x600?alt=media&token=55189df2-7cc7-4ffa-bb70-64d43f80f970",
        "reference": "stroke_photos/27841a83-2a7c-444b-b931-227e4171d8e8/600x600/0a206a1a-b632-4e4e-a2ef-95c4bde68970_600x600"
      }
    },
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "20170703",
      "gsm": "250",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": 0.4,
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "original_size": "A7"
    },
    "description": "",
    "tool": null,
    "order_number": 578
  },
  {
    "order_number": 32,
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "name": "",
      "original_size": "A4",
      "paper_surface": {},
      "gsm": "200",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "ref": "44202129",
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4"
    },
    "order_id": 0,
    "tool": {},
    "name": "Fast Stroke - Really?",
    "description": "",
    "id": "2872f77a-eaed-4679-bc95-d011b6ffedab",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/2872f77a-eaed-4679-bc95-d011b6ffedab/600x600/b521a3e2-cc1d-4deb-ac7b-df1fc5efcb99_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2872f77a-eaed-4679-bc95-d011b6ffedab%2F600x600%2Fb521a3e2-cc1d-4deb-ac7b-df1fc5efcb99_600x600?alt=media&token=8ba9640b-4f5d-45e0-bb82-7cc46229f775"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2872f77a-eaed-4679-bc95-d011b6ffedab%2Fb521a3e2-cc1d-4deb-ac7b-df1fc5efcb99?alt=media&token=ae82ed9d-c2b8-437e-a1ec-91916379400f",
        "reference": "/stroke_photos/2872f77a-eaed-4679-bc95-d011b6ffedab/b521a3e2-cc1d-4deb-ac7b-df1fc5efcb99"
      }
    }
  },
  {
    "description": "<p>Let's try an Enso. Damn! Don't tell anyone I wanted Enso, I will just call it circle</p>",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F288ff88c-bb70-46dc-a0e4-1b04680e3ae0%2Fe0c801a5-2e2e-4c45-8110-9d5cb8da1fc6?alt=media&token=831bee57-87e7-4525-b79d-50ca06cffeb7",
        "reference": "/stroke_photos/288ff88c-bb70-46dc-a0e4-1b04680e3ae0/e0c801a5-2e2e-4c45-8110-9d5cb8da1fc6"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/288ff88c-bb70-46dc-a0e4-1b04680e3ae0/600x600/e0c801a5-2e2e-4c45-8110-9d5cb8da1fc6_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F288ff88c-bb70-46dc-a0e4-1b04680e3ae0%2F600x600%2Fe0c801a5-2e2e-4c45-8110-9d5cb8da1fc6_600x600?alt=media&token=2f2d51b5-6498-4865-a743-7e412b44c4d0"
      }
    },
    "tool": null,
    "name": "Circle",
    "order_id": 0,
    "order_number": 337,
    "id": "288ff88c-bb70-46dc-a0e4-1b04680e3ae0",
    "paper": {
      "gsm": "250",
      "original_size": "A7",
      "ref": "20180107",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      }
    }
  },
  {
    "id": "28914177-0b6d-47d3-80bd-c374112e4dd8",
    "tool": null,
    "order_number": 742,
    "paper": {
      "original_size": "A7",
      "price": 0.4,
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "ref": "20170703",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250"
    },
    "order_id": 0,
    "description": "",
    "name": "Blank",
    "photos": {
      "original": {
        "reference": "/stroke_photos/28914177-0b6d-47d3-80bd-c374112e4dd8/bd9f2e64-c9b0-4b5a-9d1f-12b1aff0baf9",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F28914177-0b6d-47d3-80bd-c374112e4dd8%2Fbd9f2e64-c9b0-4b5a-9d1f-12b1aff0baf9?alt=media&token=5b2d38f1-92c3-4b27-9948-43750d583c37"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F28914177-0b6d-47d3-80bd-c374112e4dd8%2F600x600%2Fbd9f2e64-c9b0-4b5a-9d1f-12b1aff0baf9_600x600?alt=media&token=ebf486a1-b089-4477-9a0a-067d4f8ea837",
        "reference": "stroke_photos/28914177-0b6d-47d3-80bd-c374112e4dd8/600x600/bd9f2e64-c9b0-4b5a-9d1f-12b1aff0baf9_600x600"
      }
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1222,
    "id": "28b008f3-3e68-49ca-af3c-bb060479dff9"
  },
  {
    "description": "Better hit, not sure about results",
    "name": "Pendulum 2",
    "id": "28b83d7b-4bde-4fa6-bde0-466a563ffd91",
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "price": 1.35,
      "ref": "77725080001",
      "original_size": "A7"
    },
    "order_number": 438,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/28b83d7b-4bde-4fa6-bde0-466a563ffd91/600x600/ce97ee35-f522-4c0c-88e0-6f6414edd90b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F28b83d7b-4bde-4fa6-bde0-466a563ffd91%2F600x600%2Fce97ee35-f522-4c0c-88e0-6f6414edd90b_600x600?alt=media&token=40ef810c-6cdb-4618-aab3-119976b83a0a"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F28b83d7b-4bde-4fa6-bde0-466a563ffd91%2Fce97ee35-f522-4c0c-88e0-6f6414edd90b?alt=media&token=3a3fc675-7f8b-4c19-9385-93feafbe2528",
        "reference": "/stroke_photos/28b83d7b-4bde-4fa6-bde0-466a563ffd91/ce97ee35-f522-4c0c-88e0-6f6414edd90b"
      }
    },
    "tool": null,
    "order_id": 0
  },
  {
    "order_id": 0,
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/28d35711-4bbd-4af4-9009-0b1a6655f862/e78a8ac6-65b1-4e46-bcde-897762ea6fa2",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F28d35711-4bbd-4af4-9009-0b1a6655f862%2Fe78a8ac6-65b1-4e46-bcde-897762ea6fa2?alt=media&token=d2e74e47-1d70-4273-aad2-c96ca1693afc"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/28d35711-4bbd-4af4-9009-0b1a6655f862/600x600/e78a8ac6-65b1-4e46-bcde-897762ea6fa2_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F28d35711-4bbd-4af4-9009-0b1a6655f862%2F600x600%2Fe78a8ac6-65b1-4e46-bcde-897762ea6fa2_600x600?alt=media&token=e5800214-649f-436b-8f8b-4fceb4242b07"
      }
    },
    "paper": {
      "original_size": "A4",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {},
      "price": null,
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "ref": "44202129",
      "gsm": "200",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "name": ""
    },
    "id": "28d35711-4bbd-4af4-9009-0b1a6655f862",
    "order_number": 76,
    "tool": null,
    "name": "SWOB Flatter"
  },
  {
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "paper_surface": {},
      "ref": "44202129",
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo"
      },
      "gsm": "200",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "name": "",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      }
    },
    "purchase": {
      "internalDescription": "first buyer",
      "buyer": {
        "email": "dobromir.v.zlatkov@gmail.com",
        "lastName": "Zlatkov",
        "firstName": "Dobromir"
      },
      "anonymous": false,
      "status": "bought",
      "date": "2021-03-01T00:00:00.000Z"
    },
    "name": "The Very First",
    "tool": {},
    "order_number": 1,
    "description": "This is the very first stroke from the collection. Bad shape, wrong colors, shape, direction, width... pretty much everything.",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/293b909b-0eaa-401b-b15a-73b864b11c36/600x600/22426cff-af7d-4a76-b19d-d2e049fca431_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F293b909b-0eaa-401b-b15a-73b864b11c36%2F600x600%2F22426cff-af7d-4a76-b19d-d2e049fca431_600x600?alt=media&token=86ddc7fc-45d9-4688-888b-e13a60c1fde7"
      },
      "original": {
        "reference": "/stroke_photos/293b909b-0eaa-401b-b15a-73b864b11c36/22426cff-af7d-4a76-b19d-d2e049fca431",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F293b909b-0eaa-401b-b15a-73b864b11c36%2F22426cff-af7d-4a76-b19d-d2e049fca431?alt=media&token=702ee02a-b2f5-4848-b4c8-902f7c466bb1"
      }
    },
    "id": "293b909b-0eaa-401b-b15a-73b864b11c36"
  },
  {
    "paper": {
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "original_size": "A4",
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand_model": {
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "name": "Elephant Skin"
      },
      "gsm": "110",
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "name": null,
      "ref": "4005329065767",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": 1,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      }
    },
    "order_number": 851,
    "name": "Blank",
    "id": "2946af35-9e60-477c-91f9-ce86590c7750",
    "description": "",
    "order_id": 0
  },
  {
    "description": "",
    "name": "",
    "order_number": 1056,
    "id": "29bdd23c-0d8f-4801-af7f-cc02c241cbeb"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1114,
    "id": "29dd5b03-7555-4b01-93ac-fd84fc3e0d3b"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/29f1cac7-d687-497b-b938-cf7cb7c480db/7e6f8e69-524c-4969-9911-8e0d1fe11591",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F29f1cac7-d687-497b-b938-cf7cb7c480db%2F7e6f8e69-524c-4969-9911-8e0d1fe11591?alt=media&token=6a203798-2239-4f27-9c44-11a68747cd55"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/29f1cac7-d687-497b-b938-cf7cb7c480db/600x600/7e6f8e69-524c-4969-9911-8e0d1fe11591_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F29f1cac7-d687-497b-b938-cf7cb7c480db%2F600x600%2F7e6f8e69-524c-4969-9911-8e0d1fe11591_600x600?alt=media&token=90d61688-0ee9-4c7e-bab9-2c32d6273620"
      }
    },
    "order_number": 639,
    "name": "Blank",
    "tool": null,
    "order_id": 0,
    "id": "29f1cac7-d687-497b-b938-cf7cb7c480db",
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "brand": {},
      "brand_model": {},
      "ref": "70025010030",
      "original_size": "A7",
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea"
    },
    "description": ""
  },
  {
    "name": "",
    "description": "",
    "order_number": 1073,
    "id": "29ff3f3d-dfbb-48ba-a8be-a5c3f59da877"
  },
  {
    "description": "",
    "order_number": 1145,
    "name": "",
    "id": "2a126415-8dce-4e97-9773-b7ba2dadb195"
  },
  {
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "20170703",
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand": {},
      "price": 0.4,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "name": null,
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper"
      }
    },
    "order_id": 0,
    "id": "2a73ef51-d945-4b0f-8b42-593666e2300e",
    "name": "Blank",
    "order_number": 821,
    "description": ""
  },
  {
    "description": "",
    "id": "2a928876-c692-4853-8188-1f04e250d0aa",
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "brand_model": {},
      "brand": {},
      "gsm": null,
      "ref": null,
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": null
    },
    "order_number": 911,
    "order_id": 0,
    "name": "Blank"
  },
  {
    "order_id": 0,
    "description": "",
    "order_number": 654,
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/2ab170b6-249d-4602-814c-5ca42987d255/5cfe4cc6-a634-4b95-9014-0b3be24f4483",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2ab170b6-249d-4602-814c-5ca42987d255%2F5cfe4cc6-a634-4b95-9014-0b3be24f4483?alt=media&token=7f9c59ca-d4e0-488d-b86a-a9b89e9b80cf"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/2ab170b6-249d-4602-814c-5ca42987d255/600x600/5cfe4cc6-a634-4b95-9014-0b3be24f4483_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2ab170b6-249d-4602-814c-5ca42987d255%2F600x600%2F5cfe4cc6-a634-4b95-9014-0b3be24f4483_600x600?alt=media&token=baf420bb-705b-4f5d-99d6-4cb9ba08ae32"
      }
    },
    "name": "Blank",
    "id": "2ab170b6-249d-4602-814c-5ca42987d255",
    "paper": {
      "price": 19.3,
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "name": "tinto",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "ref": "30075105",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "gsm": "640",
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "original_size": "75x105cm"
    }
  },
  {
    "description": "",
    "id": "2ad107ec-a0f8-462b-90a9-30e2b9850bc3",
    "paper": {
      "name": "",
      "gsm": "200",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A4",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_surface": {},
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Accademia Designo"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "price": null,
      "ref": "44202129",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    },
    "order_id": 0,
    "tool": null,
    "name": "BS No Paint",
    "order_number": 38,
    "photos": {
      "original": {
        "reference": "/stroke_photos/2ad107ec-a0f8-462b-90a9-30e2b9850bc3/ed0d94cf-334b-4d35-b043-d9a05c057066",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2ad107ec-a0f8-462b-90a9-30e2b9850bc3%2Fed0d94cf-334b-4d35-b043-d9a05c057066?alt=media&token=1a66d4b2-a98f-4e0a-bdc9-5cd0f2aa4aaf"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2ad107ec-a0f8-462b-90a9-30e2b9850bc3%2F600x600%2Fed0d94cf-334b-4d35-b043-d9a05c057066_600x600?alt=media&token=15e9cbb4-0545-4c75-aa6e-57612bb3c98f",
        "reference": "stroke_photos/2ad107ec-a0f8-462b-90a9-30e2b9850bc3/600x600/ed0d94cf-334b-4d35-b043-d9a05c057066_600x600"
      }
    }
  },
  {
    "name": "Blank",
    "description": "",
    "order_id": 0,
    "order_number": 915,
    "paper": {
      "brand_model": {},
      "gsm": null,
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "original_size": "A4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "price": null,
      "name": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "id": "2adaf712-c9fb-4b69-9f6a-7e0b4d881c05"
  },
  {
    "order_id": 0,
    "id": "2b74bf2b-b2f2-4402-933f-f8ad0920e16f",
    "description": "",
    "paper": {
      "color": {
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl"
      },
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "original_size": "A7",
      "ref": "70025010030",
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand": {},
      "gsm": "250"
    },
    "name": "Blank",
    "order_number": 842
  },
  {
    "order_number": 307,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/2b8845f4-5a9a-4455-9493-3170b919c1d5/600x600/b20c2230-616c-47ec-9985-10cfd12c55ff_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2b8845f4-5a9a-4455-9493-3170b919c1d5%2F600x600%2Fb20c2230-616c-47ec-9985-10cfd12c55ff_600x600?alt=media&token=664e2a56-8fdd-44d4-b94d-7d51efa6e9ed"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2b8845f4-5a9a-4455-9493-3170b919c1d5%2Fb20c2230-616c-47ec-9985-10cfd12c55ff?alt=media&token=8017b093-5458-42f5-8ed4-a5bb63393ae1",
        "reference": "/stroke_photos/2b8845f4-5a9a-4455-9493-3170b919c1d5/b20c2230-616c-47ec-9985-10cfd12c55ff"
      }
    },
    "id": "2b8845f4-5a9a-4455-9493-3170b919c1d5",
    "tool": null,
    "order_id": 0,
    "name": "Okay",
    "paper": {
      "ref": "20180107",
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "name": null,
      "price": null,
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      }
    },
    "description": "<p>So I am getting the curve with this one, and the confidence, but somehow the paint is not acting right</p>"
  },
  {
    "id": "2bc2fc4b-addf-4d4f-858e-8df443adca77",
    "order_number": 490,
    "name": "Just a White Blob",
    "tool": null,
    "paper": {
      "brand_model": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "name": null,
      "price": null,
      "original_size": "A4",
      "brand": {},
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      }
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/2bc2fc4b-addf-4d4f-858e-8df443adca77/600x600/8c2c3f39-1882-4313-817c-8dc892a48ed2_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2bc2fc4b-addf-4d4f-858e-8df443adca77%2F600x600%2F8c2c3f39-1882-4313-817c-8dc892a48ed2_600x600?alt=media&token=46d0dd91-f224-4718-b9cd-c71062d72b24"
      },
      "original": {
        "reference": "/stroke_photos/2bc2fc4b-addf-4d4f-858e-8df443adca77/8c2c3f39-1882-4313-817c-8dc892a48ed2",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2bc2fc4b-addf-4d4f-858e-8df443adca77%2F8c2c3f39-1882-4313-817c-8dc892a48ed2?alt=media&token=0ae25bc8-7166-4c18-9abd-4f6da707321f"
      }
    },
    "order_id": 0,
    "description": "<p>Okay, somehow it is what it was planned</p>"
  },
  {
    "id": "2c346265-d53c-4a33-888b-ca080245f103",
    "order_number": 352,
    "description": "<p>Still not long enough</p>",
    "name": "Rainbow",
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "original_size": "A7",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "gsm": "250",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "ref": "20180107",
      "price": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2c346265-d53c-4a33-888b-ca080245f103%2F600x600%2F893e498d-0cb3-48b9-bfe0-660e9e06031e_600x600?alt=media&token=233348ca-c05a-4f63-b3d0-79d4e227353c",
        "reference": "stroke_photos/2c346265-d53c-4a33-888b-ca080245f103/600x600/893e498d-0cb3-48b9-bfe0-660e9e06031e_600x600"
      },
      "original": {
        "reference": "/stroke_photos/2c346265-d53c-4a33-888b-ca080245f103/893e498d-0cb3-48b9-bfe0-660e9e06031e",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2c346265-d53c-4a33-888b-ca080245f103%2F893e498d-0cb3-48b9-bfe0-660e9e06031e?alt=media&token=2d411088-0acc-416f-8099-b1ca7f8cf6eb"
      }
    },
    "order_id": 0,
    "tool": null
  },
  {
    "order_number": 1041,
    "name": "",
    "description": "",
    "id": "2c3cffa8-ea67-4d5a-ab63-b4b4d92801a3"
  },
  {
    "order_number": 272,
    "id": "2c469878-2bab-47d8-accf-6c05b20b14e1",
    "photos": {
      "original": {
        "reference": "/stroke_photos/2c469878-2bab-47d8-accf-6c05b20b14e1/921881d9-c4e7-4a72-8e46-729224e7ba0c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2c469878-2bab-47d8-accf-6c05b20b14e1%2F921881d9-c4e7-4a72-8e46-729224e7ba0c?alt=media&token=78c102b3-2c6d-4525-9841-574f7352e76d"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/2c469878-2bab-47d8-accf-6c05b20b14e1/600x600/921881d9-c4e7-4a72-8e46-729224e7ba0c_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2c469878-2bab-47d8-accf-6c05b20b14e1%2F600x600%2F921881d9-c4e7-4a72-8e46-729224e7ba0c_600x600?alt=media&token=7f03b1cb-1bac-47ea-b584-2a7358d4929e"
      }
    },
    "name": "Let's Try Thicker One",
    "description": "<p>Yikes, my hand got stuck in the middle of the stroke</p>",
    "order_id": 0,
    "tool": null,
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "original_size": "A7",
      "price": null,
      "ref": "20180107",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "gsm": "250",
      "name": null
    }
  },
  {
    "order_number": 1173,
    "name": "",
    "description": "",
    "id": "2c4756c2-fe60-4405-afb5-18022ae58cb1"
  },
  {
    "order_id": 0,
    "id": "2c55bafa-cb16-4122-b3bd-5005255f092b",
    "description": "",
    "order_number": 39,
    "name": "Press Forward So More Paint Stays In The Beginning",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2c55bafa-cb16-4122-b3bd-5005255f092b%2F600x600%2F5c1b2256-ba66-4855-8221-fcec93c07fbf_600x600?alt=media&token=289b7d6b-ff5f-417b-99cf-f36d5110b7fd",
        "reference": "stroke_photos/2c55bafa-cb16-4122-b3bd-5005255f092b/600x600/5c1b2256-ba66-4855-8221-fcec93c07fbf_600x600"
      },
      "original": {
        "reference": "/stroke_photos/2c55bafa-cb16-4122-b3bd-5005255f092b/5c1b2256-ba66-4855-8221-fcec93c07fbf",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2c55bafa-cb16-4122-b3bd-5005255f092b%2F5c1b2256-ba66-4855-8221-fcec93c07fbf?alt=media&token=c2631081-077f-49b0-9bd5-b1a0427d69a7"
      }
    },
    "tool": null,
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "original_size": "A4",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "44202129",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "gsm": "200",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "name": "",
      "paper_surface": {},
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    }
  },
  {
    "order_id": 0,
    "tool": null,
    "paper": {
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A7",
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "price": 1.35,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "gsm": "250",
      "ref": "77725080001"
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2c876d4f-ac05-4f62-9c20-219c814e6d56%2Fb2c9b5a7-76c4-44e9-a693-b794f1cbd566?alt=media&token=f557de83-ac49-4d79-9b56-8b4d2cdef37f",
        "reference": "/stroke_photos/2c876d4f-ac05-4f62-9c20-219c814e6d56/b2c9b5a7-76c4-44e9-a693-b794f1cbd566"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/2c876d4f-ac05-4f62-9c20-219c814e6d56/600x600/b2c9b5a7-76c4-44e9-a693-b794f1cbd566_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2c876d4f-ac05-4f62-9c20-219c814e6d56%2F600x600%2Fb2c9b5a7-76c4-44e9-a693-b794f1cbd566_600x600?alt=media&token=3e7e372d-85d3-44a4-b5cd-229ad910b1fc"
      }
    },
    "order_number": 771,
    "id": "2c876d4f-ac05-4f62-9c20-219c814e6d56",
    "name": "Blank",
    "description": ""
  },
  {
    "description": "",
    "name": "",
    "order_number": 1107,
    "id": "2c9475f5-42b3-497e-890e-d899e6429730"
  },
  {
    "description": "",
    "id": "2c9951cd-be57-418d-861e-9e0dcddae6a0",
    "order_number": 850,
    "paper": {
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "original_size": "A7",
      "name": null,
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "70025010030",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {},
      "price": null,
      "gsm": "250",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "name": "Blank",
    "order_id": 0
  },
  {
    "order_id": 0,
    "name": "Zebra",
    "description": "<p>We will do one more (!) This one looks like a zebra. We will do one more -</p>",
    "tool": null,
    "order_number": 497,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/2d0119bc-1f37-4c15-af8a-dd1cb2abb678/600x600/c6df33a0-c343-489f-b9cd-669cc127ce40_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2d0119bc-1f37-4c15-af8a-dd1cb2abb678%2F600x600%2Fc6df33a0-c343-489f-b9cd-669cc127ce40_600x600?alt=media&token=70f433d7-cd89-43c3-b6b8-70dce7cf9286"
      },
      "original": {
        "reference": "/stroke_photos/2d0119bc-1f37-4c15-af8a-dd1cb2abb678/c6df33a0-c343-489f-b9cd-669cc127ce40",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2d0119bc-1f37-4c15-af8a-dd1cb2abb678%2Fc6df33a0-c343-489f-b9cd-669cc127ce40?alt=media&token=0cb110a1-ea5c-4b3f-a13c-3a920de04b76"
      }
    },
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "gsm": null,
      "brand": {},
      "original_size": "A4",
      "name": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "price": null,
      "ref": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "id": "2d0119bc-1f37-4c15-af8a-dd1cb2abb678"
  },
  {
    "description": "",
    "order_id": 0,
    "name": "Blank",
    "order_number": 965,
    "id": "2d4ad573-9933-476f-b90a-1218f927f714",
    "paper": {
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {},
      "gsm": null,
      "brand": {},
      "price": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    }
  },
  {
    "paper": {
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "price": 1.35,
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "ref": "77725080001",
      "original_size": "A7",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "gsm": "250"
    },
    "order_number": 799,
    "photos": {
      "original": {
        "reference": "/stroke_photos/2d788788-bb1d-4e20-975e-0be504329861/ca7b976c-9c3d-4591-aeb3-ed44644aa9e8",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2d788788-bb1d-4e20-975e-0be504329861%2Fca7b976c-9c3d-4591-aeb3-ed44644aa9e8?alt=media&token=c94b68b4-5d0b-4678-97a3-945282277a2a"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2d788788-bb1d-4e20-975e-0be504329861%2F600x600%2Fca7b976c-9c3d-4591-aeb3-ed44644aa9e8_600x600?alt=media&token=c4595418-b37a-449f-b705-c65736532ab1",
        "reference": "stroke_photos/2d788788-bb1d-4e20-975e-0be504329861/600x600/ca7b976c-9c3d-4591-aeb3-ed44644aa9e8_600x600"
      }
    },
    "order_id": 0,
    "description": "",
    "id": "2d788788-bb1d-4e20-975e-0be504329861",
    "tool": null,
    "name": "Blank"
  },
  {
    "id": "2d86c206-437f-43ba-bb9f-722f6556089f",
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2d86c206-437f-43ba-bb9f-722f6556089f%2F3e6a7f46-b430-41ea-b969-95b85f99b675?alt=media&token=661d7272-1641-435e-b67c-e729aefe28e4",
        "reference": "/stroke_photos/2d86c206-437f-43ba-bb9f-722f6556089f/3e6a7f46-b430-41ea-b969-95b85f99b675"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/2d86c206-437f-43ba-bb9f-722f6556089f/600x600/3e6a7f46-b430-41ea-b969-95b85f99b675_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2d86c206-437f-43ba-bb9f-722f6556089f%2F600x600%2F3e6a7f46-b430-41ea-b969-95b85f99b675_600x600?alt=media&token=83079f05-8095-4fb1-8d99-e193b92f6784"
      }
    },
    "name": "Vaflichki 2",
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "gsm": "250",
      "ref": "77725080001",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "original_size": "A7",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "name": null,
      "price": 1.35
    },
    "order_id": 0,
    "order_number": 226,
    "description": "Attempted to correct the arc from the previous one but totally didn't work"
  },
  {
    "tool": null,
    "order_number": 273,
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "name": null,
      "original_size": "A7"
    },
    "description": "<p>Hand getting stuck again</p>",
    "order_id": 0,
    "id": "2dc68748-d27a-4bd6-a23f-3b9883c23669",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/2dc68748-d27a-4bd6-a23f-3b9883c23669/600x600/7357428e-9be8-4538-a07c-51e1d0ec7823_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2dc68748-d27a-4bd6-a23f-3b9883c23669%2F600x600%2F7357428e-9be8-4538-a07c-51e1d0ec7823_600x600?alt=media&token=7e1fd7d5-84d1-419d-b949-854a2eb4d99c"
      },
      "original": {
        "reference": "/stroke_photos/2dc68748-d27a-4bd6-a23f-3b9883c23669/7357428e-9be8-4538-a07c-51e1d0ec7823",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2dc68748-d27a-4bd6-a23f-3b9883c23669%2F7357428e-9be8-4538-a07c-51e1d0ec7823?alt=media&token=dabcd8ea-a133-448e-bf94-1a84a96a4a77"
      }
    },
    "name": "Retry"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/2dc6a975-394c-45c6-a762-2a13e40c6e43/977fd5a5-74fb-4df1-b551-32e257645c5e",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2dc6a975-394c-45c6-a762-2a13e40c6e43%2F977fd5a5-74fb-4df1-b551-32e257645c5e?alt=media&token=ecc245f1-1002-40ca-b922-498abc85b2f6"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/2dc6a975-394c-45c6-a762-2a13e40c6e43/600x600/977fd5a5-74fb-4df1-b551-32e257645c5e_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2dc6a975-394c-45c6-a762-2a13e40c6e43%2F600x600%2F977fd5a5-74fb-4df1-b551-32e257645c5e_600x600?alt=media&token=c3f9690e-0e07-4764-bec5-e9155f3bba79"
      }
    },
    "description": "<p>I am pressing the brush on it's edges so it becomes less wide</p>",
    "order_id": 0,
    "id": "2dc6a975-394c-45c6-a762-2a13e40c6e43",
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "price": null,
      "ref": null,
      "brand": {},
      "brand_model": {},
      "original_size": "A4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": null,
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b"
    },
    "name": "Better Width White",
    "order_number": 468,
    "tool": null
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2e0ba2b9-df33-43c4-b96b-6b47870b815a%2Fa67cdb44-cda7-4c7f-a42c-504ef65b8a13?alt=media&token=bdba1d86-8b90-4c7c-9f17-eb84908a2ec9",
        "reference": "/stroke_photos/2e0ba2b9-df33-43c4-b96b-6b47870b815a/a67cdb44-cda7-4c7f-a42c-504ef65b8a13"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2e0ba2b9-df33-43c4-b96b-6b47870b815a%2F600x600%2Fa67cdb44-cda7-4c7f-a42c-504ef65b8a13_600x600?alt=media&token=d86c42aa-4a3e-4815-959c-f71585c054ab",
        "reference": "stroke_photos/2e0ba2b9-df33-43c4-b96b-6b47870b815a/600x600/a67cdb44-cda7-4c7f-a42c-504ef65b8a13_600x600"
      }
    },
    "name": "Smudgy Stroke",
    "description": "<p>Seems that paint gets laid at the beginning and quickly runs out. Have to figure out a way to exed it</p>",
    "order_id": 0,
    "tool": null,
    "order_number": 351,
    "paper": {
      "original_size": "A7",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "brand": {},
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "price": null,
      "ref": "20180107"
    },
    "id": "2e0ba2b9-df33-43c4-b96b-6b47870b815a"
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2e2adf84-2e3b-4364-bd2b-d3bf886ebbae%2F2cbd6811-42f7-4455-8e13-26bb31329c3f?alt=media&token=a2910a8f-eca4-43e1-b91c-c96732b2c6e7",
        "reference": "/stroke_photos/2e2adf84-2e3b-4364-bd2b-d3bf886ebbae/2cbd6811-42f7-4455-8e13-26bb31329c3f"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/2e2adf84-2e3b-4364-bd2b-d3bf886ebbae/600x600/2cbd6811-42f7-4455-8e13-26bb31329c3f_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2e2adf84-2e3b-4364-bd2b-d3bf886ebbae%2F600x600%2F2cbd6811-42f7-4455-8e13-26bb31329c3f_600x600?alt=media&token=993d5ec5-9052-470e-aa8d-f8aa81362852"
      }
    },
    "id": "2e2adf84-2e3b-4364-bd2b-d3bf886ebbae",
    "tool": null,
    "order_number": 470,
    "description": "<p>Actually pretty good. Mixed a bit more paint in relation to water. Not bad, although not the right trajectory</p>",
    "order_id": 0,
    "name": "Variable Pressure White",
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": null,
      "brand_model": {},
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "name": null,
      "price": null,
      "original_size": "A4",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand": {},
      "gsm": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      }
    }
  },
  {
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2e65049c-0d8e-4304-a6e3-adc49eff67c7%2F10296bb8-5a3f-4697-b6ec-1cc01e51b2fd?alt=media&token=f137531f-ba20-46c7-ab73-c5d4710057c8",
        "reference": "/stroke_photos/2e65049c-0d8e-4304-a6e3-adc49eff67c7/10296bb8-5a3f-4697-b6ec-1cc01e51b2fd"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2e65049c-0d8e-4304-a6e3-adc49eff67c7%2F600x600%2F10296bb8-5a3f-4697-b6ec-1cc01e51b2fd_600x600?alt=media&token=4eb4a6fc-9a5a-42e8-a907-d19d1b68ccfe",
        "reference": "stroke_photos/2e65049c-0d8e-4304-a6e3-adc49eff67c7/600x600/10296bb8-5a3f-4697-b6ec-1cc01e51b2fd_600x600"
      }
    },
    "id": "2e65049c-0d8e-4304-a6e3-adc49eff67c7",
    "order_number": 220,
    "description": "",
    "tool": null,
    "paper": {
      "gsm": "250",
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": "77725080001",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "price": 1.35,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "name": null,
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    },
    "name": "Easy Resemblance X + 2"
  },
  {
    "name": "The Good Check",
    "order_id": 0,
    "id": "2e89ee20-7739-4d16-a6d8-40ef9188909b",
    "description": "<p>Now this one I like. I am going to make one more just in case I really hit a homerun</p>",
    "photos": {
      "original": {
        "reference": "/stroke_photos/2e89ee20-7739-4d16-a6d8-40ef9188909b/8af167d2-ba95-4ae5-bf63-f149813a4f6c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2e89ee20-7739-4d16-a6d8-40ef9188909b%2F8af167d2-ba95-4ae5-bf63-f149813a4f6c?alt=media&token=50ca9e1f-611b-4a27-acd8-6ad861ddc4ba"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2e89ee20-7739-4d16-a6d8-40ef9188909b%2F600x600%2F8af167d2-ba95-4ae5-bf63-f149813a4f6c_600x600?alt=media&token=c8234f16-ddb5-4ad5-98e8-e4d8d48b15b7",
        "reference": "stroke_photos/2e89ee20-7739-4d16-a6d8-40ef9188909b/600x600/8af167d2-ba95-4ae5-bf63-f149813a4f6c_600x600"
      }
    },
    "paper": {
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "brand": {},
      "gsm": "250",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top"
      },
      "ref": "20180107",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "price": null
    },
    "tool": null,
    "order_number": 343
  },
  {
    "id": "2ece18d7-7c0d-4609-b2a7-856a7f27c8c8",
    "paper": {
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "brand": {},
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "ref": "20180107",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "gsm": "250",
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/2ece18d7-7c0d-4609-b2a7-856a7f27c8c8/bd3f8d62-46a2-4c48-a037-1ed8ca8e0c34",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2ece18d7-7c0d-4609-b2a7-856a7f27c8c8%2Fbd3f8d62-46a2-4c48-a037-1ed8ca8e0c34?alt=media&token=9b4235f5-f6dd-4234-9b64-d1333c6c9f9f"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/2ece18d7-7c0d-4609-b2a7-856a7f27c8c8/600x600/bd3f8d62-46a2-4c48-a037-1ed8ca8e0c34_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2ece18d7-7c0d-4609-b2a7-856a7f27c8c8%2F600x600%2Fbd3f8d62-46a2-4c48-a037-1ed8ca8e0c34_600x600?alt=media&token=f4b272a7-4c16-405c-b063-c0e2cc42585c"
      }
    },
    "name": "Sideways Bounce",
    "description": "",
    "order_number": 137,
    "order_id": 0,
    "tool": null
  },
  {
    "order_number": 306,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2eef62fb-fd47-4087-b843-94174529c370%2F170e6faa-02fc-41e4-af56-30bae29ccad6?alt=media&token=07ac9354-1f6a-4600-b9ac-85f93fe3a06f",
        "reference": "/stroke_photos/2eef62fb-fd47-4087-b843-94174529c370/170e6faa-02fc-41e4-af56-30bae29ccad6"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/2eef62fb-fd47-4087-b843-94174529c370/600x600/170e6faa-02fc-41e4-af56-30bae29ccad6_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2eef62fb-fd47-4087-b843-94174529c370%2F600x600%2F170e6faa-02fc-41e4-af56-30bae29ccad6_600x600?alt=media&token=98b1cbd6-1417-4184-862b-85c4538f00aa"
      }
    },
    "description": "<p>I really tried making it smoother in pressure and the best I got was 2-piece result</p>",
    "order_id": 0,
    "name": "Moderate Success",
    "id": "2eef62fb-fd47-4087-b843-94174529c370",
    "paper": {
      "price": null,
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "tool": null
  },
  {
    "order_id": 0,
    "id": "2f2b8168-a81c-4ec4-a568-fc88e0804adb",
    "description": "",
    "paper": {
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "gsm": null,
      "brand_model": {},
      "ref": null,
      "original_size": "A4",
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "name": "Blank",
    "order_number": 907
  },
  {
    "order_number": 523,
    "paper": {
      "brand_model": {},
      "gsm": null,
      "name": null,
      "original_size": "A4",
      "price": null,
      "ref": null,
      "brand": {},
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "id": "2fa1ffae-1e75-4ee8-bf62-565695cb5608",
    "description": "<p>I will press the brush on it's edges to make it less wide (!) it starts off narrower but then goes wide again...</p>",
    "name": "Silver-On-Black Regular 3",
    "order_id": 0,
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2fa1ffae-1e75-4ee8-bf62-565695cb5608%2F4893e127-2ba3-488b-8945-aacfab5fc947?alt=media&token=d663df64-f48c-471e-8dda-54939affa0d3",
        "reference": "/stroke_photos/2fa1ffae-1e75-4ee8-bf62-565695cb5608/4893e127-2ba3-488b-8945-aacfab5fc947"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2fa1ffae-1e75-4ee8-bf62-565695cb5608%2F600x600%2F4893e127-2ba3-488b-8945-aacfab5fc947_600x600?alt=media&token=2afae9b3-9115-44e0-bfdb-77fb38471450",
        "reference": "stroke_photos/2fa1ffae-1e75-4ee8-bf62-565695cb5608/600x600/4893e127-2ba3-488b-8945-aacfab5fc947_600x600"
      }
    }
  },
  {
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/2fe25516-a403-4c37-89d8-3e5e7a795656/600x600/07e62303-a871-42f6-a697-95d6d1f727c7_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2fe25516-a403-4c37-89d8-3e5e7a795656%2F600x600%2F07e62303-a871-42f6-a697-95d6d1f727c7_600x600?alt=media&token=00d7a90b-dc04-4545-8771-45833799491e"
      },
      "original": {
        "reference": "/stroke_photos/2fe25516-a403-4c37-89d8-3e5e7a795656/07e62303-a871-42f6-a697-95d6d1f727c7",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F2fe25516-a403-4c37-89d8-3e5e7a795656%2F07e62303-a871-42f6-a697-95d6d1f727c7?alt=media&token=599ac8ae-c976-44c9-b75f-a1c73d7536ef"
      }
    },
    "paper": {
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "20180107",
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "name": null,
      "gsm": "250",
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "price": null
    },
    "description": "A lot of potential that missed it's chance.",
    "order_id": 0,
    "name": "Fake Stick 6",
    "order_number": 263,
    "id": "2fe25516-a403-4c37-89d8-3e5e7a795656",
    "tool": null
  },
  {
    "paper": {
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "gsm": "250",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "original_size": "A7",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "price": null,
      "ref": "20180107"
    },
    "description": "<p>Half Statement</p>",
    "order_number": 386,
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F30870338-a410-4018-a9ac-cc8bf9ae3b20%2F600x600%2F538b519f-9745-430b-a116-4b5fc4836e5c_600x600?alt=media&token=c20d859f-9525-4f3c-87c4-bd4be04b69b2",
        "reference": "stroke_photos/30870338-a410-4018-a9ac-cc8bf9ae3b20/600x600/538b519f-9745-430b-a116-4b5fc4836e5c_600x600"
      },
      "original": {
        "reference": "/stroke_photos/30870338-a410-4018-a9ac-cc8bf9ae3b20/538b519f-9745-430b-a116-4b5fc4836e5c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F30870338-a410-4018-a9ac-cc8bf9ae3b20%2F538b519f-9745-430b-a116-4b5fc4836e5c?alt=media&token=f18561ca-aa28-410c-8569-8af0dcdfa4b8"
      }
    },
    "id": "30870338-a410-4018-a9ac-cc8bf9ae3b20",
    "name": "Half Double Trouble",
    "tool": null
  },
  {
    "name": "Resemblance 3",
    "id": "30a39651-8311-4960-9a1b-5bfcd0f1f450",
    "order_number": 82,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F30a39651-8311-4960-9a1b-5bfcd0f1f450%2F600x600%2F85560003-7e41-4df8-b203-aa407b645fa8_600x600?alt=media&token=14d60669-c3d5-4bd6-ab89-45a05aa49142",
        "reference": "stroke_photos/30a39651-8311-4960-9a1b-5bfcd0f1f450/600x600/85560003-7e41-4df8-b203-aa407b645fa8_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F30a39651-8311-4960-9a1b-5bfcd0f1f450%2F85560003-7e41-4df8-b203-aa407b645fa8?alt=media&token=e7d9798a-7a0a-4019-867b-f42fb1fbf1ed",
        "reference": "/stroke_photos/30a39651-8311-4960-9a1b-5bfcd0f1f450/85560003-7e41-4df8-b203-aa407b645fa8"
      }
    },
    "order_id": 0,
    "tool": null,
    "paper": {
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "price": null,
      "original_size": "A4",
      "name": "",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "200",
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "ref": "44202129",
      "paper_surface": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    }
  },
  {
    "order_number": 1104,
    "description": "",
    "name": "",
    "id": "30d40760-328f-43a6-9c66-cd6078e77fd4"
  },
  {
    "paper": {
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "name": null,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A7",
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top"
      },
      "price": null
    },
    "id": "313a27a1-f6bb-42a7-bced-618d537fc49a",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F313a27a1-f6bb-42a7-bced-618d537fc49a%2F206b7bce-ff51-41b3-b58b-cec2b56d9085?alt=media&token=b72e6316-fe27-46e3-8fcc-4368e179ac7e",
        "reference": "/stroke_photos/313a27a1-f6bb-42a7-bced-618d537fc49a/206b7bce-ff51-41b3-b58b-cec2b56d9085"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/313a27a1-f6bb-42a7-bced-618d537fc49a/600x600/206b7bce-ff51-41b3-b58b-cec2b56d9085_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F313a27a1-f6bb-42a7-bced-618d537fc49a%2F600x600%2F206b7bce-ff51-41b3-b58b-cec2b56d9085_600x600?alt=media&token=b02f42b3-18e5-47a7-8540-c74d8264c6e8"
      }
    },
    "tool": null,
    "order_id": 0,
    "description": "<p>Relatively natural</p>",
    "order_number": 367,
    "name": "Wavy Experiment"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1108,
    "id": "313b01da-d787-4202-8698-b5a25ddfd3f0"
  },
  {
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F314c799b-5f54-4b7e-a49f-6cdcd00ae0ea%2F600x600%2F2a691058-e48c-4d78-8d7d-dacc08a627a6_600x600?alt=media&token=120c5740-5526-462b-bc45-aa6ce0e49519",
        "reference": "stroke_photos/314c799b-5f54-4b7e-a49f-6cdcd00ae0ea/600x600/2a691058-e48c-4d78-8d7d-dacc08a627a6_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F314c799b-5f54-4b7e-a49f-6cdcd00ae0ea%2F2a691058-e48c-4d78-8d7d-dacc08a627a6?alt=media&token=d55a24c9-7afe-44dd-9bc6-ebae7c3b7e85",
        "reference": "/stroke_photos/314c799b-5f54-4b7e-a49f-6cdcd00ae0ea/2a691058-e48c-4d78-8d7d-dacc08a627a6"
      }
    },
    "paper": {
      "ref": "20170703",
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "brand": {},
      "original_size": "A7",
      "price": 0.4,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "order_number": 571,
    "name": "Blank",
    "id": "314c799b-5f54-4b7e-a49f-6cdcd00ae0ea",
    "tool": null,
    "description": ""
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3182f567-4d15-47c2-9fa9-e707edcce522%2F9d8ef1f0-7f75-4613-83e0-b16cbdd500dd?alt=media&token=ea075148-de61-413a-938c-6691e158cd64",
        "reference": "/stroke_photos/3182f567-4d15-47c2-9fa9-e707edcce522/9d8ef1f0-7f75-4613-83e0-b16cbdd500dd"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/3182f567-4d15-47c2-9fa9-e707edcce522/600x600/9d8ef1f0-7f75-4613-83e0-b16cbdd500dd_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3182f567-4d15-47c2-9fa9-e707edcce522%2F600x600%2F9d8ef1f0-7f75-4613-83e0-b16cbdd500dd_600x600?alt=media&token=49e630e0-a956-4fe0-a312-a755eb69eaff"
      }
    },
    "name": "Reduction From Perfect Dick",
    "id": "3182f567-4d15-47c2-9fa9-e707edcce522",
    "order_id": 0,
    "tool": null,
    "paper": {
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "20180107",
      "price": null,
      "name": null
    },
    "order_number": 253,
    "description": "Thought you can actually see the head."
  },
  {
    "order_number": 160,
    "order_id": 0,
    "id": "31a7b105-af36-4a8d-86cc-5190c23eea09",
    "tool": null,
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/31a7b105-af36-4a8d-86cc-5190c23eea09/f814c1b1-d50f-4b30-941c-dfdd15b25261",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F31a7b105-af36-4a8d-86cc-5190c23eea09%2Ff814c1b1-d50f-4b30-941c-dfdd15b25261?alt=media&token=f710c563-2ef9-4501-acaa-4d1a3362ba00"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F31a7b105-af36-4a8d-86cc-5190c23eea09%2F600x600%2Ff814c1b1-d50f-4b30-941c-dfdd15b25261_600x600?alt=media&token=107273c5-cd10-428c-be8d-e2e4f162d66c",
        "reference": "stroke_photos/31a7b105-af36-4a8d-86cc-5190c23eea09/600x600/f814c1b1-d50f-4b30-941c-dfdd15b25261_600x600"
      }
    },
    "paper": {
      "price": 1.35,
      "name": null,
      "ref": "77725080001",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "gsm": "250",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "original_size": "A7",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      }
    },
    "name": "Blank"
  },
  {
    "description": "",
    "name": "Blank",
    "order_id": 0,
    "paper": {
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": null,
      "brand_model": {},
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "name": null,
      "ref": "70025010030",
      "gsm": "250",
      "original_size": "A7"
    },
    "id": "32324d31-6cef-45b7-a48f-7f3d67b3cf6e",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F32324d31-6cef-45b7-a48f-7f3d67b3cf6e%2F600x600%2Fa24a4242-0dea-4700-8364-d07eeca73587_600x600?alt=media&token=2ee3a892-69c3-4a48-96e3-f35b0c46fea4",
        "reference": "stroke_photos/32324d31-6cef-45b7-a48f-7f3d67b3cf6e/600x600/a24a4242-0dea-4700-8364-d07eeca73587_600x600"
      },
      "original": {
        "reference": "/stroke_photos/32324d31-6cef-45b7-a48f-7f3d67b3cf6e/a24a4242-0dea-4700-8364-d07eeca73587",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F32324d31-6cef-45b7-a48f-7f3d67b3cf6e%2Fa24a4242-0dea-4700-8364-d07eeca73587?alt=media&token=e42f76cf-ac3e-4f97-9ef3-54f145af21d8"
      }
    },
    "order_number": 618,
    "tool": null
  },
  {
    "tool": null,
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/32d54254-68d6-442b-9270-e5db0a979663/97d5dea0-95af-4882-a5b7-f072b5962537",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F32d54254-68d6-442b-9270-e5db0a979663%2F97d5dea0-95af-4882-a5b7-f072b5962537?alt=media&token=a02acb15-0bf6-4aac-8e14-3de8c8b08f4c"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F32d54254-68d6-442b-9270-e5db0a979663%2F600x600%2F97d5dea0-95af-4882-a5b7-f072b5962537_600x600?alt=media&token=014549b9-71ed-4f4c-a482-04f937ea41ec",
        "reference": "stroke_photos/32d54254-68d6-442b-9270-e5db0a979663/600x600/97d5dea0-95af-4882-a5b7-f072b5962537_600x600"
      }
    },
    "order_id": 0,
    "id": "32d54254-68d6-442b-9270-e5db0a979663",
    "name": "Blank",
    "order_number": 649,
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand_model": {},
      "original_size": "A7",
      "name": null,
      "brand": {},
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "70025010030",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": null,
      "gsm": "250"
    }
  },
  {
    "name": "",
    "order_number": 1208,
    "description": "",
    "id": "32dc9813-b54d-4a44-b6fe-b218d7b639ba"
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F32e6518a-fd79-44c8-97ac-019301af0064%2F62a10e18-6c13-49ba-aa6b-368ef6648988?alt=media&token=20bf5991-6d11-4be7-9458-ea45c5a7f142",
        "reference": "/stroke_photos/32e6518a-fd79-44c8-97ac-019301af0064/62a10e18-6c13-49ba-aa6b-368ef6648988"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F32e6518a-fd79-44c8-97ac-019301af0064%2F600x600%2F62a10e18-6c13-49ba-aa6b-368ef6648988_600x600?alt=media&token=568a3ef4-a06e-4ac5-8b2d-1e0f1d6851ce",
        "reference": "stroke_photos/32e6518a-fd79-44c8-97ac-019301af0064/600x600/62a10e18-6c13-49ba-aa6b-368ef6648988_600x600"
      }
    },
    "order_number": 257,
    "id": "32e6518a-fd79-44c8-97ac-019301af0064",
    "paper": {
      "gsm": "250",
      "original_size": "A7",
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "name": null,
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "price": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      }
    },
    "order_id": 0,
    "name": "Just Add Paint",
    "tool": null,
    "description": ""
  },
  {
    "name": "White Hook Boldly Again",
    "photos": {
      "original": {
        "reference": "/stroke_photos/32ec2d6f-1bb2-4e3b-b491-30979b6e0365/fdd37d27-1a88-4d69-b0d1-1c4fe1956896",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F32ec2d6f-1bb2-4e3b-b491-30979b6e0365%2Ffdd37d27-1a88-4d69-b0d1-1c4fe1956896?alt=media&token=3faeccc9-9384-4c68-a2a9-9322bd351d7f"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/32ec2d6f-1bb2-4e3b-b491-30979b6e0365/600x600/fdd37d27-1a88-4d69-b0d1-1c4fe1956896_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F32ec2d6f-1bb2-4e3b-b491-30979b6e0365%2F600x600%2Ffdd37d27-1a88-4d69-b0d1-1c4fe1956896_600x600?alt=media&token=fd3d5f38-ac3e-461d-a132-3ee6ae4b7822"
      }
    },
    "description": "<p>Let's do even more flat before the hook...</p>",
    "id": "32ec2d6f-1bb2-4e3b-b491-30979b6e0365",
    "paper": {
      "original_size": "A4",
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "gsm": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {},
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null
    },
    "order_number": 488,
    "tool": null,
    "order_id": 0
  },
  {
    "order_number": 546,
    "description": "<p>One more (!) Good, you can see the movement of the brush due to the less paint.</p>",
    "id": "332eff54-9bf1-44e5-8d0a-7ec5eb53122a",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F332eff54-9bf1-44e5-8d0a-7ec5eb53122a%2F600x600%2Fbf35d7ae-1e33-4a30-9e87-53062da8ed51_600x600?alt=media&token=707784df-8d96-4d6d-92ff-1b363f1ebe54",
        "reference": "stroke_photos/332eff54-9bf1-44e5-8d0a-7ec5eb53122a/600x600/bf35d7ae-1e33-4a30-9e87-53062da8ed51_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F332eff54-9bf1-44e5-8d0a-7ec5eb53122a%2Fbf35d7ae-1e33-4a30-9e87-53062da8ed51?alt=media&token=1568ab42-8d15-47a5-a3b7-9dc48ba0917a",
        "reference": "/stroke_photos/332eff54-9bf1-44e5-8d0a-7ec5eb53122a/bf35d7ae-1e33-4a30-9e87-53062da8ed51"
      }
    },
    "paper": {
      "gsm": null,
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": null,
      "brand": {},
      "original_size": "A4",
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "brand_model": {},
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      }
    },
    "name": "Silver-On-Black Variable Pressure 2",
    "order_id": 0,
    "tool": null
  },
  {
    "id": "3400500f-523d-49c9-a09a-6502128fcad0",
    "description": "<p>(!) This one probably looks a bit broken.</p>",
    "order_number": 534,
    "name": "Silver-On-Black Loads Of Paint 3",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/3400500f-523d-49c9-a09a-6502128fcad0/600x600/f8ad4925-3dbf-4943-a554-4961df50f970_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3400500f-523d-49c9-a09a-6502128fcad0%2F600x600%2Ff8ad4925-3dbf-4943-a554-4961df50f970_600x600?alt=media&token=631c4939-3f5a-4de7-8e96-e23b08e29715"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3400500f-523d-49c9-a09a-6502128fcad0%2Ff8ad4925-3dbf-4943-a554-4961df50f970?alt=media&token=c37f41c7-0ada-4aaf-83b6-0f086ac18232",
        "reference": "/stroke_photos/3400500f-523d-49c9-a09a-6502128fcad0/f8ad4925-3dbf-4943-a554-4961df50f970"
      }
    },
    "paper": {
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "brand_model": {},
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "ref": null,
      "price": null,
      "gsm": null
    },
    "tool": null,
    "order_id": 0
  },
  {
    "name": "Blank",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F341ea25b-12f0-4599-8f2a-eaa68cf04652%2F72d95472-2be4-4b81-a17f-078169a13fdf?alt=media&token=9f1507c7-9a23-491f-9c10-7ee2422d94d0",
        "reference": "/stroke_photos/341ea25b-12f0-4599-8f2a-eaa68cf04652/72d95472-2be4-4b81-a17f-078169a13fdf"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F341ea25b-12f0-4599-8f2a-eaa68cf04652%2F600x600%2F72d95472-2be4-4b81-a17f-078169a13fdf_600x600?alt=media&token=dbcac0e5-d947-4b2e-b4e3-2e14d4a77dc6",
        "reference": "stroke_photos/341ea25b-12f0-4599-8f2a-eaa68cf04652/600x600/72d95472-2be4-4b81-a17f-078169a13fdf_600x600"
      }
    },
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "ref": "20170703",
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "price": 0.4,
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "name": null,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      }
    },
    "description": "",
    "tool": null,
    "id": "341ea25b-12f0-4599-8f2a-eaa68cf04652",
    "order_number": 729,
    "order_id": 0
  },
  {
    "name": "",
    "description": "",
    "order_number": 1033,
    "id": "34298582-903b-442f-b5b8-1472bbab0b6c"
  },
  {
    "id": "34314a81-26cd-4994-918c-a4d6ddf76bad",
    "description": "",
    "order_number": 852,
    "order_id": 0,
    "name": "Blank",
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "ref": "4005329065767",
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "original_size": "A4",
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "gsm": "110",
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2"
      },
      "price": 1
    }
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/34497ec2-8ab4-459f-89be-f9c3af18062b/93e33bad-86ff-4b02-90b6-8cc238a65937",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F34497ec2-8ab4-459f-89be-f9c3af18062b%2F93e33bad-86ff-4b02-90b6-8cc238a65937?alt=media&token=e16d847e-cb4d-4da3-a484-17994d50c2f5"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/34497ec2-8ab4-459f-89be-f9c3af18062b/600x600/93e33bad-86ff-4b02-90b6-8cc238a65937_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F34497ec2-8ab4-459f-89be-f9c3af18062b%2F600x600%2F93e33bad-86ff-4b02-90b6-8cc238a65937_600x600?alt=media&token=9925ce7d-f7d7-4aa2-bd15-302c2b5ed408"
      }
    },
    "order_number": 656,
    "tool": null,
    "name": "Blank",
    "paper": {
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "price": 19.3,
      "original_size": "75x105cm",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "gsm": "640",
      "ref": "30075105",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "name": "tinto",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      }
    },
    "id": "34497ec2-8ab4-459f-89be-f9c3af18062b",
    "description": "",
    "order_id": 0
  },
  {
    "id": "346ea56a-4863-49ed-b87a-1f8426993809",
    "order_number": 231,
    "description": "Just didn't work out. Next.",
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "name": null,
      "price": 1.35,
      "original_size": "A7",
      "gsm": "250",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "ref": "77725080001",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      }
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/346ea56a-4863-49ed-b87a-1f8426993809/6effcdf3-b5f3-4633-8b98-afe54bb243f8",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F346ea56a-4863-49ed-b87a-1f8426993809%2F6effcdf3-b5f3-4633-8b98-afe54bb243f8?alt=media&token=6ef71b53-b7d8-49fa-bb0f-16018f5c550e"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/346ea56a-4863-49ed-b87a-1f8426993809/600x600/6effcdf3-b5f3-4633-8b98-afe54bb243f8_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F346ea56a-4863-49ed-b87a-1f8426993809%2F600x600%2F6effcdf3-b5f3-4633-8b98-afe54bb243f8_600x600?alt=media&token=d67bf915-975e-4a39-ac9e-1ecda8838530"
      }
    },
    "tool": null,
    "name": "Black Series 3",
    "order_id": 0
  },
  {
    "description": "",
    "order_number": 1038,
    "name": "",
    "id": "3477aac7-3a80-4059-a6e7-7510de22a5a6"
  },
  {
    "order_number": 962,
    "name": "Blank",
    "description": "",
    "id": "34f48940-ca57-4210-a4d8-c55af6d0a9b9",
    "order_id": 0,
    "paper": {
      "price": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": null,
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "ref": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {},
      "original_size": "A4",
      "brand": {}
    }
  },
  {
    "order_number": 996,
    "id": "34fc2762-a246-4ee2-80c4-36115848f72c",
    "paper": {
      "name": "tinto",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "brand_model": {
        "name": "Artistico",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "original_size": "75x105cm",
      "ref": "30075105",
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": 19.3,
      "gsm": "640"
    },
    "name": "Blank",
    "order_id": 0,
    "description": ""
  },
  {
    "description": "",
    "order_number": 1164,
    "name": "",
    "id": "35108537-0bfb-4906-ad09-7187f44b2182"
  },
  {
    "order_number": 444,
    "name": "Football",
    "order_id": 0,
    "paper": {
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "ref": "77725080001",
      "original_size": "A7",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "price": 1.35,
      "name": null
    },
    "tool": null,
    "id": "35a7d8ac-ee70-4727-93a4-045173a10821",
    "description": "Can't get this wrong",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F35a7d8ac-ee70-4727-93a4-045173a10821%2Fec6cc65d-a34a-4d51-a9f0-78a4decd8247?alt=media&token=34305dbf-ead8-41c4-bf82-f6385a950de9",
        "reference": "/stroke_photos/35a7d8ac-ee70-4727-93a4-045173a10821/ec6cc65d-a34a-4d51-a9f0-78a4decd8247"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/35a7d8ac-ee70-4727-93a4-045173a10821/600x600/ec6cc65d-a34a-4d51-a9f0-78a4decd8247_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F35a7d8ac-ee70-4727-93a4-045173a10821%2F600x600%2Fec6cc65d-a34a-4d51-a9f0-78a4decd8247_600x600?alt=media&token=70514e74-5166-4ec9-a248-7252d87bec6b"
      }
    }
  },
  {
    "order_id": 0,
    "name": "Blank",
    "id": "3630f19c-b446-4109-8df7-c9fb0768ed3f",
    "description": "",
    "order_number": 894,
    "paper": {
      "original_size": "A4",
      "price": 1,
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "brand_model": {
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2"
      },
      "ref": "4005329065767",
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "name": null,
      "gsm": "110",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      }
    }
  },
  {
    "order_number": 90,
    "id": "3644811a-7b38-4c2b-8c95-7c29edfe6b5a",
    "photos": {
      "original": {
        "reference": "/stroke_photos/3644811a-7b38-4c2b-8c95-7c29edfe6b5a/da27564d-2088-415d-b937-dd9b2b7794f8",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3644811a-7b38-4c2b-8c95-7c29edfe6b5a%2Fda27564d-2088-415d-b937-dd9b2b7794f8?alt=media&token=93a111a5-270e-409d-8e3c-5ca1ca3cf8e0"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/3644811a-7b38-4c2b-8c95-7c29edfe6b5a/600x600/da27564d-2088-415d-b937-dd9b2b7794f8_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3644811a-7b38-4c2b-8c95-7c29edfe6b5a%2F600x600%2Fda27564d-2088-415d-b937-dd9b2b7794f8_600x600?alt=media&token=4568e157-3f99-4ad2-b252-0901b910a282"
      }
    },
    "tool": null,
    "paper": {
      "ref": "44202129",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "price": null,
      "paper_surface": {},
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "gsm": "200",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "original_size": "A4",
      "name": ""
    },
    "name": "WOE HOT 5",
    "order_id": 0,
    "description": ""
  },
  {
    "order_number": 1051,
    "description": "",
    "name": "",
    "id": "367b21df-5f23-4239-a0fa-764de9f5a138"
  },
  {
    "name": "Blank",
    "id": "36c9c2f7-9691-47ad-a466-6efe504b0fd6",
    "paper": {
      "name": null,
      "gsm": "110",
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "original_size": "A4",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {
        "name": "Elephant Skin",
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "ref": "4005329065767",
      "price": 1
    },
    "order_number": 886,
    "order_id": 0,
    "description": ""
  },
  {
    "order_number": 1113,
    "name": "",
    "description": "",
    "id": "371bd9a0-a335-4309-a789-490eda92f2f1"
  },
  {
    "order_number": 1007,
    "name": "",
    "description": "",
    "id": "3747ce63-6b71-4055-8bf5-3e82c2461234"
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F374a7b4d-6d2f-4936-ae03-a549fd5dce47%2F6330d83e-e7ee-445f-bb61-0acdedc38bf7?alt=media&token=34a616ec-6813-4678-9c01-93638249e2d4",
        "reference": "/stroke_photos/374a7b4d-6d2f-4936-ae03-a549fd5dce47/6330d83e-e7ee-445f-bb61-0acdedc38bf7"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F374a7b4d-6d2f-4936-ae03-a549fd5dce47%2F600x600%2F6330d83e-e7ee-445f-bb61-0acdedc38bf7_600x600?alt=media&token=73bc5729-dde2-4423-a135-55cdb3c45990",
        "reference": "stroke_photos/374a7b4d-6d2f-4936-ae03-a549fd5dce47/600x600/6330d83e-e7ee-445f-bb61-0acdedc38bf7_600x600"
      }
    },
    "order_id": 0,
    "id": "374a7b4d-6d2f-4936-ae03-a549fd5dce47",
    "paper": {
      "brand_model": {},
      "price": null,
      "brand": {},
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A7",
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "ref": "70025010030",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250"
    },
    "tool": null,
    "description": "",
    "order_number": 610,
    "name": "Blank"
  },
  {
    "name": "Blank",
    "tool": null,
    "order_id": 0,
    "paper": {
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "ref": "20170703",
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "original_size": "A7",
      "price": 0.4,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250"
    },
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/377365f6-d49a-4bdb-b2d0-2e401d4b2c25/232e9326-b959-4f98-a753-7b5c84aac883",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F377365f6-d49a-4bdb-b2d0-2e401d4b2c25%2F232e9326-b959-4f98-a753-7b5c84aac883?alt=media&token=d84665b7-bfae-4c5a-83e0-35e063fcf2dc"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/377365f6-d49a-4bdb-b2d0-2e401d4b2c25/600x600/232e9326-b959-4f98-a753-7b5c84aac883_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F377365f6-d49a-4bdb-b2d0-2e401d4b2c25%2F600x600%2F232e9326-b959-4f98-a753-7b5c84aac883_600x600?alt=media&token=9a2002fa-d82e-4f5e-9b11-23a7a02cf4ad"
      }
    },
    "id": "377365f6-d49a-4bdb-b2d0-2e401d4b2c25",
    "order_number": 553
  },
  {
    "order_number": 928,
    "name": "Blank",
    "id": "37bf2d64-6d90-4328-a0c4-2e9a94aeec16",
    "paper": {
      "original_size": "A4",
      "brand": {},
      "ref": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": null,
      "name": null,
      "price": null,
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {}
    },
    "description": "",
    "order_id": 0
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F37cd95a0-32e0-4a49-a64e-dcd2905878ef%2Fae84a252-ee02-4cad-b504-b9f87bb2328c?alt=media&token=f40621fb-60c7-46b8-a3b1-20068d70225d",
        "reference": "/stroke_photos/37cd95a0-32e0-4a49-a64e-dcd2905878ef/ae84a252-ee02-4cad-b504-b9f87bb2328c"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/37cd95a0-32e0-4a49-a64e-dcd2905878ef/600x600/ae84a252-ee02-4cad-b504-b9f87bb2328c_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F37cd95a0-32e0-4a49-a64e-dcd2905878ef%2F600x600%2Fae84a252-ee02-4cad-b504-b9f87bb2328c_600x600?alt=media&token=c8d7c05a-dfcf-40a0-a877-585b82196210"
      }
    },
    "id": "37cd95a0-32e0-4a49-a64e-dcd2905878ef",
    "order_id": 0,
    "order_number": 539,
    "description": "<p>What if I do a \"V\"? (!) Okay, not bad</p>",
    "name": "Silver-On-Black V 1",
    "paper": {
      "brand": {},
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": null,
      "brand_model": {},
      "original_size": "A4",
      "name": null,
      "price": null,
      "gsm": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b"
    },
    "tool": null
  },
  {
    "name": "Musically 8",
    "order_id": 0,
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "ref": "77725080001",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "gsm": "250",
      "name": null,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "original_size": "A7",
      "price": 1.35,
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    },
    "order_number": 243,
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F37e7c0fe-104d-4c57-b95e-9c6223ba6bdc%2F600x600%2F8fbfcda8-5ab5-48f6-b6c0-a157d24b748d_600x600?alt=media&token=e85085e4-5ac9-4650-b053-015d1abda014",
        "reference": "stroke_photos/37e7c0fe-104d-4c57-b95e-9c6223ba6bdc/600x600/8fbfcda8-5ab5-48f6-b6c0-a157d24b748d_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F37e7c0fe-104d-4c57-b95e-9c6223ba6bdc%2F8fbfcda8-5ab5-48f6-b6c0-a157d24b748d?alt=media&token=6abe8779-3f98-4941-a7a9-0f11df583bd0",
        "reference": "/stroke_photos/37e7c0fe-104d-4c57-b95e-9c6223ba6bdc/8fbfcda8-5ab5-48f6-b6c0-a157d24b748d"
      }
    },
    "description": "Even worse, help!",
    "id": "37e7c0fe-104d-4c57-b95e-9c6223ba6bdc"
  },
  {
    "order_number": 1166,
    "description": "",
    "name": "",
    "id": "382daa05-dffd-4dde-acde-f4d7fd3c6df5"
  },
  {
    "order_number": 1016,
    "description": "",
    "name": "",
    "id": "38924d6f-91e0-4638-b868-56f8c506fa85"
  },
  {
    "description": "",
    "name": "Blank",
    "order_number": 868,
    "order_id": 0,
    "id": "38b1100f-8135-4a1e-a6c5-1c1edea5e6b1",
    "paper": {
      "price": 1,
      "ref": "4005329065767",
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A4",
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "gsm": "110",
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "name": "Elephant Skin",
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        }
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      }
    }
  },
  {
    "order_number": 916,
    "description": "",
    "id": "38d905a3-8f53-4eba-b87f-e36fdff319d9",
    "paper": {
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "brand": {},
      "original_size": "A4",
      "price": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "ref": null,
      "gsm": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand_model": {}
    },
    "order_id": 0,
    "name": "Blank"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1150,
    "id": "39212b85-d5a0-4481-97ed-326b0c7dbe41"
  },
  {
    "id": "394e2411-d1fb-4fec-83a5-a1bc819a7595",
    "order_number": 903,
    "order_id": 0,
    "paper": {
      "price": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "gsm": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A4",
      "name": null,
      "ref": null,
      "brand": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {},
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      }
    },
    "description": "",
    "name": "Blank"
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F398fb22b-72da-442d-9349-ce919d482f30%2F0deb579c-496a-4578-9ad4-b1ff4724f5e5?alt=media&token=88f7e4bb-568b-4168-8223-f7204c08c9bd",
        "reference": "/stroke_photos/398fb22b-72da-442d-9349-ce919d482f30/0deb579c-496a-4578-9ad4-b1ff4724f5e5"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/398fb22b-72da-442d-9349-ce919d482f30/600x600/0deb579c-496a-4578-9ad4-b1ff4724f5e5_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F398fb22b-72da-442d-9349-ce919d482f30%2F600x600%2F0deb579c-496a-4578-9ad4-b1ff4724f5e5_600x600?alt=media&token=2ed32d96-c982-40cb-907b-dd507c05f8cd"
      }
    },
    "tool": null,
    "order_number": 336,
    "id": "398fb22b-72da-442d-9349-ce919d482f30",
    "name": "Similar",
    "order_id": 0,
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "price": null,
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "250",
      "original_size": "A7"
    },
    "description": "<p>Same as the previous one, but just more boring</p>"
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3a0ba56d-4593-451d-af09-48e042a90bce%2F600x600%2Fcdf748cd-01ad-465a-9acb-6a7ee4ed237b_600x600?alt=media&token=c661457a-22a1-4216-8bdb-b2f056020499",
        "reference": "stroke_photos/3a0ba56d-4593-451d-af09-48e042a90bce/600x600/cdf748cd-01ad-465a-9acb-6a7ee4ed237b_600x600"
      },
      "original": {
        "reference": "/stroke_photos/3a0ba56d-4593-451d-af09-48e042a90bce/cdf748cd-01ad-465a-9acb-6a7ee4ed237b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3a0ba56d-4593-451d-af09-48e042a90bce%2Fcdf748cd-01ad-465a-9acb-6a7ee4ed237b?alt=media&token=130e1487-c90d-423f-9703-6f6aae8dece3"
      }
    },
    "paper": {
      "name": null,
      "brand_model": {},
      "ref": null,
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": null,
      "brand": {},
      "original_size": "A4",
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      }
    },
    "order_number": 543,
    "name": "Silver-On-Black Round Beat",
    "id": "3a0ba56d-4593-451d-af09-48e042a90bce",
    "description": "<p>Let's do the heartbeat round (!) Hm, this looks very much like a flipped Omega letter</p>",
    "order_id": 0,
    "tool": null
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3a11eaeb-1f2a-4e76-8a32-ce4cc70cdbac%2F600x600%2Fb308f73b-bfd4-431e-9102-8b726481b9a2_600x600?alt=media&token=822d60aa-32af-42bb-9286-c5f5a62fa519",
        "reference": "stroke_photos/3a11eaeb-1f2a-4e76-8a32-ce4cc70cdbac/600x600/b308f73b-bfd4-431e-9102-8b726481b9a2_600x600"
      },
      "original": {
        "reference": "/stroke_photos/3a11eaeb-1f2a-4e76-8a32-ce4cc70cdbac/b308f73b-bfd4-431e-9102-8b726481b9a2",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3a11eaeb-1f2a-4e76-8a32-ce4cc70cdbac%2Fb308f73b-bfd4-431e-9102-8b726481b9a2?alt=media&token=62604c33-868d-4ce3-b82c-427719c3b110"
      }
    },
    "name": "Fast White Stroke",
    "paper": {
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "ref": null,
      "gsm": null,
      "original_size": "A4",
      "name": null,
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand_model": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "order_number": 492,
    "tool": null,
    "description": "<p>I just want to do a fast one (!) Interistingly enough, there was a lot of pressure and paint was left mostly in the beginning</p>",
    "order_id": 0,
    "id": "3a11eaeb-1f2a-4e76-8a32-ce4cc70cdbac"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1187,
    "id": "3ac18565-0d2e-43c4-b1c3-bead98432105"
  },
  {
    "name": "Blank",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/3ad32ae0-e9f9-4bf8-a186-ff29cf6f6e92/600x600/8ecb784d-aec2-4649-93f6-0412ae6ec74b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3ad32ae0-e9f9-4bf8-a186-ff29cf6f6e92%2F600x600%2F8ecb784d-aec2-4649-93f6-0412ae6ec74b_600x600?alt=media&token=4822b414-a309-4409-81e4-bc95de2591a9"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3ad32ae0-e9f9-4bf8-a186-ff29cf6f6e92%2F8ecb784d-aec2-4649-93f6-0412ae6ec74b?alt=media&token=8d54b96f-75fb-4dad-92a2-34d40e98ad3d",
        "reference": "/stroke_photos/3ad32ae0-e9f9-4bf8-a186-ff29cf6f6e92/8ecb784d-aec2-4649-93f6-0412ae6ec74b"
      }
    },
    "paper": {
      "name": "tinto",
      "price": 19.3,
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "original_size": "75x105cm",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "ref": "30075105",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "640",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163"
    },
    "id": "3ad32ae0-e9f9-4bf8-a186-ff29cf6f6e92",
    "order_number": 657,
    "order_id": 0,
    "description": ""
  },
  {
    "name": "First Shodo Attempt",
    "description": "",
    "id": "3ad426fd-aedc-400d-8f86-9ac19923d334",
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3ad426fd-aedc-400d-8f86-9ac19923d334%2Fdf47aa8a-73e5-44a3-b316-daddeaf2abc5?alt=media&token=b30e2efc-e410-4e8f-bc96-de8306673d5b",
        "reference": "/stroke_photos/3ad426fd-aedc-400d-8f86-9ac19923d334/df47aa8a-73e5-44a3-b316-daddeaf2abc5"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/3ad426fd-aedc-400d-8f86-9ac19923d334/600x600/df47aa8a-73e5-44a3-b316-daddeaf2abc5_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3ad426fd-aedc-400d-8f86-9ac19923d334%2F600x600%2Fdf47aa8a-73e5-44a3-b316-daddeaf2abc5_600x600?alt=media&token=016892a3-ada9-411d-87d3-857d3392796b"
      }
    },
    "order_id": 0,
    "paper": {
      "gsm": "200",
      "original_size": "A4",
      "price": null,
      "name": "",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {},
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "44202129",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      }
    },
    "order_number": 34
  },
  {
    "order_number": 988,
    "order_id": 0,
    "paper": {
      "price": 19.3,
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "name": "tinto",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "ref": "30075105",
      "original_size": "75x105cm",
      "brand_model": {
        "name": "Artistico",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "gsm": "640"
    },
    "description": "",
    "id": "3ade3101-03a6-44e9-887b-248ed05f08f8",
    "name": "Blank"
  },
  {
    "tool": null,
    "name": "Blank",
    "order_number": 605,
    "id": "3af0fcef-b05f-4aac-a198-2fd8e8e43e12",
    "description": "",
    "paper": {
      "gsm": "250",
      "brand_model": {},
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "original_size": "A7",
      "price": null,
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "70025010030",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3af0fcef-b05f-4aac-a198-2fd8e8e43e12%2F600x600%2Fc835b406-3e59-4187-ad5a-59f653a8be0c_600x600?alt=media&token=9a9b278b-b3a8-4d22-ab9b-78faf5b7c68c",
        "reference": "stroke_photos/3af0fcef-b05f-4aac-a198-2fd8e8e43e12/600x600/c835b406-3e59-4187-ad5a-59f653a8be0c_600x600"
      },
      "original": {
        "reference": "/stroke_photos/3af0fcef-b05f-4aac-a198-2fd8e8e43e12/c835b406-3e59-4187-ad5a-59f653a8be0c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3af0fcef-b05f-4aac-a198-2fd8e8e43e12%2Fc835b406-3e59-4187-ad5a-59f653a8be0c?alt=media&token=fec15911-e38c-4a3d-a359-482bbb689b14"
      }
    },
    "order_id": 0
  },
  {
    "description": "",
    "order_number": 1165,
    "name": "",
    "id": "3b15cecc-62c0-4e41-91f0-26d4a668fb84"
  },
  {
    "description": "",
    "paper": {
      "brand_model": {},
      "name": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl",
        "hex": "#f5f5f5"
      },
      "original_size": "A7",
      "price": null,
      "gsm": "250",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "70025010030",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {}
    },
    "name": "Blank",
    "id": "3bde2e12-26a2-4c49-9087-c2ca949d6464",
    "order_id": 0,
    "order_number": 645,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3bde2e12-26a2-4c49-9087-c2ca949d6464%2F600x600%2F44c0d644-84ec-47c3-9ccc-c81e202ebb8c_600x600?alt=media&token=f8a557f7-4530-499d-844e-42cb7965effa",
        "reference": "stroke_photos/3bde2e12-26a2-4c49-9087-c2ca949d6464/600x600/44c0d644-84ec-47c3-9ccc-c81e202ebb8c_600x600"
      },
      "original": {
        "reference": "/stroke_photos/3bde2e12-26a2-4c49-9087-c2ca949d6464/44c0d644-84ec-47c3-9ccc-c81e202ebb8c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3bde2e12-26a2-4c49-9087-c2ca949d6464%2F44c0d644-84ec-47c3-9ccc-c81e202ebb8c?alt=media&token=3209fff2-ae2c-45c1-835f-5ab6c0c3336f"
      }
    },
    "tool": null
  },
  {
    "name": "",
    "description": "",
    "order_number": 1035,
    "id": "3c10350b-c2cc-4496-8143-d916cd56f608"
  },
  {
    "order_number": 1122,
    "name": "",
    "description": "",
    "id": "3c2c5708-54a5-4b48-a3b7-bc3034f86dff"
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3ca053ec-f9ae-4509-9359-4ee4d9011240%2F600x600%2F94f0d90d-17b4-4c82-8cc9-140799ce026d_600x600?alt=media&token=e2fcd585-1e95-472d-a367-d67e1b7a1863",
        "reference": "stroke_photos/3ca053ec-f9ae-4509-9359-4ee4d9011240/600x600/94f0d90d-17b4-4c82-8cc9-140799ce026d_600x600"
      },
      "original": {
        "reference": "/stroke_photos/3ca053ec-f9ae-4509-9359-4ee4d9011240/94f0d90d-17b4-4c82-8cc9-140799ce026d",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3ca053ec-f9ae-4509-9359-4ee4d9011240%2F94f0d90d-17b4-4c82-8cc9-140799ce026d?alt=media&token=699c0bf5-0fb4-461e-b9e1-5517f01f00ff"
      }
    },
    "id": "3ca053ec-f9ae-4509-9359-4ee4d9011240",
    "order_number": 135,
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "price": null,
      "name": null,
      "brand": {},
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      }
    },
    "description": "",
    "tool": null,
    "name": "No Idea",
    "order_id": 0
  },
  {
    "name": "Blank",
    "paper": {
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand": {},
      "price": 0.4,
      "ref": "20170703",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250"
    },
    "order_number": 551,
    "description": "",
    "id": "3cd52e7b-1553-4515-888c-b3f83fc9ed3d",
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3cd52e7b-1553-4515-888c-b3f83fc9ed3d%2F19a03b7f-7b4f-4d85-a8c2-200af5133d3c?alt=media&token=567515d5-c60e-49ed-a65e-6aa91ca0618a",
        "reference": "/stroke_photos/3cd52e7b-1553-4515-888c-b3f83fc9ed3d/19a03b7f-7b4f-4d85-a8c2-200af5133d3c"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3cd52e7b-1553-4515-888c-b3f83fc9ed3d%2F600x600%2F19a03b7f-7b4f-4d85-a8c2-200af5133d3c_600x600?alt=media&token=fac7191c-9f3a-4ed6-8322-63267a019f76",
        "reference": "stroke_photos/3cd52e7b-1553-4515-888c-b3f83fc9ed3d/600x600/19a03b7f-7b4f-4d85-a8c2-200af5133d3c_600x600"
      }
    },
    "tool": null
  },
  {
    "name": "",
    "description": "",
    "order_number": 1153,
    "id": "3cec5d23-c466-4ec0-beba-28ad0259a182"
  },
  {
    "name": "Blank",
    "description": "",
    "order_id": 0,
    "order_number": 820,
    "paper": {
      "gsm": "250",
      "price": 0.4,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "original_size": "A7",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "ref": "20170703",
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    },
    "id": "3d22018a-85e0-4ba0-8dcb-54d5dcfe1b8e"
  },
  {
    "description": "",
    "name": "Blank",
    "order_id": 0,
    "paper": {
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "brand": {},
      "brand_model": {},
      "price": null,
      "gsm": null,
      "ref": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    },
    "order_number": 940,
    "id": "3d2e6cec-8232-4273-af8e-5eae07cf7719"
  },
  {
    "name": "Blank",
    "order_number": 699,
    "tool": null,
    "order_id": 0,
    "id": "3d794e52-6dfc-4c0f-8876-609525065b4e",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/3d794e52-6dfc-4c0f-8876-609525065b4e/600x600/393c3587-2400-4916-a843-437208dbb018_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3d794e52-6dfc-4c0f-8876-609525065b4e%2F600x600%2F393c3587-2400-4916-a843-437208dbb018_600x600?alt=media&token=838b3c91-1531-423d-89ca-647e3d16716b"
      },
      "original": {
        "reference": "/stroke_photos/3d794e52-6dfc-4c0f-8876-609525065b4e/393c3587-2400-4916-a843-437208dbb018",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3d794e52-6dfc-4c0f-8876-609525065b4e%2F393c3587-2400-4916-a843-437208dbb018?alt=media&token=4ffc46e0-928c-47bc-a6c6-6c0ca2e3fc25"
      }
    },
    "paper": {
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "name": "tinto",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "original_size": "75x105cm",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "gsm": "640",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "ref": "30075105",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "price": 19.3
    },
    "description": ""
  },
  {
    "order_number": 1102,
    "description": "",
    "name": "",
    "id": "3dc1f6c8-f538-447f-966c-ac2291984304"
  },
  {
    "order_number": 753,
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3dd6bbe3-a67e-428a-a4a7-2218595bdbab%2F600x600%2Ff66a0e24-52f0-40d2-8f68-70dd18faffca_600x600?alt=media&token=69b2734c-a48f-44aa-b03d-fc30a02454f1",
        "reference": "stroke_photos/3dd6bbe3-a67e-428a-a4a7-2218595bdbab/600x600/f66a0e24-52f0-40d2-8f68-70dd18faffca_600x600"
      },
      "original": {
        "reference": "/stroke_photos/3dd6bbe3-a67e-428a-a4a7-2218595bdbab/f66a0e24-52f0-40d2-8f68-70dd18faffca",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3dd6bbe3-a67e-428a-a4a7-2218595bdbab%2Ff66a0e24-52f0-40d2-8f68-70dd18faffca?alt=media&token=8aa34e1c-6b19-457b-872d-413f5eb5c44a"
      }
    },
    "description": "",
    "tool": null,
    "paper": {
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "name": null,
      "ref": "77725080001",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "original_size": "A7",
      "gsm": "250",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      }
    },
    "name": "Blank",
    "id": "3dd6bbe3-a67e-428a-a4a7-2218595bdbab"
  },
  {
    "description": "",
    "order_number": 1009,
    "name": "",
    "id": "3dd7d3dc-866b-4a58-b147-a86183467d5d"
  },
  {
    "order_id": 0,
    "paper": {
      "ref": "20180107",
      "original_size": "A7",
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": null
    },
    "id": "3e2f6c24-3934-46d0-b8e7-1afa25ccdb01",
    "order_number": 254,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/3e2f6c24-3934-46d0-b8e7-1afa25ccdb01/600x600/1b755761-f236-4d37-a82f-9dbccb6dc301_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3e2f6c24-3934-46d0-b8e7-1afa25ccdb01%2F600x600%2F1b755761-f236-4d37-a82f-9dbccb6dc301_600x600?alt=media&token=410cddb9-7eef-47f2-ae0f-c967fefd525a"
      },
      "original": {
        "reference": "/stroke_photos/3e2f6c24-3934-46d0-b8e7-1afa25ccdb01/1b755761-f236-4d37-a82f-9dbccb6dc301",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3e2f6c24-3934-46d0-b8e7-1afa25ccdb01%2F1b755761-f236-4d37-a82f-9dbccb6dc301?alt=media&token=9bcc5f2d-55b6-4ff7-bc96-9e5a687d66c3"
      }
    },
    "description": "",
    "tool": null,
    "name": "Disappointment"
  },
  {
    "id": "3e56291b-057e-46fc-ae85-63e6ad7cf33e",
    "paper": {
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "original_size": "A7",
      "ref": "77725080001",
      "gsm": "250",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "price": 1.35
    },
    "order_id": 0,
    "name": "Blank",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3e56291b-057e-46fc-ae85-63e6ad7cf33e%2F86581aa1-8d1e-4bb3-90f4-6a595e8a9f6a?alt=media&token=6f01aa71-e2e7-4e5f-8088-dbd87725c5b1",
        "reference": "/stroke_photos/3e56291b-057e-46fc-ae85-63e6ad7cf33e/86581aa1-8d1e-4bb3-90f4-6a595e8a9f6a"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/3e56291b-057e-46fc-ae85-63e6ad7cf33e/600x600/86581aa1-8d1e-4bb3-90f4-6a595e8a9f6a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3e56291b-057e-46fc-ae85-63e6ad7cf33e%2F600x600%2F86581aa1-8d1e-4bb3-90f4-6a595e8a9f6a_600x600?alt=media&token=5ef42f3e-d931-49ab-ae68-27a471143873"
      }
    },
    "tool": null,
    "order_number": 408,
    "description": ""
  },
  {
    "order_number": 322,
    "order_id": 0,
    "paper": {
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "ref": "20180107"
    },
    "description": "<p>A bit better but so many things are off here</p>",
    "id": "3e932fe5-e22b-4824-8f8e-2b88fa4d0a8f",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/3e932fe5-e22b-4824-8f8e-2b88fa4d0a8f/600x600/28e1dc24-e726-48ce-baaa-1aaf00f34574_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3e932fe5-e22b-4824-8f8e-2b88fa4d0a8f%2F600x600%2F28e1dc24-e726-48ce-baaa-1aaf00f34574_600x600?alt=media&token=f342f7c0-bb36-4f5a-b254-e23667348320"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3e932fe5-e22b-4824-8f8e-2b88fa4d0a8f%2F28e1dc24-e726-48ce-baaa-1aaf00f34574?alt=media&token=8f2f1217-0c49-43f7-8c13-72a5c02b2d0d",
        "reference": "/stroke_photos/3e932fe5-e22b-4824-8f8e-2b88fa4d0a8f/28e1dc24-e726-48ce-baaa-1aaf00f34574"
      }
    },
    "name": "Swirly fix"
  },
  {
    "description": "",
    "name": "Blank",
    "tool": null,
    "order_id": 0,
    "order_number": 789,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3eb9385a-6db9-487c-8f6a-7b4744f4f245%2F600x600%2F0d1cf217-1ab9-432b-ba52-6a27221f22f1_600x600?alt=media&token=1f8973e9-3fc5-433c-af9e-3cb4550adcdb",
        "reference": "stroke_photos/3eb9385a-6db9-487c-8f6a-7b4744f4f245/600x600/0d1cf217-1ab9-432b-ba52-6a27221f22f1_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3eb9385a-6db9-487c-8f6a-7b4744f4f245%2F0d1cf217-1ab9-432b-ba52-6a27221f22f1?alt=media&token=863f9dac-9c21-4d4a-8f28-64761471ee8a",
        "reference": "/stroke_photos/3eb9385a-6db9-487c-8f6a-7b4744f4f245/0d1cf217-1ab9-432b-ba52-6a27221f22f1"
      }
    },
    "paper": {
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "ref": "77725080001",
      "name": null,
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    },
    "id": "3eb9385a-6db9-487c-8f6a-7b4744f4f245"
  },
  {
    "order_number": 1031,
    "name": "",
    "description": "",
    "id": "3ec27ae5-bd6d-4859-9111-b55e1c75ce56"
  },
  {
    "tool": null,
    "order_id": 0,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/3ef9a09c-b724-4847-8d82-110a2b5a9741/600x600/6dbc9a7e-1a88-437e-a091-8ee9e3066b97_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3ef9a09c-b724-4847-8d82-110a2b5a9741%2F600x600%2F6dbc9a7e-1a88-437e-a091-8ee9e3066b97_600x600?alt=media&token=470d8400-ee8a-4030-b0f1-9ca191ab94b1"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3ef9a09c-b724-4847-8d82-110a2b5a9741%2F6dbc9a7e-1a88-437e-a091-8ee9e3066b97?alt=media&token=8600ec95-aba2-4ab8-ac4d-113468071194",
        "reference": "/stroke_photos/3ef9a09c-b724-4847-8d82-110a2b5a9741/6dbc9a7e-1a88-437e-a091-8ee9e3066b97"
      }
    },
    "order_number": 108,
    "name": "4 Presses 1",
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "ref": "20180107",
      "name": null,
      "original_size": "A7",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {},
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "id": "3ef9a09c-b724-4847-8d82-110a2b5a9741"
  },
  {
    "id": "3f39274f-b930-43ff-87c4-9dd4e4c4e6fe",
    "paper": {
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "name": null,
      "gsm": "250",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "ref": "77725080001",
      "original_size": "A7",
      "price": 1.35,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "description": "",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3f39274f-b930-43ff-87c4-9dd4e4c4e6fe%2F600x600%2F2a76731d-9fa1-404a-8614-170725d3207e_600x600?alt=media&token=5a832866-0099-4414-b833-3b7937f3841b",
        "reference": "stroke_photos/3f39274f-b930-43ff-87c4-9dd4e4c4e6fe/600x600/2a76731d-9fa1-404a-8614-170725d3207e_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F3f39274f-b930-43ff-87c4-9dd4e4c4e6fe%2F2a76731d-9fa1-404a-8614-170725d3207e?alt=media&token=8a79c174-e1f2-4df8-933e-33d24c6b6235",
        "reference": "/stroke_photos/3f39274f-b930-43ff-87c4-9dd4e4c4e6fe/2a76731d-9fa1-404a-8614-170725d3207e"
      }
    },
    "order_number": 757,
    "order_id": 0,
    "tool": null,
    "name": "Blank"
  },
  {
    "description": "",
    "order_number": 192,
    "id": "40e35ebe-4853-4906-94af-e678d21a7a67",
    "paper": {
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "gsm": "250",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "A7",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "price": 1.35,
      "ref": "77725080001"
    },
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F40e35ebe-4853-4906-94af-e678d21a7a67%2F600x600%2Faa1507da-22e2-4e4a-9c29-27c5a539ccba_600x600?alt=media&token=eb9cb605-84d1-40a0-97be-b336df1eece2",
        "reference": "stroke_photos/40e35ebe-4853-4906-94af-e678d21a7a67/600x600/aa1507da-22e2-4e4a-9c29-27c5a539ccba_600x600"
      },
      "original": {
        "reference": "/stroke_photos/40e35ebe-4853-4906-94af-e678d21a7a67/aa1507da-22e2-4e4a-9c29-27c5a539ccba",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F40e35ebe-4853-4906-94af-e678d21a7a67%2Faa1507da-22e2-4e4a-9c29-27c5a539ccba?alt=media&token=1f0d7337-bfda-49f2-8b28-d7206760cef4"
      }
    },
    "order_id": 0,
    "tool": null
  },
  {
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "name": "Elephant Skin"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "ref": "4005329065767",
      "price": 1,
      "name": null,
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "gsm": "110",
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "original_size": "A4"
    },
    "order_id": 0,
    "order_number": 853,
    "id": "41076a9d-161e-4135-82ee-9bc6a868571c",
    "description": "",
    "name": "Blank"
  },
  {
    "paper": {
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "ref": "77725080001",
      "original_size": "A7",
      "name": null,
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "price": 1.35
    },
    "name": "Blank",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F42485b00-2b35-4418-83ed-5ffc51b7acd9%2Fc4b11d28-c794-44ca-b36a-930219c44055?alt=media&token=489168ed-5e79-452f-8a67-c619233c5a83",
        "reference": "/stroke_photos/42485b00-2b35-4418-83ed-5ffc51b7acd9/c4b11d28-c794-44ca-b36a-930219c44055"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/42485b00-2b35-4418-83ed-5ffc51b7acd9/600x600/c4b11d28-c794-44ca-b36a-930219c44055_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F42485b00-2b35-4418-83ed-5ffc51b7acd9%2F600x600%2Fc4b11d28-c794-44ca-b36a-930219c44055_600x600?alt=media&token=e15f5562-13f4-4561-b1bc-b846a2236a4f"
      }
    },
    "id": "42485b00-2b35-4418-83ed-5ffc51b7acd9",
    "order_id": 0,
    "tool": null,
    "order_number": 161,
    "description": ""
  },
  {
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": null,
      "brand": {},
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {},
      "price": null
    },
    "name": "Blank",
    "order_id": 0,
    "description": "",
    "id": "424d3f26-3ca1-4211-a7cd-2c4683b87f9e",
    "order_number": 931
  },
  {
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/4292e44e-69ef-4548-b1b9-48010ff7b221/9735ceb3-430d-47cd-9c3a-b65aa9521ac8",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4292e44e-69ef-4548-b1b9-48010ff7b221%2F9735ceb3-430d-47cd-9c3a-b65aa9521ac8?alt=media&token=2dc64013-74cd-449e-9bef-9d56d00918d2"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/4292e44e-69ef-4548-b1b9-48010ff7b221/600x600/9735ceb3-430d-47cd-9c3a-b65aa9521ac8_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4292e44e-69ef-4548-b1b9-48010ff7b221%2F600x600%2F9735ceb3-430d-47cd-9c3a-b65aa9521ac8_600x600?alt=media&token=adfc86ef-de59-4894-be20-b7ad2adf778a"
      }
    },
    "paper": {
      "gsm": "250",
      "name": null,
      "brand_model": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "ref": "70025010030",
      "original_size": "A7",
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      }
    },
    "tool": null,
    "id": "4292e44e-69ef-4548-b1b9-48010ff7b221",
    "name": "Blank",
    "order_id": 0,
    "order_number": 593
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F42d8ab0b-ac43-486a-ab4f-812362288274%2F600x600%2F0837bfc7-bdd5-4664-964c-240c38e4e86c_600x600?alt=media&token=ede59fa4-e641-4ca8-96fc-e6f835ac85eb",
        "reference": "stroke_photos/42d8ab0b-ac43-486a-ab4f-812362288274/600x600/0837bfc7-bdd5-4664-964c-240c38e4e86c_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F42d8ab0b-ac43-486a-ab4f-812362288274%2F0837bfc7-bdd5-4664-964c-240c38e4e86c?alt=media&token=936bc0a8-3685-4356-840d-f533492a0f34",
        "reference": "/stroke_photos/42d8ab0b-ac43-486a-ab4f-812362288274/0837bfc7-bdd5-4664-964c-240c38e4e86c"
      }
    },
    "description": "",
    "name": "Blank",
    "order_number": 550,
    "tool": null,
    "id": "42d8ab0b-ac43-486a-ab4f-812362288274",
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "name": null,
      "ref": "20170703",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "price": 0.4,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "original_size": "A7",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250"
    },
    "order_id": 0
  },
  {
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/43107416-843b-44bd-8bcf-af9d2288b533/600x600/cd95d784-e078-4149-acb4-f91f17725157_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F43107416-843b-44bd-8bcf-af9d2288b533%2F600x600%2Fcd95d784-e078-4149-acb4-f91f17725157_600x600?alt=media&token=b632cb36-2fec-4331-961a-9da312709703"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F43107416-843b-44bd-8bcf-af9d2288b533%2Fcd95d784-e078-4149-acb4-f91f17725157?alt=media&token=b3f1d10f-c6d1-4a74-abcb-e36d96e4d80c",
        "reference": "/stroke_photos/43107416-843b-44bd-8bcf-af9d2288b533/cd95d784-e078-4149-acb4-f91f17725157"
      }
    },
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "20180107",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "brand": {}
    },
    "id": "43107416-843b-44bd-8bcf-af9d2288b533",
    "tool": null,
    "order_number": 140,
    "name": "Sideways Bounce Last Last Final More Real X",
    "order_id": 0,
    "description": ""
  },
  {
    "id": "43a9e286-7bc6-449d-aa64-1d135dea3259",
    "order_id": 0,
    "description": "<p>Average amount of paint. Seems a bit transpaprent</p>",
    "order_number": 447,
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": null,
      "price": null,
      "brand": {},
      "name": null,
      "gsm": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {},
      "original_size": "A4",
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      }
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/43a9e286-7bc6-449d-aa64-1d135dea3259/600x600/6e178976-4e6e-4eb5-84ca-bc3a3e311353_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F43a9e286-7bc6-449d-aa64-1d135dea3259%2F600x600%2F6e178976-4e6e-4eb5-84ca-bc3a3e311353_600x600?alt=media&token=d7e51b24-6bc4-42b2-8d51-6c04a9a1cbba"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F43a9e286-7bc6-449d-aa64-1d135dea3259%2F6e178976-4e6e-4eb5-84ca-bc3a3e311353?alt=media&token=b44b671a-0371-40a9-8735-45a58bfa337e",
        "reference": "/stroke_photos/43a9e286-7bc6-449d-aa64-1d135dea3259/6e178976-4e6e-4eb5-84ca-bc3a3e311353"
      }
    },
    "name": "First White",
    "tool": null
  },
  {
    "name": "",
    "order_number": 1066,
    "description": "",
    "id": "43e049bd-8826-4031-a36f-65a48b05bd4e"
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F446446d4-134f-47bd-ac50-8f6a652f90ee%2F600x600%2F84ee84b1-f880-4c6c-9fdd-666983a07d54_600x600?alt=media&token=27fd5e50-72fc-4e3e-beae-d652bcca6498",
        "reference": "stroke_photos/446446d4-134f-47bd-ac50-8f6a652f90ee/600x600/84ee84b1-f880-4c6c-9fdd-666983a07d54_600x600"
      },
      "original": {
        "reference": "/stroke_photos/446446d4-134f-47bd-ac50-8f6a652f90ee/84ee84b1-f880-4c6c-9fdd-666983a07d54",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F446446d4-134f-47bd-ac50-8f6a652f90ee%2F84ee84b1-f880-4c6c-9fdd-666983a07d54?alt=media&token=513c0e3d-c3ee-4167-87a6-8b8394072b43"
      }
    },
    "paper": {
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "original_size": "A7",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "price": 1.35,
      "gsm": "250",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    },
    "tool": null,
    "order_number": 409,
    "name": "Getting Rid of Some Paint",
    "id": "446446d4-134f-47bd-ac50-8f6a652f90ee",
    "description": "Tried to get rid of some paint in the base of the brush",
    "order_id": 0
  },
  {
    "id": "44899215-631d-4e7e-94bc-702e24a1c8a3",
    "photos": {
      "original": {
        "reference": "/stroke_photos/44899215-631d-4e7e-94bc-702e24a1c8a3/0cbe2096-277b-4d26-abde-e1028cd26e44",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F44899215-631d-4e7e-94bc-702e24a1c8a3%2F0cbe2096-277b-4d26-abde-e1028cd26e44?alt=media&token=d7b1ee23-03be-41bc-887d-be23e5c23ca3"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F44899215-631d-4e7e-94bc-702e24a1c8a3%2F600x600%2F0cbe2096-277b-4d26-abde-e1028cd26e44_600x600?alt=media&token=0f9d6f3e-6cdf-45f7-b115-4e0f68be543c",
        "reference": "stroke_photos/44899215-631d-4e7e-94bc-702e24a1c8a3/600x600/0cbe2096-277b-4d26-abde-e1028cd26e44_600x600"
      }
    },
    "paper": {
      "name": null,
      "gsm": "250",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "original_size": "A7",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "price": 1.35,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "ref": "77725080001",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      }
    },
    "order_number": 213,
    "name": "Resemblance But Small Butt",
    "order_id": 0,
    "description": "",
    "tool": null
  },
  {
    "paper": {
      "name": null,
      "original_size": "A7",
      "price": 0.4,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "brand": {},
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "20170703",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "description": "",
    "order_number": 819,
    "name": "Blank",
    "id": "44970086-ef63-4b0a-97fc-2942d11d3f0a",
    "order_id": 0
  },
  {
    "order_id": 0,
    "order_number": 265,
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/449ca8c6-4f79-47b4-b73a-072987d04065/600x600/d59cd09f-1fa9-4083-a24f-e55f9a0eca2d_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F449ca8c6-4f79-47b4-b73a-072987d04065%2F600x600%2Fd59cd09f-1fa9-4083-a24f-e55f9a0eca2d_600x600?alt=media&token=c3d9ac3b-7303-4624-92c9-6cfbdcec2fba"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F449ca8c6-4f79-47b4-b73a-072987d04065%2Fd59cd09f-1fa9-4083-a24f-e55f9a0eca2d?alt=media&token=342bac64-1d0d-4b8c-b0a1-be671cab467b",
        "reference": "/stroke_photos/449ca8c6-4f79-47b4-b73a-072987d04065/d59cd09f-1fa9-4083-a24f-e55f9a0eca2d"
      }
    },
    "id": "449ca8c6-4f79-47b4-b73a-072987d04065",
    "description": "<p>Not bad, I am very accurate</p>",
    "paper": {
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "20180107",
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "gsm": "250",
      "brand": {},
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      }
    },
    "name": "Another Wide Stroke"
  },
  {
    "id": "44b6df0d-64ad-4999-be78-0b007bcc77d0",
    "photos": {
      "original": {
        "reference": "/stroke_photos/44b6df0d-64ad-4999-be78-0b007bcc77d0/49087f9c-f285-42c6-814b-97bbf468e1ee",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F44b6df0d-64ad-4999-be78-0b007bcc77d0%2F49087f9c-f285-42c6-814b-97bbf468e1ee?alt=media&token=84f19b0d-646a-4a69-bc6a-ec70ab112f12"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/44b6df0d-64ad-4999-be78-0b007bcc77d0/600x600/49087f9c-f285-42c6-814b-97bbf468e1ee_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F44b6df0d-64ad-4999-be78-0b007bcc77d0%2F600x600%2F49087f9c-f285-42c6-814b-97bbf468e1ee_600x600?alt=media&token=fbf2c7e8-870c-4f81-b674-813b71e18604"
      }
    },
    "name": "White Experiment",
    "order_id": 0,
    "order_number": 498,
    "tool": null,
    "description": "<p>(!) Very interesting! I was pressing up and down and up and down and it turned zig-zagish and it's lovely! Will try more</p>",
    "paper": {
      "brand_model": {},
      "brand": {},
      "original_size": "A4",
      "ref": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": null,
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "gsm": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    }
  },
  {
    "description": "",
    "tool": null,
    "order_number": 129,
    "id": "44c38d3d-5ff3-481a-9698-296b6c531bdb",
    "order_id": 0,
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "original_size": "A7",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "name": null,
      "price": null,
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {}
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/44c38d3d-5ff3-481a-9698-296b6c531bdb/600x600/292a0aa1-51da-463b-9d9c-840a48e793b4_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F44c38d3d-5ff3-481a-9698-296b6c531bdb%2F600x600%2F292a0aa1-51da-463b-9d9c-840a48e793b4_600x600?alt=media&token=c3ac59f9-ba97-4798-bc01-b3813f52b9d6"
      },
      "original": {
        "reference": "/stroke_photos/44c38d3d-5ff3-481a-9698-296b6c531bdb/292a0aa1-51da-463b-9d9c-840a48e793b4",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F44c38d3d-5ff3-481a-9698-296b6c531bdb%2F292a0aa1-51da-463b-9d9c-840a48e793b4?alt=media&token=8ad1e0b4-96c7-4999-935e-bf38c1c55570"
      }
    },
    "name": "Vertebrae"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1212,
    "id": "44e435e4-80d2-4a83-a90f-841ab4c6694a"
  },
  {
    "order_id": 0,
    "order_number": 950,
    "description": "",
    "id": "451e30f2-7d55-4e4a-82f3-6ce13c75c4c1",
    "paper": {
      "ref": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A4",
      "gsm": null,
      "name": null,
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "price": null,
      "brand": {}
    },
    "name": "Blank"
  },
  {
    "id": "4563a24f-a7cf-41c2-a30c-7a5542ee77aa",
    "paper": {
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "original_size": "A7",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": 0.4,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand": {},
      "ref": "20170703",
      "name": null
    },
    "name": "Blank",
    "order_id": 0,
    "description": "",
    "order_number": 801
  },
  {
    "paper": {
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "name": null,
      "gsm": "250",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "20180107",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      }
    },
    "description": "",
    "order_number": 125,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/45ca99f5-11a6-429e-8e69-877d38c8861a/600x600/f064c001-acf1-48b4-88c0-c44f12c46aa7_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F45ca99f5-11a6-429e-8e69-877d38c8861a%2F600x600%2Ff064c001-acf1-48b4-88c0-c44f12c46aa7_600x600?alt=media&token=74be2351-722a-4187-98da-dc1d4d8dd9d6"
      },
      "original": {
        "reference": "/stroke_photos/45ca99f5-11a6-429e-8e69-877d38c8861a/f064c001-acf1-48b4-88c0-c44f12c46aa7",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F45ca99f5-11a6-429e-8e69-877d38c8861a%2Ff064c001-acf1-48b4-88c0-c44f12c46aa7?alt=media&token=8e3bc428-bae5-42c2-91b5-030fbfc2227c"
      }
    },
    "order_id": 0,
    "name": "Evenness of 5",
    "id": "45ca99f5-11a6-429e-8e69-877d38c8861a",
    "tool": null
  },
  {
    "purchase": {
      "buyer": {
        "email": "dobromir.v.zlatkov@gmail.com",
        "firstName": "Dobromir",
        "lastName": "Zlatkov"
      },
      "date": "2021-03-01T00:00:00.000Z",
      "status": "bought",
      "internalDescription": "first buyer",
      "anonymous": false
    },
    "tool": {},
    "order_number": 10,
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "200",
      "original_size": "A4",
      "paper_surface": {},
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "ref": "44202129",
      "name": "",
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "price": null
    },
    "name": "Using Kitchen Knife",
    "description": "",
    "order_id": 0,
    "id": "45dc2d42-37a3-4f24-86de-1ecb00aacc82",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F45dc2d42-37a3-4f24-86de-1ecb00aacc82%2Feae40590-d719-44b6-b892-966640805453?alt=media&token=364bdc6b-7a0d-46c3-aa9e-655a5821e052",
        "reference": "/stroke_photos/45dc2d42-37a3-4f24-86de-1ecb00aacc82/eae40590-d719-44b6-b892-966640805453"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/45dc2d42-37a3-4f24-86de-1ecb00aacc82/600x600/eae40590-d719-44b6-b892-966640805453_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F45dc2d42-37a3-4f24-86de-1ecb00aacc82%2F600x600%2Feae40590-d719-44b6-b892-966640805453_600x600?alt=media&token=f7a9e97c-435d-4ffd-b246-0b54177a9520"
      }
    }
  },
  {
    "name": "",
    "order_number": 1082,
    "description": "",
    "id": "45fa9568-eef0-495c-93f9-006cfea33cb3"
  },
  {
    "order_number": 1155,
    "name": "",
    "description": "",
    "id": "462e9eed-57e3-4cd5-8434-e9c85609aa5f"
  },
  {
    "paper": {
      "brand_model": {
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4",
      "paper_surface": {},
      "ref": "44202129",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "price": null,
      "gsm": "200",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "name": ""
    },
    "id": "463476c1-9052-4df3-ac1f-afb72af0e887",
    "order_id": 0,
    "tool": null,
    "purchase": {
      "buyer": {
        "firstName": "Dobromir",
        "email": "dobromir.v.zlatkov@gmail.com",
        "lastName": "Zlatkov"
      },
      "internalDescription": "first buyer",
      "status": "bought",
      "anonymous": false,
      "date": "2021-03-01T00:00:00.000Z"
    },
    "name": "SF No Paint, No Clothes",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/463476c1-9052-4df3-ac1f-afb72af0e887/600x600/bccb50ce-898b-4f01-9d29-79a84a010142_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F463476c1-9052-4df3-ac1f-afb72af0e887%2F600x600%2Fbccb50ce-898b-4f01-9d29-79a84a010142_600x600?alt=media&token=c152c55d-3f9c-4778-965d-ee5223b97fe6"
      },
      "original": {
        "reference": "/stroke_photos/463476c1-9052-4df3-ac1f-afb72af0e887/bccb50ce-898b-4f01-9d29-79a84a010142",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F463476c1-9052-4df3-ac1f-afb72af0e887%2Fbccb50ce-898b-4f01-9d29-79a84a010142?alt=media&token=d81d6624-3818-4abb-9ba8-67a937d315f5"
      }
    },
    "order_number": 69,
    "description": ""
  },
  {
    "id": "463b95f9-5142-4dfe-bd21-5cc0c9f7fc86",
    "paper": {
      "original_size": "A4",
      "price": 1,
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "gsm": "110",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "name": "Elephant Skin"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "ref": "4005329065767",
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701"
    },
    "name": "Blank",
    "order_number": 884,
    "order_id": 0,
    "description": ""
  },
  {
    "order_id": 0,
    "order_number": 625,
    "name": "Blank",
    "id": "4643e9e0-dd57-40c6-b9fb-ee6e7b79382a",
    "paper": {
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "gsm": "250",
      "name": null,
      "brand": {},
      "brand_model": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "70025010030",
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "original_size": "A7",
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      }
    },
    "tool": null,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/4643e9e0-dd57-40c6-b9fb-ee6e7b79382a/600x600/3c49acac-b2f3-4d81-8512-1f1888c2f686_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4643e9e0-dd57-40c6-b9fb-ee6e7b79382a%2F600x600%2F3c49acac-b2f3-4d81-8512-1f1888c2f686_600x600?alt=media&token=83641b15-6ba5-40e5-839b-02f33344210e"
      },
      "original": {
        "reference": "/stroke_photos/4643e9e0-dd57-40c6-b9fb-ee6e7b79382a/3c49acac-b2f3-4d81-8512-1f1888c2f686",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4643e9e0-dd57-40c6-b9fb-ee6e7b79382a%2F3c49acac-b2f3-4d81-8512-1f1888c2f686?alt=media&token=0b522f87-4daf-4218-84c6-e53a6bf5a20d"
      }
    }
  },
  {
    "tool": null,
    "description": "<p>Okay, something new</p>",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4645c310-57a9-4bcd-95d2-13caa788b19b%2F078b26be-1599-49f4-b719-aaff6a81a78f?alt=media&token=e97566ae-c052-45b6-a0ee-5ac28aefe393",
        "reference": "/stroke_photos/4645c310-57a9-4bcd-95d2-13caa788b19b/078b26be-1599-49f4-b719-aaff6a81a78f"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/4645c310-57a9-4bcd-95d2-13caa788b19b/600x600/078b26be-1599-49f4-b719-aaff6a81a78f_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4645c310-57a9-4bcd-95d2-13caa788b19b%2F600x600%2F078b26be-1599-49f4-b719-aaff6a81a78f_600x600?alt=media&token=a91846e5-b6b6-429e-9fc7-8e09ea1c2135"
      }
    },
    "paper": {
      "ref": "20180107",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {},
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "name": null,
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250"
    },
    "order_number": 366,
    "id": "4645c310-57a9-4bcd-95d2-13caa788b19b",
    "order_id": 0,
    "name": "Diagonal Sideways"
  },
  {
    "description": "",
    "order_number": 1029,
    "name": "",
    "id": "46656394-4ed2-4ec9-a93e-25e7c0a832e9"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/467e30ad-7e2d-4ece-bc2c-724c418528e7/16002be0-c19d-4c65-a6c4-9b3b21006198",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F467e30ad-7e2d-4ece-bc2c-724c418528e7%2F16002be0-c19d-4c65-a6c4-9b3b21006198?alt=media&token=581f7374-ea89-45ed-bee2-663bcc559e21"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/467e30ad-7e2d-4ece-bc2c-724c418528e7/600x600/16002be0-c19d-4c65-a6c4-9b3b21006198_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F467e30ad-7e2d-4ece-bc2c-724c418528e7%2F600x600%2F16002be0-c19d-4c65-a6c4-9b3b21006198_600x600?alt=media&token=182f5df8-1af3-4a32-8294-5c453ac6541a"
      }
    },
    "description": "",
    "name": "Two-Point Fail",
    "tool": null,
    "order_number": 122,
    "order_id": 0,
    "id": "467e30ad-7e2d-4ece-bc2c-724c418528e7",
    "paper": {
      "price": null,
      "name": null,
      "brand": {},
      "gsm": "250",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "20180107"
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1046,
    "id": "46993020-aacb-43dc-a95d-ee33f9facfee"
  },
  {
    "tool": null,
    "paper": {
      "paper_surface": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "price": null,
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "200",
      "ref": "44202129",
      "name": "",
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "description": "",
    "id": "46a2365c-56df-478c-a5c8-11376b881490",
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/46a2365c-56df-478c-a5c8-11376b881490/4550ffdc-c328-4d04-b565-0c89f42830c4",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F46a2365c-56df-478c-a5c8-11376b881490%2F4550ffdc-c328-4d04-b565-0c89f42830c4?alt=media&token=41960d09-a31e-45f3-a2bc-6af135370157"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/46a2365c-56df-478c-a5c8-11376b881490/600x600/4550ffdc-c328-4d04-b565-0c89f42830c4_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F46a2365c-56df-478c-a5c8-11376b881490%2F600x600%2F4550ffdc-c328-4d04-b565-0c89f42830c4_600x600?alt=media&token=9b640fe9-aefa-4b45-b52b-fac6dbd03492"
      }
    },
    "name": "Pfsmpsitb Reload",
    "order_number": 43
  },
  {
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F46c3a193-2526-45de-b290-6189822a5981%2F0e586950-edd1-4ee8-bba3-b55c87ade365?alt=media&token=10b22d85-eaa8-4904-a587-8b1bdbbdcbd1",
        "reference": "/stroke_photos/46c3a193-2526-45de-b290-6189822a5981/0e586950-edd1-4ee8-bba3-b55c87ade365"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/46c3a193-2526-45de-b290-6189822a5981/600x600/0e586950-edd1-4ee8-bba3-b55c87ade365_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F46c3a193-2526-45de-b290-6189822a5981%2F600x600%2F0e586950-edd1-4ee8-bba3-b55c87ade365_600x600?alt=media&token=4c47c3f3-e4b9-4251-bccb-b0f77b6929e0"
      }
    },
    "paper": {
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top"
      },
      "price": null,
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "original_size": "A7",
      "gsm": "250",
      "name": null
    },
    "order_number": 278,
    "name": "Flat Regular",
    "description": "<p>Disappointed</p>",
    "tool": null,
    "id": "46c3a193-2526-45de-b290-6189822a5981"
  },
  {
    "id": "46df7d12-e422-40b4-8a54-2994d5fc82e4",
    "photos": {
      "original": {
        "reference": "/stroke_photos/46df7d12-e422-40b4-8a54-2994d5fc82e4/500d36f2-609e-4beb-aa58-1a2e26791c88",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F46df7d12-e422-40b4-8a54-2994d5fc82e4%2F500d36f2-609e-4beb-aa58-1a2e26791c88?alt=media&token=d1cb6c8d-c6ef-4ba7-8ef3-f23b7f27ba97"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/46df7d12-e422-40b4-8a54-2994d5fc82e4/600x600/500d36f2-609e-4beb-aa58-1a2e26791c88_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F46df7d12-e422-40b4-8a54-2994d5fc82e4%2F600x600%2F500d36f2-609e-4beb-aa58-1a2e26791c88_600x600?alt=media&token=25efc8ba-a7cb-43b5-8c92-7cbb0b480eed"
      }
    },
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "original_size": "A4",
      "brand_model": {},
      "gsm": null,
      "ref": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {}
    },
    "tool": null,
    "name": "Just Another White Blob",
    "order_id": 0,
    "order_number": 491,
    "description": "<p>I must have pressed a lot, because the stroke is wide</p>"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1109,
    "id": "46eb4a20-77b0-48b3-882e-14357b3a2059"
  },
  {
    "name": "Variable Pressure Bottom",
    "order_number": 103,
    "paper": {
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "gsm": "250",
      "name": null,
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "20180107",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86"
    },
    "tool": null,
    "description": "",
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F46fe4919-db28-4c88-a564-9bada89ed89d%2F7a895faf-0239-4fa2-b63d-7a1729b2b873?alt=media&token=e05c322a-ec5c-4b65-8dac-4b916eae740d",
        "reference": "/stroke_photos/46fe4919-db28-4c88-a564-9bada89ed89d/7a895faf-0239-4fa2-b63d-7a1729b2b873"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/46fe4919-db28-4c88-a564-9bada89ed89d/600x600/7a895faf-0239-4fa2-b63d-7a1729b2b873_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F46fe4919-db28-4c88-a564-9bada89ed89d%2F600x600%2F7a895faf-0239-4fa2-b63d-7a1729b2b873_600x600?alt=media&token=0c5cc3d7-d569-443c-a547-b402c89f6dcf"
      }
    },
    "id": "46fe4919-db28-4c88-a564-9bada89ed89d"
  },
  {
    "tool": null,
    "name": "Blank",
    "id": "4712a328-0156-434b-b13c-0a48ac92f1a1",
    "order_number": 740,
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand": {},
      "price": 0.4,
      "name": null,
      "original_size": "A7",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "ref": "20170703",
      "gsm": "250"
    },
    "description": "",
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/4712a328-0156-434b-b13c-0a48ac92f1a1/88f193d5-8d15-4625-9c20-4ac0bab227ab",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4712a328-0156-434b-b13c-0a48ac92f1a1%2F88f193d5-8d15-4625-9c20-4ac0bab227ab?alt=media&token=96f007be-4f00-4569-9a78-f1bfae8a238f"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4712a328-0156-434b-b13c-0a48ac92f1a1%2F600x600%2F88f193d5-8d15-4625-9c20-4ac0bab227ab_600x600?alt=media&token=7263db76-4605-4906-a99e-9f36a1bdf2d4",
        "reference": "stroke_photos/4712a328-0156-434b-b13c-0a48ac92f1a1/600x600/88f193d5-8d15-4625-9c20-4ac0bab227ab_600x600"
      }
    }
  },
  {
    "name": "",
    "order_number": 1225,
    "description": "",
    "id": "47168b2a-af01-469e-8964-0586fe11732d"
  },
  {
    "order_id": 0,
    "name": "Blank",
    "order_number": 902,
    "paper": {
      "original_size": "A4",
      "brand": {},
      "brand_model": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "ref": null,
      "price": null,
      "name": null,
      "gsm": null
    },
    "description": "",
    "id": "471709d4-24d0-42c7-9a0a-813e06b2c534"
  },
  {
    "order_number": 1177,
    "description": "",
    "name": "",
    "id": "474db68f-d38b-4eb5-8e90-992dc26acacf"
  },
  {
    "order_id": 0,
    "name": "Blank",
    "order_number": 936,
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "gsm": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "brand_model": {},
      "brand": {},
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "price": null
    },
    "description": "",
    "id": "4755e92f-95da-49bc-a489-c8dc8870b4cd"
  },
  {
    "description": "",
    "name": "Blank",
    "id": "47aa913f-af1e-404b-b8f6-50cd9788ea6a",
    "paper": {
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "brand": {},
      "brand_model": {},
      "name": null,
      "gsm": "250",
      "ref": "70025010030",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      }
    },
    "tool": null,
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/47aa913f-af1e-404b-b8f6-50cd9788ea6a/c388e1e3-6e68-48e5-8fc0-cfe171d669c9",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F47aa913f-af1e-404b-b8f6-50cd9788ea6a%2Fc388e1e3-6e68-48e5-8fc0-cfe171d669c9?alt=media&token=a33660af-1234-43c8-8e9c-2f90c35ebe09"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/47aa913f-af1e-404b-b8f6-50cd9788ea6a/600x600/c388e1e3-6e68-48e5-8fc0-cfe171d669c9_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F47aa913f-af1e-404b-b8f6-50cd9788ea6a%2F600x600%2Fc388e1e3-6e68-48e5-8fc0-cfe171d669c9_600x600?alt=media&token=6f2a0de2-51dc-4b1b-83da-294433388608"
      }
    },
    "order_number": 616
  },
  {
    "description": "",
    "name": "",
    "order_number": 1089,
    "id": "47db7c02-390d-4b95-84fb-ae3be061658b"
  },
  {
    "order_number": 292,
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20180107",
      "name": null,
      "price": null,
      "brand": {},
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86"
    },
    "id": "48568631-5a7b-4f22-af35-3d1c10cc3833",
    "order_id": 0,
    "description": "<p>I will make it on the paper or I quit. Okay, I am safe, don't even care how it looks</p>",
    "name": "Just Make It",
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F48568631-5a7b-4f22-af35-3d1c10cc3833%2Fb1df3105-c605-47ae-9270-8699519d8918?alt=media&token=72113d8f-23be-4f6d-864f-b60d858b50f0",
        "reference": "/stroke_photos/48568631-5a7b-4f22-af35-3d1c10cc3833/b1df3105-c605-47ae-9270-8699519d8918"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/48568631-5a7b-4f22-af35-3d1c10cc3833/600x600/b1df3105-c605-47ae-9270-8699519d8918_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F48568631-5a7b-4f22-af35-3d1c10cc3833%2F600x600%2Fb1df3105-c605-47ae-9270-8699519d8918_600x600?alt=media&token=cb0dec8a-bc29-4bca-aac3-1139c14d8daa"
      }
    }
  },
  {
    "order_id": 0,
    "name": "Blank",
    "tool": null,
    "id": "488f213c-b4bb-4472-8619-285b87d10694",
    "description": "",
    "paper": {
      "original_size": "A7",
      "brand_model": {},
      "gsm": "250",
      "name": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "price": null,
      "color": {
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "70025010030",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F488f213c-b4bb-4472-8619-285b87d10694%2F600x600%2Ff7051f4d-d21d-4979-9b47-8cd9a8693150_600x600?alt=media&token=324ff164-0705-4901-9f34-fd2550051da1",
        "reference": "stroke_photos/488f213c-b4bb-4472-8619-285b87d10694/600x600/f7051f4d-d21d-4979-9b47-8cd9a8693150_600x600"
      },
      "original": {
        "reference": "/stroke_photos/488f213c-b4bb-4472-8619-285b87d10694/f7051f4d-d21d-4979-9b47-8cd9a8693150",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F488f213c-b4bb-4472-8619-285b87d10694%2Ff7051f4d-d21d-4979-9b47-8cd9a8693150?alt=media&token=0911a4c6-97c3-4ac4-8f4d-1c7b173d8dbc"
      }
    },
    "order_number": 587
  },
  {
    "id": "48cdcfb8-d04e-4ea3-b691-520b745a0cd9",
    "order_id": 0,
    "paper": {
      "brand": {},
      "name": null,
      "original_size": "A7",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "ref": "70025010030"
    },
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/48cdcfb8-d04e-4ea3-b691-520b745a0cd9/25ba8e9a-9d1b-4f65-8629-71409cf25265",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F48cdcfb8-d04e-4ea3-b691-520b745a0cd9%2F25ba8e9a-9d1b-4f65-8629-71409cf25265?alt=media&token=d3a3fbc4-6b10-47f7-9d81-b28463a9c3a8"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F48cdcfb8-d04e-4ea3-b691-520b745a0cd9%2F600x600%2F25ba8e9a-9d1b-4f65-8629-71409cf25265_600x600?alt=media&token=13ad8174-d77c-4875-b84c-b4d872cd56c1",
        "reference": "stroke_photos/48cdcfb8-d04e-4ea3-b691-520b745a0cd9/600x600/25ba8e9a-9d1b-4f65-8629-71409cf25265_600x600"
      }
    },
    "name": "Blank",
    "order_number": 650,
    "tool": null
  },
  {
    "order_id": 0,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/48d814df-a77c-4744-8074-1f88abae4f6e/600x600/0740a060-b135-4d64-8652-af945f821917_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F48d814df-a77c-4744-8074-1f88abae4f6e%2F600x600%2F0740a060-b135-4d64-8652-af945f821917_600x600?alt=media&token=9228b2b2-46ba-4b28-ba00-379b4f548529"
      },
      "original": {
        "reference": "/stroke_photos/48d814df-a77c-4744-8074-1f88abae4f6e/0740a060-b135-4d64-8652-af945f821917",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F48d814df-a77c-4744-8074-1f88abae4f6e%2F0740a060-b135-4d64-8652-af945f821917?alt=media&token=ffd70d88-e239-4b48-b38a-b9f58e8896fc"
      }
    },
    "tool": null,
    "paper": {
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {},
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "gsm": "250",
      "original_size": "A7",
      "ref": "70025010030",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "name": null
    },
    "id": "48d814df-a77c-4744-8074-1f88abae4f6e",
    "name": "Blank",
    "order_number": 619
  },
  {
    "name": "S Weight of Brush",
    "description": "",
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {},
      "ref": "44202129",
      "original_size": "A4",
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "name": "",
      "gsm": "200",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "price": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    },
    "id": "48d84efb-bd9f-4789-a684-f5a7937540bb",
    "tool": null,
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F48d84efb-bd9f-4789-a684-f5a7937540bb%2F286ba44b-5c03-4dcc-a877-06b994bfd47b?alt=media&token=8d735898-ff10-4f07-bf9e-ee9d9c8aeb03",
        "reference": "/stroke_photos/48d84efb-bd9f-4789-a684-f5a7937540bb/286ba44b-5c03-4dcc-a877-06b994bfd47b"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/48d84efb-bd9f-4789-a684-f5a7937540bb/600x600/286ba44b-5c03-4dcc-a877-06b994bfd47b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F48d84efb-bd9f-4789-a684-f5a7937540bb%2F600x600%2F286ba44b-5c03-4dcc-a877-06b994bfd47b_600x600?alt=media&token=6414f4e7-979e-4114-9022-f476826b7d6b"
      }
    },
    "order_number": 72
  },
  {
    "paper": {
      "price": 1.35,
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "original_size": "A7",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "ref": "77725080001",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      }
    },
    "order_id": 0,
    "order_number": 794,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/48e3ec9e-3e4d-4f39-a102-b08802cac8cd/600x600/8ae764e3-d9ef-405f-8bae-88e06064a971_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F48e3ec9e-3e4d-4f39-a102-b08802cac8cd%2F600x600%2F8ae764e3-d9ef-405f-8bae-88e06064a971_600x600?alt=media&token=da199d62-72d5-4e91-9a16-bbd5f39120ce"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F48e3ec9e-3e4d-4f39-a102-b08802cac8cd%2F8ae764e3-d9ef-405f-8bae-88e06064a971?alt=media&token=f50d1c73-8fe6-4d96-adb3-6984641693b9",
        "reference": "/stroke_photos/48e3ec9e-3e4d-4f39-a102-b08802cac8cd/8ae764e3-d9ef-405f-8bae-88e06064a971"
      }
    },
    "id": "48e3ec9e-3e4d-4f39-a102-b08802cac8cd",
    "description": "",
    "name": "Blank",
    "tool": null
  },
  {
    "name": "Retry 3",
    "tool": null,
    "id": "48f91abf-4ff3-462e-9622-b43da3505ff3",
    "order_id": 0,
    "description": "<p>Unsuccessful.</p>",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F48f91abf-4ff3-462e-9622-b43da3505ff3%2F600x600%2Fb6671338-80a4-45d2-9c7b-f4537a44a070_600x600?alt=media&token=32689f7b-2c6b-4342-87fa-813ca6d2ab93",
        "reference": "stroke_photos/48f91abf-4ff3-462e-9622-b43da3505ff3/600x600/b6671338-80a4-45d2-9c7b-f4537a44a070_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F48f91abf-4ff3-462e-9622-b43da3505ff3%2Fb6671338-80a4-45d2-9c7b-f4537a44a070?alt=media&token=082bd2c7-1ccc-412c-bf4a-43c7772dbc44",
        "reference": "/stroke_photos/48f91abf-4ff3-462e-9622-b43da3505ff3/b6671338-80a4-45d2-9c7b-f4537a44a070"
      }
    },
    "order_number": 275,
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "ref": "20180107",
      "price": null,
      "name": null,
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "gsm": "250"
    }
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F490c3355-5f07-468b-bc90-c18918881739%2F7ec7f11f-d4c8-46cc-9ddd-fe635aa5aeb7?alt=media&token=91fb57a3-bbfc-4df3-aa1b-1365248ca256",
        "reference": "/stroke_photos/490c3355-5f07-468b-bc90-c18918881739/7ec7f11f-d4c8-46cc-9ddd-fe635aa5aeb7"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F490c3355-5f07-468b-bc90-c18918881739%2F600x600%2F7ec7f11f-d4c8-46cc-9ddd-fe635aa5aeb7_600x600?alt=media&token=1ab86def-cdcb-4c1c-a9ea-70cab1bbe302",
        "reference": "stroke_photos/490c3355-5f07-468b-bc90-c18918881739/600x600/7ec7f11f-d4c8-46cc-9ddd-fe635aa5aeb7_600x600"
      }
    },
    "name": "More White",
    "order_id": 0,
    "tool": null,
    "description": "<p>Trying a bit more paint and see if white covers better. Paint stays in the beginning, tail is still transparent</p>",
    "order_number": 448,
    "id": "490c3355-5f07-468b-bc90-c18918881739",
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {},
      "gsm": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "name": null,
      "original_size": "A4",
      "price": null,
      "brand": {}
    }
  },
  {
    "order_id": 0,
    "order_number": 905,
    "paper": {
      "gsm": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "price": null,
      "ref": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand_model": {},
      "brand": {},
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A4"
    },
    "name": "Blank",
    "id": "491e5938-c1b6-4ca8-b572-122e4b714a1c",
    "description": ""
  },
  {
    "paper": {
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "price": 1.35,
      "gsm": "250",
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "original_size": "A7",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "ref": "77725080001"
    },
    "description": "",
    "id": "4955a3d6-a836-4774-843c-513e8bc87a07",
    "order_id": 0,
    "name": "Blank",
    "order_number": 759,
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4955a3d6-a836-4774-843c-513e8bc87a07%2F600x600%2Fc2a3b7a6-bb94-40af-9904-6a9b8fc6f257_600x600?alt=media&token=dbcb2a65-d58b-46e8-9074-797f69baedaf",
        "reference": "stroke_photos/4955a3d6-a836-4774-843c-513e8bc87a07/600x600/c2a3b7a6-bb94-40af-9904-6a9b8fc6f257_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4955a3d6-a836-4774-843c-513e8bc87a07%2Fc2a3b7a6-bb94-40af-9904-6a9b8fc6f257?alt=media&token=988e29f7-e58e-4e1a-800d-8465a6fa0250",
        "reference": "/stroke_photos/4955a3d6-a836-4774-843c-513e8bc87a07/c2a3b7a6-bb94-40af-9904-6a9b8fc6f257"
      }
    }
  },
  {
    "order_id": 0,
    "description": "<p>yeah, not bad, just not homerun</p>",
    "tool": null,
    "paper": {
      "price": null,
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "ref": "20180107",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A7",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250"
    },
    "order_number": 344,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F499738c4-9745-4809-80cb-d706cd8deb34%2F600x600%2F0661c6b8-24f9-4e4a-8230-18d2c057c9ae_600x600?alt=media&token=5e19234f-88d6-4415-ba02-16b7a5bb4d01",
        "reference": "stroke_photos/499738c4-9745-4809-80cb-d706cd8deb34/600x600/0661c6b8-24f9-4e4a-8230-18d2c057c9ae_600x600"
      },
      "original": {
        "reference": "/stroke_photos/499738c4-9745-4809-80cb-d706cd8deb34/0661c6b8-24f9-4e4a-8230-18d2c057c9ae",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F499738c4-9745-4809-80cb-d706cd8deb34%2F0661c6b8-24f9-4e4a-8230-18d2c057c9ae?alt=media&token=e30837d2-534e-465b-bcc1-4e20fd5a44b5"
      }
    },
    "name": "Check 3",
    "id": "499738c4-9745-4809-80cb-d706cd8deb34"
  },
  {
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F49c61f28-2186-4db8-a0c3-2ec26de942cb%2Fd0ead238-37c6-4ace-98c0-6878c6cda1fd?alt=media&token=ab31b978-eed7-495e-822e-34fffc7f89c2",
        "reference": "/stroke_photos/49c61f28-2186-4db8-a0c3-2ec26de942cb/d0ead238-37c6-4ace-98c0-6878c6cda1fd"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F49c61f28-2186-4db8-a0c3-2ec26de942cb%2F600x600%2Fd0ead238-37c6-4ace-98c0-6878c6cda1fd_600x600?alt=media&token=04149a40-d5e4-4415-ae08-2fa78ac9aa63",
        "reference": "stroke_photos/49c61f28-2186-4db8-a0c3-2ec26de942cb/600x600/d0ead238-37c6-4ace-98c0-6878c6cda1fd_600x600"
      }
    },
    "paper": {
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "ref": "20180107"
    },
    "id": "49c61f28-2186-4db8-a0c3-2ec26de942cb",
    "order_number": 331,
    "description": "<p>I kind of like it</p>",
    "name": "The Other Way",
    "tool": null
  },
  {
    "id": "49f36071-7441-41b1-b0d6-d9ea951cbad9",
    "order_id": 0,
    "paper": {
      "name": null,
      "ref": null,
      "original_size": "A4",
      "price": null,
      "brand_model": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": null,
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "brand": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "order_number": 935,
    "name": "Blank",
    "description": ""
  },
  {
    "description": "Oh how I never learned to love this one",
    "tool": null,
    "order_number": 445,
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "ref": "77725080001",
      "gsm": "250",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "name": null,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "price": 1.35,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "original_size": "A7"
    },
    "order_id": 0,
    "name": "Running",
    "id": "4a302cdd-93a4-4d4c-b557-047cf2c8b00c",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4a302cdd-93a4-4d4c-b557-047cf2c8b00c%2F600x600%2F72af2aee-3a73-4ac0-aaa8-0e00f79e1cc5_600x600?alt=media&token=e6c1cdfe-6bba-40a0-b4e9-c17fc122b0d2",
        "reference": "stroke_photos/4a302cdd-93a4-4d4c-b557-047cf2c8b00c/600x600/72af2aee-3a73-4ac0-aaa8-0e00f79e1cc5_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4a302cdd-93a4-4d4c-b557-047cf2c8b00c%2F72af2aee-3a73-4ac0-aaa8-0e00f79e1cc5?alt=media&token=8e706fb8-4fe3-40a9-8683-9f3dd8a305d2",
        "reference": "/stroke_photos/4a302cdd-93a4-4d4c-b557-047cf2c8b00c/72af2aee-3a73-4ac0-aaa8-0e00f79e1cc5"
      }
    }
  },
  {
    "description": "",
    "name": "Blank",
    "id": "4a89dc53-6e13-4244-a12a-de6ae8994827",
    "order_number": 585,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/4a89dc53-6e13-4244-a12a-de6ae8994827/600x600/a8844985-a3dc-4d38-a620-38a04a3a9d0f_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4a89dc53-6e13-4244-a12a-de6ae8994827%2F600x600%2Fa8844985-a3dc-4d38-a620-38a04a3a9d0f_600x600?alt=media&token=85aa96ad-36f7-4f05-a927-c1f5472e2596"
      },
      "original": {
        "reference": "/stroke_photos/4a89dc53-6e13-4244-a12a-de6ae8994827/a8844985-a3dc-4d38-a620-38a04a3a9d0f",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4a89dc53-6e13-4244-a12a-de6ae8994827%2Fa8844985-a3dc-4d38-a620-38a04a3a9d0f?alt=media&token=4f613513-901c-4d96-82de-0b00b868b55b"
      }
    },
    "tool": null,
    "paper": {
      "ref": "70025010030",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "gsm": "250",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": null,
      "brand": {},
      "brand_model": {},
      "name": null,
      "original_size": "A7",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl",
        "hex": "#f5f5f5"
      }
    },
    "order_id": 0
  },
  {
    "name": "",
    "description": "",
    "order_number": 1228,
    "id": "4ab32cd6-93b7-4fac-a54f-e9a354d362a3"
  },
  {
    "description": "",
    "order_number": 1053,
    "name": "",
    "id": "4acb26ee-e481-4db1-b8fa-a577f7040564"
  },
  {
    "name": "",
    "order_number": 1160,
    "description": "",
    "id": "4ae18e1b-ad73-4525-b4ca-5c017d10a1a2"
  },
  {
    "purchase": {
      "date": {
        "seconds": 1677878411,
        "nanoseconds": 127000000
      },
      "anonymous": false,
      "status": "bought",
      "buyer": {
        "lastName": "Apostolova",
        "email": "unknown@mgpepe.com",
        "firstName": "Albena"
      },
      "internalDescription": "adrians sister"
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4b3b4c31-5264-42c3-b89a-275ffef29958%2F78b8ce27-79eb-4480-a0cb-d0a35c18c83e?alt=media&token=72371987-c0a3-49fb-8c28-6ba808dc660a",
        "reference": "/stroke_photos/4b3b4c31-5264-42c3-b89a-275ffef29958/78b8ce27-79eb-4480-a0cb-d0a35c18c83e"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/4b3b4c31-5264-42c3-b89a-275ffef29958/600x600/78b8ce27-79eb-4480-a0cb-d0a35c18c83e_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4b3b4c31-5264-42c3-b89a-275ffef29958%2F600x600%2F78b8ce27-79eb-4480-a0cb-d0a35c18c83e_600x600?alt=media&token=be080534-a749-44c3-8da0-c01d820b3cc3"
      }
    },
    "order_number": 7,
    "tool": {},
    "paper": {
      "gsm": "200",
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4",
      "price": null,
      "name": "",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_surface": {},
      "ref": "44202129",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      }
    },
    "name": "Flat Calligraphy Curves",
    "description": "",
    "id": "4b3b4c31-5264-42c3-b89a-275ffef29958",
    "order_id": 0
  },
  {
    "tool": null,
    "description": "Listening to music that makes me dance (ride it) I drew faster and with less thinking. Bad result, but good mood.",
    "id": "4b6bafd2-b1ce-427e-afb0-38eb480fdd31",
    "name": "Musically",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/4b6bafd2-b1ce-427e-afb0-38eb480fdd31/600x600/62d749fa-3a0a-40ed-b7dc-918b4630da13_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4b6bafd2-b1ce-427e-afb0-38eb480fdd31%2F600x600%2F62d749fa-3a0a-40ed-b7dc-918b4630da13_600x600?alt=media&token=59dea50a-e0a0-4504-994a-15e1d907d34d"
      },
      "original": {
        "reference": "/stroke_photos/4b6bafd2-b1ce-427e-afb0-38eb480fdd31/62d749fa-3a0a-40ed-b7dc-918b4630da13",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4b6bafd2-b1ce-427e-afb0-38eb480fdd31%2F62d749fa-3a0a-40ed-b7dc-918b4630da13?alt=media&token=c100b58d-de55-4292-a0b0-7cad9398e1c4"
      }
    },
    "paper": {
      "original_size": "A7",
      "gsm": "250",
      "name": null,
      "ref": "77725080001",
      "price": 1.35,
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      }
    },
    "order_id": 0,
    "order_number": 236
  },
  {
    "name": "",
    "description": "",
    "order_number": 1214,
    "id": "4bc32e26-027a-4ca0-9905-0949aff66138"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1083,
    "id": "4bd534a1-c417-4a1f-863c-1e10f5f9a743"
  },
  {
    "id": "4bede7c0-41c7-4985-b28b-11c4625b58cc",
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/4bede7c0-41c7-4985-b28b-11c4625b58cc/54f3d22d-cbf8-4906-ab94-52ce7998b09a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4bede7c0-41c7-4985-b28b-11c4625b58cc%2F54f3d22d-cbf8-4906-ab94-52ce7998b09a?alt=media&token=6b9968f1-2902-4511-b96f-9f5b3791e452"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4bede7c0-41c7-4985-b28b-11c4625b58cc%2F600x600%2F54f3d22d-cbf8-4906-ab94-52ce7998b09a_600x600?alt=media&token=5e0d5384-f7c8-44a8-855e-f4415c5d4066",
        "reference": "stroke_photos/4bede7c0-41c7-4985-b28b-11c4625b58cc/600x600/54f3d22d-cbf8-4906-ab94-52ce7998b09a_600x600"
      }
    },
    "name": "Blank",
    "paper": {
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "name": null,
      "gsm": "250",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "price": 1.35,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A7",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    },
    "order_number": 774,
    "description": "",
    "tool": null
  },
  {
    "name": "Blank",
    "description": "",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4bf89343-5218-4f6f-b0f5-0384d307ac64%2F98d2099e-37d7-4687-b5c2-8c567040306c?alt=media&token=57806733-312f-4d63-9527-3f143352bfc4",
        "reference": "/stroke_photos/4bf89343-5218-4f6f-b0f5-0384d307ac64/98d2099e-37d7-4687-b5c2-8c567040306c"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/4bf89343-5218-4f6f-b0f5-0384d307ac64/600x600/98d2099e-37d7-4687-b5c2-8c567040306c_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4bf89343-5218-4f6f-b0f5-0384d307ac64%2F600x600%2F98d2099e-37d7-4687-b5c2-8c567040306c_600x600?alt=media&token=a7dfe5e1-ffd0-4555-b695-979f101a7732"
      }
    },
    "order_id": 0,
    "order_number": 777,
    "paper": {
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "ref": "77725080001",
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "gsm": "250"
    },
    "tool": null,
    "id": "4bf89343-5218-4f6f-b0f5-0384d307ac64"
  },
  {
    "id": "4c3c1c2d-3ab7-487d-aa1d-2e526b105a54",
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": null,
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A4",
      "brand_model": {},
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": null,
      "brand": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "gsm": null
    },
    "tool": null,
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/4c3c1c2d-3ab7-487d-aa1d-2e526b105a54/9d89f298-c208-4a43-aafe-20a8244157ca",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4c3c1c2d-3ab7-487d-aa1d-2e526b105a54%2F9d89f298-c208-4a43-aafe-20a8244157ca?alt=media&token=6c0705e6-b287-463a-ad80-9e2f69c21098"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/4c3c1c2d-3ab7-487d-aa1d-2e526b105a54/600x600/9d89f298-c208-4a43-aafe-20a8244157ca_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4c3c1c2d-3ab7-487d-aa1d-2e526b105a54%2F600x600%2F9d89f298-c208-4a43-aafe-20a8244157ca_600x600?alt=media&token=52eabb10-6655-4208-8591-7ebec5650e04"
      }
    },
    "description": "<p>I will make another one with less paint (!) I like it</p>",
    "order_number": 540,
    "name": "Silver-On-Black V 2"
  },
  {
    "name": "Adding Water to White",
    "id": "4c98c427-7b3a-439c-9ef4-5e4e056df768",
    "order_number": 466,
    "paper": {
      "original_size": "A4",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "gsm": null,
      "name": null,
      "price": null,
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {}
    },
    "description": "<p>You can see the bubbles from the mixing. White is not terribly bad, but maybe should have a tail.</p>",
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4c98c427-7b3a-439c-9ef4-5e4e056df768%2Ffaa425df-6b23-4831-b49a-f2056a92bb8b?alt=media&token=7df3b1b9-466a-4ed4-9bef-7071c562109a",
        "reference": "/stroke_photos/4c98c427-7b3a-439c-9ef4-5e4e056df768/faa425df-6b23-4831-b49a-f2056a92bb8b"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4c98c427-7b3a-439c-9ef4-5e4e056df768%2F600x600%2Ffaa425df-6b23-4831-b49a-f2056a92bb8b_600x600?alt=media&token=61b35d4d-73bb-4814-b352-206921b7cbae",
        "reference": "stroke_photos/4c98c427-7b3a-439c-9ef4-5e4e056df768/600x600/faa425df-6b23-4831-b49a-f2056a92bb8b_600x600"
      }
    },
    "order_id": 0
  },
  {
    "description": "",
    "order_number": 765,
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4ccc6fc2-cd01-4b85-aae3-92c48a558484%2F61f471de-282d-443c-b9ba-258f4a459ffa?alt=media&token=4593f796-e361-4965-be7c-4c743103619a",
        "reference": "/stroke_photos/4ccc6fc2-cd01-4b85-aae3-92c48a558484/61f471de-282d-443c-b9ba-258f4a459ffa"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4ccc6fc2-cd01-4b85-aae3-92c48a558484%2F600x600%2F61f471de-282d-443c-b9ba-258f4a459ffa_600x600?alt=media&token=c484fe0b-6b5e-448b-9b82-bc0704c3025d",
        "reference": "stroke_photos/4ccc6fc2-cd01-4b85-aae3-92c48a558484/600x600/61f471de-282d-443c-b9ba-258f4a459ffa_600x600"
      }
    },
    "tool": null,
    "name": "Blank",
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "original_size": "A7",
      "price": 1.35,
      "ref": "77725080001",
      "gsm": "250",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "name": null,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    },
    "id": "4ccc6fc2-cd01-4b85-aae3-92c48a558484"
  },
  {
    "description": "",
    "order_number": 1118,
    "name": "",
    "id": "4d0067f4-97a3-4266-b576-386142e5b3b7"
  },
  {
    "id": "4d01b3f2-94e3-4e4c-9081-2aa9e260efe6",
    "description": "",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4d01b3f2-94e3-4e4c-9081-2aa9e260efe6%2F600x600%2Fd918d63d-1088-4c25-b417-7f9f39a1b8ee_600x600?alt=media&token=f6829e18-6134-4daa-9e18-fbb04548b8c2",
        "reference": "stroke_photos/4d01b3f2-94e3-4e4c-9081-2aa9e260efe6/600x600/d918d63d-1088-4c25-b417-7f9f39a1b8ee_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4d01b3f2-94e3-4e4c-9081-2aa9e260efe6%2Fd918d63d-1088-4c25-b417-7f9f39a1b8ee?alt=media&token=29d56867-7e4f-495c-9650-8312dabec85c",
        "reference": "/stroke_photos/4d01b3f2-94e3-4e4c-9081-2aa9e260efe6/d918d63d-1088-4c25-b417-7f9f39a1b8ee"
      }
    },
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "price": 0.4,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand": {},
      "name": null,
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "ref": "20170703",
      "original_size": "A7"
    },
    "name": "Blank",
    "order_number": 701,
    "order_id": 0
  },
  {
    "paper": {
      "name": null,
      "price": null,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "20180107",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "gsm": "250",
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86"
    },
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/4d24231f-76a8-4bf0-972b-e36c59134fd1/485b37f3-2a9a-4c33-b448-616f1391571b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4d24231f-76a8-4bf0-972b-e36c59134fd1%2F485b37f3-2a9a-4c33-b448-616f1391571b?alt=media&token=77c5fa29-fa2a-4564-a162-b32674eab700"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4d24231f-76a8-4bf0-972b-e36c59134fd1%2F600x600%2F485b37f3-2a9a-4c33-b448-616f1391571b_600x600?alt=media&token=cfc94496-a731-4b12-a2e0-4e4109da5e42",
        "reference": "stroke_photos/4d24231f-76a8-4bf0-972b-e36c59134fd1/600x600/485b37f3-2a9a-4c33-b448-616f1391571b_600x600"
      }
    },
    "order_number": 290,
    "id": "4d24231f-76a8-4bf0-972b-e36c59134fd1",
    "description": "<p>Damn Hard</p>",
    "name": "Wide Stroke Returns",
    "order_id": 0
  },
  {
    "description": "",
    "name": "",
    "order_number": 1225,
    "id": "4d306f1f-ab25-4a9b-9b3f-60b4e3ca7fd8"
  },
  {
    "description": "Not bad, but you can see the bottom how the line is not perfect arc",
    "name": "WOE HOT 10",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4d346304-9fb6-447d-bd31-b08f4bdd508e%2F600x600%2Fd299349a-8a2e-4846-99e9-35fe7eefc36d_600x600?alt=media&token=44d3f20c-67c7-4192-92b9-4a5c26f2fd09",
        "reference": "stroke_photos/4d346304-9fb6-447d-bd31-b08f4bdd508e/600x600/d299349a-8a2e-4846-99e9-35fe7eefc36d_600x600"
      },
      "original": {
        "reference": "/stroke_photos/4d346304-9fb6-447d-bd31-b08f4bdd508e/d299349a-8a2e-4846-99e9-35fe7eefc36d",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4d346304-9fb6-447d-bd31-b08f4bdd508e%2Fd299349a-8a2e-4846-99e9-35fe7eefc36d?alt=media&token=cd2d640f-034b-48cb-a970-54f2d8d3a64f"
      }
    },
    "paper": {
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "200",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": "",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "paper_surface": {},
      "price": null,
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "A4",
      "ref": "44202129"
    },
    "order_number": 95,
    "tool": null,
    "id": "4d346304-9fb6-447d-bd31-b08f4bdd508e"
  },
  {
    "tool": null,
    "name": "Blank",
    "order_id": 0,
    "id": "4d508343-ac24-4d0f-81d2-bf2761973ae5",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4d508343-ac24-4d0f-81d2-bf2761973ae5%2F3819f26b-cb96-4b75-beef-a97388f1ae1d?alt=media&token=36545ed2-7e64-4580-9613-4ff3b01ab132",
        "reference": "/stroke_photos/4d508343-ac24-4d0f-81d2-bf2761973ae5/3819f26b-cb96-4b75-beef-a97388f1ae1d"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4d508343-ac24-4d0f-81d2-bf2761973ae5%2F600x600%2F3819f26b-cb96-4b75-beef-a97388f1ae1d_600x600?alt=media&token=0e08c3b0-118e-42f6-86a2-d18082061647",
        "reference": "stroke_photos/4d508343-ac24-4d0f-81d2-bf2761973ae5/600x600/3819f26b-cb96-4b75-beef-a97388f1ae1d_600x600"
      }
    },
    "paper": {
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "ref": "77725080001",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "name": null,
      "gsm": "250",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "original_size": "A7",
      "price": 1.35
    },
    "order_number": 156,
    "description": ""
  },
  {
    "paper": {
      "name": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "gsm": "250",
      "original_size": "A7",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "ref": "77725080001",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "price": 1.35
    },
    "order_id": 0,
    "order_number": 755,
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/4d8e9088-0b8b-4089-aad0-2890933d9ab2/600x600/2af262a3-9c1a-488f-b1cd-fdf82246a5a0_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4d8e9088-0b8b-4089-aad0-2890933d9ab2%2F600x600%2F2af262a3-9c1a-488f-b1cd-fdf82246a5a0_600x600?alt=media&token=71f5c2b2-3c4e-4ba0-9fc1-a7dd31846608"
      },
      "original": {
        "reference": "/stroke_photos/4d8e9088-0b8b-4089-aad0-2890933d9ab2/2af262a3-9c1a-488f-b1cd-fdf82246a5a0",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4d8e9088-0b8b-4089-aad0-2890933d9ab2%2F2af262a3-9c1a-488f-b1cd-fdf82246a5a0?alt=media&token=47d378f3-f6b5-4d1a-809e-2968747e6876"
      }
    },
    "description": "",
    "id": "4d8e9088-0b8b-4089-aad0-2890933d9ab2",
    "name": "Blank"
  },
  {
    "description": "",
    "order_number": 1020,
    "name": "",
    "id": "4dc9b218-a178-4b53-902e-fbc075851062"
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4e28d116-2e59-4b2a-991e-4c21107d11d2%2F9258d024-b3e0-4a85-a31b-e69ea8ef89fc?alt=media&token=4c40b44d-138d-43bf-9a83-a100c3c06cbd",
        "reference": "/stroke_photos/4e28d116-2e59-4b2a-991e-4c21107d11d2/9258d024-b3e0-4a85-a31b-e69ea8ef89fc"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/4e28d116-2e59-4b2a-991e-4c21107d11d2/600x600/9258d024-b3e0-4a85-a31b-e69ea8ef89fc_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4e28d116-2e59-4b2a-991e-4c21107d11d2%2F600x600%2F9258d024-b3e0-4a85-a31b-e69ea8ef89fc_600x600?alt=media&token=13666044-ffe5-4ef0-8d2c-0cab34e89a9a"
      }
    },
    "order_number": 100,
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "brand": {},
      "ref": "20180107",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A7",
      "name": null,
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    },
    "name": "Hurray",
    "order_id": 0,
    "id": "4e28d116-2e59-4b2a-991e-4c21107d11d2",
    "tool": null,
    "description": ""
  },
  {
    "description": "",
    "id": "4e537964-4eed-4266-ba26-83ac81fd20d2",
    "order_id": 0,
    "tool": null,
    "name": "Blank",
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": 1.35,
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "ref": "77725080001",
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "original_size": "A7",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      }
    },
    "order_number": 172,
    "photos": {
      "original": {
        "reference": "/stroke_photos/4e537964-4eed-4266-ba26-83ac81fd20d2/adcb6950-d693-4d4f-9def-faa73967c991",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4e537964-4eed-4266-ba26-83ac81fd20d2%2Fadcb6950-d693-4d4f-9def-faa73967c991?alt=media&token=2385ca7b-880f-4781-8678-a876be9b49ba"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/4e537964-4eed-4266-ba26-83ac81fd20d2/600x600/adcb6950-d693-4d4f-9def-faa73967c991_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4e537964-4eed-4266-ba26-83ac81fd20d2%2F600x600%2Fadcb6950-d693-4d4f-9def-faa73967c991_600x600?alt=media&token=8b06e4ca-be67-4c76-8b70-dab95144e1b8"
      }
    }
  },
  {
    "order_id": 0,
    "order_number": 924,
    "id": "4e8f6c54-2fff-405f-9b88-af10d17e6521",
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand": {},
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {},
      "gsm": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "ref": null,
      "original_size": "A4",
      "price": null
    },
    "name": "Blank",
    "description": ""
  },
  {
    "order_number": 65,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4f0904e4-227b-4860-8dfc-48fe6e502c48%2F600x600%2F0ecfa081-2c7a-44aa-8d9e-21f21436627a_600x600?alt=media&token=cf391a82-17a7-4186-a558-e8c13d12d377",
        "reference": "stroke_photos/4f0904e4-227b-4860-8dfc-48fe6e502c48/600x600/0ecfa081-2c7a-44aa-8d9e-21f21436627a_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4f0904e4-227b-4860-8dfc-48fe6e502c48%2F0ecfa081-2c7a-44aa-8d9e-21f21436627a?alt=media&token=99cd13cd-e41c-4e7b-8aa9-80a8edd43338",
        "reference": "/stroke_photos/4f0904e4-227b-4860-8dfc-48fe6e502c48/0ecfa081-2c7a-44aa-8d9e-21f21436627a"
      }
    },
    "tool": null,
    "paper": {
      "paper_surface": {},
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "gsm": "200",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "original_size": "A4",
      "ref": "44202129",
      "price": null,
      "name": ""
    },
    "id": "4f0904e4-227b-4860-8dfc-48fe6e502c48",
    "order_id": 0,
    "name": "S Dry"
  },
  {
    "order_number": 377,
    "description": "<p>I wanted to spin half-way the brush while doing the stroke. Ended up with about 90 CW</p>",
    "paper": {
      "brand": {},
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "price": null,
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20180107"
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/4f226460-94fb-467d-8729-e693e4c6e08a/600x600/93983c17-60b6-4dcd-b18e-7abe6b95e7b6_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4f226460-94fb-467d-8729-e693e4c6e08a%2F600x600%2F93983c17-60b6-4dcd-b18e-7abe6b95e7b6_600x600?alt=media&token=a0afbc9c-c265-4267-a28e-e40413be7b8a"
      },
      "original": {
        "reference": "/stroke_photos/4f226460-94fb-467d-8729-e693e4c6e08a/93983c17-60b6-4dcd-b18e-7abe6b95e7b6",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4f226460-94fb-467d-8729-e693e4c6e08a%2F93983c17-60b6-4dcd-b18e-7abe6b95e7b6?alt=media&token=35437d77-23a8-475d-92ce-c9f04ed121e4"
      }
    },
    "tool": null,
    "order_id": 0,
    "id": "4f226460-94fb-467d-8729-e693e4c6e08a",
    "name": "Spinny Arc"
  },
  {
    "order_number": 436,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4f505214-2085-4146-9137-aeed3b145f83%2F600x600%2Ff6ab7262-9078-4267-8c6b-469d86e7592e_600x600?alt=media&token=d9be69cf-7a69-4e64-9e72-4ddbc0afd910",
        "reference": "stroke_photos/4f505214-2085-4146-9137-aeed3b145f83/600x600/f6ab7262-9078-4267-8c6b-469d86e7592e_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4f505214-2085-4146-9137-aeed3b145f83%2Ff6ab7262-9078-4267-8c6b-469d86e7592e?alt=media&token=26f2b5b7-6b03-466b-9ae1-2bf47d03edf2",
        "reference": "/stroke_photos/4f505214-2085-4146-9137-aeed3b145f83/f6ab7262-9078-4267-8c6b-469d86e7592e"
      }
    },
    "paper": {
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "price": 1.35,
      "original_size": "A7",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "ref": "77725080001",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "name": null,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      }
    },
    "order_id": 0,
    "id": "4f505214-2085-4146-9137-aeed3b145f83",
    "tool": null,
    "name": "Press-N-Scratch 3",
    "description": "Focused really on the delicateness I managed to yield the Loch Ness Monster"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1146,
    "id": "4f9359aa-a266-45ee-b596-d8ffb269dbd0"
  },
  {
    "order_number": 450,
    "id": "4fa7327f-b2e1-442a-89bb-aab48a861e7f",
    "tool": null,
    "paper": {
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "gsm": null,
      "price": null,
      "brand": {},
      "ref": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand_model": {},
      "name": null,
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b"
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4fa7327f-b2e1-442a-89bb-aab48a861e7f%2F4977e05c-5146-482c-8109-5fac90cd62d5?alt=media&token=8aa071db-75e0-4d1b-97c6-63c6ada5d671",
        "reference": "/stroke_photos/4fa7327f-b2e1-442a-89bb-aab48a861e7f/4977e05c-5146-482c-8109-5fac90cd62d5"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4fa7327f-b2e1-442a-89bb-aab48a861e7f%2F600x600%2F4977e05c-5146-482c-8109-5fac90cd62d5_600x600?alt=media&token=ce462fa8-7651-4cdd-a089-50d27afccb2a",
        "reference": "stroke_photos/4fa7327f-b2e1-442a-89bb-aab48a861e7f/600x600/4977e05c-5146-482c-8109-5fac90cd62d5_600x600"
      }
    },
    "name": "White Brush Lowered",
    "description": "<p>Missing arc</p>",
    "order_id": 0
  },
  {
    "tool": {},
    "order_number": 2,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4fae8359-4f3a-45c7-9ddd-2383605027ea%2Fa999c199-0233-4da8-9cf1-94aac635f550?alt=media&token=bd3f7877-af9f-4ec1-94c0-f62e702f80aa",
        "reference": "/stroke_photos/4fae8359-4f3a-45c7-9ddd-2383605027ea/a999c199-0233-4da8-9cf1-94aac635f550"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4fae8359-4f3a-45c7-9ddd-2383605027ea%2F600x600%2Fa999c199-0233-4da8-9cf1-94aac635f550_600x600?alt=media&token=62199b24-a0a2-4f76-aa7d-4e53fb1858b7",
        "reference": "stroke_photos/4fae8359-4f3a-45c7-9ddd-2383605027ea/600x600/a999c199-0233-4da8-9cf1-94aac635f550_600x600"
      }
    },
    "purchase": {
      "date": {
        "seconds": 1677878062,
        "nanoseconds": 97000000
      },
      "status": "bought",
      "buyer": {
        "email": "Simona.majerska@gmail.com",
        "firstName": "Simona",
        "lastName": "Majerska"
      },
      "anonymous": false,
      "internalDescription": ""
    },
    "name": "One More First Attempt",
    "description": "dd",
    "paper": {
      "ref": "44202129",
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "name": "",
      "paper_surface": {},
      "price": null,
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "gsm": "200",
      "original_size": "A4",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      }
    },
    "id": "4fae8359-4f3a-45c7-9ddd-2383605027ea"
  },
  {
    "tool": null,
    "name": "Blank",
    "description": "",
    "order_number": 758,
    "photos": {
      "original": {
        "reference": "/stroke_photos/4fd811a0-70b3-4826-a621-83aa840bd3e8/815463c6-7267-4189-9711-8155d3574fe6",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4fd811a0-70b3-4826-a621-83aa840bd3e8%2F815463c6-7267-4189-9711-8155d3574fe6?alt=media&token=c8df3376-4b4d-4bb8-af30-3dc8ddc2646b"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/4fd811a0-70b3-4826-a621-83aa840bd3e8/600x600/815463c6-7267-4189-9711-8155d3574fe6_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4fd811a0-70b3-4826-a621-83aa840bd3e8%2F600x600%2F815463c6-7267-4189-9711-8155d3574fe6_600x600?alt=media&token=c63d4df0-403b-453e-9c35-041735c5c0d5"
      }
    },
    "order_id": 0,
    "paper": {
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "gsm": "250",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "name": null,
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "price": 1.35,
      "ref": "77725080001"
    },
    "id": "4fd811a0-70b3-4826-a621-83aa840bd3e8"
  },
  {
    "order_id": 0,
    "paper": {
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "A7",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "ref": "77725080001",
      "name": null,
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "price": 1.35
    },
    "order_number": 149,
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/4fe63f53-7c0a-4f71-a241-15d5d9207525/600x600/9a1d78b8-a3b2-4b84-b682-d473eed9188a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4fe63f53-7c0a-4f71-a241-15d5d9207525%2F600x600%2F9a1d78b8-a3b2-4b84-b682-d473eed9188a_600x600?alt=media&token=e041fc55-5b7e-4f8f-8035-e49945a25841"
      },
      "original": {
        "reference": "/stroke_photos/4fe63f53-7c0a-4f71-a241-15d5d9207525/9a1d78b8-a3b2-4b84-b682-d473eed9188a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4fe63f53-7c0a-4f71-a241-15d5d9207525%2F9a1d78b8-a3b2-4b84-b682-d473eed9188a?alt=media&token=549eabee-fb6a-4703-bbcf-5b0827f51d12"
      }
    },
    "name": "Blank",
    "id": "4fe63f53-7c0a-4f71-a241-15d5d9207525",
    "description": ""
  },
  {
    "paper": {
      "ref": null,
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": null,
      "original_size": "A4",
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b"
    },
    "name": "Blank",
    "order_id": 0,
    "order_number": 955,
    "id": "4fefc151-8575-482b-9401-1db1a351fa51",
    "description": ""
  },
  {
    "id": "503546c4-975a-4d49-b3e6-27efceadb6f8",
    "order_id": 0,
    "name": "Blank",
    "order_number": 944,
    "description": "",
    "paper": {
      "price": null,
      "gsm": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "ref": null,
      "brand_model": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "original_size": "A4"
    }
  },
  {
    "order_number": 70,
    "paper": {
      "price": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A4",
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "name": "",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "ref": "44202129",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "gsm": "200",
      "paper_surface": {}
    },
    "tool": null,
    "name": "S Really Flat",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F50a7c53b-5d34-4e48-80f8-0959412be610%2Ff1a1817d-ac35-4925-bd4c-93b38756d03b?alt=media&token=2af39860-0fb2-46f3-9617-c5af75597ab6",
        "reference": "/stroke_photos/50a7c53b-5d34-4e48-80f8-0959412be610/f1a1817d-ac35-4925-bd4c-93b38756d03b"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F50a7c53b-5d34-4e48-80f8-0959412be610%2F600x600%2Ff1a1817d-ac35-4925-bd4c-93b38756d03b_600x600?alt=media&token=bd10f5de-dd41-4d11-a0dc-40551313a4a7",
        "reference": "stroke_photos/50a7c53b-5d34-4e48-80f8-0959412be610/600x600/f1a1817d-ac35-4925-bd4c-93b38756d03b_600x600"
      }
    },
    "description": "",
    "id": "50a7c53b-5d34-4e48-80f8-0959412be610",
    "order_id": 0
  },
  {
    "order_id": 0,
    "tool": null,
    "order_number": 168,
    "description": "",
    "paper": {
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "gsm": "250",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "original_size": "A7",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "ref": "77725080001",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": 1.35
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/50af160b-e691-4b24-ba02-e9664bda3520/f1c3d3ad-cecb-4fff-8c76-16a491049141",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F50af160b-e691-4b24-ba02-e9664bda3520%2Ff1c3d3ad-cecb-4fff-8c76-16a491049141?alt=media&token=9ee701e7-3f72-46fa-b6a3-122273a376ac"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F50af160b-e691-4b24-ba02-e9664bda3520%2F600x600%2Ff1c3d3ad-cecb-4fff-8c76-16a491049141_600x600?alt=media&token=95295d2e-81a6-4605-b11c-4c4bc4442f52",
        "reference": "stroke_photos/50af160b-e691-4b24-ba02-e9664bda3520/600x600/f1c3d3ad-cecb-4fff-8c76-16a491049141_600x600"
      }
    },
    "name": "Blank",
    "id": "50af160b-e691-4b24-ba02-e9664bda3520"
  },
  {
    "order_number": 1027,
    "description": "",
    "name": "",
    "id": "50bdad46-622d-44d0-b756-6a4ca1c34ca1"
  },
  {
    "order_number": 141,
    "name": "Sideways Braid",
    "id": "50c1a93d-cc2d-4ecd-bcc9-97e2095e904e",
    "description": "",
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "price": null,
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "ref": "20180107",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {}
    },
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/50c1a93d-cc2d-4ecd-bcc9-97e2095e904e/944ef904-6c05-4207-83b9-89a7b7f8a273",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F50c1a93d-cc2d-4ecd-bcc9-97e2095e904e%2F944ef904-6c05-4207-83b9-89a7b7f8a273?alt=media&token=5a2e8109-b1a8-4641-a280-2802fbf29d9d"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F50c1a93d-cc2d-4ecd-bcc9-97e2095e904e%2F600x600%2F944ef904-6c05-4207-83b9-89a7b7f8a273_600x600?alt=media&token=ef0fe2bf-fc73-4da8-9857-ec00c2767ada",
        "reference": "stroke_photos/50c1a93d-cc2d-4ecd-bcc9-97e2095e904e/600x600/944ef904-6c05-4207-83b9-89a7b7f8a273_600x600"
      }
    },
    "tool": null
  },
  {
    "description": "",
    "order_id": 0,
    "paper": {
      "price": 1.35,
      "original_size": "A7",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "ref": "77725080001",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso"
      },
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    },
    "id": "50ccab44-3f4e-468c-8177-c2efbd45561a",
    "order_number": 786,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F50ccab44-3f4e-468c-8177-c2efbd45561a%2F7eacba35-67bf-4d47-8460-b7b2d7234692?alt=media&token=3a8b82d0-d598-4e1a-90c7-02539c6a690f",
        "reference": "/stroke_photos/50ccab44-3f4e-468c-8177-c2efbd45561a/7eacba35-67bf-4d47-8460-b7b2d7234692"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/50ccab44-3f4e-468c-8177-c2efbd45561a/600x600/7eacba35-67bf-4d47-8460-b7b2d7234692_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F50ccab44-3f4e-468c-8177-c2efbd45561a%2F600x600%2F7eacba35-67bf-4d47-8460-b7b2d7234692_600x600?alt=media&token=2751fed6-39e8-4f94-864a-c65eb02ff89c"
      }
    },
    "name": "Blank",
    "tool": null
  },
  {
    "tool": null,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/50d276f9-8580-438c-9154-38fa9fd739b3/600x600/488d5591-5b89-4196-ac63-85142383cd32_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F50d276f9-8580-438c-9154-38fa9fd739b3%2F600x600%2F488d5591-5b89-4196-ac63-85142383cd32_600x600?alt=media&token=865c84bd-834a-4556-94f4-0fdf29bb199f"
      },
      "original": {
        "reference": "/stroke_photos/50d276f9-8580-438c-9154-38fa9fd739b3/488d5591-5b89-4196-ac63-85142383cd32",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F50d276f9-8580-438c-9154-38fa9fd739b3%2F488d5591-5b89-4196-ac63-85142383cd32?alt=media&token=e8026542-7887-4d41-8c12-860525b582e3"
      }
    },
    "order_id": 0,
    "id": "50d276f9-8580-438c-9154-38fa9fd739b3",
    "order_number": 621,
    "name": "Blank",
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "brand": {},
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "70025010030",
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "price": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand_model": {},
      "gsm": "250"
    }
  },
  {
    "description": "",
    "order_number": 1129,
    "name": "",
    "id": "51107ff9-0e30-4e80-89df-22159181295c"
  },
  {
    "order_number": 1216,
    "description": "",
    "name": "",
    "id": "51306244-df0b-4ebf-8302-9b4db66ca001"
  },
  {
    "name": "Blank",
    "id": "513ae240-c1a3-4bfa-8a6b-0aa4491938c4",
    "order_id": 0,
    "description": "",
    "order_number": 891,
    "paper": {
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "original_size": "A4",
      "ref": "4005329065767",
      "brand_model": {
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        }
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": 1,
      "gsm": "110"
    }
  },
  {
    "paper": {
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      },
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "name": "Elephant Skin"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "110",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "price": 1,
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "name": null,
      "ref": "4005329065767",
      "original_size": "A4",
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701"
    },
    "description": "",
    "order_id": 0,
    "name": "Blank",
    "order_number": 893,
    "id": "5143a2e4-a12a-436a-ad51-fcb52a9b3f04"
  },
  {
    "order_number": 937,
    "id": "5155eb94-5eec-4924-91b1-a882f39272cc",
    "description": "",
    "name": "Blank",
    "order_id": 0,
    "paper": {
      "ref": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "brand_model": {},
      "gsm": null,
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "original_size": "A4",
      "brand": {}
    }
  },
  {
    "order_id": 0,
    "name": "Blank",
    "id": "517be40e-84d3-48f0-a7ae-e1b70d21d5f2",
    "photos": {
      "original": {
        "reference": "/stroke_photos/517be40e-84d3-48f0-a7ae-e1b70d21d5f2/2b0241b0-19af-4cb1-91ed-7e6c96af2c73",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F517be40e-84d3-48f0-a7ae-e1b70d21d5f2%2F2b0241b0-19af-4cb1-91ed-7e6c96af2c73?alt=media&token=d804ac87-6308-44e8-af50-d35c08199645"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F517be40e-84d3-48f0-a7ae-e1b70d21d5f2%2F600x600%2F2b0241b0-19af-4cb1-91ed-7e6c96af2c73_600x600?alt=media&token=054011c3-934f-4c58-af26-e1faeb4f3752",
        "reference": "stroke_photos/517be40e-84d3-48f0-a7ae-e1b70d21d5f2/600x600/2b0241b0-19af-4cb1-91ed-7e6c96af2c73_600x600"
      }
    },
    "order_number": 782,
    "paper": {
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "ref": "77725080001",
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "price": 1.35,
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "name": null,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      }
    },
    "description": "",
    "tool": null
  },
  {
    "order_number": 743,
    "description": "",
    "tool": null,
    "name": "Blank",
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F51c7c95d-97e7-4305-a61b-2a977054a97a%2F53eb6f73-2895-4d3d-8f2e-a657e86abc08?alt=media&token=6041d8ab-0c7d-4d6b-a0bb-0011fa7ee379",
        "reference": "/stroke_photos/51c7c95d-97e7-4305-a61b-2a977054a97a/53eb6f73-2895-4d3d-8f2e-a657e86abc08"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F51c7c95d-97e7-4305-a61b-2a977054a97a%2F600x600%2F53eb6f73-2895-4d3d-8f2e-a657e86abc08_600x600?alt=media&token=19feec22-c706-40af-8e2b-20a293ad60ff",
        "reference": "stroke_photos/51c7c95d-97e7-4305-a61b-2a977054a97a/600x600/53eb6f73-2895-4d3d-8f2e-a657e86abc08_600x600"
      }
    },
    "id": "51c7c95d-97e7-4305-a61b-2a977054a97a",
    "paper": {
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "original_size": "A7",
      "price": 0.4,
      "brand": {},
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250",
      "ref": "20170703",
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    }
  },
  {
    "paper": {
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "price": 1,
      "ref": "4005329065767",
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "brand_model": {
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        }
      },
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "original_size": "A4",
      "gsm": "110",
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      }
    },
    "order_id": 0,
    "description": "",
    "id": "51d05f97-900a-4cee-9eb4-86de19ed9540",
    "order_number": 871,
    "name": "Blank"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/5257922d-448d-4d55-9e78-6ec08d49aa9a/c76e6c1a-3752-48c9-a8a8-788fc64f8ea2",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5257922d-448d-4d55-9e78-6ec08d49aa9a%2Fc76e6c1a-3752-48c9-a8a8-788fc64f8ea2?alt=media&token=0e9af530-ff77-438a-aa13-2cd1c4c726d2"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/5257922d-448d-4d55-9e78-6ec08d49aa9a/600x600/c76e6c1a-3752-48c9-a8a8-788fc64f8ea2_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5257922d-448d-4d55-9e78-6ec08d49aa9a%2F600x600%2Fc76e6c1a-3752-48c9-a8a8-788fc64f8ea2_600x600?alt=media&token=b9cb39b2-37ce-469e-92a6-ed443f00fbb5"
      }
    },
    "tool": null,
    "name": "Broken Tail",
    "order_id": 0,
    "paper": {
      "brand_model": {
        "name": "Accademia Designo",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "price": null,
      "ref": "44202129",
      "paper_surface": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "name": "",
      "gsm": "200",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "original_size": "A4"
    },
    "description": "",
    "id": "5257922d-448d-4d55-9e78-6ec08d49aa9a",
    "order_number": 80
  },
  {
    "order_number": 542,
    "name": "Silver-On-Black Heartbeat 2",
    "id": "52a31169-a57b-471e-9fb8-9bf2a8f9eae1",
    "tool": null,
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {},
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "ref": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "brand": {},
      "price": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "original_size": "A4",
      "gsm": null
    },
    "order_id": 0,
    "description": "<p>This looks really nice</p>",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F52a31169-a57b-471e-9fb8-9bf2a8f9eae1%2F76914126-a642-4c95-a0bb-c7292f1acd78?alt=media&token=c517445e-92e7-459c-bf90-0905f6dd2f5b",
        "reference": "/stroke_photos/52a31169-a57b-471e-9fb8-9bf2a8f9eae1/76914126-a642-4c95-a0bb-c7292f1acd78"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/52a31169-a57b-471e-9fb8-9bf2a8f9eae1/600x600/76914126-a642-4c95-a0bb-c7292f1acd78_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F52a31169-a57b-471e-9fb8-9bf2a8f9eae1%2F600x600%2F76914126-a642-4c95-a0bb-c7292f1acd78_600x600?alt=media&token=8e762eb7-f317-4d81-ba21-0e03587295f3"
      }
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1020,
    "id": "52e9eec4-1636-44e2-b103-f10976c0e6ae"
  },
  {
    "name": "Penis",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F52ee7b9b-bd31-4442-b9c2-ac5672801143%2F600x600%2F5b67800d-39d8-4cc4-8d42-df2ab7fb73d9_600x600?alt=media&token=ceb929ec-066c-4d20-8523-4ee40a58f09a",
        "reference": "stroke_photos/52ee7b9b-bd31-4442-b9c2-ac5672801143/600x600/5b67800d-39d8-4cc4-8d42-df2ab7fb73d9_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F52ee7b9b-bd31-4442-b9c2-ac5672801143%2F5b67800d-39d8-4cc4-8d42-df2ab7fb73d9?alt=media&token=3de654f9-a57e-4b03-bf9b-357193797941",
        "reference": "/stroke_photos/52ee7b9b-bd31-4442-b9c2-ac5672801143/5b67800d-39d8-4cc4-8d42-df2ab7fb73d9"
      }
    },
    "paper": {
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "original_size": "A7",
      "gsm": "250",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "price": 1.35,
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "77725080001"
    },
    "id": "52ee7b9b-bd31-4442-b9c2-ac5672801143",
    "order_number": 418,
    "tool": null,
    "description": "No other way to describe it",
    "order_id": 0
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F52fa6a51-dc7f-4100-8e65-6bdc9053c3e7%2F600x600%2F6e2d8487-b98e-4a25-9c03-f1c0a8c64eac_600x600?alt=media&token=6cbd290b-5f52-43be-a9c9-eb7da00b78f9",
        "reference": "stroke_photos/52fa6a51-dc7f-4100-8e65-6bdc9053c3e7/600x600/6e2d8487-b98e-4a25-9c03-f1c0a8c64eac_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F52fa6a51-dc7f-4100-8e65-6bdc9053c3e7%2F6e2d8487-b98e-4a25-9c03-f1c0a8c64eac?alt=media&token=b8169ea5-a996-4ccc-b8d2-c23958999a41",
        "reference": "/stroke_photos/52fa6a51-dc7f-4100-8e65-6bdc9053c3e7/6e2d8487-b98e-4a25-9c03-f1c0a8c64eac"
      }
    },
    "name": "Fake Stick 5",
    "tool": null,
    "description": "Again, bad implementation.",
    "order_id": 0,
    "order_number": 262,
    "id": "52fa6a51-dc7f-4100-8e65-6bdc9053c3e7",
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "20180107",
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "gsm": "250",
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "price": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    }
  },
  {
    "order_number": 1068,
    "description": "",
    "name": "",
    "id": "537708ae-737a-4ea3-bae0-bb4355f450fe"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1035,
    "id": "53d3bc18-9957-4656-aaa6-af2081b338b8"
  },
  {
    "order_number": 1151,
    "description": "",
    "name": "",
    "id": "5435f693-b8e3-4a2c-bf88-c32875d392c0"
  },
  {
    "order_number": 1152,
    "description": "",
    "name": "",
    "id": "544bf9b1-9238-4c78-8c42-73187cef6ddc"
  },
  {
    "id": "54605c1b-5df9-4ad2-bec9-f05f2f179aae",
    "order_number": 678,
    "order_id": 0,
    "tool": null,
    "description": "",
    "paper": {
      "original_size": "75x105cm",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Artistico"
      },
      "price": 19.3,
      "ref": "30075105",
      "gsm": "640",
      "name": "tinto",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163"
    },
    "name": "Blank",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F54605c1b-5df9-4ad2-bec9-f05f2f179aae%2F9baffe15-f5e0-4e8e-8035-0c2c8f329286?alt=media&token=f2b4129b-93c2-4d02-91a3-6458b5c40ff2",
        "reference": "/stroke_photos/54605c1b-5df9-4ad2-bec9-f05f2f179aae/9baffe15-f5e0-4e8e-8035-0c2c8f329286"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/54605c1b-5df9-4ad2-bec9-f05f2f179aae/600x600/9baffe15-f5e0-4e8e-8035-0c2c8f329286_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F54605c1b-5df9-4ad2-bec9-f05f2f179aae%2F600x600%2F9baffe15-f5e0-4e8e-8035-0c2c8f329286_600x600?alt=media&token=2a41b9e4-24a5-4ddd-ae19-cd01cf9f6f36"
      }
    }
  },
  {
    "order_number": 60,
    "paper": {
      "ref": "44202129",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {},
      "name": "",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "price": null,
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "original_size": "A4",
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo"
      },
      "gsm": "200"
    },
    "id": "547f694b-8834-4ff3-b4cf-72999318e344",
    "name": "S Bigger",
    "tool": null,
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/547f694b-8834-4ff3-b4cf-72999318e344/65957899-a92d-4d45-aeac-dc32ec9045b8",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F547f694b-8834-4ff3-b4cf-72999318e344%2F65957899-a92d-4d45-aeac-dc32ec9045b8?alt=media&token=d177f5b0-ea3d-4ae7-9709-085483aa7ff4"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/547f694b-8834-4ff3-b4cf-72999318e344/600x600/65957899-a92d-4d45-aeac-dc32ec9045b8_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F547f694b-8834-4ff3-b4cf-72999318e344%2F600x600%2F65957899-a92d-4d45-aeac-dc32ec9045b8_600x600?alt=media&token=0248549b-9fd4-496a-bbeb-88b478f60585"
      }
    },
    "description": ""
  },
  {
    "description": "",
    "order_number": 1116,
    "name": "",
    "id": "54a23b3f-ea6d-49ef-abe5-351aa3fe1443"
  },
  {
    "description": "",
    "order_number": 1110,
    "name": "",
    "id": "54a6c9b2-42ea-4a85-9d83-a3d87c2e0792"
  },
  {
    "name": "",
    "order_number": 1153,
    "description": "",
    "id": "54e0d886-176d-43cc-98b0-a12a09f2c5d5"
  },
  {
    "id": "54eca975-172d-451d-bc97-ec38bef22dd7",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F54eca975-172d-451d-bc97-ec38bef22dd7%2Fe27be154-1315-42a4-ba7d-4319585da663?alt=media&token=99857478-f4ae-4750-9e78-a80fc13c6666",
        "reference": "/stroke_photos/54eca975-172d-451d-bc97-ec38bef22dd7/e27be154-1315-42a4-ba7d-4319585da663"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/54eca975-172d-451d-bc97-ec38bef22dd7/600x600/e27be154-1315-42a4-ba7d-4319585da663_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F54eca975-172d-451d-bc97-ec38bef22dd7%2F600x600%2Fe27be154-1315-42a4-ba7d-4319585da663_600x600?alt=media&token=4e33976b-2748-4a39-bbd8-6d49eea6a62d"
      }
    },
    "name": "Blank",
    "order_number": 584,
    "order_id": 0,
    "description": "",
    "tool": null,
    "paper": {
      "brand": {},
      "name": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250",
      "original_size": "A7",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "70025010030"
    }
  },
  {
    "name": "",
    "order_number": 1065,
    "description": "",
    "id": "5513d9f4-2797-4fa4-a56d-443c9b358de2"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1047,
    "id": "55679f85-d2ad-49ba-9c02-44c6ca6b6175"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1117,
    "id": "55a999b9-a083-40c8-8753-09be09c570dc"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1048,
    "id": "55abf350-223e-4fc3-89fd-00c23eb53dad"
  },
  {
    "order_id": 0,
    "id": "55dcc44e-7bbf-449f-ace7-944479c6b3b3",
    "paper": {
      "ref": "44202129",
      "original_size": "A4",
      "price": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "name": "",
      "paper_surface": {},
      "gsm": "200",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "purchase": {
      "status": "bought",
      "internalDescription": "",
      "date": {
        "seconds": 1677878346,
        "nanoseconds": 548000000
      },
      "buyer": {
        "lastName": "Statev",
        "email": "boris@statev.com",
        "firstName": "Boris"
      },
      "anonymous": false
    },
    "name": "SWOB Pause at End 2",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F55dcc44e-7bbf-449f-ace7-944479c6b3b3%2Fec5e43e3-1da3-4d9a-9ac7-7a8f6769ee88?alt=media&token=d55088c4-b0a5-4028-bb6b-185ce20693bc",
        "reference": "/stroke_photos/55dcc44e-7bbf-449f-ace7-944479c6b3b3/ec5e43e3-1da3-4d9a-9ac7-7a8f6769ee88"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/55dcc44e-7bbf-449f-ace7-944479c6b3b3/600x600/ec5e43e3-1da3-4d9a-9ac7-7a8f6769ee88_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F55dcc44e-7bbf-449f-ace7-944479c6b3b3%2F600x600%2Fec5e43e3-1da3-4d9a-9ac7-7a8f6769ee88_600x600?alt=media&token=0b7a666c-9dcb-4320-aeb2-8f613a084516"
      }
    },
    "description": "",
    "tool": null,
    "order_number": 74
  },
  {
    "paper": {
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "brand": {},
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {},
      "gsm": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4"
    },
    "description": "",
    "order_id": 0,
    "order_number": 951,
    "id": "55fa4bb5-885e-4263-9116-fbcb4bfb6aa9",
    "name": "Blank"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/560fa95f-391a-4a7b-b7e1-4c6c65bf13c7/b33aa28e-44bb-4426-8505-b56fe30e6003",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F560fa95f-391a-4a7b-b7e1-4c6c65bf13c7%2Fb33aa28e-44bb-4426-8505-b56fe30e6003?alt=media&token=283ac55d-5e07-44cd-828b-4e21e163cbb8"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F560fa95f-391a-4a7b-b7e1-4c6c65bf13c7%2F600x600%2Fb33aa28e-44bb-4426-8505-b56fe30e6003_600x600?alt=media&token=4f7a3213-cbf7-4778-90eb-3334e25aac03",
        "reference": "stroke_photos/560fa95f-391a-4a7b-b7e1-4c6c65bf13c7/600x600/b33aa28e-44bb-4426-8505-b56fe30e6003_600x600"
      }
    },
    "purchase": {
      "anonymous": false,
      "status": "bought",
      "internalDescription": "",
      "buyer": {
        "lastName": "Dimitrov",
        "email": "dilyan.dimitrov@gmail.com",
        "firstName": "Dilyan"
      },
      "date": {
        "seconds": 1677877994,
        "nanoseconds": 529000000
      }
    },
    "name": "Black Test For Diffusion",
    "order_number": 3,
    "paper": {
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "paper_surface": {},
      "gsm": "200",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "original_size": "A4",
      "ref": "44202129",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": "",
      "price": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    },
    "tool": {},
    "description": "dd",
    "id": "560fa95f-391a-4a7b-b7e1-4c6c65bf13c7"
  },
  {
    "description": "<p>Very eneven, tons of point on in the beginning and by end looks like ... nothing.</p>",
    "order_id": 0,
    "order_number": 480,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/561b525a-4f3a-4523-b69c-ba023985fe53/600x600/79fcde6f-d7ac-4144-9ae6-4ae378106901_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F561b525a-4f3a-4523-b69c-ba023985fe53%2F600x600%2F79fcde6f-d7ac-4144-9ae6-4ae378106901_600x600?alt=media&token=70516c32-01d5-431a-93cb-d5ab3809d9fe"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F561b525a-4f3a-4523-b69c-ba023985fe53%2F79fcde6f-d7ac-4144-9ae6-4ae378106901?alt=media&token=6aa61642-366f-48ce-8422-3a1aae4a95b0",
        "reference": "/stroke_photos/561b525a-4f3a-4523-b69c-ba023985fe53/79fcde6f-d7ac-4144-9ae6-4ae378106901"
      }
    },
    "paper": {
      "brand": {},
      "original_size": "A4",
      "gsm": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "ref": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {},
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "name": "White Zig-Zag",
    "tool": null,
    "id": "561b525a-4f3a-4523-b69c-ba023985fe53"
  },
  {
    "name": "Blank",
    "paper": {
      "ref": "77725080001",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "name": null,
      "original_size": "A7",
      "price": 1.35,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      }
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5625bbcd-7067-4d9a-b36d-c259f9ee13df%2F88b288e2-2848-419a-9195-0fbbd47d36bc?alt=media&token=97b024ca-bff8-4300-8923-0c1bb719e60c",
        "reference": "/stroke_photos/5625bbcd-7067-4d9a-b36d-c259f9ee13df/88b288e2-2848-419a-9195-0fbbd47d36bc"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/5625bbcd-7067-4d9a-b36d-c259f9ee13df/600x600/88b288e2-2848-419a-9195-0fbbd47d36bc_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5625bbcd-7067-4d9a-b36d-c259f9ee13df%2F600x600%2F88b288e2-2848-419a-9195-0fbbd47d36bc_600x600?alt=media&token=ce5fc5b0-955f-4a94-a0dd-054d40ffb2f8"
      }
    },
    "id": "5625bbcd-7067-4d9a-b36d-c259f9ee13df",
    "order_id": 0,
    "description": "",
    "order_number": 783,
    "tool": null
  },
  {
    "paper": {
      "name": "",
      "gsm": "200",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "paper_surface": {},
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A4",
      "ref": "44202129"
    },
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5668aa18-dfd3-436e-8803-2f9670de7222%2F9ff29a17-f562-4698-8e20-ef1fbdd0ff8f?alt=media&token=a7697f9c-6bf5-4c85-bfa3-cac5e0018f44",
        "reference": "/stroke_photos/5668aa18-dfd3-436e-8803-2f9670de7222/9ff29a17-f562-4698-8e20-ef1fbdd0ff8f"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5668aa18-dfd3-436e-8803-2f9670de7222%2F600x600%2F9ff29a17-f562-4698-8e20-ef1fbdd0ff8f_600x600?alt=media&token=5debf4b5-8acf-4332-9dee-e46923db058f",
        "reference": "stroke_photos/5668aa18-dfd3-436e-8803-2f9670de7222/600x600/9ff29a17-f562-4698-8e20-ef1fbdd0ff8f_600x600"
      }
    },
    "id": "5668aa18-dfd3-436e-8803-2f9670de7222",
    "order_number": 31,
    "description": "",
    "tool": {},
    "name": "Fast Stroke Tons Of Paint"
  },
  {
    "id": "569f809a-800f-4239-bb0c-a1ade2b84b38",
    "description": "",
    "order_number": 826,
    "paper": {
      "ref": "70025010030",
      "brand": {},
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand_model": {},
      "name": null,
      "gsm": "250",
      "original_size": "A7",
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl",
        "hex": "#f5f5f5"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "name": "Blank",
    "order_id": 0
  },
  {
    "name": "",
    "order_number": 1122,
    "description": "",
    "id": "56ad700c-4a65-4ebf-8e18-71044d86891b"
  },
  {
    "name": "Masked Insecurity",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/56c05f11-e680-406e-b2f8-854f5de6d845/600x600/dd878614-0e9e-41ed-8871-e83eb4264a44_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F56c05f11-e680-406e-b2f8-854f5de6d845%2F600x600%2Fdd878614-0e9e-41ed-8871-e83eb4264a44_600x600?alt=media&token=6309c042-a8b6-4d3f-b0d2-3235c1fc36cf"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F56c05f11-e680-406e-b2f8-854f5de6d845%2Fdd878614-0e9e-41ed-8871-e83eb4264a44?alt=media&token=d6c8bada-2985-4ef2-b2e4-922c0a92d3b6",
        "reference": "/stroke_photos/56c05f11-e680-406e-b2f8-854f5de6d845/dd878614-0e9e-41ed-8871-e83eb4264a44"
      }
    },
    "description": "<p>Okay, Just a bit better! Paint and pressure is off, but I am beginning to accuse the paintbrush, which I will remind is lipstick brush</p>",
    "id": "56c05f11-e680-406e-b2f8-854f5de6d845",
    "order_number": 324,
    "paper": {
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "20180107",
      "price": null,
      "brand": {},
      "name": null,
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "original_size": "A7",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86"
    },
    "order_id": 0
  },
  {
    "description": "<p>Hm, didn't expect just that by putting more paint and making short hops, but I like it</p>",
    "id": "56c3a1e4-9ce6-4dd4-b8b4-4e280ef4c797",
    "paper": {
      "ref": "20180107",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "brand": {},
      "price": null,
      "original_size": "A7",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86"
    },
    "order_id": 0,
    "name": "Rich Silver Variable",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F56c3a1e4-9ce6-4dd4-b8b4-4e280ef4c797%2F600x600%2F9f4e0ff0-12c4-4b6a-a88c-cfb09b6379cf_600x600?alt=media&token=cfc47650-2810-4a58-925b-1d9391c6219c",
        "reference": "stroke_photos/56c3a1e4-9ce6-4dd4-b8b4-4e280ef4c797/600x600/9f4e0ff0-12c4-4b6a-a88c-cfb09b6379cf_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F56c3a1e4-9ce6-4dd4-b8b4-4e280ef4c797%2F9f4e0ff0-12c4-4b6a-a88c-cfb09b6379cf?alt=media&token=3117b920-c367-4a98-b216-8799a0f415ee",
        "reference": "/stroke_photos/56c3a1e4-9ce6-4dd4-b8b4-4e280ef4c797/9f4e0ff0-12c4-4b6a-a88c-cfb09b6379cf"
      }
    },
    "tool": null,
    "order_number": 360
  },
  {
    "order_number": 1156,
    "name": "",
    "description": "",
    "id": "5736ba19-0430-425d-943f-850df459d847"
  },
  {
    "name": "",
    "order_number": 1107,
    "description": "",
    "id": "575565a1-6bf1-46ed-a798-7ff4bc30a55e"
  },
  {
    "tool": null,
    "description": "<p>I came upon a lipstick brush by Avon. Why not, let's give it a try and see how a delicate stroke looks like</p>",
    "photos": {
      "original": {
        "reference": "/stroke_photos/575b94ab-1da4-45e5-bd5f-f6bc6a8f2679/06a0cb19-cc5f-4e39-92bc-2b2c84705465",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F575b94ab-1da4-45e5-bd5f-f6bc6a8f2679%2F06a0cb19-cc5f-4e39-92bc-2b2c84705465?alt=media&token=3b690b28-017d-4a48-aa07-f1c39ea9443f"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/575b94ab-1da4-45e5-bd5f-f6bc6a8f2679/600x600/06a0cb19-cc5f-4e39-92bc-2b2c84705465_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F575b94ab-1da4-45e5-bd5f-f6bc6a8f2679%2F600x600%2F06a0cb19-cc5f-4e39-92bc-2b2c84705465_600x600?alt=media&token=7a918524-aacc-4805-a3c8-1e74055f4126"
      }
    },
    "order_number": 297,
    "name": "Skinny",
    "order_id": 0,
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "price": null,
      "ref": "20180107",
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "id": "575b94ab-1da4-45e5-bd5f-f6bc6a8f2679"
  },
  {
    "description": "",
    "order_number": 1086,
    "name": "",
    "id": "57674895-3d75-44c0-93b1-6fe81641636d"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1087,
    "id": "5797f3bc-ee59-46ae-a851-fefe8a0b3ef5"
  },
  {
    "name": "",
    "order_number": 1036,
    "description": "",
    "id": "57c1cc39-ee7f-449a-a252-1654a7c260db"
  },
  {
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "brand": {},
      "ref": "20180107",
      "original_size": "A7",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "name": null,
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    },
    "id": "57ddddd9-a913-42cd-8d24-cb7f657f5ed2",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F57ddddd9-a913-42cd-8d24-cb7f657f5ed2%2F600x600%2F5a695f68-23ad-445a-900a-418d0c13cef9_600x600?alt=media&token=f23182b4-b9fc-446c-853d-bd37bc5cbb4a",
        "reference": "stroke_photos/57ddddd9-a913-42cd-8d24-cb7f657f5ed2/600x600/5a695f68-23ad-445a-900a-418d0c13cef9_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F57ddddd9-a913-42cd-8d24-cb7f657f5ed2%2F5a695f68-23ad-445a-900a-418d0c13cef9?alt=media&token=468046e9-c217-4f02-b786-cb06fc6070c1",
        "reference": "/stroke_photos/57ddddd9-a913-42cd-8d24-cb7f657f5ed2/5a695f68-23ad-445a-900a-418d0c13cef9"
      }
    },
    "order_id": 0,
    "tool": null,
    "description": "So when paint dries up a little, it makes the hairs of the brush stick together to make that corridor...",
    "name": "Paint Dried Up",
    "order_number": 249
  },
  {
    "id": "57df667a-0c01-4b9a-81ea-4bb321375d95",
    "order_id": 0,
    "order_number": 831,
    "name": "Blank",
    "description": "",
    "paper": {
      "ref": "70025010030",
      "price": null,
      "gsm": "250",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "name": null,
      "brand_model": {},
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    }
  },
  {
    "order_number": 1190,
    "description": "",
    "name": "",
    "id": "57e80f97-e070-45c8-86e2-afc74ef7a150"
  },
  {
    "order_number": 563,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/58030525-289b-4009-a45a-e6370b84d5d5/600x600/3bab2aa3-af4f-4e73-b992-1ef056d6de0a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F58030525-289b-4009-a45a-e6370b84d5d5%2F600x600%2F3bab2aa3-af4f-4e73-b992-1ef056d6de0a_600x600?alt=media&token=e28be695-dbd4-4bd0-9f5f-06f329a22445"
      },
      "original": {
        "reference": "/stroke_photos/58030525-289b-4009-a45a-e6370b84d5d5/3bab2aa3-af4f-4e73-b992-1ef056d6de0a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F58030525-289b-4009-a45a-e6370b84d5d5%2F3bab2aa3-af4f-4e73-b992-1ef056d6de0a?alt=media&token=12a4cba3-0d28-4475-83ce-956358569878"
      }
    },
    "description": "",
    "tool": null,
    "name": "Blank",
    "order_id": 0,
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "name": null,
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20170703",
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "price": 0.4
    },
    "id": "58030525-289b-4009-a45a-e6370b84d5d5"
  },
  {
    "order_number": 1213,
    "name": "",
    "description": "",
    "id": "586301f7-3386-4524-ba2a-ebf49bf31e1e"
  },
  {
    "paper": {
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": "70025010030",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "gsm": "250",
      "original_size": "A7",
      "brand_model": {},
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "order_id": 0,
    "order_number": 830,
    "description": "",
    "name": "Blank",
    "id": "58fa1b49-10b5-4e07-a487-c05b5aac85bc"
  },
  {
    "paper": {
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "ref": "20180107",
      "original_size": "A7",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "name": null,
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "gsm": "250",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "tool": null,
    "description": "<p>Maybe if I make a small circle, I can make it better. Indeed looks better somehow. Let's try one more...</p>",
    "name": "Circliness",
    "id": "59147a09-fcfd-41cf-acfe-3837f091a77d",
    "order_id": 0,
    "order_number": 339,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F59147a09-fcfd-41cf-acfe-3837f091a77d%2F600x600%2F766f0cac-d847-4612-8e61-c5cba95f9f6d_600x600?alt=media&token=91abe8bf-d925-4106-b926-7756c1b398f6",
        "reference": "stroke_photos/59147a09-fcfd-41cf-acfe-3837f091a77d/600x600/766f0cac-d847-4612-8e61-c5cba95f9f6d_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F59147a09-fcfd-41cf-acfe-3837f091a77d%2F766f0cac-d847-4612-8e61-c5cba95f9f6d?alt=media&token=8cde54e8-e17b-4d00-a5f3-f055100ea6b0",
        "reference": "/stroke_photos/59147a09-fcfd-41cf-acfe-3837f091a77d/766f0cac-d847-4612-8e61-c5cba95f9f6d"
      }
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1061,
    "id": "59514091-0076-4aed-97d2-e2bb9997edbf"
  },
  {
    "order_number": 1188,
    "name": "",
    "description": "",
    "id": "5988da55-9744-42b3-bdd6-b2401ecc9272"
  },
  {
    "order_id": 0,
    "description": "",
    "id": "59ac3d74-61a5-4a1f-9497-7a25aa38d207",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F59ac3d74-61a5-4a1f-9497-7a25aa38d207%2F600x600%2Fbfbfd70e-850c-432f-aa0a-d370aa8ec9aa_600x600?alt=media&token=59379b65-08b1-4186-a2b7-f1ad0238ca18",
        "reference": "stroke_photos/59ac3d74-61a5-4a1f-9497-7a25aa38d207/600x600/bfbfd70e-850c-432f-aa0a-d370aa8ec9aa_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F59ac3d74-61a5-4a1f-9497-7a25aa38d207%2Fbfbfd70e-850c-432f-aa0a-d370aa8ec9aa?alt=media&token=66794881-3a97-493e-9366-2b1ad1760af4",
        "reference": "/stroke_photos/59ac3d74-61a5-4a1f-9497-7a25aa38d207/bfbfd70e-850c-432f-aa0a-d370aa8ec9aa"
      }
    },
    "name": "Blank",
    "order_number": 726,
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "original_size": "A7",
      "ref": "20170703",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "price": 0.4,
      "name": null,
      "brand": {},
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "gsm": "250",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "tool": null
  },
  {
    "name": "",
    "description": "",
    "order_number": 1096,
    "id": "59da7793-5d76-46e2-8bd0-f5fa7d3ff5ef"
  },
  {
    "paper": {
      "brand_model": {},
      "ref": null,
      "brand": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4",
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": null,
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null
    },
    "id": "59f3388e-8008-4502-b2dd-24380b434ee6",
    "order_id": 0,
    "name": "Silver-On-Black Regular 5",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F59f3388e-8008-4502-b2dd-24380b434ee6%2F60f3cb40-ac51-47de-b5ee-468a4e282cdb?alt=media&token=4a98fcee-f292-44a3-a1cf-8df0c9615490",
        "reference": "/stroke_photos/59f3388e-8008-4502-b2dd-24380b434ee6/60f3cb40-ac51-47de-b5ee-468a4e282cdb"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F59f3388e-8008-4502-b2dd-24380b434ee6%2F600x600%2F60f3cb40-ac51-47de-b5ee-468a4e282cdb_600x600?alt=media&token=6daa5028-24c6-44e2-accb-4c59676452e1",
        "reference": "stroke_photos/59f3388e-8008-4502-b2dd-24380b434ee6/600x600/60f3cb40-ac51-47de-b5ee-468a4e282cdb_600x600"
      }
    },
    "order_number": 525,
    "tool": null,
    "description": "<p>One more, normal everything again (!) Ah! I have the wrong brush size! that's why the come out too thick!</p>"
  },
  {
    "order_number": 700,
    "id": "5a30cfd7-cbce-458b-bfca-e176fc97ecc5",
    "paper": {
      "name": "tinto",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "ref": "30075105",
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "gsm": "640",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "price": 19.3,
      "original_size": "75x105cm",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      }
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5a30cfd7-cbce-458b-bfca-e176fc97ecc5%2F5e489d31-7f78-432b-9cca-9a305512a753?alt=media&token=93b7b615-5ef0-4837-86d6-9e8fd3433aed",
        "reference": "/stroke_photos/5a30cfd7-cbce-458b-bfca-e176fc97ecc5/5e489d31-7f78-432b-9cca-9a305512a753"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5a30cfd7-cbce-458b-bfca-e176fc97ecc5%2F600x600%2F5e489d31-7f78-432b-9cca-9a305512a753_600x600?alt=media&token=863847bd-1d09-413c-a9fe-131cd936d0d4",
        "reference": "stroke_photos/5a30cfd7-cbce-458b-bfca-e176fc97ecc5/600x600/5e489d31-7f78-432b-9cca-9a305512a753_600x600"
      }
    },
    "name": "Blank",
    "tool": null,
    "order_id": 0,
    "description": ""
  },
  {
    "id": "5a3ec2f5-963c-4643-b054-bde71f834a48",
    "order_id": 0,
    "name": "Blank",
    "order_number": 829,
    "description": "",
    "paper": {
      "ref": "70025010030",
      "brand_model": {},
      "brand": {},
      "price": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    }
  },
  {
    "paper": {
      "ref": "77725080001",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": 1.35,
      "gsm": "250",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "original_size": "A7",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/5a587bef-107c-4d10-8653-facc858cac89/600x600/f1290253-36eb-4d52-99d0-9c8503350449_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5a587bef-107c-4d10-8653-facc858cac89%2F600x600%2Ff1290253-36eb-4d52-99d0-9c8503350449_600x600?alt=media&token=22f3be53-9425-40b3-aee4-79c506ff12c5"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5a587bef-107c-4d10-8653-facc858cac89%2Ff1290253-36eb-4d52-99d0-9c8503350449?alt=media&token=69f94e56-73a7-4447-b7fb-b5685f81cba6",
        "reference": "/stroke_photos/5a587bef-107c-4d10-8653-facc858cac89/f1290253-36eb-4d52-99d0-9c8503350449"
      }
    },
    "id": "5a587bef-107c-4d10-8653-facc858cac89",
    "order_id": 0,
    "description": "",
    "name": "Blank",
    "order_number": 176,
    "tool": null
  },
  {
    "description": "",
    "name": "",
    "order_number": 1119,
    "id": "5a9d3e3e-d94d-43ba-a48e-9f19d7082e30"
  },
  {
    "order_number": 882,
    "id": "5ab0df3b-9c2b-48eb-ac8f-7b40466cf346",
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "brand_model": {
        "name": "Elephant Skin",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2"
      },
      "ref": "4005329065767",
      "price": 1,
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A4",
      "gsm": "110",
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701"
    },
    "name": "Blank",
    "order_id": 0,
    "description": ""
  },
  {
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5ac1e11f-a396-4c4b-9427-bcc1d5d62a63%2F600x600%2Fc14534fe-3bde-4d43-bc75-6a4e3ca77ad9_600x600?alt=media&token=7e3281bc-9e83-45e6-9734-c63f56e70555",
        "reference": "stroke_photos/5ac1e11f-a396-4c4b-9427-bcc1d5d62a63/600x600/c14534fe-3bde-4d43-bc75-6a4e3ca77ad9_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5ac1e11f-a396-4c4b-9427-bcc1d5d62a63%2Fc14534fe-3bde-4d43-bc75-6a4e3ca77ad9?alt=media&token=d85779a5-89ef-433e-a997-2bcd0f7d7924",
        "reference": "/stroke_photos/5ac1e11f-a396-4c4b-9427-bcc1d5d62a63/c14534fe-3bde-4d43-bc75-6a4e3ca77ad9"
      }
    },
    "id": "5ac1e11f-a396-4c4b-9427-bcc1d5d62a63",
    "paper": {
      "name": null,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "ref": "77725080001",
      "original_size": "A7",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "gsm": "250",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": 1.35
    },
    "order_id": 0,
    "tool": null,
    "order_number": 767,
    "description": ""
  },
  {
    "tool": {},
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5ac2e2a2-8029-46b9-bc0b-f55624a51745%2F19ae5f11-7564-49ff-b2a0-2e9eb95fdc25?alt=media&token=9421c1fe-df5b-4990-92b9-01e39cccd5ff",
        "reference": "/stroke_photos/5ac2e2a2-8029-46b9-bc0b-f55624a51745/19ae5f11-7564-49ff-b2a0-2e9eb95fdc25"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/5ac2e2a2-8029-46b9-bc0b-f55624a51745/600x600/19ae5f11-7564-49ff-b2a0-2e9eb95fdc25_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5ac2e2a2-8029-46b9-bc0b-f55624a51745%2F600x600%2F19ae5f11-7564-49ff-b2a0-2e9eb95fdc25_600x600?alt=media&token=faf90ccb-2ac2-49ef-81a5-6ffca992f510"
      }
    },
    "paper": {
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "price": null,
      "ref": "44202129",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "200",
      "name": "",
      "paper_surface": {},
      "original_size": "A4",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4"
    },
    "description": "",
    "name": "Popf Very Low Brush",
    "id": "5ac2e2a2-8029-46b9-bc0b-f55624a51745",
    "order_number": 25
  },
  {
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "ref": "70025010030",
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "brand_model": {},
      "price": null,
      "original_size": "A7",
      "gsm": "250"
    },
    "id": "5acd520e-1a56-4dc8-9893-038199bfb31e",
    "order_number": 635,
    "tool": null,
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5acd520e-1a56-4dc8-9893-038199bfb31e%2F760117ef-f541-4dd3-85e6-bff1099fc285?alt=media&token=5eacc36a-c898-4b11-901b-9237f5a6c75f",
        "reference": "/stroke_photos/5acd520e-1a56-4dc8-9893-038199bfb31e/760117ef-f541-4dd3-85e6-bff1099fc285"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5acd520e-1a56-4dc8-9893-038199bfb31e%2F600x600%2F760117ef-f541-4dd3-85e6-bff1099fc285_600x600?alt=media&token=89b219ca-0e90-41eb-aa49-7bd7edcffac0",
        "reference": "stroke_photos/5acd520e-1a56-4dc8-9893-038199bfb31e/600x600/760117ef-f541-4dd3-85e6-bff1099fc285_600x600"
      }
    },
    "name": "Blank",
    "description": ""
  },
  {
    "id": "5ae87a75-f4cc-46e5-8037-1f948db5602d",
    "tool": null,
    "order_id": 0,
    "order_number": 552,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/5ae87a75-f4cc-46e5-8037-1f948db5602d/600x600/a2b71f8e-dc4e-4aef-bd4a-6ecb2f4846e9_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5ae87a75-f4cc-46e5-8037-1f948db5602d%2F600x600%2Fa2b71f8e-dc4e-4aef-bd4a-6ecb2f4846e9_600x600?alt=media&token=a640d119-efa9-49ee-b53c-e532c0c5cd65"
      },
      "original": {
        "reference": "/stroke_photos/5ae87a75-f4cc-46e5-8037-1f948db5602d/a2b71f8e-dc4e-4aef-bd4a-6ecb2f4846e9",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5ae87a75-f4cc-46e5-8037-1f948db5602d%2Fa2b71f8e-dc4e-4aef-bd4a-6ecb2f4846e9?alt=media&token=822c3dbb-d279-408d-854b-b2b15e2bdb30"
      }
    },
    "paper": {
      "ref": "20170703",
      "original_size": "A7",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": 0.4,
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "gsm": "250",
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand": {},
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "name": "Blank",
    "description": ""
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5b396fdb-bcdd-4f09-b9d8-157fe221cecc%2F600x600%2Fecf6a90f-2a5a-434a-89bd-6155c011a579_600x600?alt=media&token=73a165c1-fe4c-434d-a221-ef88a53d05db",
        "reference": "stroke_photos/5b396fdb-bcdd-4f09-b9d8-157fe221cecc/600x600/ecf6a90f-2a5a-434a-89bd-6155c011a579_600x600"
      },
      "original": {
        "reference": "/stroke_photos/5b396fdb-bcdd-4f09-b9d8-157fe221cecc/ecf6a90f-2a5a-434a-89bd-6155c011a579",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5b396fdb-bcdd-4f09-b9d8-157fe221cecc%2Fecf6a90f-2a5a-434a-89bd-6155c011a579?alt=media&token=6559f89d-b7f5-4400-93af-43d0d44f11e2"
      }
    },
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "brand": {},
      "gsm": null,
      "price": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A4"
    },
    "description": "<p>Let's do a straight line to see how the silver handles (!) Pretty good</p>",
    "tool": null,
    "name": "Silver-On-Black Straight Line",
    "order_id": 0,
    "order_number": 544,
    "id": "5b396fdb-bcdd-4f09-b9d8-157fe221cecc"
  },
  {
    "description": "",
    "order_id": 0,
    "order_number": 667,
    "paper": {
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico"
      },
      "gsm": "640",
      "price": 19.3,
      "ref": "30075105",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "75x105cm",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "name": "tinto"
    },
    "id": "5b78611b-3fab-4ff3-ac98-82f8b53aac68",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/5b78611b-3fab-4ff3-ac98-82f8b53aac68/600x600/977b7232-b834-4ef2-9e24-7d2fd0060e3c_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5b78611b-3fab-4ff3-ac98-82f8b53aac68%2F600x600%2F977b7232-b834-4ef2-9e24-7d2fd0060e3c_600x600?alt=media&token=b1e144d6-77a4-4383-aabb-d7a4b5908f05"
      },
      "original": {
        "reference": "/stroke_photos/5b78611b-3fab-4ff3-ac98-82f8b53aac68/977b7232-b834-4ef2-9e24-7d2fd0060e3c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5b78611b-3fab-4ff3-ac98-82f8b53aac68%2F977b7232-b834-4ef2-9e24-7d2fd0060e3c?alt=media&token=5a66e1d3-16e9-42b8-8164-9c38e16368e6"
      }
    },
    "name": "Blank",
    "tool": null
  },
  {
    "name": "",
    "order_number": 1169,
    "description": "",
    "id": "5b93d4f8-c335-4d2a-a5cc-1e2a29a8db82"
  },
  {
    "name": "",
    "order_number": 1148,
    "description": "",
    "id": "5ba3c3ea-15c1-4bc7-9d27-a6ba92d198d1"
  },
  {
    "tool": null,
    "order_number": 341,
    "paper": {
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "gsm": "250",
      "price": null,
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "20180107",
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "original_size": "A7",
      "name": null
    },
    "order_id": 0,
    "description": "<p>My frist check. Terrible, but I know firsts are bad</p>",
    "name": "Check",
    "id": "5c287082-3ead-4ed0-9626-6360a80c2e4b",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5c287082-3ead-4ed0-9626-6360a80c2e4b%2F600x600%2F20e86068-abce-48a6-821d-9c73998cc75f_600x600?alt=media&token=4d311bc0-c6d0-4067-a0dd-aec835a80d1f",
        "reference": "stroke_photos/5c287082-3ead-4ed0-9626-6360a80c2e4b/600x600/20e86068-abce-48a6-821d-9c73998cc75f_600x600"
      },
      "original": {
        "reference": "/stroke_photos/5c287082-3ead-4ed0-9626-6360a80c2e4b/20e86068-abce-48a6-821d-9c73998cc75f",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5c287082-3ead-4ed0-9626-6360a80c2e4b%2F20e86068-abce-48a6-821d-9c73998cc75f?alt=media&token=073a8b5a-0c1d-4091-8e7b-6e4c66886887"
      }
    }
  },
  {
    "order_number": 1181,
    "name": "",
    "description": "",
    "id": "5c43f376-a79e-4fe6-9c4a-c38aa554933b"
  },
  {
    "order_number": 359,
    "tool": null,
    "description": "<p>A bit of those. Strange arc, let's do it again</p>",
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "brand": {},
      "price": null,
      "original_size": "A7",
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20180107",
      "name": null
    },
    "name": "Silver Variable Pressure",
    "order_id": 0,
    "id": "5c48f2b0-eadc-46a1-9b22-e3c8d85b51e8",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5c48f2b0-eadc-46a1-9b22-e3c8d85b51e8%2F56c1499f-d82f-46df-8256-111fb6eeea5c?alt=media&token=62317d78-fd90-452f-a3fd-aff8712f301a",
        "reference": "/stroke_photos/5c48f2b0-eadc-46a1-9b22-e3c8d85b51e8/56c1499f-d82f-46df-8256-111fb6eeea5c"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/5c48f2b0-eadc-46a1-9b22-e3c8d85b51e8/600x600/56c1499f-d82f-46df-8256-111fb6eeea5c_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5c48f2b0-eadc-46a1-9b22-e3c8d85b51e8%2F600x600%2F56c1499f-d82f-46df-8256-111fb6eeea5c_600x600?alt=media&token=50b593a2-b076-464a-bc2a-2e5f37fa5af9"
      }
    }
  },
  {
    "name": "",
    "order_number": 1125,
    "description": "",
    "id": "5c9e78d7-e83c-48b0-be70-e14a7b3b371f"
  },
  {
    "order_number": 163,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5cc9ed69-5693-49ea-80d5-5239bf8f8609%2F600x600%2F8244ab6c-398b-4b55-9617-6ac1700f1d86_600x600?alt=media&token=7773d440-1b5b-42fc-a33f-bb9d2dcf3489",
        "reference": "stroke_photos/5cc9ed69-5693-49ea-80d5-5239bf8f8609/600x600/8244ab6c-398b-4b55-9617-6ac1700f1d86_600x600"
      },
      "original": {
        "reference": "/stroke_photos/5cc9ed69-5693-49ea-80d5-5239bf8f8609/8244ab6c-398b-4b55-9617-6ac1700f1d86",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5cc9ed69-5693-49ea-80d5-5239bf8f8609%2F8244ab6c-398b-4b55-9617-6ac1700f1d86?alt=media&token=746032e0-e91d-4f4d-8636-6d1edf372243"
      }
    },
    "order_id": 0,
    "name": "Blank",
    "paper": {
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "77725080001",
      "gsm": "250"
    },
    "tool": null,
    "description": "",
    "id": "5cc9ed69-5693-49ea-80d5-5239bf8f8609"
  },
  {
    "tool": null,
    "id": "5ce03bd4-e1c3-4bc2-9e7c-beaeb45737ba",
    "order_number": 225,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5ce03bd4-e1c3-4bc2-9e7c-beaeb45737ba%2F600x600%2F99558511-dd49-4964-8325-c2299c96c194_600x600?alt=media&token=764c8546-9fbb-44fb-9bb8-911918718abf",
        "reference": "stroke_photos/5ce03bd4-e1c3-4bc2-9e7c-beaeb45737ba/600x600/99558511-dd49-4964-8325-c2299c96c194_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5ce03bd4-e1c3-4bc2-9e7c-beaeb45737ba%2F99558511-dd49-4964-8325-c2299c96c194?alt=media&token=914c6626-66d5-4683-ab48-ce654598014c",
        "reference": "/stroke_photos/5ce03bd4-e1c3-4bc2-9e7c-beaeb45737ba/99558511-dd49-4964-8325-c2299c96c194"
      }
    },
    "name": "Vaflichki 1",
    "paper": {
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "ref": "77725080001",
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A7",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "price": 1.35,
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      }
    },
    "description": "",
    "order_id": 0
  },
  {
    "name": "Blank",
    "order_number": 741,
    "description": "",
    "paper": {
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "ref": "20170703",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "250",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand": {},
      "price": 0.4,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    },
    "id": "5d1f3a45-230a-4447-8bdf-d55bcab492c0",
    "order_id": 0,
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/5d1f3a45-230a-4447-8bdf-d55bcab492c0/600x600/619c6795-e720-42df-87c8-2446b6e9b1b5_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5d1f3a45-230a-4447-8bdf-d55bcab492c0%2F600x600%2F619c6795-e720-42df-87c8-2446b6e9b1b5_600x600?alt=media&token=501d3ace-29ce-4a4a-a60a-ff6513578445"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5d1f3a45-230a-4447-8bdf-d55bcab492c0%2F619c6795-e720-42df-87c8-2446b6e9b1b5?alt=media&token=621d5fd9-96ef-4e0b-a710-de3f10c6757e",
        "reference": "/stroke_photos/5d1f3a45-230a-4447-8bdf-d55bcab492c0/619c6795-e720-42df-87c8-2446b6e9b1b5"
      }
    }
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5dc70177-eeb2-40e1-bffe-a5b2207a8265%2F497bf632-c4b0-41da-aeb3-d014810ec88a?alt=media&token=4ff5c4fc-bbe8-49f0-abac-c5121444ddf1",
        "reference": "/stroke_photos/5dc70177-eeb2-40e1-bffe-a5b2207a8265/497bf632-c4b0-41da-aeb3-d014810ec88a"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/5dc70177-eeb2-40e1-bffe-a5b2207a8265/600x600/497bf632-c4b0-41da-aeb3-d014810ec88a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5dc70177-eeb2-40e1-bffe-a5b2207a8265%2F600x600%2F497bf632-c4b0-41da-aeb3-d014810ec88a_600x600?alt=media&token=7f43f76e-0157-4a75-be1b-d895f920ccd4"
      }
    },
    "id": "5dc70177-eeb2-40e1-bffe-a5b2207a8265",
    "order_id": 0,
    "name": "WOE HOT 9",
    "description": "",
    "paper": {
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Accademia Designo"
      },
      "original_size": "A4",
      "ref": "44202129",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_surface": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "name": "",
      "price": null,
      "gsm": "200"
    },
    "tool": null,
    "order_number": 94
  },
  {
    "name": "",
    "order_number": 1190,
    "description": "",
    "id": "5dcba78b-e27d-4058-af04-e56da8d202be"
  },
  {
    "description": "",
    "paper": {
      "original_size": "A7",
      "ref": "20170703",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bristol Paper"
      },
      "price": 0.4,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250",
      "brand": {}
    },
    "name": "Blank",
    "order_id": 0,
    "id": "5dd56733-224f-4634-a491-14bb9e1301b4",
    "order_number": 806
  },
  {
    "order_number": 724,
    "id": "5e05f9d1-68d0-453d-a455-b4071e7a2b2c",
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5e05f9d1-68d0-453d-a455-b4071e7a2b2c%2F600x600%2Fd673d57e-4dee-4290-88ab-c7a366f5131b_600x600?alt=media&token=429ced21-678a-4d5b-ac7e-e281b0c95abc",
        "reference": "stroke_photos/5e05f9d1-68d0-453d-a455-b4071e7a2b2c/600x600/d673d57e-4dee-4290-88ab-c7a366f5131b_600x600"
      },
      "original": {
        "reference": "/stroke_photos/5e05f9d1-68d0-453d-a455-b4071e7a2b2c/d673d57e-4dee-4290-88ab-c7a366f5131b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5e05f9d1-68d0-453d-a455-b4071e7a2b2c%2Fd673d57e-4dee-4290-88ab-c7a366f5131b?alt=media&token=83af9fe8-98e3-4585-aa1a-89f05a701d32"
      }
    },
    "order_id": 0,
    "description": "",
    "paper": {
      "price": 0.4,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "250",
      "original_size": "A7",
      "ref": "20170703",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand": {},
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "name": null
    },
    "tool": null
  },
  {
    "name": "",
    "description": "",
    "order_number": 1146,
    "id": "5e093b7e-ef3e-487d-868e-5cc0f4cf719e"
  },
  {
    "tool": null,
    "order_id": 0,
    "name": "Door to Happiness",
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "ref": "20180107",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "original_size": "A7",
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "250",
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {}
    },
    "description": "<p>It's very angelic</p>",
    "id": "5e12ba51-061c-463c-8eb3-11f1674d28d8",
    "photos": {
      "original": {
        "reference": "/stroke_photos/5e12ba51-061c-463c-8eb3-11f1674d28d8/ee19c814-cf5d-4d72-9a8b-bbf319222fdb",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5e12ba51-061c-463c-8eb3-11f1674d28d8%2Fee19c814-cf5d-4d72-9a8b-bbf319222fdb?alt=media&token=517c893f-a2d9-4231-8f0b-edff5f8c1a34"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/5e12ba51-061c-463c-8eb3-11f1674d28d8/600x600/ee19c814-cf5d-4d72-9a8b-bbf319222fdb_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5e12ba51-061c-463c-8eb3-11f1674d28d8%2F600x600%2Fee19c814-cf5d-4d72-9a8b-bbf319222fdb_600x600?alt=media&token=68be47ac-425e-4471-abb9-9756bd323e2a"
      }
    },
    "order_number": 379
  },
  {
    "tool": null,
    "description": "<p>keep straining it</p>",
    "paper": {
      "brand_model": {},
      "ref": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "gsm": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "brand": {},
      "original_size": "A4"
    },
    "order_id": 0,
    "order_number": 519,
    "name": "White Splodge Touch 9",
    "id": "5e190f32-5b76-4c20-8161-800958c2143e",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5e190f32-5b76-4c20-8161-800958c2143e%2F600x600%2Fdfa6d550-890c-4ab4-a756-6c51473e1ae4_600x600?alt=media&token=05e42167-0bee-4430-8162-d42bd35914ca",
        "reference": "stroke_photos/5e190f32-5b76-4c20-8161-800958c2143e/600x600/dfa6d550-890c-4ab4-a756-6c51473e1ae4_600x600"
      },
      "original": {
        "reference": "/stroke_photos/5e190f32-5b76-4c20-8161-800958c2143e/dfa6d550-890c-4ab4-a756-6c51473e1ae4",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5e190f32-5b76-4c20-8161-800958c2143e%2Fdfa6d550-890c-4ab4-a756-6c51473e1ae4?alt=media&token=ba602435-c44c-4d1b-9072-0ddffb2c38d2"
      }
    }
  },
  {
    "description": "",
    "order_id": 0,
    "paper": {
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "ref": "4005329065767",
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": 1,
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "original_size": "A4",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "name": "Elephant Skin"
      },
      "gsm": "110"
    },
    "order_number": 857,
    "name": "Blank",
    "id": "5e2d186d-ee17-48bf-92a2-3bfdbb1de175"
  },
  {
    "name": "Dash",
    "paper": {
      "ref": "77725080001",
      "price": 1.35,
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "original_size": "A7",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "gsm": "250",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      }
    },
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5e2f0293-6a36-4b6f-93a5-48c1bf8477f2%2F5a21e9e9-6266-4539-b939-517e2c48c479?alt=media&token=9df5755f-4367-40a7-9fe5-0881111d4fcc",
        "reference": "/stroke_photos/5e2f0293-6a36-4b6f-93a5-48c1bf8477f2/5a21e9e9-6266-4539-b939-517e2c48c479"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/5e2f0293-6a36-4b6f-93a5-48c1bf8477f2/600x600/5a21e9e9-6266-4539-b939-517e2c48c479_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5e2f0293-6a36-4b6f-93a5-48c1bf8477f2%2F600x600%2F5a21e9e9-6266-4539-b939-517e2c48c479_600x600?alt=media&token=6853529a-0226-4a4f-9908-d3bf5a14feb5"
      }
    },
    "id": "5e2f0293-6a36-4b6f-93a5-48c1bf8477f2",
    "tool": null,
    "order_number": 420,
    "description": "Elegant, minimalistic, cute."
  },
  {
    "order_id": 0,
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5e814422-cec5-48b8-94ba-661f16056ccd%2F600x600%2Fcb5bca0c-07e7-437a-9d16-70235d0915c3_600x600?alt=media&token=94f9943b-6b27-4b31-823d-75ecb42c9a91",
        "reference": "stroke_photos/5e814422-cec5-48b8-94ba-661f16056ccd/600x600/cb5bca0c-07e7-437a-9d16-70235d0915c3_600x600"
      },
      "original": {
        "reference": "/stroke_photos/5e814422-cec5-48b8-94ba-661f16056ccd/cb5bca0c-07e7-437a-9d16-70235d0915c3",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5e814422-cec5-48b8-94ba-661f16056ccd%2Fcb5bca0c-07e7-437a-9d16-70235d0915c3?alt=media&token=29164a9b-942b-4ff3-9d17-a1cc5248fa2d"
      }
    },
    "order_number": 647,
    "description": "",
    "paper": {
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "original_size": "A7",
      "ref": "70025010030",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "gsm": "250",
      "brand_model": {}
    },
    "id": "5e814422-cec5-48b8-94ba-661f16056ccd",
    "tool": null
  },
  {
    "order_id": 0,
    "name": "Swirly Yuk",
    "id": "5ec1f549-72af-4d40-aca3-0ec61a6cac45",
    "order_number": 320,
    "paper": {
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "ref": "20180107",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A7",
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "brand": {},
      "gsm": "250"
    },
    "description": "<p>These swirlies turn out to be harder than I thought</p>",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5ec1f549-72af-4d40-aca3-0ec61a6cac45%2Fa6458c5a-fd1c-46f1-9265-82017bfe16db?alt=media&token=d61d538e-e9e9-4796-8226-1e58d8ae4ebb",
        "reference": "/stroke_photos/5ec1f549-72af-4d40-aca3-0ec61a6cac45/a6458c5a-fd1c-46f1-9265-82017bfe16db"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/5ec1f549-72af-4d40-aca3-0ec61a6cac45/600x600/a6458c5a-fd1c-46f1-9265-82017bfe16db_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5ec1f549-72af-4d40-aca3-0ec61a6cac45%2F600x600%2Fa6458c5a-fd1c-46f1-9265-82017bfe16db_600x600?alt=media&token=07411225-d0c9-4958-a05c-b916c9235b23"
      }
    },
    "tool": null
  },
  {
    "order_number": 590,
    "paper": {
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "original_size": "A7",
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "brand_model": {},
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "ref": "70025010030",
      "gsm": "250"
    },
    "description": "",
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5ef3a16e-ae97-4d0d-b4ca-57754b0e3488%2F3109ec0c-3f1f-4f00-af6c-5273c3512ba3?alt=media&token=c55d9f4a-6296-4f85-875b-0b8c8b767d0c",
        "reference": "/stroke_photos/5ef3a16e-ae97-4d0d-b4ca-57754b0e3488/3109ec0c-3f1f-4f00-af6c-5273c3512ba3"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/5ef3a16e-ae97-4d0d-b4ca-57754b0e3488/600x600/3109ec0c-3f1f-4f00-af6c-5273c3512ba3_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5ef3a16e-ae97-4d0d-b4ca-57754b0e3488%2F600x600%2F3109ec0c-3f1f-4f00-af6c-5273c3512ba3_600x600?alt=media&token=19da2bd1-eb00-4be4-82ec-cf927bd06b44"
      }
    },
    "name": "Blank",
    "tool": null,
    "id": "5ef3a16e-ae97-4d0d-b4ca-57754b0e3488"
  },
  {
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand_model": {},
      "ref": null,
      "original_size": "A4",
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "gsm": null
    },
    "tool": null,
    "order_number": 454,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5f04a4f7-12b3-4ab7-9798-929bac650c90%2Feacf3b2c-773a-4680-84c4-c6860b7a941b?alt=media&token=dd48a296-7daf-4b12-aedc-1bdf6073b2c4",
        "reference": "/stroke_photos/5f04a4f7-12b3-4ab7-9798-929bac650c90/eacf3b2c-773a-4680-84c4-c6860b7a941b"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5f04a4f7-12b3-4ab7-9798-929bac650c90%2F600x600%2Feacf3b2c-773a-4680-84c4-c6860b7a941b_600x600?alt=media&token=a5173c06-d5bb-4263-89c5-e56c3ca03e6f",
        "reference": "stroke_photos/5f04a4f7-12b3-4ab7-9798-929bac650c90/600x600/eacf3b2c-773a-4680-84c4-c6860b7a941b_600x600"
      }
    },
    "name": "Slightly Diagonal Brush With White",
    "order_id": 0,
    "id": "5f04a4f7-12b3-4ab7-9798-929bac650c90",
    "description": ""
  },
  {
    "name": "Blank",
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "brand_model": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "brand": {},
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "gsm": null,
      "ref": null,
      "original_size": "A4",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    },
    "order_id": 0,
    "description": "",
    "order_number": 912,
    "id": "5f1ee94c-bcc8-43f1-8efd-a45c17e9d982"
  },
  {
    "paper": {
      "name": "",
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Accademia Designo"
      },
      "gsm": "200",
      "ref": "44202129",
      "paper_surface": {},
      "price": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A4",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      }
    },
    "order_number": 27,
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5f9eac71-03ea-426f-8a82-6d963718ec36%2Fb1920fbd-117e-4500-9a53-3e80870d86fb?alt=media&token=bbd6a05a-2fec-4dd0-860d-5cb88fa40e0a",
        "reference": "/stroke_photos/5f9eac71-03ea-426f-8a82-6d963718ec36/b1920fbd-117e-4500-9a53-3e80870d86fb"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5f9eac71-03ea-426f-8a82-6d963718ec36%2F600x600%2Fb1920fbd-117e-4500-9a53-3e80870d86fb_600x600?alt=media&token=42d52820-bec1-4a69-a9a9-d1d8fea7b71a",
        "reference": "stroke_photos/5f9eac71-03ea-426f-8a82-6d963718ec36/600x600/b1920fbd-117e-4500-9a53-3e80870d86fb_600x600"
      }
    },
    "id": "5f9eac71-03ea-426f-8a82-6d963718ec36",
    "name": "Popf A Lot Of Paint",
    "description": "",
    "tool": {}
  },
  {
    "name": "Straight White Line With Lots of Paint",
    "tool": null,
    "order_id": 0,
    "order_number": 460,
    "paper": {
      "ref": null,
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A4",
      "brand_model": {},
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "price": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": null
    },
    "description": "<p>Okay, still slightly transparent</p>",
    "id": "5fa24424-eef5-448d-9285-b4594f25d72e",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5fa24424-eef5-448d-9285-b4594f25d72e%2F600x600%2F564d24d6-2860-4e8b-8242-15de83d0bd4e_600x600?alt=media&token=3e203633-e2b6-4e29-a4d4-b5cbb7566e78",
        "reference": "stroke_photos/5fa24424-eef5-448d-9285-b4594f25d72e/600x600/564d24d6-2860-4e8b-8242-15de83d0bd4e_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F5fa24424-eef5-448d-9285-b4594f25d72e%2F564d24d6-2860-4e8b-8242-15de83d0bd4e?alt=media&token=b603c027-17e0-4d1d-a55a-8514b0d29ffd",
        "reference": "/stroke_photos/5fa24424-eef5-448d-9285-b4594f25d72e/564d24d6-2860-4e8b-8242-15de83d0bd4e"
      }
    }
  },
  {
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A4",
      "gsm": null,
      "ref": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {},
      "price": null,
      "brand_model": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b"
    },
    "name": "Sound Wave White",
    "order_number": 506,
    "order_id": 0,
    "description": "<p>I want to do a very wide stroke by brushing up and down (!) Looks like a sound wave, I like it</p>",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F60120935-aa3c-4293-b39c-3c4aed541b43%2F600x600%2F43814ce0-06bd-4818-b466-d5a4a53b9150_600x600?alt=media&token=efb0e99f-09c7-4381-a16b-8489d7132de3",
        "reference": "stroke_photos/60120935-aa3c-4293-b39c-3c4aed541b43/600x600/43814ce0-06bd-4818-b466-d5a4a53b9150_600x600"
      },
      "original": {
        "reference": "/stroke_photos/60120935-aa3c-4293-b39c-3c4aed541b43/43814ce0-06bd-4818-b466-d5a4a53b9150",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F60120935-aa3c-4293-b39c-3c4aed541b43%2F43814ce0-06bd-4818-b466-d5a4a53b9150?alt=media&token=0bdb026e-5edc-4015-80d9-3253741e9f1e"
      }
    },
    "id": "60120935-aa3c-4293-b39c-3c4aed541b43",
    "tool": null
  },
  {
    "order_id": 0,
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "name": "Elephant Skin"
      },
      "original_size": "A4",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "name": null,
      "price": 1,
      "ref": "4005329065767",
      "gsm": "110",
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701"
    },
    "id": "601db0ec-8815-4d19-a66d-dba7c616f2df",
    "name": "Blank",
    "description": "",
    "order_number": 874
  },
  {
    "description": "",
    "order_number": 1017,
    "name": "",
    "id": "60243ccd-ec4a-439c-96ad-3b6c1ce684f2"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1081,
    "id": "60542bb8-bfe3-4aa2-ab38-27e68ddcea73"
  },
  {
    "order_number": 684,
    "tool": null,
    "id": "60760d55-393d-4959-8969-d9050c04bdbf",
    "name": "Blank",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F60760d55-393d-4959-8969-d9050c04bdbf%2F3c0938f5-7a53-47b4-9e6e-298bf438dec5?alt=media&token=8a4c377a-22d8-4a18-b037-19f29d9c59f8",
        "reference": "/stroke_photos/60760d55-393d-4959-8969-d9050c04bdbf/3c0938f5-7a53-47b4-9e6e-298bf438dec5"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/60760d55-393d-4959-8969-d9050c04bdbf/600x600/3c0938f5-7a53-47b4-9e6e-298bf438dec5_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F60760d55-393d-4959-8969-d9050c04bdbf%2F600x600%2F3c0938f5-7a53-47b4-9e6e-298bf438dec5_600x600?alt=media&token=e1e7144c-0ddf-4bbc-b024-61105ad6e661"
      }
    },
    "order_id": 0,
    "description": "",
    "paper": {
      "name": "tinto",
      "ref": "30075105",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "gsm": "640",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "brand_model": {
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "price": 19.3,
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "original_size": "75x105cm",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    }
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F60ae4cbf-0b02-4ec0-9392-fde1582e4f79%2Ffb3bab87-7ea4-4a6a-87ec-d7bcaefcdc32?alt=media&token=73f2c8cd-816c-4f70-9144-0b0dffd854f4",
        "reference": "/stroke_photos/60ae4cbf-0b02-4ec0-9392-fde1582e4f79/fb3bab87-7ea4-4a6a-87ec-d7bcaefcdc32"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F60ae4cbf-0b02-4ec0-9392-fde1582e4f79%2F600x600%2Ffb3bab87-7ea4-4a6a-87ec-d7bcaefcdc32_600x600?alt=media&token=22315b41-3054-4cbe-90b3-604f462debb0",
        "reference": "stroke_photos/60ae4cbf-0b02-4ec0-9392-fde1582e4f79/600x600/fb3bab87-7ea4-4a6a-87ec-d7bcaefcdc32_600x600"
      }
    },
    "name": "First Wipeoff",
    "description": "Too much paint all over the flat brush, so I laid it down and pulled...",
    "order_number": 234,
    "id": "60ae4cbf-0b02-4ec0-9392-fde1582e4f79",
    "paper": {
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "ref": "77725080001",
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "original_size": "A7",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "price": 1.35,
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      }
    },
    "order_id": 0,
    "tool": null
  },
  {
    "order_number": 354,
    "paper": {
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "ref": "20180107",
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "name": null,
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      }
    },
    "name": "Longer Silver",
    "id": "60ba22dd-a0f8-4895-b746-3d8affb0fdc0",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/60ba22dd-a0f8-4895-b746-3d8affb0fdc0/600x600/d2714945-efbf-4478-a7c9-72fe62b32eab_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F60ba22dd-a0f8-4895-b746-3d8affb0fdc0%2F600x600%2Fd2714945-efbf-4478-a7c9-72fe62b32eab_600x600?alt=media&token=0e8b2737-c431-4930-a7ca-f97f095d7fb0"
      },
      "original": {
        "reference": "/stroke_photos/60ba22dd-a0f8-4895-b746-3d8affb0fdc0/d2714945-efbf-4478-a7c9-72fe62b32eab",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F60ba22dd-a0f8-4895-b746-3d8affb0fdc0%2Fd2714945-efbf-4478-a7c9-72fe62b32eab?alt=media&token=da52d7e3-382f-4f5d-98c1-63d40ec070de"
      }
    },
    "tool": null,
    "description": "<p>That's better. Can be improved as texture. Hand looks shaky. Strange ending</p>",
    "order_id": 0
  },
  {
    "order_number": 591,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F60cc53ca-1dc2-4a26-93d3-3c0ecd9bf964%2F600x600%2F142d5c7d-eda0-49ab-85ba-41f00012cb19_600x600?alt=media&token=2d80770b-60a6-4d0c-ad74-f566643490ed",
        "reference": "stroke_photos/60cc53ca-1dc2-4a26-93d3-3c0ecd9bf964/600x600/142d5c7d-eda0-49ab-85ba-41f00012cb19_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F60cc53ca-1dc2-4a26-93d3-3c0ecd9bf964%2F142d5c7d-eda0-49ab-85ba-41f00012cb19?alt=media&token=4243288f-df8b-4575-9c9a-402a2aa4a53c",
        "reference": "/stroke_photos/60cc53ca-1dc2-4a26-93d3-3c0ecd9bf964/142d5c7d-eda0-49ab-85ba-41f00012cb19"
      }
    },
    "description": "",
    "name": "Blank",
    "tool": null,
    "paper": {
      "brand": {},
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "250",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand_model": {},
      "name": null,
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "price": null,
      "ref": "70025010030"
    },
    "order_id": 0,
    "id": "60cc53ca-1dc2-4a26-93d3-3c0ecd9bf964"
  },
  {
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/60ed68de-1831-4010-94fc-0279edca82be/600x600/2e4baa32-29f0-484b-ad4e-62ba02348138_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F60ed68de-1831-4010-94fc-0279edca82be%2F600x600%2F2e4baa32-29f0-484b-ad4e-62ba02348138_600x600?alt=media&token=5f60f01f-cc8d-48ca-aae9-423190896fad"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F60ed68de-1831-4010-94fc-0279edca82be%2F2e4baa32-29f0-484b-ad4e-62ba02348138?alt=media&token=c4e79634-be68-453b-ade4-21da8ef1636a",
        "reference": "/stroke_photos/60ed68de-1831-4010-94fc-0279edca82be/2e4baa32-29f0-484b-ad4e-62ba02348138"
      }
    },
    "order_id": 0,
    "description": "",
    "order_number": 745,
    "paper": {
      "name": null,
      "original_size": "A7",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "ref": "20170703",
      "price": 0.4,
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bristol Paper"
      },
      "brand": {}
    },
    "tool": null,
    "name": "Blank",
    "id": "60ed68de-1831-4010-94fc-0279edca82be"
  },
  {
    "order_number": 1196,
    "description": "",
    "name": "",
    "id": "6167fe23-32ce-4cd3-a036-551398591c4b"
  },
  {
    "order_number": 648,
    "id": "61828531-6d80-4705-8207-90f6842217ff",
    "tool": null,
    "paper": {
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "price": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "ref": "70025010030",
      "brand_model": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "brand": {}
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/61828531-6d80-4705-8207-90f6842217ff/600x600/4b565448-b88b-4658-aa34-a6adf1ac88ef_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F61828531-6d80-4705-8207-90f6842217ff%2F600x600%2F4b565448-b88b-4658-aa34-a6adf1ac88ef_600x600?alt=media&token=dbd8cf39-9ecd-4624-9cc9-5c9883c3aedd"
      },
      "original": {
        "reference": "/stroke_photos/61828531-6d80-4705-8207-90f6842217ff/4b565448-b88b-4658-aa34-a6adf1ac88ef",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F61828531-6d80-4705-8207-90f6842217ff%2F4b565448-b88b-4658-aa34-a6adf1ac88ef?alt=media&token=e6547de4-d410-4235-b5fe-71a9b4f2c71e"
      }
    },
    "order_id": 0,
    "name": "Blank",
    "description": ""
  },
  {
    "name": "Blank",
    "order_number": 809,
    "description": "",
    "order_id": 0,
    "id": "618c6107-c28c-44a7-ab05-1aad2c6b624f",
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "name": null,
      "price": 0.4,
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20170703",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "gsm": "250"
    }
  },
  {
    "id": "618f8894-6501-4c2c-94e2-f2b34cfc2c79",
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/618f8894-6501-4c2c-94e2-f2b34cfc2c79/3e2919cb-7ce4-41bd-8fd1-a24f3cf77b2c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F618f8894-6501-4c2c-94e2-f2b34cfc2c79%2F3e2919cb-7ce4-41bd-8fd1-a24f3cf77b2c?alt=media&token=6fe4fe91-2a9d-4c73-b854-db718a6f122d"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F618f8894-6501-4c2c-94e2-f2b34cfc2c79%2F600x600%2F3e2919cb-7ce4-41bd-8fd1-a24f3cf77b2c_600x600?alt=media&token=d548530e-0dbb-4fef-bf6a-5baa02e0d04d",
        "reference": "stroke_photos/618f8894-6501-4c2c-94e2-f2b34cfc2c79/600x600/3e2919cb-7ce4-41bd-8fd1-a24f3cf77b2c_600x600"
      }
    },
    "name": "Musically 3",
    "description": "<meta charset=\"utf-8\"><span>Pressing hard leaves apparently a big black butt, so I will attempt next stroke to make a smoother gradient</span>",
    "order_number": 238,
    "order_id": 0,
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "original_size": "A7",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "gsm": "250",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "price": 1.35
    }
  },
  {
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F61ccdf67-a4d2-4ecf-9843-f4025492213d%2F600x600%2F1e20c229-c7a7-46a1-8460-63f3756f19b5_600x600?alt=media&token=225abe2b-0236-4974-8550-35b3c640ea72",
        "reference": "stroke_photos/61ccdf67-a4d2-4ecf-9843-f4025492213d/600x600/1e20c229-c7a7-46a1-8460-63f3756f19b5_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F61ccdf67-a4d2-4ecf-9843-f4025492213d%2F1e20c229-c7a7-46a1-8460-63f3756f19b5?alt=media&token=d69c0c27-04e3-422a-a6f3-2e3cf526fe7f",
        "reference": "/stroke_photos/61ccdf67-a4d2-4ecf-9843-f4025492213d/1e20c229-c7a7-46a1-8460-63f3756f19b5"
      }
    },
    "paper": {
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "original_size": "A7",
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": 0.4,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "20170703",
      "gsm": "250"
    },
    "id": "61ccdf67-a4d2-4ecf-9843-f4025492213d",
    "name": "Blank",
    "order_number": 723,
    "description": "",
    "order_id": 0
  },
  {
    "description": "",
    "order_number": 1013,
    "name": "",
    "id": "61ef1094-6aa1-4611-8763-6ad16f1d74ba"
  },
  {
    "tool": null,
    "paper": {
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "price": 1.35,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "gsm": "250",
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      }
    },
    "order_number": 166,
    "photos": {
      "original": {
        "reference": "/stroke_photos/6250f667-731f-4115-9be5-1ee2c44ec2a5/1a02b14c-ccea-431b-b2eb-9c3700c5c3aa",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6250f667-731f-4115-9be5-1ee2c44ec2a5%2F1a02b14c-ccea-431b-b2eb-9c3700c5c3aa?alt=media&token=95643d74-def2-4d53-aa1c-166be650c459"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/6250f667-731f-4115-9be5-1ee2c44ec2a5/600x600/1a02b14c-ccea-431b-b2eb-9c3700c5c3aa_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6250f667-731f-4115-9be5-1ee2c44ec2a5%2F600x600%2F1a02b14c-ccea-431b-b2eb-9c3700c5c3aa_600x600?alt=media&token=54297a42-9f4d-4b98-be92-6c97287bc87e"
      }
    },
    "id": "6250f667-731f-4115-9be5-1ee2c44ec2a5",
    "name": "Blank",
    "description": "",
    "order_id": 0
  },
  {
    "paper": {
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "original_size": "75x105cm",
      "price": 19.3,
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "name": "tinto",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "gsm": "640",
      "ref": "30075105"
    },
    "id": "625b92b0-b0ec-4cfb-ac76-42280d5cc0ed",
    "order_id": 0,
    "order_number": 694,
    "name": "Blank",
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/625b92b0-b0ec-4cfb-ac76-42280d5cc0ed/600x600/b0f2293e-4fd2-4218-9c19-252039bd8379_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F625b92b0-b0ec-4cfb-ac76-42280d5cc0ed%2F600x600%2Fb0f2293e-4fd2-4218-9c19-252039bd8379_600x600?alt=media&token=34f095c0-ae07-4241-8176-7afc44b1e758"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F625b92b0-b0ec-4cfb-ac76-42280d5cc0ed%2Fb0f2293e-4fd2-4218-9c19-252039bd8379?alt=media&token=496cf9f4-6fa7-4d0c-90a6-eea6d8ca5a31",
        "reference": "/stroke_photos/625b92b0-b0ec-4cfb-ac76-42280d5cc0ed/b0f2293e-4fd2-4218-9c19-252039bd8379"
      }
    },
    "tool": null
  },
  {
    "id": "628ded3b-5d35-4e08-a10d-feaac93afdcc",
    "description": "",
    "paper": {
      "color": {
        "hex": "#f5f5f5",
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "name": null,
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {},
      "ref": "70025010030",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea"
    },
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/628ded3b-5d35-4e08-a10d-feaac93afdcc/600x600/acd61af3-3c27-4c53-9c8a-b365a0dddf76_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F628ded3b-5d35-4e08-a10d-feaac93afdcc%2F600x600%2Facd61af3-3c27-4c53-9c8a-b365a0dddf76_600x600?alt=media&token=2835e208-35c2-4665-907a-c2db179ab347"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F628ded3b-5d35-4e08-a10d-feaac93afdcc%2Facd61af3-3c27-4c53-9c8a-b365a0dddf76?alt=media&token=d6af61f9-98dd-4d92-99d0-1ee81cd9f37a",
        "reference": "/stroke_photos/628ded3b-5d35-4e08-a10d-feaac93afdcc/acd61af3-3c27-4c53-9c8a-b365a0dddf76"
      }
    },
    "order_id": 0,
    "name": "Blank",
    "order_number": 641
  },
  {
    "name": "Just Make It Again",
    "id": "628fb799-441d-490a-ae15-68f3e0aded00",
    "description": "<p>Good, just being on the paper is my target</p>",
    "order_number": 293,
    "order_id": 0,
    "tool": null,
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "price": null,
      "gsm": "250",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "ref": "20180107",
      "original_size": "A7",
      "brand": {}
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/628fb799-441d-490a-ae15-68f3e0aded00/8386b0ca-d569-4eae-bc7e-53498a4ee002",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F628fb799-441d-490a-ae15-68f3e0aded00%2F8386b0ca-d569-4eae-bc7e-53498a4ee002?alt=media&token=3be68b29-f9c8-49e0-8c91-571c3e77504a"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/628fb799-441d-490a-ae15-68f3e0aded00/600x600/8386b0ca-d569-4eae-bc7e-53498a4ee002_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F628fb799-441d-490a-ae15-68f3e0aded00%2F600x600%2F8386b0ca-d569-4eae-bc7e-53498a4ee002_600x600?alt=media&token=bef030d7-2639-4f91-9e5f-b61cd7550e26"
      }
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1205,
    "id": "629099a2-0b92-4ddd-a2cc-9dbe1bb272de"
  },
  {
    "description": "If my brush gets sticky, I can make it stuck in a certain position purposefully.",
    "name": "Fake Stick 1",
    "order_number": 258,
    "id": "62ea21c5-9f97-4f2c-818e-62b808fc0d80",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F62ea21c5-9f97-4f2c-818e-62b808fc0d80%2F600x600%2F4e718085-28e8-40f1-8151-cbe0d33ffc9d_600x600?alt=media&token=1d4339bc-f027-4f3a-954d-9b807a0c0576",
        "reference": "stroke_photos/62ea21c5-9f97-4f2c-818e-62b808fc0d80/600x600/4e718085-28e8-40f1-8151-cbe0d33ffc9d_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F62ea21c5-9f97-4f2c-818e-62b808fc0d80%2F4e718085-28e8-40f1-8151-cbe0d33ffc9d?alt=media&token=c519d23e-71a6-4770-992d-099c1da6c555",
        "reference": "/stroke_photos/62ea21c5-9f97-4f2c-818e-62b808fc0d80/4e718085-28e8-40f1-8151-cbe0d33ffc9d"
      }
    },
    "tool": null,
    "order_id": 0,
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "name": null,
      "price": null,
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "20180107",
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "250"
    }
  },
  {
    "id": "62f1abc3-aceb-46e8-90a7-142fc46eea84",
    "paper": {
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "A7",
      "ref": "20180107",
      "gsm": "250",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top"
      },
      "brand": {},
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "order_id": 0,
    "description": "<p>Seems that you have to put more paint at least</p>",
    "order_number": 349,
    "photos": {
      "original": {
        "reference": "/stroke_photos/62f1abc3-aceb-46e8-90a7-142fc46eea84/4be2c0d8-12dc-4c33-be37-3edda98c8287",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F62f1abc3-aceb-46e8-90a7-142fc46eea84%2F4be2c0d8-12dc-4c33-be37-3edda98c8287?alt=media&token=e6791f9a-d7d6-4e82-9955-46ee1d01429e"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/62f1abc3-aceb-46e8-90a7-142fc46eea84/600x600/4be2c0d8-12dc-4c33-be37-3edda98c8287_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F62f1abc3-aceb-46e8-90a7-142fc46eea84%2F600x600%2F4be2c0d8-12dc-4c33-be37-3edda98c8287_600x600?alt=media&token=5a33d48d-abe9-427b-b213-708e9a4a152b"
      }
    },
    "tool": null,
    "name": "Another Silver Shot"
  },
  {
    "order_number": 1023,
    "name": "",
    "description": "",
    "id": "62f848e8-d3e6-42eb-b7d3-9a43e9625fb8"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/6304871b-194b-421e-a251-e893ef385f9f/9b69b937-63cc-4186-bc45-dec7ecad2a87",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6304871b-194b-421e-a251-e893ef385f9f%2F9b69b937-63cc-4186-bc45-dec7ecad2a87?alt=media&token=6e263756-494b-4683-827b-94211eadebe3"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/6304871b-194b-421e-a251-e893ef385f9f/600x600/9b69b937-63cc-4186-bc45-dec7ecad2a87_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6304871b-194b-421e-a251-e893ef385f9f%2F600x600%2F9b69b937-63cc-4186-bc45-dec7ecad2a87_600x600?alt=media&token=08fac508-249b-4ee3-96b4-dab5c4538df7"
      }
    },
    "name": "Blank",
    "description": "",
    "order_id": 0,
    "id": "6304871b-194b-421e-a251-e893ef385f9f",
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "ref": "77725080001",
      "gsm": "250",
      "price": 1.35,
      "original_size": "A7",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      }
    },
    "order_number": 768,
    "tool": null
  },
  {
    "order_number": 814,
    "order_id": 0,
    "id": "633349d1-c00b-4ac1-8d24-b22d45a7248a",
    "name": "Blank",
    "paper": {
      "name": null,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "ref": "20170703",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "original_size": "A7",
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": 0.4,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "description": ""
  },
  {
    "id": "63500283-54a3-4227-bacc-86b8ae36c766",
    "order_number": 659,
    "description": "",
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/63500283-54a3-4227-bacc-86b8ae36c766/600x600/64ffdcdb-a0e3-4c12-b66a-9eefaa560320_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F63500283-54a3-4227-bacc-86b8ae36c766%2F600x600%2F64ffdcdb-a0e3-4c12-b66a-9eefaa560320_600x600?alt=media&token=4ffa89a3-07e0-43f2-a2f9-154e4f9222e4"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F63500283-54a3-4227-bacc-86b8ae36c766%2F64ffdcdb-a0e3-4c12-b66a-9eefaa560320?alt=media&token=5b31f6ed-6b7b-474b-b3d1-d1714dbc4b4a",
        "reference": "/stroke_photos/63500283-54a3-4227-bacc-86b8ae36c766/64ffdcdb-a0e3-4c12-b66a-9eefaa560320"
      }
    },
    "paper": {
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Artistico"
      },
      "gsm": "640",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "name": "tinto",
      "ref": "30075105",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": 19.3,
      "original_size": "75x105cm",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      }
    },
    "order_id": 0,
    "tool": null
  },
  {
    "description": "",
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "brand": {},
      "name": null,
      "brand_model": {},
      "ref": null,
      "price": null,
      "gsm": null,
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      }
    },
    "id": "63a4d9e2-eab9-45bc-8904-b3338ae80231",
    "order_id": 0,
    "name": "Blank",
    "order_number": 947
  },
  {
    "name": "Blank",
    "paper": {
      "name": null,
      "ref": "4005329065767",
      "brand_model": {
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        }
      },
      "gsm": "110",
      "original_size": "A4",
      "price": 1,
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "order_number": 885,
    "order_id": 0,
    "id": "63eacd8b-8fb8-4bfe-9213-a31b6ca40266",
    "description": ""
  },
  {
    "name": "Strokie",
    "order_number": 333,
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "ref": "20180107",
      "name": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "original_size": "A7",
      "price": null
    },
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/641c12a1-178b-4747-850a-76f94fe78df4/98df518a-012a-4b08-a301-dce6b85d012d",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F641c12a1-178b-4747-850a-76f94fe78df4%2F98df518a-012a-4b08-a301-dce6b85d012d?alt=media&token=7c7c49f0-f435-4a70-afe7-0ebee05de321"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/641c12a1-178b-4747-850a-76f94fe78df4/600x600/98df518a-012a-4b08-a301-dce6b85d012d_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F641c12a1-178b-4747-850a-76f94fe78df4%2F600x600%2F98df518a-012a-4b08-a301-dce6b85d012d_600x600?alt=media&token=697775bc-601a-43e3-936e-e6062871a651"
      }
    },
    "id": "641c12a1-178b-4747-850a-76f94fe78df4",
    "description": "<p>Better. Still there is something thick in the middle and the curve is off</p>",
    "order_id": 0
  },
  {
    "description": "",
    "order_number": 1154,
    "name": "",
    "id": "642ded98-9dd8-4674-bb92-1a8a5f2caa76"
  },
  {
    "name": "",
    "order_number": 1168,
    "description": "",
    "id": "644c2e76-c7ff-4cb7-ab04-8fe9ffa5bed8"
  },
  {
    "order_number": 1109,
    "name": "",
    "description": "",
    "id": "645252a3-75ec-4764-99cf-468bb84dd970"
  },
  {
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/64725b1c-e1b3-4aea-8384-e5ca6043c37a/3880fc79-1b1a-4f17-aa4b-fa65ca0d40e6",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F64725b1c-e1b3-4aea-8384-e5ca6043c37a%2F3880fc79-1b1a-4f17-aa4b-fa65ca0d40e6?alt=media&token=19705c0b-02a1-4f21-a3d7-22d6dd857cc3"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F64725b1c-e1b3-4aea-8384-e5ca6043c37a%2F600x600%2F3880fc79-1b1a-4f17-aa4b-fa65ca0d40e6_600x600?alt=media&token=1b3d1850-8e3c-4216-a839-7fccee614261",
        "reference": "stroke_photos/64725b1c-e1b3-4aea-8384-e5ca6043c37a/600x600/3880fc79-1b1a-4f17-aa4b-fa65ca0d40e6_600x600"
      }
    },
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "ref": "20170703",
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": 0.4,
      "brand": {}
    },
    "tool": null,
    "id": "64725b1c-e1b3-4aea-8384-e5ca6043c37a",
    "name": "Blank",
    "order_number": 736,
    "order_id": 0
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F64a0b6b4-caf0-458c-8b4c-3260fbeac810%2F600x600%2F8b4f3bf4-ba09-498c-8b16-dfb2de3cc6fb_600x600?alt=media&token=bf2bcae2-7835-4c34-8efe-4b5b7a1664b8",
        "reference": "stroke_photos/64a0b6b4-caf0-458c-8b4c-3260fbeac810/600x600/8b4f3bf4-ba09-498c-8b16-dfb2de3cc6fb_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F64a0b6b4-caf0-458c-8b4c-3260fbeac810%2F8b4f3bf4-ba09-498c-8b16-dfb2de3cc6fb?alt=media&token=56100c8c-8597-43b8-b199-d13e4e8059d7",
        "reference": "/stroke_photos/64a0b6b4-caf0-458c-8b4c-3260fbeac810/8b4f3bf4-ba09-498c-8b16-dfb2de3cc6fb"
      }
    },
    "order_number": 407,
    "id": "64a0b6b4-caf0-458c-8b4c-3260fbeac810",
    "description": "",
    "name": "Blank",
    "tool": null,
    "order_id": 0,
    "paper": {
      "gsm": "250",
      "price": 1.35,
      "ref": "77725080001",
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "original_size": "A7"
    }
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F64d4aa23-d33b-413c-8222-ee4e07a7c863%2F600x600%2F680ac475-b1c2-49ae-b5e8-35341a312d8e_600x600?alt=media&token=55678b14-220a-461e-be71-ec389936d9f3",
        "reference": "stroke_photos/64d4aa23-d33b-413c-8222-ee4e07a7c863/600x600/680ac475-b1c2-49ae-b5e8-35341a312d8e_600x600"
      },
      "original": {
        "reference": "/stroke_photos/64d4aa23-d33b-413c-8222-ee4e07a7c863/680ac475-b1c2-49ae-b5e8-35341a312d8e",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F64d4aa23-d33b-413c-8222-ee4e07a7c863%2F680ac475-b1c2-49ae-b5e8-35341a312d8e?alt=media&token=d5992f05-736a-4805-89a9-9c2e5712e187"
      }
    },
    "order_id": 0,
    "tool": null,
    "name": "Regular Worse",
    "description": "<p>Maybe next time</p>",
    "id": "64d4aa23-d33b-413c-8222-ee4e07a7c863",
    "paper": {
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "price": null,
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "original_size": "A7",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "brand": {},
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    },
    "order_number": 270
  },
  {
    "order_number": 1182,
    "description": "",
    "name": "",
    "id": "64ef99ed-4333-47b7-93c7-d26948490113"
  },
  {
    "paper": {
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "ref": "30075105",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "original_size": "75x105cm",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": "tinto",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": 19.3,
      "gsm": "640"
    },
    "order_number": 666,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F650c17cc-aeda-4e7f-99cd-37e06087309c%2F77df9205-e740-455d-8462-a8bd715da41e?alt=media&token=f6f48509-fec5-4878-ba61-e19744952443",
        "reference": "/stroke_photos/650c17cc-aeda-4e7f-99cd-37e06087309c/77df9205-e740-455d-8462-a8bd715da41e"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F650c17cc-aeda-4e7f-99cd-37e06087309c%2F600x600%2F77df9205-e740-455d-8462-a8bd715da41e_600x600?alt=media&token=704696a2-c0ff-42ff-a780-615945589427",
        "reference": "stroke_photos/650c17cc-aeda-4e7f-99cd-37e06087309c/600x600/77df9205-e740-455d-8462-a8bd715da41e_600x600"
      }
    },
    "description": "",
    "order_id": 0,
    "name": "Blank",
    "id": "650c17cc-aeda-4e7f-99cd-37e06087309c",
    "tool": null
  },
  {
    "description": "<p>How did I get back to shortness?</p>",
    "order_number": 356,
    "paper": {
      "ref": "20180107",
      "brand": {},
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "original_size": "A7",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "name": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "order_id": 0,
    "tool": null,
    "name": "Shorty Again =(",
    "id": "66132fdb-41bd-44e9-b75c-5e57eeb38193",
    "photos": {
      "original": {
        "reference": "/stroke_photos/66132fdb-41bd-44e9-b75c-5e57eeb38193/40d2e208-6645-4b0c-8d01-7ca0f8b609ef",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F66132fdb-41bd-44e9-b75c-5e57eeb38193%2F40d2e208-6645-4b0c-8d01-7ca0f8b609ef?alt=media&token=74e79cce-4fd9-49c6-a3db-76563e190f4a"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F66132fdb-41bd-44e9-b75c-5e57eeb38193%2F600x600%2F40d2e208-6645-4b0c-8d01-7ca0f8b609ef_600x600?alt=media&token=c154518f-6002-4e05-9d4c-f51e349def06",
        "reference": "stroke_photos/66132fdb-41bd-44e9-b75c-5e57eeb38193/600x600/40d2e208-6645-4b0c-8d01-7ca0f8b609ef_600x600"
      }
    }
  },
  {
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null,
      "gsm": "250",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand": {},
      "ref": "20180107"
    },
    "tool": null,
    "order_id": 0,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/662f3019-a99a-4762-880f-81d1db2e668e/600x600/1a2c642b-925f-42b4-b249-be3a55e09478_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F662f3019-a99a-4762-880f-81d1db2e668e%2F600x600%2F1a2c642b-925f-42b4-b249-be3a55e09478_600x600?alt=media&token=d1133215-9c90-4aa3-9f08-1331254750b6"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F662f3019-a99a-4762-880f-81d1db2e668e%2F1a2c642b-925f-42b4-b249-be3a55e09478?alt=media&token=1779c205-5197-4058-8b31-f55da99c2e58",
        "reference": "/stroke_photos/662f3019-a99a-4762-880f-81d1db2e668e/1a2c642b-925f-42b4-b249-be3a55e09478"
      }
    },
    "order_number": 105,
    "id": "662f3019-a99a-4762-880f-81d1db2e668e",
    "name": "VP Zebra"
  },
  {
    "tool": null,
    "order_id": 0,
    "order_number": 348,
    "id": "666422c9-42d0-47f1-a96b-2fc28c5e12d3",
    "name": "Silver smudge",
    "description": "<p>Even more pale. Terrible really</p>",
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "name": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top"
      },
      "gsm": "250",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "20180107",
      "brand": {}
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F666422c9-42d0-47f1-a96b-2fc28c5e12d3%2F600x600%2F0f62ff7f-36d1-4c45-9b30-a1e27ef57488_600x600?alt=media&token=5f93ee52-1b0c-4088-b3c1-6860f945b44b",
        "reference": "stroke_photos/666422c9-42d0-47f1-a96b-2fc28c5e12d3/600x600/0f62ff7f-36d1-4c45-9b30-a1e27ef57488_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F666422c9-42d0-47f1-a96b-2fc28c5e12d3%2F0f62ff7f-36d1-4c45-9b30-a1e27ef57488?alt=media&token=ee903a38-576d-4fba-9c2b-52bdfea053e8",
        "reference": "/stroke_photos/666422c9-42d0-47f1-a96b-2fc28c5e12d3/0f62ff7f-36d1-4c45-9b30-a1e27ef57488"
      }
    }
  },
  {
    "description": "",
    "order_number": 1019,
    "name": "",
    "id": "667ca759-21ee-43ce-8d77-5dde02aa8977"
  },
  {
    "id": "66cf41eb-eb35-4f0a-8709-f7ceb06b7f52",
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "brand_model": {},
      "brand": {},
      "ref": null,
      "original_size": "A4",
      "gsm": null,
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "name": "Mosaics White",
    "description": "<p>Very interesting. Some holes but more like separate parts formed</p>",
    "order_number": 494,
    "tool": null,
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/66cf41eb-eb35-4f0a-8709-f7ceb06b7f52/135473ae-9f50-4179-a8fa-6b0c7c90312c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F66cf41eb-eb35-4f0a-8709-f7ceb06b7f52%2F135473ae-9f50-4179-a8fa-6b0c7c90312c?alt=media&token=7f81eb62-53d3-4e1e-9a6f-50120ad05697"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/66cf41eb-eb35-4f0a-8709-f7ceb06b7f52/600x600/135473ae-9f50-4179-a8fa-6b0c7c90312c_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F66cf41eb-eb35-4f0a-8709-f7ceb06b7f52%2F600x600%2F135473ae-9f50-4179-a8fa-6b0c7c90312c_600x600?alt=media&token=da9dc751-2d82-49f4-96ba-0e69cff709be"
      }
    }
  },
  {
    "order_number": 603,
    "tool": null,
    "paper": {
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "70025010030",
      "brand": {},
      "price": null,
      "brand_model": {}
    },
    "description": "",
    "id": "66cff4ee-982a-4442-9f33-19511569cb47",
    "order_id": 0,
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F66cff4ee-982a-4442-9f33-19511569cb47%2F600x600%2F3d4ccad2-15ea-41cb-b150-5ba82a567441_600x600?alt=media&token=817b3722-8f24-49ec-bf86-360b01e3b50d",
        "reference": "stroke_photos/66cff4ee-982a-4442-9f33-19511569cb47/600x600/3d4ccad2-15ea-41cb-b150-5ba82a567441_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F66cff4ee-982a-4442-9f33-19511569cb47%2F3d4ccad2-15ea-41cb-b150-5ba82a567441?alt=media&token=6ab2d95d-8e0f-4ce0-9cee-5ea1b616e3a5",
        "reference": "/stroke_photos/66cff4ee-982a-4442-9f33-19511569cb47/3d4ccad2-15ea-41cb-b150-5ba82a567441"
      }
    }
  },
  {
    "order_number": 836,
    "id": "6755603e-b1ef-4334-9b3c-31ec6299ab41",
    "name": "Blank",
    "paper": {
      "price": null,
      "ref": "70025010030",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand": {},
      "brand_model": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "original_size": "A7",
      "gsm": "250"
    },
    "order_id": 0,
    "description": ""
  },
  {
    "description": "",
    "order_number": 194,
    "order_id": 0,
    "paper": {
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "original_size": "A7",
      "name": null,
      "price": 1.35,
      "ref": "77725080001",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      }
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/676a844d-996b-4830-ada7-47f300e3adf7/600x600/63a94648-da65-4c73-8194-2b05e324b468_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F676a844d-996b-4830-ada7-47f300e3adf7%2F600x600%2F63a94648-da65-4c73-8194-2b05e324b468_600x600?alt=media&token=16392a6c-6166-4677-89be-91cb79f234ac"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F676a844d-996b-4830-ada7-47f300e3adf7%2F63a94648-da65-4c73-8194-2b05e324b468?alt=media&token=cc13ea60-52f0-452e-a9ad-91b918f4731a",
        "reference": "/stroke_photos/676a844d-996b-4830-ada7-47f300e3adf7/63a94648-da65-4c73-8194-2b05e324b468"
      }
    },
    "name": "Blank",
    "id": "676a844d-996b-4830-ada7-47f300e3adf7",
    "tool": null
  },
  {
    "name": "Blank",
    "order_number": 560,
    "paper": {
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "ref": "20170703",
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bristol Paper"
      },
      "price": 0.4,
      "brand": {},
      "original_size": "A7",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      }
    },
    "order_id": 0,
    "tool": null,
    "description": "",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F676d78b7-c1a9-454b-bc00-a94f8f31d955%2F2e270501-f71c-4404-9706-b30891472ebc?alt=media&token=002ab79c-8113-4b90-afc4-f44fc240e898",
        "reference": "/stroke_photos/676d78b7-c1a9-454b-bc00-a94f8f31d955/2e270501-f71c-4404-9706-b30891472ebc"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/676d78b7-c1a9-454b-bc00-a94f8f31d955/600x600/2e270501-f71c-4404-9706-b30891472ebc_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F676d78b7-c1a9-454b-bc00-a94f8f31d955%2F600x600%2F2e270501-f71c-4404-9706-b30891472ebc_600x600?alt=media&token=3a4553eb-0598-42e7-abb3-4f630cd46a32"
      }
    },
    "id": "676d78b7-c1a9-454b-bc00-a94f8f31d955"
  },
  {
    "id": "6790aa53-5995-4cf3-8341-7dfb6d28c67c",
    "description": "",
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/6790aa53-5995-4cf3-8341-7dfb6d28c67c/600x600/8998d402-9041-4752-a1b0-432847f62b95_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6790aa53-5995-4cf3-8341-7dfb6d28c67c%2F600x600%2F8998d402-9041-4752-a1b0-432847f62b95_600x600?alt=media&token=ef3719b1-5102-403c-993e-429e4bea964e"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6790aa53-5995-4cf3-8341-7dfb6d28c67c%2F8998d402-9041-4752-a1b0-432847f62b95?alt=media&token=b42f9bc1-8f8f-490d-a86b-42c393e547c5",
        "reference": "/stroke_photos/6790aa53-5995-4cf3-8341-7dfb6d28c67c/8998d402-9041-4752-a1b0-432847f62b95"
      }
    },
    "tool": null,
    "order_id": 0,
    "order_number": 750,
    "paper": {
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "gsm": "250",
      "brand": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "20170703",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "original_size": "A7",
      "name": null,
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": 0.4
    }
  },
  {
    "name": "",
    "order_number": 1204,
    "description": "",
    "id": "67abdcdb-d504-489a-9541-cc215ec6aec2"
  },
  {
    "order_number": 1173,
    "name": "",
    "description": "",
    "id": "681bb21a-990b-45ef-9a38-a9bca8581d36"
  },
  {
    "tool": null,
    "paper": {
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "name": null,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "original_size": "A7",
      "price": 1.35,
      "ref": "77725080001"
    },
    "order_number": 212,
    "name": "Pivot",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/685175a8-48e9-4003-9484-e02216344d09/600x600/af5f19e9-0f0c-4c04-9aad-62d325033a31_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F685175a8-48e9-4003-9484-e02216344d09%2F600x600%2Faf5f19e9-0f0c-4c04-9aad-62d325033a31_600x600?alt=media&token=c55be99e-c22e-4d67-9be3-6243a6a2a37d"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F685175a8-48e9-4003-9484-e02216344d09%2Faf5f19e9-0f0c-4c04-9aad-62d325033a31?alt=media&token=56549589-940e-487d-b135-7769dc977f4f",
        "reference": "/stroke_photos/685175a8-48e9-4003-9484-e02216344d09/af5f19e9-0f0c-4c04-9aad-62d325033a31"
      }
    },
    "id": "685175a8-48e9-4003-9484-e02216344d09",
    "description": "This is the first attempt to draw the stroke with a natural arm arc that pivots exactly around the elbow. I attempted to fix my wrist, fingers, and everything from the elbow on. Quite pleased with the naturalness of the arc."
  },
  {
    "description": "",
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "gsm": null,
      "ref": null,
      "name": null,
      "brand": {},
      "brand_model": {},
      "original_size": "A4"
    },
    "id": "68c16878-e1d8-4538-a803-74789e1c9da3",
    "order_id": 0,
    "name": "Blank",
    "order_number": 910
  },
  {
    "paper": {
      "brand_model": {
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        }
      },
      "original_size": "A4",
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "110",
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": 1,
      "ref": "4005329065767",
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      }
    },
    "order_number": 876,
    "name": "Blank",
    "id": "68f3794a-c297-418e-9f6c-5e13333324aa",
    "description": "",
    "order_id": 0
  },
  {
    "paper": {
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": "tinto",
      "gsm": "640",
      "ref": "30075105",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "price": 19.3,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "original_size": "75x105cm",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "brand_model": {
        "name": "Artistico",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      }
    },
    "description": "",
    "name": "Blank",
    "id": "692d9781-2843-4fb7-882b-95baeecd6811",
    "order_number": 989,
    "order_id": 0
  },
  {
    "order_number": 158,
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F695eccf3-19ab-4f53-8777-2dd248f5ccf0%2F1eeb3992-73c6-42a0-8277-f14fec2f5a81?alt=media&token=47277a28-d7d3-414e-950b-53c108aa6e34",
        "reference": "/stroke_photos/695eccf3-19ab-4f53-8777-2dd248f5ccf0/1eeb3992-73c6-42a0-8277-f14fec2f5a81"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F695eccf3-19ab-4f53-8777-2dd248f5ccf0%2F600x600%2F1eeb3992-73c6-42a0-8277-f14fec2f5a81_600x600?alt=media&token=29261b3e-6bbb-4a83-b1dd-bbc6bbf5cad2",
        "reference": "stroke_photos/695eccf3-19ab-4f53-8777-2dd248f5ccf0/600x600/1eeb3992-73c6-42a0-8277-f14fec2f5a81_600x600"
      }
    },
    "tool": null,
    "description": "",
    "paper": {
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "original_size": "A7",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "ref": "77725080001",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "price": 1.35
    },
    "name": "Wavy",
    "id": "695eccf3-19ab-4f53-8777-2dd248f5ccf0"
  },
  {
    "order_id": 0,
    "id": "6987c8a3-6aa5-4db9-882b-c5bb08f5e121",
    "order_number": 781,
    "description": "",
    "tool": null,
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/6987c8a3-6aa5-4db9-882b-c5bb08f5e121/600x600/7797be88-8502-48ec-9e9e-52a6c4e4fd5a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6987c8a3-6aa5-4db9-882b-c5bb08f5e121%2F600x600%2F7797be88-8502-48ec-9e9e-52a6c4e4fd5a_600x600?alt=media&token=8af9e694-23ec-4a78-9129-106f5ddec9a7"
      },
      "original": {
        "reference": "/stroke_photos/6987c8a3-6aa5-4db9-882b-c5bb08f5e121/7797be88-8502-48ec-9e9e-52a6c4e4fd5a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6987c8a3-6aa5-4db9-882b-c5bb08f5e121%2F7797be88-8502-48ec-9e9e-52a6c4e4fd5a?alt=media&token=39e5e86c-1118-4c8b-b3cc-a030762e0593"
      }
    },
    "paper": {
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "ref": "77725080001",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "name": null,
      "original_size": "A7",
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "gsm": "250",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      }
    }
  },
  {
    "order_number": 376,
    "tool": null,
    "description": "<p>This somehow reminds me of chains of rainbows</p>",
    "paper": {
      "name": null,
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "ref": "20180107",
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "price": null,
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F69944064-d1e0-4330-a47c-047273f9260b%2F600x600%2Fb73b9351-23b3-46bc-9f3a-44825e37d2eb_600x600?alt=media&token=09d5462b-e206-4283-9f3d-fbfcbcac528f",
        "reference": "stroke_photos/69944064-d1e0-4330-a47c-047273f9260b/600x600/b73b9351-23b3-46bc-9f3a-44825e37d2eb_600x600"
      },
      "original": {
        "reference": "/stroke_photos/69944064-d1e0-4330-a47c-047273f9260b/b73b9351-23b3-46bc-9f3a-44825e37d2eb",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F69944064-d1e0-4330-a47c-047273f9260b%2Fb73b9351-23b3-46bc-9f3a-44825e37d2eb?alt=media&token=0799f5b3-c0d8-4966-9005-e6e773511752"
      }
    },
    "id": "69944064-d1e0-4330-a47c-047273f9260b",
    "name": "Silver-Black Spin 3",
    "order_id": 0
  },
  {
    "name": "Blank",
    "tool": null,
    "description": "",
    "paper": {
      "brand": {},
      "ref": "70025010030",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {},
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "price": null,
      "original_size": "A7",
      "name": null,
      "gsm": "250",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "id": "699e0397-bbcd-4fa0-afc5-f24caa8e790d",
    "order_id": 0,
    "order_number": 629,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F699e0397-bbcd-4fa0-afc5-f24caa8e790d%2F11a94ae5-ebd5-4e67-bb9a-da8b4ff509bf?alt=media&token=70e68f9b-397c-4a6e-898c-849b0df1a7ce",
        "reference": "/stroke_photos/699e0397-bbcd-4fa0-afc5-f24caa8e790d/11a94ae5-ebd5-4e67-bb9a-da8b4ff509bf"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F699e0397-bbcd-4fa0-afc5-f24caa8e790d%2F600x600%2F11a94ae5-ebd5-4e67-bb9a-da8b4ff509bf_600x600?alt=media&token=1b6e902c-f0d1-43de-a73c-5c87e345332a",
        "reference": "stroke_photos/699e0397-bbcd-4fa0-afc5-f24caa8e790d/600x600/11a94ae5-ebd5-4e67-bb9a-da8b4ff509bf_600x600"
      }
    }
  },
  {
    "name": "",
    "description": "",
    "order_number": 1078,
    "id": "69adfeb7-f67e-422c-9c96-335eafdc037a"
  },
  {
    "name": "",
    "order_number": 1095,
    "description": "",
    "id": "69f48956-5476-441b-814d-82a08678fd88"
  },
  {
    "description": "",
    "order_number": 1194,
    "name": "",
    "id": "6a32176c-bebe-4f19-8986-d294c44f96d5"
  },
  {
    "description": "",
    "tool": null,
    "order_number": 184,
    "name": "Blank",
    "paper": {
      "ref": "77725080001",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "original_size": "A7",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": 1.35,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      }
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6a4756ea-6740-4a4e-9c3a-c70b71c28187%2F600x600%2Fd4813ac3-e36d-4787-9cd1-6fa67642c62e_600x600?alt=media&token=02a3022f-9a34-4bee-9a9a-b9be68c3297c",
        "reference": "stroke_photos/6a4756ea-6740-4a4e-9c3a-c70b71c28187/600x600/d4813ac3-e36d-4787-9cd1-6fa67642c62e_600x600"
      },
      "original": {
        "reference": "/stroke_photos/6a4756ea-6740-4a4e-9c3a-c70b71c28187/d4813ac3-e36d-4787-9cd1-6fa67642c62e",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6a4756ea-6740-4a4e-9c3a-c70b71c28187%2Fd4813ac3-e36d-4787-9cd1-6fa67642c62e?alt=media&token=3025ac12-a593-47f1-b60e-7ecba61c43ce"
      }
    },
    "id": "6a4756ea-6740-4a4e-9c3a-c70b71c28187",
    "order_id": 0
  },
  {
    "order_number": 1215,
    "name": "",
    "description": "",
    "id": "6a580f0d-8d2c-4b69-9b17-7c5dcf0611da"
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6ab26b10-f3d0-4b10-8f9f-30ff0f2c81a7%2F56546ddf-86f6-47c0-a3ff-a56b5ebe5a64?alt=media&token=127d7c91-b810-4d58-be3f-25dbb37a0929",
        "reference": "/stroke_photos/6ab26b10-f3d0-4b10-8f9f-30ff0f2c81a7/56546ddf-86f6-47c0-a3ff-a56b5ebe5a64"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6ab26b10-f3d0-4b10-8f9f-30ff0f2c81a7%2F600x600%2F56546ddf-86f6-47c0-a3ff-a56b5ebe5a64_600x600?alt=media&token=4cadd5b7-0666-4342-b484-37e4dadce17b",
        "reference": "stroke_photos/6ab26b10-f3d0-4b10-8f9f-30ff0f2c81a7/600x600/56546ddf-86f6-47c0-a3ff-a56b5ebe5a64_600x600"
      }
    },
    "order_id": 0,
    "order_number": 138,
    "description": "",
    "id": "6ab26b10-f3d0-4b10-8f9f-30ff0f2c81a7",
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": null,
      "gsm": "250",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "ref": "20180107",
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7"
    },
    "name": "Sideways Bounce 2",
    "tool": null
  },
  {
    "description": "<p>I like the paint in the beginning</p>",
    "id": "6abe4d29-1809-4bd2-a38b-08a87b1f920f",
    "order_id": 0,
    "paper": {
      "gsm": "250",
      "price": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "original_size": "A7",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20180107",
      "name": null
    },
    "tool": null,
    "order_number": 368,
    "name": "Frizzy Hair",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6abe4d29-1809-4bd2-a38b-08a87b1f920f%2Fa421bc29-11d6-4975-aa1c-725ca7b390bc?alt=media&token=360a9df1-e724-4495-bbbd-ebfc29e4d714",
        "reference": "/stroke_photos/6abe4d29-1809-4bd2-a38b-08a87b1f920f/a421bc29-11d6-4975-aa1c-725ca7b390bc"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/6abe4d29-1809-4bd2-a38b-08a87b1f920f/600x600/a421bc29-11d6-4975-aa1c-725ca7b390bc_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6abe4d29-1809-4bd2-a38b-08a87b1f920f%2F600x600%2Fa421bc29-11d6-4975-aa1c-725ca7b390bc_600x600?alt=media&token=27308da7-bfcf-4361-89a5-a3e0e50ff3b6"
      }
    }
  },
  {
    "order_number": 1126,
    "description": "",
    "name": "",
    "id": "6abf6377-7e47-4e51-9a55-902135975e09"
  },
  {
    "order_id": 0,
    "order_number": 848,
    "id": "6b119d1b-bf6f-4120-83e8-c580f89615c8",
    "description": "",
    "name": "Blank",
    "paper": {
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {},
      "original_size": "A7",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl"
      },
      "ref": "70025010030",
      "brand": {},
      "gsm": "250",
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null
    }
  },
  {
    "order_number": 1131,
    "description": "",
    "name": "",
    "id": "6b21be13-d7d8-4ea3-bb7b-b6bcd9b59c3f"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1022,
    "id": "6b2441c1-3af7-4112-a4b3-aa344c26664c"
  },
  {
    "order_id": 0,
    "name": "Dots",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/6b489922-07e5-4737-aab7-582cb3fa7fb6/600x600/6ecf236d-48cf-4df2-b2db-6575ecb91837_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6b489922-07e5-4737-aab7-582cb3fa7fb6%2F600x600%2F6ecf236d-48cf-4df2-b2db-6575ecb91837_600x600?alt=media&token=be352d50-be2c-4a4a-9fb8-5de5bd615db9"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6b489922-07e5-4737-aab7-582cb3fa7fb6%2F6ecf236d-48cf-4df2-b2db-6575ecb91837?alt=media&token=ce29c060-226c-417c-bdfe-0bcb47a1746c",
        "reference": "/stroke_photos/6b489922-07e5-4737-aab7-582cb3fa7fb6/6ecf236d-48cf-4df2-b2db-6575ecb91837"
      }
    },
    "description": "<p>Impressive. Love it. Really love it</p>",
    "id": "6b489922-07e5-4737-aab7-582cb3fa7fb6",
    "order_number": 388,
    "paper": {
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "name": null,
      "gsm": "250",
      "brand": {},
      "ref": "20180107",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "price": null
    }
  },
  {
    "description": "",
    "tool": null,
    "paper": {
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "name": null,
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "price": 1.35,
      "ref": "77725080001",
      "gsm": "250",
      "original_size": "A7",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "order_id": 0,
    "order_number": 205,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/6b5fbee3-7c0d-48b2-946a-3ec197bd25ed/600x600/892ac18d-a1bb-49fb-9f9a-fdd1a44e4df1_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6b5fbee3-7c0d-48b2-946a-3ec197bd25ed%2F600x600%2F892ac18d-a1bb-49fb-9f9a-fdd1a44e4df1_600x600?alt=media&token=cb0e5d05-2981-4ea3-8ea6-69f68624c0cf"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6b5fbee3-7c0d-48b2-946a-3ec197bd25ed%2F892ac18d-a1bb-49fb-9f9a-fdd1a44e4df1?alt=media&token=5341651d-2270-4a5b-acb9-16b4dab40af4",
        "reference": "/stroke_photos/6b5fbee3-7c0d-48b2-946a-3ec197bd25ed/892ac18d-a1bb-49fb-9f9a-fdd1a44e4df1"
      }
    },
    "name": "A Naturaaaal",
    "id": "6b5fbee3-7c0d-48b2-946a-3ec197bd25ed"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1098,
    "id": "6b9fff80-2a5a-4cc5-a70c-38a6775cacd4"
  },
  {
    "description": "",
    "order_number": 1125,
    "name": "",
    "id": "6bc50789-f57d-4eac-b27d-9d5c74b5f501"
  },
  {
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/6c02916f-d93b-4fab-bd38-e16e4f675f6b/3406a9bf-0267-40cb-8a14-b0328057da41",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6c02916f-d93b-4fab-bd38-e16e4f675f6b%2F3406a9bf-0267-40cb-8a14-b0328057da41?alt=media&token=ca2f7da4-a707-41ab-b49a-2c28f128e4f3"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/6c02916f-d93b-4fab-bd38-e16e4f675f6b/600x600/3406a9bf-0267-40cb-8a14-b0328057da41_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6c02916f-d93b-4fab-bd38-e16e4f675f6b%2F600x600%2F3406a9bf-0267-40cb-8a14-b0328057da41_600x600?alt=media&token=a8b31d82-56eb-4828-a088-33cd9f8e4cf0"
      }
    },
    "description": "",
    "order_number": 104,
    "paper": {
      "original_size": "A7",
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "name": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top"
      },
      "ref": "20180107"
    },
    "name": "Variable Pressure Bottom Thick",
    "id": "6c02916f-d93b-4fab-bd38-e16e4f675f6b",
    "order_id": 0
  },
  {
    "name": "",
    "order_number": 1062,
    "description": "",
    "id": "6c4be7ce-c992-4bcd-81e0-3b4eef7d368e"
  },
  {
    "name": "Blank",
    "order_id": 0,
    "description": "",
    "id": "6c5e6714-4abc-49be-83df-c2cbd6f539a4",
    "order_number": 956,
    "paper": {
      "name": null,
      "gsm": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4",
      "ref": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "brand_model": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "price": null
    }
  },
  {
    "name": "",
    "description": "",
    "order_number": 1052,
    "id": "6cf6de85-f6dd-4e48-955a-cb40e54b681c"
  },
  {
    "paper": {
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "original_size": "A7",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "price": 1.35,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "name": null,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "gsm": "250"
    },
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/6cfaa13a-9f07-4569-80a7-6a1dca00b426/07cc97ce-5006-4823-b0ff-46e608d3c96b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6cfaa13a-9f07-4569-80a7-6a1dca00b426%2F07cc97ce-5006-4823-b0ff-46e608d3c96b?alt=media&token=92ee3311-dae3-42c3-a7ff-8ab3789e2acb"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/6cfaa13a-9f07-4569-80a7-6a1dca00b426/600x600/07cc97ce-5006-4823-b0ff-46e608d3c96b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6cfaa13a-9f07-4569-80a7-6a1dca00b426%2F600x600%2F07cc97ce-5006-4823-b0ff-46e608d3c96b_600x600?alt=media&token=226f69ab-609f-422d-8154-e3ebee887089"
      }
    },
    "order_number": 432,
    "id": "6cfaa13a-9f07-4569-80a7-6a1dca00b426",
    "description": "Actually that was a deliberate attempt on making a better crown, and it failed so spectacularly I didn't even try it again",
    "name": "Crown Turns Into Batman",
    "tool": null
  },
  {
    "description": "",
    "name": "Blank",
    "id": "6d0f36d1-22b5-4a60-b9a3-65c1e18aa07d",
    "order_number": 914,
    "order_id": 0,
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A4",
      "price": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "brand_model": {},
      "brand": {},
      "gsm": null,
      "name": null,
      "ref": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    }
  },
  {
    "tool": null,
    "description": "<p>This really shows insecurity</p>",
    "order_number": 323,
    "name": "Insecurity",
    "id": "6d707f2a-d8a0-4da7-8f11-d6859e9f6d6c",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6d707f2a-d8a0-4da7-8f11-d6859e9f6d6c%2F600x600%2F8defc4bc-ad17-4e1d-b9bb-0c7129aabcc2_600x600?alt=media&token=0216451b-4f05-4501-821d-04bced27dab8",
        "reference": "stroke_photos/6d707f2a-d8a0-4da7-8f11-d6859e9f6d6c/600x600/8defc4bc-ad17-4e1d-b9bb-0c7129aabcc2_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6d707f2a-d8a0-4da7-8f11-d6859e9f6d6c%2F8defc4bc-ad17-4e1d-b9bb-0c7129aabcc2?alt=media&token=436c3b6d-5207-445b-8678-86037d33eae5",
        "reference": "/stroke_photos/6d707f2a-d8a0-4da7-8f11-d6859e9f6d6c/8defc4bc-ad17-4e1d-b9bb-0c7129aabcc2"
      }
    },
    "paper": {
      "name": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20180107",
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "gsm": "250",
      "brand": {},
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      }
    },
    "order_id": 0
  },
  {
    "name": "",
    "description": "",
    "order_number": 1008,
    "id": "6d7722d7-6766-4cef-afd6-abac362b16e9"
  },
  {
    "description": "<p>Or not</p>",
    "order_number": 280,
    "tool": null,
    "id": "6ea9d770-9857-4ecc-9afd-e5844c6e70f5",
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20180107",
      "brand": {},
      "gsm": "250",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "price": null,
      "original_size": "A7"
    },
    "name": "Gradually Getting Better",
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6ea9d770-9857-4ecc-9afd-e5844c6e70f5%2F5a448296-4e12-45cd-8893-1601db2ee361?alt=media&token=8628f282-78bb-43f9-b455-8eb1cbf0b290",
        "reference": "/stroke_photos/6ea9d770-9857-4ecc-9afd-e5844c6e70f5/5a448296-4e12-45cd-8893-1601db2ee361"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6ea9d770-9857-4ecc-9afd-e5844c6e70f5%2F600x600%2F5a448296-4e12-45cd-8893-1601db2ee361_600x600?alt=media&token=ca0f9f6a-ce99-4fcb-b133-216b5335e2fd",
        "reference": "stroke_photos/6ea9d770-9857-4ecc-9afd-e5844c6e70f5/600x600/5a448296-4e12-45cd-8893-1601db2ee361_600x600"
      }
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1219,
    "id": "6ebc058e-efab-4ebb-bd3c-a0045246ade3"
  },
  {
    "order_id": 0,
    "id": "6ef0ed53-49d1-4fb4-951e-2c86d8643bd6",
    "name": "Blank",
    "description": "",
    "order_number": 870,
    "paper": {
      "price": 1,
      "brand_model": {
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        }
      },
      "ref": "4005329065767",
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "original_size": "A4",
      "gsm": "110",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "name": null,
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1221,
    "id": "6f094a04-1d68-4bb5-8e70-f55ba89cf66a"
  },
  {
    "order_id": 0,
    "tool": null,
    "order_number": 250,
    "id": "6f108ad2-06d4-49b4-97d1-177ebd9f1266",
    "description": "I actually don't remember how this happened like this.",
    "paper": {
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": null,
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "20180107",
      "brand": {},
      "original_size": "A7",
      "name": null
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6f108ad2-06d4-49b4-97d1-177ebd9f1266%2F15f0f633-18a4-4201-8b3f-afd79b0dc566?alt=media&token=cbe9134d-f061-4298-bda5-a16574a50481",
        "reference": "/stroke_photos/6f108ad2-06d4-49b4-97d1-177ebd9f1266/15f0f633-18a4-4201-8b3f-afd79b0dc566"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/6f108ad2-06d4-49b4-97d1-177ebd9f1266/600x600/15f0f633-18a4-4201-8b3f-afd79b0dc566_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6f108ad2-06d4-49b4-97d1-177ebd9f1266%2F600x600%2F15f0f633-18a4-4201-8b3f-afd79b0dc566_600x600?alt=media&token=a9220070-8fc1-43dc-989d-26f09021c35d"
      }
    },
    "name": "Lost Ancestors"
  },
  {
    "id": "6f1f215a-0a0b-4d34-9315-48295650df73",
    "order_number": 927,
    "paper": {
      "brand": {},
      "ref": null,
      "price": null,
      "gsm": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "brand_model": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "original_size": "A4",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "name": "Blank",
    "description": "",
    "order_id": 0
  },
  {
    "name": "Blank",
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/6f4598b5-0f64-4fb8-90eb-d9695cb027b2/818e38e0-f3ca-422d-a869-22f4c6d4e242",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6f4598b5-0f64-4fb8-90eb-d9695cb027b2%2F818e38e0-f3ca-422d-a869-22f4c6d4e242?alt=media&token=5ba6894f-1fd8-4737-974b-501099656329"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/6f4598b5-0f64-4fb8-90eb-d9695cb027b2/600x600/818e38e0-f3ca-422d-a869-22f4c6d4e242_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6f4598b5-0f64-4fb8-90eb-d9695cb027b2%2F600x600%2F818e38e0-f3ca-422d-a869-22f4c6d4e242_600x600?alt=media&token=f9e5bf9d-4a07-4fa3-b9b0-0f8f2d434a27"
      }
    },
    "id": "6f4598b5-0f64-4fb8-90eb-d9695cb027b2",
    "order_id": 0,
    "tool": null,
    "order_number": 706,
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand": {},
      "name": null,
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "original_size": "A7",
      "ref": "20170703",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": 0.4
    }
  },
  {
    "order_number": 676,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6f47a11f-f8ec-4314-99e9-8a0a7ed26260%2F65d86fe3-19c0-4adc-a4a9-dd2d4eb2f2a9?alt=media&token=0bcdff5e-7048-4acc-a149-4c99033414e1",
        "reference": "/stroke_photos/6f47a11f-f8ec-4314-99e9-8a0a7ed26260/65d86fe3-19c0-4adc-a4a9-dd2d4eb2f2a9"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/6f47a11f-f8ec-4314-99e9-8a0a7ed26260/600x600/65d86fe3-19c0-4adc-a4a9-dd2d4eb2f2a9_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6f47a11f-f8ec-4314-99e9-8a0a7ed26260%2F600x600%2F65d86fe3-19c0-4adc-a4a9-dd2d4eb2f2a9_600x600?alt=media&token=c67ea97d-cf49-4d41-b3ad-3db773274e99"
      }
    },
    "id": "6f47a11f-f8ec-4314-99e9-8a0a7ed26260",
    "description": "",
    "name": "Blank",
    "order_id": 0,
    "tool": null,
    "paper": {
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "ref": "30075105",
      "name": "tinto",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": 19.3,
      "gsm": "640",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Artistico"
      },
      "original_size": "75x105cm",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      }
    }
  },
  {
    "name": "Blank",
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": 1,
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "name": "Elephant Skin",
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        }
      },
      "ref": "4005329065767",
      "name": null,
      "gsm": "110",
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      },
      "original_size": "A4",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "description": "",
    "order_id": 0,
    "id": "6f83d93c-4f4b-4905-8b24-1c1e99f06416",
    "order_number": 896
  },
  {
    "order_number": 374,
    "order_id": 0,
    "paper": {
      "price": null,
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "original_size": "A7",
      "ref": "20180107",
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/6fc6883e-ade4-4f98-b2a4-23b3b9d0f477/e3e7116f-e804-4d62-aa16-4189f9bddd23",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6fc6883e-ade4-4f98-b2a4-23b3b9d0f477%2Fe3e7116f-e804-4d62-aa16-4189f9bddd23?alt=media&token=f0cba21f-da52-4e0c-927c-43aa3f0d5534"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/6fc6883e-ade4-4f98-b2a4-23b3b9d0f477/600x600/e3e7116f-e804-4d62-aa16-4189f9bddd23_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F6fc6883e-ade4-4f98-b2a4-23b3b9d0f477%2F600x600%2Fe3e7116f-e804-4d62-aa16-4189f9bddd23_600x600?alt=media&token=25ed705c-f0aa-40a0-9421-b1fdb79b6c45"
      }
    },
    "name": "Silver-Black Spin",
    "description": "<p>Let's try again that spinning brush while doing the stroke. Didn't work really, let's try it again</p>",
    "id": "6fc6883e-ade4-4f98-b2a4-23b3b9d0f477",
    "tool": null
  },
  {
    "description": "",
    "order_id": 0,
    "id": "701f6649-8c0c-4d75-9f7a-8d95c1e6bfca",
    "name": "Blank",
    "order_number": 967,
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": null,
      "original_size": "A4",
      "name": null,
      "price": null,
      "brand_model": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "ref": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      }
    }
  },
  {
    "description": "",
    "order_number": 589,
    "tool": null,
    "paper": {
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand": {},
      "ref": "70025010030",
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {},
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "price": null
    },
    "name": "Blank",
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/704b8312-e704-4638-8976-a4f8f6f3f513/f019806f-ff7b-4be5-acb3-bf6b6fa9dd9f",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F704b8312-e704-4638-8976-a4f8f6f3f513%2Ff019806f-ff7b-4be5-acb3-bf6b6fa9dd9f?alt=media&token=9cd742a7-0146-42e7-a246-351e9c28eec5"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F704b8312-e704-4638-8976-a4f8f6f3f513%2F600x600%2Ff019806f-ff7b-4be5-acb3-bf6b6fa9dd9f_600x600?alt=media&token=d5d98535-4b7d-48f2-91be-37ca10838074",
        "reference": "stroke_photos/704b8312-e704-4638-8976-a4f8f6f3f513/600x600/f019806f-ff7b-4be5-acb3-bf6b6fa9dd9f_600x600"
      }
    },
    "id": "704b8312-e704-4638-8976-a4f8f6f3f513"
  },
  {
    "name": "",
    "order_number": 1063,
    "description": "",
    "id": "70562d9d-61f6-4c1e-8f14-ed5ad3390841"
  },
  {
    "name": "Blank",
    "description": "",
    "paper": {
      "brand_model": {},
      "gsm": "250",
      "original_size": "A7",
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl",
        "hex": "#f5f5f5"
      },
      "ref": "70025010030",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/70bb427e-08b1-4c31-b9fa-d26a8bd22b89/d09cbaef-fa68-4f88-a2d1-cd47714cce09",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F70bb427e-08b1-4c31-b9fa-d26a8bd22b89%2Fd09cbaef-fa68-4f88-a2d1-cd47714cce09?alt=media&token=4d85c2b1-2ef0-42ff-ac75-54b7554931c8"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/70bb427e-08b1-4c31-b9fa-d26a8bd22b89/600x600/d09cbaef-fa68-4f88-a2d1-cd47714cce09_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F70bb427e-08b1-4c31-b9fa-d26a8bd22b89%2F600x600%2Fd09cbaef-fa68-4f88-a2d1-cd47714cce09_600x600?alt=media&token=736c588b-8f98-4543-bb79-e406b43d5a77"
      }
    },
    "order_id": 0,
    "id": "70bb427e-08b1-4c31-b9fa-d26a8bd22b89",
    "tool": null,
    "order_number": 626
  },
  {
    "id": "71113724-dfc0-4e31-ad77-e844d4053595",
    "order_id": 0,
    "order_number": 316,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/71113724-dfc0-4e31-ad77-e844d4053595/600x600/869334f9-5818-4f5d-a661-d1f9aef0ea85_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F71113724-dfc0-4e31-ad77-e844d4053595%2F600x600%2F869334f9-5818-4f5d-a661-d1f9aef0ea85_600x600?alt=media&token=21320936-8b01-48ca-80eb-a2f41db4b2df"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F71113724-dfc0-4e31-ad77-e844d4053595%2F869334f9-5818-4f5d-a661-d1f9aef0ea85?alt=media&token=a3751764-20f0-4020-ae5f-7e2ce3c05a51",
        "reference": "/stroke_photos/71113724-dfc0-4e31-ad77-e844d4053595/869334f9-5818-4f5d-a661-d1f9aef0ea85"
      }
    },
    "name": "Too Ambitious",
    "description": "<p>Totally missing curve</p>",
    "tool": null,
    "paper": {
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "brand": {},
      "name": null,
      "original_size": "A7",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "ref": "20180107",
      "price": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      }
    }
  },
  {
    "id": "715fee54-5bf5-420e-a35c-83f4ffe28f04",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F715fee54-5bf5-420e-a35c-83f4ffe28f04%2F002b7649-2ba7-43d9-a221-aa0dbde041e3?alt=media&token=3f8ece72-ddae-4645-a348-3ce53f39eee6",
        "reference": "/stroke_photos/715fee54-5bf5-420e-a35c-83f4ffe28f04/002b7649-2ba7-43d9-a221-aa0dbde041e3"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F715fee54-5bf5-420e-a35c-83f4ffe28f04%2F600x600%2F002b7649-2ba7-43d9-a221-aa0dbde041e3_600x600?alt=media&token=93ec1725-2b30-4d43-9f32-f422ba495301",
        "reference": "stroke_photos/715fee54-5bf5-420e-a35c-83f4ffe28f04/600x600/002b7649-2ba7-43d9-a221-aa0dbde041e3_600x600"
      }
    },
    "tool": null,
    "order_id": 0,
    "paper": {
      "price": 1.35,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "ref": "77725080001",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "gsm": "250",
      "original_size": "A7",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      }
    },
    "name": "Musically 5",
    "description": "Again, too straight. Bring back the curve!",
    "order_number": 240
  },
  {
    "order_id": 0,
    "description": "<p>Have you seen a real one?</p>",
    "paper": {
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "original_size": "A7",
      "gsm": "250",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F717841ff-99bc-42ee-90ac-718af0ec410a%2Fc9d1d6f6-2bf5-4f79-b152-caab1745882d?alt=media&token=a5a4d957-dc60-4390-b48f-218696bb7775",
        "reference": "/stroke_photos/717841ff-99bc-42ee-90ac-718af0ec410a/c9d1d6f6-2bf5-4f79-b152-caab1745882d"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/717841ff-99bc-42ee-90ac-718af0ec410a/600x600/c9d1d6f6-2bf5-4f79-b152-caab1745882d_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F717841ff-99bc-42ee-90ac-718af0ec410a%2F600x600%2Fc9d1d6f6-2bf5-4f79-b152-caab1745882d_600x600?alt=media&token=6a17b4c2-9d01-40a4-a60e-e67a938aaabf"
      }
    },
    "order_number": 346,
    "tool": null,
    "id": "717841ff-99bc-42ee-90ac-718af0ec410a",
    "name": "Caterpillar"
  },
  {
    "order_number": 41,
    "name": "Pfsmpsitb Another One",
    "tool": null,
    "id": "722187d3-6a02-4d6a-87e0-5784b8a52045",
    "paper": {
      "original_size": "A4",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "ref": "44202129",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": "",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_surface": {},
      "price": null,
      "gsm": "200",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/722187d3-6a02-4d6a-87e0-5784b8a52045/600x600/66bf5bc8-e67d-40b6-b930-895823ae6269_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F722187d3-6a02-4d6a-87e0-5784b8a52045%2F600x600%2F66bf5bc8-e67d-40b6-b930-895823ae6269_600x600?alt=media&token=da6e35ac-3c43-49c1-befb-a286a355997b"
      },
      "original": {
        "reference": "/stroke_photos/722187d3-6a02-4d6a-87e0-5784b8a52045/66bf5bc8-e67d-40b6-b930-895823ae6269",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F722187d3-6a02-4d6a-87e0-5784b8a52045%2F66bf5bc8-e67d-40b6-b930-895823ae6269?alt=media&token=af493bd5-b23b-4575-aeb9-8b18918832b1"
      }
    },
    "description": ""
  },
  {
    "order_id": 0,
    "description": "",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F72bf0519-88bc-44e6-ac13-c0a2814d482f%2Fc6c60bee-6bb5-4360-a0f3-e90512f0f15b?alt=media&token=ef1d6965-47a4-47ac-a894-8a6fff580b6b",
        "reference": "/stroke_photos/72bf0519-88bc-44e6-ac13-c0a2814d482f/c6c60bee-6bb5-4360-a0f3-e90512f0f15b"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/72bf0519-88bc-44e6-ac13-c0a2814d482f/600x600/c6c60bee-6bb5-4360-a0f3-e90512f0f15b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F72bf0519-88bc-44e6-ac13-c0a2814d482f%2F600x600%2Fc6c60bee-6bb5-4360-a0f3-e90512f0f15b_600x600?alt=media&token=e1ce225c-1dd3-4b69-85fd-6e3cbebe1c45"
      }
    },
    "paper": {
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "brand_model": {},
      "original_size": "A7",
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "70025010030",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea"
    },
    "id": "72bf0519-88bc-44e6-ac13-c0a2814d482f",
    "name": "Blank",
    "order_number": 623,
    "tool": null
  },
  {
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "brand_model": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A4",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "price": null,
      "name": null,
      "ref": null
    },
    "order_number": 974,
    "order_id": 0,
    "description": "",
    "id": "72c9f7f7-45bd-4678-bb71-5077d4aa51f9",
    "name": "Blank"
  },
  {
    "description": "",
    "tool": {},
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {},
      "ref": "44202129",
      "price": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "name": "",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "gsm": "200",
      "original_size": "A4"
    },
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F72d72822-b06a-4139-8f2e-74ed02c01f1e%2F600x600%2F723dc042-b1e2-419e-86d4-352aedbf8502_600x600?alt=media&token=986ecbd8-eab5-45f2-9397-a8ebcda8cdbf",
        "reference": "stroke_photos/72d72822-b06a-4139-8f2e-74ed02c01f1e/600x600/723dc042-b1e2-419e-86d4-352aedbf8502_600x600"
      },
      "original": {
        "reference": "/stroke_photos/72d72822-b06a-4139-8f2e-74ed02c01f1e/723dc042-b1e2-419e-86d4-352aedbf8502",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F72d72822-b06a-4139-8f2e-74ed02c01f1e%2F723dc042-b1e2-419e-86d4-352aedbf8502?alt=media&token=b0740cf9-e6b9-4ae8-bcad-75d60f429a97"
      }
    },
    "order_number": 16,
    "id": "72d72822-b06a-4139-8f2e-74ed02c01f1e",
    "name": "More Water"
  },
  {
    "paper": {
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "brand_model": {},
      "ref": null,
      "gsm": null,
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "brand": {}
    },
    "order_id": 0,
    "order_number": 509,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7332f36b-a934-4c38-818b-198a3032be1d%2F600x600%2Fa7dc77c3-6ec7-43bc-866e-41e540f915da_600x600?alt=media&token=acde4003-4a05-4bb0-a503-5512988f6deb",
        "reference": "stroke_photos/7332f36b-a934-4c38-818b-198a3032be1d/600x600/a7dc77c3-6ec7-43bc-866e-41e540f915da_600x600"
      },
      "original": {
        "reference": "/stroke_photos/7332f36b-a934-4c38-818b-198a3032be1d/a7dc77c3-6ec7-43bc-866e-41e540f915da",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7332f36b-a934-4c38-818b-198a3032be1d%2Fa7dc77c3-6ec7-43bc-866e-41e540f915da?alt=media&token=121755e2-e409-4d9c-91b5-acef5c0baa4a"
      }
    },
    "id": "7332f36b-a934-4c38-818b-198a3032be1d",
    "name": "Fat Sound Wave White",
    "description": "<p>I will put more paint in the beginning (!) Also fantastic!</p>",
    "tool": null
  },
  {
    "id": "735a7bd6-ea92-47ba-bfcf-50ced0362d2e",
    "name": "Vaflichki 3",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F735a7bd6-ea92-47ba-bfcf-50ced0362d2e%2F600x600%2Fc33560ed-b631-483f-aa67-0fa00a1d12be_600x600?alt=media&token=23742c0b-a805-49ca-bc2c-2d6c8cf8218f",
        "reference": "stroke_photos/735a7bd6-ea92-47ba-bfcf-50ced0362d2e/600x600/c33560ed-b631-483f-aa67-0fa00a1d12be_600x600"
      },
      "original": {
        "reference": "/stroke_photos/735a7bd6-ea92-47ba-bfcf-50ced0362d2e/c33560ed-b631-483f-aa67-0fa00a1d12be",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F735a7bd6-ea92-47ba-bfcf-50ced0362d2e%2Fc33560ed-b631-483f-aa67-0fa00a1d12be?alt=media&token=264b9761-d592-46f3-8cef-c482e1cbb756"
      }
    },
    "paper": {
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "gsm": "250",
      "original_size": "A7",
      "ref": "77725080001",
      "price": 1.35,
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      }
    },
    "description": "Arc looking better but still not perfect",
    "order_number": 227,
    "tool": null
  },
  {
    "description": "",
    "name": "",
    "order_number": 1200,
    "id": "738e8d90-1a3c-4ca8-97b9-78939dc74b75"
  },
  {
    "id": "73ef3e6a-6c66-4e75-a8a9-ff6bf2f5162a",
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/73ef3e6a-6c66-4e75-a8a9-ff6bf2f5162a/600x600/198e3981-fd65-4626-8d71-ca4d1c9d5fe2_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F73ef3e6a-6c66-4e75-a8a9-ff6bf2f5162a%2F600x600%2F198e3981-fd65-4626-8d71-ca4d1c9d5fe2_600x600?alt=media&token=96b91b67-07f7-408e-b7b5-bbceb865870a"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F73ef3e6a-6c66-4e75-a8a9-ff6bf2f5162a%2F198e3981-fd65-4626-8d71-ca4d1c9d5fe2?alt=media&token=6b9a4bc9-010f-44da-b556-85331f440fa9",
        "reference": "/stroke_photos/73ef3e6a-6c66-4e75-a8a9-ff6bf2f5162a/198e3981-fd65-4626-8d71-ca4d1c9d5fe2"
      }
    },
    "tool": null,
    "order_id": 0,
    "paper": {
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": 0.4,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {},
      "ref": "20170703",
      "original_size": "A7",
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "description": "",
    "order_number": 746
  },
  {
    "name": "Blank",
    "order_number": 918,
    "description": "",
    "order_id": 0,
    "paper": {
      "ref": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "gsm": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "brand_model": {},
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "original_size": "A4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {}
    },
    "id": "73f0fc4d-246b-48ad-b389-0f1d1eeed149"
  },
  {
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "name": null,
      "original_size": "A7",
      "price": 1.35,
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "gsm": "250"
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F74791b95-fbcf-46f3-a7c2-e334874feee3%2Fa866d274-c1c6-4bd1-8aa4-2a768774dd3e?alt=media&token=04d6651e-5f11-44b5-bff6-0d2826cb5777",
        "reference": "/stroke_photos/74791b95-fbcf-46f3-a7c2-e334874feee3/a866d274-c1c6-4bd1-8aa4-2a768774dd3e"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/74791b95-fbcf-46f3-a7c2-e334874feee3/600x600/a866d274-c1c6-4bd1-8aa4-2a768774dd3e_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F74791b95-fbcf-46f3-a7c2-e334874feee3%2F600x600%2Fa866d274-c1c6-4bd1-8aa4-2a768774dd3e_600x600?alt=media&token=62b470fa-ffa5-4159-bcab-81697c59a195"
      }
    },
    "order_id": 0,
    "tool": null,
    "order_number": 200,
    "id": "74791b95-fbcf-46f3-a7c2-e334874feee3",
    "name": "Press Less, Smooth More Again",
    "description": ""
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F74bb5d0e-b7e1-4e4f-85dc-56205433e8a5%2F6de824fe-08c4-47c4-beef-d2890aea3ea8?alt=media&token=af74b6d6-f48a-46e7-ba50-0185ebcbcec3",
        "reference": "/stroke_photos/74bb5d0e-b7e1-4e4f-85dc-56205433e8a5/6de824fe-08c4-47c4-beef-d2890aea3ea8"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/74bb5d0e-b7e1-4e4f-85dc-56205433e8a5/600x600/6de824fe-08c4-47c4-beef-d2890aea3ea8_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F74bb5d0e-b7e1-4e4f-85dc-56205433e8a5%2F600x600%2F6de824fe-08c4-47c4-beef-d2890aea3ea8_600x600?alt=media&token=c942be37-c465-4e7c-930f-8e49aa5769c8"
      }
    },
    "order_number": 269,
    "name": "Back To Regulars",
    "id": "74bb5d0e-b7e1-4e4f-85dc-56205433e8a5",
    "paper": {
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "20180107",
      "brand": {},
      "gsm": "250",
      "original_size": "A7",
      "price": null
    },
    "description": "<p>Just attempting the standard perfection</p>",
    "tool": null,
    "order_id": 0
  },
  {
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/74d5cdf6-b49a-40ba-9729-1b020c078f14/600x600/134336f8-076f-42cc-bdd4-e8a032e15b34_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F74d5cdf6-b49a-40ba-9729-1b020c078f14%2F600x600%2F134336f8-076f-42cc-bdd4-e8a032e15b34_600x600?alt=media&token=22e4268d-b525-4c43-bf35-3984feabdd74"
      },
      "original": {
        "reference": "/stroke_photos/74d5cdf6-b49a-40ba-9729-1b020c078f14/134336f8-076f-42cc-bdd4-e8a032e15b34",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F74d5cdf6-b49a-40ba-9729-1b020c078f14%2F134336f8-076f-42cc-bdd4-e8a032e15b34?alt=media&token=bbc2aff8-83f2-4494-aa97-6aa2d33ddb37"
      }
    },
    "name": "Blank",
    "description": "",
    "order_number": 766,
    "id": "74d5cdf6-b49a-40ba-9729-1b020c078f14",
    "paper": {
      "name": null,
      "original_size": "A7",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "gsm": "250",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso"
      },
      "ref": "77725080001",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "price": 1.35
    },
    "order_id": 0,
    "tool": null
  },
  {
    "name": "",
    "order_number": 1136,
    "description": "",
    "id": "74fe5d52-323f-49be-8a32-9041aeabc212"
  },
  {
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "name": null,
      "gsm": "250",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "original_size": "A7",
      "ref": "20180107",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "price": null
    },
    "order_id": 0,
    "description": "<p>Tip is broken, paint is irregular, curve is broken, beggining is not clear. So many likeable imperfections</p>",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/7583b7f5-e7f1-4854-852a-5d5be2ff4073/600x600/f858b107-7341-487e-afa5-fbc1c9bd8a04_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7583b7f5-e7f1-4854-852a-5d5be2ff4073%2F600x600%2Ff858b107-7341-487e-afa5-fbc1c9bd8a04_600x600?alt=media&token=05d49056-8293-41eb-909c-63a46600a152"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7583b7f5-e7f1-4854-852a-5d5be2ff4073%2Ff858b107-7341-487e-afa5-fbc1c9bd8a04?alt=media&token=1ffeccf5-04ac-47b3-a24a-1ccdab7e099d",
        "reference": "/stroke_photos/7583b7f5-e7f1-4854-852a-5d5be2ff4073/f858b107-7341-487e-afa5-fbc1c9bd8a04"
      }
    },
    "id": "7583b7f5-e7f1-4854-852a-5d5be2ff4073",
    "tool": null,
    "order_number": 285,
    "name": "Far From Perfect"
  },
  {
    "order_number": 1149,
    "name": "",
    "description": "",
    "id": "758471ef-bb45-4c09-80c6-c06f4406193f"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1198,
    "id": "75989c1a-1955-46d8-9811-4ea192d7a04a"
  },
  {
    "name": "WOE HOT 8",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F75bd7dba-74f5-4cf8-b846-ceef80f3f899%2F600x600%2F1aa5bfe6-60ad-468c-80a7-6d9b6e595f23_600x600?alt=media&token=318c6ae6-1c00-44f3-8f5b-06f20dda4f4e",
        "reference": "stroke_photos/75bd7dba-74f5-4cf8-b846-ceef80f3f899/600x600/1aa5bfe6-60ad-468c-80a7-6d9b6e595f23_600x600"
      },
      "original": {
        "reference": "/stroke_photos/75bd7dba-74f5-4cf8-b846-ceef80f3f899/1aa5bfe6-60ad-468c-80a7-6d9b6e595f23",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F75bd7dba-74f5-4cf8-b846-ceef80f3f899%2F1aa5bfe6-60ad-468c-80a7-6d9b6e595f23?alt=media&token=934821bf-e1dd-49d4-b2d5-58d2990a9f99"
      }
    },
    "id": "75bd7dba-74f5-4cf8-b846-ceef80f3f899",
    "paper": {
      "ref": "44202129",
      "paper_surface": {},
      "price": null,
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "gsm": "200",
      "name": "",
      "original_size": "A4",
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "description": "",
    "order_number": 93,
    "tool": null,
    "order_id": 0
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/75c3bf77-1504-42e3-b4b6-027d4a87e9ab/2dbed131-0029-47e8-898b-612f1796a1bf",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F75c3bf77-1504-42e3-b4b6-027d4a87e9ab%2F2dbed131-0029-47e8-898b-612f1796a1bf?alt=media&token=18a82417-f31a-49fb-ba20-5cb0b7fa5581"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F75c3bf77-1504-42e3-b4b6-027d4a87e9ab%2F600x600%2F2dbed131-0029-47e8-898b-612f1796a1bf_600x600?alt=media&token=d659d5fa-693d-4c9c-a3b2-5c5571a1c441",
        "reference": "stroke_photos/75c3bf77-1504-42e3-b4b6-027d4a87e9ab/600x600/2dbed131-0029-47e8-898b-612f1796a1bf_600x600"
      }
    },
    "id": "75c3bf77-1504-42e3-b4b6-027d4a87e9ab",
    "description": "<p>Another splodge</p>",
    "tool": null,
    "order_number": 512,
    "order_id": 0,
    "paper": {
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "brand": {},
      "original_size": "A4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": null,
      "gsm": null,
      "brand_model": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "name": "White Splodge Touch 2"
  },
  {
    "order_id": 0,
    "paper": {
      "brand": {},
      "price": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "brand_model": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "250",
      "ref": "70025010030",
      "original_size": "A7"
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/75d6a045-9e13-4257-9746-25f7059e5535/600x600/bffb5530-18d7-429f-aa24-8690791b808b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F75d6a045-9e13-4257-9746-25f7059e5535%2F600x600%2Fbffb5530-18d7-429f-aa24-8690791b808b_600x600?alt=media&token=d0a9ce1f-f857-4c58-b24f-358c8a98582d"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F75d6a045-9e13-4257-9746-25f7059e5535%2Fbffb5530-18d7-429f-aa24-8690791b808b?alt=media&token=4b0aaca7-44ae-418c-9cf8-48ecffa65708",
        "reference": "/stroke_photos/75d6a045-9e13-4257-9746-25f7059e5535/bffb5530-18d7-429f-aa24-8690791b808b"
      }
    },
    "description": "",
    "order_number": 631,
    "name": "Blank",
    "tool": null,
    "id": "75d6a045-9e13-4257-9746-25f7059e5535"
  },
  {
    "description": "<p>Very friendly. One of my favorites to be honest</p>",
    "tool": null,
    "order_id": 0,
    "id": "7632d7d4-e789-49e7-8f31-4c92bd03814b",
    "order_number": 313,
    "paper": {
      "ref": "20180107",
      "brand": {},
      "gsm": "250",
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "original_size": "A7",
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86"
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7632d7d4-e789-49e7-8f31-4c92bd03814b%2F600x600%2F56cb11d3-0305-4751-8bd9-a250ed045488_600x600?alt=media&token=88d5ab44-ba6c-40cd-9bfc-076a48f0763a",
        "reference": "stroke_photos/7632d7d4-e789-49e7-8f31-4c92bd03814b/600x600/56cb11d3-0305-4751-8bd9-a250ed045488_600x600"
      },
      "original": {
        "reference": "/stroke_photos/7632d7d4-e789-49e7-8f31-4c92bd03814b/56cb11d3-0305-4751-8bd9-a250ed045488",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7632d7d4-e789-49e7-8f31-4c92bd03814b%2F56cb11d3-0305-4751-8bd9-a250ed045488?alt=media&token=5c6a9fbd-7d19-4fcf-a632-99e8bc0d3647"
      }
    },
    "name": "Light Touch"
  },
  {
    "description": "",
    "paper": {
      "name": "",
      "paper_surface": {},
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "price": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A4",
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Accademia Designo"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "gsm": "200",
      "ref": "44202129",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4"
    },
    "id": "7637e5aa-fcde-4d76-9279-4199263249ac",
    "photos": {
      "original": {
        "reference": "/stroke_photos/7637e5aa-fcde-4d76-9279-4199263249ac/ce31262e-3046-43cf-9394-5a49b558583f",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7637e5aa-fcde-4d76-9279-4199263249ac%2Fce31262e-3046-43cf-9394-5a49b558583f?alt=media&token=eadc88c9-b573-449c-8ef6-2e76be58016f"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/7637e5aa-fcde-4d76-9279-4199263249ac/600x600/ce31262e-3046-43cf-9394-5a49b558583f_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7637e5aa-fcde-4d76-9279-4199263249ac%2F600x600%2Fce31262e-3046-43cf-9394-5a49b558583f_600x600?alt=media&token=4f0fe316-0b8d-4651-b64a-a0a2d4715a1a"
      }
    },
    "order_number": 99,
    "name": "Variable Pressure 2 With Less Paint",
    "tool": null,
    "order_id": 0
  },
  {
    "paper": {
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "ref": "44202129",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "paper_surface": {},
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A4",
      "gsm": "200",
      "name": "",
      "price": null,
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      }
    },
    "order_id": 0,
    "tool": null,
    "name": "WOE Hand Off Table",
    "order_number": 86,
    "description": "",
    "id": "763b4461-7c2f-4a1e-986f-36981760b467",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/763b4461-7c2f-4a1e-986f-36981760b467/600x600/41b211d7-4910-4553-8d32-166a200c8ebf_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F763b4461-7c2f-4a1e-986f-36981760b467%2F600x600%2F41b211d7-4910-4553-8d32-166a200c8ebf_600x600?alt=media&token=1b651a04-5956-4f97-8e28-20a2132ee1ae"
      },
      "original": {
        "reference": "/stroke_photos/763b4461-7c2f-4a1e-986f-36981760b467/41b211d7-4910-4553-8d32-166a200c8ebf",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F763b4461-7c2f-4a1e-986f-36981760b467%2F41b211d7-4910-4553-8d32-166a200c8ebf?alt=media&token=3a53377d-c091-4b9a-b640-f87c348bdca3"
      }
    }
  },
  {
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "price": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand_model": {},
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "gsm": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {},
      "original_size": "A4"
    },
    "id": "7675d979-4bcd-44a7-a619-c8f9d9671f55",
    "order_number": 522,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7675d979-4bcd-44a7-a619-c8f9d9671f55%2F600x600%2F979199f4-b5f0-4483-8edd-c00457143d7b_600x600?alt=media&token=28a58987-9fa9-4b91-b913-0ed94a47e1df",
        "reference": "stroke_photos/7675d979-4bcd-44a7-a619-c8f9d9671f55/600x600/979199f4-b5f0-4483-8edd-c00457143d7b_600x600"
      },
      "original": {
        "reference": "/stroke_photos/7675d979-4bcd-44a7-a619-c8f9d9671f55/979199f4-b5f0-4483-8edd-c00457143d7b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7675d979-4bcd-44a7-a619-c8f9d9671f55%2F979199f4-b5f0-4483-8edd-c00457143d7b?alt=media&token=3fa62d52-3d5d-4490-b583-a5c8422749c0"
      }
    },
    "description": "<p>I will press less this time (!) There's less paint and a certain gradient but still very wide</p>",
    "name": "Silver-On-Black Regular 2",
    "tool": null,
    "order_id": 0
  },
  {
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/76951734-ac7b-496b-b2a4-1e6a8d911f98/600x600/cc617615-d53e-4e53-b2b0-6bb74c8a18cd_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F76951734-ac7b-496b-b2a4-1e6a8d911f98%2F600x600%2Fcc617615-d53e-4e53-b2b0-6bb74c8a18cd_600x600?alt=media&token=fd3851da-e5ff-49b5-99a1-8c05b613609b"
      },
      "original": {
        "reference": "/stroke_photos/76951734-ac7b-496b-b2a4-1e6a8d911f98/cc617615-d53e-4e53-b2b0-6bb74c8a18cd",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F76951734-ac7b-496b-b2a4-1e6a8d911f98%2Fcc617615-d53e-4e53-b2b0-6bb74c8a18cd?alt=media&token=66ff441c-d4f1-4f01-8f44-9b396848bb3e"
      }
    },
    "order_id": 0,
    "description": "",
    "id": "76951734-ac7b-496b-b2a4-1e6a8d911f98",
    "name": "Blank",
    "paper": {
      "original_size": "A7",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "20170703",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {},
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "gsm": "250",
      "price": 0.4,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "order_number": 709,
    "tool": null
  },
  {
    "name": "",
    "order_number": 1063,
    "description": "",
    "id": "7723adc1-14b3-4c00-91e9-eb95a68e1ecb"
  },
  {
    "name": "",
    "order_number": 1077,
    "description": "",
    "id": "7737050b-770f-4e3d-96f6-cb7f654f8563"
  },
  {
    "order_number": 1191,
    "name": "",
    "description": "",
    "id": "77638deb-e2ce-409f-8fb5-1aea301f0f33"
  },
  {
    "description": "",
    "order_number": 1091,
    "name": "",
    "id": "77e70fd9-1534-40dd-a8cf-cff16a546880"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1040,
    "id": "77f07c48-a78d-4142-8c7a-77bdd04a3668"
  },
  {
    "order_id": 0,
    "paper": {
      "name": null,
      "price": null,
      "gsm": "250",
      "ref": "70025010030",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {},
      "brand": {},
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A7"
    },
    "id": "77f50882-b950-4962-a99b-381ef0f60c56",
    "name": "Blank",
    "order_number": 607,
    "tool": null,
    "description": "",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F77f50882-b950-4962-a99b-381ef0f60c56%2F076f57e6-0a07-40f0-aa5d-724fc232ba6a?alt=media&token=67a79b95-93d2-42a6-85b4-640718fae242",
        "reference": "/stroke_photos/77f50882-b950-4962-a99b-381ef0f60c56/076f57e6-0a07-40f0-aa5d-724fc232ba6a"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F77f50882-b950-4962-a99b-381ef0f60c56%2F600x600%2F076f57e6-0a07-40f0-aa5d-724fc232ba6a_600x600?alt=media&token=ed4d9cbc-f005-428b-b4c0-87ea5082e519",
        "reference": "stroke_photos/77f50882-b950-4962-a99b-381ef0f60c56/600x600/076f57e6-0a07-40f0-aa5d-724fc232ba6a_600x600"
      }
    }
  },
  {
    "name": "Blank",
    "paper": {
      "gsm": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand": {},
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "ref": null,
      "original_size": "A4",
      "brand_model": {},
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": null
    },
    "description": "",
    "order_id": 0,
    "id": "7812c95f-6471-4cc6-a704-e6aea62ddf75",
    "order_number": 934
  },
  {
    "paper": {
      "ref": "20180107",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "brand": {},
      "original_size": "A7",
      "name": null,
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "tool": null,
    "name": "Let's Really Hit Paper This Time",
    "id": "7850eaf7-4f68-490b-a647-aca01d2ff7d0",
    "order_number": 296,
    "description": "<p>Really</p>",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/7850eaf7-4f68-490b-a647-aca01d2ff7d0/600x600/d7feb10d-9d31-43d8-8ab6-fd79c1a009b8_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7850eaf7-4f68-490b-a647-aca01d2ff7d0%2F600x600%2Fd7feb10d-9d31-43d8-8ab6-fd79c1a009b8_600x600?alt=media&token=d3fb1c90-c9b7-45f3-a876-ce1966f3114c"
      },
      "original": {
        "reference": "/stroke_photos/7850eaf7-4f68-490b-a647-aca01d2ff7d0/d7feb10d-9d31-43d8-8ab6-fd79c1a009b8",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7850eaf7-4f68-490b-a647-aca01d2ff7d0%2Fd7feb10d-9d31-43d8-8ab6-fd79c1a009b8?alt=media&token=2c790be6-526d-47c7-856d-b32e6f22c236"
      }
    }
  },
  {
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "20180107",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": null,
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "brand": {}
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F788560e8-cb40-4c32-9530-fb0363e73a23%2F600x600%2F45c5053c-3ab1-4d6e-8bc2-69fb0e2d101a_600x600?alt=media&token=1ac8bd00-f4e8-4af8-93be-cdb2b8d9f076",
        "reference": "stroke_photos/788560e8-cb40-4c32-9530-fb0363e73a23/600x600/45c5053c-3ab1-4d6e-8bc2-69fb0e2d101a_600x600"
      },
      "original": {
        "reference": "/stroke_photos/788560e8-cb40-4c32-9530-fb0363e73a23/45c5053c-3ab1-4d6e-8bc2-69fb0e2d101a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F788560e8-cb40-4c32-9530-fb0363e73a23%2F45c5053c-3ab1-4d6e-8bc2-69fb0e2d101a?alt=media&token=95f06341-3012-42c1-9fc2-15d91b19987b"
      }
    },
    "description": "<p>Love it! Love it! It's my lucky star</p>",
    "tool": null,
    "order_number": 314,
    "order_id": 0,
    "id": "788560e8-cb40-4c32-9530-fb0363e73a23",
    "name": "Lucky Star"
  },
  {
    "description": "",
    "name": "Blank",
    "id": "7898030e-f643-4cfd-875f-1dc39341019c",
    "order_number": 811,
    "order_id": 0,
    "paper": {
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "original_size": "A7",
      "ref": "20170703",
      "gsm": "250",
      "price": 0.4
    }
  },
  {
    "name": "",
    "description": "",
    "order_number": 1222,
    "id": "78980987-83c1-4e6f-bea5-ac5f514c624f"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1106,
    "id": "78ebf225-0407-4893-8d63-a9497ffd3169"
  },
  {
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/78f9f96a-c05b-49c3-b7dc-7820c9dfbf9a/600x600/263c213b-c8cc-4e23-8feb-4b4107031613_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F78f9f96a-c05b-49c3-b7dc-7820c9dfbf9a%2F600x600%2F263c213b-c8cc-4e23-8feb-4b4107031613_600x600?alt=media&token=045b199f-0e1c-4b07-a8da-7b01867c75d2"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F78f9f96a-c05b-49c3-b7dc-7820c9dfbf9a%2F263c213b-c8cc-4e23-8feb-4b4107031613?alt=media&token=094e9440-3149-4baa-b20c-f3da1b6473a3",
        "reference": "/stroke_photos/78f9f96a-c05b-49c3-b7dc-7820c9dfbf9a/263c213b-c8cc-4e23-8feb-4b4107031613"
      }
    },
    "name": "unknown",
    "paper": {},
    "description": "missing",
    "order_number": 396,
    "id": "78f9f96a-c05b-49c3-b7dc-7820c9dfbf9a"
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F78fc1ffb-3ea0-4b72-a722-fec53ac81921%2F600x600%2F43df5a13-cc64-4bee-8642-89345da5e868_600x600?alt=media&token=b01e1731-f45b-4d54-94cc-fab96361f799",
        "reference": "stroke_photos/78fc1ffb-3ea0-4b72-a722-fec53ac81921/600x600/43df5a13-cc64-4bee-8642-89345da5e868_600x600"
      },
      "original": {
        "reference": "/stroke_photos/78fc1ffb-3ea0-4b72-a722-fec53ac81921/43df5a13-cc64-4bee-8642-89345da5e868",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F78fc1ffb-3ea0-4b72-a722-fec53ac81921%2F43df5a13-cc64-4bee-8642-89345da5e868?alt=media&token=1efe1a6f-84c6-4d68-a763-a77ca0905227"
      }
    },
    "name": "Silver-On-Black Regular 1",
    "description": "<p>Let's start with a standard one (!) okay. It looks thick and wide though</p>",
    "id": "78fc1ffb-3ea0-4b72-a722-fec53ac81921",
    "tool": null,
    "paper": {
      "gsm": null,
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "brand_model": {},
      "original_size": "A4",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "ref": null,
      "brand": {}
    },
    "order_number": 521,
    "order_id": 0
  },
  {
    "order_number": 636,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F790be55e-6767-484b-819f-55abddc4307c%2F600x600%2Fb894cb75-c92a-43ad-ab73-5db413ed1305_600x600?alt=media&token=b5363500-865b-4ea3-93fc-5964399f0e61",
        "reference": "stroke_photos/790be55e-6767-484b-819f-55abddc4307c/600x600/b894cb75-c92a-43ad-ab73-5db413ed1305_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F790be55e-6767-484b-819f-55abddc4307c%2Fb894cb75-c92a-43ad-ab73-5db413ed1305?alt=media&token=90a7079e-7ede-4d3f-808c-b08f915abbf4",
        "reference": "/stroke_photos/790be55e-6767-484b-819f-55abddc4307c/b894cb75-c92a-43ad-ab73-5db413ed1305"
      }
    },
    "description": "",
    "order_id": 0,
    "id": "790be55e-6767-484b-819f-55abddc4307c",
    "name": "Blank",
    "tool": null,
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand": {},
      "name": null,
      "brand_model": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "original_size": "A7",
      "ref": "70025010030",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl",
        "hex": "#f5f5f5"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "price": null,
      "gsm": "250"
    }
  },
  {
    "id": "7953c558-5bc7-460a-b6ed-963cdeca13c8",
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7953c558-5bc7-460a-b6ed-963cdeca13c8%2F380e390e-fa5e-43c0-8414-3cdf43229262?alt=media&token=ea6ca2ed-561a-46d3-8c6a-22652bfa96eb",
        "reference": "/stroke_photos/7953c558-5bc7-460a-b6ed-963cdeca13c8/380e390e-fa5e-43c0-8414-3cdf43229262"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/7953c558-5bc7-460a-b6ed-963cdeca13c8/600x600/380e390e-fa5e-43c0-8414-3cdf43229262_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7953c558-5bc7-460a-b6ed-963cdeca13c8%2F600x600%2F380e390e-fa5e-43c0-8414-3cdf43229262_600x600?alt=media&token=6a8927c7-4132-4f95-bb62-3e8e09bd607b"
      }
    },
    "description": "",
    "name": "Skeleton",
    "tool": null,
    "paper": {
      "name": null,
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20180107",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "original_size": "A7",
      "gsm": "250",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "order_number": 117
  },
  {
    "order_number": 1001,
    "name": "",
    "description": "",
    "id": "79755197-e408-45e7-81af-948d0bd37d21"
  },
  {
    "description": "",
    "order_number": 1023,
    "name": "",
    "id": "7988b42f-fa4d-4a39-8f4a-765403d079d6"
  },
  {
    "order_number": 428,
    "tool": null,
    "id": "7a0a60d3-6403-462e-b310-5b40ef506029",
    "paper": {
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "name": null,
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "A7",
      "ref": "77725080001",
      "price": 1.35,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/7a0a60d3-6403-462e-b310-5b40ef506029/147c41c6-eccc-470a-bf3c-465d2d79ac33",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7a0a60d3-6403-462e-b310-5b40ef506029%2F147c41c6-eccc-470a-bf3c-465d2d79ac33?alt=media&token=c643a0cb-3bde-477a-8759-ef3a5860d62b"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7a0a60d3-6403-462e-b310-5b40ef506029%2F600x600%2F147c41c6-eccc-470a-bf3c-465d2d79ac33_600x600?alt=media&token=433a86a4-9f5d-4a63-916b-551b75b28221",
        "reference": "stroke_photos/7a0a60d3-6403-462e-b310-5b40ef506029/600x600/147c41c6-eccc-470a-bf3c-465d2d79ac33_600x600"
      }
    },
    "name": "Just Something",
    "order_id": 0,
    "description": "What happens if I roll the brush in a wavy line? Weird nothing"
  },
  {
    "order_number": 1136,
    "description": "",
    "name": "",
    "id": "7a109ba9-8c91-480c-9eff-0126f9fe01e3"
  },
  {
    "name": "",
    "order_number": 1088,
    "description": "",
    "id": "7a3886db-a218-40b6-9467-4f7d8f61f2d5"
  },
  {
    "name": "PH No Reload",
    "description": "Same paint second attempt pressing hard. We see the whole in the middle again. Maybe time to abandon this strategy.",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/7a67dc7b-6cbe-408d-8334-962b8448d84c/600x600/c0963ec0-b77c-49f4-8f37-4317ec18442a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7a67dc7b-6cbe-408d-8334-962b8448d84c%2F600x600%2Fc0963ec0-b77c-49f4-8f37-4317ec18442a_600x600?alt=media&token=813b68b3-9071-4946-b4c0-d41d105619ca"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7a67dc7b-6cbe-408d-8334-962b8448d84c%2Fc0963ec0-b77c-49f4-8f37-4317ec18442a?alt=media&token=2b1e978a-0422-4093-9116-fa150c95d49f",
        "reference": "/stroke_photos/7a67dc7b-6cbe-408d-8334-962b8448d84c/c0963ec0-b77c-49f4-8f37-4317ec18442a"
      }
    },
    "id": "7a67dc7b-6cbe-408d-8334-962b8448d84c",
    "paper": {
      "original_size": "A4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "200",
      "ref": "44202129",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "price": null,
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "name": "",
      "paper_surface": {},
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      }
    },
    "tool": null,
    "order_number": 58,
    "order_id": 0
  },
  {
    "order_number": 401,
    "order_id": 0,
    "name": "Blank",
    "id": "7ab1d606-cb5b-4349-884b-9bdc89ed190b",
    "tool": null,
    "paper": {
      "original_size": "A7",
      "price": 1.35,
      "gsm": "250",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "ref": "77725080001",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      }
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7ab1d606-cb5b-4349-884b-9bdc89ed190b%2F600x600%2F11274c06-89e3-44f4-9779-5a3e46119e1f_600x600?alt=media&token=b8a38a0d-b0fa-40d3-8004-69b4a1066c83",
        "reference": "stroke_photos/7ab1d606-cb5b-4349-884b-9bdc89ed190b/600x600/11274c06-89e3-44f4-9779-5a3e46119e1f_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7ab1d606-cb5b-4349-884b-9bdc89ed190b%2F11274c06-89e3-44f4-9779-5a3e46119e1f?alt=media&token=3e81f526-6788-4de2-b56c-3693a059c087",
        "reference": "/stroke_photos/7ab1d606-cb5b-4349-884b-9bdc89ed190b/11274c06-89e3-44f4-9779-5a3e46119e1f"
      }
    },
    "description": ""
  },
  {
    "paper": {
      "original_size": "A4",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "brand": {},
      "brand_model": {},
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "ref": null,
      "name": null,
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      }
    },
    "name": "Silver-On-Black Heartbeat",
    "tool": null,
    "id": "7ab5d9b1-2ebe-475a-b631-ea5cf6f4b3f5",
    "order_id": 0,
    "order_number": 541,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7ab5d9b1-2ebe-475a-b631-ea5cf6f4b3f5%2Fa9175a57-1190-4e33-9bc9-ee643eef3c57?alt=media&token=2b430ef2-9de9-43b7-9302-8d7491093cf3",
        "reference": "/stroke_photos/7ab5d9b1-2ebe-475a-b631-ea5cf6f4b3f5/a9175a57-1190-4e33-9bc9-ee643eef3c57"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7ab5d9b1-2ebe-475a-b631-ea5cf6f4b3f5%2F600x600%2Fa9175a57-1190-4e33-9bc9-ee643eef3c57_600x600?alt=media&token=4079ce2c-39d7-4a34-99a7-9cdb2ea97529",
        "reference": "stroke_photos/7ab5d9b1-2ebe-475a-b631-ea5cf6f4b3f5/600x600/a9175a57-1190-4e33-9bc9-ee643eef3c57_600x600"
      }
    },
    "description": "<p>You know what would be awesome? A heartbeat in the middle of the stroke (!) Yeah! Heartsome!</p>"
  },
  {
    "order_number": 1078,
    "description": "",
    "name": "",
    "id": "7b4d32f1-09ab-426a-aeec-55bea80c707f"
  },
  {
    "order_number": 1103,
    "name": "",
    "description": "",
    "id": "7b6d42cc-dc34-4511-a3c1-030893f9506f"
  },
  {
    "order_number": 890,
    "name": "Blank",
    "id": "7b7bf989-50e2-4e83-be59-29d1710b7adf",
    "description": "",
    "order_id": 0,
    "paper": {
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "original_size": "A4",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": 1,
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "name": null,
      "ref": "4005329065767",
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "name": "Elephant Skin"
      },
      "gsm": "110",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      }
    }
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/7bae67bb-67ad-42d3-bd23-4dab88298c26/1bcc1b67-087b-40bb-956f-60c4785fde4c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7bae67bb-67ad-42d3-bd23-4dab88298c26%2F1bcc1b67-087b-40bb-956f-60c4785fde4c?alt=media&token=c7aab8e4-bc7d-40bd-9059-28a35fddd294"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/7bae67bb-67ad-42d3-bd23-4dab88298c26/600x600/1bcc1b67-087b-40bb-956f-60c4785fde4c_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7bae67bb-67ad-42d3-bd23-4dab88298c26%2F600x600%2F1bcc1b67-087b-40bb-956f-60c4785fde4c_600x600?alt=media&token=7f14ff83-b053-42db-a1e8-273d5db8a737"
      }
    },
    "paper": {
      "brand_model": {},
      "color": {
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "ref": "70025010030",
      "price": null,
      "gsm": "250",
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand": {}
    },
    "tool": null,
    "description": "",
    "id": "7bae67bb-67ad-42d3-bd23-4dab88298c26",
    "order_number": 598,
    "order_id": 0,
    "name": "Blank"
  },
  {
    "order_number": 1192,
    "description": "",
    "name": "",
    "id": "7bccfbd3-5416-4b66-a632-ea84cdd260f1"
  },
  {
    "order_number": 1099,
    "description": "",
    "name": "",
    "id": "7be562f9-66e0-4149-8440-5b6c5dc5a83c"
  },
  {
    "order_number": 1066,
    "name": "",
    "description": "",
    "id": "7be8bc44-d29f-4357-a226-c9953e0c7db8"
  },
  {
    "order_number": 1103,
    "name": "",
    "description": "",
    "id": "7bf11959-f487-4c7d-bf90-670072b02272"
  },
  {
    "order_number": 1072,
    "name": "",
    "description": "",
    "id": "7c07299a-a3c5-48ca-8e79-c816a5d9ec5a"
  },
  {
    "id": "7c089240-a2c0-4278-8c30-e0873b93c9d4",
    "order_id": 0,
    "name": "Blank",
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": 1,
      "brand_model": {
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        }
      },
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "ref": "4005329065767",
      "original_size": "A4",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "gsm": "110"
    },
    "order_number": 887,
    "description": ""
  },
  {
    "name": "Patchy 2",
    "order_number": 383,
    "description": "<p>More of that goodness, just not as good as the previous accidental one</p>",
    "order_id": 0,
    "id": "7c33b1ec-9774-458e-a7d1-21e651071c2b",
    "paper": {
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "name": null,
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "ref": "20180107",
      "gsm": "250",
      "brand": {},
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      }
    },
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/7c33b1ec-9774-458e-a7d1-21e651071c2b/75db3a20-ad14-4288-a2e0-7a9d839eda07",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7c33b1ec-9774-458e-a7d1-21e651071c2b%2F75db3a20-ad14-4288-a2e0-7a9d839eda07?alt=media&token=48092244-0156-4404-9d61-530dec3b003b"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7c33b1ec-9774-458e-a7d1-21e651071c2b%2F600x600%2F75db3a20-ad14-4288-a2e0-7a9d839eda07_600x600?alt=media&token=519ccec3-05a2-4da6-abfc-62461ed76558",
        "reference": "stroke_photos/7c33b1ec-9774-458e-a7d1-21e651071c2b/600x600/75db3a20-ad14-4288-a2e0-7a9d839eda07_600x600"
      }
    }
  },
  {
    "name": "",
    "description": "",
    "order_number": 1178,
    "id": "7c4d00c4-76d3-43ff-8d55-47c79557188b"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/7c61e707-194f-4d85-b557-ebd46c66effb/6c870057-6e3d-4f75-b3ae-10189c0b12c6",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7c61e707-194f-4d85-b557-ebd46c66effb%2F6c870057-6e3d-4f75-b3ae-10189c0b12c6?alt=media&token=d3cf5be4-92b5-4ca3-952a-be3b511b1471"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7c61e707-194f-4d85-b557-ebd46c66effb%2F600x600%2F6c870057-6e3d-4f75-b3ae-10189c0b12c6_600x600?alt=media&token=d2b3fdc5-2e4f-4bde-9247-7f52886aa3e5",
        "reference": "stroke_photos/7c61e707-194f-4d85-b557-ebd46c66effb/600x600/6c870057-6e3d-4f75-b3ae-10189c0b12c6_600x600"
      }
    },
    "paper": {
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "ref": "77725080001",
      "original_size": "A7",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "price": 1.35,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "gsm": "250",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      }
    },
    "name": "Blank",
    "order_id": 0,
    "order_number": 152,
    "description": "",
    "id": "7c61e707-194f-4d85-b557-ebd46c66effb",
    "tool": null
  },
  {
    "order_number": 1150,
    "description": "",
    "name": "",
    "id": "7c65a79a-9a32-41f8-af88-c14f64b56f3b"
  },
  {
    "tool": null,
    "name": "Sideways Bounce 3",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7c725fa8-45c6-49c9-871c-df84800317a1%2F600x600%2F9c10e3d4-fee6-4c90-9307-912d0e2d4346_600x600?alt=media&token=810c3100-d276-4113-8567-28160e4da234",
        "reference": "stroke_photos/7c725fa8-45c6-49c9-871c-df84800317a1/600x600/9c10e3d4-fee6-4c90-9307-912d0e2d4346_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7c725fa8-45c6-49c9-871c-df84800317a1%2F9c10e3d4-fee6-4c90-9307-912d0e2d4346?alt=media&token=c3585e21-8b9c-4d4b-89d9-9b7bd07364cf",
        "reference": "/stroke_photos/7c725fa8-45c6-49c9-871c-df84800317a1/9c10e3d4-fee6-4c90-9307-912d0e2d4346"
      }
    },
    "id": "7c725fa8-45c6-49c9-871c-df84800317a1",
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "20180107",
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "price": null,
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "order_number": 139,
    "description": ""
  },
  {
    "description": "",
    "name": "",
    "order_number": 1175,
    "id": "7c9fa413-2c13-4c80-8f2a-995d889f0ddf"
  },
  {
    "order_number": 1097,
    "description": "",
    "name": "",
    "id": "7cab8298-6a33-438d-8043-d63a92ac6317"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/7cc11e80-88ef-4f35-bd96-9ad90c7f0a8a/333c1ebd-b3d0-466d-a34e-9ba3dcce1ef0",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7cc11e80-88ef-4f35-bd96-9ad90c7f0a8a%2F333c1ebd-b3d0-466d-a34e-9ba3dcce1ef0?alt=media&token=cbc47c2c-64b7-40aa-8cbe-174cda6990a0"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7cc11e80-88ef-4f35-bd96-9ad90c7f0a8a%2F600x600%2F333c1ebd-b3d0-466d-a34e-9ba3dcce1ef0_600x600?alt=media&token=81e686fb-4d1a-4ffd-912e-7926b3cbdfb0",
        "reference": "stroke_photos/7cc11e80-88ef-4f35-bd96-9ad90c7f0a8a/600x600/333c1ebd-b3d0-466d-a34e-9ba3dcce1ef0_600x600"
      }
    },
    "name": "Tons Of Paint!",
    "order_id": 0,
    "order_number": 410,
    "id": "7cc11e80-88ef-4f35-bd96-9ad90c7f0a8a",
    "paper": {
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "gsm": "250",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "price": 1.35,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "ref": "77725080001"
    },
    "tool": null,
    "description": "Tried to put as much paint as possible. Surprisingly, there is still a coarse gradient at the end"
  },
  {
    "order_number": 369,
    "tool": null,
    "id": "7ccdafc6-677f-4a7f-ad89-d18069bc9b3f",
    "description": "<p>So I thought black and silver would be interesting to combine and have one single stroke</p>",
    "paper": {
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "ref": "20180107",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "original_size": "A7",
      "brand": {},
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "price": null,
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7ccdafc6-677f-4a7f-ad89-d18069bc9b3f%2Fe009c08d-d090-4612-b471-7ea0f31147fc?alt=media&token=8ca234b5-529c-4dad-8d13-a94c8cebca39",
        "reference": "/stroke_photos/7ccdafc6-677f-4a7f-ad89-d18069bc9b3f/e009c08d-d090-4612-b471-7ea0f31147fc"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7ccdafc6-677f-4a7f-ad89-d18069bc9b3f%2F600x600%2Fe009c08d-d090-4612-b471-7ea0f31147fc_600x600?alt=media&token=1fcfa2dc-e6f9-470b-b905-5fe4aaa0cedc",
        "reference": "stroke_photos/7ccdafc6-677f-4a7f-ad89-d18069bc9b3f/600x600/e009c08d-d090-4612-b471-7ea0f31147fc_600x600"
      }
    },
    "name": "Double power",
    "order_id": 0
  },
  {
    "description": "",
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "ref": null,
      "brand": {},
      "brand_model": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": null,
      "price": null
    },
    "order_number": 971,
    "id": "7cebf3a9-af18-4c2a-926c-0125deb94145",
    "order_id": 0,
    "name": "Blank"
  },
  {
    "id": "7d2e7d1b-bbd7-492f-820d-99d49897e100",
    "name": "Taller White Hat",
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "original_size": "A4",
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "gsm": null,
      "name": null,
      "brand_model": {},
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": null,
      "brand": {},
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "order_number": 479,
    "photos": {
      "original": {
        "reference": "/stroke_photos/7d2e7d1b-bbd7-492f-820d-99d49897e100/cf580c00-1633-4ea4-b0f9-055a3888208e",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7d2e7d1b-bbd7-492f-820d-99d49897e100%2Fcf580c00-1633-4ea4-b0f9-055a3888208e?alt=media&token=cb5168ae-a1cf-4a41-96de-62bf85ce1a4a"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7d2e7d1b-bbd7-492f-820d-99d49897e100%2F600x600%2Fcf580c00-1633-4ea4-b0f9-055a3888208e_600x600?alt=media&token=4396a777-02db-463b-8c7d-477e819f753c",
        "reference": "stroke_photos/7d2e7d1b-bbd7-492f-820d-99d49897e100/600x600/cf580c00-1633-4ea4-b0f9-055a3888208e_600x600"
      }
    },
    "tool": null,
    "order_id": 0,
    "description": "<p>Not much to say</p>"
  },
  {
    "id": "7dc5fdab-b016-4ef7-9426-d639d1a7aff8",
    "order_id": 0,
    "purchase": {
      "buyer": {
        "firstName": "Mira",
        "lastName": "Batchvarova",
        "email": "mira.batchvarova@gmail.com"
      },
      "date": {
        "seconds": 1677878315,
        "nanoseconds": 151000000
      },
      "internalDescription": "",
      "anonymous": false,
      "status": "bought"
    },
    "name": "Fast Stroke At The End Only",
    "description": "",
    "paper": {
      "gsm": "200",
      "paper_surface": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "price": null,
      "name": "",
      "ref": "44202129",
      "original_size": "A4"
    },
    "tool": null,
    "order_number": 33,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/7dc5fdab-b016-4ef7-9426-d639d1a7aff8/600x600/36af53ef-4035-482d-bb79-75df097de0b2_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7dc5fdab-b016-4ef7-9426-d639d1a7aff8%2F600x600%2F36af53ef-4035-482d-bb79-75df097de0b2_600x600?alt=media&token=d8f3654c-f038-4bcf-92b0-c66d1eeb2c39"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7dc5fdab-b016-4ef7-9426-d639d1a7aff8%2F36af53ef-4035-482d-bb79-75df097de0b2?alt=media&token=2468a53d-73fd-43e0-b466-df018c7642b9",
        "reference": "/stroke_photos/7dc5fdab-b016-4ef7-9426-d639d1a7aff8/36af53ef-4035-482d-bb79-75df097de0b2"
      }
    }
  },
  {
    "name": "Blank",
    "order_id": 0,
    "order_number": 803,
    "paper": {
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": 0.4,
      "ref": "20170703",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A7",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250"
    },
    "id": "7dfa79ad-5b49-49da-a6a2-39bb4b0f200f",
    "description": ""
  },
  {
    "description": "",
    "name": "Blank",
    "id": "7e240e76-7f22-4b67-af7e-073b6cf21441",
    "order_number": 808,
    "paper": {
      "ref": "20170703",
      "name": null,
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand": {},
      "price": 0.4,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A7",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "order_id": 0
  },
  {
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/7e3a0a57-71dc-44c5-80dc-85f276feb80b/600x600/3d5dc9ac-6eaa-467a-a75f-a2653ad96e3d_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7e3a0a57-71dc-44c5-80dc-85f276feb80b%2F600x600%2F3d5dc9ac-6eaa-467a-a75f-a2653ad96e3d_600x600?alt=media&token=c6b11b06-d46d-4d66-bedf-b705d7c7f930"
      },
      "original": {
        "reference": "/stroke_photos/7e3a0a57-71dc-44c5-80dc-85f276feb80b/3d5dc9ac-6eaa-467a-a75f-a2653ad96e3d",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7e3a0a57-71dc-44c5-80dc-85f276feb80b%2F3d5dc9ac-6eaa-467a-a75f-a2653ad96e3d?alt=media&token=5f111f60-002d-493f-8d5d-3ef66fff54a2"
      }
    },
    "name": "Another White Zig-Zag",
    "id": "7e3a0a57-71dc-44c5-80dc-85f276feb80b",
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": null,
      "brand_model": {},
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "name": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "gsm": null,
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand": {}
    },
    "order_id": 0,
    "description": "<p>Yes, a bit more even, but with 1 corner less which still looks decent</p>",
    "order_number": 481,
    "tool": null
  },
  {
    "id": "7eae402c-399a-4d04-a4da-71d6db5c4ff0",
    "description": "",
    "order_number": 845,
    "order_id": 0,
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "ref": "70025010030",
      "brand": {},
      "original_size": "A7",
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {},
      "name": null,
      "gsm": "250"
    },
    "name": "Blank"
  },
  {
    "order_number": 1098,
    "name": "",
    "description": "",
    "id": "7eb85ceb-a19d-497f-9da8-e84dc682dbea"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1133,
    "id": "7f015bc2-4361-43dd-bff4-16953f39f0b1"
  },
  {
    "description": "",
    "order_number": 1155,
    "name": "",
    "id": "7f4f3994-d636-40d3-bb10-1206b3f18822"
  },
  {
    "description": "<p>I will try to do the same, but almost horizontal brush (!) Curious. Now I got the fine mosaic effect with little holes</p>",
    "order_id": 0,
    "name": "Horizontal Brush White",
    "photos": {
      "original": {
        "reference": "/stroke_photos/7f745d55-373f-43b7-8871-dbbf99e00af8/0dd2ad13-d27e-4be9-8e00-5b6b83878539",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7f745d55-373f-43b7-8871-dbbf99e00af8%2F0dd2ad13-d27e-4be9-8e00-5b6b83878539?alt=media&token=eb6c99d6-727a-48cf-9e69-a501b5257238"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/7f745d55-373f-43b7-8871-dbbf99e00af8/600x600/0dd2ad13-d27e-4be9-8e00-5b6b83878539_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F7f745d55-373f-43b7-8871-dbbf99e00af8%2F600x600%2F0dd2ad13-d27e-4be9-8e00-5b6b83878539_600x600?alt=media&token=9f998570-56af-4b7f-852e-697a4a308911"
      }
    },
    "tool": null,
    "order_number": 503,
    "id": "7f745d55-373f-43b7-8871-dbbf99e00af8",
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "brand": {},
      "name": null,
      "brand_model": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "original_size": "A4",
      "gsm": null,
      "ref": null
    }
  },
  {
    "order_number": 1079,
    "name": "",
    "description": "",
    "id": "7f85416f-fe54-4cd4-bc44-4b9db50dc087"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1025,
    "id": "7f96d72e-e956-48f7-bccd-3e36f5158b59"
  },
  {
    "id": "804a5e74-16c4-48c7-8ba5-ad08530abfa2",
    "name": "Silver-Black Spin 2",
    "tool": null,
    "order_id": 0,
    "description": "<p>Much better. I really like it, it is like 3D almost</p>",
    "order_number": 375,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F804a5e74-16c4-48c7-8ba5-ad08530abfa2%2F600x600%2Fd987025d-1bac-4781-b1e9-f82c43a92cef_600x600?alt=media&token=1c6d7a54-bff2-4d0c-8adf-e15ab8b15d69",
        "reference": "stroke_photos/804a5e74-16c4-48c7-8ba5-ad08530abfa2/600x600/d987025d-1bac-4781-b1e9-f82c43a92cef_600x600"
      },
      "original": {
        "reference": "/stroke_photos/804a5e74-16c4-48c7-8ba5-ad08530abfa2/d987025d-1bac-4781-b1e9-f82c43a92cef",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F804a5e74-16c4-48c7-8ba5-ad08530abfa2%2Fd987025d-1bac-4781-b1e9-f82c43a92cef?alt=media&token=a883a020-a0b9-40cf-b2a0-366d04c3be04"
      }
    },
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "price": null,
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "name": null
    }
  },
  {
    "tool": null,
    "name": "White Splodge Touch 3",
    "description": "<p>And another splodge</p>",
    "paper": {
      "ref": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "brand_model": {},
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {}
    },
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/8055c33b-dea2-4da4-ac51-af7bbd2b837b/600x600/61c61ae4-8f89-41f6-938a-39d6684585a4_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8055c33b-dea2-4da4-ac51-af7bbd2b837b%2F600x600%2F61c61ae4-8f89-41f6-938a-39d6684585a4_600x600?alt=media&token=fcf7660e-4522-47e8-a4a5-c4c3afd31d4e"
      },
      "original": {
        "reference": "/stroke_photos/8055c33b-dea2-4da4-ac51-af7bbd2b837b/61c61ae4-8f89-41f6-938a-39d6684585a4",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8055c33b-dea2-4da4-ac51-af7bbd2b837b%2F61c61ae4-8f89-41f6-938a-39d6684585a4?alt=media&token=d531cc86-4222-4210-a964-5dd5b0752c4e"
      }
    },
    "order_number": 513,
    "id": "8055c33b-dea2-4da4-ac51-af7bbd2b837b"
  },
  {
    "order_number": 1169,
    "name": "",
    "description": "",
    "id": "80613db1-8073-46bf-ab51-f565d67721df"
  },
  {
    "paper": {
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "20180107",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "brand": {},
      "original_size": "A7",
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250"
    },
    "description": "",
    "id": "806b61ad-70f4-4a36-a9f5-88f190c7bcdc",
    "tool": null,
    "name": "Disappointment, Just Larger",
    "order_id": 0,
    "order_number": 255,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F806b61ad-70f4-4a36-a9f5-88f190c7bcdc%2F600x600%2F6a5ba948-f1bf-4bf1-b718-c4446e1b8b37_600x600?alt=media&token=45d55af5-eaec-42e5-87ac-db9b11ac3588",
        "reference": "stroke_photos/806b61ad-70f4-4a36-a9f5-88f190c7bcdc/600x600/6a5ba948-f1bf-4bf1-b718-c4446e1b8b37_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F806b61ad-70f4-4a36-a9f5-88f190c7bcdc%2F6a5ba948-f1bf-4bf1-b718-c4446e1b8b37?alt=media&token=5315efb5-08ad-4e12-b765-ef8497a24e76",
        "reference": "/stroke_photos/806b61ad-70f4-4a36-a9f5-88f190c7bcdc/6a5ba948-f1bf-4bf1-b718-c4446e1b8b37"
      }
    }
  },
  {
    "description": "",
    "order_number": 1094,
    "name": "",
    "id": "806c4343-f637-468b-b4bd-0c28ae66f825"
  },
  {
    "description": "",
    "order_number": 1163,
    "name": "",
    "id": "806d547c-a2a4-4667-8ec2-f7d627ab05a1"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1084,
    "id": "8072eadd-7152-4cd4-b1d9-e8ba40b9eeef"
  },
  {
    "id": "8170c24c-1d30-487c-a613-1b004f04f295",
    "paper": {
      "brand_model": {
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "original_size": "75x105cm",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": 19.3,
      "gsm": "640",
      "name": "tinto",
      "ref": "30075105",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      }
    },
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8170c24c-1d30-487c-a613-1b004f04f295%2F600x600%2F60316626-d095-4538-aa9b-3f81eb5d4fdd_600x600?alt=media&token=20eae77f-078e-42a7-ad6e-de2560fa3142",
        "reference": "stroke_photos/8170c24c-1d30-487c-a613-1b004f04f295/600x600/60316626-d095-4538-aa9b-3f81eb5d4fdd_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8170c24c-1d30-487c-a613-1b004f04f295%2F60316626-d095-4538-aa9b-3f81eb5d4fdd?alt=media&token=90f448b7-fd96-4729-892e-ca0b185dd4d2",
        "reference": "/stroke_photos/8170c24c-1d30-487c-a613-1b004f04f295/60316626-d095-4538-aa9b-3f81eb5d4fdd"
      }
    },
    "order_number": 691,
    "tool": null,
    "order_id": 0,
    "description": ""
  },
  {
    "description": "",
    "name": "Blank",
    "order_number": 949,
    "id": "8202092a-fa91-45c7-ac0f-3f9074196688",
    "paper": {
      "brand": {},
      "original_size": "A4",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "gsm": null,
      "ref": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {}
    },
    "order_id": 0
  },
  {
    "name": "",
    "description": "",
    "order_number": 1082,
    "id": "824d5c00-733f-4a7e-91bc-5ece08348245"
  },
  {
    "tool": null,
    "id": "82664304-eb7a-4a4e-a751-ad8fa93a04cf",
    "order_number": 289,
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "price": null,
      "ref": "20180107",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "gsm": "250",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A7",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "name": null
    },
    "order_id": 0,
    "name": "Dad",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F82664304-eb7a-4a4e-a751-ad8fa93a04cf%2F600x600%2F4baaa067-cf15-4430-a2d6-f887280883bb_600x600?alt=media&token=da24e975-9901-4c30-a919-9867d277e992",
        "reference": "stroke_photos/82664304-eb7a-4a4e-a751-ad8fa93a04cf/600x600/4baaa067-cf15-4430-a2d6-f887280883bb_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F82664304-eb7a-4a4e-a751-ad8fa93a04cf%2F4baaa067-cf15-4430-a2d6-f887280883bb?alt=media&token=4b4efb86-98e7-4893-bb57-1bdbc750ce82",
        "reference": "/stroke_photos/82664304-eb7a-4a4e-a751-ad8fa93a04cf/4baaa067-cf15-4430-a2d6-f887280883bb"
      }
    },
    "description": "<p>... Who clearly doesn't have a clue about things</p>"
  },
  {
    "name": "Blank",
    "order_id": 0,
    "order_number": 770,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/8289942b-5a40-41a4-b5d4-d7aab0a58813/600x600/ad60931e-48d3-4431-b0fd-cd03e3d7d952_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8289942b-5a40-41a4-b5d4-d7aab0a58813%2F600x600%2Fad60931e-48d3-4431-b0fd-cd03e3d7d952_600x600?alt=media&token=b1a9495f-1caa-4585-b694-daafb620a962"
      },
      "original": {
        "reference": "/stroke_photos/8289942b-5a40-41a4-b5d4-d7aab0a58813/ad60931e-48d3-4431-b0fd-cd03e3d7d952",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8289942b-5a40-41a4-b5d4-d7aab0a58813%2Fad60931e-48d3-4431-b0fd-cd03e3d7d952?alt=media&token=5e883afd-e2b9-4794-823f-643d14dd9dfe"
      }
    },
    "description": "",
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "price": 1.35,
      "name": null,
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "ref": "77725080001",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "original_size": "A7",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      }
    },
    "tool": null,
    "id": "8289942b-5a40-41a4-b5d4-d7aab0a58813"
  },
  {
    "name": "Just the Beginning",
    "order_id": 0,
    "id": "82b47447-5330-4db2-bee4-913c0adeb537",
    "tool": null,
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "brand": {},
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "ref": "20180107",
      "original_size": "A7",
      "name": null,
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86"
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/82b47447-5330-4db2-bee4-913c0adeb537/600x600/2527a22a-828c-45c9-ad34-af110916770a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F82b47447-5330-4db2-bee4-913c0adeb537%2F600x600%2F2527a22a-828c-45c9-ad34-af110916770a_600x600?alt=media&token=264ac923-0e89-49ae-8702-1273fff560a8"
      },
      "original": {
        "reference": "/stroke_photos/82b47447-5330-4db2-bee4-913c0adeb537/2527a22a-828c-45c9-ad34-af110916770a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F82b47447-5330-4db2-bee4-913c0adeb537%2F2527a22a-828c-45c9-ad34-af110916770a?alt=media&token=02445808-f874-4f3f-9acb-4decc34cec5b"
      }
    },
    "description": "<p>What if I did just the beginning of a stroke?</p>",
    "order_number": 389
  },
  {
    "description": "",
    "order_number": 1024,
    "name": "",
    "id": "82f27735-e2bd-4b96-971b-66de4a450d73"
  },
  {
    "order_id": 0,
    "tool": null,
    "paper": {
      "name": "tinto",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "price": 19.3,
      "gsm": "640",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "30075105",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "original_size": "75x105cm",
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      }
    },
    "description": "",
    "order_number": 695,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/83145768-d9ca-465d-9f6b-3a26a58ddc4f/600x600/ebbf2527-e670-4422-9bf6-c95e99654530_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F83145768-d9ca-465d-9f6b-3a26a58ddc4f%2F600x600%2Febbf2527-e670-4422-9bf6-c95e99654530_600x600?alt=media&token=d32a9ce6-33ab-441c-a50c-3b6f5b961dcb"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F83145768-d9ca-465d-9f6b-3a26a58ddc4f%2Febbf2527-e670-4422-9bf6-c95e99654530?alt=media&token=6cb218cc-8688-4f46-919e-ff5c9c3971b4",
        "reference": "/stroke_photos/83145768-d9ca-465d-9f6b-3a26a58ddc4f/ebbf2527-e670-4422-9bf6-c95e99654530"
      }
    },
    "name": "Blank",
    "id": "83145768-d9ca-465d-9f6b-3a26a58ddc4f"
  },
  {
    "order_id": 0,
    "order_number": 111,
    "id": "8333a657-0a3e-4cca-9509-f892af50ac6e",
    "paper": {
      "price": null,
      "gsm": "250",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "ref": "20180107",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {}
    },
    "tool": null,
    "name": "Attempt for Smoothness That Didn't Work",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/8333a657-0a3e-4cca-9509-f892af50ac6e/600x600/ef919bc6-b10a-4872-8be1-fa29915a1ffb_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8333a657-0a3e-4cca-9509-f892af50ac6e%2F600x600%2Fef919bc6-b10a-4872-8be1-fa29915a1ffb_600x600?alt=media&token=90f20977-c244-4d03-908f-4a10b4c59ad2"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8333a657-0a3e-4cca-9509-f892af50ac6e%2Fef919bc6-b10a-4872-8be1-fa29915a1ffb?alt=media&token=ca2d1c27-6cfc-47b7-b8e7-615019b906d7",
        "reference": "/stroke_photos/8333a657-0a3e-4cca-9509-f892af50ac6e/ef919bc6-b10a-4872-8be1-fa29915a1ffb"
      }
    },
    "description": ""
  },
  {
    "name": "",
    "order_number": 1159,
    "description": "",
    "id": "8395955a-58a6-448e-bbda-0f1c56c03006"
  },
  {
    "order_number": 932,
    "name": "Blank",
    "description": "",
    "paper": {
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "ref": null,
      "brand_model": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "gsm": null,
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      }
    },
    "id": "83bed1b1-7452-4105-b2b0-fb30ae1998ec",
    "order_id": 0
  },
  {
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/83e8a2da-4f8d-4ed7-a5bb-49cfff9cc7af/600x600/64491a0c-897b-44e9-b9b9-495104c16680_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F83e8a2da-4f8d-4ed7-a5bb-49cfff9cc7af%2F600x600%2F64491a0c-897b-44e9-b9b9-495104c16680_600x600?alt=media&token=aa84009d-c8c6-4508-8d8f-9fcd166f603e"
      },
      "original": {
        "reference": "/stroke_photos/83e8a2da-4f8d-4ed7-a5bb-49cfff9cc7af/64491a0c-897b-44e9-b9b9-495104c16680",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F83e8a2da-4f8d-4ed7-a5bb-49cfff9cc7af%2F64491a0c-897b-44e9-b9b9-495104c16680?alt=media&token=288c58d3-5abc-4b1b-9398-adb42cfbf2b8"
      }
    },
    "id": "83e8a2da-4f8d-4ed7-a5bb-49cfff9cc7af",
    "description": "",
    "tool": null,
    "order_id": 0,
    "name": "WOE HOT 7",
    "order_number": 92,
    "paper": {
      "original_size": "A4",
      "gsm": "200",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Accademia Designo"
      },
      "name": "",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "44202129",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_surface": {},
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "price": null
    }
  },
  {
    "tool": null,
    "order_number": 459,
    "description": "<p>Not solid, not with a gradient, not with a tail... Just a not version</p>",
    "id": "84304d88-1467-4f6d-8a0f-cd8d2481c032",
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": null,
      "original_size": "A4",
      "gsm": null,
      "price": null,
      "brand": {},
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {}
    },
    "order_id": 0,
    "name": "Straight White Line",
    "photos": {
      "original": {
        "reference": "/stroke_photos/84304d88-1467-4f6d-8a0f-cd8d2481c032/fd0549fc-64a9-47d0-aee1-cbbb8c1ae29d",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F84304d88-1467-4f6d-8a0f-cd8d2481c032%2Ffd0549fc-64a9-47d0-aee1-cbbb8c1ae29d?alt=media&token=35d3afc7-9e0f-4f80-bf78-97bb8915f84e"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F84304d88-1467-4f6d-8a0f-cd8d2481c032%2F600x600%2Ffd0549fc-64a9-47d0-aee1-cbbb8c1ae29d_600x600?alt=media&token=88670b92-fd45-4cdf-b9a8-c7ffd326efee",
        "reference": "stroke_photos/84304d88-1467-4f6d-8a0f-cd8d2481c032/600x600/fd0549fc-64a9-47d0-aee1-cbbb8c1ae29d_600x600"
      }
    }
  },
  {
    "id": "84384a3f-3fd3-480f-a274-c9efb171eb58",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F84384a3f-3fd3-480f-a274-c9efb171eb58%2Fbe53e86a-f898-42bf-a1cb-666276723c6d?alt=media&token=0858cc3c-2408-49ff-8e88-100a8aca0e7a",
        "reference": "/stroke_photos/84384a3f-3fd3-480f-a274-c9efb171eb58/be53e86a-f898-42bf-a1cb-666276723c6d"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F84384a3f-3fd3-480f-a274-c9efb171eb58%2F600x600%2Fbe53e86a-f898-42bf-a1cb-666276723c6d_600x600?alt=media&token=16ea1c07-69ae-484e-ad61-c5fb47f04ec8",
        "reference": "stroke_photos/84384a3f-3fd3-480f-a274-c9efb171eb58/600x600/be53e86a-f898-42bf-a1cb-666276723c6d_600x600"
      }
    },
    "description": "",
    "name": "Two and a Half",
    "tool": null,
    "paper": {
      "name": null,
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "ref": "20180107",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "price": null
    },
    "order_number": 121,
    "order_id": 0
  },
  {
    "name": "Blank",
    "id": "84403e74-b1c2-45d4-b30c-a5a2d57fb597",
    "paper": {
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "ref": null,
      "name": null,
      "gsm": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {},
      "original_size": "A4",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {}
    },
    "description": "",
    "order_id": 0,
    "order_number": 960
  },
  {
    "order_number": 939,
    "id": "8459e478-a81f-48c6-b978-9bb28baf101b",
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": null,
      "original_size": "A4",
      "price": null,
      "brand": {},
      "brand_model": {},
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": null
    },
    "name": "Blank",
    "description": "",
    "order_id": 0
  },
  {
    "id": "845a53bb-bccb-44f1-b6f4-e2b716268275",
    "order_number": 514,
    "paper": {
      "original_size": "A4",
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {},
      "name": null,
      "price": null,
      "gsm": null,
      "brand": {},
      "ref": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "description": "<p>one more</p>",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F845a53bb-bccb-44f1-b6f4-e2b716268275%2F600x600%2F00ac7442-b6e5-4661-9a34-24ce2dad5e3a_600x600?alt=media&token=fd907712-bde1-4ca7-85fc-19078cfab163",
        "reference": "stroke_photos/845a53bb-bccb-44f1-b6f4-e2b716268275/600x600/00ac7442-b6e5-4661-9a34-24ce2dad5e3a_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F845a53bb-bccb-44f1-b6f4-e2b716268275%2F00ac7442-b6e5-4661-9a34-24ce2dad5e3a?alt=media&token=e87ff0b3-06cf-48b3-86a4-35e8c9d04650",
        "reference": "/stroke_photos/845a53bb-bccb-44f1-b6f4-e2b716268275/00ac7442-b6e5-4661-9a34-24ce2dad5e3a"
      }
    },
    "name": "White Splodge Touch 4",
    "tool": null,
    "order_id": 0
  },
  {
    "name": "",
    "order_number": 1130,
    "description": "",
    "id": "846b0653-e427-42c0-a15b-89d5b69e7577"
  },
  {
    "tool": null,
    "name": "SF Fast Stroke",
    "description": "",
    "order_id": 0,
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "ref": "44202129",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "200",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "price": null,
      "paper_surface": {},
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "name": "",
      "original_size": "A4"
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8490d4ec-f246-4c48-a2df-0fe27f514abb%2F96416b39-f432-4bec-b0d2-9063af98c3ea?alt=media&token=12e3b88c-059f-4038-8ab2-0d42b02d3a06",
        "reference": "/stroke_photos/8490d4ec-f246-4c48-a2df-0fe27f514abb/96416b39-f432-4bec-b0d2-9063af98c3ea"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8490d4ec-f246-4c48-a2df-0fe27f514abb%2F600x600%2F96416b39-f432-4bec-b0d2-9063af98c3ea_600x600?alt=media&token=6481f7bc-0d94-453c-b66f-c2568602f612",
        "reference": "stroke_photos/8490d4ec-f246-4c48-a2df-0fe27f514abb/600x600/96416b39-f432-4bec-b0d2-9063af98c3ea_600x600"
      }
    },
    "order_number": 68,
    "id": "8490d4ec-f246-4c48-a2df-0fe27f514abb"
  },
  {
    "order_number": 23,
    "id": "84abb677-a781-400f-8ad2-0a5443f32b21",
    "order_id": 0,
    "name": "Popf Vertical Brush",
    "description": "",
    "tool": {},
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/84abb677-a781-400f-8ad2-0a5443f32b21/600x600/2295f378-cbfa-471a-87f7-3160a1b3812e_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F84abb677-a781-400f-8ad2-0a5443f32b21%2F600x600%2F2295f378-cbfa-471a-87f7-3160a1b3812e_600x600?alt=media&token=4dec3901-b974-448e-b2f7-97df428bc26e"
      },
      "original": {
        "reference": "/stroke_photos/84abb677-a781-400f-8ad2-0a5443f32b21/2295f378-cbfa-471a-87f7-3160a1b3812e",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F84abb677-a781-400f-8ad2-0a5443f32b21%2F2295f378-cbfa-471a-87f7-3160a1b3812e?alt=media&token=484cc601-f8ab-4b1a-8d8d-f8b45cae2a1f"
      }
    },
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "price": null,
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "gsm": "200",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "name": "",
      "original_size": "A4",
      "paper_surface": {},
      "ref": "44202129",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      }
    }
  },
  {
    "description": "",
    "order_id": 0,
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F84ba0da0-92f2-4ce4-a552-62954c4133e6%2Ff3ccb144-d11d-40ba-8920-61c45515a160?alt=media&token=a56e153b-4f02-424f-9cee-91fa92941a91",
        "reference": "/stroke_photos/84ba0da0-92f2-4ce4-a552-62954c4133e6/f3ccb144-d11d-40ba-8920-61c45515a160"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F84ba0da0-92f2-4ce4-a552-62954c4133e6%2F600x600%2Ff3ccb144-d11d-40ba-8920-61c45515a160_600x600?alt=media&token=3b8693a0-1ee2-4fd8-ad32-f0b66655bfe7",
        "reference": "stroke_photos/84ba0da0-92f2-4ce4-a552-62954c4133e6/600x600/f3ccb144-d11d-40ba-8920-61c45515a160_600x600"
      }
    },
    "order_number": 405,
    "paper": {
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "price": 1.35,
      "ref": "77725080001",
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "original_size": "A7"
    },
    "name": "Blank",
    "id": "84ba0da0-92f2-4ce4-a552-62954c4133e6"
  },
  {
    "description": "",
    "order_number": 1142,
    "name": "",
    "id": "84c15da3-752a-4b22-a067-3d55313fae34"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1075,
    "id": "84d09703-452b-489f-ba65-37f9d5fd1770"
  },
  {
    "id": "84d7790e-4415-4d11-995b-fe0fcc88ba2d",
    "description": "",
    "tool": null,
    "order_id": 0,
    "paper": {
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "price": 1.35,
      "ref": "77725080001",
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "original_size": "A7",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    },
    "order_number": 164,
    "name": "Blank",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F84d7790e-4415-4d11-995b-fe0fcc88ba2d%2F2c0300b9-a2cb-455d-a978-fbc2923dbc1f?alt=media&token=b383722e-7444-4456-a897-8834117529c0",
        "reference": "/stroke_photos/84d7790e-4415-4d11-995b-fe0fcc88ba2d/2c0300b9-a2cb-455d-a978-fbc2923dbc1f"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F84d7790e-4415-4d11-995b-fe0fcc88ba2d%2F600x600%2F2c0300b9-a2cb-455d-a978-fbc2923dbc1f_600x600?alt=media&token=1ecc87d7-1f72-4712-a28b-baa0c7c77214",
        "reference": "stroke_photos/84d7790e-4415-4d11-995b-fe0fcc88ba2d/600x600/2c0300b9-a2cb-455d-a978-fbc2923dbc1f_600x600"
      }
    }
  },
  {
    "id": "84f43406-703f-423b-85bc-8065bc8d6345",
    "tool": null,
    "description": "",
    "name": "Fear",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/84f43406-703f-423b-85bc-8065bc8d6345/600x600/25ed17eb-cc52-4e7a-8f1f-a3deb6d0a5ba_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F84f43406-703f-423b-85bc-8065bc8d6345%2F600x600%2F25ed17eb-cc52-4e7a-8f1f-a3deb6d0a5ba_600x600?alt=media&token=4a8e9402-d4c0-463c-bce0-574db7ee1d53"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F84f43406-703f-423b-85bc-8065bc8d6345%2F25ed17eb-cc52-4e7a-8f1f-a3deb6d0a5ba?alt=media&token=de09db63-82eb-4bbd-bb36-b49b650397fe",
        "reference": "/stroke_photos/84f43406-703f-423b-85bc-8065bc8d6345/25ed17eb-cc52-4e7a-8f1f-a3deb6d0a5ba"
      }
    },
    "order_number": 197,
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "price": 1.35,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "gsm": "250",
      "ref": "77725080001",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "original_size": "A7",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      }
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1217,
    "id": "85369010-cfc7-4d0c-94e0-9a134fe633e1"
  },
  {
    "order_number": 26,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8558fca5-3d31-4ebd-86cc-c27b7dfcd86b%2Fbbf98ae1-7754-470b-b82a-ef305afe9b03?alt=media&token=2075fe4c-764d-481e-a467-2ff52519bec2",
        "reference": "/stroke_photos/8558fca5-3d31-4ebd-86cc-c27b7dfcd86b/bbf98ae1-7754-470b-b82a-ef305afe9b03"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8558fca5-3d31-4ebd-86cc-c27b7dfcd86b%2F600x600%2Fbbf98ae1-7754-470b-b82a-ef305afe9b03_600x600?alt=media&token=da918262-cc8c-4f33-b11b-afec400a8bb3",
        "reference": "stroke_photos/8558fca5-3d31-4ebd-86cc-c27b7dfcd86b/600x600/bbf98ae1-7754-470b-b82a-ef305afe9b03_600x600"
      }
    },
    "name": "Popf Low Brush Less Paint",
    "description": "",
    "paper": {
      "ref": "44202129",
      "original_size": "A4",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo"
      },
      "paper_surface": {},
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "price": null,
      "name": "",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "200"
    },
    "order_id": 0,
    "purchase": {
      "anonymous": false,
      "date": {
        "seconds": 1677877969,
        "nanoseconds": 43000000
      },
      "buyer": {
        "firstName": "Dilyan",
        "lastName": "Dimitrov",
        "email": "dilyan.dimitrov@gmail.com"
      },
      "status": "bought",
      "internalDescription": ""
    },
    "id": "8558fca5-3d31-4ebd-86cc-c27b7dfcd86b",
    "tool": {}
  },
  {
    "order_number": 999,
    "order_id": 0,
    "name": "Blank",
    "description": "",
    "id": "855c4922-f41e-4320-aa1e-7a24580693fb",
    "paper": {
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "name": "tinto",
      "ref": "30075105",
      "original_size": "75x105cm",
      "price": 19.3,
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "gsm": "640",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico"
      }
    }
  },
  {
    "id": "857a6187-38f6-4669-923f-268301b5c3e5",
    "order_number": 170,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/857a6187-38f6-4669-923f-268301b5c3e5/600x600/38f278c6-26d3-4cec-9406-3ab34b2f18e4_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F857a6187-38f6-4669-923f-268301b5c3e5%2F600x600%2F38f278c6-26d3-4cec-9406-3ab34b2f18e4_600x600?alt=media&token=6bafbb0b-0977-48b1-87e2-7e768de6e090"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F857a6187-38f6-4669-923f-268301b5c3e5%2F38f278c6-26d3-4cec-9406-3ab34b2f18e4?alt=media&token=bdaca219-9043-4a31-87d0-b8a50a18e213",
        "reference": "/stroke_photos/857a6187-38f6-4669-923f-268301b5c3e5/38f278c6-26d3-4cec-9406-3ab34b2f18e4"
      }
    },
    "name": "Blank",
    "tool": null,
    "order_id": 0,
    "paper": {
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "name": null,
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "ref": "77725080001",
      "gsm": "250",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "original_size": "A7",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "price": 1.35
    },
    "description": ""
  },
  {
    "order_number": 1075,
    "name": "",
    "description": "",
    "id": "85dfa3ad-cfc2-434a-8131-271c2484cc96"
  },
  {
    "name": "",
    "order_number": 1071,
    "description": "",
    "id": "85e309eb-e770-4046-9b76-cfe0096198f4"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/85e533c0-a847-4e0c-af8f-d586ff45dc7d/20a1772c-dad9-4f69-813f-c88a939e47de",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F85e533c0-a847-4e0c-af8f-d586ff45dc7d%2F20a1772c-dad9-4f69-813f-c88a939e47de?alt=media&token=0189d4fd-492d-4f94-81ae-99d981eddbe7"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/85e533c0-a847-4e0c-af8f-d586ff45dc7d/600x600/20a1772c-dad9-4f69-813f-c88a939e47de_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F85e533c0-a847-4e0c-af8f-d586ff45dc7d%2F600x600%2F20a1772c-dad9-4f69-813f-c88a939e47de_600x600?alt=media&token=79ee413d-13fd-4d38-9ff1-29cbbd3a6c8a"
      }
    },
    "order_number": 762,
    "description": "",
    "id": "85e533c0-a847-4e0c-af8f-d586ff45dc7d",
    "name": "Blank",
    "tool": null,
    "order_id": 0,
    "paper": {
      "ref": "77725080001",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "original_size": "A7",
      "gsm": "250",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      }
    }
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/8611a05f-a7e3-4abf-b353-5f95cd1a9cd1/b1599341-d48d-4700-b4a7-303699fa4d0b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8611a05f-a7e3-4abf-b353-5f95cd1a9cd1%2Fb1599341-d48d-4700-b4a7-303699fa4d0b?alt=media&token=18362c0e-9b50-4e9a-a305-86a9bcb5f2fb"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/8611a05f-a7e3-4abf-b353-5f95cd1a9cd1/600x600/b1599341-d48d-4700-b4a7-303699fa4d0b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8611a05f-a7e3-4abf-b353-5f95cd1a9cd1%2F600x600%2Fb1599341-d48d-4700-b4a7-303699fa4d0b_600x600?alt=media&token=6a6538c4-db19-4c06-afa1-47aeb927721f"
      }
    },
    "name": "PH More Paint 3",
    "paper": {
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "name": "",
      "original_size": "A4",
      "price": null,
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_surface": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "200",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "44202129",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      }
    },
    "order_id": 0,
    "order_number": 56,
    "tool": null,
    "description": "It is confirmed pressing hard causes whole in the middle and thick tip.",
    "id": "8611a05f-a7e3-4abf-b353-5f95cd1a9cd1"
  },
  {
    "order_id": 0,
    "order_number": 426,
    "name": "Crown",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F86156c3c-2dcf-499c-9d2d-e1785983a9a3%2F600x600%2Fa7a9eb3a-3a2a-4466-896e-34acbaaa8837_600x600?alt=media&token=472a7a61-ea90-42f5-a976-28a05a3e4d80",
        "reference": "stroke_photos/86156c3c-2dcf-499c-9d2d-e1785983a9a3/600x600/a7a9eb3a-3a2a-4466-896e-34acbaaa8837_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F86156c3c-2dcf-499c-9d2d-e1785983a9a3%2Fa7a9eb3a-3a2a-4466-896e-34acbaaa8837?alt=media&token=499c4663-4f3f-4d00-8657-9bd245408143",
        "reference": "/stroke_photos/86156c3c-2dcf-499c-9d2d-e1785983a9a3/a7a9eb3a-3a2a-4466-896e-34acbaaa8837"
      }
    },
    "paper": {
      "price": 1.35,
      "ref": "77725080001",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "name": null,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "original_size": "A7"
    },
    "description": "What happens if I roll the brush in a line?",
    "id": "86156c3c-2dcf-499c-9d2d-e1785983a9a3",
    "tool": null
  },
  {
    "order_number": 536,
    "description": "<p>And with an angle...</p>",
    "id": "867faa15-58ae-444b-9529-b48a6ee366bd",
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "gsm": null,
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "brand_model": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "original_size": "A4",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": null
    },
    "name": "Silver-On-Black Sideways 2",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/867faa15-58ae-444b-9529-b48a6ee366bd/600x600/836e81b4-1a09-40e3-b835-a474c68b816e_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F867faa15-58ae-444b-9529-b48a6ee366bd%2F600x600%2F836e81b4-1a09-40e3-b835-a474c68b816e_600x600?alt=media&token=559d3e24-25cb-4a78-9a14-1721d9990481"
      },
      "original": {
        "reference": "/stroke_photos/867faa15-58ae-444b-9529-b48a6ee366bd/836e81b4-1a09-40e3-b835-a474c68b816e",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F867faa15-58ae-444b-9529-b48a6ee366bd%2F836e81b4-1a09-40e3-b835-a474c68b816e?alt=media&token=c98465a4-d11a-4edd-b232-47cbdca78f8c"
      }
    },
    "order_id": 0,
    "tool": null
  },
  {
    "description": "",
    "tool": null,
    "paper": {
      "brand": {},
      "original_size": "A7",
      "price": null,
      "name": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand_model": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250",
      "ref": "70025010030",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl"
      }
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F867ffcd1-dea3-4ff8-ba1d-c14e7e0ca403%2F996574d2-c02a-4bb1-9098-75ef024d17bd?alt=media&token=428adf70-8d30-4116-b0c2-ab8582a1e48b",
        "reference": "/stroke_photos/867ffcd1-dea3-4ff8-ba1d-c14e7e0ca403/996574d2-c02a-4bb1-9098-75ef024d17bd"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F867ffcd1-dea3-4ff8-ba1d-c14e7e0ca403%2F600x600%2F996574d2-c02a-4bb1-9098-75ef024d17bd_600x600?alt=media&token=173e2004-1ed0-4ce4-bf89-60777398b558",
        "reference": "stroke_photos/867ffcd1-dea3-4ff8-ba1d-c14e7e0ca403/600x600/996574d2-c02a-4bb1-9098-75ef024d17bd_600x600"
      }
    },
    "name": "Blank",
    "id": "867ffcd1-dea3-4ff8-ba1d-c14e7e0ca403",
    "order_id": 0,
    "order_number": 606
  },
  {
    "id": "868956de-d216-4f78-b9c0-bab11d87ace2",
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "name": null,
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "ref": "77725080001",
      "price": 1.35,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "gsm": "250"
    },
    "order_id": 0,
    "description": "I feel like sports",
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F868956de-d216-4f78-b9c0-bab11d87ace2%2F866f8f64-4c42-4f8f-a475-eec5aecbafa3?alt=media&token=623969a8-1f33-4948-9426-350d3e348a20",
        "reference": "/stroke_photos/868956de-d216-4f78-b9c0-bab11d87ace2/866f8f64-4c42-4f8f-a475-eec5aecbafa3"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F868956de-d216-4f78-b9c0-bab11d87ace2%2F600x600%2F866f8f64-4c42-4f8f-a475-eec5aecbafa3_600x600?alt=media&token=2f56f2ab-8b50-4edf-9d9f-d9f8d7af6c9e",
        "reference": "stroke_photos/868956de-d216-4f78-b9c0-bab11d87ace2/600x600/866f8f64-4c42-4f8f-a475-eec5aecbafa3_600x600"
      }
    },
    "name": "Tennis",
    "order_number": 440
  },
  {
    "description": "",
    "paper": {
      "original_size": "A7",
      "name": null,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "price": 1.35
    },
    "id": "868ea508-c85c-4430-bf2b-6298e6b5cc08",
    "order_number": 779,
    "name": "Blank",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/868ea508-c85c-4430-bf2b-6298e6b5cc08/600x600/ac42f17d-4253-4f72-9af2-f23eab7a5802_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F868ea508-c85c-4430-bf2b-6298e6b5cc08%2F600x600%2Fac42f17d-4253-4f72-9af2-f23eab7a5802_600x600?alt=media&token=8b7cccb5-c601-4f48-82b2-ac07d91a334e"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F868ea508-c85c-4430-bf2b-6298e6b5cc08%2Fac42f17d-4253-4f72-9af2-f23eab7a5802?alt=media&token=b473f014-c60e-4c3f-9344-82a8f0c5aeb5",
        "reference": "/stroke_photos/868ea508-c85c-4430-bf2b-6298e6b5cc08/ac42f17d-4253-4f72-9af2-f23eab7a5802"
      }
    },
    "tool": null
  },
  {
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "price": null,
      "name": null,
      "original_size": "A7",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "gsm": "250",
      "brand": {},
      "ref": "20180107"
    },
    "id": "86cc02aa-c7b5-4a22-96da-9174d6722266",
    "name": "Shy Little Guy",
    "order_number": 318,
    "order_id": 0,
    "description": "<p>Not as good as my lucky start, but still very friendly</p>",
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F86cc02aa-c7b5-4a22-96da-9174d6722266%2F2d2a476c-235d-41ba-80a9-9a2eb3d91bc7?alt=media&token=b98fed18-9cc8-4fb3-9a3c-656132609f2e",
        "reference": "/stroke_photos/86cc02aa-c7b5-4a22-96da-9174d6722266/2d2a476c-235d-41ba-80a9-9a2eb3d91bc7"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F86cc02aa-c7b5-4a22-96da-9174d6722266%2F600x600%2F2d2a476c-235d-41ba-80a9-9a2eb3d91bc7_600x600?alt=media&token=44e3937c-f8e7-42ed-bf4f-9d7798753865",
        "reference": "stroke_photos/86cc02aa-c7b5-4a22-96da-9174d6722266/600x600/2d2a476c-235d-41ba-80a9-9a2eb3d91bc7_600x600"
      }
    }
  },
  {
    "description": "",
    "order_number": 78,
    "name": "QP 2",
    "photos": {
      "original": {
        "reference": "/stroke_photos/86dc4e4d-3cd0-43a2-a2b9-0dd3b826fffa/77aabd90-fb0c-49fa-a861-cd53c40c278a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F86dc4e4d-3cd0-43a2-a2b9-0dd3b826fffa%2F77aabd90-fb0c-49fa-a861-cd53c40c278a?alt=media&token=ed730650-114b-4412-919b-28785563cbe5"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/86dc4e4d-3cd0-43a2-a2b9-0dd3b826fffa/600x600/77aabd90-fb0c-49fa-a861-cd53c40c278a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F86dc4e4d-3cd0-43a2-a2b9-0dd3b826fffa%2F600x600%2F77aabd90-fb0c-49fa-a861-cd53c40c278a_600x600?alt=media&token=6ff503ac-c423-4bcc-8ae1-1068127cdfd0"
      }
    },
    "paper": {
      "price": null,
      "paper_surface": {},
      "original_size": "A4",
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "44202129",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "name": "",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "gsm": "200"
    },
    "tool": null,
    "order_id": 0,
    "id": "86dc4e4d-3cd0-43a2-a2b9-0dd3b826fffa"
  },
  {
    "name": "",
    "order_number": 1205,
    "description": "",
    "id": "86f33015-57b5-4ad2-974e-e0bce9416f55"
  },
  {
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "price": 1.35,
      "ref": "77725080001",
      "gsm": "250",
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "original_size": "A7"
    },
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/872b77d3-61af-4ad1-8058-2f87834ad43b/600x600/b3d24195-0a1d-4096-b835-e3abc0f30e07_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F872b77d3-61af-4ad1-8058-2f87834ad43b%2F600x600%2Fb3d24195-0a1d-4096-b835-e3abc0f30e07_600x600?alt=media&token=e24c402e-636f-4aca-b09f-86e82c7ea5b4"
      },
      "original": {
        "reference": "/stroke_photos/872b77d3-61af-4ad1-8058-2f87834ad43b/b3d24195-0a1d-4096-b835-e3abc0f30e07",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F872b77d3-61af-4ad1-8058-2f87834ad43b%2Fb3d24195-0a1d-4096-b835-e3abc0f30e07?alt=media&token=79751499-1d28-4d4f-a4b8-94ffecce199a"
      }
    },
    "order_id": 0,
    "name": "Blank",
    "id": "872b77d3-61af-4ad1-8058-2f87834ad43b",
    "order_number": 193,
    "tool": null
  },
  {
    "description": "",
    "order_number": 1049,
    "name": "",
    "id": "8751f54b-637a-4ed0-b97d-fc556b067248"
  },
  {
    "name": "",
    "order_number": 1004,
    "description": "",
    "id": "87ab769c-7bb8-4e3e-8f19-337daf04b66d"
  },
  {
    "order_number": 1031,
    "description": "",
    "name": "",
    "id": "87e4f891-f3a5-46cb-bb3e-49508a9358f4"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1090,
    "id": "87e913ba-587e-4b7b-9eee-c4609340f523"
  },
  {
    "name": "Fake Stick 3",
    "description": "Not the right direction and curve, but still - it's got some character",
    "order_number": 260,
    "tool": null,
    "paper": {
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A7",
      "gsm": "250",
      "ref": "20180107",
      "name": null,
      "price": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      }
    },
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F880fa05f-3546-4f45-9472-d19db90ee1ab%2Fac543479-76d3-4270-acc1-b1b3c449d2b0?alt=media&token=a1966b87-5e3c-4de5-ac34-a7e59120f409",
        "reference": "/stroke_photos/880fa05f-3546-4f45-9472-d19db90ee1ab/ac543479-76d3-4270-acc1-b1b3c449d2b0"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F880fa05f-3546-4f45-9472-d19db90ee1ab%2F600x600%2Fac543479-76d3-4270-acc1-b1b3c449d2b0_600x600?alt=media&token=ab86c923-aff8-40d5-87c8-d882947bf951",
        "reference": "stroke_photos/880fa05f-3546-4f45-9472-d19db90ee1ab/600x600/ac543479-76d3-4270-acc1-b1b3c449d2b0_600x600"
      }
    },
    "id": "880fa05f-3546-4f45-9472-d19db90ee1ab"
  },
  {
    "paper": {
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "gsm": "250",
      "ref": "77725080001",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "original_size": "A7",
      "price": 1.35,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      }
    },
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F881850b2-b3b1-41f7-a8b4-f916e231818e%2F600x600%2Fd7988fd0-fa4f-4f41-9b5e-6560d3457569_600x600?alt=media&token=d8ab477a-7c03-42f6-a531-f9fb1a95fb0c",
        "reference": "stroke_photos/881850b2-b3b1-41f7-a8b4-f916e231818e/600x600/d7988fd0-fa4f-4f41-9b5e-6560d3457569_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F881850b2-b3b1-41f7-a8b4-f916e231818e%2Fd7988fd0-fa4f-4f41-9b5e-6560d3457569?alt=media&token=e0ef2c99-2311-4711-b141-896f3f0e83c7",
        "reference": "/stroke_photos/881850b2-b3b1-41f7-a8b4-f916e231818e/d7988fd0-fa4f-4f41-9b5e-6560d3457569"
      }
    },
    "order_number": 153,
    "description": "",
    "tool": null,
    "order_id": 0,
    "id": "881850b2-b3b1-41f7-a8b4-f916e231818e"
  },
  {
    "order_number": 1197,
    "description": "",
    "name": "",
    "id": "883bee06-41f8-4da2-b28e-92cf3e79e4b9"
  },
  {
    "tool": null,
    "description": "And with a gradient? Unfortunately bad implementation",
    "photos": {
      "original": {
        "reference": "/stroke_photos/8871ae31-44c4-4b23-afa7-20b6a1cc2e1c/ede5c919-4303-4c0b-b00b-c0a3c722f0f4",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8871ae31-44c4-4b23-afa7-20b6a1cc2e1c%2Fede5c919-4303-4c0b-b00b-c0a3c722f0f4?alt=media&token=afdd63fc-8cd8-49a3-9a1d-aa40e06e3be6"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8871ae31-44c4-4b23-afa7-20b6a1cc2e1c%2F600x600%2Fede5c919-4303-4c0b-b00b-c0a3c722f0f4_600x600?alt=media&token=d4b7e423-bc52-49aa-af23-0dc934047e03",
        "reference": "stroke_photos/8871ae31-44c4-4b23-afa7-20b6a1cc2e1c/600x600/ede5c919-4303-4c0b-b00b-c0a3c722f0f4_600x600"
      }
    },
    "paper": {
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "original_size": "A7",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "price": null,
      "ref": "20180107"
    },
    "order_id": 0,
    "order_number": 261,
    "name": "Fake Stick 4",
    "id": "8871ae31-44c4-4b23-afa7-20b6a1cc2e1c"
  },
  {
    "order_id": 0,
    "paper": {
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "ref": "20170703",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": 0.4,
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {},
      "gsm": "250",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "name": null
    },
    "order_number": 710,
    "name": "Blank",
    "tool": null,
    "description": "",
    "id": "887cb871-c255-48da-88cd-9f7ecf842717",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F887cb871-c255-48da-88cd-9f7ecf842717%2F6dbe21e0-d961-45af-bced-4f85dcd4a63c?alt=media&token=798aa508-9d7b-4fb3-9ed1-aee9867456fd",
        "reference": "/stroke_photos/887cb871-c255-48da-88cd-9f7ecf842717/6dbe21e0-d961-45af-bced-4f85dcd4a63c"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/887cb871-c255-48da-88cd-9f7ecf842717/600x600/6dbe21e0-d961-45af-bced-4f85dcd4a63c_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F887cb871-c255-48da-88cd-9f7ecf842717%2F600x600%2F6dbe21e0-d961-45af-bced-4f85dcd4a63c_600x600?alt=media&token=ee7f120f-fd9b-42c2-bd7f-d1533447c69c"
      }
    }
  },
  {
    "order_number": 1189,
    "description": "",
    "name": "",
    "id": "892959fd-ccdc-4680-9236-5c53ce307e97"
  },
  {
    "description": "",
    "order_number": 1211,
    "name": "",
    "id": "8973bdcb-c199-4634-a7f6-3176359e5644"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1012,
    "id": "898097ca-d5ce-42f3-8faf-93cf3d539a76"
  },
  {
    "description": "",
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "price": null,
      "name": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top"
      },
      "original_size": "A7"
    },
    "name": "Perfect Young DIck",
    "order_id": 0,
    "tool": null,
    "id": "8981de4d-7403-4c91-a566-450ac4453e47",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8981de4d-7403-4c91-a566-450ac4453e47%2F600x600%2F9af8c22d-79c4-401e-95b4-988e9b7cc572_600x600?alt=media&token=ac9d0b87-f343-4c9f-9890-d31e0dfc86ed",
        "reference": "stroke_photos/8981de4d-7403-4c91-a566-450ac4453e47/600x600/9af8c22d-79c4-401e-95b4-988e9b7cc572_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8981de4d-7403-4c91-a566-450ac4453e47%2F9af8c22d-79c4-401e-95b4-988e9b7cc572?alt=media&token=afbadff6-f666-4f59-9d2c-6194fce975aa",
        "reference": "/stroke_photos/8981de4d-7403-4c91-a566-450ac4453e47/9af8c22d-79c4-401e-95b4-988e9b7cc572"
      }
    },
    "order_number": 252
  },
  {
    "order_id": 0,
    "paper": {
      "gsm": "640",
      "price": 19.3,
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico"
      },
      "name": "tinto",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "original_size": "75x105cm",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "ref": "30075105",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163"
    },
    "description": "",
    "name": "Blank",
    "tool": null,
    "id": "89a66fbf-4aae-4b1a-bb92-83608858390b",
    "order_number": 690,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/89a66fbf-4aae-4b1a-bb92-83608858390b/600x600/c905ab03-05cf-427d-ae1b-6372e02f67b7_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F89a66fbf-4aae-4b1a-bb92-83608858390b%2F600x600%2Fc905ab03-05cf-427d-ae1b-6372e02f67b7_600x600?alt=media&token=e7d794d9-4fd8-4c8c-b297-aa177b389244"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F89a66fbf-4aae-4b1a-bb92-83608858390b%2Fc905ab03-05cf-427d-ae1b-6372e02f67b7?alt=media&token=53ca87c1-cf6e-4c28-ba36-f37514d5bc8b",
        "reference": "/stroke_photos/89a66fbf-4aae-4b1a-bb92-83608858390b/c905ab03-05cf-427d-ae1b-6372e02f67b7"
      }
    }
  },
  {
    "order_id": 0,
    "name": "Blank",
    "id": "8a00e457-44f0-436d-9683-1f192ce3d00a",
    "description": "",
    "paper": {
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "price": 0.4,
      "brand": {},
      "original_size": "A7",
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "20170703",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "order_number": 802
  },
  {
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {},
      "original_size": "A7",
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "20180107",
      "name": null,
      "gsm": "250"
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/8a049ddb-79bd-4724-afb7-7d9ad2558a5c/c5ff0bb1-c4a5-4a13-a814-cd26735fe15f",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8a049ddb-79bd-4724-afb7-7d9ad2558a5c%2Fc5ff0bb1-c4a5-4a13-a814-cd26735fe15f?alt=media&token=347fa7ef-372e-4e5b-b2ab-b9e456898b16"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/8a049ddb-79bd-4724-afb7-7d9ad2558a5c/600x600/c5ff0bb1-c4a5-4a13-a814-cd26735fe15f_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8a049ddb-79bd-4724-afb7-7d9ad2558a5c%2F600x600%2Fc5ff0bb1-c4a5-4a13-a814-cd26735fe15f_600x600?alt=media&token=47087ec4-1f1a-4121-aedc-bc63a4ee4af1"
      }
    },
    "id": "8a049ddb-79bd-4724-afb7-7d9ad2558a5c",
    "order_number": 109,
    "order_id": 0,
    "tool": null,
    "description": "",
    "name": "Terrible Spots"
  },
  {
    "name": "PH a Lot of Paint",
    "paper": {
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand_model": {
        "name": "Accademia Designo",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "price": null,
      "gsm": "200",
      "paper_surface": {},
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": "",
      "original_size": "A4",
      "ref": "44202129"
    },
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/8a21310d-fb11-4ed2-9ccd-69cc43891700/600x600/b6d5336b-4df9-404c-8ed2-ab136fcbb29a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8a21310d-fb11-4ed2-9ccd-69cc43891700%2F600x600%2Fb6d5336b-4df9-404c-8ed2-ab136fcbb29a_600x600?alt=media&token=427b0116-1102-42d7-a0f8-77666697610b"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8a21310d-fb11-4ed2-9ccd-69cc43891700%2Fb6d5336b-4df9-404c-8ed2-ab136fcbb29a?alt=media&token=70949c33-acd8-4c03-a93e-fa1923086d37",
        "reference": "/stroke_photos/8a21310d-fb11-4ed2-9ccd-69cc43891700/b6d5336b-4df9-404c-8ed2-ab136fcbb29a"
      }
    },
    "description": "Adding a lot of paint again to see if we can remove that whole in the middle. Not bad, somewhat worked, but overall structure doesn't feel \"perfect\".",
    "order_id": 0,
    "order_number": 57,
    "id": "8a21310d-fb11-4ed2-9ccd-69cc43891700"
  },
  {
    "order_number": 1216,
    "name": "",
    "description": "",
    "id": "8a368c82-034e-472c-9f36-d19d670863f8"
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8a503fdc-9666-48dd-adaa-7974dbe97223%2Fa0e40ac6-735c-45ae-aa0f-a80d8431dfc7?alt=media&token=5e177870-f243-4e86-8847-ffa6c2bc1c45",
        "reference": "/stroke_photos/8a503fdc-9666-48dd-adaa-7974dbe97223/a0e40ac6-735c-45ae-aa0f-a80d8431dfc7"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/8a503fdc-9666-48dd-adaa-7974dbe97223/600x600/a0e40ac6-735c-45ae-aa0f-a80d8431dfc7_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8a503fdc-9666-48dd-adaa-7974dbe97223%2F600x600%2Fa0e40ac6-735c-45ae-aa0f-a80d8431dfc7_600x600?alt=media&token=0ebbd1a5-aa32-44ca-9973-81af8eec2a7a"
      }
    },
    "order_number": 177,
    "id": "8a503fdc-9666-48dd-adaa-7974dbe97223",
    "paper": {
      "price": 1.35,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "gsm": "250",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "original_size": "A7",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "ref": "77725080001",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      }
    },
    "name": "Blank",
    "tool": null,
    "description": "",
    "order_id": 0
  },
  {
    "order_number": 397,
    "order_id": 0,
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/8b009aa8-72ea-48dd-8069-1809fc55211a/600x600/20b4854e-c6d1-440a-8ca5-4a904e6f7bd1_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8b009aa8-72ea-48dd-8069-1809fc55211a%2F600x600%2F20b4854e-c6d1-440a-8ca5-4a904e6f7bd1_600x600?alt=media&token=8c69064e-bc8f-449a-80c4-c83ee40b8ef8"
      },
      "original": {
        "reference": "/stroke_photos/8b009aa8-72ea-48dd-8069-1809fc55211a/20b4854e-c6d1-440a-8ca5-4a904e6f7bd1",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8b009aa8-72ea-48dd-8069-1809fc55211a%2F20b4854e-c6d1-440a-8ca5-4a904e6f7bd1?alt=media&token=2a3b6745-0baf-4a0b-8480-4796313ff962"
      }
    },
    "id": "8b009aa8-72ea-48dd-8069-1809fc55211a",
    "description": "",
    "paper": {
      "price": 1.35,
      "name": null,
      "original_size": "A7",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "77725080001",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "gsm": "250",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      }
    },
    "tool": null
  },
  {
    "description": "<p>What a big hand. Grandma, why is your hand so big?</p>",
    "photos": {
      "original": {
        "reference": "/stroke_photos/8b17cdad-03e3-4bea-a07c-6674b4079d69/80780c27-22fe-4bee-965d-b72abc673b3d",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8b17cdad-03e3-4bea-a07c-6674b4079d69%2F80780c27-22fe-4bee-965d-b72abc673b3d?alt=media&token=30ce0d21-3252-4cd9-b037-fa4b51200cb3"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8b17cdad-03e3-4bea-a07c-6674b4079d69%2F600x600%2F80780c27-22fe-4bee-965d-b72abc673b3d_600x600?alt=media&token=00b6631f-9b92-4be5-8829-e433a218c3dd",
        "reference": "stroke_photos/8b17cdad-03e3-4bea-a07c-6674b4079d69/600x600/80780c27-22fe-4bee-965d-b72abc673b3d_600x600"
      }
    },
    "tool": null,
    "order_id": 0,
    "name": "Swirly Total Yuk",
    "id": "8b17cdad-03e3-4bea-a07c-6674b4079d69",
    "order_number": 321,
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250",
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "name": null,
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "brand": {},
      "ref": "20180107",
      "price": null
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1112,
    "id": "8b56708f-5f5a-40b8-a7bc-3afbe89aff59"
  },
  {
    "description": "",
    "order_number": 1105,
    "name": "",
    "id": "8b8f7314-c106-4dba-8d2e-3a3a6a300793"
  },
  {
    "id": "8bc91da0-054d-4121-8294-316c2f3bf800",
    "paper": {
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "price": 0.4,
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "gsm": "250",
      "ref": "20170703",
      "original_size": "A7",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "name": null,
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      }
    },
    "name": "Blank",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8bc91da0-054d-4121-8294-316c2f3bf800%2Fe7d9f4e3-b342-490f-8c4a-f9d466351ef7?alt=media&token=54d678ce-670f-4199-b87a-0285dff2195b",
        "reference": "/stroke_photos/8bc91da0-054d-4121-8294-316c2f3bf800/e7d9f4e3-b342-490f-8c4a-f9d466351ef7"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8bc91da0-054d-4121-8294-316c2f3bf800%2F600x600%2Fe7d9f4e3-b342-490f-8c4a-f9d466351ef7_600x600?alt=media&token=410cc448-cb88-40fc-a79a-1dfcdbd8dd91",
        "reference": "stroke_photos/8bc91da0-054d-4121-8294-316c2f3bf800/600x600/e7d9f4e3-b342-490f-8c4a-f9d466351ef7_600x600"
      }
    },
    "order_id": 0,
    "description": "",
    "order_number": 744,
    "tool": null
  },
  {
    "description": "",
    "order_number": 1026,
    "name": "",
    "id": "8be00291-3315-4164-8527-6dbc636140ba"
  },
  {
    "description": "",
    "order_number": 1086,
    "name": "",
    "id": "8be9c1b6-7285-4105-9e3a-d68b9f69a31d"
  },
  {
    "name": "Blank",
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "original_size": "A7",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {},
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": null,
      "ref": "70025010030",
      "gsm": "250",
      "brand": {}
    },
    "description": "",
    "tool": null,
    "order_number": 633,
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8c2c371a-4e78-436f-a176-7056b33a6c93%2F2e995ebd-2e04-4403-a551-95f15ed38a02?alt=media&token=473c09a6-d7e7-4ebc-9e39-85b0ab645169",
        "reference": "/stroke_photos/8c2c371a-4e78-436f-a176-7056b33a6c93/2e995ebd-2e04-4403-a551-95f15ed38a02"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8c2c371a-4e78-436f-a176-7056b33a6c93%2F600x600%2F2e995ebd-2e04-4403-a551-95f15ed38a02_600x600?alt=media&token=312c768c-7d37-4233-a311-84bfb69f4a78",
        "reference": "stroke_photos/8c2c371a-4e78-436f-a176-7056b33a6c93/600x600/2e995ebd-2e04-4403-a551-95f15ed38a02_600x600"
      }
    },
    "id": "8c2c371a-4e78-436f-a176-7056b33a6c93"
  },
  {
    "description": "",
    "id": "8c554815-de9f-4aa9-9614-aadb10d7dcd2",
    "order_number": 191,
    "paper": {
      "gsm": "250",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "name": null,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso"
      },
      "price": 1.35,
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "ref": "77725080001"
    },
    "tool": null,
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8c554815-de9f-4aa9-9614-aadb10d7dcd2%2F600x600%2F94a6b54b-404d-454d-8c4b-db838362b594_600x600?alt=media&token=1d228f25-56c7-4e81-93b1-864c361d811b",
        "reference": "stroke_photos/8c554815-de9f-4aa9-9614-aadb10d7dcd2/600x600/94a6b54b-404d-454d-8c4b-db838362b594_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8c554815-de9f-4aa9-9614-aadb10d7dcd2%2F94a6b54b-404d-454d-8c4b-db838362b594?alt=media&token=5f041848-3824-4580-a304-93484a9d5531",
        "reference": "/stroke_photos/8c554815-de9f-4aa9-9614-aadb10d7dcd2/94a6b54b-404d-454d-8c4b-db838362b594"
      }
    },
    "order_id": 0
  },
  {
    "paper": {
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "ref": "77725080001",
      "original_size": "A7",
      "name": null,
      "price": 1.35,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    },
    "order_number": 422,
    "tool": null,
    "name": "Ascension 2",
    "id": "8c7f961d-260d-4f62-b5bd-53b562e44de5",
    "order_id": 0,
    "description": "Without that playfulness, it's missing something",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8c7f961d-260d-4f62-b5bd-53b562e44de5%2Fb175dc9f-636e-4cdf-a0c0-d957f1259886?alt=media&token=1431eee2-75bc-4da4-a9e5-aab2e85383e9",
        "reference": "/stroke_photos/8c7f961d-260d-4f62-b5bd-53b562e44de5/b175dc9f-636e-4cdf-a0c0-d957f1259886"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/8c7f961d-260d-4f62-b5bd-53b562e44de5/600x600/b175dc9f-636e-4cdf-a0c0-d957f1259886_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8c7f961d-260d-4f62-b5bd-53b562e44de5%2F600x600%2Fb175dc9f-636e-4cdf-a0c0-d957f1259886_600x600?alt=media&token=f896dc5a-66c8-4f20-bdd8-8188a121fbe0"
      }
    }
  },
  {
    "order_number": 1015,
    "description": "",
    "name": "",
    "id": "8c8e42c2-efdc-40af-9860-3fefc69b3c1b"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1166,
    "id": "8cc32b36-fd5d-4359-882c-638861290603"
  },
  {
    "description": "",
    "id": "8cda474d-7751-4fe5-b42c-5ee2bec96997",
    "order_number": 973,
    "order_id": 0,
    "paper": {
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": null,
      "original_size": "A4",
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "price": null,
      "brand": {},
      "brand_model": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null
    },
    "name": "Blank"
  },
  {
    "order_number": 1142,
    "description": "",
    "name": "",
    "id": "8cf3b2d5-12ec-448f-a69e-5af7f31c7420"
  },
  {
    "description": "",
    "order_number": 1228,
    "name": "",
    "id": "8d095fa7-4595-4779-9537-6a5391d4ae6a"
  },
  {
    "order_id": 0,
    "name": "Blank",
    "id": "8d65a095-4f93-4f43-8b1e-d11220a4434c",
    "description": "",
    "paper": {
      "original_size": "A7",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "20170703",
      "brand": {},
      "gsm": "250",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": 0.4
    },
    "order_number": 825
  },
  {
    "description": "",
    "order_number": 1047,
    "name": "",
    "id": "8d85ffdd-438e-4313-a3b0-76c7bce1ea4c"
  },
  {
    "name": "Soccer",
    "description": "My love from the early years",
    "paper": {
      "gsm": "250",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "ref": "77725080001",
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "original_size": "A7",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "price": 1.35,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    },
    "tool": null,
    "order_number": 443,
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8df040d1-85b0-44e5-a741-157b48f93657%2F347bd02f-4d95-4e5b-a418-3e73d663631d?alt=media&token=fde4fd96-0859-44e9-9b9f-fa0cbdb7b3bc",
        "reference": "/stroke_photos/8df040d1-85b0-44e5-a741-157b48f93657/347bd02f-4d95-4e5b-a418-3e73d663631d"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8df040d1-85b0-44e5-a741-157b48f93657%2F600x600%2F347bd02f-4d95-4e5b-a418-3e73d663631d_600x600?alt=media&token=0289df39-e785-4884-856a-dac237d4f333",
        "reference": "stroke_photos/8df040d1-85b0-44e5-a741-157b48f93657/600x600/347bd02f-4d95-4e5b-a418-3e73d663631d_600x600"
      }
    },
    "id": "8df040d1-85b0-44e5-a741-157b48f93657"
  },
  {
    "order_id": 0,
    "id": "8e783776-b27d-468a-984a-9ed565382e2f",
    "description": "",
    "paper": {
      "brand_model": {
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "name": "tinto",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "original_size": "75x105cm",
      "price": 19.3,
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "gsm": "640",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "ref": "30075105"
    },
    "order_number": 978,
    "name": "Blank"
  },
  {
    "order_id": 0,
    "id": "8eba5148-0465-4354-b218-2c33a6420866",
    "paper": {
      "ref": "44202129",
      "gsm": "200",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "name": "",
      "paper_surface": {},
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "name": "SWOB Pause at End",
    "description": "",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8eba5148-0465-4354-b218-2c33a6420866%2F05bb0aed-d8e0-4201-8ab4-746737fb857f?alt=media&token=4b762a7e-8dd3-44fc-8ea0-6d345ecd1e87",
        "reference": "/stroke_photos/8eba5148-0465-4354-b218-2c33a6420866/05bb0aed-d8e0-4201-8ab4-746737fb857f"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8eba5148-0465-4354-b218-2c33a6420866%2F600x600%2F05bb0aed-d8e0-4201-8ab4-746737fb857f_600x600?alt=media&token=4fe3a716-00b7-4528-a32f-7e0239be6d84",
        "reference": "stroke_photos/8eba5148-0465-4354-b218-2c33a6420866/600x600/05bb0aed-d8e0-4201-8ab4-746737fb857f_600x600"
      }
    },
    "tool": null,
    "order_number": 73
  },
  {
    "description": "",
    "name": "Blank",
    "order_id": 0,
    "order_number": 917,
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "price": null,
      "gsm": null,
      "name": null,
      "brand_model": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "original_size": "A4"
    },
    "id": "8ecb2a8b-1c3c-4f85-89ce-b522d0ba80c0"
  },
  {
    "paper": {
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "gsm": "250",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "price": 0.4,
      "ref": "20170703",
      "original_size": "A7"
    },
    "order_number": 559,
    "id": "8ed1e147-74a0-4e81-add6-ef50350ecf5a",
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/8ed1e147-74a0-4e81-add6-ef50350ecf5a/dfd9a2db-0801-4faa-8b29-5164f605761b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8ed1e147-74a0-4e81-add6-ef50350ecf5a%2Fdfd9a2db-0801-4faa-8b29-5164f605761b?alt=media&token=788a2b73-3890-4ee9-8cd7-8deaf572e2f1"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/8ed1e147-74a0-4e81-add6-ef50350ecf5a/600x600/dfd9a2db-0801-4faa-8b29-5164f605761b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8ed1e147-74a0-4e81-add6-ef50350ecf5a%2F600x600%2Fdfd9a2db-0801-4faa-8b29-5164f605761b_600x600?alt=media&token=3c9a78f4-a20d-4256-a89e-47459c361890"
      }
    },
    "tool": null,
    "name": "Blank",
    "order_id": 0
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8ee92455-337e-44d8-b1f1-ffa35b238c12%2Ff1286d54-d425-44f3-a772-3207075e7771?alt=media&token=9e9cdfac-9b40-4e29-b83c-fb9a2e4437a5",
        "reference": "/stroke_photos/8ee92455-337e-44d8-b1f1-ffa35b238c12/f1286d54-d425-44f3-a772-3207075e7771"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/8ee92455-337e-44d8-b1f1-ffa35b238c12/600x600/f1286d54-d425-44f3-a772-3207075e7771_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8ee92455-337e-44d8-b1f1-ffa35b238c12%2F600x600%2Ff1286d54-d425-44f3-a772-3207075e7771_600x600?alt=media&token=ca95e47d-5ddf-416c-a0a7-5ac032a272f4"
      }
    },
    "id": "8ee92455-337e-44d8-b1f1-ffa35b238c12",
    "paper": {
      "brand_model": {},
      "ref": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "brand": {},
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A4",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "gsm": null
    },
    "order_id": 0,
    "name": "Silver-On-Black Loads Of Paint!",
    "description": "<p>Let's do again loads of paint and barely touching for mosaics (!) I really love it! Will have to protect the paint later</p>",
    "order_number": 530,
    "tool": null
  },
  {
    "description": "",
    "paper": {
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "original_size": "A7",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "price": 1.35,
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso"
      },
      "gsm": "250",
      "ref": "77725080001"
    },
    "order_number": 201,
    "tool": null,
    "name": "Smoothmove",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8ef3f11f-9eb0-4000-8756-b74f55bf73d1%2F600x600%2F1892388d-c586-4e42-a00e-221cdfa39f52_600x600?alt=media&token=06effd5c-3e39-4a93-bb9a-a3577b915735",
        "reference": "stroke_photos/8ef3f11f-9eb0-4000-8756-b74f55bf73d1/600x600/1892388d-c586-4e42-a00e-221cdfa39f52_600x600"
      },
      "original": {
        "reference": "/stroke_photos/8ef3f11f-9eb0-4000-8756-b74f55bf73d1/1892388d-c586-4e42-a00e-221cdfa39f52",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8ef3f11f-9eb0-4000-8756-b74f55bf73d1%2F1892388d-c586-4e42-a00e-221cdfa39f52?alt=media&token=d5751e00-2a6c-4bff-9347-0d3af5d9ab94"
      }
    },
    "order_id": 0,
    "id": "8ef3f11f-9eb0-4000-8756-b74f55bf73d1"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/8f3bc091-c27e-4822-94e3-8b10a660ba0e/90845d73-d302-4d10-babf-a65930c37d6e",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8f3bc091-c27e-4822-94e3-8b10a660ba0e%2F90845d73-d302-4d10-babf-a65930c37d6e?alt=media&token=a6e53933-e30d-49a4-a1e3-1db6240c547a"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8f3bc091-c27e-4822-94e3-8b10a660ba0e%2F600x600%2F90845d73-d302-4d10-babf-a65930c37d6e_600x600?alt=media&token=b4fbdcd7-3b69-460a-a790-6ba782797f00",
        "reference": "stroke_photos/8f3bc091-c27e-4822-94e3-8b10a660ba0e/600x600/90845d73-d302-4d10-babf-a65930c37d6e_600x600"
      }
    },
    "order_number": 97,
    "description": "Actually not a bad arc",
    "name": "Variable Pressure",
    "tool": null,
    "paper": {
      "gsm": "200",
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "44202129",
      "paper_surface": {},
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "original_size": "A4",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "name": "",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      }
    },
    "id": "8f3bc091-c27e-4822-94e3-8b10a660ba0e",
    "order_id": 0
  },
  {
    "order_number": 1059,
    "description": "",
    "name": "",
    "id": "8f52a628-8818-435a-a4a3-4a2bdb921019"
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8f5b056a-fe55-425b-b3d0-e31a9f0cec05%2Fe91957db-4a7f-4b54-a68f-521dc52c597e?alt=media&token=f27fb972-e24c-4553-9564-85a359926422",
        "reference": "/stroke_photos/8f5b056a-fe55-425b-b3d0-e31a9f0cec05/e91957db-4a7f-4b54-a68f-521dc52c597e"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8f5b056a-fe55-425b-b3d0-e31a9f0cec05%2F600x600%2Fe91957db-4a7f-4b54-a68f-521dc52c597e_600x600?alt=media&token=b3d5dfcc-02ce-4a8a-b4fa-6a03be171e1e",
        "reference": "stroke_photos/8f5b056a-fe55-425b-b3d0-e31a9f0cec05/600x600/e91957db-4a7f-4b54-a68f-521dc52c597e_600x600"
      }
    },
    "tool": null,
    "name": "First Attempt for 3 Pressure Points",
    "description": "",
    "order_number": 114,
    "order_id": 0,
    "paper": {
      "price": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20180107",
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "original_size": "A7",
      "gsm": "250",
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null
    },
    "id": "8f5b056a-fe55-425b-b3d0-e31a9f0cec05"
  },
  {
    "paper": {
      "brand": {},
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {},
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": null,
      "ref": null
    },
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8fbf73a9-dd6c-497e-b0d4-42a7494bd082%2F600x600%2Fd17564b1-4128-404b-9508-90d26e4f4557_600x600?alt=media&token=0fd83f5a-6f50-4e1d-8cbb-834ea2ba412d",
        "reference": "stroke_photos/8fbf73a9-dd6c-497e-b0d4-42a7494bd082/600x600/d17564b1-4128-404b-9508-90d26e4f4557_600x600"
      },
      "original": {
        "reference": "/stroke_photos/8fbf73a9-dd6c-497e-b0d4-42a7494bd082/d17564b1-4128-404b-9508-90d26e4f4557",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8fbf73a9-dd6c-497e-b0d4-42a7494bd082%2Fd17564b1-4128-404b-9508-90d26e4f4557?alt=media&token=04a9b0c9-790f-4131-9561-32386f0cba15"
      }
    },
    "description": "<p>Okay</p>",
    "order_id": 0,
    "order_number": 473,
    "id": "8fbf73a9-dd6c-497e-b0d4-42a7494bd082",
    "name": "Wavy White Attempt"
  },
  {
    "name": "Blank",
    "order_id": 0,
    "paper": {
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "original_size": "A7",
      "ref": "77725080001",
      "gsm": "250",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "price": 1.35,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "order_number": 787,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/8fdb7714-fcc2-49bf-b376-1530f3b196d4/600x600/78f9b105-1f74-4e96-90a9-e0c8611417d9_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8fdb7714-fcc2-49bf-b376-1530f3b196d4%2F600x600%2F78f9b105-1f74-4e96-90a9-e0c8611417d9_600x600?alt=media&token=d9581c65-ef62-47d2-a7a7-d912d044214d"
      },
      "original": {
        "reference": "/stroke_photos/8fdb7714-fcc2-49bf-b376-1530f3b196d4/78f9b105-1f74-4e96-90a9-e0c8611417d9",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F8fdb7714-fcc2-49bf-b376-1530f3b196d4%2F78f9b105-1f74-4e96-90a9-e0c8611417d9?alt=media&token=db043185-2c36-4527-9e93-c4eb541edbec"
      }
    },
    "tool": null,
    "description": "",
    "id": "8fdb7714-fcc2-49bf-b376-1530f3b196d4"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1044,
    "id": "8fee7304-2704-45b9-bb58-f9e8bceb74d7"
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F90822bc7-20dd-47e0-a159-825c0d09bdee%2F0278376a-e34d-4a68-8e7a-9a3c2cb40d3e?alt=media&token=1de5d618-4b88-4919-a517-f1cce54d5450",
        "reference": "/stroke_photos/90822bc7-20dd-47e0-a159-825c0d09bdee/0278376a-e34d-4a68-8e7a-9a3c2cb40d3e"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F90822bc7-20dd-47e0-a159-825c0d09bdee%2F600x600%2F0278376a-e34d-4a68-8e7a-9a3c2cb40d3e_600x600?alt=media&token=697c4b9c-fdd5-40d6-9a3e-6b69b9b8ee4e",
        "reference": "stroke_photos/90822bc7-20dd-47e0-a159-825c0d09bdee/600x600/0278376a-e34d-4a68-8e7a-9a3c2cb40d3e_600x600"
      }
    },
    "description": "",
    "order_id": 0,
    "id": "90822bc7-20dd-47e0-a159-825c0d09bdee",
    "tool": null,
    "name": "Blank",
    "order_number": 708,
    "paper": {
      "ref": "20170703",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "price": 0.4,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "brand": {},
      "gsm": "250",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    }
  },
  {
    "order_number": 1141,
    "name": "",
    "description": "",
    "id": "90cb7ba2-6aff-44e0-9767-5910e15d53ad"
  },
  {
    "order_number": 1034,
    "description": "",
    "name": "",
    "id": "90cf5b3c-192e-4ea0-9fae-4a4ac83c588a"
  },
  {
    "order_number": 1154,
    "description": "",
    "name": "",
    "id": "90e87f46-09a3-48f9-ae05-f2a110a7d97b"
  },
  {
    "tool": null,
    "paper": {
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "original_size": "A7",
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "brand": {},
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/910456d7-fb1e-4a3f-b0f5-3c116cc08ad5/600x600/fa727e68-bef2-46b1-ae4d-e8cc551eb97a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F910456d7-fb1e-4a3f-b0f5-3c116cc08ad5%2F600x600%2Ffa727e68-bef2-46b1-ae4d-e8cc551eb97a_600x600?alt=media&token=ec5da9c8-738b-4683-8634-6052452353db"
      },
      "original": {
        "reference": "/stroke_photos/910456d7-fb1e-4a3f-b0f5-3c116cc08ad5/fa727e68-bef2-46b1-ae4d-e8cc551eb97a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F910456d7-fb1e-4a3f-b0f5-3c116cc08ad5%2Ffa727e68-bef2-46b1-ae4d-e8cc551eb97a?alt=media&token=315c3789-35d5-46f5-9995-aed09dfce328"
      }
    },
    "order_number": 355,
    "name": "Shorty Again",
    "id": "910456d7-fb1e-4a3f-b0f5-3c116cc08ad5",
    "description": "<p>Oh Well</p>",
    "order_id": 0
  },
  {
    "order_id": 0,
    "order_number": 486,
    "id": "9118e731-278b-452d-86bd-e75adcd56281",
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {},
      "price": null,
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "gsm": null,
      "brand": {},
      "ref": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null
    },
    "name": "White Hook With Angle",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9118e731-278b-452d-86bd-e75adcd56281%2F600x600%2Fe93fca55-c6dc-49f4-8d63-1726dc52a4dc_600x600?alt=media&token=f29854a5-3054-4be7-8ce0-9a6b777c0e5f",
        "reference": "stroke_photos/9118e731-278b-452d-86bd-e75adcd56281/600x600/e93fca55-c6dc-49f4-8d63-1726dc52a4dc_600x600"
      },
      "original": {
        "reference": "/stroke_photos/9118e731-278b-452d-86bd-e75adcd56281/e93fca55-c6dc-49f4-8d63-1726dc52a4dc",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9118e731-278b-452d-86bd-e75adcd56281%2Fe93fca55-c6dc-49f4-8d63-1726dc52a4dc?alt=media&token=3ad3f030-3af2-41db-b796-70250d0922a2"
      }
    },
    "description": "<p>I want to keep the angle of the brush and see what happens. (!) Okay, I like it. Let's do a few more hooks</p>"
  },
  {
    "description": "",
    "order_number": 1128,
    "name": "",
    "id": "914bf633-2525-46c4-9558-eb2c789b222e"
  },
  {
    "order_number": 1199,
    "description": "",
    "name": "",
    "id": "915421a5-8dd8-4448-9c03-d4ce33445526"
  },
  {
    "id": "91f0d6f2-c5bf-4402-b6d6-c9b4cec0b77b",
    "paper": {
      "gsm": "250",
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": null,
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top"
      },
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "ref": "20180107",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F91f0d6f2-c5bf-4402-b6d6-c9b4cec0b77b%2F600x600%2F755745fa-1256-49dd-8cba-b898435881cc_600x600?alt=media&token=892752d4-5c85-4ec1-992e-c37667f87226",
        "reference": "stroke_photos/91f0d6f2-c5bf-4402-b6d6-c9b4cec0b77b/600x600/755745fa-1256-49dd-8cba-b898435881cc_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F91f0d6f2-c5bf-4402-b6d6-c9b4cec0b77b%2F755745fa-1256-49dd-8cba-b898435881cc?alt=media&token=5ee14735-81b1-41fc-a418-13f357ad1970",
        "reference": "/stroke_photos/91f0d6f2-c5bf-4402-b6d6-c9b4cec0b77b/755745fa-1256-49dd-8cba-b898435881cc"
      }
    },
    "order_number": 395,
    "name": "Mixer 5",
    "order_id": 0,
    "description": "<p>Mostly silver with a bit of black, getting there. Still not perfect. Also, looks terrible on the home-photo I did.</p>"
  },
  {
    "description": "",
    "name": "Blank",
    "order_id": 0,
    "order_number": 718,
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper"
      },
      "brand": {},
      "price": 0.4,
      "gsm": "250",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "20170703"
    },
    "id": "91ff2a7e-518f-4506-8805-68561c8c8fac",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/91ff2a7e-518f-4506-8805-68561c8c8fac/600x600/0c680215-128f-4872-8e3b-6d446990a2e4_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F91ff2a7e-518f-4506-8805-68561c8c8fac%2F600x600%2F0c680215-128f-4872-8e3b-6d446990a2e4_600x600?alt=media&token=ca40b440-7421-4ee0-96d9-17e0a97cc7d4"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F91ff2a7e-518f-4506-8805-68561c8c8fac%2F0c680215-128f-4872-8e3b-6d446990a2e4?alt=media&token=aad12d28-facb-4b58-99f4-95489f73d9ae",
        "reference": "/stroke_photos/91ff2a7e-518f-4506-8805-68561c8c8fac/0c680215-128f-4872-8e3b-6d446990a2e4"
      }
    },
    "tool": null
  },
  {
    "order_number": 990,
    "order_id": 0,
    "id": "92151d3a-bf87-4719-95d7-b94abb1a5485",
    "name": "Blank",
    "description": "",
    "paper": {
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "75x105cm",
      "price": 19.3,
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "name": "tinto",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "gsm": "640",
      "ref": "30075105",
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      }
    }
  },
  {
    "name": "Blank",
    "order_id": 0,
    "description": "",
    "id": "921ca461-0010-49a7-98ea-c222a1377086",
    "tool": null,
    "order_number": 722,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F921ca461-0010-49a7-98ea-c222a1377086%2F612a4417-c1cd-43e3-b94b-2e6422506491?alt=media&token=d49ea6f6-340a-4d66-9adf-ec08920a2ed3",
        "reference": "/stroke_photos/921ca461-0010-49a7-98ea-c222a1377086/612a4417-c1cd-43e3-b94b-2e6422506491"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/921ca461-0010-49a7-98ea-c222a1377086/600x600/612a4417-c1cd-43e3-b94b-2e6422506491_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F921ca461-0010-49a7-98ea-c222a1377086%2F600x600%2F612a4417-c1cd-43e3-b94b-2e6422506491_600x600?alt=media&token=4bc1cc77-b753-4373-8717-f012cbae80dd"
      }
    },
    "paper": {
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": 0.4,
      "gsm": "250",
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "original_size": "A7",
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20170703",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    }
  },
  {
    "order_id": 0,
    "id": "9220db18-c459-4552-ac31-3dbaf6478bdf",
    "description": "",
    "name": "Blank",
    "order_number": 922,
    "paper": {
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "ref": null,
      "original_size": "A4",
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand": {},
      "gsm": null,
      "name": null,
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {}
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1174,
    "id": "9222c925-0410-49f5-8485-bf6ea6852ca0"
  },
  {
    "paper": {
      "original_size": "A4",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": null,
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "gsm": "200",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "ref": "44202129",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {},
      "name": "",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4"
    },
    "description": "",
    "name": "Paint On Paper First",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F92246e5a-3dcf-4177-8d46-d2a805982b4e%2F600x600%2Fd29ab281-75b9-4b01-87ca-8c42255a295b_600x600?alt=media&token=cc2ffebc-5802-40cc-b4ca-01ea1c0860ac",
        "reference": "stroke_photos/92246e5a-3dcf-4177-8d46-d2a805982b4e/600x600/d29ab281-75b9-4b01-87ca-8c42255a295b_600x600"
      },
      "original": {
        "reference": "/stroke_photos/92246e5a-3dcf-4177-8d46-d2a805982b4e/d29ab281-75b9-4b01-87ca-8c42255a295b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F92246e5a-3dcf-4177-8d46-d2a805982b4e%2Fd29ab281-75b9-4b01-87ca-8c42255a295b?alt=media&token=7de65047-b320-461c-8166-e8d08d703d6d"
      }
    },
    "order_id": 0,
    "tool": {},
    "id": "92246e5a-3dcf-4177-8d46-d2a805982b4e",
    "order_number": 20
  },
  {
    "id": "9242077b-bfd1-48ec-a7c1-b47b7034336e",
    "paper": {
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": 1,
      "ref": "4005329065767",
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "brand_model": {
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "name": "Elephant Skin"
      },
      "gsm": "110",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "original_size": "A4"
    },
    "order_number": 862,
    "name": "Blank",
    "order_id": 0,
    "description": ""
  },
  {
    "tool": null,
    "name": "Black Series 5",
    "description": "Still far away from the perferct.",
    "order_id": 0,
    "order_number": 233,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/9277c5d7-a2e8-4fa5-842f-4c2d5230fb3d/600x600/645a77ac-9f57-4527-b24e-0a50fdae1529_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9277c5d7-a2e8-4fa5-842f-4c2d5230fb3d%2F600x600%2F645a77ac-9f57-4527-b24e-0a50fdae1529_600x600?alt=media&token=d1d3f37f-a613-4409-b8c4-5564933744a0"
      },
      "original": {
        "reference": "/stroke_photos/9277c5d7-a2e8-4fa5-842f-4c2d5230fb3d/645a77ac-9f57-4527-b24e-0a50fdae1529",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9277c5d7-a2e8-4fa5-842f-4c2d5230fb3d%2F645a77ac-9f57-4527-b24e-0a50fdae1529?alt=media&token=d5e02f6c-a65b-4144-aefd-334aa41cbdc1"
      }
    },
    "id": "9277c5d7-a2e8-4fa5-842f-4c2d5230fb3d",
    "paper": {
      "price": 1.35,
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "gsm": "250",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "original_size": "A7",
      "ref": "77725080001"
    }
  },
  {
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "brand": {},
      "brand_model": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "price": null,
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "70025010030",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "gsm": "250"
    },
    "order_id": 0,
    "id": "92c42f19-bc23-44a1-a51b-e6d9d5498f09",
    "order_number": 832,
    "name": "Blank",
    "description": ""
  },
  {
    "id": "92cb28e8-aebe-4243-a20d-de8874b004a0",
    "order_number": 378,
    "name": "Snake That Ate an Elephant",
    "description": "<p>I promise you, it's real</p>",
    "paper": {
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "name": null,
      "brand": {},
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "20180107",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": null,
      "gsm": "250",
      "original_size": "A7",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "tool": null,
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/92cb28e8-aebe-4243-a20d-de8874b004a0/37facd91-ba89-479b-bcb8-71a77b3aee38",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F92cb28e8-aebe-4243-a20d-de8874b004a0%2F37facd91-ba89-479b-bcb8-71a77b3aee38?alt=media&token=b15e60c7-3e85-4aa6-938a-53b24e9fb729"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/92cb28e8-aebe-4243-a20d-de8874b004a0/600x600/37facd91-ba89-479b-bcb8-71a77b3aee38_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F92cb28e8-aebe-4243-a20d-de8874b004a0%2F600x600%2F37facd91-ba89-479b-bcb8-71a77b3aee38_600x600?alt=media&token=36c7e085-bfb7-46b4-bc6a-e77f4e1406c7"
      }
    }
  },
  {
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "price": 19.3,
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "gsm": "640",
      "ref": "30075105",
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico"
      },
      "original_size": "75x105cm",
      "name": "tinto",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      }
    },
    "id": "92dc1758-e1c7-4428-af26-88b86ce5c547",
    "description": "",
    "tool": null,
    "order_number": 685,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F92dc1758-e1c7-4428-af26-88b86ce5c547%2Fc52bf952-d8ac-42cb-adec-77009799c587?alt=media&token=6d4f3501-e83a-4bef-aa70-9246855e16a1",
        "reference": "/stroke_photos/92dc1758-e1c7-4428-af26-88b86ce5c547/c52bf952-d8ac-42cb-adec-77009799c587"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F92dc1758-e1c7-4428-af26-88b86ce5c547%2F600x600%2Fc52bf952-d8ac-42cb-adec-77009799c587_600x600?alt=media&token=5b9f5ef1-e5bf-404b-b6d0-39584dea7d55",
        "reference": "stroke_photos/92dc1758-e1c7-4428-af26-88b86ce5c547/600x600/c52bf952-d8ac-42cb-adec-77009799c587_600x600"
      }
    },
    "name": "Blank",
    "order_id": 0
  },
  {
    "order_id": 0,
    "order_number": 28,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/92e447c7-af3e-40dd-91bb-3839a5c6a06d/600x600/21e6e6d8-2537-4148-8b15-e73cd3bed3fb_600x600",
        "download_url": "https://storage.googleapis.com/thank-you-project.appspot.com/stroke_photos%2F92e447c7-af3e-40dd-91bb-3839a5c6a06d%2F600x600%2F21e6e6d8-2537-4148-8b15-e73cd3bed3fb_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F92e447c7-af3e-40dd-91bb-3839a5c6a06d%2F21e6e6d8-2537-4148-8b15-e73cd3bed3fb?alt=media&token=e19581fe-c1e0-4492-a0d9-314e92ca14b2",
        "reference": "/stroke_photos/92e447c7-af3e-40dd-91bb-3839a5c6a06d/21e6e6d8-2537-4148-8b15-e73cd3bed3fb"
      }
    },
    "paper": {
      "ref": "44202129",
      "gsm": "200",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "original_size": "A4",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "name": "",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_surface": {},
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "tool": null,
    "name": "George",
    "description": "",
    "id": "92e447c7-af3e-40dd-91bb-3839a5c6a06d"
  },
  {
    "paper": {
      "ref": "77725080001",
      "original_size": "A7",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "price": 1.35,
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      }
    },
    "name": "Press-N-Lift",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F92f903e6-4075-4043-ab69-c0a16a881931%2Fb6aec504-6a3a-4f7d-b96c-ea53cde50d0f?alt=media&token=80e36972-2c42-4df1-855c-e1eac7814a6b",
        "reference": "/stroke_photos/92f903e6-4075-4043-ab69-c0a16a881931/b6aec504-6a3a-4f7d-b96c-ea53cde50d0f"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F92f903e6-4075-4043-ab69-c0a16a881931%2F600x600%2Fb6aec504-6a3a-4f7d-b96c-ea53cde50d0f_600x600?alt=media&token=8f149644-e664-4f54-966b-c2e20cf8d1ee",
        "reference": "stroke_photos/92f903e6-4075-4043-ab69-c0a16a881931/600x600/b6aec504-6a3a-4f7d-b96c-ea53cde50d0f_600x600"
      }
    },
    "order_id": 0,
    "tool": null,
    "description": "No sideways movement. Minimum Stroke possible",
    "id": "92f903e6-4075-4043-ab69-c0a16a881931",
    "order_number": 433
  },
  {
    "name": "",
    "description": "",
    "order_number": 1226,
    "id": "9305ad18-b7a7-4ed6-beb6-e76a9e72fa35"
  },
  {
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/930620ab-0446-463c-ae7a-c95fe843a729/600x600/bca6333f-87d4-434d-a36e-64318febf074_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F930620ab-0446-463c-ae7a-c95fe843a729%2F600x600%2Fbca6333f-87d4-434d-a36e-64318febf074_600x600?alt=media&token=38fe8c26-2572-4435-9b55-289733c1079d"
      },
      "original": {
        "reference": "/stroke_photos/930620ab-0446-463c-ae7a-c95fe843a729/bca6333f-87d4-434d-a36e-64318febf074",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F930620ab-0446-463c-ae7a-c95fe843a729%2Fbca6333f-87d4-434d-a36e-64318febf074?alt=media&token=99693f71-fa1a-4785-8f3b-3da8767cfa1e"
      }
    },
    "order_number": 427,
    "id": "930620ab-0446-463c-ae7a-c95fe843a729",
    "name": "Crown 2",
    "description": "What happnes if I do the same thing again? Almost the same result, yet so different.",
    "order_id": 0,
    "paper": {
      "gsm": "250",
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "price": 1.35,
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      }
    }
  },
  {
    "description": "",
    "order_number": 18,
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "44202129",
      "gsm": "200",
      "name": "",
      "original_size": "A4",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "paper_surface": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "price": null
    },
    "tool": {},
    "name": "A Lot Of Paint",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F93414f14-20f7-45d7-b434-712b3697feaa%2F600x600%2F6806cdb6-a34e-44d8-af18-e664832990af_600x600?alt=media&token=62c928d3-b8cb-4f72-9242-c4d7a717811f",
        "reference": "stroke_photos/93414f14-20f7-45d7-b434-712b3697feaa/600x600/6806cdb6-a34e-44d8-af18-e664832990af_600x600"
      },
      "original": {
        "reference": "/stroke_photos/93414f14-20f7-45d7-b434-712b3697feaa/6806cdb6-a34e-44d8-af18-e664832990af",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F93414f14-20f7-45d7-b434-712b3697feaa%2F6806cdb6-a34e-44d8-af18-e664832990af?alt=media&token=255cc976-0611-4263-830b-42e04de40f8f"
      }
    },
    "id": "93414f14-20f7-45d7-b434-712b3697feaa"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1106,
    "id": "935e00d0-b3ab-468f-bc8b-6279721473cc"
  },
  {
    "order_number": 1037,
    "description": "",
    "name": "",
    "id": "936eba18-3457-4969-8687-39d862acdef4"
  },
  {
    "paper": {
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "gsm": "250",
      "name": null,
      "ref": "77725080001",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": 1.35,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      }
    },
    "description": "",
    "order_id": 0,
    "id": "938c5e9c-a910-46de-8ac3-1061fb4aca24",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F938c5e9c-a910-46de-8ac3-1061fb4aca24%2F225caef9-d6be-472f-a19a-0ced20f25a91?alt=media&token=d5ededb9-9114-4b73-96d3-abab6a8f4084",
        "reference": "/stroke_photos/938c5e9c-a910-46de-8ac3-1061fb4aca24/225caef9-d6be-472f-a19a-0ced20f25a91"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F938c5e9c-a910-46de-8ac3-1061fb4aca24%2F600x600%2F225caef9-d6be-472f-a19a-0ced20f25a91_600x600?alt=media&token=917848d7-8494-4445-b853-52e6cad38e3d",
        "reference": "stroke_photos/938c5e9c-a910-46de-8ac3-1061fb4aca24/600x600/225caef9-d6be-472f-a19a-0ced20f25a91_600x600"
      }
    },
    "tool": null,
    "order_number": 155,
    "name": "Blank"
  },
  {
    "order_number": 1006,
    "description": "",
    "name": "",
    "id": "939d50ac-c40a-481e-9d70-064f99628725"
  },
  {
    "description": "",
    "id": "93ba53cf-fbf5-47ae-8b8f-4439e12c34c7",
    "order_number": 204,
    "paper": {
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "original_size": "A7",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "name": null,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "77725080001",
      "price": 1.35,
      "gsm": "250",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    },
    "tool": null,
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F93ba53cf-fbf5-47ae-8b8f-4439e12c34c7%2Fe16da8f0-79f2-4366-948a-8be9bee2cd52?alt=media&token=4032dca3-465e-48f5-bc99-92252d17f840",
        "reference": "/stroke_photos/93ba53cf-fbf5-47ae-8b8f-4439e12c34c7/e16da8f0-79f2-4366-948a-8be9bee2cd52"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F93ba53cf-fbf5-47ae-8b8f-4439e12c34c7%2F600x600%2Fe16da8f0-79f2-4366-948a-8be9bee2cd52_600x600?alt=media&token=86e0e1bd-6a06-486c-89f5-41f5874ef3d1",
        "reference": "stroke_photos/93ba53cf-fbf5-47ae-8b8f-4439e12c34c7/600x600/e16da8f0-79f2-4366-948a-8be9bee2cd52_600x600"
      }
    },
    "name": "A Natural"
  },
  {
    "order_number": 119,
    "id": "94333ae5-805b-4e07-acb0-ee33cdc54222",
    "order_id": 0,
    "description": "",
    "name": "Two Points 2",
    "tool": null,
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "original_size": "A7",
      "name": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250",
      "brand": {}
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/94333ae5-805b-4e07-acb0-ee33cdc54222/600x600/29c4643b-017a-4584-8f82-5db5593f1d85_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F94333ae5-805b-4e07-acb0-ee33cdc54222%2F600x600%2F29c4643b-017a-4584-8f82-5db5593f1d85_600x600?alt=media&token=4e734aa3-d146-4e3b-b78c-0fde7710cd86"
      },
      "original": {
        "reference": "/stroke_photos/94333ae5-805b-4e07-acb0-ee33cdc54222/29c4643b-017a-4584-8f82-5db5593f1d85",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F94333ae5-805b-4e07-acb0-ee33cdc54222%2F29c4643b-017a-4584-8f82-5db5593f1d85?alt=media&token=b9cbb133-e105-46ed-beae-f7c51b74f28c"
      }
    }
  },
  {
    "id": "944947bd-6006-4c47-9942-ba3f2074798e",
    "tool": null,
    "description": "",
    "name": "Blank",
    "order_id": 0,
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": "640",
      "price": 19.3,
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "ref": "30075105",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "name": "tinto",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "original_size": "75x105cm"
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F944947bd-6006-4c47-9942-ba3f2074798e%2F64fe89ea-4b25-4e13-8d1b-3aab9ea8141b?alt=media&token=9ca9a1f0-e752-4651-830a-dccf9e339148",
        "reference": "/stroke_photos/944947bd-6006-4c47-9942-ba3f2074798e/64fe89ea-4b25-4e13-8d1b-3aab9ea8141b"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/944947bd-6006-4c47-9942-ba3f2074798e/600x600/64fe89ea-4b25-4e13-8d1b-3aab9ea8141b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F944947bd-6006-4c47-9942-ba3f2074798e%2F600x600%2F64fe89ea-4b25-4e13-8d1b-3aab9ea8141b_600x600?alt=media&token=893923c8-c3bd-4cbf-93bd-bb52c226850f"
      }
    },
    "order_number": 679
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/94627c8f-f85a-40de-9b2a-e9e2c048e510/b9e2376d-f1bb-4e0b-9e13-905bd9df88ef",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F94627c8f-f85a-40de-9b2a-e9e2c048e510%2Fb9e2376d-f1bb-4e0b-9e13-905bd9df88ef?alt=media&token=148f0eb4-eed0-4020-a6c1-f6d74c2f87b2"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F94627c8f-f85a-40de-9b2a-e9e2c048e510%2F600x600%2Fb9e2376d-f1bb-4e0b-9e13-905bd9df88ef_600x600?alt=media&token=2201b494-23d2-4044-b6bf-1a81bfd66d9e",
        "reference": "stroke_photos/94627c8f-f85a-40de-9b2a-e9e2c048e510/600x600/b9e2376d-f1bb-4e0b-9e13-905bd9df88ef_600x600"
      }
    },
    "name": "Blank",
    "order_number": 693,
    "order_id": 0,
    "paper": {
      "ref": "30075105",
      "name": "tinto",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "gsm": "640",
      "original_size": "75x105cm",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "price": 19.3,
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      }
    },
    "tool": null,
    "id": "94627c8f-f85a-40de-9b2a-e9e2c048e510",
    "description": ""
  },
  {
    "order_id": 0,
    "paper": {
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "original_size": "A7",
      "gsm": "250",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "price": 1.35
    },
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F94cf5083-e7f7-4d97-8628-b7a61eccc1c1%2F600x600%2Fa22e5317-466a-4837-9bc8-4dce41c0b620_600x600?alt=media&token=683a3476-72ab-450e-944d-aed0371d0877",
        "reference": "stroke_photos/94cf5083-e7f7-4d97-8628-b7a61eccc1c1/600x600/a22e5317-466a-4837-9bc8-4dce41c0b620_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F94cf5083-e7f7-4d97-8628-b7a61eccc1c1%2Fa22e5317-466a-4837-9bc8-4dce41c0b620?alt=media&token=8029521c-22e3-497a-8b3f-c1021c989814",
        "reference": "/stroke_photos/94cf5083-e7f7-4d97-8628-b7a61eccc1c1/a22e5317-466a-4837-9bc8-4dce41c0b620"
      }
    },
    "description": "",
    "tool": null,
    "id": "94cf5083-e7f7-4d97-8628-b7a61eccc1c1",
    "order_number": 763
  },
  {
    "name": "",
    "order_number": 1074,
    "description": "",
    "id": "94ed1284-515e-4b03-98f7-4387bec6f18b"
  },
  {
    "name": "Blank",
    "id": "94ffaa74-351b-407d-9614-d68004718ef8",
    "paper": {
      "original_size": "A4",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": null,
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand_model": {},
      "gsm": null
    },
    "description": "",
    "order_number": 926,
    "order_id": 0
  },
  {
    "description": "",
    "order_number": 1160,
    "name": "",
    "id": "957ad600-e758-454b-92dd-d636818f51de"
  },
  {
    "description": "",
    "order_id": 0,
    "name": "Blank",
    "paper": {
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "brand_model": {},
      "ref": "70025010030",
      "color": {
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl"
      },
      "price": null
    },
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F95812d3a-a264-420b-8018-00a9c08ccc6e%2F600x600%2F597fd77b-08ec-4180-b4b7-e7a1d0bbe05e_600x600?alt=media&token=642fb395-fc10-4c9d-8991-07a595a96e22",
        "reference": "stroke_photos/95812d3a-a264-420b-8018-00a9c08ccc6e/600x600/597fd77b-08ec-4180-b4b7-e7a1d0bbe05e_600x600"
      },
      "original": {
        "reference": "/stroke_photos/95812d3a-a264-420b-8018-00a9c08ccc6e/597fd77b-08ec-4180-b4b7-e7a1d0bbe05e",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F95812d3a-a264-420b-8018-00a9c08ccc6e%2F597fd77b-08ec-4180-b4b7-e7a1d0bbe05e?alt=media&token=fd6090aa-2d34-4304-8a05-3d446c08e60c"
      }
    },
    "order_number": 583,
    "id": "95812d3a-a264-420b-8018-00a9c08ccc6e"
  },
  {
    "order_number": 1092,
    "description": "",
    "name": "",
    "id": "9593c69c-0a02-4070-b6d2-40c3900e6ddd"
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F95fcd7e8-1878-4cf9-bdb1-5203cb36cce5%2F68304174-1c74-44d8-8f46-e00455e3d2e6?alt=media&token=5c9d978c-552e-4316-8c0b-b387e83fbfca",
        "reference": "/stroke_photos/95fcd7e8-1878-4cf9-bdb1-5203cb36cce5/68304174-1c74-44d8-8f46-e00455e3d2e6"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F95fcd7e8-1878-4cf9-bdb1-5203cb36cce5%2F600x600%2F68304174-1c74-44d8-8f46-e00455e3d2e6_600x600?alt=media&token=1b0a0a66-5579-4ef6-9cff-7e71f5657e00",
        "reference": "stroke_photos/95fcd7e8-1878-4cf9-bdb1-5203cb36cce5/600x600/68304174-1c74-44d8-8f46-e00455e3d2e6_600x600"
      }
    },
    "name": "Shaky",
    "order_number": 304,
    "id": "95fcd7e8-1878-4cf9-bdb1-5203cb36cce5",
    "order_id": 0,
    "description": "<p>I can't belive my hand shakes so much I can't make a straight line! Curve!</p>",
    "tool": null,
    "paper": {
      "original_size": "A7",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": null
    }
  },
  {
    "id": "96218e81-c1df-4489-a38d-714ceeb3e5fe",
    "order_id": 0,
    "tool": null,
    "name": "Blank",
    "order_number": 680,
    "paper": {
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "gsm": "640",
      "price": 19.3,
      "name": "tinto",
      "ref": "30075105",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "original_size": "75x105cm",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Artistico"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      }
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/96218e81-c1df-4489-a38d-714ceeb3e5fe/600x600/504e35a1-6f3a-4d43-8864-31a0c53ce222_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F96218e81-c1df-4489-a38d-714ceeb3e5fe%2F600x600%2F504e35a1-6f3a-4d43-8864-31a0c53ce222_600x600?alt=media&token=ca663f9a-1b68-4c4e-bf4c-338f8807772a"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F96218e81-c1df-4489-a38d-714ceeb3e5fe%2F504e35a1-6f3a-4d43-8864-31a0c53ce222?alt=media&token=402fda0b-8d3e-4417-92bf-69b3d26c088a",
        "reference": "/stroke_photos/96218e81-c1df-4489-a38d-714ceeb3e5fe/504e35a1-6f3a-4d43-8864-31a0c53ce222"
      }
    },
    "description": ""
  },
  {
    "description": "",
    "order_number": 1174,
    "name": "",
    "id": "9649ef6e-b5b4-444e-b380-c768328e6192"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1114,
    "id": "966a01bb-746b-4258-bef7-726b19f9bad5"
  },
  {
    "id": "96766c3a-07e9-4091-bfa3-06d59374bc15",
    "order_id": 0,
    "name": "Blank",
    "description": "",
    "paper": {
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "gsm": "640",
      "ref": "30075105",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": 19.3,
      "brand_model": {
        "name": "Artistico",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "original_size": "75x105cm",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "name": "tinto"
    },
    "order_number": 993
  },
  {
    "order_number": 651,
    "tool": null,
    "order_id": 0,
    "name": "Blank",
    "id": "9689c6a0-3f0f-4c20-b30b-2f0ac570da8e",
    "description": "",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9689c6a0-3f0f-4c20-b30b-2f0ac570da8e%2F600x600%2Fc3faffcb-810c-43c2-8dfe-45d686ed3d94_600x600?alt=media&token=e892e371-5313-4031-9ffe-34cf36becf8e",
        "reference": "stroke_photos/9689c6a0-3f0f-4c20-b30b-2f0ac570da8e/600x600/c3faffcb-810c-43c2-8dfe-45d686ed3d94_600x600"
      },
      "original": {
        "reference": "/stroke_photos/9689c6a0-3f0f-4c20-b30b-2f0ac570da8e/c3faffcb-810c-43c2-8dfe-45d686ed3d94",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9689c6a0-3f0f-4c20-b30b-2f0ac570da8e%2Fc3faffcb-810c-43c2-8dfe-45d686ed3d94?alt=media&token=597c0bc7-8cba-455c-958f-fe5d56f7c66d"
      }
    },
    "paper": {
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "original_size": "75x105cm",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": "30075105",
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "price": 19.3,
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "name": "tinto",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "gsm": "640",
      "brand_model": {
        "name": "Artistico",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      }
    }
  },
  {
    "paper": {
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "price": 1,
      "ref": "4005329065767",
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      },
      "name": null,
      "gsm": "110",
      "original_size": "A4",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2"
      }
    },
    "order_number": 866,
    "name": "Blank",
    "description": "",
    "order_id": 0,
    "id": "96aacee4-9435-4bef-86b9-e22f3fdd9a28"
  },
  {
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F96b0cdb7-7450-42b0-842c-579d4906a717%2Fda52b91a-bf70-4a87-b332-6f89f95b21b8?alt=media&token=f63a47ae-23e4-4073-9460-1472bc0c8165",
        "reference": "/stroke_photos/96b0cdb7-7450-42b0-842c-579d4906a717/da52b91a-bf70-4a87-b332-6f89f95b21b8"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/96b0cdb7-7450-42b0-842c-579d4906a717/600x600/da52b91a-bf70-4a87-b332-6f89f95b21b8_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F96b0cdb7-7450-42b0-842c-579d4906a717%2F600x600%2Fda52b91a-bf70-4a87-b332-6f89f95b21b8_600x600?alt=media&token=595db101-3baf-4eeb-aa43-feb8bd0671a9"
      }
    },
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "gsm": "250",
      "original_size": "A7",
      "price": null
    },
    "tool": null,
    "description": "<p>Not bad</p>",
    "order_number": 371,
    "name": "Just a Good Double",
    "id": "96b0cdb7-7450-42b0-842c-579d4906a717"
  },
  {
    "description": "",
    "order_number": 1101,
    "name": "",
    "id": "9734fb40-c24e-45ab-ba89-6b4feff11e4f"
  },
  {
    "id": "9736bdb8-fa71-4973-a326-5830db438730",
    "order_id": 0,
    "paper": {
      "ref": "4005329065767",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": 1,
      "brand_model": {
        "name": "Elephant Skin",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2"
      },
      "name": null,
      "original_size": "A4",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "110",
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701"
    },
    "order_number": 892,
    "name": "Blank",
    "description": ""
  },
  {
    "description": "",
    "name": "",
    "order_number": 1101,
    "id": "9746bfc6-4b4e-4a63-81f7-b0bbb7025995"
  },
  {
    "name": "Blank",
    "order_id": 0,
    "id": "9746f065-5355-42a1-b986-f073f05b0ccc",
    "order_number": 859,
    "description": "",
    "paper": {
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "original_size": "A4",
      "gsm": "110",
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "name": "Elephant Skin"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "4005329065767",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "price": 1,
      "name": null,
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      }
    }
  },
  {
    "order_number": 387,
    "name": "Tiny Double Trouble",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/97480e39-09aa-4b8d-8c7d-e6f7a14896e8/600x600/e46bad54-0916-44a4-bdf7-beb44b6f7e03_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F97480e39-09aa-4b8d-8c7d-e6f7a14896e8%2F600x600%2Fe46bad54-0916-44a4-bdf7-beb44b6f7e03_600x600?alt=media&token=799cdb0d-9aec-4a4d-8f72-358fcdde1c31"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F97480e39-09aa-4b8d-8c7d-e6f7a14896e8%2Fe46bad54-0916-44a4-bdf7-beb44b6f7e03?alt=media&token=8632c96a-f82b-4164-8437-6d2faae970c0",
        "reference": "/stroke_photos/97480e39-09aa-4b8d-8c7d-e6f7a14896e8/e46bad54-0916-44a4-bdf7-beb44b6f7e03"
      }
    },
    "id": "97480e39-09aa-4b8d-8c7d-e6f7a14896e8",
    "paper": {
      "original_size": "A7",
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "250",
      "ref": "20180107",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86"
    },
    "tool": null,
    "description": "<p>Tiny Statement</p>"
  },
  {
    "id": "97637793-ab67-4bcb-a8eb-645ced8704fe",
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/97637793-ab67-4bcb-a8eb-645ced8704fe/53070461-239d-4ff0-b1ba-fd561315641f",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F97637793-ab67-4bcb-a8eb-645ced8704fe%2F53070461-239d-4ff0-b1ba-fd561315641f?alt=media&token=a5faec58-b2ca-4b42-8b11-d54567d673b9"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/97637793-ab67-4bcb-a8eb-645ced8704fe/600x600/53070461-239d-4ff0-b1ba-fd561315641f_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F97637793-ab67-4bcb-a8eb-645ced8704fe%2F600x600%2F53070461-239d-4ff0-b1ba-fd561315641f_600x600?alt=media&token=fad0135d-c782-4041-a62c-da3e7fd47a57"
      }
    },
    "name": "Press-N-Scratch 2",
    "order_number": 435,
    "description": "I changed the direction and somehow expected delicateness again. Yet got this monster.",
    "paper": {
      "name": null,
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "original_size": "A7",
      "price": 1.35,
      "ref": "77725080001",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      }
    },
    "tool": null
  },
  {
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F97cd22a9-9bc3-47ec-8a6c-28a52bb78ad4%2F600x600%2F1695c1d0-d99d-471f-84ac-03daec8257d8_600x600?alt=media&token=d5da64fd-35c9-41a1-a9e2-81a1c5858e6f",
        "reference": "stroke_photos/97cd22a9-9bc3-47ec-8a6c-28a52bb78ad4/600x600/1695c1d0-d99d-471f-84ac-03daec8257d8_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F97cd22a9-9bc3-47ec-8a6c-28a52bb78ad4%2F1695c1d0-d99d-471f-84ac-03daec8257d8?alt=media&token=a62cb9ab-4f3e-4cff-ba65-dde51cecb193",
        "reference": "/stroke_photos/97cd22a9-9bc3-47ec-8a6c-28a52bb78ad4/1695c1d0-d99d-471f-84ac-03daec8257d8"
      }
    },
    "description": "",
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "gsm": "250",
      "name": null,
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {}
    },
    "name": "Just Something Brilliant",
    "order_number": 147,
    "order_id": 0,
    "id": "97cd22a9-9bc3-47ec-8a6c-28a52bb78ad4"
  },
  {
    "tool": null,
    "description": "<p>...and then just do the ending?</p>",
    "name": "Just the Ending",
    "order_number": 390,
    "order_id": 0,
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "20180107",
      "name": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "price": null,
      "gsm": "250",
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "brand": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    },
    "id": "97cfb5b0-df66-4bd5-a7b3-a21e88698080",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F97cfb5b0-df66-4bd5-a7b3-a21e88698080%2F600x600%2F72f48e00-f716-43a3-af8d-be5068ca5b2b_600x600?alt=media&token=9bca7bee-edb9-4bd3-bb8c-fc1dc0e4854d",
        "reference": "stroke_photos/97cfb5b0-df66-4bd5-a7b3-a21e88698080/600x600/72f48e00-f716-43a3-af8d-be5068ca5b2b_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F97cfb5b0-df66-4bd5-a7b3-a21e88698080%2F72f48e00-f716-43a3-af8d-be5068ca5b2b?alt=media&token=903cd96b-eeec-4336-a335-940d500c67a4",
        "reference": "/stroke_photos/97cfb5b0-df66-4bd5-a7b3-a21e88698080/72f48e00-f716-43a3-af8d-be5068ca5b2b"
      }
    }
  },
  {
    "order_number": 1118,
    "name": "",
    "description": "",
    "id": "97e39796-8d45-44b3-bea3-aeee91adf01b"
  },
  {
    "name": "",
    "order_number": 1138,
    "description": "",
    "id": "981eae44-9902-4382-a339-777328483636"
  },
  {
    "order_number": 1089,
    "description": "",
    "name": "",
    "id": "9830b26d-31d5-476e-b198-4a5f3bc577f9"
  },
  {
    "id": "9836c95a-8d82-4835-8a4d-d1dac5a47eb4",
    "order_id": 0,
    "name": "SF Thinner",
    "description": "",
    "order_number": 67,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9836c95a-8d82-4835-8a4d-d1dac5a47eb4%2Fbed7041e-6854-47f9-ba75-506056cb3cb8?alt=media&token=7134c310-b206-4041-aa6b-592d5558a2a6",
        "reference": "/stroke_photos/9836c95a-8d82-4835-8a4d-d1dac5a47eb4/bed7041e-6854-47f9-ba75-506056cb3cb8"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/9836c95a-8d82-4835-8a4d-d1dac5a47eb4/600x600/bed7041e-6854-47f9-ba75-506056cb3cb8_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9836c95a-8d82-4835-8a4d-d1dac5a47eb4%2F600x600%2Fbed7041e-6854-47f9-ba75-506056cb3cb8_600x600?alt=media&token=635e6524-22b0-40b4-8f24-fdbd8c71b809"
      }
    },
    "tool": null,
    "paper": {
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "price": null,
      "paper_surface": {},
      "name": "",
      "gsm": "200",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A4",
      "ref": "44202129",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4"
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1072,
    "id": "989a9bd7-f4f2-4ba1-9075-6e4694fa65cd"
  },
  {
    "order_number": 1084,
    "description": "",
    "name": "",
    "id": "98aec5d9-f3a1-4aca-9448-ccc5423637e5"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/98e47f0c-630b-495b-9a34-5ae48c901832/510b698d-4342-401c-bb61-1f48b06d4442",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F98e47f0c-630b-495b-9a34-5ae48c901832%2F510b698d-4342-401c-bb61-1f48b06d4442?alt=media&token=6ff3bbb5-30d4-4b7c-a05f-45d8178cf73b"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F98e47f0c-630b-495b-9a34-5ae48c901832%2F600x600%2F510b698d-4342-401c-bb61-1f48b06d4442_600x600?alt=media&token=e23e62f7-9d18-4b8d-a9ba-4d8153482573",
        "reference": "stroke_photos/98e47f0c-630b-495b-9a34-5ae48c901832/600x600/510b698d-4342-401c-bb61-1f48b06d4442_600x600"
      }
    },
    "id": "98e47f0c-630b-495b-9a34-5ae48c901832",
    "order_id": 0,
    "tool": null,
    "order_number": 423,
    "description": "Span that end into a one. It's got some hair on it too. Reminds me of the logo of Three, the mobile operator.",
    "paper": {
      "price": 1.35,
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "gsm": "250",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "ref": "77725080001"
    },
    "name": "Ascension 3"
  },
  {
    "order_number": 674,
    "photos": {
      "original": {
        "reference": "/stroke_photos/992a694e-d8ce-436e-8c95-6773b6ddca99/151ac328-376f-4c79-9f00-0b2da3187157",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F992a694e-d8ce-436e-8c95-6773b6ddca99%2F151ac328-376f-4c79-9f00-0b2da3187157?alt=media&token=15615c74-5149-4b86-977c-049d1b79b058"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/992a694e-d8ce-436e-8c95-6773b6ddca99/600x600/151ac328-376f-4c79-9f00-0b2da3187157_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F992a694e-d8ce-436e-8c95-6773b6ddca99%2F600x600%2F151ac328-376f-4c79-9f00-0b2da3187157_600x600?alt=media&token=08bbdb69-cb28-46bf-b8d0-42f17e654128"
      }
    },
    "description": "",
    "name": "Blank",
    "id": "992a694e-d8ce-436e-8c95-6773b6ddca99",
    "tool": null,
    "order_id": 0,
    "paper": {
      "original_size": "75x105cm",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "price": 19.3,
      "ref": "30075105",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Artistico"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "gsm": "640",
      "name": "tinto"
    }
  },
  {
    "description": "I tried swinging the brush above the paper, the lowering it until I make one stroke.",
    "photos": {
      "original": {
        "reference": "/stroke_photos/9947888c-4eae-41bf-9cfa-86142862549d/8f82745a-9a89-4b00-820d-2aaa141b597e",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9947888c-4eae-41bf-9cfa-86142862549d%2F8f82745a-9a89-4b00-820d-2aaa141b597e?alt=media&token=319c240f-3c67-4305-9f54-f5b9c1ffd0d1"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9947888c-4eae-41bf-9cfa-86142862549d%2F600x600%2F8f82745a-9a89-4b00-820d-2aaa141b597e_600x600?alt=media&token=2d6c3884-5dea-4aca-93d4-21ce93a66ffe",
        "reference": "stroke_photos/9947888c-4eae-41bf-9cfa-86142862549d/600x600/8f82745a-9a89-4b00-820d-2aaa141b597e_600x600"
      }
    },
    "paper": {
      "original_size": "A7",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": "77725080001",
      "gsm": "250",
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    },
    "id": "9947888c-4eae-41bf-9cfa-86142862549d",
    "order_number": 437,
    "tool": null,
    "name": "Pendulum",
    "order_id": 0
  },
  {
    "id": "99662d2a-6de9-4a06-a4b3-d4cc9b53b4b7",
    "order_id": 0,
    "tool": null,
    "name": "Side Brush With Double Color",
    "order_number": 381,
    "description": "<p>Okay, but not enough to continue more tries</p>",
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "price": null,
      "name": null,
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "gsm": "250",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F99662d2a-6de9-4a06-a4b3-d4cc9b53b4b7%2Fa9720cab-5522-4991-b009-fd9635b6da7d?alt=media&token=332314fe-cc69-4256-9a82-6ace84e92da1",
        "reference": "/stroke_photos/99662d2a-6de9-4a06-a4b3-d4cc9b53b4b7/a9720cab-5522-4991-b009-fd9635b6da7d"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/99662d2a-6de9-4a06-a4b3-d4cc9b53b4b7/600x600/a9720cab-5522-4991-b009-fd9635b6da7d_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F99662d2a-6de9-4a06-a4b3-d4cc9b53b4b7%2F600x600%2Fa9720cab-5522-4991-b009-fd9635b6da7d_600x600?alt=media&token=2c84808a-64c5-4216-9d06-fa863abb6316"
      }
    }
  },
  {
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "gsm": "250",
      "ref": "20180107",
      "name": null
    },
    "description": "<p>Getting longer</p>",
    "order_id": 0,
    "order_number": 353,
    "id": "997a9388-793a-4310-be11-46bd0a332f54",
    "name": "Another Stroke",
    "photos": {
      "original": {
        "reference": "/stroke_photos/997a9388-793a-4310-be11-46bd0a332f54/818dc287-36a1-4cfa-81e3-077412ed0653",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F997a9388-793a-4310-be11-46bd0a332f54%2F818dc287-36a1-4cfa-81e3-077412ed0653?alt=media&token=0ce98fe9-cac4-4a7c-b662-68881e48a8d8"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/997a9388-793a-4310-be11-46bd0a332f54/600x600/818dc287-36a1-4cfa-81e3-077412ed0653_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F997a9388-793a-4310-be11-46bd0a332f54%2F600x600%2F818dc287-36a1-4cfa-81e3-077412ed0653_600x600?alt=media&token=54571b7e-efd1-4a69-9840-db6ccdf69b45"
      }
    },
    "tool": null
  },
  {
    "tool": null,
    "order_number": 187,
    "name": "Blank",
    "order_id": 0,
    "id": "997ab5ce-ba27-4e58-bca4-fd5935b162d2",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/997ab5ce-ba27-4e58-bca4-fd5935b162d2/600x600/ebd5e0d2-7855-447a-b006-82008bc91bd1_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F997ab5ce-ba27-4e58-bca4-fd5935b162d2%2F600x600%2Febd5e0d2-7855-447a-b006-82008bc91bd1_600x600?alt=media&token=0c0ca42c-3dce-490e-ae70-ed099359221b"
      },
      "original": {
        "reference": "/stroke_photos/997ab5ce-ba27-4e58-bca4-fd5935b162d2/ebd5e0d2-7855-447a-b006-82008bc91bd1",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F997ab5ce-ba27-4e58-bca4-fd5935b162d2%2Febd5e0d2-7855-447a-b006-82008bc91bd1?alt=media&token=31357530-a88f-44ca-8e69-3c0c86a6a6da"
      }
    },
    "paper": {
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": 1.35,
      "ref": "77725080001",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "name": null,
      "original_size": "A7",
      "gsm": "250",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      }
    },
    "description": ""
  },
  {
    "name": "",
    "order_number": 1141,
    "description": "",
    "id": "999e31e8-7df7-4aaf-b588-da4647c23481"
  },
  {
    "order_number": 59,
    "id": "99d6791b-2710-409d-9057-6760b4e96833",
    "tool": null,
    "name": "Smile Number 1",
    "description": "",
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/99d6791b-2710-409d-9057-6760b4e96833/cb6b5b6c-13ac-4a64-bc38-2d05b9d7d82d",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F99d6791b-2710-409d-9057-6760b4e96833%2Fcb6b5b6c-13ac-4a64-bc38-2d05b9d7d82d?alt=media&token=1d6a41d0-550d-4899-9a97-ef1472b30a88"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/99d6791b-2710-409d-9057-6760b4e96833/600x600/cb6b5b6c-13ac-4a64-bc38-2d05b9d7d82d_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F99d6791b-2710-409d-9057-6760b4e96833%2F600x600%2Fcb6b5b6c-13ac-4a64-bc38-2d05b9d7d82d_600x600?alt=media&token=f97559fe-777d-4382-ba9e-8e83533a810d"
      }
    },
    "paper": {
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "gsm": "200",
      "price": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": "",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "paper_surface": {},
      "ref": "44202129",
      "original_size": "A4"
    }
  },
  {
    "id": "99d99fae-8de9-408f-b3e4-12f169d4c597",
    "paper": {
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "77725080001",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "price": 1.35,
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "original_size": "A7",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "gsm": "250",
      "name": null
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/99d99fae-8de9-408f-b3e4-12f169d4c597/03ef1c0a-6d67-4ab7-b529-726cf7dea5ed",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F99d99fae-8de9-408f-b3e4-12f169d4c597%2F03ef1c0a-6d67-4ab7-b529-726cf7dea5ed?alt=media&token=db566f78-45d8-4269-ac9d-4d63fe1bab37"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/99d99fae-8de9-408f-b3e4-12f169d4c597/600x600/03ef1c0a-6d67-4ab7-b529-726cf7dea5ed_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F99d99fae-8de9-408f-b3e4-12f169d4c597%2F600x600%2F03ef1c0a-6d67-4ab7-b529-726cf7dea5ed_600x600?alt=media&token=67418093-ab48-48bd-b6b9-9ba932ae1f8a"
      }
    },
    "name": "Blank",
    "order_number": 178,
    "description": "",
    "order_id": 0,
    "tool": null
  },
  {
    "order_id": 0,
    "name": "Blank",
    "description": "",
    "id": "9a14c742-6e19-4ca5-b910-34c8fd204b3b",
    "order_number": 938,
    "paper": {
      "brand": {},
      "gsm": null,
      "brand_model": {},
      "ref": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4"
    }
  },
  {
    "order_number": 312,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9a65af29-bb2a-450a-8ae8-ca3d7857b3b5%2F600x600%2Fab5ad762-708a-4924-9c9a-0c84b33f4cc5_600x600?alt=media&token=3cc4cbc6-83ba-4f97-ac30-ba5d4596cf0a",
        "reference": "stroke_photos/9a65af29-bb2a-450a-8ae8-ca3d7857b3b5/600x600/ab5ad762-708a-4924-9c9a-0c84b33f4cc5_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9a65af29-bb2a-450a-8ae8-ca3d7857b3b5%2Fab5ad762-708a-4924-9c9a-0c84b33f4cc5?alt=media&token=29769587-eefc-4c8f-90f2-d09b6c3e7602",
        "reference": "/stroke_photos/9a65af29-bb2a-450a-8ae8-ca3d7857b3b5/ab5ad762-708a-4924-9c9a-0c84b33f4cc5"
      }
    },
    "tool": null,
    "name": "Meh",
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "name": null,
      "brand": {},
      "ref": "20180107",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": null
    },
    "description": "<p>Still not getting the right results. Sometimes I wonder if I am doing something wrong. Everything wrong.</p>",
    "order_id": 0,
    "id": "9a65af29-bb2a-450a-8ae8-ca3d7857b3b5"
  },
  {
    "id": "9a9f945e-b589-4ad5-a9a6-70f31e9b40c1",
    "paper": {
      "gsm": "110",
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "name": "Elephant Skin"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "4005329065767",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "original_size": "A4",
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "price": 1
    },
    "name": "Blank",
    "order_number": 858,
    "description": "",
    "order_id": 0
  },
  {
    "name": "BS Less Paint",
    "order_id": 0,
    "id": "9ad08de0-b802-4469-9dc1-027575a4aa93",
    "tool": null,
    "order_number": 36,
    "paper": {
      "paper_surface": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "price": null,
      "gsm": "200",
      "name": "",
      "ref": "44202129",
      "original_size": "A4"
    },
    "description": "",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9ad08de0-b802-4469-9dc1-027575a4aa93%2F00724245-a25b-468f-b8aa-e4dd9e0cb790?alt=media&token=04914ce4-bea9-4a47-9404-4301d3b80120",
        "reference": "/stroke_photos/9ad08de0-b802-4469-9dc1-027575a4aa93/00724245-a25b-468f-b8aa-e4dd9e0cb790"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9ad08de0-b802-4469-9dc1-027575a4aa93%2F600x600%2F00724245-a25b-468f-b8aa-e4dd9e0cb790_600x600?alt=media&token=3094ee1b-692b-4c97-91d0-d1e815202bf8",
        "reference": "stroke_photos/9ad08de0-b802-4469-9dc1-027575a4aa93/600x600/00724245-a25b-468f-b8aa-e4dd9e0cb790_600x600"
      }
    }
  },
  {
    "paper": {
      "ref": "20170703",
      "price": 0.4,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "gsm": "250",
      "original_size": "A7",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "name": null,
      "brand": {}
    },
    "order_number": 549,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9ad7535c-eb99-4771-ab2f-d3e1dc929590%2Fc6c4a498-57dd-4abe-8d11-98bc873e951e?alt=media&token=27ec239e-37e1-4dc1-8f04-277646522fd4",
        "reference": "/stroke_photos/9ad7535c-eb99-4771-ab2f-d3e1dc929590/c6c4a498-57dd-4abe-8d11-98bc873e951e"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9ad7535c-eb99-4771-ab2f-d3e1dc929590%2F600x600%2Fc6c4a498-57dd-4abe-8d11-98bc873e951e_600x600?alt=media&token=aec64ae3-4ff4-4749-b0b7-1dae82cd2bc2",
        "reference": "stroke_photos/9ad7535c-eb99-4771-ab2f-d3e1dc929590/600x600/c6c4a498-57dd-4abe-8d11-98bc873e951e_600x600"
      }
    },
    "tool": null,
    "description": "",
    "id": "9ad7535c-eb99-4771-ab2f-d3e1dc929590",
    "name": "Blank",
    "order_id": 0
  },
  {
    "name": "",
    "description": "",
    "order_number": 1188,
    "id": "9b438472-e0fb-4633-85d3-0262b3260e28"
  },
  {
    "name": "Blank",
    "tool": null,
    "paper": {
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "gsm": "250",
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": 0.4,
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "20170703",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "order_id": 0,
    "description": "",
    "order_number": 554,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9be47f38-58e5-4da6-8b51-78e161704dff%2F600x600%2F6680673c-511b-40c6-bd32-d78a2cc95fca_600x600?alt=media&token=13e9b302-d945-4962-93a3-b26061f43ba3",
        "reference": "stroke_photos/9be47f38-58e5-4da6-8b51-78e161704dff/600x600/6680673c-511b-40c6-bd32-d78a2cc95fca_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9be47f38-58e5-4da6-8b51-78e161704dff%2F6680673c-511b-40c6-bd32-d78a2cc95fca?alt=media&token=1dd5e3f6-698c-4f98-94a7-d69cebfbf0db",
        "reference": "/stroke_photos/9be47f38-58e5-4da6-8b51-78e161704dff/6680673c-511b-40c6-bd32-d78a2cc95fca"
      }
    },
    "id": "9be47f38-58e5-4da6-8b51-78e161704dff"
  },
  {
    "tool": null,
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A4",
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {},
      "brand": {},
      "gsm": null,
      "price": null,
      "ref": null
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/9bfa1a3f-9ae4-4c26-9343-748cbe695add/600x600/c7b56bac-5363-43d1-8036-63001fcab314_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9bfa1a3f-9ae4-4c26-9343-748cbe695add%2F600x600%2Fc7b56bac-5363-43d1-8036-63001fcab314_600x600?alt=media&token=582e4da7-6382-4cb4-a7dd-cf94c73ba091"
      },
      "original": {
        "reference": "/stroke_photos/9bfa1a3f-9ae4-4c26-9343-748cbe695add/c7b56bac-5363-43d1-8036-63001fcab314",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9bfa1a3f-9ae4-4c26-9343-748cbe695add%2Fc7b56bac-5363-43d1-8036-63001fcab314?alt=media&token=049d6175-1586-43dc-9abe-1f4d4303e804"
      }
    },
    "id": "9bfa1a3f-9ae4-4c26-9343-748cbe695add",
    "description": "",
    "order_id": 0,
    "name": "Same White Paint",
    "order_number": 452
  },
  {
    "tool": null,
    "id": "9c0e6b46-213e-4fdc-aef1-bd36ddd531ca",
    "name": "Blank",
    "paper": {
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "gsm": "250",
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "price": 1.35,
      "original_size": "A7",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "77725080001"
    },
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/9c0e6b46-213e-4fdc-aef1-bd36ddd531ca/600x600/e035b32e-c525-4671-b971-d3c0d10f9495_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9c0e6b46-213e-4fdc-aef1-bd36ddd531ca%2F600x600%2Fe035b32e-c525-4671-b971-d3c0d10f9495_600x600?alt=media&token=4fc8c2cf-0ae3-4a9a-b6b5-3f2b5bc65a1e"
      },
      "original": {
        "reference": "/stroke_photos/9c0e6b46-213e-4fdc-aef1-bd36ddd531ca/e035b32e-c525-4671-b971-d3c0d10f9495",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9c0e6b46-213e-4fdc-aef1-bd36ddd531ca%2Fe035b32e-c525-4671-b971-d3c0d10f9495?alt=media&token=45cd9831-159b-49f7-ac56-0747f6f4f9bb"
      }
    },
    "order_number": 775,
    "order_id": 0
  },
  {
    "description": "",
    "name": "",
    "order_number": 1111,
    "id": "9c331e0b-0c71-47d7-8417-fc81d1ffc0ae"
  },
  {
    "order_number": 317,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9c537a05-7352-4745-b73c-76f8263cc0e8%2F600x600%2F8afe5301-3caf-4abb-b109-1e331de1a5e0_600x600?alt=media&token=c9e2cefd-7286-4a97-a288-35272b9d05d5",
        "reference": "stroke_photos/9c537a05-7352-4745-b73c-76f8263cc0e8/600x600/8afe5301-3caf-4abb-b109-1e331de1a5e0_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9c537a05-7352-4745-b73c-76f8263cc0e8%2F8afe5301-3caf-4abb-b109-1e331de1a5e0?alt=media&token=254b3eea-99ee-4145-a517-aa6d6afeb60c",
        "reference": "/stroke_photos/9c537a05-7352-4745-b73c-76f8263cc0e8/8afe5301-3caf-4abb-b109-1e331de1a5e0"
      }
    },
    "id": "9c537a05-7352-4745-b73c-76f8263cc0e8",
    "name": "Back To Bad",
    "order_id": 0,
    "paper": {
      "name": null,
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20180107",
      "brand": {},
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250"
    },
    "description": "<p>Too many things to say, so just skip</p>",
    "tool": null
  },
  {
    "description": "",
    "order_number": 1058,
    "name": "",
    "id": "9c80d5d2-7827-40e9-bd2f-1ec53f6887f8"
  },
  {
    "order_number": 1149,
    "description": "",
    "name": "",
    "id": "9cbe9f00-76b9-4b08-a187-78f3ac51eb78"
  },
  {
    "description": "",
    "order_number": 1014,
    "name": "",
    "id": "9cc71d30-3c2c-4e2b-a67c-38e85fcf7074"
  },
  {
    "order_number": 761,
    "paper": {
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "price": 1.35,
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "77725080001",
      "name": null,
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "gsm": "250"
    },
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/9d1b92ed-586a-480a-bb99-56edbcc359f4/99f2ef99-e6af-42f3-ac0c-4d492fd70fd1",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9d1b92ed-586a-480a-bb99-56edbcc359f4%2F99f2ef99-e6af-42f3-ac0c-4d492fd70fd1?alt=media&token=f622e095-54b0-4d51-a31a-15197be97907"
      }
    },
    "tool": null,
    "id": "9d1b92ed-586a-480a-bb99-56edbcc359f4",
    "order_id": 0,
    "name": "Blank"
  },
  {
    "description": "",
    "order_number": 167,
    "name": "Blank",
    "paper": {
      "ref": "77725080001",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "price": 1.35,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "original_size": "A7",
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      }
    },
    "tool": null,
    "order_id": 0,
    "id": "9d5db00a-99b0-47cc-ba32-e278399216cd",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/9d5db00a-99b0-47cc-ba32-e278399216cd/600x600/ad89c69e-16a4-4c3a-86d1-b657fa596c7e_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9d5db00a-99b0-47cc-ba32-e278399216cd%2F600x600%2Fad89c69e-16a4-4c3a-86d1-b657fa596c7e_600x600?alt=media&token=bf1be600-0041-4057-b080-e50878049c02"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9d5db00a-99b0-47cc-ba32-e278399216cd%2Fad89c69e-16a4-4c3a-86d1-b657fa596c7e?alt=media&token=4581a5cc-2e39-4b63-8a5d-04b5110f3a8a",
        "reference": "/stroke_photos/9d5db00a-99b0-47cc-ba32-e278399216cd/ad89c69e-16a4-4c3a-86d1-b657fa596c7e"
      }
    }
  },
  {
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9d605c68-1e55-4a16-a06b-7c24dea64183%2F600x600%2F7d27a3ed-bce0-4b99-b4d1-6933e75634e7_600x600?alt=media&token=533249a0-1126-4f6f-9161-d728135f037f",
        "reference": "stroke_photos/9d605c68-1e55-4a16-a06b-7c24dea64183/600x600/7d27a3ed-bce0-4b99-b4d1-6933e75634e7_600x600"
      },
      "original": {
        "reference": "/stroke_photos/9d605c68-1e55-4a16-a06b-7c24dea64183/7d27a3ed-bce0-4b99-b4d1-6933e75634e7",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9d605c68-1e55-4a16-a06b-7c24dea64183%2F7d27a3ed-bce0-4b99-b4d1-6933e75634e7?alt=media&token=bc9950f0-5451-4dda-ab46-682a7d252948"
      }
    },
    "id": "9d605c68-1e55-4a16-a06b-7c24dea64183",
    "tool": null,
    "name": "Blank",
    "paper": {
      "gsm": "250",
      "price": 1.35,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "name": null,
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "ref": "77725080001",
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      }
    },
    "description": "",
    "order_number": 791
  },
  {
    "tool": null,
    "order_number": 471,
    "paper": {
      "name": null,
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": null,
      "brand_model": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A4",
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand": {},
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "ref": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "description": "<p>A bit less paint</p>",
    "id": "9d84659a-68f8-475d-a385-24a2bc7eaf17",
    "order_id": 0,
    "name": "Repeat Variable Pressure White",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/9d84659a-68f8-475d-a385-24a2bc7eaf17/600x600/59b7460f-9bc7-4941-8bd9-1caad2283dbf_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9d84659a-68f8-475d-a385-24a2bc7eaf17%2F600x600%2F59b7460f-9bc7-4941-8bd9-1caad2283dbf_600x600?alt=media&token=c82ac6bf-68b6-4bc4-8354-51346376fa0f"
      },
      "original": {
        "reference": "/stroke_photos/9d84659a-68f8-475d-a385-24a2bc7eaf17/59b7460f-9bc7-4941-8bd9-1caad2283dbf",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9d84659a-68f8-475d-a385-24a2bc7eaf17%2F59b7460f-9bc7-4941-8bd9-1caad2283dbf?alt=media&token=c26b0270-572c-49d8-a564-e13500e4c3ad"
      }
    }
  },
  {
    "name": "Vroooom",
    "tool": null,
    "order_number": 283,
    "id": "9d8bbf65-50c6-479c-99e8-81822cace3c9",
    "order_id": 0,
    "description": "<p>Not bad. I wish there was just a tit less paint. The curve is a different than what I imagine, so that bugs me</p>",
    "photos": {
      "original": {
        "reference": "/stroke_photos/9d8bbf65-50c6-479c-99e8-81822cace3c9/74118f24-8327-4728-ac3a-accd0efd6923",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9d8bbf65-50c6-479c-99e8-81822cace3c9%2F74118f24-8327-4728-ac3a-accd0efd6923?alt=media&token=c361d285-46d7-480d-9ace-e1593365342b"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/9d8bbf65-50c6-479c-99e8-81822cace3c9/600x600/74118f24-8327-4728-ac3a-accd0efd6923_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9d8bbf65-50c6-479c-99e8-81822cace3c9%2F600x600%2F74118f24-8327-4728-ac3a-accd0efd6923_600x600?alt=media&token=afd6c864-a909-4ae7-9bc5-c1b8aa344c0a"
      }
    },
    "paper": {
      "brand": {},
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "gsm": "250",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "price": null,
      "ref": "20180107",
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    }
  },
  {
    "order_number": 730,
    "description": "",
    "name": "Blank",
    "photos": {
      "original": {
        "reference": "/stroke_photos/9d9d1e6b-649e-4ec2-bb11-3469081bc542/2f3c99b1-d181-4e6d-b689-23140c1a07aa",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9d9d1e6b-649e-4ec2-bb11-3469081bc542%2F2f3c99b1-d181-4e6d-b689-23140c1a07aa?alt=media&token=d084a13f-e246-4932-8837-de7f0ec0da73"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/9d9d1e6b-649e-4ec2-bb11-3469081bc542/600x600/2f3c99b1-d181-4e6d-b689-23140c1a07aa_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9d9d1e6b-649e-4ec2-bb11-3469081bc542%2F600x600%2F2f3c99b1-d181-4e6d-b689-23140c1a07aa_600x600?alt=media&token=dfd6c68e-ecfc-4e46-bc80-cafc7c87c32e"
      }
    },
    "tool": null,
    "order_id": 0,
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "20170703",
      "gsm": "250",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand": {},
      "price": 0.4,
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "original_size": "A7",
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "id": "9d9d1e6b-649e-4ec2-bb11-3469081bc542"
  },
  {
    "name": "",
    "order_number": 1184,
    "description": "",
    "id": "9de6328b-273b-4e1b-a30d-d7a8b5ad2735"
  },
  {
    "id": "9e276897-a625-4b9d-9c3a-db433c3f7f02",
    "order_id": 0,
    "description": "",
    "name": "Pfsmpsitb Further Not Reload",
    "order_number": 45,
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/9e276897-a625-4b9d-9c3a-db433c3f7f02/600x600/4d28abf0-243a-4deb-81e9-b73f48a7681a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9e276897-a625-4b9d-9c3a-db433c3f7f02%2F600x600%2F4d28abf0-243a-4deb-81e9-b73f48a7681a_600x600?alt=media&token=94a90b68-9be6-4b49-aee8-318d7d171cfd"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9e276897-a625-4b9d-9c3a-db433c3f7f02%2F4d28abf0-243a-4deb-81e9-b73f48a7681a?alt=media&token=d49230b3-c57b-47ca-93c6-9ceb5dcb34bf",
        "reference": "/stroke_photos/9e276897-a625-4b9d-9c3a-db433c3f7f02/4d28abf0-243a-4deb-81e9-b73f48a7681a"
      }
    },
    "paper": {
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "ref": "44202129",
      "gsm": "200",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {},
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A4",
      "price": null,
      "name": ""
    }
  },
  {
    "tool": null,
    "id": "9ecab3e2-7057-4b1f-aea2-cfa1bf4cc4c5",
    "description": "<p>I had to come up with a fake excuse of how bad that one was</p>",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9ecab3e2-7057-4b1f-aea2-cfa1bf4cc4c5%2F8e0dfe97-3ea1-4e0b-9048-21688a6f270b?alt=media&token=856c35ca-93b5-48b1-b1c2-6037536331ed",
        "reference": "/stroke_photos/9ecab3e2-7057-4b1f-aea2-cfa1bf4cc4c5/8e0dfe97-3ea1-4e0b-9048-21688a6f270b"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9ecab3e2-7057-4b1f-aea2-cfa1bf4cc4c5%2F600x600%2F8e0dfe97-3ea1-4e0b-9048-21688a6f270b_600x600?alt=media&token=2330affb-b873-4fcd-b96c-73a7c48a6eb7",
        "reference": "stroke_photos/9ecab3e2-7057-4b1f-aea2-cfa1bf4cc4c5/600x600/8e0dfe97-3ea1-4e0b-9048-21688a6f270b_600x600"
      }
    },
    "order_number": 311,
    "order_id": 0,
    "name": "Tickle",
    "paper": {
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "ref": "20180107",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "brand": {},
      "original_size": "A7"
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1119,
    "id": "9f3142d0-87c3-45b9-9ee4-09e5f16f285d"
  },
  {
    "order_number": 1009,
    "name": "",
    "description": "",
    "id": "9f3aaf7d-2bd9-4f81-9d00-6d7785ec4b52"
  },
  {
    "description": "",
    "order_number": 402,
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9f435736-f6b3-48a7-92cc-db4ce358d9db%2F42840e14-3ecb-4608-8bc6-fc3a8fb90502?alt=media&token=73a60ccf-7aa9-4726-8c77-f7991236ca4e",
        "reference": "/stroke_photos/9f435736-f6b3-48a7-92cc-db4ce358d9db/42840e14-3ecb-4608-8bc6-fc3a8fb90502"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9f435736-f6b3-48a7-92cc-db4ce358d9db%2F600x600%2F42840e14-3ecb-4608-8bc6-fc3a8fb90502_600x600?alt=media&token=a8f09464-b3be-4e9b-b569-7996ef6c8503",
        "reference": "stroke_photos/9f435736-f6b3-48a7-92cc-db4ce358d9db/600x600/42840e14-3ecb-4608-8bc6-fc3a8fb90502_600x600"
      }
    },
    "tool": null,
    "name": "Blank",
    "paper": {
      "original_size": "A7",
      "ref": "77725080001",
      "gsm": "250",
      "price": 1.35,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    },
    "id": "9f435736-f6b3-48a7-92cc-db4ce358d9db"
  },
  {
    "description": "",
    "order_number": 1172,
    "name": "",
    "id": "9f491917-5447-451a-ab0c-696d75ab98b9"
  },
  {
    "name": "Poott Correct Trajectory",
    "description": "",
    "paper": {
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "name": "",
      "ref": "44202129",
      "brand_model": {
        "name": "Accademia Designo",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "original_size": "A4",
      "paper_surface": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "200",
      "price": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9f5a91dc-929d-4716-8f7a-99f122a6106c%2F600x600%2Ff15385c7-bba2-4604-b646-56039332dee3_600x600?alt=media&token=409abf5e-9326-455e-a8c5-09361c8f6cc9",
        "reference": "stroke_photos/9f5a91dc-929d-4716-8f7a-99f122a6106c/600x600/f15385c7-bba2-4604-b646-56039332dee3_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9f5a91dc-929d-4716-8f7a-99f122a6106c%2Ff15385c7-bba2-4604-b646-56039332dee3?alt=media&token=7536c85f-597b-40fb-abf2-798da44c89d8",
        "reference": "/stroke_photos/9f5a91dc-929d-4716-8f7a-99f122a6106c/f15385c7-bba2-4604-b646-56039332dee3"
      }
    },
    "tool": null,
    "order_number": 50,
    "order_id": 0,
    "id": "9f5a91dc-929d-4716-8f7a-99f122a6106c"
  },
  {
    "name": "Blank",
    "order_id": 0,
    "paper": {
      "original_size": "A7",
      "price": null,
      "ref": "70025010030",
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand": {},
      "gsm": "250",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand_model": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9f5d4acf-28b2-4964-9070-65e7a3620520%2F600x600%2F566fb5a9-7beb-40b8-8376-6018a2aee2fb_600x600?alt=media&token=cc672a07-aec2-46bb-b3c6-5259f90f796e",
        "reference": "stroke_photos/9f5d4acf-28b2-4964-9070-65e7a3620520/600x600/566fb5a9-7beb-40b8-8376-6018a2aee2fb_600x600"
      },
      "original": {
        "reference": "/stroke_photos/9f5d4acf-28b2-4964-9070-65e7a3620520/566fb5a9-7beb-40b8-8376-6018a2aee2fb",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9f5d4acf-28b2-4964-9070-65e7a3620520%2F566fb5a9-7beb-40b8-8376-6018a2aee2fb?alt=media&token=cc8adb23-7f6c-4754-9c7e-63dd67edc05c"
      }
    },
    "id": "9f5d4acf-28b2-4964-9070-65e7a3620520",
    "order_number": 627,
    "description": ""
  },
  {
    "name": "Blank",
    "photos": {
      "original": {
        "reference": "/stroke_photos/9f6a6e1f-9d05-4bac-b54b-bdcb1ea7361c/4a79813d-5993-46fe-9e9b-a150d9457121",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9f6a6e1f-9d05-4bac-b54b-bdcb1ea7361c%2F4a79813d-5993-46fe-9e9b-a150d9457121?alt=media&token=b64a3dc2-743e-4364-b745-329ad0ec7d3d"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/9f6a6e1f-9d05-4bac-b54b-bdcb1ea7361c/600x600/4a79813d-5993-46fe-9e9b-a150d9457121_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9f6a6e1f-9d05-4bac-b54b-bdcb1ea7361c%2F600x600%2F4a79813d-5993-46fe-9e9b-a150d9457121_600x600?alt=media&token=b26fd734-6e34-499f-a6d2-ed7742c769ae"
      }
    },
    "order_number": 721,
    "tool": null,
    "description": "",
    "order_id": 0,
    "id": "9f6a6e1f-9d05-4bac-b54b-bdcb1ea7361c",
    "paper": {
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bristol Paper"
      },
      "gsm": "250",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "20170703",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand": {},
      "original_size": "A7",
      "price": 0.4,
      "name": null
    }
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/9f869827-62ba-42fe-8a53-313893e19aa9/fefdd122-5c01-408c-bb84-f0681f39fe18",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9f869827-62ba-42fe-8a53-313893e19aa9%2Ffefdd122-5c01-408c-bb84-f0681f39fe18?alt=media&token=dc3f6087-7f77-4f1a-a961-6e690c3355f8"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9f869827-62ba-42fe-8a53-313893e19aa9%2F600x600%2Ffefdd122-5c01-408c-bb84-f0681f39fe18_600x600?alt=media&token=5e272392-aa64-4a9a-918e-53146354f76a",
        "reference": "stroke_photos/9f869827-62ba-42fe-8a53-313893e19aa9/600x600/fefdd122-5c01-408c-bb84-f0681f39fe18_600x600"
      }
    },
    "description": "",
    "order_id": 0,
    "order_number": 717,
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "gsm": "250",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "name": null,
      "price": 0.4,
      "ref": "20170703",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper"
      },
      "brand": {}
    },
    "name": "Blank",
    "id": "9f869827-62ba-42fe-8a53-313893e19aa9",
    "tool": null
  },
  {
    "order_number": 1090,
    "description": "",
    "name": "",
    "id": "9fc3dfe5-7165-4b34-8182-107335d0d590"
  },
  {
    "tool": null,
    "name": "Sideways Effort",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9ff024be-5348-46e3-bb82-51d353f8f1c3%2F600x600%2Fbf63d090-5039-452e-9718-91786af368dc_600x600?alt=media&token=4e763099-7b14-4ad5-bee3-7f8e6e9ed7c0",
        "reference": "stroke_photos/9ff024be-5348-46e3-bb82-51d353f8f1c3/600x600/bf63d090-5039-452e-9718-91786af368dc_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9ff024be-5348-46e3-bb82-51d353f8f1c3%2Fbf63d090-5039-452e-9718-91786af368dc?alt=media&token=e405e957-4bf3-4047-a90b-9871c3808214",
        "reference": "/stroke_photos/9ff024be-5348-46e3-bb82-51d353f8f1c3/bf63d090-5039-452e-9718-91786af368dc"
      }
    },
    "order_number": 365,
    "order_id": 0,
    "id": "9ff024be-5348-46e3-bb82-51d353f8f1c3",
    "description": "<p>Let's use this time less paint. Hmm, very delicate start</p>",
    "paper": {
      "original_size": "A7",
      "price": null,
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null
    }
  },
  {
    "description": "",
    "order_id": 0,
    "tool": null,
    "order_number": 795,
    "name": "Blank",
    "photos": {
      "original": {
        "reference": "/stroke_photos/9ff2dac6-6e3d-4bf5-90e9-f39592797edd/aa57f430-e86f-4693-b944-568a793248f1",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9ff2dac6-6e3d-4bf5-90e9-f39592797edd%2Faa57f430-e86f-4693-b944-568a793248f1?alt=media&token=64777b8f-9781-4ac3-8b77-589544ce3eb7"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F9ff2dac6-6e3d-4bf5-90e9-f39592797edd%2F600x600%2Faa57f430-e86f-4693-b944-568a793248f1_600x600?alt=media&token=e0e9de1d-eddb-453f-a84e-9c097b0cc59e",
        "reference": "stroke_photos/9ff2dac6-6e3d-4bf5-90e9-f39592797edd/600x600/aa57f430-e86f-4693-b944-568a793248f1_600x600"
      }
    },
    "paper": {
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "ref": "77725080001",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "original_size": "A7"
    },
    "id": "9ff2dac6-6e3d-4bf5-90e9-f39592797edd"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1220,
    "id": "a00f742d-b587-4d8c-bcfb-1cb822c824ee"
  },
  {
    "order_number": 727,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa017ab71-4dfc-460f-89c0-fb25303841e9%2F10b033c7-2b89-4cf7-bb7a-26b23b6a5a6a?alt=media&token=3b4f9d9e-40d1-48e0-ae05-661ab365e715",
        "reference": "/stroke_photos/a017ab71-4dfc-460f-89c0-fb25303841e9/10b033c7-2b89-4cf7-bb7a-26b23b6a5a6a"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa017ab71-4dfc-460f-89c0-fb25303841e9%2F600x600%2F10b033c7-2b89-4cf7-bb7a-26b23b6a5a6a_600x600?alt=media&token=4676950b-f4ae-4666-8392-ea1ffa9adff5",
        "reference": "stroke_photos/a017ab71-4dfc-460f-89c0-fb25303841e9/600x600/10b033c7-2b89-4cf7-bb7a-26b23b6a5a6a_600x600"
      }
    },
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "ref": "20170703",
      "name": null,
      "brand": {},
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "price": 0.4,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b"
    },
    "name": "Blank",
    "id": "a017ab71-4dfc-460f-89c0-fb25303841e9",
    "tool": null,
    "order_id": 0,
    "description": ""
  },
  {
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/a01cc68b-420b-4397-aa40-eba481304d1a/8b7ed88d-a40c-4c1e-bd8f-86da9ed806a6",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa01cc68b-420b-4397-aa40-eba481304d1a%2F8b7ed88d-a40c-4c1e-bd8f-86da9ed806a6?alt=media&token=6e98c826-007f-4c69-b242-f6abde5a9b4c"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/a01cc68b-420b-4397-aa40-eba481304d1a/600x600/8b7ed88d-a40c-4c1e-bd8f-86da9ed806a6_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa01cc68b-420b-4397-aa40-eba481304d1a%2F600x600%2F8b7ed88d-a40c-4c1e-bd8f-86da9ed806a6_600x600?alt=media&token=d1f0b6a5-6560-4231-8554-ee48ea1e48b0"
      }
    },
    "name": "Wearing Bikini",
    "id": "a01cc68b-420b-4397-aa40-eba481304d1a",
    "description": "<p>When putting less paint I can see better where the pressure is</p>",
    "order_number": 279,
    "order_id": 0,
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "name": null,
      "original_size": "A7",
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "ref": "20180107",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    }
  },
  {
    "order_id": 0,
    "order_number": 71,
    "id": "a04d0ee8-8d86-4614-b130-317de38337c5",
    "paper": {
      "original_size": "A4",
      "gsm": "200",
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Accademia Designo"
      },
      "ref": "44202129",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "name": "",
      "price": null,
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_surface": {}
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa04d0ee8-8d86-4614-b130-317de38337c5%2F600x600%2F53164380-c93d-4aa8-86c3-4cb4bcba8a8b_600x600?alt=media&token=a821cb72-f92f-4b7b-97a6-301538420eeb",
        "reference": "stroke_photos/a04d0ee8-8d86-4614-b130-317de38337c5/600x600/53164380-c93d-4aa8-86c3-4cb4bcba8a8b_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa04d0ee8-8d86-4614-b130-317de38337c5%2F53164380-c93d-4aa8-86c3-4cb4bcba8a8b?alt=media&token=913430f0-821c-4b6f-aa95-b11d10983722",
        "reference": "/stroke_photos/a04d0ee8-8d86-4614-b130-317de38337c5/53164380-c93d-4aa8-86c3-4cb4bcba8a8b"
      }
    },
    "tool": null,
    "name": "S Correction of Curve",
    "description": ""
  },
  {
    "name": "Blank",
    "order_id": 0,
    "order_number": 707,
    "paper": {
      "original_size": "A7",
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "brand": {},
      "ref": "20170703",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "price": 0.4
    },
    "id": "a099f515-2f2d-4d67-9d3c-d9d5032004e4",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/a099f515-2f2d-4d67-9d3c-d9d5032004e4/600x600/99bfa6d2-2025-4ded-8b8a-38398a51986a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa099f515-2f2d-4d67-9d3c-d9d5032004e4%2F600x600%2F99bfa6d2-2025-4ded-8b8a-38398a51986a_600x600?alt=media&token=074a266d-6ab3-42e8-aa39-27903f7ffbcf"
      },
      "original": {
        "reference": "/stroke_photos/a099f515-2f2d-4d67-9d3c-d9d5032004e4/99bfa6d2-2025-4ded-8b8a-38398a51986a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa099f515-2f2d-4d67-9d3c-d9d5032004e4%2F99bfa6d2-2025-4ded-8b8a-38398a51986a?alt=media&token=0f52b518-0722-4a47-bc12-cd9133a299e7"
      }
    },
    "description": "",
    "tool": null
  },
  {
    "order_number": 1042,
    "name": "",
    "description": "",
    "id": "a09a7cb2-5a3a-4a41-a138-53d16e9e3be7"
  },
  {
    "order_number": 751,
    "order_id": 0,
    "description": "",
    "paper": {
      "price": 1.35,
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": "77725080001",
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "original_size": "A7",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "gsm": "250",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      }
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa0d9602b-ce0f-4408-b7b1-8e4edabc7094%2F600x600%2F12ff687c-7239-4494-85d8-eb52c3a6c56d_600x600?alt=media&token=f9e63b06-f0b5-4d9f-9720-58e7f0af0b91",
        "reference": "stroke_photos/a0d9602b-ce0f-4408-b7b1-8e4edabc7094/600x600/12ff687c-7239-4494-85d8-eb52c3a6c56d_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa0d9602b-ce0f-4408-b7b1-8e4edabc7094%2F12ff687c-7239-4494-85d8-eb52c3a6c56d?alt=media&token=43fbe750-1f6b-4af0-bdea-6f9006e814f3",
        "reference": "/stroke_photos/a0d9602b-ce0f-4408-b7b1-8e4edabc7094/12ff687c-7239-4494-85d8-eb52c3a6c56d"
      }
    },
    "tool": null,
    "id": "a0d9602b-ce0f-4408-b7b1-8e4edabc7094",
    "name": "Blank"
  },
  {
    "tool": null,
    "name": "Blank",
    "order_id": 0,
    "description": "",
    "id": "a0de553f-ac4d-487f-a2f6-20fa5a96c990",
    "paper": {
      "ref": "70025010030",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "price": null,
      "original_size": "A7",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {},
      "gsm": "250",
      "brand": {},
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null
    },
    "order_number": 640,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa0de553f-ac4d-487f-a2f6-20fa5a96c990%2F600x600%2F9875b940-5c29-4aac-91ae-7074d7f89171_600x600?alt=media&token=15e89a58-7541-49e2-b434-65a680006d11",
        "reference": "stroke_photos/a0de553f-ac4d-487f-a2f6-20fa5a96c990/600x600/9875b940-5c29-4aac-91ae-7074d7f89171_600x600"
      },
      "original": {
        "reference": "/stroke_photos/a0de553f-ac4d-487f-a2f6-20fa5a96c990/9875b940-5c29-4aac-91ae-7074d7f89171",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa0de553f-ac4d-487f-a2f6-20fa5a96c990%2F9875b940-5c29-4aac-91ae-7074d7f89171?alt=media&token=15d9d28a-44dd-4bcd-8eeb-461ccb859da9"
      }
    }
  },
  {
    "order_number": 1057,
    "description": "",
    "name": "",
    "id": "a0e04591-b061-41dd-854b-30a007c01743"
  },
  {
    "paper": {
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "ref": "30075105",
      "price": 19.3,
      "gsm": "640",
      "original_size": "75x105cm",
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": "tinto"
    },
    "tool": null,
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa0ee8ddf-75fc-4458-a96c-f018293ea96c%2F600x600%2F09821d55-b2e5-45dd-a685-cf59dd72ff67_600x600?alt=media&token=b9ebc2df-f4d8-4ed0-966c-cf254fde5084",
        "reference": "stroke_photos/a0ee8ddf-75fc-4458-a96c-f018293ea96c/600x600/09821d55-b2e5-45dd-a685-cf59dd72ff67_600x600"
      },
      "original": {
        "reference": "/stroke_photos/a0ee8ddf-75fc-4458-a96c-f018293ea96c/09821d55-b2e5-45dd-a685-cf59dd72ff67",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa0ee8ddf-75fc-4458-a96c-f018293ea96c%2F09821d55-b2e5-45dd-a685-cf59dd72ff67?alt=media&token=550b45e0-1a72-4a1b-85ea-35300cc154c8"
      }
    },
    "id": "a0ee8ddf-75fc-4458-a96c-f018293ea96c",
    "description": "",
    "name": "Blank",
    "order_number": 671
  },
  {
    "order_number": 185,
    "paper": {
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "ref": "77725080001",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "price": 1.35,
      "original_size": "A7",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250",
      "name": null
    },
    "description": "",
    "name": "Blank",
    "id": "a1057429-60ad-4e05-b9aa-2b8941e1c0d8",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/a1057429-60ad-4e05-b9aa-2b8941e1c0d8/600x600/619a5229-f86d-4b1f-9d56-01412b254802_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa1057429-60ad-4e05-b9aa-2b8941e1c0d8%2F600x600%2F619a5229-f86d-4b1f-9d56-01412b254802_600x600?alt=media&token=3e481a1c-ca7b-4f5a-b2ca-696fce0470f3"
      },
      "original": {
        "reference": "/stroke_photos/a1057429-60ad-4e05-b9aa-2b8941e1c0d8/619a5229-f86d-4b1f-9d56-01412b254802",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa1057429-60ad-4e05-b9aa-2b8941e1c0d8%2F619a5229-f86d-4b1f-9d56-01412b254802?alt=media&token=a5bd4157-0778-4812-95f1-34e10aa2e103"
      }
    },
    "tool": null
  },
  {
    "id": "a1070573-f3fa-41af-a86c-ad10c008323b",
    "order_number": 215,
    "description": "Still trying to achieve natural balance between body and butt",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa1070573-f3fa-41af-a86c-ad10c008323b%2F711e50be-59b8-40b0-b924-f9034c20a0eb?alt=media&token=aa4176d2-861e-4734-be49-e33a42da27a9",
        "reference": "/stroke_photos/a1070573-f3fa-41af-a86c-ad10c008323b/711e50be-59b8-40b0-b924-f9034c20a0eb"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/a1070573-f3fa-41af-a86c-ad10c008323b/600x600/711e50be-59b8-40b0-b924-f9034c20a0eb_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa1070573-f3fa-41af-a86c-ad10c008323b%2F600x600%2F711e50be-59b8-40b0-b924-f9034c20a0eb_600x600?alt=media&token=260a7b0f-65d2-4d7c-97ed-b920ce72168c"
      }
    },
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "name": null,
      "gsm": "250",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "price": 1.35,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "original_size": "A7",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "ref": "77725080001",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    },
    "order_id": 0,
    "name": "Proportionate Shorty",
    "tool": null
  },
  {
    "paper": {
      "paper_surface": {},
      "ref": "44202129",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "price": null,
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A4",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "200",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "name": ""
    },
    "name": "Curved Black",
    "tool": {},
    "order_number": 4,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa10ea544-d939-407b-a86b-3ba564ccc0a2%2F209c2b14-b53f-4bcc-88a3-ac2d588f1b37?alt=media&token=6a29e039-d646-4054-974b-95f1c72f6c89",
        "reference": "/stroke_photos/a10ea544-d939-407b-a86b-3ba564ccc0a2/209c2b14-b53f-4bcc-88a3-ac2d588f1b37"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/a10ea544-d939-407b-a86b-3ba564ccc0a2/600x600/209c2b14-b53f-4bcc-88a3-ac2d588f1b37_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa10ea544-d939-407b-a86b-3ba564ccc0a2%2F600x600%2F209c2b14-b53f-4bcc-88a3-ac2d588f1b37_600x600?alt=media&token=326b9eaa-3f4e-4bbf-bfc5-cc77a6926134"
      }
    },
    "purchase": {
      "buyer": {
        "email": "Simona.majerska@gmail.com",
        "lastName": "Majerska",
        "firstName": "Simona"
      },
      "status": "bought",
      "date": {
        "seconds": 1677878064,
        "nanoseconds": 776000000
      },
      "internalDescription": "",
      "anonymous": false
    },
    "description": "",
    "order_id": 0,
    "id": "a10ea544-d939-407b-a86b-3ba564ccc0a2"
  },
  {
    "id": "a11c42ec-7b71-4568-87fc-795b640bc3e6",
    "description": "",
    "order_id": 0,
    "paper": {
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "original_size": "A4",
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "brand_model": {},
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "gsm": null,
      "brand": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    },
    "order_number": 908,
    "name": "Blank"
  },
  {
    "paper": {
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250",
      "price": null,
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand": {},
      "ref": "20180107",
      "name": null
    },
    "tool": null,
    "name": "Check 2",
    "id": "a181271a-3e2c-45bd-802a-7f44ce278abf",
    "order_number": 342,
    "photos": {
      "original": {
        "reference": "/stroke_photos/a181271a-3e2c-45bd-802a-7f44ce278abf/d0f10bd0-b281-4cb1-b25b-ad00f788c7c5",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa181271a-3e2c-45bd-802a-7f44ce278abf%2Fd0f10bd0-b281-4cb1-b25b-ad00f788c7c5?alt=media&token=e135a8e9-bb05-4f0c-b69f-9e61ed8727fb"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/a181271a-3e2c-45bd-802a-7f44ce278abf/600x600/d0f10bd0-b281-4cb1-b25b-ad00f788c7c5_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa181271a-3e2c-45bd-802a-7f44ce278abf%2F600x600%2Fd0f10bd0-b281-4cb1-b25b-ad00f788c7c5_600x600?alt=media&token=393b04d7-9bd7-4be1-ba96-810f1ed2c746"
      }
    },
    "description": "<p>Let's correct the shape a big, and run away from that terrible shaky hand and inexperienced flow</p>",
    "order_id": 0
  },
  {
    "id": "a186b403-a49f-49af-b53d-0925e0b7562c",
    "order_number": 856,
    "order_id": 0,
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "4005329065767",
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      },
      "price": 1,
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "110",
      "original_size": "A4",
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "name": "Elephant Skin"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701"
    },
    "name": "Blank",
    "description": ""
  },
  {
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/a191c00f-8e97-4291-b111-54eb48849cd1/600x600/dc83858e-1c7e-41a4-89ed-632f3fd8ff82_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa191c00f-8e97-4291-b111-54eb48849cd1%2F600x600%2Fdc83858e-1c7e-41a4-89ed-632f3fd8ff82_600x600?alt=media&token=6fdad46b-1a95-4755-9d23-f2320657cd21"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa191c00f-8e97-4291-b111-54eb48849cd1%2Fdc83858e-1c7e-41a4-89ed-632f3fd8ff82?alt=media&token=e096d1e3-2891-4dd8-98ea-1d4b69355cea",
        "reference": "/stroke_photos/a191c00f-8e97-4291-b111-54eb48849cd1/dc83858e-1c7e-41a4-89ed-632f3fd8ff82"
      }
    },
    "description": "Given I have a tablecloth under the paper, I was scared to bring the brush down fast, so I ended up doing the winner in the lightest competition",
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "name": null,
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "ref": "77725080001",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "gsm": "250",
      "price": 1.35,
      "original_size": "A7"
    },
    "name": "Pendulum 3",
    "order_number": 439,
    "tool": null,
    "id": "a191c00f-8e97-4291-b111-54eb48849cd1"
  },
  {
    "paper": {
      "original_size": "A7",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "ref": "70025010030",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "brand": {},
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {},
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null
    },
    "name": "Blank",
    "description": "",
    "order_number": 833,
    "order_id": 0,
    "id": "a19998b8-4820-4592-876e-965fb174e141"
  },
  {
    "order_id": 0,
    "id": "a1aa280c-2ba7-4c61-a0ee-49c36c1fac95",
    "description": "",
    "paper": {
      "brand_model": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "ref": "70025010030",
      "brand": {},
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "name": null
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/a1aa280c-2ba7-4c61-a0ee-49c36c1fac95/600x600/f8172edd-110f-4811-8bbb-d50054848eda_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa1aa280c-2ba7-4c61-a0ee-49c36c1fac95%2F600x600%2Ff8172edd-110f-4811-8bbb-d50054848eda_600x600?alt=media&token=4fdfd5c1-0e54-4861-8ccc-97e8a46510f8"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa1aa280c-2ba7-4c61-a0ee-49c36c1fac95%2Ff8172edd-110f-4811-8bbb-d50054848eda?alt=media&token=5a7d6bff-7308-42d2-b926-6388669d7d7a",
        "reference": "/stroke_photos/a1aa280c-2ba7-4c61-a0ee-49c36c1fac95/f8172edd-110f-4811-8bbb-d50054848eda"
      }
    },
    "tool": null,
    "name": "Blank",
    "order_number": 611
  },
  {
    "id": "a1ad822a-4547-416e-b2e9-60a7f1d53ecc",
    "name": "Blank",
    "order_number": 403,
    "description": "",
    "order_id": 0,
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "price": 1.35,
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "A7",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "gsm": "250",
      "ref": "77725080001",
      "name": null,
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      }
    },
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa1ad822a-4547-416e-b2e9-60a7f1d53ecc%2F96499765-8c2d-4bdb-858b-f70614f4dc55?alt=media&token=1efd4f5e-c2e0-4987-995e-746c16bcc70b",
        "reference": "/stroke_photos/a1ad822a-4547-416e-b2e9-60a7f1d53ecc/96499765-8c2d-4bdb-858b-f70614f4dc55"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/a1ad822a-4547-416e-b2e9-60a7f1d53ecc/600x600/96499765-8c2d-4bdb-858b-f70614f4dc55_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa1ad822a-4547-416e-b2e9-60a7f1d53ecc%2F600x600%2F96499765-8c2d-4bdb-858b-f70614f4dc55_600x600?alt=media&token=518505a3-8c38-42b2-b5e2-79d1dc34061f"
      }
    }
  },
  {
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/a1cdc961-cc21-4117-84c7-ec77d5f07054/1fead130-6b97-4df5-8309-4307c6a2c537",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa1cdc961-cc21-4117-84c7-ec77d5f07054%2F1fead130-6b97-4df5-8309-4307c6a2c537?alt=media&token=44268918-4c21-426e-9d03-53554f4ac6d6"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/a1cdc961-cc21-4117-84c7-ec77d5f07054/600x600/1fead130-6b97-4df5-8309-4307c6a2c537_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa1cdc961-cc21-4117-84c7-ec77d5f07054%2F600x600%2F1fead130-6b97-4df5-8309-4307c6a2c537_600x600?alt=media&token=15558e37-cf72-4120-b581-de1f8e8bede4"
      }
    },
    "description": "",
    "id": "a1cdc961-cc21-4117-84c7-ec77d5f07054",
    "paper": {
      "original_size": "75x105cm",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "ref": "30075105",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Artistico"
      },
      "price": 19.3,
      "gsm": "640",
      "name": "tinto"
    },
    "order_number": 688,
    "name": "Blank",
    "order_id": 0
  },
  {
    "name": "",
    "order_number": 1193,
    "description": "",
    "id": "a1e4205b-de04-45b1-a466-5978521e6045"
  },
  {
    "order_number": 788,
    "name": "Blank",
    "id": "a2033db8-7315-4dca-a1f4-acaebfb9d073",
    "photos": {
      "original": {
        "reference": "/stroke_photos/a2033db8-7315-4dca-a1f4-acaebfb9d073/e375c094-abed-4362-8e83-b6da610ee3c2",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa2033db8-7315-4dca-a1f4-acaebfb9d073%2Fe375c094-abed-4362-8e83-b6da610ee3c2?alt=media&token=a915f294-8169-4fd4-84bd-1177100cf255"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa2033db8-7315-4dca-a1f4-acaebfb9d073%2F600x600%2Fe375c094-abed-4362-8e83-b6da610ee3c2_600x600?alt=media&token=18e9e865-fcd4-4fca-87bc-72bc182d58c9",
        "reference": "stroke_photos/a2033db8-7315-4dca-a1f4-acaebfb9d073/600x600/e375c094-abed-4362-8e83-b6da610ee3c2_600x600"
      }
    },
    "order_id": 0,
    "description": "",
    "tool": null,
    "paper": {
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "ref": "77725080001",
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": 1.35,
      "gsm": "250",
      "name": null,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    }
  },
  {
    "paper": {
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "gsm": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "original_size": "A4",
      "brand_model": {},
      "ref": null
    },
    "id": "a20fbf5e-7d3f-4346-b2f9-d796f41068d2",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa20fbf5e-7d3f-4346-b2f9-d796f41068d2%2F2836256d-131f-4cf9-bcfa-a327e1126efa?alt=media&token=fef8ce51-74ef-407d-acd6-01fec8384baa",
        "reference": "/stroke_photos/a20fbf5e-7d3f-4346-b2f9-d796f41068d2/2836256d-131f-4cf9-bcfa-a327e1126efa"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa20fbf5e-7d3f-4346-b2f9-d796f41068d2%2F600x600%2F2836256d-131f-4cf9-bcfa-a327e1126efa_600x600?alt=media&token=435492b8-7400-489f-a6d7-37b4384ffa74",
        "reference": "stroke_photos/a20fbf5e-7d3f-4346-b2f9-d796f41068d2/600x600/2836256d-131f-4cf9-bcfa-a327e1126efa_600x600"
      }
    },
    "name": "Silver-On-Black Regular 6",
    "tool": null,
    "order_id": 0,
    "order_number": 527,
    "description": "<p>One more with a bit less paint</p>"
  },
  {
    "order_number": 1191,
    "description": "",
    "name": "",
    "id": "a21aa4a1-ab2d-41c5-b0ad-804e5d899a49"
  },
  {
    "tool": null,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/a22165cf-33b7-45f8-ac2b-c3008fba1375/600x600/f42c31be-82a4-4095-a7d0-e57fff4c05d0_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa22165cf-33b7-45f8-ac2b-c3008fba1375%2F600x600%2Ff42c31be-82a4-4095-a7d0-e57fff4c05d0_600x600?alt=media&token=0c2e6540-ff68-4f2f-beb7-0a08b8fd1497"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa22165cf-33b7-45f8-ac2b-c3008fba1375%2Ff42c31be-82a4-4095-a7d0-e57fff4c05d0?alt=media&token=5b26f7d1-df1a-4ab8-8b5d-e53c570ce3b6",
        "reference": "/stroke_photos/a22165cf-33b7-45f8-ac2b-c3008fba1375/f42c31be-82a4-4095-a7d0-e57fff4c05d0"
      }
    },
    "id": "a22165cf-33b7-45f8-ac2b-c3008fba1375",
    "paper": {
      "gsm": "250",
      "name": null,
      "original_size": "A7",
      "price": 0.4,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bristol Paper"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand": {},
      "ref": "20170703"
    },
    "order_id": 0,
    "name": "Blank",
    "order_number": 732
  },
  {
    "description": "",
    "id": "a22c8a92-b47b-4607-a94d-a491c72d0f51",
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/a22c8a92-b47b-4607-a94d-a491c72d0f51/aadf1cff-c1a0-4783-9142-7a3c63b88343",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa22c8a92-b47b-4607-a94d-a491c72d0f51%2Faadf1cff-c1a0-4783-9142-7a3c63b88343?alt=media&token=63fa23e0-aa0d-4e2a-afb4-8835f9aec74e"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/a22c8a92-b47b-4607-a94d-a491c72d0f51/600x600/aadf1cff-c1a0-4783-9142-7a3c63b88343_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa22c8a92-b47b-4607-a94d-a491c72d0f51%2F600x600%2Faadf1cff-c1a0-4783-9142-7a3c63b88343_600x600?alt=media&token=4bcc3e21-8251-4c84-bc65-f14814d2b0c2"
      }
    },
    "order_number": 561,
    "paper": {
      "original_size": "A7",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "price": 0.4,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "20170703",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "brand": {}
    },
    "name": "Blank",
    "order_id": 0
  },
  {
    "order_id": 0,
    "order_number": 815,
    "paper": {
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": 0.4,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "250",
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "ref": "20170703",
      "name": null,
      "brand": {},
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      }
    },
    "description": "",
    "name": "Blank",
    "id": "a24f86d5-e1f5-4da6-9b98-6eb1b64d0bad"
  },
  {
    "paper": {
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "gsm": "250",
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "price": 1.35,
      "ref": "77725080001",
      "original_size": "A7",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      }
    },
    "tool": null,
    "order_number": 218,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/a258f815-cd43-48c4-82db-83aa101960a5/600x600/02955c3d-1694-4b07-8c85-6857afb73bd6_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa258f815-cd43-48c4-82db-83aa101960a5%2F600x600%2F02955c3d-1694-4b07-8c85-6857afb73bd6_600x600?alt=media&token=b11575f4-8975-4590-be88-2b69812b5e86"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa258f815-cd43-48c4-82db-83aa101960a5%2F02955c3d-1694-4b07-8c85-6857afb73bd6?alt=media&token=04514b9a-3fcb-4714-b02d-ba2f7ff03adb",
        "reference": "/stroke_photos/a258f815-cd43-48c4-82db-83aa101960a5/02955c3d-1694-4b07-8c85-6857afb73bd6"
      }
    },
    "name": "Two Parts - Sometimes Win, Sometimes Lune",
    "order_id": 0,
    "description": "",
    "id": "a258f815-cd43-48c4-82db-83aa101960a5"
  },
  {
    "name": "",
    "order_number": 1093,
    "description": "",
    "id": "a26988c9-6deb-4c35-9f84-bb70419e47ed"
  },
  {
    "order_id": 0,
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "name": "tinto",
      "price": 19.3,
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "original_size": "75x105cm",
      "ref": "30075105",
      "gsm": "640"
    },
    "id": "a279ce93-3f72-4b48-9162-baa4d1320240",
    "description": "",
    "name": "Blank",
    "order_number": 982
  },
  {
    "order_number": 1021,
    "name": "",
    "description": "",
    "id": "a2873059-706f-4958-9c61-75ec1d456d60"
  },
  {
    "paper": {
      "gsm": "250",
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "ref": "77725080001",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "original_size": "A7"
    },
    "order_number": 223,
    "name": "Reduced Cost Compensating With Pushiness",
    "order_id": 0,
    "tool": null,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa28b982d-8cf3-4391-8ec0-256958445d3d%2F600x600%2Fb162e228-e860-4b39-a7f6-2e9eba91736e_600x600?alt=media&token=ffb2f5b2-414f-4a26-8d3f-13d7922cf2a1",
        "reference": "stroke_photos/a28b982d-8cf3-4391-8ec0-256958445d3d/600x600/b162e228-e860-4b39-a7f6-2e9eba91736e_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa28b982d-8cf3-4391-8ec0-256958445d3d%2Fb162e228-e860-4b39-a7f6-2e9eba91736e?alt=media&token=b05dcd17-9f1f-4ea9-a05d-c1270a42ccad",
        "reference": "/stroke_photos/a28b982d-8cf3-4391-8ec0-256958445d3d/b162e228-e860-4b39-a7f6-2e9eba91736e"
      }
    },
    "id": "a28b982d-8cf3-4391-8ec0-256958445d3d"
  },
  {
    "order_id": 0,
    "description": "",
    "name": "S Flat",
    "order_number": 66,
    "id": "a28f99c8-2d0e-49e8-b14b-be30a978a74c",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/a28f99c8-2d0e-49e8-b14b-be30a978a74c/600x600/dd1c8ed5-e4cb-4f80-a98a-25eb77be8ba9_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa28f99c8-2d0e-49e8-b14b-be30a978a74c%2F600x600%2Fdd1c8ed5-e4cb-4f80-a98a-25eb77be8ba9_600x600?alt=media&token=fe2a0cb7-a567-404a-ac02-f0306572ef00"
      },
      "original": {
        "reference": "/stroke_photos/a28f99c8-2d0e-49e8-b14b-be30a978a74c/dd1c8ed5-e4cb-4f80-a98a-25eb77be8ba9",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa28f99c8-2d0e-49e8-b14b-be30a978a74c%2Fdd1c8ed5-e4cb-4f80-a98a-25eb77be8ba9?alt=media&token=52e40f80-9619-4a15-9fb6-494c49b91fc1"
      }
    },
    "paper": {
      "original_size": "A4",
      "ref": "44202129",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_surface": {},
      "gsm": "200",
      "name": "",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null
    }
  },
  {
    "order_id": 0,
    "description": "",
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/a2f09531-5e05-4ddd-92fc-2b953ffaebf6/600x600/36a57e6e-cef0-4c02-84bb-fea1c4f47685_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa2f09531-5e05-4ddd-92fc-2b953ffaebf6%2F600x600%2F36a57e6e-cef0-4c02-84bb-fea1c4f47685_600x600?alt=media&token=55c13985-b46f-418c-9e13-de8692e5d8e8"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa2f09531-5e05-4ddd-92fc-2b953ffaebf6%2F36a57e6e-cef0-4c02-84bb-fea1c4f47685?alt=media&token=d4d14b62-4656-4f2d-bc5d-9ed0e6da3eac",
        "reference": "/stroke_photos/a2f09531-5e05-4ddd-92fc-2b953ffaebf6/36a57e6e-cef0-4c02-84bb-fea1c4f47685"
      }
    },
    "order_number": 652,
    "id": "a2f09531-5e05-4ddd-92fc-2b953ffaebf6",
    "paper": {
      "name": "tinto",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "ref": "30075105",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "gsm": "640",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "original_size": "75x105cm",
      "price": 19.3,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      }
    },
    "tool": null
  },
  {
    "name": "",
    "description": "",
    "order_number": 1214,
    "id": "a321b0c5-3d10-4c0a-a10e-915571271d29"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1197,
    "id": "a3680d48-8602-4908-b4f3-9e530649adcf"
  },
  {
    "tool": null,
    "id": "a3b699ef-1fa1-4d2f-bf68-3ae6d73e4f84",
    "description": "",
    "order_number": 398,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/a3b699ef-1fa1-4d2f-bf68-3ae6d73e4f84/600x600/5b2f30e7-64e1-426d-b431-2fbadc9fcc73_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa3b699ef-1fa1-4d2f-bf68-3ae6d73e4f84%2F600x600%2F5b2f30e7-64e1-426d-b431-2fbadc9fcc73_600x600?alt=media&token=07d2be81-f307-426a-a6b9-e74d65c53e41"
      },
      "original": {
        "reference": "/stroke_photos/a3b699ef-1fa1-4d2f-bf68-3ae6d73e4f84/5b2f30e7-64e1-426d-b431-2fbadc9fcc73",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa3b699ef-1fa1-4d2f-bf68-3ae6d73e4f84%2F5b2f30e7-64e1-426d-b431-2fbadc9fcc73?alt=media&token=48258901-f0ca-434f-a4dd-fd918834f718"
      }
    },
    "name": "Blank",
    "order_id": 0,
    "paper": {
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "77725080001",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": 1.35,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "gsm": "250",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "original_size": "A7",
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    }
  },
  {
    "order_number": 773,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/a3b79971-bbcf-42c4-b31b-ffba2c024839/600x600/d9160099-8ca0-4d18-9012-f874a3db7009_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa3b79971-bbcf-42c4-b31b-ffba2c024839%2F600x600%2Fd9160099-8ca0-4d18-9012-f874a3db7009_600x600?alt=media&token=1248166d-00d8-48ea-9f9d-d374deec1e0d"
      },
      "original": {
        "reference": "/stroke_photos/a3b79971-bbcf-42c4-b31b-ffba2c024839/d9160099-8ca0-4d18-9012-f874a3db7009",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa3b79971-bbcf-42c4-b31b-ffba2c024839%2Fd9160099-8ca0-4d18-9012-f874a3db7009?alt=media&token=a4405cda-d843-461d-898e-e74f9e8cc4ab"
      }
    },
    "description": "",
    "id": "a3b79971-bbcf-42c4-b31b-ffba2c024839",
    "order_id": 0,
    "tool": null,
    "paper": {
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "name": null,
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "ref": "77725080001",
      "price": 1.35,
      "gsm": "250",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      }
    },
    "name": "Blank"
  },
  {
    "tool": null,
    "id": "a3c412b7-f1a4-4809-bf19-095f4b71b400",
    "paper": {
      "name": null,
      "ref": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand_model": {},
      "price": null,
      "gsm": null,
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "brand": {}
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/a3c412b7-f1a4-4809-bf19-095f4b71b400/2b91b772-a5c9-47cd-b185-c2e9e97db9ec",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa3c412b7-f1a4-4809-bf19-095f4b71b400%2F2b91b772-a5c9-47cd-b185-c2e9e97db9ec?alt=media&token=ee000e47-d306-47fd-b544-9180d0fef65f"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa3c412b7-f1a4-4809-bf19-095f4b71b400%2F600x600%2F2b91b772-a5c9-47cd-b185-c2e9e97db9ec_600x600?alt=media&token=5dac7384-8c47-48c6-9bd6-019e3e36c2dd",
        "reference": "stroke_photos/a3c412b7-f1a4-4809-bf19-095f4b71b400/600x600/2b91b772-a5c9-47cd-b185-c2e9e97db9ec_600x600"
      }
    },
    "description": "<p>Making this time a straight line from bottom-left to top-right. I like it.</p>",
    "name": "Directional Line",
    "order_number": 477,
    "order_id": 0
  },
  {
    "paper": {
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "ref": "77725080001",
      "original_size": "A7",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": 1.35,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      }
    },
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/a45e20ad-a0ce-4aa7-aee4-f65085d567b5/600x600/b932532a-4516-4b5a-a80c-9507b578935b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa45e20ad-a0ce-4aa7-aee4-f65085d567b5%2F600x600%2Fb932532a-4516-4b5a-a80c-9507b578935b_600x600?alt=media&token=c6c00284-286f-4bd1-9ec8-caf3cae3a77a"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa45e20ad-a0ce-4aa7-aee4-f65085d567b5%2Fb932532a-4516-4b5a-a80c-9507b578935b?alt=media&token=391e6bc9-797f-4663-80a0-fdaca0b20052",
        "reference": "/stroke_photos/a45e20ad-a0ce-4aa7-aee4-f65085d567b5/b932532a-4516-4b5a-a80c-9507b578935b"
      }
    },
    "description": "",
    "name": "Blank",
    "tool": null,
    "order_number": 400,
    "id": "a45e20ad-a0ce-4aa7-aee4-f65085d567b5"
  },
  {
    "name": "",
    "order_number": 1180,
    "description": "",
    "id": "a4a8f89d-93df-4670-a2f9-ad9361b9a940"
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa4d59e18-4f0c-480f-aefd-fc2d5feeb2b6%2F600x600%2F60493fa0-4394-41dc-973c-fcf190abf662_600x600?alt=media&token=b0ec71d5-b2eb-4157-8480-9ed4236a5630",
        "reference": "stroke_photos/a4d59e18-4f0c-480f-aefd-fc2d5feeb2b6/600x600/60493fa0-4394-41dc-973c-fcf190abf662_600x600"
      },
      "original": {
        "reference": "/stroke_photos/a4d59e18-4f0c-480f-aefd-fc2d5feeb2b6/60493fa0-4394-41dc-973c-fcf190abf662",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa4d59e18-4f0c-480f-aefd-fc2d5feeb2b6%2F60493fa0-4394-41dc-973c-fcf190abf662?alt=media&token=c6ed050c-5021-4ee7-9424-6c1870ee921f"
      }
    },
    "description": "I love how definitive that came out.",
    "paper": {
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "price": 1.35,
      "gsm": "250",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "ref": "77725080001",
      "original_size": "A7",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    },
    "name": "Confidence",
    "order_id": 0,
    "tool": null,
    "id": "a4d59e18-4f0c-480f-aefd-fc2d5feeb2b6",
    "order_number": 417
  },
  {
    "description": "",
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/a4ddfb66-1385-4f0f-8969-16ca1bbce0ec/600x600/9fa3343b-f213-4960-b350-70ff1dbd4bf5_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa4ddfb66-1385-4f0f-8969-16ca1bbce0ec%2F600x600%2F9fa3343b-f213-4960-b350-70ff1dbd4bf5_600x600?alt=media&token=449bbf63-c614-4ba5-85f6-9d0c2649f168"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa4ddfb66-1385-4f0f-8969-16ca1bbce0ec%2F9fa3343b-f213-4960-b350-70ff1dbd4bf5?alt=media&token=eacdc4b9-f4d8-4b4f-86fc-ae695dd4a8cf",
        "reference": "/stroke_photos/a4ddfb66-1385-4f0f-8969-16ca1bbce0ec/9fa3343b-f213-4960-b350-70ff1dbd4bf5"
      }
    },
    "order_id": 0,
    "tool": null,
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "gsm": "250",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "brand_model": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "ref": "70025010030",
      "original_size": "A7"
    },
    "order_number": 646,
    "id": "a4ddfb66-1385-4f0f-8969-16ca1bbce0ec"
  },
  {
    "tool": null,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/a4f99732-d4be-4203-88cb-1e1b43d5ce91/600x600/a0aeb4e1-2078-4fc0-ad6d-1e626c44840f_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa4f99732-d4be-4203-88cb-1e1b43d5ce91%2F600x600%2Fa0aeb4e1-2078-4fc0-ad6d-1e626c44840f_600x600?alt=media&token=7fa413f6-f833-4e13-bb23-a1170455ebc5"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa4f99732-d4be-4203-88cb-1e1b43d5ce91%2Fa0aeb4e1-2078-4fc0-ad6d-1e626c44840f?alt=media&token=7d151e12-fad5-42c6-ae9b-7bc038eb7eb6",
        "reference": "/stroke_photos/a4f99732-d4be-4203-88cb-1e1b43d5ce91/a0aeb4e1-2078-4fc0-ad6d-1e626c44840f"
      }
    },
    "order_number": 115,
    "order_id": 0,
    "id": "a4f99732-d4be-4203-88cb-1e1b43d5ce91",
    "name": "I like it",
    "paper": {
      "ref": "20180107",
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "gsm": "250",
      "original_size": "A7",
      "name": null,
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    }
  },
  {
    "order_number": 399,
    "tool": null,
    "description": "",
    "id": "a5449858-7355-4569-a216-6add3f86795f",
    "paper": {
      "original_size": "A7",
      "ref": "77725080001",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "gsm": "250",
      "price": 1.35,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "name": null,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      }
    },
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/a5449858-7355-4569-a216-6add3f86795f/2018e16d-947c-4887-b582-6ac297e467bc",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa5449858-7355-4569-a216-6add3f86795f%2F2018e16d-947c-4887-b582-6ac297e467bc?alt=media&token=fabba400-741b-4704-baf3-a5eeec08542f"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/a5449858-7355-4569-a216-6add3f86795f/600x600/2018e16d-947c-4887-b582-6ac297e467bc_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa5449858-7355-4569-a216-6add3f86795f%2F600x600%2F2018e16d-947c-4887-b582-6ac297e467bc_600x600?alt=media&token=1202df32-18cf-44ed-add8-15939fb11b48"
      }
    },
    "name": "Blank"
  },
  {
    "description": "<p>I want to try something more complicated with that little brush that gives me the capability for it</p>",
    "order_id": 0,
    "paper": {
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "name": null,
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "20180107",
      "gsm": "250"
    },
    "id": "a56ff13e-6b24-4518-b4dd-56d52e1e9ec8",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa56ff13e-6b24-4518-b4dd-56d52e1e9ec8%2F600x600%2F54803e3a-635f-45c1-bcf1-0518a2f15e3e_600x600?alt=media&token=90ff8ac4-bbbb-41b0-9101-e304df092b95",
        "reference": "stroke_photos/a56ff13e-6b24-4518-b4dd-56d52e1e9ec8/600x600/54803e3a-635f-45c1-bcf1-0518a2f15e3e_600x600"
      },
      "original": {
        "reference": "/stroke_photos/a56ff13e-6b24-4518-b4dd-56d52e1e9ec8/54803e3a-635f-45c1-bcf1-0518a2f15e3e",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa56ff13e-6b24-4518-b4dd-56d52e1e9ec8%2F54803e3a-635f-45c1-bcf1-0518a2f15e3e?alt=media&token=f5c7bb7d-21e8-43e5-8e5e-ff487e756721"
      }
    },
    "order_number": 319,
    "name": "Swirly",
    "tool": null
  },
  {
    "name": "",
    "description": "",
    "order_number": 1064,
    "id": "a5c077bb-e594-4df2-8d8b-c635c8cd7085"
  },
  {
    "paper": {
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "gsm": "110",
      "original_size": "A4",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "price": 1,
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      },
      "ref": "4005329065767",
      "name": null,
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "name": "Elephant Skin"
      }
    },
    "order_number": 879,
    "name": "Blank",
    "order_id": 0,
    "id": "a5f7d4b5-c68a-4c31-a2b3-760de53a129d",
    "description": ""
  },
  {
    "description": "",
    "name": "",
    "order_number": 1184,
    "id": "a6574015-f475-4d1b-b737-70ee8e159f71"
  },
  {
    "name": "",
    "order_number": 1215,
    "description": "",
    "id": "a6a9a1e2-b648-49ca-bc79-daa9162e0ef7"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1171,
    "id": "a6c1cd26-4487-4879-9992-456a900b1af0"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1091,
    "id": "a6df7c0d-9349-4c71-b81c-edd6d9dc6d72"
  },
  {
    "order_number": 847,
    "description": "",
    "order_id": 0,
    "id": "a6e41415-8ecd-4127-b65b-d0cace0db24f",
    "name": "Blank",
    "paper": {
      "original_size": "A7",
      "brand": {},
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {},
      "gsm": "250",
      "ref": "70025010030",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "name": null
    }
  },
  {
    "name": "",
    "description": "",
    "order_number": 1157,
    "id": "a71dab5c-6f55-4ed5-bd03-e137095aa841"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1045,
    "id": "a73ba12e-cfb0-4361-ba5e-341a2fe049a6"
  },
  {
    "tool": null,
    "name": "Variable Pressure Close",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/a789f2ec-2725-4b1a-b721-5d85654a0c7f/600x600/691564f2-cf62-42c7-b93e-4d9bbf6eeb78_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa789f2ec-2725-4b1a-b721-5d85654a0c7f%2F600x600%2F691564f2-cf62-42c7-b93e-4d9bbf6eeb78_600x600?alt=media&token=1ab77b37-2a66-4223-be0d-e2219a225efb"
      },
      "original": {
        "reference": "/stroke_photos/a789f2ec-2725-4b1a-b721-5d85654a0c7f/691564f2-cf62-42c7-b93e-4d9bbf6eeb78",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa789f2ec-2725-4b1a-b721-5d85654a0c7f%2F691564f2-cf62-42c7-b93e-4d9bbf6eeb78?alt=media&token=dc7d10fd-a82e-4792-83fd-72a12d1279c8"
      }
    },
    "id": "a789f2ec-2725-4b1a-b721-5d85654a0c7f",
    "paper": {
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "gsm": "250",
      "ref": "20180107",
      "original_size": "A7",
      "price": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "description": "",
    "order_number": 101,
    "order_id": 0
  },
  {
    "order_number": 953,
    "id": "a7a0426a-3b4f-418c-9212-d43e519f0d8c",
    "name": "Blank",
    "description": "",
    "paper": {
      "brand_model": {},
      "gsm": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": null,
      "brand": {},
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4",
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null
    },
    "order_id": 0
  },
  {
    "name": "Blank",
    "order_number": 920,
    "id": "a81d2c97-034d-4a8f-bc63-637e7d560c59",
    "paper": {
      "name": null,
      "price": null,
      "brand_model": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "gsm": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4",
      "ref": null,
      "brand": {}
    },
    "order_id": 0,
    "description": ""
  },
  {
    "order_id": 0,
    "id": "a8207fdc-fe5a-49c3-a2c3-80d9809b5db6",
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": null,
      "brand_model": {},
      "brand": {},
      "ref": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "original_size": "A4"
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa8207fdc-fe5a-49c3-a2c3-80d9809b5db6%2F1531c824-902c-4c9d-acf8-4eb42e86a6bc?alt=media&token=acfa760b-6880-44ce-a670-797905da194b",
        "reference": "/stroke_photos/a8207fdc-fe5a-49c3-a2c3-80d9809b5db6/1531c824-902c-4c9d-acf8-4eb42e86a6bc"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/a8207fdc-fe5a-49c3-a2c3-80d9809b5db6/600x600/1531c824-902c-4c9d-acf8-4eb42e86a6bc_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa8207fdc-fe5a-49c3-a2c3-80d9809b5db6%2F600x600%2F1531c824-902c-4c9d-acf8-4eb42e86a6bc_600x600?alt=media&token=5b2660a9-8a1f-4ef1-9284-f1707fe56df1"
      }
    },
    "description": "<p>Has tail but too wide...</p>",
    "tool": null,
    "name": "Water + White + Tail",
    "order_number": 467
  },
  {
    "tool": null,
    "name": "Strange Variables",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa8330e25-5c3e-4e0d-be0c-dfd62b66670f%2Fa3eeac33-42c7-4070-968c-928727001d79?alt=media&token=632c3140-ab0e-4e2f-9563-0f6216b68c56",
        "reference": "/stroke_photos/a8330e25-5c3e-4e0d-be0c-dfd62b66670f/a3eeac33-42c7-4070-968c-928727001d79"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/a8330e25-5c3e-4e0d-be0c-dfd62b66670f/600x600/a3eeac33-42c7-4070-968c-928727001d79_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa8330e25-5c3e-4e0d-be0c-dfd62b66670f%2F600x600%2Fa3eeac33-42c7-4070-968c-928727001d79_600x600?alt=media&token=8b49026f-ba23-4a3b-9647-d94a7d38cc41"
      }
    },
    "order_id": 0,
    "id": "a8330e25-5c3e-4e0d-be0c-dfd62b66670f",
    "description": "<p>Sub-par in many ways - paint distribution, pressure distances, curve evenness, ungradiented ending</p>",
    "paper": {
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "brand": {},
      "original_size": "A7",
      "gsm": "250",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "20180107",
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      }
    },
    "order_number": 361
  },
  {
    "description": "",
    "name": "",
    "order_number": 1144,
    "id": "a8895761-df07-4da9-8b12-ac26a5cfc34c"
  },
  {
    "order_number": 1037,
    "name": "",
    "description": "",
    "id": "a8a62a92-3fe1-4069-a2ff-86ba445e8363"
  },
  {
    "tool": null,
    "id": "a8b3653f-7d68-4dbd-b46f-a30c2a3be9d7",
    "paper": {
      "original_size": "A7",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "250",
      "ref": "70025010030",
      "price": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {},
      "name": null,
      "brand_model": {},
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      }
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/a8b3653f-7d68-4dbd-b46f-a30c2a3be9d7/600x600/8ab74de4-3e9a-48ea-8dac-fc3a404c2928_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa8b3653f-7d68-4dbd-b46f-a30c2a3be9d7%2F600x600%2F8ab74de4-3e9a-48ea-8dac-fc3a404c2928_600x600?alt=media&token=584a42a1-59d6-42f9-af51-b6d3d44c1a11"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa8b3653f-7d68-4dbd-b46f-a30c2a3be9d7%2F8ab74de4-3e9a-48ea-8dac-fc3a404c2928?alt=media&token=2b31bba1-ceb6-486c-a892-04bf4c24863e",
        "reference": "/stroke_photos/a8b3653f-7d68-4dbd-b46f-a30c2a3be9d7/8ab74de4-3e9a-48ea-8dac-fc3a404c2928"
      }
    },
    "order_number": 600,
    "name": "Blank",
    "order_id": 0,
    "description": ""
  },
  {
    "description": "",
    "order_number": 1115,
    "name": "",
    "id": "a8f63470-d0d4-49be-b812-10ccbe130a04"
  },
  {
    "description": "",
    "order_number": 1224,
    "name": "",
    "id": "a91931a2-30ef-40de-b323-3baf6144a467"
  },
  {
    "description": "You can see the slow and inexperienced hand drawing a perfectly imperfect uneven line.",
    "order_number": 232,
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa998479a-3649-43fe-aa60-2a9a23923522%2Fbd72fcca-6169-4947-af8d-f8858d1ebbf4?alt=media&token=09d54b7f-7127-4719-9a68-18f00dc6f849",
        "reference": "/stroke_photos/a998479a-3649-43fe-aa60-2a9a23923522/bd72fcca-6169-4947-af8d-f8858d1ebbf4"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fa998479a-3649-43fe-aa60-2a9a23923522%2F600x600%2Fbd72fcca-6169-4947-af8d-f8858d1ebbf4_600x600?alt=media&token=80e1adc0-8df1-41f8-91a3-b4f5997b3318",
        "reference": "stroke_photos/a998479a-3649-43fe-aa60-2a9a23923522/600x600/bd72fcca-6169-4947-af8d-f8858d1ebbf4_600x600"
      }
    },
    "paper": {
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "original_size": "A7",
      "name": null,
      "price": 1.35,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      }
    },
    "name": "Black Series 4",
    "order_id": 0,
    "id": "a998479a-3649-43fe-aa60-2a9a23923522"
  },
  {
    "order_number": 1060,
    "name": "",
    "description": "",
    "id": "a9e54703-bc19-40ee-bff6-fa75df0d5bcd"
  },
  {
    "name": "",
    "order_number": 1018,
    "description": "",
    "id": "aa01b1d4-1f56-479e-9a18-af0b209f31a3"
  },
  {
    "tool": null,
    "name": "Wavy White 3 Turns",
    "id": "aa6e7662-721e-4006-ac83-147c3ad692a4",
    "description": "<p>I am going to make one with 3 turns. It looks a bit slanted</p>",
    "photos": {
      "original": {
        "reference": "/stroke_photos/aa6e7662-721e-4006-ac83-147c3ad692a4/2d37a6ec-c77d-4a0d-8ec2-2c8c0f1d0aff",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Faa6e7662-721e-4006-ac83-147c3ad692a4%2F2d37a6ec-c77d-4a0d-8ec2-2c8c0f1d0aff?alt=media&token=cd6ecb1d-5e75-4c44-a2e0-35687a4fe999"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/aa6e7662-721e-4006-ac83-147c3ad692a4/600x600/2d37a6ec-c77d-4a0d-8ec2-2c8c0f1d0aff_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Faa6e7662-721e-4006-ac83-147c3ad692a4%2F600x600%2F2d37a6ec-c77d-4a0d-8ec2-2c8c0f1d0aff_600x600?alt=media&token=ddf380e0-2307-4e60-b628-60a11162fc2c"
      }
    },
    "order_id": 0,
    "order_number": 475,
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "original_size": "A4",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": null,
      "brand_model": {},
      "gsm": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "name": null
    }
  },
  {
    "name": "",
    "description": "",
    "order_number": 1128,
    "id": "aa837731-ef0f-47b3-86c9-447201624e5b"
  },
  {
    "order_number": 1185,
    "name": "",
    "description": "",
    "id": "aa859a8d-7499-4061-b5c0-feec98665d8c"
  },
  {
    "id": "ab0b9b50-7d1d-4f7d-b837-c80a3dc0c380",
    "paper": {
      "paper_surface": {},
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "44202129",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "name": "",
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "gsm": "200",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4"
    },
    "tool": {},
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/ab0b9b50-7d1d-4f7d-b837-c80a3dc0c380/600x600/5a0cd08d-2aff-426c-b1de-d7f44223f8f7_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fab0b9b50-7d1d-4f7d-b837-c80a3dc0c380%2F600x600%2F5a0cd08d-2aff-426c-b1de-d7f44223f8f7_600x600?alt=media&token=a5011729-82b9-42e2-a1a2-e30f3b1a5e51"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fab0b9b50-7d1d-4f7d-b837-c80a3dc0c380%2F5a0cd08d-2aff-426c-b1de-d7f44223f8f7?alt=media&token=48878297-485e-4d33-8d08-c52ead615d64",
        "reference": "/stroke_photos/ab0b9b50-7d1d-4f7d-b837-c80a3dc0c380/5a0cd08d-2aff-426c-b1de-d7f44223f8f7"
      }
    },
    "order_id": 0,
    "description": "",
    "name": "Improved Pink Curve",
    "order_number": 5
  },
  {
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/ab3eee56-9503-468a-b113-553e5dc81e3e/600x600/2658fb5b-dd14-4be3-a27d-5464be3727fa_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fab3eee56-9503-468a-b113-553e5dc81e3e%2F600x600%2F2658fb5b-dd14-4be3-a27d-5464be3727fa_600x600?alt=media&token=48df0948-f42a-4b30-849d-8c9d6da037f3"
      },
      "original": {
        "reference": "/stroke_photos/ab3eee56-9503-468a-b113-553e5dc81e3e/2658fb5b-dd14-4be3-a27d-5464be3727fa",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fab3eee56-9503-468a-b113-553e5dc81e3e%2F2658fb5b-dd14-4be3-a27d-5464be3727fa?alt=media&token=be05e837-4a39-49ce-8881-c35aed702ddd"
      }
    },
    "description": "<p>Or really not</p>",
    "name": "Off To Amazing Start",
    "order_number": 295,
    "paper": {
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "ref": "20180107",
      "gsm": "250",
      "name": null,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A7",
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86"
    },
    "order_id": 0,
    "tool": null,
    "id": "ab3eee56-9503-468a-b113-553e5dc81e3e"
  },
  {
    "tool": null,
    "name": "Blank",
    "id": "ab508bae-06e1-4b95-b95b-12e175b378fe",
    "paper": {
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "gsm": "250",
      "price": 1.35,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "original_size": "A7",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "ref": "77725080001"
    },
    "description": "",
    "order_number": 784,
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fab508bae-06e1-4b95-b95b-12e175b378fe%2F197f9e01-284f-47aa-aa91-3889900e870c?alt=media&token=5ae62787-7b0d-4113-96f0-7262a7b0164f",
        "reference": "/stroke_photos/ab508bae-06e1-4b95-b95b-12e175b378fe/197f9e01-284f-47aa-aa91-3889900e870c"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/ab508bae-06e1-4b95-b95b-12e175b378fe/600x600/197f9e01-284f-47aa-aa91-3889900e870c_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fab508bae-06e1-4b95-b95b-12e175b378fe%2F600x600%2F197f9e01-284f-47aa-aa91-3889900e870c_600x600?alt=media&token=5b00318b-4c9f-43d9-a824-a29e723cbc64"
      }
    }
  },
  {
    "order_number": 1158,
    "name": "",
    "description": "",
    "id": "ab68f134-f1b1-4029-9968-c38e50b09e7c"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1088,
    "id": "ab95905a-44f3-49e7-bfcf-a296edf01495"
  },
  {
    "order_number": 1085,
    "name": "",
    "description": "",
    "id": "aba758b1-af74-419c-a89c-1624910c7c28"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1202,
    "id": "abaecb36-9fb6-4056-8ca2-8b58d8994984"
  },
  {
    "id": "abb5d92b-5c3d-419f-9c81-e102a54394e6",
    "order_number": 899,
    "name": "Blank",
    "description": "",
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "110",
      "ref": "4005329065767",
      "original_size": "A4",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "brand_model": {
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "price": 1
    },
    "order_id": 0
  },
  {
    "name": "Some More Sideways",
    "order_number": 413,
    "description": "With certain wrist twist. Can you see it?",
    "paper": {
      "original_size": "A7",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "gsm": "250",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "ref": "77725080001",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "name": null
    },
    "tool": null,
    "id": "abdc6a5c-86bc-4678-9494-94c3a4ea58f3",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/abdc6a5c-86bc-4678-9494-94c3a4ea58f3/600x600/863d5a31-d592-4c7a-b725-5c1b83babb93_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fabdc6a5c-86bc-4678-9494-94c3a4ea58f3%2F600x600%2F863d5a31-d592-4c7a-b725-5c1b83babb93_600x600?alt=media&token=2366003d-b371-4bbc-a86f-4647d5b82aab"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fabdc6a5c-86bc-4678-9494-94c3a4ea58f3%2F863d5a31-d592-4c7a-b725-5c1b83babb93?alt=media&token=9c830e71-4100-4709-93da-c40640bf977c",
        "reference": "/stroke_photos/abdc6a5c-86bc-4678-9494-94c3a4ea58f3/863d5a31-d592-4c7a-b725-5c1b83babb93"
      }
    },
    "order_id": 0
  },
  {
    "description": "<p>This will be just a splodge with my brush</p>",
    "name": "White Splodge Touch",
    "id": "abf3adda-434b-431f-9825-c7c1430a042b",
    "order_id": 0,
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand_model": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": null,
      "original_size": "A4",
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand": {},
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": null,
      "price": null,
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      }
    },
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fabf3adda-434b-431f-9825-c7c1430a042b%2F100f23b3-8991-40d5-93ea-a25db37a2e4d?alt=media&token=cccac69f-2e58-4eed-ac8a-333bb9113872",
        "reference": "/stroke_photos/abf3adda-434b-431f-9825-c7c1430a042b/100f23b3-8991-40d5-93ea-a25db37a2e4d"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fabf3adda-434b-431f-9825-c7c1430a042b%2F600x600%2F100f23b3-8991-40d5-93ea-a25db37a2e4d_600x600?alt=media&token=abd67296-2fda-404e-a558-ca1b5d9e658a",
        "reference": "stroke_photos/abf3adda-434b-431f-9825-c7c1430a042b/600x600/100f23b3-8991-40d5-93ea-a25db37a2e4d_600x600"
      }
    },
    "order_number": 511
  },
  {
    "order_number": 1207,
    "description": "",
    "name": "",
    "id": "ac321c0e-b1af-452b-8260-4b8077043439"
  },
  {
    "order_number": 1032,
    "name": "",
    "description": "",
    "id": "ac4c240c-778d-4984-b69a-c56f59d8b911"
  },
  {
    "tool": null,
    "order_number": 229,
    "name": "Black Series 1",
    "order_id": 0,
    "paper": {
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "ref": "77725080001",
      "gsm": "250",
      "price": 1.35,
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      }
    },
    "id": "ac524dd1-9a0f-452d-b0e8-c53fa8307680",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fac524dd1-9a0f-452d-b0e8-c53fa8307680%2F600x600%2F8443219a-6c76-47ec-b0ae-60fc7deeeab5_600x600?alt=media&token=615d4ba7-eace-4c75-b55d-17264dff43a6",
        "reference": "stroke_photos/ac524dd1-9a0f-452d-b0e8-c53fa8307680/600x600/8443219a-6c76-47ec-b0ae-60fc7deeeab5_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fac524dd1-9a0f-452d-b0e8-c53fa8307680%2F8443219a-6c76-47ec-b0ae-60fc7deeeab5?alt=media&token=7f448a9b-7edb-4caa-9375-bd8ed4ae345d",
        "reference": "/stroke_photos/ac524dd1-9a0f-452d-b0e8-c53fa8307680/8443219a-6c76-47ec-b0ae-60fc7deeeab5"
      }
    },
    "description": "Can I make it fully black, no gradient?"
  },
  {
    "order_number": 574,
    "paper": {
      "brand": {},
      "original_size": "A7",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": 0.4,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "20170703",
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "name": null
    },
    "tool": null,
    "order_id": 0,
    "description": "",
    "id": "ac53230c-d388-42a0-b079-362cf7d74d83",
    "photos": {
      "original": {
        "reference": "/stroke_photos/ac53230c-d388-42a0-b079-362cf7d74d83/9582ea95-179b-44c1-bb08-dc953717ea71",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fac53230c-d388-42a0-b079-362cf7d74d83%2F9582ea95-179b-44c1-bb08-dc953717ea71?alt=media&token=d42ee5d8-28a7-4755-bce5-ae08ca019968"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fac53230c-d388-42a0-b079-362cf7d74d83%2F600x600%2F9582ea95-179b-44c1-bb08-dc953717ea71_600x600?alt=media&token=01117e5e-87d1-4987-adb2-d55cd5199fd9",
        "reference": "stroke_photos/ac53230c-d388-42a0-b079-362cf7d74d83/600x600/9582ea95-179b-44c1-bb08-dc953717ea71_600x600"
      }
    },
    "name": "Blank"
  },
  {
    "id": "ac66028e-c352-4776-b5ff-92e9b71a4fb6",
    "paper": {
      "ref": "44202129",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": "",
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "paper_surface": {},
      "gsm": "200",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": null
    },
    "order_number": 87,
    "name": "WOE HOT 2",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/ac66028e-c352-4776-b5ff-92e9b71a4fb6/600x600/2c22f675-27e5-47eb-9d0d-aca31630ab66_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fac66028e-c352-4776-b5ff-92e9b71a4fb6%2F600x600%2F2c22f675-27e5-47eb-9d0d-aca31630ab66_600x600?alt=media&token=95a4e4bc-f4fe-445d-9d4d-109a922d4640"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fac66028e-c352-4776-b5ff-92e9b71a4fb6%2F2c22f675-27e5-47eb-9d0d-aca31630ab66?alt=media&token=2d09227f-567e-4296-bfc1-89c5b4156a6a",
        "reference": "/stroke_photos/ac66028e-c352-4776-b5ff-92e9b71a4fb6/2c22f675-27e5-47eb-9d0d-aca31630ab66"
      }
    },
    "description": "",
    "tool": null
  },
  {
    "description": "",
    "name": "",
    "order_number": 1135,
    "id": "acdce063-2699-460d-9ac9-7f4f818eb01a"
  },
  {
    "description": "",
    "order_id": 0,
    "name": "Reduced Cost Compensating With Awkwardness",
    "id": "ad0795b5-eaf9-4013-a12c-e3fff5d23278",
    "paper": {
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "original_size": "A7",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "price": 1.35,
      "name": null,
      "ref": "77725080001",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      }
    },
    "order_number": 224,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fad0795b5-eaf9-4013-a12c-e3fff5d23278%2F600x600%2F7cb88fe0-7996-4f4d-9acb-b76c5ec91350_600x600?alt=media&token=beaf799a-7e75-435b-adb7-bfe175f90b16",
        "reference": "stroke_photos/ad0795b5-eaf9-4013-a12c-e3fff5d23278/600x600/7cb88fe0-7996-4f4d-9acb-b76c5ec91350_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fad0795b5-eaf9-4013-a12c-e3fff5d23278%2F7cb88fe0-7996-4f4d-9acb-b76c5ec91350?alt=media&token=0b2e3b14-536c-40f6-9d84-7ddd1177645c",
        "reference": "/stroke_photos/ad0795b5-eaf9-4013-a12c-e3fff5d23278/7cb88fe0-7996-4f4d-9acb-b76c5ec91350"
      }
    },
    "tool": null
  },
  {
    "order_number": 188,
    "tool": null,
    "order_id": 0,
    "id": "adaf3cd5-8855-44c8-97f9-74385eba2e93",
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/adaf3cd5-8855-44c8-97f9-74385eba2e93/4fe481d2-1114-421b-ac68-460e3a3828ab",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fadaf3cd5-8855-44c8-97f9-74385eba2e93%2F4fe481d2-1114-421b-ac68-460e3a3828ab?alt=media&token=aee27ef3-89ce-4974-abe9-9f763031e073"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/adaf3cd5-8855-44c8-97f9-74385eba2e93/600x600/4fe481d2-1114-421b-ac68-460e3a3828ab_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fadaf3cd5-8855-44c8-97f9-74385eba2e93%2F600x600%2F4fe481d2-1114-421b-ac68-460e3a3828ab_600x600?alt=media&token=c994c8e9-7a83-4111-92f8-be48639c91d9"
      }
    },
    "paper": {
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "original_size": "A7",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "price": 1.35,
      "gsm": "250",
      "name": null,
      "ref": "77725080001",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    },
    "name": "Blank"
  },
  {
    "order_id": 0,
    "paper": {
      "price": null,
      "ref": "70025010030",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "name": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "gsm": "250",
      "brand": {},
      "brand_model": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    },
    "description": "",
    "id": "add3dc33-bb79-4dc5-a2f3-e41179c23307",
    "photos": {
      "original": {
        "reference": "/stroke_photos/add3dc33-bb79-4dc5-a2f3-e41179c23307/dafe4012-3a91-4c3d-bd47-8f665ffb30e1",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fadd3dc33-bb79-4dc5-a2f3-e41179c23307%2Fdafe4012-3a91-4c3d-bd47-8f665ffb30e1?alt=media&token=4e07f73e-d4ee-46e2-835e-042f49aa96a4"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fadd3dc33-bb79-4dc5-a2f3-e41179c23307%2F600x600%2Fdafe4012-3a91-4c3d-bd47-8f665ffb30e1_600x600?alt=media&token=613048c8-66f0-4df7-9b92-1e292ffdd4f8",
        "reference": "stroke_photos/add3dc33-bb79-4dc5-a2f3-e41179c23307/600x600/dafe4012-3a91-4c3d-bd47-8f665ffb30e1_600x600"
      }
    },
    "name": "Blank",
    "order_number": 596,
    "tool": null
  },
  {
    "order_id": 0,
    "name": "Mucho Macho",
    "description": "",
    "id": "ae28e03d-5798-45d2-bea7-6cd0ab1fdab1",
    "paper": {
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "name": null,
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "price": 1.35,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "original_size": "A7",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "gsm": "250",
      "ref": "77725080001"
    },
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fae28e03d-5798-45d2-bea7-6cd0ab1fdab1%2F600x600%2Fdb63f2be-1de0-4631-a412-391fe07bd16d_600x600?alt=media&token=206775d7-9cb9-440c-81b0-97e80c8e63a6",
        "reference": "stroke_photos/ae28e03d-5798-45d2-bea7-6cd0ab1fdab1/600x600/db63f2be-1de0-4631-a412-391fe07bd16d_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fae28e03d-5798-45d2-bea7-6cd0ab1fdab1%2Fdb63f2be-1de0-4631-a412-391fe07bd16d?alt=media&token=d32b7a7d-4010-4b13-8dc1-73b829976d07",
        "reference": "/stroke_photos/ae28e03d-5798-45d2-bea7-6cd0ab1fdab1/db63f2be-1de0-4631-a412-391fe07bd16d"
      }
    },
    "order_number": 207
  },
  {
    "description": "",
    "name": "",
    "order_number": 1068,
    "id": "ae4bf524-bc3d-4d16-b39e-867a04d181b4"
  },
  {
    "order_number": 1152,
    "name": "",
    "description": "",
    "id": "ae4ea79c-049b-4a17-aaf5-001363d799d3"
  },
  {
    "order_number": 772,
    "id": "af505abc-4e45-43d9-a14c-cfc5b4ca2f93",
    "order_id": 0,
    "paper": {
      "ref": "77725080001",
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "original_size": "A7",
      "price": 1.35,
      "gsm": "250",
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      }
    },
    "name": "Blank",
    "tool": null,
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/af505abc-4e45-43d9-a14c-cfc5b4ca2f93/0cf6f1ff-8e2c-4590-8e2e-a9fea75c9465",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Faf505abc-4e45-43d9-a14c-cfc5b4ca2f93%2F0cf6f1ff-8e2c-4590-8e2e-a9fea75c9465?alt=media&token=07f35560-42ce-40c3-9f75-9b5fcf2c5b58"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/af505abc-4e45-43d9-a14c-cfc5b4ca2f93/600x600/0cf6f1ff-8e2c-4590-8e2e-a9fea75c9465_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Faf505abc-4e45-43d9-a14c-cfc5b4ca2f93%2F600x600%2F0cf6f1ff-8e2c-4590-8e2e-a9fea75c9465_600x600?alt=media&token=639518fe-2a99-4ad7-afc4-2762cafb9d6f"
      }
    }
  },
  {
    "order_number": 453,
    "photos": {
      "original": {
        "reference": "/stroke_photos/af73e708-fa70-481d-a209-bf7d106a95a9/6d73ca27-b81e-4cdd-aabb-f3b1f4eb62a2",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Faf73e708-fa70-481d-a209-bf7d106a95a9%2F6d73ca27-b81e-4cdd-aabb-f3b1f4eb62a2?alt=media&token=84a83861-7239-431e-aedb-b5a96f487dd6"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/af73e708-fa70-481d-a209-bf7d106a95a9/600x600/6d73ca27-b81e-4cdd-aabb-f3b1f4eb62a2_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Faf73e708-fa70-481d-a209-bf7d106a95a9%2F600x600%2F6d73ca27-b81e-4cdd-aabb-f3b1f4eb62a2_600x600?alt=media&token=70ae44af-5a1d-436c-8f24-bc94053019e3"
      }
    },
    "tool": null,
    "description": "<p>Okay, not bad</p>",
    "id": "af73e708-fa70-481d-a209-bf7d106a95a9",
    "order_id": 0,
    "name": "Another Regular White",
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "price": null,
      "brand_model": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "gsm": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": null,
      "name": null,
      "original_size": "A4",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      }
    }
  },
  {
    "description": "",
    "order_number": 1100,
    "name": "",
    "id": "af82687c-14fe-413e-8c58-fb58bc62edf1"
  },
  {
    "description": "<p>Better.</p>",
    "order_id": 0,
    "order_number": 451,
    "tool": null,
    "paper": {
      "name": null,
      "brand_model": {},
      "gsm": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand": {},
      "ref": null,
      "price": null,
      "original_size": "A4",
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/af94c7d6-b0c0-4733-8fbf-747c0835ea46/568fc134-6a1b-46eb-add0-a9712b7618d9",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Faf94c7d6-b0c0-4733-8fbf-747c0835ea46%2F568fc134-6a1b-46eb-add0-a9712b7618d9?alt=media&token=52dc069c-8cae-4dfb-b102-826a52a4164f"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Faf94c7d6-b0c0-4733-8fbf-747c0835ea46%2F600x600%2F568fc134-6a1b-46eb-add0-a9712b7618d9_600x600?alt=media&token=e6221ef1-8cf9-433e-a3e0-64450a679693",
        "reference": "stroke_photos/af94c7d6-b0c0-4733-8fbf-747c0835ea46/600x600/568fc134-6a1b-46eb-add0-a9712b7618d9_600x600"
      }
    },
    "id": "af94c7d6-b0c0-4733-8fbf-747c0835ea46",
    "name": "Better Parabola"
  },
  {
    "name": "Quality Paint",
    "description": "",
    "id": "afb28c56-6b30-4529-b0c3-2f6d543acfe5",
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/afb28c56-6b30-4529-b0c3-2f6d543acfe5/67abf019-6c3e-4b40-a162-bbdd4121db18",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fafb28c56-6b30-4529-b0c3-2f6d543acfe5%2F67abf019-6c3e-4b40-a162-bbdd4121db18?alt=media&token=8138d080-8418-4263-9934-8245f3e7688c"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/afb28c56-6b30-4529-b0c3-2f6d543acfe5/600x600/67abf019-6c3e-4b40-a162-bbdd4121db18_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fafb28c56-6b30-4529-b0c3-2f6d543acfe5%2F600x600%2F67abf019-6c3e-4b40-a162-bbdd4121db18_600x600?alt=media&token=91c06b5f-826d-4a3e-b3ac-ebc9be937406"
      }
    },
    "order_number": 77,
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": "200",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "ref": "44202129",
      "name": "",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "price": null,
      "paper_surface": {},
      "original_size": "A4",
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      }
    },
    "tool": null
  },
  {
    "order_number": 291,
    "id": "afbe977b-401e-4df8-876c-3309e2c95539",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/afbe977b-401e-4df8-876c-3309e2c95539/600x600/9969cfb3-4f8d-4148-b148-d77522c44fc5_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fafbe977b-401e-4df8-876c-3309e2c95539%2F600x600%2F9969cfb3-4f8d-4148-b148-d77522c44fc5_600x600?alt=media&token=44898efa-bd04-45b4-8929-423b9a468f7e"
      },
      "original": {
        "reference": "/stroke_photos/afbe977b-401e-4df8-876c-3309e2c95539/9969cfb3-4f8d-4148-b148-d77522c44fc5",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fafbe977b-401e-4df8-876c-3309e2c95539%2F9969cfb3-4f8d-4148-b148-d77522c44fc5?alt=media&token=35f99008-f7ec-4e71-9fe3-84d234a1bbfc"
      }
    },
    "tool": null,
    "name": "Goal!",
    "description": "<p>I keep missing it!</p>",
    "order_id": 0,
    "paper": {
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "gsm": "250",
      "original_size": "A7",
      "ref": "20180107"
    }
  },
  {
    "order_number": 1079,
    "name": "",
    "description": "",
    "id": "afe75bcf-0836-4547-9f2f-4a6cea2900f7"
  },
  {
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Faffa4159-6c4d-4a08-8227-9096cdde8a99%2F3cef90cb-6606-4c33-9cc3-5aa93e60ce9d?alt=media&token=7aa0bc72-2ed8-4d7b-be93-56da33d83022",
        "reference": "/stroke_photos/affa4159-6c4d-4a08-8227-9096cdde8a99/3cef90cb-6606-4c33-9cc3-5aa93e60ce9d"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Faffa4159-6c4d-4a08-8227-9096cdde8a99%2F600x600%2F3cef90cb-6606-4c33-9cc3-5aa93e60ce9d_600x600?alt=media&token=d5738ed4-66eb-48ad-bfe3-27e4d52a51a5",
        "reference": "stroke_photos/affa4159-6c4d-4a08-8227-9096cdde8a99/600x600/3cef90cb-6606-4c33-9cc3-5aa93e60ce9d_600x600"
      }
    },
    "tool": null,
    "name": "Blank",
    "paper": {
      "price": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": "70025010030",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "brand_model": {},
      "original_size": "A7",
      "gsm": "250"
    },
    "id": "affa4159-6c4d-4a08-8227-9096cdde8a99",
    "order_number": 604,
    "description": ""
  },
  {
    "name": "Just A Quickie",
    "order_number": 362,
    "tool": null,
    "paper": {
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "ref": "20180107",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "price": null,
      "gsm": "250",
      "name": null,
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86"
    },
    "order_id": 0,
    "description": "<p>See what happens quickly with a regular stroke again</p>",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/affd14ec-9d13-4d80-9842-fe5726c5acdb/600x600/ed688f37-2018-44b9-a638-682fe591ffe7_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Faffd14ec-9d13-4d80-9842-fe5726c5acdb%2F600x600%2Fed688f37-2018-44b9-a638-682fe591ffe7_600x600?alt=media&token=0f17f299-457a-4eca-973b-22c5d9af0eed"
      },
      "original": {
        "reference": "/stroke_photos/affd14ec-9d13-4d80-9842-fe5726c5acdb/ed688f37-2018-44b9-a638-682fe591ffe7",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Faffd14ec-9d13-4d80-9842-fe5726c5acdb%2Fed688f37-2018-44b9-a638-682fe591ffe7?alt=media&token=4ce6cc41-fec4-47eb-9e97-03dfec971ce4"
      }
    },
    "id": "affd14ec-9d13-4d80-9842-fe5726c5acdb"
  },
  {
    "id": "b0113b43-bcc6-4530-a785-71ee8ffe8a08",
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "brand_model": {},
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "name": null,
      "brand": {},
      "original_size": "A7",
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "70025010030",
      "color": {
        "hex": "#f5f5f5",
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      }
    },
    "order_number": 849,
    "description": "",
    "order_id": 0,
    "name": "Blank"
  },
  {
    "order_number": 1002,
    "name": "",
    "description": "",
    "id": "b0137629-5ef4-4daa-aab2-53f2e9976708"
  },
  {
    "paper": {
      "original_size": "A4",
      "price": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "gsm": null,
      "brand_model": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "ref": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      }
    },
    "order_id": 0,
    "id": "b031040c-453d-435f-bea6-ae8697281c4e",
    "order_number": 449,
    "name": "Full White",
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb031040c-453d-435f-bea6-ae8697281c4e%2F21372e6e-b781-4703-8a06-dd3130eaebf2?alt=media&token=6f879008-c495-4aba-a1fd-2e54d3330be7",
        "reference": "/stroke_photos/b031040c-453d-435f-bea6-ae8697281c4e/21372e6e-b781-4703-8a06-dd3130eaebf2"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/b031040c-453d-435f-bea6-ae8697281c4e/600x600/21372e6e-b781-4703-8a06-dd3130eaebf2_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb031040c-453d-435f-bea6-ae8697281c4e%2F600x600%2F21372e6e-b781-4703-8a06-dd3130eaebf2_600x600?alt=media&token=5d2f0595-5c01-45a2-83cf-35b4c2b41e26"
      }
    },
    "description": "<p>Trying more paint on the whole brush, \"not just the tip\".</p>"
  },
  {
    "name": "",
    "order_number": 1132,
    "description": "",
    "id": "b0745cf2-e901-4d96-9d12-9f89bd3aae42"
  },
  {
    "description": "",
    "tool": null,
    "name": "Blank",
    "photos": {
      "original": {
        "reference": "/stroke_photos/b0dabc87-882c-4212-8ba0-c3eea6c37a0f/ac761f74-486c-4807-84e8-23707ad72697",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb0dabc87-882c-4212-8ba0-c3eea6c37a0f%2Fac761f74-486c-4807-84e8-23707ad72697?alt=media&token=875da2ca-a10d-4c43-814a-4e58d6317770"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb0dabc87-882c-4212-8ba0-c3eea6c37a0f%2F600x600%2Fac761f74-486c-4807-84e8-23707ad72697_600x600?alt=media&token=f1fe5a57-7574-4b39-aee0-cd47a377df66",
        "reference": "stroke_photos/b0dabc87-882c-4212-8ba0-c3eea6c37a0f/600x600/ac761f74-486c-4807-84e8-23707ad72697_600x600"
      }
    },
    "id": "b0dabc87-882c-4212-8ba0-c3eea6c37a0f",
    "order_number": 189,
    "order_id": 0,
    "paper": {
      "ref": "77725080001",
      "gsm": "250",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "original_size": "A7",
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      }
    }
  },
  {
    "description": "Now that's more like what I expected. Though Far away from the perfect stroke.",
    "id": "b0fb5ef2-a95c-4791-b823-a7467bbdb32f",
    "paper": {
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "original_size": "A7",
      "price": 1.35,
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "gsm": "250"
    },
    "order_id": 0,
    "order_number": 246,
    "name": "Musically 9 - Spin 3",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/b0fb5ef2-a95c-4791-b823-a7467bbdb32f/600x600/f62657de-c1e9-4f9b-896e-facac0e66077_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb0fb5ef2-a95c-4791-b823-a7467bbdb32f%2F600x600%2Ff62657de-c1e9-4f9b-896e-facac0e66077_600x600?alt=media&token=21756dae-b9c0-4943-8e99-94e8212fac33"
      },
      "original": {
        "reference": "/stroke_photos/b0fb5ef2-a95c-4791-b823-a7467bbdb32f/f62657de-c1e9-4f9b-896e-facac0e66077",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb0fb5ef2-a95c-4791-b823-a7467bbdb32f%2Ff62657de-c1e9-4f9b-896e-facac0e66077?alt=media&token=8e7fd06b-b2ef-4d4f-9975-2f4df5a29e86"
      }
    },
    "tool": null
  },
  {
    "order_id": 0,
    "order_number": 216,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/b12740af-0f54-49ab-913f-b854cbe2fa91/600x600/48cd6e96-ed2c-4ed6-a511-f030622fae0c_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb12740af-0f54-49ab-913f-b854cbe2fa91%2F600x600%2F48cd6e96-ed2c-4ed6-a511-f030622fae0c_600x600?alt=media&token=d10548ea-1f4a-456d-8435-def0efc77f2c"
      },
      "original": {
        "reference": "/stroke_photos/b12740af-0f54-49ab-913f-b854cbe2fa91/48cd6e96-ed2c-4ed6-a511-f030622fae0c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb12740af-0f54-49ab-913f-b854cbe2fa91%2F48cd6e96-ed2c-4ed6-a511-f030622fae0c?alt=media&token=28531c12-c6e9-4008-a9dd-ee9c600dbdc2"
      }
    },
    "id": "b12740af-0f54-49ab-913f-b854cbe2fa91",
    "paper": {
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": 1.35,
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "77725080001",
      "name": null,
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7"
    },
    "description": "",
    "name": "Resemblance X + 1",
    "tool": null
  },
  {
    "paper": {
      "ref": "20180107",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "original_size": "A7",
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "price": null,
      "brand": {},
      "gsm": "250",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "order_id": 0,
    "tool": null,
    "id": "b14ec99a-1803-4df3-9f4e-a81c496b42ea",
    "description": "<p>What happens if I put more paint? Meh</p>",
    "name": "Skinny 4",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb14ec99a-1803-4df3-9f4e-a81c496b42ea%2F600x600%2Fb7ccb654-324b-4409-9715-db137505a851_600x600?alt=media&token=335005a3-0248-4354-9963-1e0e4e3d8fa9",
        "reference": "stroke_photos/b14ec99a-1803-4df3-9f4e-a81c496b42ea/600x600/b7ccb654-324b-4409-9715-db137505a851_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb14ec99a-1803-4df3-9f4e-a81c496b42ea%2Fb7ccb654-324b-4409-9715-db137505a851?alt=media&token=a083af11-7327-43e9-949d-76311319a712",
        "reference": "/stroke_photos/b14ec99a-1803-4df3-9f4e-a81c496b42ea/b7ccb654-324b-4409-9715-db137505a851"
      }
    },
    "order_number": 300
  },
  {
    "order_number": 510,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb162af2b-0200-4f14-8775-582c82029f8d%2Fa3fb58b0-2f06-4fa6-a275-284879798c2a?alt=media&token=7a3cca16-3ab6-4a64-962b-61d6d99248c7",
        "reference": "/stroke_photos/b162af2b-0200-4f14-8775-582c82029f8d/a3fb58b0-2f06-4fa6-a275-284879798c2a"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/b162af2b-0200-4f14-8775-582c82029f8d/600x600/a3fb58b0-2f06-4fa6-a275-284879798c2a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb162af2b-0200-4f14-8775-582c82029f8d%2F600x600%2Fa3fb58b0-2f06-4fa6-a275-284879798c2a_600x600?alt=media&token=4b47c0a0-8ea9-434a-9154-ab6ae9901875"
      }
    },
    "id": "b162af2b-0200-4f14-8775-582c82029f8d",
    "tool": null,
    "order_id": 0,
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "gsm": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": null,
      "brand_model": {},
      "price": null,
      "brand": {},
      "original_size": "A4",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    },
    "name": "White No Sound Wave",
    "description": "<p>Now time for some silence with a flat thin line..</p>"
  },
  {
    "order_id": 0,
    "description": "",
    "id": "b1986910-33b3-495a-a02c-d8a857e19892",
    "order_number": 17,
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "44202129",
      "name": "",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "original_size": "A4",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "gsm": "200",
      "paper_surface": {},
      "price": null
    },
    "name": "More Water But Mixed Better",
    "tool": {},
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb1986910-33b3-495a-a02c-d8a857e19892%2F600x600%2F8c719434-5a84-403e-851f-b65a181b9418_600x600?alt=media&token=c869868d-2655-441d-91c4-933ce4e0e8db",
        "reference": "stroke_photos/b1986910-33b3-495a-a02c-d8a857e19892/600x600/8c719434-5a84-403e-851f-b65a181b9418_600x600"
      },
      "original": {
        "reference": "/stroke_photos/b1986910-33b3-495a-a02c-d8a857e19892/8c719434-5a84-403e-851f-b65a181b9418",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb1986910-33b3-495a-a02c-d8a857e19892%2F8c719434-5a84-403e-851f-b65a181b9418?alt=media&token=cc7a2c28-a93d-4872-aa34-89f3d53603f1"
      }
    }
  },
  {
    "order_id": 0,
    "tool": null,
    "description": "What would happen if I changed the direction? I like that playfullness at the end that happened on its own",
    "id": "b1a0a8fa-71b2-49d1-ae77-ca5348d99d62",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb1a0a8fa-71b2-49d1-ae77-ca5348d99d62%2F36040174-746a-47f2-ab89-3451a2c9e7b6?alt=media&token=bb54d91a-f403-4f65-b20e-80708f4b3be6",
        "reference": "/stroke_photos/b1a0a8fa-71b2-49d1-ae77-ca5348d99d62/36040174-746a-47f2-ab89-3451a2c9e7b6"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb1a0a8fa-71b2-49d1-ae77-ca5348d99d62%2F600x600%2F36040174-746a-47f2-ab89-3451a2c9e7b6_600x600?alt=media&token=ce8111ec-d94a-4c8f-bb00-d686f6381176",
        "reference": "stroke_photos/b1a0a8fa-71b2-49d1-ae77-ca5348d99d62/600x600/36040174-746a-47f2-ab89-3451a2c9e7b6_600x600"
      }
    },
    "paper": {
      "gsm": "250",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "price": 1.35,
      "original_size": "A7",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "ref": "77725080001"
    },
    "name": "Ascension",
    "order_number": 421
  },
  {
    "paper": {
      "ref": "20180107",
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "gsm": "250",
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      }
    },
    "order_id": 0,
    "id": "b1b932a0-21aa-43c5-bb42-c8568f4b7c83",
    "name": "Mixer 4",
    "tool": null,
    "order_number": 394,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb1b932a0-21aa-43c5-bb42-c8568f4b7c83%2F600x600%2Fa35f7c2d-e309-45a0-aff8-7b14aea48cfc_600x600?alt=media&token=21004802-b0bc-44ae-9486-5c0c82f574c4",
        "reference": "stroke_photos/b1b932a0-21aa-43c5-bb42-c8568f4b7c83/600x600/a35f7c2d-e309-45a0-aff8-7b14aea48cfc_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb1b932a0-21aa-43c5-bb42-c8568f4b7c83%2Fa35f7c2d-e309-45a0-aff8-7b14aea48cfc?alt=media&token=878e5c7a-9771-4aae-ae12-908b2d85f5a3",
        "reference": "/stroke_photos/b1b932a0-21aa-43c5-bb42-c8568f4b7c83/a35f7c2d-e309-45a0-aff8-7b14aea48cfc"
      }
    },
    "description": "<p>Add Even more more silver</p>"
  },
  {
    "id": "b1e83d12-4390-446c-9246-8ea247b0d44c",
    "description": "",
    "name": "Blank",
    "order_number": 998,
    "order_id": 0,
    "paper": {
      "ref": "30075105",
      "name": "tinto",
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "price": 19.3,
      "original_size": "75x105cm",
      "gsm": "640",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      }
    }
  },
  {
    "name": "Reusing White Paint 3",
    "order_number": 463,
    "id": "b20dc03f-5256-484b-be94-aa872cedcf42",
    "paper": {
      "brand": {},
      "gsm": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "brand_model": {},
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A4",
      "name": null,
      "ref": null,
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      }
    },
    "description": "<p>There was more paint than I expected. Craving less paint</p>",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/b20dc03f-5256-484b-be94-aa872cedcf42/600x600/9675379c-5ecc-4cd5-bff7-9adae0678347_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb20dc03f-5256-484b-be94-aa872cedcf42%2F600x600%2F9675379c-5ecc-4cd5-bff7-9adae0678347_600x600?alt=media&token=cf04823c-8432-40ac-9ecb-77383bc10256"
      },
      "original": {
        "reference": "/stroke_photos/b20dc03f-5256-484b-be94-aa872cedcf42/9675379c-5ecc-4cd5-bff7-9adae0678347",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb20dc03f-5256-484b-be94-aa872cedcf42%2F9675379c-5ecc-4cd5-bff7-9adae0678347?alt=media&token=0c2aa635-ec98-43f4-bcc4-8c824cbcaa30"
      }
    },
    "tool": null,
    "order_id": 0
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb27a40d4-dfb0-45a1-946f-6198583f3927%2F600x600%2F6dc735a1-b0c4-4d18-b16c-ea83be56ee29_600x600?alt=media&token=e374c912-3527-4d85-9577-84a965b31d8a",
        "reference": "stroke_photos/b27a40d4-dfb0-45a1-946f-6198583f3927/600x600/6dc735a1-b0c4-4d18-b16c-ea83be56ee29_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb27a40d4-dfb0-45a1-946f-6198583f3927%2F6dc735a1-b0c4-4d18-b16c-ea83be56ee29?alt=media&token=464c61c2-25ce-4e19-9c52-d55d2a2a5c2a",
        "reference": "/stroke_photos/b27a40d4-dfb0-45a1-946f-6198583f3927/6dc735a1-b0c4-4d18-b16c-ea83be56ee29"
      }
    },
    "order_number": 13,
    "description": "",
    "paper": {
      "name": "",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "price": null,
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "original_size": "A4",
      "ref": "44202129",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {},
      "gsm": "200"
    },
    "tool": {},
    "id": "b27a40d4-dfb0-45a1-946f-6198583f3927",
    "order_id": 0,
    "name": "First Resemblance"
  },
  {
    "paper": {
      "ref": "70025010030",
      "brand": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "name": null,
      "brand_model": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "price": null,
      "original_size": "A7",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "id": "b2a7a27d-8d21-46e2-9a1d-2a07ddfd5a7b",
    "name": "Blank",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/b2a7a27d-8d21-46e2-9a1d-2a07ddfd5a7b/600x600/43fdd5f0-de23-46ea-a8da-72cf2fde622e_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb2a7a27d-8d21-46e2-9a1d-2a07ddfd5a7b%2F600x600%2F43fdd5f0-de23-46ea-a8da-72cf2fde622e_600x600?alt=media&token=1ad4bc95-34ac-4d64-b597-a045fed46e52"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb2a7a27d-8d21-46e2-9a1d-2a07ddfd5a7b%2F43fdd5f0-de23-46ea-a8da-72cf2fde622e?alt=media&token=7d3f9f6d-422d-4db2-ad4d-ba74ffec3363",
        "reference": "/stroke_photos/b2a7a27d-8d21-46e2-9a1d-2a07ddfd5a7b/43fdd5f0-de23-46ea-a8da-72cf2fde622e"
      }
    },
    "description": "",
    "order_number": 620,
    "tool": null
  },
  {
    "order_number": 1123,
    "name": "",
    "description": "",
    "id": "b2c76c4a-be3e-438c-aaf2-511a67b6fc2e"
  },
  {
    "tool": null,
    "name": "Hook White",
    "description": "<p>You know, movement in life isn't always forward unfortunately</p>",
    "paper": {
      "original_size": "A4",
      "gsm": null,
      "price": null,
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "ref": null,
      "name": null,
      "brand_model": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand": {}
    },
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/b2d84fb2-b10f-4712-ba9e-52c373529b44/b4d72ee4-bbb4-4eeb-a808-1e3ca79a8d90",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb2d84fb2-b10f-4712-ba9e-52c373529b44%2Fb4d72ee4-bbb4-4eeb-a808-1e3ca79a8d90?alt=media&token=fc252ac7-fc8d-4845-84f6-5ebd946ac3e8"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/b2d84fb2-b10f-4712-ba9e-52c373529b44/600x600/b4d72ee4-bbb4-4eeb-a808-1e3ca79a8d90_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb2d84fb2-b10f-4712-ba9e-52c373529b44%2F600x600%2Fb4d72ee4-bbb4-4eeb-a808-1e3ca79a8d90_600x600?alt=media&token=6b1a86df-2bc6-42f5-a1f2-1d8806192b98"
      }
    },
    "id": "b2d84fb2-b10f-4712-ba9e-52c373529b44",
    "order_number": 485
  },
  {
    "tool": null,
    "order_id": 0,
    "name": "Blank",
    "description": "",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb32beb2d-2c0b-4d88-99f1-fdb28289ed07%2F0a52a351-02a5-4714-a5a6-4dd9e9fdc131?alt=media&token=82207de4-bbb9-49de-8c17-3b414b21fef3",
        "reference": "/stroke_photos/b32beb2d-2c0b-4d88-99f1-fdb28289ed07/0a52a351-02a5-4714-a5a6-4dd9e9fdc131"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/b32beb2d-2c0b-4d88-99f1-fdb28289ed07/600x600/0a52a351-02a5-4714-a5a6-4dd9e9fdc131_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb32beb2d-2c0b-4d88-99f1-fdb28289ed07%2F600x600%2F0a52a351-02a5-4714-a5a6-4dd9e9fdc131_600x600?alt=media&token=5cff4b3f-b8a8-46cf-8bc0-c768f8b4485b"
      }
    },
    "paper": {
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "price": 0.4,
      "brand": {},
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "250",
      "ref": "20170703",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "name": null
    },
    "id": "b32beb2d-2c0b-4d88-99f1-fdb28289ed07",
    "order_number": 731
  },
  {
    "id": "b33918b2-81f8-4601-bbc6-2f9299b5064e",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb33918b2-81f8-4601-bbc6-2f9299b5064e%2F600x600%2F47a41aae-1a41-4207-837c-93074244ad6a_600x600?alt=media&token=da2a9db1-3c11-4850-8e35-3130faaa6915",
        "reference": "stroke_photos/b33918b2-81f8-4601-bbc6-2f9299b5064e/600x600/47a41aae-1a41-4207-837c-93074244ad6a_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb33918b2-81f8-4601-bbc6-2f9299b5064e%2F47a41aae-1a41-4207-837c-93074244ad6a?alt=media&token=d10a8871-30e1-4b16-8966-cde0ef5b7ab9",
        "reference": "/stroke_photos/b33918b2-81f8-4601-bbc6-2f9299b5064e/47a41aae-1a41-4207-837c-93074244ad6a"
      }
    },
    "description": "I tried to keep it thinner, with the size of the brush and also consistent. Killed the arc though",
    "tool": null,
    "order_number": 412,
    "paper": {
      "name": null,
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "price": 1.35,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "original_size": "A7",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "ref": "77725080001"
    },
    "name": "Thinner Consistency"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/b349604f-30c6-458c-86b5-cc4732f6055d/d0fa1174-8c8a-479d-81ad-dcfb806af6ca",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb349604f-30c6-458c-86b5-cc4732f6055d%2Fd0fa1174-8c8a-479d-81ad-dcfb806af6ca?alt=media&token=3b4f659a-1f1b-4e9a-9eed-290bc80593d0"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb349604f-30c6-458c-86b5-cc4732f6055d%2F600x600%2Fd0fa1174-8c8a-479d-81ad-dcfb806af6ca_600x600?alt=media&token=465de427-19d3-4607-b3d7-645085772782",
        "reference": "stroke_photos/b349604f-30c6-458c-86b5-cc4732f6055d/600x600/d0fa1174-8c8a-479d-81ad-dcfb806af6ca_600x600"
      }
    },
    "order_number": 524,
    "description": "<p>One more, normal everything</p>",
    "name": "Silver-On-Black Regular 4",
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {},
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "name": null,
      "price": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "original_size": "A4",
      "gsm": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    },
    "order_id": 0,
    "tool": null,
    "id": "b349604f-30c6-458c-86b5-cc4732f6055d"
  },
  {
    "order_number": 1129,
    "name": "",
    "description": "",
    "id": "b3645e25-cad2-4103-8328-8cf8117e2da3"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1212,
    "id": "b367bbbc-101e-4221-ab10-f5af584ea3cb"
  },
  {
    "name": "Blank",
    "order_number": 968,
    "paper": {
      "brand_model": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "price": null,
      "gsm": null,
      "brand": {},
      "ref": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "original_size": "A4",
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      }
    },
    "order_id": 0,
    "description": "",
    "id": "b36a3817-e871-469f-a927-f8463611e741"
  },
  {
    "tool": null,
    "name": "Resemblance Y",
    "photos": {
      "original": {
        "reference": "/stroke_photos/b36abd96-a04f-4cf3-baa1-4b30dda25fdb/99a9a464-69e3-4d29-8e93-7fe1d0811285",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb36abd96-a04f-4cf3-baa1-4b30dda25fdb%2F99a9a464-69e3-4d29-8e93-7fe1d0811285?alt=media&token=a76bce0b-3d8d-4f79-9f75-1e84a033cc56"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/b36abd96-a04f-4cf3-baa1-4b30dda25fdb/600x600/99a9a464-69e3-4d29-8e93-7fe1d0811285_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb36abd96-a04f-4cf3-baa1-4b30dda25fdb%2F600x600%2F99a9a464-69e3-4d29-8e93-7fe1d0811285_600x600?alt=media&token=3f974bec-69fe-4483-9721-62c5b9b5ce24"
      }
    },
    "id": "b36abd96-a04f-4cf3-baa1-4b30dda25fdb",
    "description": "<p>This one is a really nice one. Good Interesting start, adequate body, effective finish.</p>",
    "order_id": 0,
    "paper": {
      "brand": {},
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "original_size": "A7",
      "gsm": "250",
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "20180107",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "order_number": 335
  },
  {
    "order_number": 1083,
    "description": "",
    "name": "",
    "id": "b36bf314-14d1-4287-baed-ed5adb306805"
  },
  {
    "name": "",
    "order_number": 1211,
    "description": "",
    "id": "b38b04ff-9982-4d07-a19d-10609b054100"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1170,
    "id": "b4042608-54fb-410b-a47e-1b8310c7ae67"
  },
  {
    "description": "",
    "paper": {
      "gsm": "250",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "name": null,
      "ref": "70025010030",
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {},
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null
    },
    "name": "Blank",
    "order_number": 637,
    "id": "b450c486-2276-48bd-bc61-03277c89dbe8",
    "order_id": 0,
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb450c486-2276-48bd-bc61-03277c89dbe8%2F3bd9b55b-8412-452d-9f41-4ee1494f5b6d?alt=media&token=4ff751fe-edd3-4a05-8594-6c55b13ed755",
        "reference": "/stroke_photos/b450c486-2276-48bd-bc61-03277c89dbe8/3bd9b55b-8412-452d-9f41-4ee1494f5b6d"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/b450c486-2276-48bd-bc61-03277c89dbe8/600x600/3bd9b55b-8412-452d-9f41-4ee1494f5b6d_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb450c486-2276-48bd-bc61-03277c89dbe8%2F600x600%2F3bd9b55b-8412-452d-9f41-4ee1494f5b6d_600x600?alt=media&token=87ebe30c-e33d-418b-a72b-142da5d11f0d"
      }
    }
  },
  {
    "tool": null,
    "paper": {
      "ref": null,
      "brand_model": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A4",
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "brand": {},
      "name": null,
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      }
    },
    "order_id": 0,
    "description": "<p>Trying to put evenly more paint, rather than just in the beginning</p>",
    "id": "b4a5b965-d876-4dab-ada4-255fde243bb8",
    "order_number": 529,
    "name": "Silver-On-Black Regular 6",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb4a5b965-d876-4dab-ada4-255fde243bb8%2F600x600%2F08f943d3-5f58-44b6-85c5-13b7129d512e_600x600?alt=media&token=3d013183-9135-46c9-a656-8f6edac35983",
        "reference": "stroke_photos/b4a5b965-d876-4dab-ada4-255fde243bb8/600x600/08f943d3-5f58-44b6-85c5-13b7129d512e_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb4a5b965-d876-4dab-ada4-255fde243bb8%2F08f943d3-5f58-44b6-85c5-13b7129d512e?alt=media&token=eb850a13-5f18-42bc-92d2-61289fede65c",
        "reference": "/stroke_photos/b4a5b965-d876-4dab-ada4-255fde243bb8/08f943d3-5f58-44b6-85c5-13b7129d512e"
      }
    }
  },
  {
    "order_number": 1043,
    "name": "",
    "description": "",
    "id": "b4bba0d0-fc49-4a06-8756-2b1e2c17771e"
  },
  {
    "order_id": 0,
    "description": "",
    "paper": {
      "name": "tinto",
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "gsm": "640",
      "brand_model": {
        "name": "Artistico",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "original_size": "75x105cm",
      "ref": "30075105",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "price": 19.3,
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      }
    },
    "order_number": 692,
    "photos": {
      "original": {
        "reference": "/stroke_photos/b4ec521b-841c-43dc-a460-ea062a806ac1/b1360104-256c-442b-bff5-aacbaa72db46",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb4ec521b-841c-43dc-a460-ea062a806ac1%2Fb1360104-256c-442b-bff5-aacbaa72db46?alt=media&token=2c1051cb-3155-47b4-8b6e-99e56700123c"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/b4ec521b-841c-43dc-a460-ea062a806ac1/600x600/b1360104-256c-442b-bff5-aacbaa72db46_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb4ec521b-841c-43dc-a460-ea062a806ac1%2F600x600%2Fb1360104-256c-442b-bff5-aacbaa72db46_600x600?alt=media&token=6df43f7d-a614-4f54-9217-a6258a407d0b"
      }
    },
    "name": "Blank",
    "id": "b4ec521b-841c-43dc-a460-ea062a806ac1",
    "tool": null
  },
  {
    "name": "",
    "order_number": 1112,
    "description": "",
    "id": "b5aeba8c-30df-4e54-94ce-f55cb5a70c9d"
  },
  {
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "20180107",
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "original_size": "A7",
      "gsm": "250",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "price": null,
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      }
    },
    "order_id": 0,
    "description": "<p>I don't know what to say. I guess good curve. But that disappointment...</p>",
    "order_number": 310,
    "id": "b5ca2195-dc6f-4a67-b83f-be1fd376bc9a",
    "name": "No Name",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb5ca2195-dc6f-4a67-b83f-be1fd376bc9a%2F600x600%2Fcc344b3b-e897-49cc-9580-ce35ff784ea8_600x600?alt=media&token=58f733cd-54c6-4b1d-8f73-9bdee4f5b79a",
        "reference": "stroke_photos/b5ca2195-dc6f-4a67-b83f-be1fd376bc9a/600x600/cc344b3b-e897-49cc-9580-ce35ff784ea8_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb5ca2195-dc6f-4a67-b83f-be1fd376bc9a%2Fcc344b3b-e897-49cc-9580-ce35ff784ea8?alt=media&token=d506ea41-e003-4c05-9022-ddc769a1ef55",
        "reference": "/stroke_photos/b5ca2195-dc6f-4a67-b83f-be1fd376bc9a/cc344b3b-e897-49cc-9580-ce35ff784ea8"
      }
    }
  },
  {
    "name": "",
    "description": "",
    "order_number": 1059,
    "id": "b5edcf13-6ce8-43c5-a7bb-c9c757eaac2c"
  },
  {
    "tool": null,
    "name": "Blank",
    "description": "",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb6181a71-b422-4810-bb4e-e3f3139e8c95%2Fff2f99d0-1ac2-4de7-bcd6-33a6f54f6ea3?alt=media&token=1ce6b679-9df9-4473-b9a4-9f0d7acf416d",
        "reference": "/stroke_photos/b6181a71-b422-4810-bb4e-e3f3139e8c95/ff2f99d0-1ac2-4de7-bcd6-33a6f54f6ea3"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb6181a71-b422-4810-bb4e-e3f3139e8c95%2F600x600%2Fff2f99d0-1ac2-4de7-bcd6-33a6f54f6ea3_600x600?alt=media&token=e5cba0ad-881f-44f7-b813-6a198855190c",
        "reference": "stroke_photos/b6181a71-b422-4810-bb4e-e3f3139e8c95/600x600/ff2f99d0-1ac2-4de7-bcd6-33a6f54f6ea3_600x600"
      }
    },
    "paper": {
      "brand_model": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "price": null,
      "name": null,
      "original_size": "A7",
      "ref": "70025010030",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea"
    },
    "id": "b6181a71-b422-4810-bb4e-e3f3139e8c95",
    "order_id": 0,
    "order_number": 582
  },
  {
    "description": "",
    "name": "Blank",
    "id": "b62b28c1-d0ce-47e7-ae2f-f57eb989e61f",
    "order_id": 0,
    "paper": {
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "original_size": "75x105cm",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "price": 19.3,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "gsm": "640",
      "ref": "30075105",
      "name": "tinto"
    },
    "order_number": 991
  },
  {
    "order_number": 1162,
    "description": "",
    "name": "",
    "id": "b650ba19-b896-4c41-86d2-551af6b5ad17"
  },
  {
    "id": "b67613b9-4a50-4cb7-ad11-562f5ef74c13",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb67613b9-4a50-4cb7-ad11-562f5ef74c13%2F600x600%2F60412d3c-48fe-4366-9b07-7de55d03e4c2_600x600?alt=media&token=21050b09-b30a-4a67-abfc-3ea2607f952a",
        "reference": "stroke_photos/b67613b9-4a50-4cb7-ad11-562f5ef74c13/600x600/60412d3c-48fe-4366-9b07-7de55d03e4c2_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb67613b9-4a50-4cb7-ad11-562f5ef74c13%2F60412d3c-48fe-4366-9b07-7de55d03e4c2?alt=media&token=d8c09f66-ca9c-4d54-a308-afbfccfd8388",
        "reference": "/stroke_photos/b67613b9-4a50-4cb7-ad11-562f5ef74c13/60412d3c-48fe-4366-9b07-7de55d03e4c2"
      }
    },
    "order_id": 0,
    "order_number": 266,
    "paper": {
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "name": null,
      "ref": "20180107",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250",
      "price": null,
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      }
    },
    "tool": null,
    "name": "Wide Strokie",
    "description": "<p>Tiny mini, take my words back</p>"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1168,
    "id": "b684190f-508b-4e0a-871f-3dcd34490bf9"
  },
  {
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/b684b0b1-ebf1-430a-90ef-7a00901dade2/600x600/e5ecd0c3-5676-4d00-8dd4-f49f24f4c145_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb684b0b1-ebf1-430a-90ef-7a00901dade2%2F600x600%2Fe5ecd0c3-5676-4d00-8dd4-f49f24f4c145_600x600?alt=media&token=850ba676-bd96-43a7-bc63-f0d76ce6db98"
      },
      "original": {
        "reference": "/stroke_photos/b684b0b1-ebf1-430a-90ef-7a00901dade2/e5ecd0c3-5676-4d00-8dd4-f49f24f4c145",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb684b0b1-ebf1-430a-90ef-7a00901dade2%2Fe5ecd0c3-5676-4d00-8dd4-f49f24f4c145?alt=media&token=644fdfcd-3461-4cd5-afb4-9800bea2ad4d"
      }
    },
    "description": "Terrible results",
    "tool": null,
    "name": "Smooth Paper Fine Work",
    "order_number": 247,
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "ref": "20180107",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": null,
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {}
    },
    "id": "b684b0b1-ebf1-430a-90ef-7a00901dade2",
    "order_id": 0
  },
  {
    "order_number": 1021,
    "description": "",
    "name": "",
    "id": "b6dfa5e3-c94f-454b-9e5b-3320aede77d9"
  },
  {
    "order_number": 1167,
    "name": "",
    "description": "",
    "id": "b703361c-046c-4fc8-8ad9-133ca754de9a"
  },
  {
    "tool": null,
    "id": "b706091b-4bb6-4e99-8cf7-4c20bedd0350",
    "photos": {
      "original": {
        "reference": "/stroke_photos/b706091b-4bb6-4e99-8cf7-4c20bedd0350/f3511b89-c937-48b1-b71a-d6e8b4ab7c41",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb706091b-4bb6-4e99-8cf7-4c20bedd0350%2Ff3511b89-c937-48b1-b71a-d6e8b4ab7c41?alt=media&token=6434f9ab-5ef4-4371-bd98-a0f02ca10af5"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/b706091b-4bb6-4e99-8cf7-4c20bedd0350/600x600/f3511b89-c937-48b1-b71a-d6e8b4ab7c41_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb706091b-4bb6-4e99-8cf7-4c20bedd0350%2F600x600%2Ff3511b89-c937-48b1-b71a-d6e8b4ab7c41_600x600?alt=media&token=c751519e-7eee-4b10-aead-20888e4ac660"
      }
    },
    "order_id": 0,
    "name": "Artistic Fail",
    "paper": {
      "original_size": "A7",
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": null,
      "gsm": "250",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "ref": "20180107",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      }
    },
    "description": "",
    "order_number": 130
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb713c28a-7e2b-4641-8838-63f5bcc52402%2F600x600%2F02146936-05de-41ce-8f77-e49fad3028f6_600x600?alt=media&token=b6500026-47b2-41f8-9627-bf1698e218f8",
        "reference": "stroke_photos/b713c28a-7e2b-4641-8838-63f5bcc52402/600x600/02146936-05de-41ce-8f77-e49fad3028f6_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb713c28a-7e2b-4641-8838-63f5bcc52402%2F02146936-05de-41ce-8f77-e49fad3028f6?alt=media&token=d1efc4ef-a1ac-4ffa-bc33-916a9d1473f5",
        "reference": "/stroke_photos/b713c28a-7e2b-4641-8838-63f5bcc52402/02146936-05de-41ce-8f77-e49fad3028f6"
      }
    },
    "id": "b713c28a-7e2b-4641-8838-63f5bcc52402",
    "name": "Back To Normal Spin",
    "description": "",
    "order_number": 46,
    "tool": null,
    "order_id": 0,
    "paper": {
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "ref": "44202129",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "price": null,
      "paper_surface": {},
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "name": "",
      "original_size": "A4",
      "gsm": "200",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    }
  },
  {
    "tool": null,
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb753c132-a3e5-4b06-bc44-d9f710b524b8%2F600x600%2F20c64489-ac13-4d59-8a7a-e99a9753954c_600x600?alt=media&token=29e641cd-56c2-41bf-998c-0727a731b0c6",
        "reference": "stroke_photos/b753c132-a3e5-4b06-bc44-d9f710b524b8/600x600/20c64489-ac13-4d59-8a7a-e99a9753954c_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb753c132-a3e5-4b06-bc44-d9f710b524b8%2F20c64489-ac13-4d59-8a7a-e99a9753954c?alt=media&token=08cc4680-0e50-4ebb-9b6c-cfb430571f1a",
        "reference": "/stroke_photos/b753c132-a3e5-4b06-bc44-d9f710b524b8/20c64489-ac13-4d59-8a7a-e99a9753954c"
      }
    },
    "name": "Wavy White Less Paint",
    "order_number": 474,
    "id": "b753c132-a3e5-4b06-bc44-d9f710b524b8",
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand": {},
      "ref": null,
      "name": null,
      "price": null,
      "brand_model": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A4",
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "gsm": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "description": "<p>Just to see how it looks. I love the ending, even without good gradient. I think I like it with less water in the mixture</p>"
  },
  {
    "order_number": 1163,
    "name": "",
    "description": "",
    "id": "b76d79c1-1042-44f3-ab9f-9e6dccf8e1cd"
  },
  {
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "price": 1.35,
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "original_size": "A7",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "gsm": "250",
      "name": null,
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      }
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/b775c65a-9fba-4f42-87ce-ab27c515f1cf/6fe72c6e-2604-4181-8a6e-dff121ec490f",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb775c65a-9fba-4f42-87ce-ab27c515f1cf%2F6fe72c6e-2604-4181-8a6e-dff121ec490f?alt=media&token=5146eab7-414c-4563-8f8a-cfcc95d78767"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/b775c65a-9fba-4f42-87ce-ab27c515f1cf/600x600/6fe72c6e-2604-4181-8a6e-dff121ec490f_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb775c65a-9fba-4f42-87ce-ab27c515f1cf%2F600x600%2F6fe72c6e-2604-4181-8a6e-dff121ec490f_600x600?alt=media&token=9a273a0e-55c8-4a81-b63c-21be046a1186"
      }
    },
    "name": "Blank",
    "tool": null,
    "description": "",
    "id": "b775c65a-9fba-4f42-87ce-ab27c515f1cf",
    "order_number": 754,
    "order_id": 0
  },
  {
    "name": "",
    "description": "",
    "order_number": 1062,
    "id": "b7c917f6-d2c4-4bf8-bbef-7ca7d0a58b5d"
  },
  {
    "id": "b7daa2c5-d1bd-4542-9a65-389a2f71c674",
    "description": "",
    "order_id": 0,
    "paper": {
      "gsm": "250",
      "price": 0.4,
      "brand": {},
      "original_size": "A7",
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "ref": "20170703",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "name": "Blank",
    "order_number": 824
  },
  {
    "order_number": 245,
    "id": "b8035f69-424f-4bab-9b50-84e75298d7b3",
    "paper": {
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "ref": "77725080001",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "price": 1.35
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/b8035f69-424f-4bab-9b50-84e75298d7b3/bb962d51-4d2b-4280-8bdc-057b033fedb2",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb8035f69-424f-4bab-9b50-84e75298d7b3%2Fbb962d51-4d2b-4280-8bdc-057b033fedb2?alt=media&token=c4e60770-6bc6-43c3-93f9-e2cf1a625577"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/b8035f69-424f-4bab-9b50-84e75298d7b3/600x600/bb962d51-4d2b-4280-8bdc-057b033fedb2_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb8035f69-424f-4bab-9b50-84e75298d7b3%2F600x600%2Fbb962d51-4d2b-4280-8bdc-057b033fedb2_600x600?alt=media&token=ce8523ab-77a2-4b7c-b05e-90d8008482b7"
      }
    },
    "name": "Musically 9 - Spin 2",
    "order_id": 0,
    "description": "Still not what I expected. Second part is too flat, and it doesn't have good difference between narrow and wide side of the brush.",
    "tool": null
  },
  {
    "name": "Blank",
    "order_id": 0,
    "id": "b864c6a7-610e-4d29-8171-77a69e00389b",
    "paper": {
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      },
      "ref": "4005329065767",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": 1,
      "brand_model": {
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        }
      },
      "original_size": "A4",
      "gsm": "110",
      "name": null,
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "order_number": 863,
    "description": ""
  },
  {
    "order_number": 42,
    "id": "b8f1055a-4cd3-4ef4-8a8d-bb267aadb6be",
    "paper": {
      "original_size": "A4",
      "name": "",
      "gsm": "200",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "ref": "44202129",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_surface": {}
    },
    "name": "Pfsmpsitb Another One 2",
    "tool": null,
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb8f1055a-4cd3-4ef4-8a8d-bb267aadb6be%2F22277e05-4664-44a7-9112-e1f933de46cd?alt=media&token=4ff417b1-3b30-4c0d-8903-1a3b79bbb174",
        "reference": "/stroke_photos/b8f1055a-4cd3-4ef4-8a8d-bb267aadb6be/22277e05-4664-44a7-9112-e1f933de46cd"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb8f1055a-4cd3-4ef4-8a8d-bb267aadb6be%2F600x600%2F22277e05-4664-44a7-9112-e1f933de46cd_600x600?alt=media&token=820ef272-9e0a-4f78-8495-7bf7f8289b46",
        "reference": "stroke_photos/b8f1055a-4cd3-4ef4-8a8d-bb267aadb6be/600x600/22277e05-4664-44a7-9112-e1f933de46cd_600x600"
      }
    },
    "description": ""
  },
  {
    "name": "",
    "description": "",
    "order_number": 1227,
    "id": "b8f24771-fe84-45a9-81f2-13bced0619aa"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1016,
    "id": "b9161c08-ef23-4354-8824-1f687c81f640"
  },
  {
    "tool": null,
    "id": "b961787e-325b-4f0a-88f9-38bb4fb07e7c",
    "name": "Bamboo",
    "paper": {
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "name": null,
      "ref": "20180107",
      "original_size": "A7",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250",
      "price": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb961787e-325b-4f0a-88f9-38bb4fb07e7c%2F600x600%2F6d44771e-0366-49fd-9d58-e6dce38eda9c_600x600?alt=media&token=4503ef04-b866-496c-8f74-b045b0c3c875",
        "reference": "stroke_photos/b961787e-325b-4f0a-88f9-38bb4fb07e7c/600x600/6d44771e-0366-49fd-9d58-e6dce38eda9c_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fb961787e-325b-4f0a-88f9-38bb4fb07e7c%2F6d44771e-0366-49fd-9d58-e6dce38eda9c?alt=media&token=1e186ab6-5c52-42c7-9939-d157f66c9481",
        "reference": "/stroke_photos/b961787e-325b-4f0a-88f9-38bb4fb07e7c/6d44771e-0366-49fd-9d58-e6dce38eda9c"
      }
    },
    "order_number": 113,
    "description": ""
  },
  {
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/ba4ec6ca-4fe5-453b-b015-6f3d6eacb3c4/c6fde194-1864-4531-981b-e4fa4f2fc9f5",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fba4ec6ca-4fe5-453b-b015-6f3d6eacb3c4%2Fc6fde194-1864-4531-981b-e4fa4f2fc9f5?alt=media&token=b29bc102-72c7-4580-afd3-5e4f5fbc8f40"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/ba4ec6ca-4fe5-453b-b015-6f3d6eacb3c4/600x600/c6fde194-1864-4531-981b-e4fa4f2fc9f5_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fba4ec6ca-4fe5-453b-b015-6f3d6eacb3c4%2F600x600%2Fc6fde194-1864-4531-981b-e4fa4f2fc9f5_600x600?alt=media&token=f9eebbf7-6e0d-44d1-a4b0-40342a303f66"
      }
    },
    "paper": {
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A4",
      "price": null,
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "gsm": "200",
      "paper_surface": {},
      "name": "",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "44202129",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      }
    },
    "order_number": 89,
    "id": "ba4ec6ca-4fe5-453b-b015-6f3d6eacb3c4",
    "description": "",
    "tool": null,
    "name": "WOE HOT 4"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1056,
    "id": "ba812e0c-e099-46d0-af54-d976649e0b3c"
  },
  {
    "order_number": 1055,
    "description": "",
    "name": "",
    "id": "baa8825f-1547-4dcd-a991-2354f12b4e64"
  },
  {
    "paper": {
      "ref": "70025010030",
      "name": null,
      "original_size": "A7",
      "gsm": "250",
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {},
      "color": {
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbae109b5-027b-4bc9-b08e-38ec604763a5%2Fefd468ae-fe7b-48a8-99f0-18c8ac9e0189?alt=media&token=317c7afe-3ced-49d4-9f22-ea60289f3699",
        "reference": "/stroke_photos/bae109b5-027b-4bc9-b08e-38ec604763a5/efd468ae-fe7b-48a8-99f0-18c8ac9e0189"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbae109b5-027b-4bc9-b08e-38ec604763a5%2F600x600%2Fefd468ae-fe7b-48a8-99f0-18c8ac9e0189_600x600?alt=media&token=a0bb522b-8b80-4fed-b361-232d102cc115",
        "reference": "stroke_photos/bae109b5-027b-4bc9-b08e-38ec604763a5/600x600/efd468ae-fe7b-48a8-99f0-18c8ac9e0189_600x600"
      }
    },
    "tool": null,
    "name": "Blank",
    "description": "",
    "id": "bae109b5-027b-4bc9-b08e-38ec604763a5",
    "order_id": 0,
    "order_number": 602
  },
  {
    "order_number": 180,
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/baf41e85-f35f-43cd-adb7-cc70faa71bd2/600x600/6897ce06-feca-4d2c-bc1e-9e8922e9ca42_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbaf41e85-f35f-43cd-adb7-cc70faa71bd2%2F600x600%2F6897ce06-feca-4d2c-bc1e-9e8922e9ca42_600x600?alt=media&token=8af176e4-3d4a-408a-be09-6d0ed4127f68"
      },
      "original": {
        "reference": "/stroke_photos/baf41e85-f35f-43cd-adb7-cc70faa71bd2/6897ce06-feca-4d2c-bc1e-9e8922e9ca42",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbaf41e85-f35f-43cd-adb7-cc70faa71bd2%2F6897ce06-feca-4d2c-bc1e-9e8922e9ca42?alt=media&token=892a55b3-84da-4ef8-9da4-fa1b51b4fc8a"
      }
    },
    "name": "Blank",
    "id": "baf41e85-f35f-43cd-adb7-cc70faa71bd2",
    "tool": null,
    "paper": {
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "77725080001",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "gsm": "250",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso"
      },
      "price": 1.35,
      "original_size": "A7",
      "name": null,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    },
    "description": ""
  },
  {
    "name": "Press-N-Scratch",
    "order_id": 0,
    "id": "bb043d14-750c-40fc-8436-f6e864cc7419",
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/bb043d14-750c-40fc-8436-f6e864cc7419/310fb8f0-8b27-4255-a004-ac38be501587",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbb043d14-750c-40fc-8436-f6e864cc7419%2F310fb8f0-8b27-4255-a004-ac38be501587?alt=media&token=155a9dab-c448-4e7c-80e1-aba37bd10510"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/bb043d14-750c-40fc-8436-f6e864cc7419/600x600/310fb8f0-8b27-4255-a004-ac38be501587_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbb043d14-750c-40fc-8436-f6e864cc7419%2F600x600%2F310fb8f0-8b27-4255-a004-ac38be501587_600x600?alt=media&token=2c01ae5b-2c0b-4e39-ba9a-bcfede15041f"
      }
    },
    "description": "I expected quite a different result. Somehow more delicate...",
    "paper": {
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "77725080001",
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "price": 1.35,
      "original_size": "A7",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    },
    "order_number": 434
  },
  {
    "name": "Blank",
    "id": "bb12ae6e-7a7c-4413-b79d-a8f71f45ef0f",
    "tool": null,
    "paper": {
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "price": 19.3,
      "ref": "30075105",
      "name": "tinto",
      "gsm": "640",
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "original_size": "75x105cm",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      }
    },
    "order_number": 697,
    "order_id": 0,
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/bb12ae6e-7a7c-4413-b79d-a8f71f45ef0f/d913d5b9-7aa2-45f8-a771-f966e6afa693",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbb12ae6e-7a7c-4413-b79d-a8f71f45ef0f%2Fd913d5b9-7aa2-45f8-a771-f966e6afa693?alt=media&token=e6646bc5-9421-48cc-81df-f819d2c8e249"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbb12ae6e-7a7c-4413-b79d-a8f71f45ef0f%2F600x600%2Fd913d5b9-7aa2-45f8-a771-f966e6afa693_600x600?alt=media&token=d0a6a3ca-26b9-48c2-aeb6-742577a293f9",
        "reference": "stroke_photos/bb12ae6e-7a7c-4413-b79d-a8f71f45ef0f/600x600/d913d5b9-7aa2-45f8-a771-f966e6afa693_600x600"
      }
    }
  },
  {
    "id": "bb24f51f-031d-4c20-b7e0-398bd1e9c206",
    "order_number": 504,
    "name": "Splodge Beginning",
    "order_id": 0,
    "paper": {
      "brand_model": {},
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A4",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "gsm": null,
      "name": null,
      "price": null,
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      }
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/bb24f51f-031d-4c20-b7e0-398bd1e9c206/2c495e10-10f0-4f99-bdef-3d0da84d96aa",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbb24f51f-031d-4c20-b7e0-398bd1e9c206%2F2c495e10-10f0-4f99-bdef-3d0da84d96aa?alt=media&token=a19518f2-de4c-4b07-9916-56306d5665a4"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbb24f51f-031d-4c20-b7e0-398bd1e9c206%2F600x600%2F2c495e10-10f0-4f99-bdef-3d0da84d96aa_600x600?alt=media&token=64c8594b-8fc1-4d45-ab38-4034c3706a54",
        "reference": "stroke_photos/bb24f51f-031d-4c20-b7e0-398bd1e9c206/600x600/2c495e10-10f0-4f99-bdef-3d0da84d96aa_600x600"
      }
    },
    "description": "<p>We attampt again low brush angle (!) Interesting, the paint is very dry by now and we get a lump in the beginning</p>",
    "tool": null
  },
  {
    "order_id": 0,
    "order_number": 505,
    "photos": {
      "original": {
        "reference": "/stroke_photos/bb25c86e-ad95-41f6-af3d-df8e65014d0d/5f998c22-bc6c-4b3d-a431-3b3052ec2fe2",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbb25c86e-ad95-41f6-af3d-df8e65014d0d%2F5f998c22-bc6c-4b3d-a431-3b3052ec2fe2?alt=media&token=21e01cf4-9377-43da-a436-c02153bf6845"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/bb25c86e-ad95-41f6-af3d-df8e65014d0d/600x600/5f998c22-bc6c-4b3d-a431-3b3052ec2fe2_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbb25c86e-ad95-41f6-af3d-df8e65014d0d%2F600x600%2F5f998c22-bc6c-4b3d-a431-3b3052ec2fe2_600x600?alt=media&token=d680be07-bd7e-4ac8-9436-095c4307f6cd"
      }
    },
    "description": "<p>I will try another horizontal brush, being held at the very end (!) Okay, we have a broken body due to my hand shaking</p>",
    "tool": null,
    "id": "bb25c86e-ad95-41f6-af3d-df8e65014d0d",
    "name": "Broken White",
    "paper": {
      "brand_model": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A4",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": null,
      "ref": null,
      "gsm": null,
      "brand": {},
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      }
    }
  },
  {
    "description": "",
    "paper": {
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "ref": "30075105",
      "price": 19.3,
      "name": "tinto",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "original_size": "75x105cm",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "gsm": "640"
    },
    "order_id": 0,
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbc51fa85-c184-445b-9d8b-5559fdd116bb%2Fac1cfad7-e23c-4d6a-8d82-cc09aa5a6332?alt=media&token=af62b354-dfdd-4b9e-9fef-e9d46483e643",
        "reference": "/stroke_photos/bc51fa85-c184-445b-9d8b-5559fdd116bb/ac1cfad7-e23c-4d6a-8d82-cc09aa5a6332"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbc51fa85-c184-445b-9d8b-5559fdd116bb%2F600x600%2Fac1cfad7-e23c-4d6a-8d82-cc09aa5a6332_600x600?alt=media&token=1654b1a6-7e08-4f93-b9b6-50dfb5da7f6d",
        "reference": "stroke_photos/bc51fa85-c184-445b-9d8b-5559fdd116bb/600x600/ac1cfad7-e23c-4d6a-8d82-cc09aa5a6332_600x600"
      }
    },
    "name": "Blank",
    "id": "bc51fa85-c184-445b-9d8b-5559fdd116bb",
    "order_number": 681
  },
  {
    "id": "bc968249-efbd-463e-8a96-cbd0b2cdb183",
    "tool": null,
    "name": "Blank",
    "order_number": 716,
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbc968249-efbd-463e-8a96-cbd0b2cdb183%2Fb4defa76-f4ba-48d3-b82f-95d8657ef7a9?alt=media&token=8b08703b-aa81-4b26-a286-fb6c5d2cabe1",
        "reference": "/stroke_photos/bc968249-efbd-463e-8a96-cbd0b2cdb183/b4defa76-f4ba-48d3-b82f-95d8657ef7a9"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/bc968249-efbd-463e-8a96-cbd0b2cdb183/600x600/b4defa76-f4ba-48d3-b82f-95d8657ef7a9_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbc968249-efbd-463e-8a96-cbd0b2cdb183%2F600x600%2Fb4defa76-f4ba-48d3-b82f-95d8657ef7a9_600x600?alt=media&token=887ff54a-a1ba-4b2b-b06e-29941e10b659"
      }
    },
    "description": "",
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "20170703",
      "name": null,
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250",
      "price": 0.4,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "original_size": "A7"
    }
  },
  {
    "description": "",
    "order_number": 1006,
    "name": "",
    "id": "bcc75af5-8460-4aca-bd75-66ad1688699d"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1120,
    "id": "bcf5e669-1f8d-4625-801c-4ef5d03baa92"
  },
  {
    "description": "",
    "order_id": 0,
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "original_size": "A4",
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "ref": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {},
      "gsm": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "brand": {}
    },
    "name": "Blank",
    "order_number": 925,
    "id": "bd14ab61-f573-4ae3-bea4-316f0cd57ed8"
  },
  {
    "name": "Loads of White Paint",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbd26fdd7-8b69-45be-952a-2e611ac1e7ad%2F600x600%2F716c9ac0-7b8e-429e-9cb9-b292d4fb6fcb_600x600?alt=media&token=9bff3ff7-2f5e-4b04-82f7-90903978653a",
        "reference": "stroke_photos/bd26fdd7-8b69-45be-952a-2e611ac1e7ad/600x600/716c9ac0-7b8e-429e-9cb9-b292d4fb6fcb_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbd26fdd7-8b69-45be-952a-2e611ac1e7ad%2F716c9ac0-7b8e-429e-9cb9-b292d4fb6fcb?alt=media&token=5edba9e3-6ea4-4a3c-a9f7-6b2e8c510460",
        "reference": "/stroke_photos/bd26fdd7-8b69-45be-952a-2e611ac1e7ad/716c9ac0-7b8e-429e-9cb9-b292d4fb6fcb"
      }
    },
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "ref": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": null,
      "brand_model": {},
      "original_size": "A4",
      "name": null,
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "tool": null,
    "description": "<p>I will put a lot of paint and try to achieve the mosaic effect (!) Mosaic effect definitely didn't work. Will try again</p>",
    "order_id": 0,
    "order_number": 493,
    "id": "bd26fdd7-8b69-45be-952a-2e611ac1e7ad"
  },
  {
    "name": "",
    "order_number": 1213,
    "description": "",
    "id": "bd2b33b9-8982-4136-8ef6-2808c14705d5"
  },
  {
    "description": "<p>Attempted improvement over the curve, which clearly didn't work</p>",
    "order_id": 0,
    "name": "Vroooom Curvy",
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {},
      "gsm": "250",
      "name": null,
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "original_size": "A7",
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107"
    },
    "id": "bd69435b-7562-4ae4-bca5-e1cbdfc89aa9",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbd69435b-7562-4ae4-bca5-e1cbdfc89aa9%2F600x600%2Fbc45cd6f-e885-4c37-bc89-081295c47447_600x600?alt=media&token=4c863aa8-2126-479d-875d-477de45fcaec",
        "reference": "stroke_photos/bd69435b-7562-4ae4-bca5-e1cbdfc89aa9/600x600/bc45cd6f-e885-4c37-bc89-081295c47447_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbd69435b-7562-4ae4-bca5-e1cbdfc89aa9%2Fbc45cd6f-e885-4c37-bc89-081295c47447?alt=media&token=1603807e-6b9a-4c38-9690-40723405920f",
        "reference": "/stroke_photos/bd69435b-7562-4ae4-bca5-e1cbdfc89aa9/bc45cd6f-e885-4c37-bc89-081295c47447"
      }
    },
    "order_number": 284,
    "tool": null
  },
  {
    "tool": null,
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {},
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "gsm": "250",
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": null,
      "brand": {},
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "ref": "70025010030",
      "name": null
    },
    "id": "bd82121b-ac88-4b73-8648-204077c740f6",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/bd82121b-ac88-4b73-8648-204077c740f6/600x600/d33f55a6-af73-416f-9027-c4709796cf98_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbd82121b-ac88-4b73-8648-204077c740f6%2F600x600%2Fd33f55a6-af73-416f-9027-c4709796cf98_600x600?alt=media&token=b9c5935d-f8f0-4e3b-abb8-028b552cca79"
      },
      "original": {
        "reference": "/stroke_photos/bd82121b-ac88-4b73-8648-204077c740f6/d33f55a6-af73-416f-9027-c4709796cf98",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbd82121b-ac88-4b73-8648-204077c740f6%2Fd33f55a6-af73-416f-9027-c4709796cf98?alt=media&token=456c6e44-7681-4bc9-8b53-c9cfb10b9180"
      }
    },
    "order_number": 628,
    "order_id": 0,
    "description": "",
    "name": "Blank"
  },
  {
    "order_id": 0,
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "original_size": "A7",
      "price": 0.4,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "gsm": "250",
      "brand": {},
      "ref": "20170703",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      }
    },
    "order_number": 807,
    "name": "Blank",
    "id": "bd888962-53cc-430f-80d8-030042458043",
    "description": ""
  },
  {
    "order_id": 0,
    "paper": {
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "price": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand_model": {},
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "original_size": "A4",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "ref": null,
      "gsm": null
    },
    "description": "<p>I will try to bring back the mosaics (!) I just got some clouds</p>",
    "id": "bea6f918-da82-4dbb-8b7b-eaebaf4dceae",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbea6f918-da82-4dbb-8b7b-eaebaf4dceae%2F600x600%2F84664149-183a-4ee3-b1a7-41a6eb192676_600x600?alt=media&token=8ce07417-209b-4b4e-a539-a8a98e63e942",
        "reference": "stroke_photos/bea6f918-da82-4dbb-8b7b-eaebaf4dceae/600x600/84664149-183a-4ee3-b1a7-41a6eb192676_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbea6f918-da82-4dbb-8b7b-eaebaf4dceae%2F84664149-183a-4ee3-b1a7-41a6eb192676?alt=media&token=f6bad3cc-19a7-4ce0-8105-43e2d197e317",
        "reference": "/stroke_photos/bea6f918-da82-4dbb-8b7b-eaebaf4dceae/84664149-183a-4ee3-b1a7-41a6eb192676"
      }
    },
    "order_number": 500,
    "tool": null,
    "name": "White Zig-Zag Plain"
  },
  {
    "name": "",
    "order_number": 1060,
    "description": "",
    "id": "bea93520-de72-4ad7-b482-ccb88a1bc0ca"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1110,
    "id": "bec980fa-1267-4e6a-aa7a-c4794590ab57"
  },
  {
    "order_number": 655,
    "name": "Blank",
    "id": "bedfaebe-b579-4aad-bdf7-9f81fa1934ec",
    "tool": null,
    "description": "",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbedfaebe-b579-4aad-bdf7-9f81fa1934ec%2F9a174746-773c-40a7-8a2c-93bc2ec3b31e?alt=media&token=c0c746d0-3396-4ebd-b560-d9227e8654de",
        "reference": "/stroke_photos/bedfaebe-b579-4aad-bdf7-9f81fa1934ec/9a174746-773c-40a7-8a2c-93bc2ec3b31e"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/bedfaebe-b579-4aad-bdf7-9f81fa1934ec/600x600/9a174746-773c-40a7-8a2c-93bc2ec3b31e_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbedfaebe-b579-4aad-bdf7-9f81fa1934ec%2F600x600%2F9a174746-773c-40a7-8a2c-93bc2ec3b31e_600x600?alt=media&token=f4b1e87c-ffbc-4658-a49d-58b3beb2cee3"
      }
    },
    "order_id": 0,
    "paper": {
      "ref": "30075105",
      "original_size": "75x105cm",
      "price": 19.3,
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "gsm": "640",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": "tinto",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "brand_model": {
        "name": "Artistico",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      }
    }
  },
  {
    "name": "Roundy White",
    "id": "bee88e58-f454-4a85-b91b-2df0cd654e96",
    "order_id": 0,
    "tool": null,
    "description": "<p>I guess a bit too round, but not bad...Interesting defect there</p>",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/bee88e58-f454-4a85-b91b-2df0cd654e96/600x600/b0cdd57f-ed63-4ce2-931b-6f3ad9396e35_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbee88e58-f454-4a85-b91b-2df0cd654e96%2F600x600%2Fb0cdd57f-ed63-4ce2-931b-6f3ad9396e35_600x600?alt=media&token=bbcb41cf-a2c6-4fbc-8a00-beb41d59ad9d"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbee88e58-f454-4a85-b91b-2df0cd654e96%2Fb0cdd57f-ed63-4ce2-931b-6f3ad9396e35?alt=media&token=d1c49083-736c-4e05-ad78-4a147c570568",
        "reference": "/stroke_photos/bee88e58-f454-4a85-b91b-2df0cd654e96/b0cdd57f-ed63-4ce2-931b-6f3ad9396e35"
      }
    },
    "order_number": 469,
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4",
      "brand_model": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "gsm": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": null,
      "brand": {},
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      }
    }
  },
  {
    "id": "bf2c89a8-f84c-40cf-9389-bc66d7805356",
    "name": "Blank",
    "tool": null,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/bf2c89a8-f84c-40cf-9389-bc66d7805356/600x600/9f406a8a-6ede-4bba-80c0-acba20fdbf53_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbf2c89a8-f84c-40cf-9389-bc66d7805356%2F600x600%2F9f406a8a-6ede-4bba-80c0-acba20fdbf53_600x600?alt=media&token=d75155eb-aed4-44e1-bba6-1b52282d4afd"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbf2c89a8-f84c-40cf-9389-bc66d7805356%2F9f406a8a-6ede-4bba-80c0-acba20fdbf53?alt=media&token=04fcbee2-78ba-4324-b40c-10a46f9d9e5b",
        "reference": "/stroke_photos/bf2c89a8-f84c-40cf-9389-bc66d7805356/9f406a8a-6ede-4bba-80c0-acba20fdbf53"
      }
    },
    "order_number": 575,
    "paper": {
      "original_size": "A7",
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "ref": "20170703",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand": {},
      "price": 0.4,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    },
    "order_id": 0
  },
  {
    "order_number": 1187,
    "name": "",
    "description": "",
    "id": "bf4abf36-fc13-4f9d-be5b-44086fef486d"
  },
  {
    "order_number": 1217,
    "description": "",
    "name": "",
    "id": "bfe623ac-8a05-4663-ade6-49fcc6c28069"
  },
  {
    "paper": {
      "gsm": "250",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "original_size": "A7",
      "brand": {},
      "price": 0.4,
      "ref": "20170703",
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "id": "bfe98714-3eb2-490a-845d-a0538475b313",
    "name": "Blank",
    "tool": null,
    "description": "",
    "order_number": 719,
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbfe98714-3eb2-490a-845d-a0538475b313%2F240bbbb9-6674-4e0f-9633-91d715e584ba?alt=media&token=05350ce9-fa03-4b6e-b15f-d9ca45640548",
        "reference": "/stroke_photos/bfe98714-3eb2-490a-845d-a0538475b313/240bbbb9-6674-4e0f-9633-91d715e584ba"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/bfe98714-3eb2-490a-845d-a0538475b313/600x600/240bbbb9-6674-4e0f-9633-91d715e584ba_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fbfe98714-3eb2-490a-845d-a0538475b313%2F600x600%2F240bbbb9-6674-4e0f-9633-91d715e584ba_600x600?alt=media&token=bcc15eac-7a51-4c43-9c3a-cf9423548552"
      }
    }
  },
  {
    "order_number": 1165,
    "name": "",
    "description": "",
    "id": "c022244d-9c74-4bb5-9219-fd8995054b50"
  },
  {
    "order_number": 1186,
    "name": "",
    "description": "",
    "id": "c03b7375-9cd2-478f-865e-253e25b314bd"
  },
  {
    "id": "c0da44dd-6ed4-4c0c-9201-eee19930c0cd",
    "name": "BS Even Less Paint",
    "tool": null,
    "paper": {
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": "44202129",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {},
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "price": null,
      "name": "",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "gsm": "200",
      "original_size": "A4"
    },
    "order_id": 0,
    "order_number": 37,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc0da44dd-6ed4-4c0c-9201-eee19930c0cd%2F600x600%2F4a68fae3-9f45-432d-a896-a86647ba2f4c_600x600?alt=media&token=b1e42783-bdb3-4112-b1a4-a30b475ffd45",
        "reference": "stroke_photos/c0da44dd-6ed4-4c0c-9201-eee19930c0cd/600x600/4a68fae3-9f45-432d-a896-a86647ba2f4c_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc0da44dd-6ed4-4c0c-9201-eee19930c0cd%2F4a68fae3-9f45-432d-a896-a86647ba2f4c?alt=media&token=033b5e54-1563-40b2-a638-cf7019bd60fc",
        "reference": "/stroke_photos/c0da44dd-6ed4-4c0c-9201-eee19930c0cd/4a68fae3-9f45-432d-a896-a86647ba2f4c"
      }
    },
    "description": ""
  },
  {
    "description": "",
    "order_id": 0,
    "name": "Blank",
    "paper": {
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "gsm": null,
      "ref": null,
      "original_size": "A4",
      "brand_model": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "id": "c10c8c90-7a32-47ab-93b0-632da55ab069",
    "order_number": 975
  },
  {
    "order_number": 1044,
    "description": "",
    "name": "",
    "id": "c118dd4a-db59-4fce-b888-6b4bc4b5c54b"
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc12466eb-4755-486d-82c7-b6b16899ee8c%2F600x600%2F60ee36cc-b7dd-4e8c-b734-6ae6fd1bd282_600x600?alt=media&token=17880b63-2906-45cc-8bd4-5627bba34bb3",
        "reference": "stroke_photos/c12466eb-4755-486d-82c7-b6b16899ee8c/600x600/60ee36cc-b7dd-4e8c-b734-6ae6fd1bd282_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc12466eb-4755-486d-82c7-b6b16899ee8c%2F60ee36cc-b7dd-4e8c-b734-6ae6fd1bd282?alt=media&token=6aaf1b35-ba61-4ed6-9251-8c311eba3b9f",
        "reference": "/stroke_photos/c12466eb-4755-486d-82c7-b6b16899ee8c/60ee36cc-b7dd-4e8c-b734-6ae6fd1bd282"
      }
    },
    "tool": null,
    "order_id": 0,
    "id": "c12466eb-4755-486d-82c7-b6b16899ee8c",
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "ref": null,
      "price": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {},
      "brand": {},
      "original_size": "A4",
      "gsm": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      }
    },
    "name": "Mosaics White Improvement",
    "order_number": 495,
    "description": "<p>I will try to make a better arc and aim for the mosaics effect by having a lot of paint and just barely touching paper</p>"
  },
  {
    "order_id": 0,
    "order_number": 869,
    "name": "Blank",
    "paper": {
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "110",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "name": null,
      "ref": "4005329065767",
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "original_size": "A4",
      "brand_model": {
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2"
      },
      "price": 1
    },
    "description": "",
    "id": "c127ddaa-9a29-4bd7-b589-7cdc7a865019"
  },
  {
    "description": "",
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "original_size": "A7",
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "gsm": "250",
      "ref": "20180107",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "brand": {},
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86"
    },
    "order_id": 0,
    "order_number": 102,
    "id": "c14b1d22-4e36-4d55-9be7-0467f3fa0192",
    "tool": null,
    "name": "Variable Pressure Light",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/c14b1d22-4e36-4d55-9be7-0467f3fa0192/600x600/b3c03eca-3577-4389-88a6-d8adf67911bd_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc14b1d22-4e36-4d55-9be7-0467f3fa0192%2F600x600%2Fb3c03eca-3577-4389-88a6-d8adf67911bd_600x600?alt=media&token=1248f8e0-611f-4621-ab2c-654390db91f8"
      },
      "original": {
        "reference": "/stroke_photos/c14b1d22-4e36-4d55-9be7-0467f3fa0192/b3c03eca-3577-4389-88a6-d8adf67911bd",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc14b1d22-4e36-4d55-9be7-0467f3fa0192%2Fb3c03eca-3577-4389-88a6-d8adf67911bd?alt=media&token=4e11543b-6899-4b33-a316-072034c697d5"
      }
    }
  },
  {
    "order_number": 1116,
    "name": "",
    "description": "",
    "id": "c1695731-f0f3-4967-8ba2-ad9a85255885"
  },
  {
    "order_number": 1158,
    "name": "",
    "description": "",
    "id": "c174c10b-4c4c-48f9-820b-ed8015000026"
  },
  {
    "description": "",
    "id": "c1d262aa-5fc7-4deb-85b5-75a3383caa97",
    "order_number": 972,
    "name": "Blank",
    "paper": {
      "original_size": "A4",
      "price": null,
      "name": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {},
      "ref": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "order_id": 0
  },
  {
    "id": "c1f05ac7-7147-4378-a5a6-5032aaa24bb7",
    "name": "Double Trouble Shorty",
    "order_id": 0,
    "order_number": 385,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc1f05ac7-7147-4378-a5a6-5032aaa24bb7%2F600x600%2Fb229d329-3677-4e43-978b-8a5f3d03e30a_600x600?alt=media&token=6a5cbbee-6058-4aa1-958e-c4852494692d",
        "reference": "stroke_photos/c1f05ac7-7147-4378-a5a6-5032aaa24bb7/600x600/b229d329-3677-4e43-978b-8a5f3d03e30a_600x600"
      },
      "original": {
        "reference": "/stroke_photos/c1f05ac7-7147-4378-a5a6-5032aaa24bb7/b229d329-3677-4e43-978b-8a5f3d03e30a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc1f05ac7-7147-4378-a5a6-5032aaa24bb7%2Fb229d329-3677-4e43-978b-8a5f3d03e30a?alt=media&token=c867edd2-2b71-46a6-9bcb-8520d5f24b11"
      }
    },
    "description": "<p>Statement</p>",
    "tool": null,
    "paper": {
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": null,
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "brand": {}
    }
  },
  {
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc26c128a-5f11-44df-b47f-f1799a1e40de%2F56be9913-e707-4538-aafc-7bb733f3cd6d?alt=media&token=404d1820-6c8c-4f60-8b29-f44e16e9dbf8",
        "reference": "/stroke_photos/c26c128a-5f11-44df-b47f-f1799a1e40de/56be9913-e707-4538-aafc-7bb733f3cd6d"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc26c128a-5f11-44df-b47f-f1799a1e40de%2F600x600%2F56be9913-e707-4538-aafc-7bb733f3cd6d_600x600?alt=media&token=721d6836-3e3c-416c-b769-c6d1bdd8c1d6",
        "reference": "stroke_photos/c26c128a-5f11-44df-b47f-f1799a1e40de/600x600/56be9913-e707-4538-aafc-7bb733f3cd6d_600x600"
      }
    },
    "description": "",
    "id": "c26c128a-5f11-44df-b47f-f1799a1e40de",
    "paper": {
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "name": null,
      "brand": {},
      "price": null,
      "brand_model": {},
      "original_size": "A7",
      "ref": "70025010030",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      }
    },
    "name": "Blank",
    "order_number": 630,
    "order_id": 0
  },
  {
    "order_number": 670,
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/c2d9b30f-e30e-4125-b7f0-66fcb9b3f516/600x600/15212929-b5d2-47a6-938e-42eb112906ff_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc2d9b30f-e30e-4125-b7f0-66fcb9b3f516%2F600x600%2F15212929-b5d2-47a6-938e-42eb112906ff_600x600?alt=media&token=48d330a6-f497-4a9d-8be2-5a7cb714b269"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc2d9b30f-e30e-4125-b7f0-66fcb9b3f516%2F15212929-b5d2-47a6-938e-42eb112906ff?alt=media&token=8e1282be-9307-45c1-a7e0-b7f6dc04c8e3",
        "reference": "/stroke_photos/c2d9b30f-e30e-4125-b7f0-66fcb9b3f516/15212929-b5d2-47a6-938e-42eb112906ff"
      }
    },
    "description": "",
    "id": "c2d9b30f-e30e-4125-b7f0-66fcb9b3f516",
    "order_id": 0,
    "tool": null,
    "paper": {
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "brand_model": {
        "name": "Artistico",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "price": 19.3,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "ref": "30075105",
      "original_size": "75x105cm",
      "gsm": "640",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "name": "tinto"
    }
  },
  {
    "description": "Maybe I am primed by Richard",
    "order_number": 419,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/c32f00bc-f6d0-4cea-b750-129f6ad8abf5/600x600/7383752e-2e34-4817-8983-788efa56a5ce_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc32f00bc-f6d0-4cea-b750-129f6ad8abf5%2F600x600%2F7383752e-2e34-4817-8983-788efa56a5ce_600x600?alt=media&token=f25dbac2-9f86-40f1-b871-1cf5d6bb9697"
      },
      "original": {
        "reference": "/stroke_photos/c32f00bc-f6d0-4cea-b750-129f6ad8abf5/7383752e-2e34-4817-8983-788efa56a5ce",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc32f00bc-f6d0-4cea-b750-129f6ad8abf5%2F7383752e-2e34-4817-8983-788efa56a5ce?alt=media&token=539ae868-00a5-4916-9e7d-a467136e65a8"
      }
    },
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "original_size": "A7",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "ref": "77725080001",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "price": 1.35,
      "name": null
    },
    "name": "Inversed Asphalt Line",
    "tool": null,
    "order_id": 0,
    "id": "c32f00bc-f6d0-4cea-b750-129f6ad8abf5"
  },
  {
    "tool": null,
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "ref": "20180107",
      "price": null,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7"
    },
    "description": "<p>Cool Luck</p>",
    "order_id": 0,
    "name": "Hungry Father",
    "id": "c36a41c0-7944-43d7-85ee-4aeb095d9765",
    "order_number": 315,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc36a41c0-7944-43d7-85ee-4aeb095d9765%2F10ad2c10-8785-4946-88b7-e6782ba4af47?alt=media&token=27c2409f-7143-424a-a80c-3c1efc80ff96",
        "reference": "/stroke_photos/c36a41c0-7944-43d7-85ee-4aeb095d9765/10ad2c10-8785-4946-88b7-e6782ba4af47"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/c36a41c0-7944-43d7-85ee-4aeb095d9765/600x600/10ad2c10-8785-4946-88b7-e6782ba4af47_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc36a41c0-7944-43d7-85ee-4aeb095d9765%2F600x600%2F10ad2c10-8785-4946-88b7-e6782ba4af47_600x600?alt=media&token=4217c719-1105-40ba-bdce-fb97d775a972"
      }
    }
  },
  {
    "paper": {
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "ref": "20170703",
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": 0.4,
      "gsm": "250"
    },
    "order_number": 566,
    "order_id": 0,
    "description": "",
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc36f6e03-ffb4-41fd-be02-530ff6bf9a79%2F600x600%2Fac52a92a-48fb-4a8b-9c10-e217724ecbfe_600x600?alt=media&token=9b990a2b-1505-4371-84ca-0774917bc81b",
        "reference": "stroke_photos/c36f6e03-ffb4-41fd-be02-530ff6bf9a79/600x600/ac52a92a-48fb-4a8b-9c10-e217724ecbfe_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc36f6e03-ffb4-41fd-be02-530ff6bf9a79%2Fac52a92a-48fb-4a8b-9c10-e217724ecbfe?alt=media&token=d2dc8b2a-a587-4338-aad3-b0af472379bb",
        "reference": "/stroke_photos/c36f6e03-ffb4-41fd-be02-530ff6bf9a79/ac52a92a-48fb-4a8b-9c10-e217724ecbfe"
      }
    },
    "id": "c36f6e03-ffb4-41fd-be02-530ff6bf9a79",
    "tool": null
  },
  {
    "order_id": 0,
    "paper": {
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "gsm": "250",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "name": null,
      "original_size": "A7",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "brand": {},
      "ref": "20180107",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "name": "New Kid On The Block",
    "order_number": 256,
    "tool": null,
    "description": "I don't think I can repeat that, not sure how I got it. Maybe I will set.a session for this effect only.",
    "id": "c389e346-030e-42dd-8240-3598715b21f8",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc389e346-030e-42dd-8240-3598715b21f8%2F600x600%2F8e2850cd-3785-4211-8df6-0725319a34db_600x600?alt=media&token=b6d60fa5-7f3e-4ea0-8e56-9137f5364cb4",
        "reference": "stroke_photos/c389e346-030e-42dd-8240-3598715b21f8/600x600/8e2850cd-3785-4211-8df6-0725319a34db_600x600"
      },
      "original": {
        "reference": "/stroke_photos/c389e346-030e-42dd-8240-3598715b21f8/8e2850cd-3785-4211-8df6-0725319a34db",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc389e346-030e-42dd-8240-3598715b21f8%2F8e2850cd-3785-4211-8df6-0725319a34db?alt=media&token=4ed61fae-6fd2-4509-a584-8e371c6a178c"
      }
    }
  },
  {
    "paper": {
      "original_size": "75x105cm",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "name": "tinto",
      "price": 19.3,
      "ref": "30075105",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "gsm": "640",
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      }
    },
    "order_id": 0,
    "id": "c3a8f996-eaa2-4840-b191-7037fefe0d02",
    "name": "Blank",
    "order_number": 976,
    "description": ""
  },
  {
    "tool": null,
    "description": "",
    "id": "c3c0e640-6afe-447e-9641-fc232de35e4a",
    "name": "Take a Break From Bounciness",
    "order_number": 136,
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": null,
      "original_size": "A7",
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      }
    },
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc3c0e640-6afe-447e-9641-fc232de35e4a%2F600x600%2F15e0903f-7068-4238-8135-1bf99a433dcf_600x600?alt=media&token=9db9725a-5bea-49b1-8ad2-2c2ac0b02b14",
        "reference": "stroke_photos/c3c0e640-6afe-447e-9641-fc232de35e4a/600x600/15e0903f-7068-4238-8135-1bf99a433dcf_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc3c0e640-6afe-447e-9641-fc232de35e4a%2F15e0903f-7068-4238-8135-1bf99a433dcf?alt=media&token=597bc0d0-2322-4549-b9a6-59714c4832d9",
        "reference": "/stroke_photos/c3c0e640-6afe-447e-9641-fc232de35e4a/15e0903f-7068-4238-8135-1bf99a433dcf"
      }
    }
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc3c9fe74-6b1b-494d-8cb9-1408060726d1%2F600x600%2F01a0f4f1-2d75-456e-8107-cf464a222c13_600x600?alt=media&token=a4e85feb-88eb-498f-9764-77c198caf465",
        "reference": "stroke_photos/c3c9fe74-6b1b-494d-8cb9-1408060726d1/600x600/01a0f4f1-2d75-456e-8107-cf464a222c13_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc3c9fe74-6b1b-494d-8cb9-1408060726d1%2F01a0f4f1-2d75-456e-8107-cf464a222c13?alt=media&token=046f1c24-8546-4dd6-afb1-46347df8960a",
        "reference": "/stroke_photos/c3c9fe74-6b1b-494d-8cb9-1408060726d1/01a0f4f1-2d75-456e-8107-cf464a222c13"
      }
    },
    "description": "<p>I attempte to do a very wide stroke that starts before the paper and ends after the paper. Barely made it i</p>",
    "order_number": 264,
    "order_id": 0,
    "id": "c3c9fe74-6b1b-494d-8cb9-1408060726d1",
    "tool": null,
    "name": "Wide Stroke",
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "name": null,
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "price": null,
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    }
  },
  {
    "paper": {
      "gsm": null,
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A4",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "name": null,
      "ref": null,
      "brand_model": {},
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand": {}
    },
    "name": "Blank",
    "order_number": 943,
    "id": "c3f5b210-fb7a-4e8b-b1d9-89e39e4379f8",
    "order_id": 0,
    "description": ""
  },
  {
    "description": "",
    "paper": {
      "ref": "77725080001",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "gsm": "250",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "original_size": "A7",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      }
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc417056a-2813-489a-b8d9-e7259ccf217d%2Ff288e8d0-ba2e-4600-b808-84731a54f315?alt=media&token=6fee3209-d794-42da-8e3a-2867ee2e3dba",
        "reference": "/stroke_photos/c417056a-2813-489a-b8d9-e7259ccf217d/f288e8d0-ba2e-4600-b808-84731a54f315"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/c417056a-2813-489a-b8d9-e7259ccf217d/600x600/f288e8d0-ba2e-4600-b808-84731a54f315_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc417056a-2813-489a-b8d9-e7259ccf217d%2F600x600%2Ff288e8d0-ba2e-4600-b808-84731a54f315_600x600?alt=media&token=b80983fe-e1b6-401f-8206-b0e8636a5d06"
      }
    },
    "tool": null,
    "id": "c417056a-2813-489a-b8d9-e7259ccf217d",
    "order_id": 0,
    "name": "Blank",
    "order_number": 150
  },
  {
    "name": "",
    "description": "",
    "order_number": 1077,
    "id": "c4202476-9819-4a7f-b61b-d33e54254feb"
  },
  {
    "order_id": 0,
    "description": "",
    "id": "c48bebc2-f8dd-423c-9506-b7ad60f7a8f0",
    "name": "Blank",
    "order_number": 933,
    "paper": {
      "brand_model": {},
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "original_size": "A4",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null,
      "gsm": null
    }
  },
  {
    "name": "Just Another Joe",
    "paper": {
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "ref": "20180107",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {},
      "original_size": "A7",
      "name": null
    },
    "description": "",
    "order_number": 144,
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/c4bdb1c9-b75c-40c0-8ff3-9bb7a6d2641b/600x600/5f56d37f-fc5b-40d1-bfa9-deaa29964f42_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc4bdb1c9-b75c-40c0-8ff3-9bb7a6d2641b%2F600x600%2F5f56d37f-fc5b-40d1-bfa9-deaa29964f42_600x600?alt=media&token=1a5e3908-eec0-4c13-a11f-8ed90905dcaf"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc4bdb1c9-b75c-40c0-8ff3-9bb7a6d2641b%2F5f56d37f-fc5b-40d1-bfa9-deaa29964f42?alt=media&token=45dbd6e7-37d5-4d07-9faf-02eac1dd956f",
        "reference": "/stroke_photos/c4bdb1c9-b75c-40c0-8ff3-9bb7a6d2641b/5f56d37f-fc5b-40d1-bfa9-deaa29964f42"
      }
    },
    "tool": null,
    "id": "c4bdb1c9-b75c-40c0-8ff3-9bb7a6d2641b"
  },
  {
    "order_id": 0,
    "id": "c4e86ff3-8a71-42b8-b883-584daf32a92b",
    "paper": {
      "price": null,
      "gsm": null,
      "brand": {},
      "original_size": "A4",
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "ref": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "description": "",
    "order_number": 941,
    "name": "Blank"
  },
  {
    "tool": null,
    "order_number": 49,
    "order_id": 0,
    "description": "",
    "paper": {
      "paper_surface": {},
      "gsm": "200",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "name": "",
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A4",
      "ref": "44202129",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      }
    },
    "name": "Paint Only On The Tip",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc513c335-d455-41df-8169-9668ca2205e5%2Fe618e688-928f-4240-aaf8-e8a2a234282b?alt=media&token=7e07475f-f2a5-49cc-b0f1-c7804584c9ca",
        "reference": "/stroke_photos/c513c335-d455-41df-8169-9668ca2205e5/e618e688-928f-4240-aaf8-e8a2a234282b"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc513c335-d455-41df-8169-9668ca2205e5%2F600x600%2Fe618e688-928f-4240-aaf8-e8a2a234282b_600x600?alt=media&token=acadee47-3abc-4720-8921-b9ca9df9d049",
        "reference": "stroke_photos/c513c335-d455-41df-8169-9668ca2205e5/600x600/e618e688-928f-4240-aaf8-e8a2a234282b_600x600"
      }
    },
    "id": "c513c335-d455-41df-8169-9668ca2205e5"
  },
  {
    "paper": {
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": 0.4,
      "brand": {},
      "ref": "20170703",
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      }
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/c544cc19-7192-4daa-a8d9-b4fd87d302bc/600x600/58f9c433-756f-4aca-aa1a-2884f89f6de4_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc544cc19-7192-4daa-a8d9-b4fd87d302bc%2F600x600%2F58f9c433-756f-4aca-aa1a-2884f89f6de4_600x600?alt=media&token=f288c6a2-f28d-47d7-8f1f-287d265e70c4"
      },
      "original": {
        "reference": "/stroke_photos/c544cc19-7192-4daa-a8d9-b4fd87d302bc/58f9c433-756f-4aca-aa1a-2884f89f6de4",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc544cc19-7192-4daa-a8d9-b4fd87d302bc%2F58f9c433-756f-4aca-aa1a-2884f89f6de4?alt=media&token=0238a2ae-236c-4de9-a464-e643251ada8b"
      }
    },
    "id": "c544cc19-7192-4daa-a8d9-b4fd87d302bc",
    "order_number": 705,
    "name": "Blank",
    "description": "",
    "order_id": 0,
    "tool": null
  },
  {
    "description": "",
    "order_number": 1185,
    "name": "",
    "id": "c54e4948-0660-46b5-9fba-15247a8297fd"
  },
  {
    "tool": null,
    "name": "Better Diagonal White",
    "photos": {
      "original": {
        "reference": "/stroke_photos/c59a0e21-77cd-49fd-9deb-380004551d11/19607d03-6cd8-4328-b701-820c8b80cefd",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc59a0e21-77cd-49fd-9deb-380004551d11%2F19607d03-6cd8-4328-b701-820c8b80cefd?alt=media&token=9003f443-f17f-4a63-84b0-7f6f3facfd82"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/c59a0e21-77cd-49fd-9deb-380004551d11/600x600/19607d03-6cd8-4328-b701-820c8b80cefd_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc59a0e21-77cd-49fd-9deb-380004551d11%2F600x600%2F19607d03-6cd8-4328-b701-820c8b80cefd_600x600?alt=media&token=68706776-005b-4b5b-9e5b-a8cb6744d267"
      }
    },
    "description": "<p>Too curvy this time. One more</p>",
    "order_number": 456,
    "order_id": 0,
    "id": "c59a0e21-77cd-49fd-9deb-380004551d11",
    "paper": {
      "original_size": "A4",
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {},
      "ref": null,
      "gsm": null,
      "price": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null
    }
  },
  {
    "order_number": 208,
    "name": "Mucho Mucho",
    "id": "c5bfae07-b3aa-44f4-860b-94cba9f00cb6",
    "description": "",
    "paper": {
      "original_size": "A7",
      "gsm": "250",
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "name": null,
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "price": 1.35
    },
    "tool": null,
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/c5bfae07-b3aa-44f4-860b-94cba9f00cb6/600x600/92ba2236-f783-4ddc-a018-696afc4a9127_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc5bfae07-b3aa-44f4-860b-94cba9f00cb6%2F600x600%2F92ba2236-f783-4ddc-a018-696afc4a9127_600x600?alt=media&token=c8ea6493-3913-48ac-bcb9-74cfdececcf3"
      },
      "original": {
        "reference": "/stroke_photos/c5bfae07-b3aa-44f4-860b-94cba9f00cb6/92ba2236-f783-4ddc-a018-696afc4a9127",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc5bfae07-b3aa-44f4-860b-94cba9f00cb6%2F92ba2236-f783-4ddc-a018-696afc4a9127?alt=media&token=fd285558-6b4d-4fe0-863c-4b38ef2484d7"
      }
    }
  },
  {
    "name": "Blank",
    "tool": null,
    "order_id": 0,
    "order_number": 634,
    "photos": {
      "original": {
        "reference": "/stroke_photos/c5c3c24c-8a35-409c-8b45-ead73c8c855c/5736903f-b307-4ac7-a974-5894384b4759",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc5c3c24c-8a35-409c-8b45-ead73c8c855c%2F5736903f-b307-4ac7-a974-5894384b4759?alt=media&token=2843f7bd-3f9c-4607-82ee-a2a00a80abe1"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/c5c3c24c-8a35-409c-8b45-ead73c8c855c/600x600/5736903f-b307-4ac7-a974-5894384b4759_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc5c3c24c-8a35-409c-8b45-ead73c8c855c%2F600x600%2F5736903f-b307-4ac7-a974-5894384b4759_600x600?alt=media&token=ff21a986-003c-4628-a154-0363534def7c"
      }
    },
    "paper": {
      "original_size": "A7",
      "brand_model": {},
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "name": null,
      "price": null,
      "ref": "70025010030",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "id": "c5c3c24c-8a35-409c-8b45-ead73c8c855c",
    "description": ""
  },
  {
    "description": "",
    "id": "c5fb4b2c-47b4-4c0e-a180-f11aa98fd477",
    "photos": {
      "original": {
        "reference": "/stroke_photos/c5fb4b2c-47b4-4c0e-a180-f11aa98fd477/cb7ab3d3-4438-442c-8ad9-68df35a1a520",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc5fb4b2c-47b4-4c0e-a180-f11aa98fd477%2Fcb7ab3d3-4438-442c-8ad9-68df35a1a520?alt=media&token=44ac6f72-a3bd-4901-9cf8-03e1f38fd20d"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/c5fb4b2c-47b4-4c0e-a180-f11aa98fd477/600x600/cb7ab3d3-4438-442c-8ad9-68df35a1a520_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc5fb4b2c-47b4-4c0e-a180-f11aa98fd477%2F600x600%2Fcb7ab3d3-4438-442c-8ad9-68df35a1a520_600x600?alt=media&token=35151bda-38f0-4d07-a428-d8fd0cbe68f7"
      }
    },
    "order_id": 0,
    "tool": null,
    "order_number": 556,
    "name": "Blank",
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "ref": "20170703",
      "price": 0.4,
      "gsm": "250",
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bristol Paper"
      },
      "brand": {},
      "name": null
    }
  },
  {
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/c62d1f31-883d-442e-a7b2-1debb796cd29/a96ffb9d-1364-496a-ae8d-cfd139809ceb",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc62d1f31-883d-442e-a7b2-1debb796cd29%2Fa96ffb9d-1364-496a-ae8d-cfd139809ceb?alt=media&token=779282f7-3932-4a7f-86e7-4d79f959f06d"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc62d1f31-883d-442e-a7b2-1debb796cd29%2F600x600%2Fa96ffb9d-1364-496a-ae8d-cfd139809ceb_600x600?alt=media&token=31742f8e-ffee-47e3-9bee-a83ad9a09cf7",
        "reference": "stroke_photos/c62d1f31-883d-442e-a7b2-1debb796cd29/600x600/a96ffb9d-1364-496a-ae8d-cfd139809ceb_600x600"
      }
    },
    "paper": {
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "name": null,
      "gsm": "250",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "original_size": "A7",
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "ref": "77725080001",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      }
    },
    "tool": null,
    "id": "c62d1f31-883d-442e-a7b2-1debb796cd29",
    "name": "Insecurity",
    "description": "",
    "order_number": 198
  },
  {
    "name": "Blank",
    "order_id": 0,
    "paper": {
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "ref": "20170703",
      "name": null,
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {},
      "price": 0.4,
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "order_number": 739,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc66cdbb5-6293-402c-ab45-06c022eda1c6%2Fdf9f4455-ae54-4efa-b88d-a0acae66288f?alt=media&token=74b7f8f7-a649-4354-a2aa-289242ad2224",
        "reference": "/stroke_photos/c66cdbb5-6293-402c-ab45-06c022eda1c6/df9f4455-ae54-4efa-b88d-a0acae66288f"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/c66cdbb5-6293-402c-ab45-06c022eda1c6/600x600/df9f4455-ae54-4efa-b88d-a0acae66288f_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc66cdbb5-6293-402c-ab45-06c022eda1c6%2F600x600%2Fdf9f4455-ae54-4efa-b88d-a0acae66288f_600x600?alt=media&token=1f127319-c02f-4a6b-af26-0aa716fcd17b"
      }
    },
    "id": "c66cdbb5-6293-402c-ab45-06c022eda1c6",
    "description": "",
    "tool": null
  },
  {
    "tool": null,
    "order_id": 0,
    "paper": {
      "name": null,
      "ref": "20180107",
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "gsm": "250",
      "brand": {},
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7"
    },
    "id": "c698fd8a-892f-4671-b575-1bc0aaa453b7",
    "order_number": 259,
    "name": "Fake Stick 2",
    "description": "One more",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc698fd8a-892f-4671-b575-1bc0aaa453b7%2F600x600%2F5872d625-5bd6-4509-ade8-4f9f1649d0fa_600x600?alt=media&token=2e13fe04-dd68-4ac3-b487-b5ee68ded538",
        "reference": "stroke_photos/c698fd8a-892f-4671-b575-1bc0aaa453b7/600x600/5872d625-5bd6-4509-ade8-4f9f1649d0fa_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc698fd8a-892f-4671-b575-1bc0aaa453b7%2F5872d625-5bd6-4509-ade8-4f9f1649d0fa?alt=media&token=48f733e5-4ad2-4f11-99bb-634ce64d5a56",
        "reference": "/stroke_photos/c698fd8a-892f-4671-b575-1bc0aaa453b7/5872d625-5bd6-4509-ade8-4f9f1649d0fa"
      }
    }
  },
  {
    "order_number": 921,
    "paper": {
      "ref": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "original_size": "A4",
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand": {},
      "brand_model": {},
      "gsm": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null
    },
    "name": "Blank",
    "description": "",
    "id": "c6b0a406-522b-4cae-ac5f-801133a7627f",
    "order_id": 0
  },
  {
    "id": "c6b7ac47-18ea-40e2-8b59-3eb618245404",
    "description": "",
    "paper": {
      "gsm": "250",
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "name": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": null,
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {},
      "ref": "70025010030"
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc6b7ac47-18ea-40e2-8b59-3eb618245404%2Fcb57e692-f32c-4ff1-a6bc-258c5d76d105?alt=media&token=af69a48d-a081-4f86-a047-4f8402ab59c9",
        "reference": "/stroke_photos/c6b7ac47-18ea-40e2-8b59-3eb618245404/cb57e692-f32c-4ff1-a6bc-258c5d76d105"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/c6b7ac47-18ea-40e2-8b59-3eb618245404/600x600/cb57e692-f32c-4ff1-a6bc-258c5d76d105_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc6b7ac47-18ea-40e2-8b59-3eb618245404%2F600x600%2Fcb57e692-f32c-4ff1-a6bc-258c5d76d105_600x600?alt=media&token=f33aa3c2-f70f-44b4-b9b8-071214b6a2ef"
      }
    },
    "name": "Blank",
    "order_id": 0,
    "tool": null,
    "order_number": 597
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/c71044a2-5abf-4d71-93b9-91acb1b26e4d/fbcdff3b-6ffb-4466-b22d-c92876069cbc",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc71044a2-5abf-4d71-93b9-91acb1b26e4d%2Ffbcdff3b-6ffb-4466-b22d-c92876069cbc?alt=media&token=eb3ce1c3-0783-4565-9ad4-7d27bcd3f1dd"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/c71044a2-5abf-4d71-93b9-91acb1b26e4d/600x600/fbcdff3b-6ffb-4466-b22d-c92876069cbc_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc71044a2-5abf-4d71-93b9-91acb1b26e4d%2F600x600%2Ffbcdff3b-6ffb-4466-b22d-c92876069cbc_600x600?alt=media&token=648e8b5f-8811-499d-93da-1d2a0d6927e0"
      }
    },
    "order_id": 0,
    "description": "",
    "paper": {
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A7",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "brand": {},
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250",
      "name": null,
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top"
      }
    },
    "order_number": 146,
    "tool": null,
    "name": "Joe's Leftovers",
    "id": "c71044a2-5abf-4d71-93b9-91acb1b26e4d"
  },
  {
    "description": "",
    "id": "c73c6699-3e09-4db8-8412-ec4183658bb2",
    "tool": null,
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc73c6699-3e09-4db8-8412-ec4183658bb2%2F600x600%2F28a4eaba-8c64-43e6-aaae-16f4c2c52226_600x600?alt=media&token=5f22c001-e182-4d47-9ee1-300349ffbd05",
        "reference": "stroke_photos/c73c6699-3e09-4db8-8412-ec4183658bb2/600x600/28a4eaba-8c64-43e6-aaae-16f4c2c52226_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc73c6699-3e09-4db8-8412-ec4183658bb2%2F28a4eaba-8c64-43e6-aaae-16f4c2c52226?alt=media&token=4c6b5fc9-d0d6-4caa-8ee6-2453868feb51",
        "reference": "/stroke_photos/c73c6699-3e09-4db8-8412-ec4183658bb2/28a4eaba-8c64-43e6-aaae-16f4c2c52226"
      }
    },
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "ref": "30075105",
      "price": 19.3,
      "original_size": "75x105cm",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "brand_model": {
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "gsm": "640",
      "name": "tinto"
    },
    "order_number": 698,
    "order_id": 0
  },
  {
    "name": "",
    "order_number": 1179,
    "description": "",
    "id": "c7605d0b-83b5-4698-aa2f-31f216faefaa"
  },
  {
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc79b6c2c-01ba-4392-ab86-617d23ec5e79%2F60681517-fc42-431f-abfb-b6c53fe2232d?alt=media&token=96b88d95-beaa-4054-bcfd-02e535ca5efd",
        "reference": "/stroke_photos/c79b6c2c-01ba-4392-ab86-617d23ec5e79/60681517-fc42-431f-abfb-b6c53fe2232d"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc79b6c2c-01ba-4392-ab86-617d23ec5e79%2F600x600%2F60681517-fc42-431f-abfb-b6c53fe2232d_600x600?alt=media&token=5b150827-915d-4fef-a5a0-7c2de7c66070",
        "reference": "stroke_photos/c79b6c2c-01ba-4392-ab86-617d23ec5e79/600x600/60681517-fc42-431f-abfb-b6c53fe2232d_600x600"
      }
    },
    "paper": {
      "price": 1.35,
      "name": null,
      "ref": "77725080001",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "gsm": "250",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    },
    "order_id": 0,
    "id": "c79b6c2c-01ba-4392-ab86-617d23ec5e79",
    "order_number": 442,
    "description": "Hey to Svil",
    "name": "Lacrosse"
  },
  {
    "order_number": 1004,
    "description": "",
    "name": "",
    "id": "c7cd2887-cb6a-4485-ba0b-dd24f9cf2242"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1115,
    "id": "c7dfa2cb-4b98-4d87-bd3f-977786e6890a"
  },
  {
    "name": "Blank",
    "id": "c84ef067-84c7-4fb3-b3ae-f5ba8957ce61",
    "description": "",
    "order_id": 0,
    "paper": {
      "name": "tinto",
      "brand_model": {
        "name": "Artistico",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "ref": "30075105",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "75x105cm",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "price": 19.3,
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "gsm": "640",
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      }
    },
    "order_number": 997
  },
  {
    "description": "",
    "paper": {
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "price": 0.4,
      "original_size": "A7",
      "name": null,
      "gsm": "250",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "20170703",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {}
    },
    "name": "Blank",
    "id": "c876fd5a-6fe4-48ba-a0cf-93216f85c7ff",
    "order_id": 0,
    "order_number": 813
  },
  {
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/c87c1cac-6068-46b3-a642-9787efa4db11/600x600/5e9de2a1-9c2b-44c1-8249-81e1fb69d462_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc87c1cac-6068-46b3-a642-9787efa4db11%2F600x600%2F5e9de2a1-9c2b-44c1-8249-81e1fb69d462_600x600?alt=media&token=a478c843-0f02-4862-b47c-972d799065fd"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc87c1cac-6068-46b3-a642-9787efa4db11%2F5e9de2a1-9c2b-44c1-8249-81e1fb69d462?alt=media&token=ce6e7b52-1074-49e9-857d-869e0940c7ba",
        "reference": "/stroke_photos/c87c1cac-6068-46b3-a642-9787efa4db11/5e9de2a1-9c2b-44c1-8249-81e1fb69d462"
      }
    },
    "paper": {
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "gsm": "250",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "price": 1.35,
      "name": null,
      "ref": "77725080001",
      "original_size": "A7",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "id": "c87c1cac-6068-46b3-a642-9787efa4db11",
    "order_id": 0,
    "tool": null,
    "description": "",
    "order_number": 406,
    "name": "Blank"
  },
  {
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "original_size": "A4",
      "gsm": "200",
      "name": "",
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "ref": "44202129",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {}
    },
    "description": "",
    "order_id": 0,
    "name": "S Awkward",
    "order_number": 62,
    "id": "c8c9b62e-082a-4cf7-8f6a-82c9326057ed",
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/c8c9b62e-082a-4cf7-8f6a-82c9326057ed/71e717f2-8f3a-45d9-aca9-94d40eab3cb2",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc8c9b62e-082a-4cf7-8f6a-82c9326057ed%2F71e717f2-8f3a-45d9-aca9-94d40eab3cb2?alt=media&token=a038930d-fea3-4c66-9c83-ca959e9e72a6"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/c8c9b62e-082a-4cf7-8f6a-82c9326057ed/600x600/71e717f2-8f3a-45d9-aca9-94d40eab3cb2_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc8c9b62e-082a-4cf7-8f6a-82c9326057ed%2F600x600%2F71e717f2-8f3a-45d9-aca9-94d40eab3cb2_600x600?alt=media&token=80bf3b07-7df8-40a6-9277-b484e84a701c"
      }
    }
  },
  {
    "paper": {
      "price": 0.4,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand": {},
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "gsm": "250",
      "name": null,
      "original_size": "A7",
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "ref": "20170703",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b"
    },
    "id": "c8eb1356-3efe-40a6-a19f-19f8260dfd80",
    "description": "",
    "order_number": 704,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc8eb1356-3efe-40a6-a19f-19f8260dfd80%2F600x600%2F8aeb677f-c8f6-41a1-85c5-a2cb81437727_600x600?alt=media&token=9f193616-bdd6-47fe-a0cf-92b3544827c7",
        "reference": "stroke_photos/c8eb1356-3efe-40a6-a19f-19f8260dfd80/600x600/8aeb677f-c8f6-41a1-85c5-a2cb81437727_600x600"
      },
      "original": {
        "reference": "/stroke_photos/c8eb1356-3efe-40a6-a19f-19f8260dfd80/8aeb677f-c8f6-41a1-85c5-a2cb81437727",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc8eb1356-3efe-40a6-a19f-19f8260dfd80%2F8aeb677f-c8f6-41a1-85c5-a2cb81437727?alt=media&token=d96a3eca-831d-450c-b7a6-e8c2995caa6d"
      }
    },
    "tool": null,
    "name": "Blank",
    "order_id": 0
  },
  {
    "name": "",
    "order_number": 1177,
    "description": "",
    "id": "c90a818e-3680-495f-9086-1e5aa6a601e6"
  },
  {
    "description": "",
    "order_number": 1054,
    "name": "",
    "id": "c974b4a0-dad2-4223-87bf-f67107940062"
  },
  {
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "paper_surface": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "ref": "44202129",
      "name": "",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "A4",
      "gsm": "200",
      "brand_model": {
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4"
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc9bb5936-6302-4a05-b741-9600a88c84e2%2F600x600%2Fc0cc9111-6af5-4472-bb0f-45ea6cc04129_600x600?alt=media&token=91e20f58-d5a1-470c-aa8a-505ef1219a59",
        "reference": "stroke_photos/c9bb5936-6302-4a05-b741-9600a88c84e2/600x600/c0cc9111-6af5-4472-bb0f-45ea6cc04129_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fc9bb5936-6302-4a05-b741-9600a88c84e2%2Fc0cc9111-6af5-4472-bb0f-45ea6cc04129?alt=media&token=d731201e-cb8d-4740-b941-c897ebdb9768",
        "reference": "/stroke_photos/c9bb5936-6302-4a05-b741-9600a88c84e2/c0cc9111-6af5-4472-bb0f-45ea6cc04129"
      }
    },
    "order_id": 0,
    "id": "c9bb5936-6302-4a05-b741-9600a88c84e2",
    "name": "Back To Black Simple Line",
    "order_number": 8,
    "description": "",
    "tool": {}
  },
  {
    "order_number": 964,
    "order_id": 0,
    "id": "c9c5cce2-8d81-401c-ab6c-572f51c9b3f2",
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": null,
      "ref": null,
      "brand": {},
      "brand_model": {},
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A4",
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "name": null
    },
    "name": "Blank",
    "description": ""
  },
  {
    "order_number": 1226,
    "description": "",
    "name": "",
    "id": "c9f78b81-924d-4e0d-b5a0-2254681c586a"
  },
  {
    "name": "Reusing White Paint 4",
    "tool": null,
    "description": "",
    "id": "ca8f51d1-b840-4cc1-b1d1-68c9f93f1e2f",
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A4",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand_model": {},
      "gsm": null,
      "ref": null,
      "brand": {},
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "name": null,
      "price": null
    },
    "order_id": 0,
    "order_number": 464,
    "photos": {
      "original": {
        "reference": "/stroke_photos/ca8f51d1-b840-4cc1-b1d1-68c9f93f1e2f/991808df-fe96-4c2c-b321-55f0847306be",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fca8f51d1-b840-4cc1-b1d1-68c9f93f1e2f%2F991808df-fe96-4c2c-b321-55f0847306be?alt=media&token=f30944e2-e075-4fe9-a0e6-aa752f41794c"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/ca8f51d1-b840-4cc1-b1d1-68c9f93f1e2f/600x600/991808df-fe96-4c2c-b321-55f0847306be_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fca8f51d1-b840-4cc1-b1d1-68c9f93f1e2f%2F600x600%2F991808df-fe96-4c2c-b321-55f0847306be_600x600?alt=media&token=1e050109-a897-40dc-983a-c338259e7979"
      }
    }
  },
  {
    "name": "Blank",
    "description": "",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fca98f0fb-80e0-4b03-a21e-1320a368fb56%2F4e47002a-e0c0-4d81-9c89-dffa29226883?alt=media&token=0a063d35-3d8e-4e79-b254-60bd06bcfb55",
        "reference": "/stroke_photos/ca98f0fb-80e0-4b03-a21e-1320a368fb56/4e47002a-e0c0-4d81-9c89-dffa29226883"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fca98f0fb-80e0-4b03-a21e-1320a368fb56%2F600x600%2F4e47002a-e0c0-4d81-9c89-dffa29226883_600x600?alt=media&token=8d2bd3d6-fc64-43f2-a598-a7db43ac4706",
        "reference": "stroke_photos/ca98f0fb-80e0-4b03-a21e-1320a368fb56/600x600/4e47002a-e0c0-4d81-9c89-dffa29226883_600x600"
      }
    },
    "tool": null,
    "id": "ca98f0fb-80e0-4b03-a21e-1320a368fb56",
    "order_id": 0,
    "order_number": 562,
    "paper": {
      "price": 0.4,
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper"
      },
      "original_size": "A7",
      "brand": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "250",
      "name": null,
      "ref": "20170703"
    }
  },
  {
    "description": "",
    "order_id": 0,
    "name": "Broken Back",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/cab41803-9821-441b-8fec-7201da7a3fab/600x600/f7f2c878-e00f-4071-9fca-2e0070479f96_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcab41803-9821-441b-8fec-7201da7a3fab%2F600x600%2Ff7f2c878-e00f-4071-9fca-2e0070479f96_600x600?alt=media&token=677ce19b-d35c-4935-80b3-97459ec169d9"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcab41803-9821-441b-8fec-7201da7a3fab%2Ff7f2c878-e00f-4071-9fca-2e0070479f96?alt=media&token=9d8082fb-5282-4765-8c28-1bdc7c219338",
        "reference": "/stroke_photos/cab41803-9821-441b-8fec-7201da7a3fab/f7f2c878-e00f-4071-9fca-2e0070479f96"
      }
    },
    "id": "cab41803-9821-441b-8fec-7201da7a3fab",
    "paper": {
      "ref": "77725080001",
      "name": null,
      "price": 1.35,
      "gsm": "250",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      }
    },
    "order_number": 211,
    "tool": null
  },
  {
    "paper": {
      "price": 1,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4",
      "name": null,
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "name": "Elephant Skin"
      },
      "gsm": "110",
      "ref": "4005329065767",
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701"
    },
    "name": "Blank",
    "id": "cab85d86-344b-4228-bd96-b945ab1a42da",
    "description": "",
    "order_id": 0,
    "order_number": 865
  },
  {
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/caec4f82-4288-4ac6-af54-7a78ca8ce8f3/600x600/04c0a2b3-02de-43c0-bbf1-cf1730a5dea6_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcaec4f82-4288-4ac6-af54-7a78ca8ce8f3%2F600x600%2F04c0a2b3-02de-43c0-bbf1-cf1730a5dea6_600x600?alt=media&token=99fca404-55f5-448a-9755-cd6813f2f05d"
      },
      "original": {
        "reference": "/stroke_photos/caec4f82-4288-4ac6-af54-7a78ca8ce8f3/04c0a2b3-02de-43c0-bbf1-cf1730a5dea6",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcaec4f82-4288-4ac6-af54-7a78ca8ce8f3%2F04c0a2b3-02de-43c0-bbf1-cf1730a5dea6?alt=media&token=8f16dbcc-16c9-4194-8e0e-9a31d2f17924"
      }
    },
    "description": "",
    "paper": {
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "brand": {},
      "original_size": "A7",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": "250",
      "name": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "20180107"
    },
    "order_number": 132,
    "tool": null,
    "name": "Make Pressure Points Thin",
    "order_id": 0,
    "id": "caec4f82-4288-4ac6-af54-7a78ca8ce8f3"
  },
  {
    "id": "cb1cc4a3-74e4-43c2-873f-e63292e9ef57",
    "tool": null,
    "name": "SWOB More Curve, Fast Out",
    "order_number": 75,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/cb1cc4a3-74e4-43c2-873f-e63292e9ef57/600x600/8c9446cf-489a-4993-833c-085a9da09551_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcb1cc4a3-74e4-43c2-873f-e63292e9ef57%2F600x600%2F8c9446cf-489a-4993-833c-085a9da09551_600x600?alt=media&token=82fe86c2-599e-4b62-bf4e-414757d0a5f4"
      },
      "original": {
        "reference": "/stroke_photos/cb1cc4a3-74e4-43c2-873f-e63292e9ef57/8c9446cf-489a-4993-833c-085a9da09551",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcb1cc4a3-74e4-43c2-873f-e63292e9ef57%2F8c9446cf-489a-4993-833c-085a9da09551?alt=media&token=110eba32-af8c-4aa1-a81f-2092becb78fe"
      }
    },
    "order_id": 0,
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": "",
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_surface": {},
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "44202129",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "gsm": "200",
      "original_size": "A4"
    }
  },
  {
    "description": "",
    "order_number": 1167,
    "name": "",
    "id": "cb36cb64-4038-4043-b216-c439d69b6e03"
  },
  {
    "order_id": 0,
    "id": "cb470878-2f68-4cd2-a7c3-7282fd65b2a1",
    "name": "WOE 2",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcb470878-2f68-4cd2-a7c3-7282fd65b2a1%2F600x600%2Fdadc6646-5b12-426c-aa38-addc018624c4_600x600?alt=media&token=8ed6bc0f-2806-4377-bd06-509eb7407765",
        "reference": "stroke_photos/cb470878-2f68-4cd2-a7c3-7282fd65b2a1/600x600/dadc6646-5b12-426c-aa38-addc018624c4_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcb470878-2f68-4cd2-a7c3-7282fd65b2a1%2Fdadc6646-5b12-426c-aa38-addc018624c4?alt=media&token=5c2c9574-77d1-40a8-a44d-27d9f2a36e27",
        "reference": "/stroke_photos/cb470878-2f68-4cd2-a7c3-7282fd65b2a1/dadc6646-5b12-426c-aa38-addc018624c4"
      }
    },
    "order_number": 85,
    "tool": null,
    "paper": {
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "paper_surface": {},
      "price": null,
      "gsm": "200",
      "ref": "44202129",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": "",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "original_size": "A4"
    },
    "description": ""
  },
  {
    "tool": null,
    "order_id": 0,
    "order_number": 301,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcb483591-d4fd-403c-9aa0-4b83c9a4eb50%2F600x600%2Ff3e5ec90-0c15-4f23-8d9c-9ae7329c1c4b_600x600?alt=media&token=3c69a7d8-c3e9-4a63-a22a-aa4ab8b825fc",
        "reference": "stroke_photos/cb483591-d4fd-403c-9aa0-4b83c9a4eb50/600x600/f3e5ec90-0c15-4f23-8d9c-9ae7329c1c4b_600x600"
      },
      "original": {
        "reference": "/stroke_photos/cb483591-d4fd-403c-9aa0-4b83c9a4eb50/f3e5ec90-0c15-4f23-8d9c-9ae7329c1c4b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcb483591-d4fd-403c-9aa0-4b83c9a4eb50%2Ff3e5ec90-0c15-4f23-8d9c-9ae7329c1c4b?alt=media&token=e7e6e43d-cc7b-4e35-aa69-f6fef33faacf"
      }
    },
    "id": "cb483591-d4fd-403c-9aa0-4b83c9a4eb50",
    "description": "<p>Somehow the paint keeps not being even</p>",
    "name": "A Bit Less",
    "paper": {
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "price": null,
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": "20180107",
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      }
    }
  },
  {
    "id": "cb6de20a-9db1-4714-afc3-02e50b276cc8",
    "order_number": 861,
    "name": "Blank",
    "order_id": 0,
    "description": "",
    "paper": {
      "ref": "4005329065767",
      "price": 1,
      "brand_model": {
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "name": "Elephant Skin"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "original_size": "A4",
      "gsm": "110",
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "name": null
    }
  },
  {
    "id": "cb757bd6-2811-4ad5-905e-033fbcb71551",
    "tool": null,
    "order_number": 145,
    "paper": {
      "brand": {},
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "name": null,
      "price": null,
      "gsm": "250",
      "original_size": "A7",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "ref": "20180107"
    },
    "name": "Fat Joe",
    "description": "",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcb757bd6-2811-4ad5-905e-033fbcb71551%2F600x600%2F38195a2d-196e-4d95-9b2c-cdfeb05474af_600x600?alt=media&token=ed234386-7a3d-47d7-b1a9-3328b63a772b",
        "reference": "stroke_photos/cb757bd6-2811-4ad5-905e-033fbcb71551/600x600/38195a2d-196e-4d95-9b2c-cdfeb05474af_600x600"
      },
      "original": {
        "reference": "/stroke_photos/cb757bd6-2811-4ad5-905e-033fbcb71551/38195a2d-196e-4d95-9b2c-cdfeb05474af",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcb757bd6-2811-4ad5-905e-033fbcb71551%2F38195a2d-196e-4d95-9b2c-cdfeb05474af?alt=media&token=231e0c92-4284-4bda-b9a0-5d50bb45435a"
      }
    }
  },
  {
    "order_number": 1003,
    "name": "",
    "description": "",
    "id": "cb794e5d-2546-4bb4-836a-858cf890698e"
  },
  {
    "description": "",
    "order_number": 1157,
    "name": "",
    "id": "cbda531d-916a-4efd-b152-087c01f45240"
  },
  {
    "order_number": 1018,
    "name": "",
    "description": "",
    "id": "cbe54ad3-8101-43d2-bf82-1bf84a879025"
  },
  {
    "order_id": 0,
    "id": "cbef8ae5-686f-42f4-a493-6cafd681e2fa",
    "description": "",
    "tool": null,
    "paper": {
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "original_size": "75x105cm",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "name": "tinto",
      "ref": "30075105",
      "brand_model": {
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "price": 19.3,
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "gsm": "640",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      }
    },
    "order_number": 682,
    "name": "Blank",
    "photos": {
      "original": {
        "reference": "/stroke_photos/cbef8ae5-686f-42f4-a493-6cafd681e2fa/c08ee373-7333-439d-be0c-92eff40150a1",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcbef8ae5-686f-42f4-a493-6cafd681e2fa%2Fc08ee373-7333-439d-be0c-92eff40150a1?alt=media&token=c56fb2cf-9da4-40a9-a557-45b6ac3deb61"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/cbef8ae5-686f-42f4-a493-6cafd681e2fa/600x600/c08ee373-7333-439d-be0c-92eff40150a1_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcbef8ae5-686f-42f4-a493-6cafd681e2fa%2F600x600%2Fc08ee373-7333-439d-be0c-92eff40150a1_600x600?alt=media&token=4e9807e5-1885-4493-9406-4ec15f9ab7db"
      }
    }
  },
  {
    "name": "",
    "order_number": 1117,
    "description": "",
    "id": "cc4a4155-8123-4a93-9733-6c704d05cf47"
  },
  {
    "description": "",
    "order_number": 1156,
    "name": "",
    "id": "cc5783f1-2094-45e1-aef5-e167e251af40"
  },
  {
    "tool": null,
    "order_id": 0,
    "order_number": 299,
    "id": "cc643f29-b043-453d-a8e6-ddadaaebb29e",
    "description": "<p>Curve looks like a rainbow. Also paint is not working out correctly</p>",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcc643f29-b043-453d-a8e6-ddadaaebb29e%2F600x600%2Fd62504e5-2b60-44d6-b6b3-5ed6442314d8_600x600?alt=media&token=02a32df9-ea28-46d5-9fd3-36b8b642f62f",
        "reference": "stroke_photos/cc643f29-b043-453d-a8e6-ddadaaebb29e/600x600/d62504e5-2b60-44d6-b6b3-5ed6442314d8_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcc643f29-b043-453d-a8e6-ddadaaebb29e%2Fd62504e5-2b60-44d6-b6b3-5ed6442314d8?alt=media&token=b18fe9c3-88bb-4b8d-9f61-fcf0c377376a",
        "reference": "/stroke_photos/cc643f29-b043-453d-a8e6-ddadaaebb29e/d62504e5-2b60-44d6-b6b3-5ed6442314d8"
      }
    },
    "paper": {
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "20180107",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "brand": {},
      "gsm": "250",
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "price": null
    },
    "name": "Skinny 3"
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcca8cabb-09c2-4421-be31-2705e9c5be34%2F710f8f51-b086-4d85-9d95-cbb9c6f760a8?alt=media&token=3b171410-7ec7-4ddc-8e32-0ecd0c16ee15",
        "reference": "/stroke_photos/cca8cabb-09c2-4421-be31-2705e9c5be34/710f8f51-b086-4d85-9d95-cbb9c6f760a8"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcca8cabb-09c2-4421-be31-2705e9c5be34%2F600x600%2F710f8f51-b086-4d85-9d95-cbb9c6f760a8_600x600?alt=media&token=d1341bfb-bc11-4c70-8260-6a73f7423bf6",
        "reference": "stroke_photos/cca8cabb-09c2-4421-be31-2705e9c5be34/600x600/710f8f51-b086-4d85-9d95-cbb9c6f760a8_600x600"
      }
    },
    "id": "cca8cabb-09c2-4421-be31-2705e9c5be34",
    "order_id": 0,
    "name": "Blank",
    "order_number": 572,
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "price": 0.4,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "original_size": "A7",
      "gsm": "250",
      "brand": {},
      "ref": "20170703",
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      }
    },
    "tool": null,
    "description": ""
  },
  {
    "order_number": 827,
    "id": "ccc969ad-ed9c-4c1c-8cc0-80e6da260702",
    "description": "",
    "name": "Blank",
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "price": null,
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "70025010030",
      "brand": {},
      "name": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {},
      "gsm": "250"
    },
    "order_id": 0
  },
  {
    "description": "",
    "name": "",
    "order_number": 1032,
    "id": "cd19bab7-ce6a-4737-8e23-7fa5869688e6"
  },
  {
    "description": "",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcd56d7df-5179-4d4d-926a-710487093d0b%2F600x600%2F774dd5f0-af92-41c5-ac4e-274c76b1304f_600x600?alt=media&token=e8c5d877-fee6-44b1-8283-c0f333ae4268",
        "reference": "stroke_photos/cd56d7df-5179-4d4d-926a-710487093d0b/600x600/774dd5f0-af92-41c5-ac4e-274c76b1304f_600x600"
      },
      "original": {
        "reference": "/stroke_photos/cd56d7df-5179-4d4d-926a-710487093d0b/774dd5f0-af92-41c5-ac4e-274c76b1304f",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcd56d7df-5179-4d4d-926a-710487093d0b%2F774dd5f0-af92-41c5-ac4e-274c76b1304f?alt=media&token=a04c0bd2-ebe4-4437-8c4b-cdc71479827b"
      }
    },
    "name": "Popf More Paint",
    "order_number": 21,
    "tool": {},
    "id": "cd56d7df-5179-4d4d-926a-710487093d0b",
    "paper": {
      "ref": "44202129",
      "gsm": "200",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "name": "",
      "paper_surface": {},
      "original_size": "A4",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "price": null
    }
  },
  {
    "order_id": 0,
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "20180107",
      "gsm": "250",
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "name": null,
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      }
    },
    "id": "ce43280c-be77-4d6a-adff-100558247f63",
    "order_number": 332,
    "name": "Line Again",
    "description": "<p>Tired of swirlies. A few more regular strokes aiming for perfection</p>",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fce43280c-be77-4d6a-adff-100558247f63%2F600x600%2F15941db7-3670-45d0-8f1b-2f0907f19659_600x600?alt=media&token=8c39731b-65f7-4baa-ab5e-c03cf31ce575",
        "reference": "stroke_photos/ce43280c-be77-4d6a-adff-100558247f63/600x600/15941db7-3670-45d0-8f1b-2f0907f19659_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fce43280c-be77-4d6a-adff-100558247f63%2F15941db7-3670-45d0-8f1b-2f0907f19659?alt=media&token=8d28698e-8c80-49f2-802f-08a309278d84",
        "reference": "/stroke_photos/ce43280c-be77-4d6a-adff-100558247f63/15941db7-3670-45d0-8f1b-2f0907f19659"
      }
    },
    "tool": null
  },
  {
    "id": "ce93c519-d7d4-42f0-b7aa-75b171f40702",
    "paper": {
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "ref": "20170703",
      "brand": {},
      "gsm": "250",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "price": 0.4,
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "tool": null,
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/ce93c519-d7d4-42f0-b7aa-75b171f40702/600x600/45927756-a062-4711-8adb-77f74bc75b86_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fce93c519-d7d4-42f0-b7aa-75b171f40702%2F600x600%2F45927756-a062-4711-8adb-77f74bc75b86_600x600?alt=media&token=93301b6a-28f7-4357-b141-899aeb82e02c"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fce93c519-d7d4-42f0-b7aa-75b171f40702%2F45927756-a062-4711-8adb-77f74bc75b86?alt=media&token=5611c8f2-6677-4ef6-80a9-4d67ae6d0067",
        "reference": "/stroke_photos/ce93c519-d7d4-42f0-b7aa-75b171f40702/45927756-a062-4711-8adb-77f74bc75b86"
      }
    },
    "order_id": 0,
    "description": "",
    "order_number": 565
  },
  {
    "paper": {
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "brand_model": {},
      "price": null,
      "name": null,
      "ref": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": null
    },
    "name": "Blank",
    "id": "cea0e6ce-33cc-471a-870e-b9fee8cf2c27",
    "order_id": 0,
    "description": "",
    "order_number": 969
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/ceb32bf4-59ac-4a6c-bcd0-d852ba967ce5/5ec15718-7737-4632-88c4-b0e2aba350c5",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fceb32bf4-59ac-4a6c-bcd0-d852ba967ce5%2F5ec15718-7737-4632-88c4-b0e2aba350c5?alt=media&token=c573543c-baa2-4112-9e77-d068ad164c43"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/ceb32bf4-59ac-4a6c-bcd0-d852ba967ce5/600x600/5ec15718-7737-4632-88c4-b0e2aba350c5_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fceb32bf4-59ac-4a6c-bcd0-d852ba967ce5%2F600x600%2F5ec15718-7737-4632-88c4-b0e2aba350c5_600x600?alt=media&token=dd6b1840-fc79-4083-8ffa-1eb497224330"
      }
    },
    "description": "",
    "paper": {
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "gsm": "250",
      "ref": "70025010030",
      "brand": {},
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null,
      "brand_model": {},
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "order_number": 613,
    "id": "ceb32bf4-59ac-4a6c-bcd0-d852ba967ce5",
    "order_id": 0,
    "name": "Blank",
    "tool": null
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fceba0cca-588e-4e2b-ae10-f1454d1c116c%2F600x600%2Fa55a9849-26c5-469c-9a1f-a5dcb74f4e18_600x600?alt=media&token=7a24ce76-6fce-4415-bd5a-aebe26e24bcf",
        "reference": "stroke_photos/ceba0cca-588e-4e2b-ae10-f1454d1c116c/600x600/a55a9849-26c5-469c-9a1f-a5dcb74f4e18_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fceba0cca-588e-4e2b-ae10-f1454d1c116c%2Fa55a9849-26c5-469c-9a1f-a5dcb74f4e18?alt=media&token=67dfeaeb-108f-4024-a965-1f74bcbfbb46",
        "reference": "/stroke_photos/ceba0cca-588e-4e2b-ae10-f1454d1c116c/a55a9849-26c5-469c-9a1f-a5dcb74f4e18"
      }
    },
    "description": "<p>And my favorite sound-wave (!) Gorgeous!</p>",
    "name": "Silver-On-Black Soundwave",
    "tool": null,
    "paper": {
      "gsm": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {},
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "price": null,
      "brand": {},
      "original_size": "A4",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "order_number": 548,
    "id": "ceba0cca-588e-4e2b-ae10-f1454d1c116c",
    "order_id": 0
  },
  {
    "order_number": 1038,
    "description": "",
    "name": "",
    "id": "ced483b6-1c48-4f4a-95ce-88f5d895cfb6"
  },
  {
    "description": "<p>one such more without paint</p>",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcedafaf2-cd7b-486c-beb2-1e36a8edf495%2F5d876045-cfb1-4ddc-bbe5-926a9d3496ed?alt=media&token=aaf0bbb2-c815-448b-a8de-42fe68c2238a",
        "reference": "/stroke_photos/cedafaf2-cd7b-486c-beb2-1e36a8edf495/5d876045-cfb1-4ddc-bbe5-926a9d3496ed"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/cedafaf2-cd7b-486c-beb2-1e36a8edf495/600x600/5d876045-cfb1-4ddc-bbe5-926a9d3496ed_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcedafaf2-cd7b-486c-beb2-1e36a8edf495%2F600x600%2F5d876045-cfb1-4ddc-bbe5-926a9d3496ed_600x600?alt=media&token=51717b87-3f4e-49a8-bee1-66c2fa05e058"
      }
    },
    "name": "White Splodge Touch 5",
    "id": "cedafaf2-cd7b-486c-beb2-1e36a8edf495",
    "paper": {
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": null,
      "original_size": "A4",
      "name": null,
      "brand_model": {},
      "brand": {},
      "gsm": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    },
    "tool": null,
    "order_number": 515,
    "order_id": 0
  },
  {
    "paper": {
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "ref": "77725080001",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "gsm": "250",
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "price": 1.35
    },
    "order_id": 0,
    "name": "Blank",
    "tool": null,
    "description": "",
    "id": "cef8cfeb-0386-4dd9-9ccb-0ee55bd76baa",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/cef8cfeb-0386-4dd9-9ccb-0ee55bd76baa/600x600/e38f1a3e-9166-430e-b4f5-9176e4b9e741_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcef8cfeb-0386-4dd9-9ccb-0ee55bd76baa%2F600x600%2Fe38f1a3e-9166-430e-b4f5-9176e4b9e741_600x600?alt=media&token=229431b8-ac8a-4bba-b8ae-eceda1337b7a"
      },
      "original": {
        "reference": "/stroke_photos/cef8cfeb-0386-4dd9-9ccb-0ee55bd76baa/e38f1a3e-9166-430e-b4f5-9176e4b9e741",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcef8cfeb-0386-4dd9-9ccb-0ee55bd76baa%2Fe38f1a3e-9166-430e-b4f5-9176e4b9e741?alt=media&token=56a361aa-92fc-4383-ba44-607fdf203154"
      }
    },
    "order_number": 764
  },
  {
    "order_id": 0,
    "description": "",
    "id": "cf00d258-703b-4630-b4d7-69d22338a2dc",
    "tool": null,
    "name": "S Uglier",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcf00d258-703b-4630-b4d7-69d22338a2dc%2F3b4544bd-731d-4717-9613-1049e7585f1e?alt=media&token=5c8f311f-a047-4f6f-9d8b-de522b7a6ea7",
        "reference": "/stroke_photos/cf00d258-703b-4630-b4d7-69d22338a2dc/3b4544bd-731d-4717-9613-1049e7585f1e"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcf00d258-703b-4630-b4d7-69d22338a2dc%2F600x600%2F3b4544bd-731d-4717-9613-1049e7585f1e_600x600?alt=media&token=14440f35-2825-4fec-8310-68a15fe73c1d",
        "reference": "stroke_photos/cf00d258-703b-4630-b4d7-69d22338a2dc/600x600/3b4544bd-731d-4717-9613-1049e7585f1e_600x600"
      }
    },
    "paper": {
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": null,
      "ref": "44202129",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "gsm": "200",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {},
      "original_size": "A4",
      "name": "",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      }
    },
    "order_number": 61
  },
  {
    "order_id": 0,
    "description": "",
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {},
      "original_size": "A4",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "price": null,
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "brand": {},
      "gsm": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "id": "cfbe9fdf-2cc6-441c-8a90-393231d9cce7",
    "name": "Blank",
    "order_number": 959
  },
  {
    "order_number": 1093,
    "description": "",
    "name": "",
    "id": "cff12794-8f38-439e-bca4-a8b8c166a290"
  },
  {
    "description": "",
    "paper": {
      "price": null,
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "paper_surface": {},
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": "",
      "gsm": "200",
      "ref": "44202129",
      "original_size": "A4",
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      }
    },
    "order_id": 0,
    "tool": {},
    "order_number": 11,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/cff6c69b-df7c-47e8-bfbc-f91dedd41c01/600x600/d574fafe-3857-45f5-8f8a-c05ce101c530_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcff6c69b-df7c-47e8-bfbc-f91dedd41c01%2F600x600%2Fd574fafe-3857-45f5-8f8a-c05ce101c530_600x600?alt=media&token=3054ac41-b3ae-4493-950e-e8543ceed777"
      },
      "original": {
        "reference": "/stroke_photos/cff6c69b-df7c-47e8-bfbc-f91dedd41c01/d574fafe-3857-45f5-8f8a-c05ce101c530",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fcff6c69b-df7c-47e8-bfbc-f91dedd41c01%2Fd574fafe-3857-45f5-8f8a-c05ce101c530?alt=media&token=137430ed-c3ee-4dcb-81fb-8ee489e3ff62"
      }
    },
    "name": "Very Wide Brush",
    "id": "cff6c69b-df7c-47e8-bfbc-f91dedd41c01"
  },
  {
    "name": "Blank",
    "order_id": 0,
    "paper": {
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": 1.35,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "ref": "77725080001",
      "gsm": "250",
      "original_size": "A7",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      }
    },
    "id": "d037c21b-8dbd-44e9-b764-261b1e992616",
    "description": "",
    "order_number": 752,
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/d037c21b-8dbd-44e9-b764-261b1e992616/f48a1aa0-00d4-4f51-ba76-cf7e8127a494",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd037c21b-8dbd-44e9-b764-261b1e992616%2Ff48a1aa0-00d4-4f51-ba76-cf7e8127a494?alt=media&token=7ac66632-b099-49a3-a83d-c229e3a37b87"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd037c21b-8dbd-44e9-b764-261b1e992616%2F600x600%2Ff48a1aa0-00d4-4f51-ba76-cf7e8127a494_600x600?alt=media&token=0e62189b-764a-4fd9-9e12-491e9a90eb90",
        "reference": "stroke_photos/d037c21b-8dbd-44e9-b764-261b1e992616/600x600/f48a1aa0-00d4-4f51-ba76-cf7e8127a494_600x600"
      }
    }
  },
  {
    "order_number": 1058,
    "description": "",
    "name": "",
    "id": "d05c70cb-0092-4d10-8d2d-805a9e300305"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1133,
    "id": "d0934c59-7549-411e-a481-3992b8740391"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1206,
    "id": "d0c0d5a5-c156-46cc-ad31-94bc1ce1acf8"
  },
  {
    "order_number": 840,
    "description": "",
    "name": "Blank",
    "order_id": 0,
    "paper": {
      "name": null,
      "brand": {},
      "ref": "70025010030",
      "original_size": "A7",
      "brand_model": {},
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea"
    },
    "id": "d108d910-9123-48f1-ae04-f71b2238e0a2"
  },
  {
    "name": "Blank",
    "description": "",
    "order_id": 0,
    "id": "d11a1d5b-ee7b-48f7-8a16-0452bebe3982",
    "paper": {
      "ref": "4005329065767",
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "gsm": "110",
      "brand_model": {
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": 1,
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "name": null,
      "original_size": "A4"
    },
    "order_number": 878
  },
  {
    "name": "",
    "order_number": 1218,
    "description": "",
    "id": "d11a7e4f-e371-4a52-aa91-1992374e66c2"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/d14256d1-f04d-4051-afea-a6d8d88c9da5/c1015b0e-34f7-467f-a289-d88a5791a3e3",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd14256d1-f04d-4051-afea-a6d8d88c9da5%2Fc1015b0e-34f7-467f-a289-d88a5791a3e3?alt=media&token=2795bfe4-8deb-43b3-bf1c-2e1f7c23f17c"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/d14256d1-f04d-4051-afea-a6d8d88c9da5/600x600/c1015b0e-34f7-467f-a289-d88a5791a3e3_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd14256d1-f04d-4051-afea-a6d8d88c9da5%2F600x600%2Fc1015b0e-34f7-467f-a289-d88a5791a3e3_600x600?alt=media&token=a1436a0e-613c-41fe-b452-f556abfb5624"
      }
    },
    "description": "",
    "order_number": 683,
    "order_id": 0,
    "id": "d14256d1-f04d-4051-afea-a6d8d88c9da5",
    "tool": null,
    "paper": {
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "gsm": "640",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": "30075105",
      "name": "tinto",
      "original_size": "75x105cm",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "price": 19.3,
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      }
    },
    "name": "Blank"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1043,
    "id": "d169f37b-526c-49ab-8258-a02d26fcf625"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1011,
    "id": "d17310d8-d5f1-449f-8bd6-8bbe29ba3c49"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1121,
    "id": "d1b189b8-12ef-4061-ba1c-1d55287b973f"
  },
  {
    "description": "",
    "tool": {},
    "name": "Mix All The Colors I Had",
    "order_number": 19,
    "photos": {
      "original": {
        "reference": "/stroke_photos/d1b5635a-0090-4320-9849-29dca6012791/510c23ba-3972-472d-bead-ea48481c298c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd1b5635a-0090-4320-9849-29dca6012791%2F510c23ba-3972-472d-bead-ea48481c298c?alt=media&token=4083c90c-e0e2-4a23-8758-a350cb5bfd53"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd1b5635a-0090-4320-9849-29dca6012791%2F600x600%2F510c23ba-3972-472d-bead-ea48481c298c_600x600?alt=media&token=92f0e0f5-0455-4cbc-a67b-5d949cd480b7",
        "reference": "stroke_photos/d1b5635a-0090-4320-9849-29dca6012791/600x600/510c23ba-3972-472d-bead-ea48481c298c_600x600"
      }
    },
    "order_id": 0,
    "purchase": {
      "anonymous": false,
      "status": "bought",
      "buyer": {
        "firstName": "Elena",
        "email": "elena@escreo.com",
        "lastName": "Nikolova"
      },
      "date": {
        "seconds": 1677878272,
        "nanoseconds": 936000000
      },
      "internalDescription": ""
    },
    "paper": {
      "gsm": "200",
      "price": null,
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "ref": "44202129",
      "paper_surface": {},
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "name": "Accademia Designo",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "original_size": "A4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": "",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      }
    },
    "id": "d1b5635a-0090-4320-9849-29dca6012791"
  },
  {
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd20cff29-a723-46e5-a6b7-8b30d491f7c2%2F71247bc9-9906-482c-a983-09edde428866?alt=media&token=dac03753-945c-479c-884a-da35acbd2262",
        "reference": "/stroke_photos/d20cff29-a723-46e5-a6b7-8b30d491f7c2/71247bc9-9906-482c-a983-09edde428866"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/d20cff29-a723-46e5-a6b7-8b30d491f7c2/600x600/71247bc9-9906-482c-a983-09edde428866_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd20cff29-a723-46e5-a6b7-8b30d491f7c2%2F600x600%2F71247bc9-9906-482c-a983-09edde428866_600x600?alt=media&token=e3c59fde-f310-4332-8d82-d7862c6754ff"
      }
    },
    "description": "<p>This time I kept my hand entirely in the air and focused on getting one really gradual line</p>",
    "id": "d20cff29-a723-46e5-a6b7-8b30d491f7c2",
    "paper": {
      "brand": {},
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "250",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "original_size": "A7",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "price": null,
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86"
    },
    "name": "Retry 4",
    "order_id": 0,
    "order_number": 276
  },
  {
    "name": "",
    "description": "",
    "order_number": 1050,
    "id": "d24dd114-6b8d-4034-9c7f-57f06c9163eb"
  },
  {
    "id": "d25cbfbb-7008-4d4d-9734-22b96f24f760",
    "paper": {
      "brand": {},
      "original_size": "A7",
      "gsm": "250",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "70025010030",
      "name": null,
      "price": null,
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "brand_model": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "order_number": 643,
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd25cbfbb-7008-4d4d-9734-22b96f24f760%2Fd986d0b7-0076-405a-920f-2b02fbfba2b7?alt=media&token=541d836e-6c28-4832-9ce5-0d626aacf554",
        "reference": "/stroke_photos/d25cbfbb-7008-4d4d-9734-22b96f24f760/d986d0b7-0076-405a-920f-2b02fbfba2b7"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/d25cbfbb-7008-4d4d-9734-22b96f24f760/600x600/d986d0b7-0076-405a-920f-2b02fbfba2b7_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd25cbfbb-7008-4d4d-9734-22b96f24f760%2F600x600%2Fd986d0b7-0076-405a-920f-2b02fbfba2b7_600x600?alt=media&token=37827478-79f8-4445-b168-3ca5bf660c7f"
      }
    },
    "description": "",
    "tool": null,
    "name": "Blank"
  },
  {
    "order_number": 568,
    "description": "",
    "order_id": 0,
    "tool": null,
    "name": "Blank",
    "id": "d26493db-7317-495c-92cd-f39dcb405ffc",
    "paper": {
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "20170703",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "original_size": "A7",
      "brand": {},
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": 0.4
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd26493db-7317-495c-92cd-f39dcb405ffc%2F600x600%2F413fb2e5-29d0-4f77-bb83-acd83321b5c3_600x600?alt=media&token=346ae849-7dc0-49b6-a81e-cdea2dc265cc",
        "reference": "stroke_photos/d26493db-7317-495c-92cd-f39dcb405ffc/600x600/413fb2e5-29d0-4f77-bb83-acd83321b5c3_600x600"
      },
      "original": {
        "reference": "/stroke_photos/d26493db-7317-495c-92cd-f39dcb405ffc/413fb2e5-29d0-4f77-bb83-acd83321b5c3",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd26493db-7317-495c-92cd-f39dcb405ffc%2F413fb2e5-29d0-4f77-bb83-acd83321b5c3?alt=media&token=e24b8a71-9427-4c96-85df-f3feca36c74d"
      }
    }
  },
  {
    "order_number": 1092,
    "description": "",
    "name": "",
    "id": "d294c52d-dea7-4221-9994-5696686ea070"
  },
  {
    "order_id": 0,
    "tool": null,
    "order_number": 131,
    "purchase": {
      "anonymous": false,
      "date": {
        "seconds": 1677621600,
        "nanoseconds": 47000000
      },
      "status": "bought",
      "buyer": {
        "email": "dobromir.v.zlatkov@gmail.com",
        "lastName": "Zlatkov",
        "phone": "0000",
        "firstName": "Dobromir"
      },
      "internalDescription": "Nothing"
    },
    "description": "",
    "name": "Add More Pressures",
    "paper": {
      "ref": "20180107",
      "name": null,
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "price": null,
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd2aa5eda-921c-4550-8178-25aae61c0d84%2F600x600%2F2b76164c-575a-4070-8f46-50ba50b0c3cb_600x600?alt=media&token=399f3688-3a00-4bf9-8796-2b3d11afcb91",
        "reference": "stroke_photos/d2aa5eda-921c-4550-8178-25aae61c0d84/600x600/2b76164c-575a-4070-8f46-50ba50b0c3cb_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd2aa5eda-921c-4550-8178-25aae61c0d84%2F2b76164c-575a-4070-8f46-50ba50b0c3cb?alt=media&token=5e7a8e1d-2a0e-4f27-8376-dd9d8f453a6d",
        "reference": "/stroke_photos/d2aa5eda-921c-4550-8178-25aae61c0d84/2b76164c-575a-4070-8f46-50ba50b0c3cb"
      }
    },
    "id": "d2aa5eda-921c-4550-8178-25aae61c0d84"
  },
  {
    "name": "Blank",
    "order_number": 929,
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {},
      "price": null,
      "ref": null,
      "brand": {},
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "name": null,
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "gsm": null
    },
    "description": "",
    "order_id": 0,
    "id": "d2b3197f-170d-4e25-a919-44caf4f8172e"
  },
  {
    "id": "d2ddb016-38b2-4f40-94e3-a7b1dfef53a1",
    "paper": {
      "original_size": "A7",
      "brand_model": {},
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "ref": "70025010030",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "brand": {},
      "name": null,
      "gsm": "250"
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd2ddb016-38b2-4f40-94e3-a7b1dfef53a1%2F4cb7c678-401b-484c-b686-640f6c9777e5?alt=media&token=b3e32395-f467-4832-9bc2-9f1a424987d5",
        "reference": "/stroke_photos/d2ddb016-38b2-4f40-94e3-a7b1dfef53a1/4cb7c678-401b-484c-b686-640f6c9777e5"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/d2ddb016-38b2-4f40-94e3-a7b1dfef53a1/600x600/4cb7c678-401b-484c-b686-640f6c9777e5_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd2ddb016-38b2-4f40-94e3-a7b1dfef53a1%2F600x600%2F4cb7c678-401b-484c-b686-640f6c9777e5_600x600?alt=media&token=0a3c9a5b-fc2b-4e94-b427-dac83935bd0c"
      }
    },
    "name": "Blank",
    "description": "",
    "order_id": 0,
    "tool": null,
    "order_number": 586
  },
  {
    "paper": {
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "brand": {},
      "price": 0.4,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "name": null,
      "ref": "20170703",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "order_id": 0,
    "order_number": 747,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd302c7be-ade2-499e-a81c-8e9eb7083333%2F600x600%2F20150d20-01c4-4258-8fd9-d00943b82d41_600x600?alt=media&token=c86bc4e3-b774-4d16-8814-6e52a3fb8e4a",
        "reference": "stroke_photos/d302c7be-ade2-499e-a81c-8e9eb7083333/600x600/20150d20-01c4-4258-8fd9-d00943b82d41_600x600"
      },
      "original": {
        "reference": "/stroke_photos/d302c7be-ade2-499e-a81c-8e9eb7083333/20150d20-01c4-4258-8fd9-d00943b82d41",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd302c7be-ade2-499e-a81c-8e9eb7083333%2F20150d20-01c4-4258-8fd9-d00943b82d41?alt=media&token=082cfe57-309a-45e8-b6e3-e8f80cbc7fb4"
      }
    },
    "tool": null,
    "name": "Blank",
    "id": "d302c7be-ade2-499e-a81c-8e9eb7083333"
  },
  {
    "order_number": 1210,
    "name": "",
    "description": "",
    "id": "d32e8342-ffc1-4ada-aef5-ba54db75e7ad"
  },
  {
    "name": "No Paint, No Clothes 2",
    "paper": {
      "paper_surface": {},
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "ref": "44202129",
      "name": "",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "200",
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "original_size": "A4"
    },
    "order_number": 96,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/d33a3c87-148a-4dcb-a79b-196b21fbf3f1/600x600/85734030-5e92-4fa0-b8f2-48daff4e3520_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd33a3c87-148a-4dcb-a79b-196b21fbf3f1%2F600x600%2F85734030-5e92-4fa0-b8f2-48daff4e3520_600x600?alt=media&token=984b98cd-60ba-4f48-8611-ff0541df48bc"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd33a3c87-148a-4dcb-a79b-196b21fbf3f1%2F85734030-5e92-4fa0-b8f2-48daff4e3520?alt=media&token=2c30e958-eea5-4af8-b121-f4f6596bb3ac",
        "reference": "/stroke_photos/d33a3c87-148a-4dcb-a79b-196b21fbf3f1/85734030-5e92-4fa0-b8f2-48daff4e3520"
      }
    },
    "id": "d33a3c87-148a-4dcb-a79b-196b21fbf3f1",
    "description": "",
    "tool": null,
    "order_id": 0
  },
  {
    "name": "Blank",
    "description": "",
    "tool": null,
    "order_number": 760,
    "id": "d377976d-0b04-4c6d-a300-6db16ea3f22f",
    "paper": {
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "price": 1.35,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "original_size": "A7",
      "name": null,
      "gsm": "250",
      "ref": "77725080001",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      }
    },
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/d377976d-0b04-4c6d-a300-6db16ea3f22f/600x600/3cc8e2a7-b69b-49e4-a868-d4cef3e8a650_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd377976d-0b04-4c6d-a300-6db16ea3f22f%2F600x600%2F3cc8e2a7-b69b-49e4-a868-d4cef3e8a650_600x600?alt=media&token=c1f08c0d-7390-4aaf-a668-976c8615ebd9"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd377976d-0b04-4c6d-a300-6db16ea3f22f%2F3cc8e2a7-b69b-49e4-a868-d4cef3e8a650?alt=media&token=b8d0ea53-af6e-41af-a57f-d4e6cfe7f95d",
        "reference": "/stroke_photos/d377976d-0b04-4c6d-a300-6db16ea3f22f/3cc8e2a7-b69b-49e4-a868-d4cef3e8a650"
      }
    }
  },
  {
    "name": "Sideways Braid Less Paint",
    "order_id": 0,
    "paper": {
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "original_size": "A7",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250",
      "ref": "20180107",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      }
    },
    "tool": null,
    "description": "",
    "id": "d3ab9e43-dbc5-4e0a-ab20-15d04791a733",
    "order_number": 142,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/d3ab9e43-dbc5-4e0a-ab20-15d04791a733/600x600/88c14e8e-09ce-4fcf-8ab2-b364a4458b65_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd3ab9e43-dbc5-4e0a-ab20-15d04791a733%2F600x600%2F88c14e8e-09ce-4fcf-8ab2-b364a4458b65_600x600?alt=media&token=5a28efbe-b79e-45dc-b61d-77c2182addc4"
      },
      "original": {
        "reference": "/stroke_photos/d3ab9e43-dbc5-4e0a-ab20-15d04791a733/88c14e8e-09ce-4fcf-8ab2-b364a4458b65",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd3ab9e43-dbc5-4e0a-ab20-15d04791a733%2F88c14e8e-09ce-4fcf-8ab2-b364a4458b65?alt=media&token=4236cfd3-188c-4f27-a53c-59d00efc1aeb"
      }
    }
  },
  {
    "description": "",
    "order_number": 1094,
    "name": "",
    "id": "d3cd21fc-9d06-4515-ad6c-a2e5d753f013"
  },
  {
    "id": "d3dd3fc8-32f0-4f13-904a-1a50e48dcbeb",
    "tool": null,
    "paper": {
      "name": null,
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "original_size": "A7",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "ref": "77725080001",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "250"
    },
    "description": "Somehow that turned out to be less good.",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/d3dd3fc8-32f0-4f13-904a-1a50e48dcbeb/600x600/ba5ddc92-d066-4127-be0d-34dd4d5b0f4f_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd3dd3fc8-32f0-4f13-904a-1a50e48dcbeb%2F600x600%2Fba5ddc92-d066-4127-be0d-34dd4d5b0f4f_600x600?alt=media&token=00443eb4-3d9e-4a0d-8d8b-8be1f1510992"
      },
      "original": {
        "reference": "/stroke_photos/d3dd3fc8-32f0-4f13-904a-1a50e48dcbeb/ba5ddc92-d066-4127-be0d-34dd4d5b0f4f",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd3dd3fc8-32f0-4f13-904a-1a50e48dcbeb%2Fba5ddc92-d066-4127-be0d-34dd4d5b0f4f?alt=media&token=aced80e6-14a6-4e87-bad8-8c45037c1f36"
      }
    },
    "order_id": 0,
    "name": "More Water",
    "order_number": 415
  },
  {
    "name": "Blank",
    "order_number": 986,
    "id": "d3f8895f-6ee5-47bf-9995-a886465acd7e",
    "order_id": 0,
    "description": "",
    "paper": {
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "ref": "30075105",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "price": 19.3,
      "original_size": "75x105cm",
      "name": "tinto",
      "gsm": "640",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico"
      },
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      }
    }
  },
  {
    "order_id": 0,
    "order_number": 483,
    "name": "Upside White Stroke 2",
    "description": "<p>A little bit better, but not even in the center.</p>",
    "paper": {
      "brand": {},
      "name": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": null,
      "brand_model": {},
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "gsm": null
    },
    "tool": null,
    "id": "d3fe8521-fb60-4e5c-a6c8-2f696a6f1713",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd3fe8521-fb60-4e5c-a6c8-2f696a6f1713%2F600x600%2F328ebfb1-4a8a-4944-a7ee-c44a37e18da0_600x600?alt=media&token=b2c8dafb-3238-47de-b146-6133743f1554",
        "reference": "stroke_photos/d3fe8521-fb60-4e5c-a6c8-2f696a6f1713/600x600/328ebfb1-4a8a-4944-a7ee-c44a37e18da0_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd3fe8521-fb60-4e5c-a6c8-2f696a6f1713%2F328ebfb1-4a8a-4944-a7ee-c44a37e18da0?alt=media&token=89b34aba-e47f-43b2-9f19-7a5fd6a46dc1",
        "reference": "/stroke_photos/d3fe8521-fb60-4e5c-a6c8-2f696a6f1713/328ebfb1-4a8a-4944-a7ee-c44a37e18da0"
      }
    }
  },
  {
    "name": "",
    "description": "",
    "order_number": 1028,
    "id": "d4115f5a-746c-4a1c-b77f-067c52842318"
  },
  {
    "order_number": 116,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/d41a3be6-4665-4d23-a84f-2eabe899f801/600x600/816b2b0e-fcf4-4625-8790-af1b9a484d72_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd41a3be6-4665-4d23-a84f-2eabe899f801%2F600x600%2F816b2b0e-fcf4-4625-8790-af1b9a484d72_600x600?alt=media&token=e22f4c61-23b1-4abd-97a5-588747e77127"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd41a3be6-4665-4d23-a84f-2eabe899f801%2F816b2b0e-fcf4-4625-8790-af1b9a484d72?alt=media&token=8cc65328-fb67-4c6d-a3cc-0b2845e50d6e",
        "reference": "/stroke_photos/d41a3be6-4665-4d23-a84f-2eabe899f801/816b2b0e-fcf4-4625-8790-af1b9a484d72"
      }
    },
    "tool": null,
    "paper": {
      "price": null,
      "ref": "20180107",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "gsm": "250",
      "original_size": "A7",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {}
    },
    "name": "Dirty Cut",
    "id": "d41a3be6-4665-4d23-a84f-2eabe899f801",
    "order_id": 0
  },
  {
    "name": "",
    "order_number": 1010,
    "description": "",
    "id": "d41d85b6-5d9e-4590-91d0-a0ba78bbebf0"
  },
  {
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/d4213234-0ee4-421f-a7dc-ebe4b837fd86/3528b664-28d8-47a3-8972-601a9d13d4c3",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd4213234-0ee4-421f-a7dc-ebe4b837fd86%2F3528b664-28d8-47a3-8972-601a9d13d4c3?alt=media&token=742c233f-8114-4f4d-90e0-e517d459d104"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/d4213234-0ee4-421f-a7dc-ebe4b837fd86/600x600/3528b664-28d8-47a3-8972-601a9d13d4c3_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd4213234-0ee4-421f-a7dc-ebe4b837fd86%2F600x600%2F3528b664-28d8-47a3-8972-601a9d13d4c3_600x600?alt=media&token=ebc72a88-3fed-4dff-b27a-600c76bf64e7"
      }
    },
    "name": "Slightly More Than 5",
    "order_id": 0,
    "paper": {
      "name": null,
      "gsm": "250",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "ref": "20180107",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "A7",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "id": "d4213234-0ee4-421f-a7dc-ebe4b837fd86",
    "description": "",
    "order_number": 126
  },
  {
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "price": null,
      "ref": "20180107",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "original_size": "A7",
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "description": "<p>Keep finghting this length problem</p>",
    "order_number": 357,
    "order_id": 0,
    "name": "Fixing Length",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd4222940-09c6-44cc-aecf-e43c7a45b72f%2F600x600%2F159db3b8-ad5e-4f38-94c3-ef6ede181cb3_600x600?alt=media&token=6c7f578d-27af-4af7-9b96-1079a07d5c28",
        "reference": "stroke_photos/d4222940-09c6-44cc-aecf-e43c7a45b72f/600x600/159db3b8-ad5e-4f38-94c3-ef6ede181cb3_600x600"
      },
      "original": {
        "reference": "/stroke_photos/d4222940-09c6-44cc-aecf-e43c7a45b72f/159db3b8-ad5e-4f38-94c3-ef6ede181cb3",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd4222940-09c6-44cc-aecf-e43c7a45b72f%2F159db3b8-ad5e-4f38-94c3-ef6ede181cb3?alt=media&token=48234952-0756-4933-b5f5-551a513aefc7"
      }
    },
    "tool": null,
    "id": "d4222940-09c6-44cc-aecf-e43c7a45b72f"
  },
  {
    "paper": {
      "gsm": "250",
      "ref": "77725080001",
      "name": null,
      "original_size": "A7",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "price": 1.35,
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      }
    },
    "order_id": 0,
    "name": "Musically 9 - Spin",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/d44e00dc-7f44-4d72-a48a-aeab24adcd63/600x600/19a45714-a30d-4ad3-b783-a69e3cca78dd_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd44e00dc-7f44-4d72-a48a-aeab24adcd63%2F600x600%2F19a45714-a30d-4ad3-b783-a69e3cca78dd_600x600?alt=media&token=11dcca9d-d233-4d34-8b60-16841db9a9ca"
      },
      "original": {
        "reference": "/stroke_photos/d44e00dc-7f44-4d72-a48a-aeab24adcd63/19a45714-a30d-4ad3-b783-a69e3cca78dd",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd44e00dc-7f44-4d72-a48a-aeab24adcd63%2F19a45714-a30d-4ad3-b783-a69e3cca78dd?alt=media&token=2b1587b6-6f1e-43b0-b858-d9475233859c"
      }
    },
    "id": "d44e00dc-7f44-4d72-a48a-aeab24adcd63",
    "tool": null,
    "order_number": 244,
    "description": "If I was dancing at this moment, I would spin. So let's spin the brush in motion and see what happens if it was dancing..."
  },
  {
    "description": "",
    "order_number": 1034,
    "name": "",
    "id": "d463b296-de32-465d-8acb-be345c1f682d"
  },
  {
    "order_number": 1030,
    "description": "",
    "name": "",
    "id": "d464aa86-7671-4852-a5ed-ae3fce932d02"
  },
  {
    "paper": {
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "gsm": "250",
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "20170703",
      "brand": {},
      "price": 0.4
    },
    "id": "d46be59a-0fda-43f7-9b77-44ffff728a03",
    "order_id": 0,
    "description": "",
    "name": "Blank",
    "order_number": 822
  },
  {
    "name": "",
    "description": "",
    "order_number": 1195,
    "id": "d4992084-770d-4d0c-82b9-14fdaec39fe9"
  },
  {
    "description": "",
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand_model": {},
      "gsm": "250",
      "ref": "70025010030",
      "name": null,
      "original_size": "A7",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      }
    },
    "id": "d4a9de7e-3de5-462f-b1f6-c35c158796c6",
    "order_number": 614,
    "photos": {
      "original": {
        "reference": "/stroke_photos/d4a9de7e-3de5-462f-b1f6-c35c158796c6/31f68c69-962d-412b-8a87-b9a3f53094d4",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd4a9de7e-3de5-462f-b1f6-c35c158796c6%2F31f68c69-962d-412b-8a87-b9a3f53094d4?alt=media&token=0d85f589-f506-4bb8-befd-753e4a75c371"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd4a9de7e-3de5-462f-b1f6-c35c158796c6%2F600x600%2F31f68c69-962d-412b-8a87-b9a3f53094d4_600x600?alt=media&token=cacacf57-c364-4877-80e0-8945c8f99356",
        "reference": "stroke_photos/d4a9de7e-3de5-462f-b1f6-c35c158796c6/600x600/31f68c69-962d-412b-8a87-b9a3f53094d4_600x600"
      }
    },
    "name": "Blank",
    "order_id": 0,
    "tool": null
  },
  {
    "tool": null,
    "description": "",
    "paper": {
      "price": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "original_size": "A7",
      "ref": "20180107",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86"
    },
    "id": "d4cf4977-a610-4324-80f7-956a65c187fd",
    "order_number": 106,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/d4cf4977-a610-4324-80f7-956a65c187fd/600x600/42593f15-83e2-420a-a100-e8dc85d55fd1_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd4cf4977-a610-4324-80f7-956a65c187fd%2F600x600%2F42593f15-83e2-420a-a100-e8dc85d55fd1_600x600?alt=media&token=2ba8c728-ec12-4729-a602-b427342563f7"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd4cf4977-a610-4324-80f7-956a65c187fd%2F42593f15-83e2-420a-a100-e8dc85d55fd1?alt=media&token=a580e090-67b8-4c20-8d0c-733cd3b7324b",
        "reference": "/stroke_photos/d4cf4977-a610-4324-80f7-956a65c187fd/42593f15-83e2-420a-a100-e8dc85d55fd1"
      }
    },
    "name": "VP Bottom  Sharps Thinner",
    "order_id": 0
  },
  {
    "order_id": 0,
    "description": "",
    "name": "Blank",
    "id": "d4e83bec-38c8-41ce-be8d-9fc2554373c5",
    "paper": {
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "price": 19.3,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "gsm": "640",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "original_size": "75x105cm",
      "brand_model": {
        "name": "Artistico",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "ref": "30075105",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "name": "tinto",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      }
    },
    "order_number": 984
  },
  {
    "name": "",
    "description": "",
    "order_number": 1134,
    "id": "d4f468ee-f7b9-49aa-88bc-5dc5673bb99d"
  },
  {
    "order_number": 14,
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "ref": "44202129",
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Accademia Designo"
      },
      "price": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "paper_surface": {},
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "name": "",
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "200"
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd4f89910-5fec-4442-abc1-f669cbbd71b3%2F94e6fab2-799a-4ff0-9cfa-eaa23b3a37f9?alt=media&token=0d505d54-f989-4aa2-acce-5abffd1e4912",
        "reference": "/stroke_photos/d4f89910-5fec-4442-abc1-f669cbbd71b3/94e6fab2-799a-4ff0-9cfa-eaa23b3a37f9"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/d4f89910-5fec-4442-abc1-f669cbbd71b3/600x600/94e6fab2-799a-4ff0-9cfa-eaa23b3a37f9_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd4f89910-5fec-4442-abc1-f669cbbd71b3%2F600x600%2F94e6fab2-799a-4ff0-9cfa-eaa23b3a37f9_600x600?alt=media&token=d02529d0-6b02-4e85-946a-7edc3bc42dd0"
      }
    },
    "id": "d4f89910-5fec-4442-abc1-f669cbbd71b3",
    "tool": {},
    "order_id": 0,
    "name": "Less Paint 1",
    "description": ""
  },
  {
    "order_id": 0,
    "paper": {
      "price": 1.35,
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "gsm": "250",
      "name": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "original_size": "A7",
      "ref": "77725080001"
    },
    "name": "Blank",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/d50350a6-ec6b-4d03-980c-726abc19ab59/600x600/a2b32965-6f79-4d5a-acdb-12dfee5cfd03_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd50350a6-ec6b-4d03-980c-726abc19ab59%2F600x600%2Fa2b32965-6f79-4d5a-acdb-12dfee5cfd03_600x600?alt=media&token=be409519-2bd2-4c8c-b6bf-bba066150dc2"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd50350a6-ec6b-4d03-980c-726abc19ab59%2Fa2b32965-6f79-4d5a-acdb-12dfee5cfd03?alt=media&token=8852d6d0-2e94-4ee2-b973-4af3e2caae2f",
        "reference": "/stroke_photos/d50350a6-ec6b-4d03-980c-726abc19ab59/a2b32965-6f79-4d5a-acdb-12dfee5cfd03"
      }
    },
    "description": "",
    "order_number": 798,
    "id": "d50350a6-ec6b-4d03-980c-726abc19ab59"
  },
  {
    "order_number": 1218,
    "name": "",
    "description": "",
    "id": "d5dbc4df-1d56-407b-8fc4-70649f2809c6"
  },
  {
    "name": "Blank",
    "paper": {
      "ref": "30075105",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": "tinto",
      "price": 19.3,
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "gsm": "640",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "original_size": "75x105cm",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      }
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/d5e0751e-7740-459a-bba9-e1e7338ac0c4/600x600/d1bdc7f3-35c0-4e3d-b10d-94298de38b6b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd5e0751e-7740-459a-bba9-e1e7338ac0c4%2F600x600%2Fd1bdc7f3-35c0-4e3d-b10d-94298de38b6b_600x600?alt=media&token=111b6469-b86e-4eb5-957d-944c32ebf72d"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd5e0751e-7740-459a-bba9-e1e7338ac0c4%2Fd1bdc7f3-35c0-4e3d-b10d-94298de38b6b?alt=media&token=7197e65e-9354-43dd-8f37-e3304d0dffdd",
        "reference": "/stroke_photos/d5e0751e-7740-459a-bba9-e1e7338ac0c4/d1bdc7f3-35c0-4e3d-b10d-94298de38b6b"
      }
    },
    "order_id": 0,
    "order_number": 689,
    "id": "d5e0751e-7740-459a-bba9-e1e7338ac0c4",
    "tool": null,
    "description": ""
  },
  {
    "description": "",
    "order_number": 979,
    "name": "Blank",
    "id": "d636ae00-2e7c-44bb-814b-17d00ed5bc1e",
    "paper": {
      "original_size": "75x105cm",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "price": 19.3,
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "name": "tinto",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico"
      },
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "ref": "30075105",
      "gsm": "640"
    },
    "order_id": 0
  },
  {
    "order_number": 1161,
    "description": "",
    "name": "",
    "id": "d64b572c-9904-47cb-8389-70ad0a62c1d7"
  },
  {
    "tool": null,
    "name": "PH More Paint 2",
    "id": "d664025d-3bab-4d75-9399-4edea1cf6ea5",
    "photos": {
      "original": {
        "reference": "/stroke_photos/d664025d-3bab-4d75-9399-4edea1cf6ea5/ac20749f-d33a-4f0a-81b4-c3af9d401c3a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd664025d-3bab-4d75-9399-4edea1cf6ea5%2Fac20749f-d33a-4f0a-81b4-c3af9d401c3a?alt=media&token=cad145c2-881c-4948-b0ba-51cbe3008a9f"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/d664025d-3bab-4d75-9399-4edea1cf6ea5/600x600/ac20749f-d33a-4f0a-81b4-c3af9d401c3a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd664025d-3bab-4d75-9399-4edea1cf6ea5%2F600x600%2Fac20749f-d33a-4f0a-81b4-c3af9d401c3a_600x600?alt=media&token=12249b7c-d2b9-42d9-b551-adb04a3e057c"
      }
    },
    "order_number": 55,
    "order_id": 0,
    "description": "Here is another one pressing hard. I can see the tip coming up again. Not ideal direction",
    "paper": {
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "original_size": "A4",
      "paper_surface": {},
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "gsm": "200",
      "price": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": "",
      "ref": "44202129",
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo"
      }
    }
  },
  {
    "name": "",
    "order_number": 1145,
    "description": "",
    "id": "d67a72d5-d782-40ba-bdc4-83c358d52b63"
  },
  {
    "id": "d6854ee5-11cd-4aae-ad87-25234bffe307",
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": null,
      "ref": "20180107",
      "brand": {},
      "name": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "order_id": 0,
    "order_number": 384,
    "name": "Patchy 3",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/d6854ee5-11cd-4aae-ad87-25234bffe307/600x600/b9e2d0d6-0d2a-4faa-bd5d-7f6ea6cef1ab_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd6854ee5-11cd-4aae-ad87-25234bffe307%2F600x600%2Fb9e2d0d6-0d2a-4faa-bd5d-7f6ea6cef1ab_600x600?alt=media&token=84dd019e-d1a5-4fa2-9e0a-678af439b426"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd6854ee5-11cd-4aae-ad87-25234bffe307%2Fb9e2d0d6-0d2a-4faa-bd5d-7f6ea6cef1ab?alt=media&token=ac8b1469-3900-44a9-804c-c9d24a9e865c",
        "reference": "/stroke_photos/d6854ee5-11cd-4aae-ad87-25234bffe307/b9e2d0d6-0d2a-4faa-bd5d-7f6ea6cef1ab"
      }
    },
    "description": "<p>Nice</p>",
    "tool": null
  },
  {
    "id": "d6a13166-078e-46b8-871f-44ba36cbf3ac",
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "ref": "20170703",
      "price": 0.4,
      "name": null,
      "gsm": "250",
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "name": "Blank",
    "description": "",
    "order_number": 823,
    "order_id": 0
  },
  {
    "name": "Blank",
    "paper": {
      "gsm": "250",
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "original_size": "A7",
      "name": null,
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "ref": "77725080001"
    },
    "order_number": 195,
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd6bcae97-a8e7-40cb-85d9-e0a376f02ede%2Fae72f5f8-9c05-461f-807b-723e52e7aa2b?alt=media&token=fc08f150-10fa-49cf-82d0-c7a9e64f8c72",
        "reference": "/stroke_photos/d6bcae97-a8e7-40cb-85d9-e0a376f02ede/ae72f5f8-9c05-461f-807b-723e52e7aa2b"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/d6bcae97-a8e7-40cb-85d9-e0a376f02ede/600x600/ae72f5f8-9c05-461f-807b-723e52e7aa2b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd6bcae97-a8e7-40cb-85d9-e0a376f02ede%2F600x600%2Fae72f5f8-9c05-461f-807b-723e52e7aa2b_600x600?alt=media&token=ca5e1149-8783-4a7e-b853-4a8be5c010d0"
      }
    },
    "id": "d6bcae97-a8e7-40cb-85d9-e0a376f02ede",
    "description": "",
    "tool": null
  },
  {
    "description": "",
    "order_number": 1051,
    "name": "",
    "id": "d6dbbb31-0a07-4e22-a39c-e5897907d558"
  },
  {
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "gsm": "110",
      "price": 1,
      "original_size": "A4",
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "4005329065767",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "brand_model": {
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        }
      }
    },
    "description": "",
    "order_id": 0,
    "id": "d6ea311c-77ae-4578-a4ca-9870558649da",
    "name": "Blank",
    "order_number": 889
  },
  {
    "order_number": 1208,
    "name": "",
    "description": "",
    "id": "d6f3b980-1118-4dd6-a9ca-78d481205a8d"
  },
  {
    "order_number": 1126,
    "name": "",
    "description": "",
    "id": "d708a64a-9ad9-4ab1-80fc-ee561a7aed1d"
  },
  {
    "order_id": 0,
    "paper": {
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "ref": "4005329065767",
      "gsm": "110",
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        }
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "original_size": "A4",
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "price": 1
    },
    "description": "",
    "id": "d71b385b-5c1b-4ec3-ac8f-2c408163e2f3",
    "order_number": 880,
    "name": "Blank"
  },
  {
    "order_number": 1111,
    "description": "",
    "name": "",
    "id": "d73786d5-807f-4579-8670-c9a268ca86af"
  },
  {
    "description": "",
    "tool": null,
    "id": "d73811a9-edfe-4cd9-9e03-b33b2d81d479",
    "name": "S Fast",
    "order_number": 64,
    "photos": {
      "original": {
        "reference": "/stroke_photos/d73811a9-edfe-4cd9-9e03-b33b2d81d479/820669bc-bdb6-4ebf-baf6-172544c28f02",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd73811a9-edfe-4cd9-9e03-b33b2d81d479%2F820669bc-bdb6-4ebf-baf6-172544c28f02?alt=media&token=79638f00-634f-44d8-b0d3-282d7eebb058"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd73811a9-edfe-4cd9-9e03-b33b2d81d479%2F600x600%2F820669bc-bdb6-4ebf-baf6-172544c28f02_600x600?alt=media&token=97f85849-05dd-41d2-b736-15e96905eb24",
        "reference": "stroke_photos/d73811a9-edfe-4cd9-9e03-b33b2d81d479/600x600/820669bc-bdb6-4ebf-baf6-172544c28f02_600x600"
      }
    },
    "paper": {
      "name": "",
      "price": null,
      "paper_surface": {},
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4",
      "ref": "44202129",
      "gsm": "200",
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo"
      }
    },
    "order_id": 0
  },
  {
    "order_number": 478,
    "description": "",
    "id": "d8a8b742-d766-487c-82bc-f2b70bb14c7a",
    "tool": null,
    "name": "White Hat",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd8a8b742-d766-487c-82bc-f2b70bb14c7a%2F600x600%2F31870c03-3341-414e-a412-21b42b58de34_600x600?alt=media&token=11c7b489-0bf8-435b-9482-5f760b9092ea",
        "reference": "stroke_photos/d8a8b742-d766-487c-82bc-f2b70bb14c7a/600x600/31870c03-3341-414e-a412-21b42b58de34_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd8a8b742-d766-487c-82bc-f2b70bb14c7a%2F31870c03-3341-414e-a412-21b42b58de34?alt=media&token=3dbb9f4f-67b7-467d-ac73-91d7923b1744",
        "reference": "/stroke_photos/d8a8b742-d766-487c-82bc-f2b70bb14c7a/31870c03-3341-414e-a412-21b42b58de34"
      }
    },
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "gsm": null,
      "original_size": "A4",
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "brand_model": {},
      "name": null,
      "price": null,
      "ref": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    }
  },
  {
    "order_number": 1099,
    "name": "",
    "description": "",
    "id": "d8aef74e-0985-495e-8727-c294aff40069"
  },
  {
    "tool": null,
    "order_number": 334,
    "paper": {
      "ref": "20180107",
      "gsm": "250",
      "price": null,
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "original_size": "A7",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {}
    },
    "name": "Strokie 2",
    "description": "<p>Marginally better</p>",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd8d3a503-0202-4937-9f71-92c22fae5132%2F600x600%2Ff766fe6e-923d-4da7-b5bd-137282c21e7b_600x600?alt=media&token=46dd7cdb-8fd4-4bd1-97fc-cba2d874f4fb",
        "reference": "stroke_photos/d8d3a503-0202-4937-9f71-92c22fae5132/600x600/f766fe6e-923d-4da7-b5bd-137282c21e7b_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd8d3a503-0202-4937-9f71-92c22fae5132%2Ff766fe6e-923d-4da7-b5bd-137282c21e7b?alt=media&token=77d6db48-1ccd-4c6f-bd69-205f50445022",
        "reference": "/stroke_photos/d8d3a503-0202-4937-9f71-92c22fae5132/f766fe6e-923d-4da7-b5bd-137282c21e7b"
      }
    },
    "id": "d8d3a503-0202-4937-9f71-92c22fae5132",
    "order_id": 0
  },
  {
    "description": "One more...",
    "order_number": 237,
    "name": "Musically 2",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd8e5006c-6ef3-4e46-ac22-559ae094b507%2F600x600%2F4ca80ada-0220-46b2-8489-34ed43e20266_600x600?alt=media&token=13ce687a-25c9-497e-8fae-e6e7890113c1",
        "reference": "stroke_photos/d8e5006c-6ef3-4e46-ac22-559ae094b507/600x600/4ca80ada-0220-46b2-8489-34ed43e20266_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd8e5006c-6ef3-4e46-ac22-559ae094b507%2F4ca80ada-0220-46b2-8489-34ed43e20266?alt=media&token=e96dbc3f-f982-43a0-beb5-f7f91ca4422a",
        "reference": "/stroke_photos/d8e5006c-6ef3-4e46-ac22-559ae094b507/4ca80ada-0220-46b2-8489-34ed43e20266"
      }
    },
    "id": "d8e5006c-6ef3-4e46-ac22-559ae094b507",
    "tool": null,
    "order_id": 0,
    "paper": {
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "price": 1.35,
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "ref": "77725080001",
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "gsm": "250",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      }
    }
  },
  {
    "order_number": 1046,
    "name": "",
    "description": "",
    "id": "d901a15d-7f19-43c8-86b5-d18cbae01a62"
  },
  {
    "order_id": 0,
    "name": "Small Mucho",
    "tool": null,
    "description": "",
    "id": "d95ec469-f43d-44be-91ad-4ed428f6cdbd",
    "paper": {
      "original_size": "A7",
      "price": 1.35,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "ref": "77725080001",
      "name": null,
      "gsm": "250",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      }
    },
    "order_number": 209,
    "photos": {
      "original": {
        "reference": "/stroke_photos/d95ec469-f43d-44be-91ad-4ed428f6cdbd/5ffe691c-bcc4-44e0-adfd-9531f380a7fb",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd95ec469-f43d-44be-91ad-4ed428f6cdbd%2F5ffe691c-bcc4-44e0-adfd-9531f380a7fb?alt=media&token=2a0082cc-4ea3-4ad9-8962-9d53c2e903f0"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd95ec469-f43d-44be-91ad-4ed428f6cdbd%2F600x600%2F5ffe691c-bcc4-44e0-adfd-9531f380a7fb_600x600?alt=media&token=fd1e0cd8-5ea0-4cb3-9167-11459f9ab6b8",
        "reference": "stroke_photos/d95ec469-f43d-44be-91ad-4ed428f6cdbd/600x600/5ffe691c-bcc4-44e0-adfd-9531f380a7fb_600x600"
      }
    }
  },
  {
    "order_number": 288,
    "name": "Sis",
    "paper": {
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "gsm": "250",
      "name": null,
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "ref": "20180107",
      "price": null,
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/d979551a-47c5-4ea7-80b2-4ae86107cf49/64d8d3d0-1f3b-4338-bd14-7434c0ed943a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd979551a-47c5-4ea7-80b2-4ae86107cf49%2F64d8d3d0-1f3b-4338-bd14-7434c0ed943a?alt=media&token=df30090b-063a-4b36-89fd-ceed92434cc0"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/d979551a-47c5-4ea7-80b2-4ae86107cf49/600x600/64d8d3d0-1f3b-4338-bd14-7434c0ed943a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd979551a-47c5-4ea7-80b2-4ae86107cf49%2F600x600%2F64d8d3d0-1f3b-4338-bd14-7434c0ed943a_600x600?alt=media&token=09262782-d3a0-425a-9ede-120c8e18b642"
      }
    },
    "tool": null,
    "order_id": 0,
    "description": "<p>Now their sister is from another dad</p>",
    "id": "d979551a-47c5-4ea7-80b2-4ae86107cf49"
  },
  {
    "tool": null,
    "description": "",
    "id": "d9c03396-cb8d-4bb4-8715-26adaf68ee0e",
    "order_number": 569,
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/d9c03396-cb8d-4bb4-8715-26adaf68ee0e/3b31b9ee-c937-4083-95ed-614d15c18ba2",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd9c03396-cb8d-4bb4-8715-26adaf68ee0e%2F3b31b9ee-c937-4083-95ed-614d15c18ba2?alt=media&token=f9c591f6-3197-46b4-bc88-56e6a41ee423"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/d9c03396-cb8d-4bb4-8715-26adaf68ee0e/600x600/3b31b9ee-c937-4083-95ed-614d15c18ba2_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fd9c03396-cb8d-4bb4-8715-26adaf68ee0e%2F600x600%2F3b31b9ee-c937-4083-95ed-614d15c18ba2_600x600?alt=media&token=68c75c87-b0a6-41b6-a6bc-c388eb6354c5"
      }
    },
    "paper": {
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand": {},
      "price": 0.4,
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "ref": "20170703",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "gsm": "250",
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      }
    },
    "name": "Blank"
  },
  {
    "order_number": 977,
    "name": "Blank",
    "id": "d9d72b02-c967-4ef4-8a3e-c4844129b4f8",
    "order_id": 0,
    "paper": {
      "price": 19.3,
      "name": "tinto",
      "gsm": "640",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "ref": "30075105",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "75x105cm"
    },
    "description": ""
  },
  {
    "name": "",
    "description": "",
    "order_number": 1144,
    "id": "d9d9143e-b00e-4a5b-a4da-378be074d540"
  },
  {
    "paper": {
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "original_size": "A4",
      "ref": "4005329065767",
      "name": null,
      "price": 1,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "110",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "brand_model": {
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "name": "Elephant Skin"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      }
    },
    "description": "",
    "order_number": 883,
    "order_id": 0,
    "name": "Blank",
    "id": "da5b8b90-eb51-4146-b818-674a9289026a"
  },
  {
    "id": "da803577-f928-42da-a446-c68511dc015b",
    "name": "Blank",
    "order_number": 994,
    "paper": {
      "ref": "30075105",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "brand_model": {
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "gsm": "640",
      "price": 19.3,
      "name": "tinto",
      "original_size": "75x105cm",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163"
    },
    "order_id": 0,
    "description": ""
  },
  {
    "description": "",
    "name": "",
    "order_number": 1137,
    "id": "db4d7de9-a4a6-413b-bf90-fb244b0c769a"
  },
  {
    "description": "",
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "price": null,
      "brand": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand_model": {},
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4",
      "gsm": null,
      "ref": null,
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "name": "Blank",
    "order_number": 913,
    "order_id": 0,
    "id": "db6122a6-a89b-45a0-bd8a-1ef87f5e25d1"
  },
  {
    "paper": {
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {},
      "price": null,
      "ref": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {},
      "gsm": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "name": "Blank",
    "order_number": 963,
    "id": "db7086a3-0202-40ce-9258-b50df8fc71f3",
    "order_id": 0,
    "description": ""
  },
  {
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/db8d16ea-0d27-4958-bff8-0f2452c68960/600x600/7725c451-c989-40f9-9b47-08d40bffcebd_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdb8d16ea-0d27-4958-bff8-0f2452c68960%2F600x600%2F7725c451-c989-40f9-9b47-08d40bffcebd_600x600?alt=media&token=730a78f8-c9cf-4b15-8622-d752b761f243"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdb8d16ea-0d27-4958-bff8-0f2452c68960%2F7725c451-c989-40f9-9b47-08d40bffcebd?alt=media&token=d945d04b-7399-499d-bdd0-f30e83b4076e",
        "reference": "/stroke_photos/db8d16ea-0d27-4958-bff8-0f2452c68960/7725c451-c989-40f9-9b47-08d40bffcebd"
      }
    },
    "order_number": 441,
    "name": "Golf",
    "description": "More sports",
    "order_id": 0,
    "id": "db8d16ea-0d27-4958-bff8-0f2452c68960",
    "tool": null,
    "paper": {
      "gsm": "250",
      "ref": "77725080001",
      "price": 1.35,
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "original_size": "A7",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    }
  },
  {
    "order_number": 537,
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/dbaf3e28-f592-46b8-b42e-989230b9043d/600x600/5e39f76a-4b67-4544-b2b6-cbd809af945b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdbaf3e28-f592-46b8-b42e-989230b9043d%2F600x600%2F5e39f76a-4b67-4544-b2b6-cbd809af945b_600x600?alt=media&token=a2915281-33b0-451b-b227-39e930790524"
      },
      "original": {
        "reference": "/stroke_photos/dbaf3e28-f592-46b8-b42e-989230b9043d/5e39f76a-4b67-4544-b2b6-cbd809af945b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdbaf3e28-f592-46b8-b42e-989230b9043d%2F5e39f76a-4b67-4544-b2b6-cbd809af945b?alt=media&token=8c279a86-9446-4111-961e-e4af0035d8c3"
      }
    },
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {},
      "gsm": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "brand": {},
      "ref": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "name": null,
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "original_size": "A4"
    },
    "id": "dbaf3e28-f592-46b8-b42e-989230b9043d",
    "tool": null,
    "name": "Silver-On-Black Sideways 3",
    "description": "<p>Again...</p>"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/dbe5364d-3206-4ee4-879d-006b22d8efe0/06f9952f-9257-486c-80ed-88523c65979d",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdbe5364d-3206-4ee4-879d-006b22d8efe0%2F06f9952f-9257-486c-80ed-88523c65979d?alt=media&token=5eabeb90-5bde-4528-972e-1bc7ec782f4f"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/dbe5364d-3206-4ee4-879d-006b22d8efe0/600x600/06f9952f-9257-486c-80ed-88523c65979d_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdbe5364d-3206-4ee4-879d-006b22d8efe0%2F600x600%2F06f9952f-9257-486c-80ed-88523c65979d_600x600?alt=media&token=7089da04-14f8-4466-8f16-517e2f838aba"
      }
    },
    "tool": null,
    "order_number": 214,
    "id": "dbe5364d-3206-4ee4-879d-006b22d8efe0",
    "order_id": 0,
    "description": "Still having that unwanted small but at the end",
    "name": "Bigger But or Smaller Body",
    "paper": {
      "original_size": "A7",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "price": 1.35,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "ref": "77725080001",
      "gsm": "250",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    }
  },
  {
    "name": "",
    "order_number": 1143,
    "description": "",
    "id": "dc116de7-67fe-4a18-a943-6f2a8520604f"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1193,
    "id": "dc18664f-cab2-4acc-80d8-67fd1a796ec4"
  },
  {
    "description": "",
    "id": "dc7908f9-fda7-4932-a85d-50f923d2f750",
    "order_number": 837,
    "order_id": 0,
    "paper": {
      "brand": {},
      "original_size": "A7",
      "brand_model": {},
      "price": null,
      "name": null,
      "gsm": "250",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "70025010030",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      }
    },
    "name": "Blank"
  },
  {
    "description": "",
    "order_number": 1121,
    "name": "",
    "id": "dcb0ec12-9cb9-4985-a79c-0c07d2062f80"
  },
  {
    "id": "dcc17a95-9a70-4dfc-8935-ec5795011e7a",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdcc17a95-9a70-4dfc-8935-ec5795011e7a%2F5e1d27ba-abd6-4947-8f5f-2b2610df12e6?alt=media&token=a7bb704c-ee2f-4c3e-aab5-754c4f5bfcf9",
        "reference": "/stroke_photos/dcc17a95-9a70-4dfc-8935-ec5795011e7a/5e1d27ba-abd6-4947-8f5f-2b2610df12e6"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdcc17a95-9a70-4dfc-8935-ec5795011e7a%2F600x600%2F5e1d27ba-abd6-4947-8f5f-2b2610df12e6_600x600?alt=media&token=05b7de6f-9af8-4400-bcb8-09857b0474d2",
        "reference": "stroke_photos/dcc17a95-9a70-4dfc-8935-ec5795011e7a/600x600/5e1d27ba-abd6-4947-8f5f-2b2610df12e6_600x600"
      }
    },
    "order_number": 734,
    "description": "",
    "paper": {
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "price": 0.4,
      "gsm": "250",
      "name": null,
      "ref": "20170703",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "order_id": 0,
    "name": "Blank",
    "tool": null
  },
  {
    "name": "Silver-On-Black Regular 6",
    "description": "<p>A bit more paint to see how the silver would look</p>",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/dcedf4cc-7ec0-42df-91f0-0acf20ea88ba/600x600/7733a5ec-db14-4cef-9220-c8068e3a492a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdcedf4cc-7ec0-42df-91f0-0acf20ea88ba%2F600x600%2F7733a5ec-db14-4cef-9220-c8068e3a492a_600x600?alt=media&token=ef2436fb-9a99-44bc-9628-40eda436d593"
      },
      "original": {
        "reference": "/stroke_photos/dcedf4cc-7ec0-42df-91f0-0acf20ea88ba/7733a5ec-db14-4cef-9220-c8068e3a492a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdcedf4cc-7ec0-42df-91f0-0acf20ea88ba%2F7733a5ec-db14-4cef-9220-c8068e3a492a?alt=media&token=2cb69301-7f16-4081-b3fe-e0bb51b5aa1c"
      }
    },
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "name": null,
      "gsm": null,
      "brand_model": {},
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "ref": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    },
    "order_number": 528,
    "tool": null,
    "id": "dcedf4cc-7ec0-42df-91f0-0acf20ea88ba"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1207,
    "id": "dd02bda6-8ad9-4669-bb87-bc9d67e2ac13"
  },
  {
    "order_id": 0,
    "id": "dd5c76bd-1593-4403-8094-8ee7ef0a5afd",
    "paper": {
      "original_size": "A7",
      "price": null,
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "ref": "20180107",
      "gsm": "250",
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "order_number": 370,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/dd5c76bd-1593-4403-8094-8ee7ef0a5afd/600x600/7befad9f-7da1-4284-a941-cae2a54bc2cf_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdd5c76bd-1593-4403-8094-8ee7ef0a5afd%2F600x600%2F7befad9f-7da1-4284-a941-cae2a54bc2cf_600x600?alt=media&token=8f1dde5c-d3e7-40d1-af6f-5980db7af46c"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdd5c76bd-1593-4403-8094-8ee7ef0a5afd%2F7befad9f-7da1-4284-a941-cae2a54bc2cf?alt=media&token=3b496f0e-fe72-46e7-834e-3ad31e1d6978",
        "reference": "/stroke_photos/dd5c76bd-1593-4403-8094-8ee7ef0a5afd/7befad9f-7da1-4284-a941-cae2a54bc2cf"
      }
    },
    "description": "<p>It is interesting how the paint skipped the paper due to it's driness maybe</p>",
    "name": "Curious",
    "tool": null
  },
  {
    "description": "",
    "order_number": 1171,
    "name": "",
    "id": "dd6c9aa8-0754-4ad7-bf3d-c4b1df239250"
  },
  {
    "name": "",
    "order_number": 1039,
    "description": "",
    "id": "ddcd7cfe-5beb-46bc-8b11-e03f4d71c77c"
  },
  {
    "order_number": 1143,
    "name": "",
    "description": "",
    "id": "de21bb7a-d7b3-4176-99be-221af1843fbd"
  },
  {
    "id": "de257051-2587-473b-8a0a-fe48ef304089",
    "paper": {
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "gsm": "640",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "original_size": "75x105cm",
      "ref": "30075105",
      "name": "tinto",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "price": 19.3
    },
    "description": "",
    "order_id": 0,
    "name": "Blank",
    "order_number": 985
  },
  {
    "paper": {
      "gsm": null,
      "brand_model": {},
      "price": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A4",
      "brand": {},
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "ref": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "order_id": 0,
    "description": "",
    "id": "de5e591d-7e4f-44bd-a05d-cc4fa2a7beb4",
    "name": "Blank",
    "order_number": 958
  },
  {
    "order_number": 1045,
    "name": "",
    "description": "",
    "id": "de6cdcdf-3ea0-44d8-a47d-1eb6d6c43224"
  },
  {
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdee9af3b-3ae0-42ef-b377-c9c299eecc56%2F600x600%2F2158684e-45fa-414e-8921-4973dd827978_600x600?alt=media&token=98482660-bb3b-4f84-a352-f427de43d384",
        "reference": "stroke_photos/dee9af3b-3ae0-42ef-b377-c9c299eecc56/600x600/2158684e-45fa-414e-8921-4973dd827978_600x600"
      },
      "original": {
        "reference": "/stroke_photos/dee9af3b-3ae0-42ef-b377-c9c299eecc56/2158684e-45fa-414e-8921-4973dd827978",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdee9af3b-3ae0-42ef-b377-c9c299eecc56%2F2158684e-45fa-414e-8921-4973dd827978?alt=media&token=73195ba9-2f6a-4cd9-92fc-e0c3a6df9aae"
      }
    },
    "description": "",
    "tool": null,
    "order_number": 601,
    "paper": {
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "70025010030",
      "name": null,
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "original_size": "A7",
      "brand": {},
      "price": null,
      "brand_model": {},
      "gsm": "250",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "id": "dee9af3b-3ae0-42ef-b377-c9c299eecc56",
    "order_id": 0
  },
  {
    "id": "def37c07-e84a-4ff1-9ded-eede50b87754",
    "name": "Blank",
    "paper": {
      "original_size": "A7",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "gsm": "250",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "ref": "77725080001",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso"
      },
      "price": 1.35,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null
    },
    "description": "",
    "order_number": 183,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/def37c07-e84a-4ff1-9ded-eede50b87754/600x600/a72279a3-25c6-45c2-99b1-1895c5aab8dd_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdef37c07-e84a-4ff1-9ded-eede50b87754%2F600x600%2Fa72279a3-25c6-45c2-99b1-1895c5aab8dd_600x600?alt=media&token=5fd34e83-23d6-49d7-a8a9-e2bf889c37b0"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdef37c07-e84a-4ff1-9ded-eede50b87754%2Fa72279a3-25c6-45c2-99b1-1895c5aab8dd?alt=media&token=f718748d-b6bc-40bf-b74d-741dce52bb6b",
        "reference": "/stroke_photos/def37c07-e84a-4ff1-9ded-eede50b87754/a72279a3-25c6-45c2-99b1-1895c5aab8dd"
      }
    },
    "tool": null,
    "order_id": 0
  },
  {
    "description": "",
    "paper": {
      "ref": "4005329065767",
      "original_size": "A4",
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "price": 1,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "brand_model": {
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2"
      },
      "gsm": "110"
    },
    "name": "Blank",
    "id": "df129bf7-21ce-4d5a-a29b-bcc6526040b3",
    "order_id": 0,
    "order_number": 881
  },
  {
    "order_number": 1064,
    "description": "",
    "name": "",
    "id": "df77b80a-f115-4bb5-b8ad-105134a184f6"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1200,
    "id": "df9a08e3-07ae-4f49-b1ae-3091d792b46a"
  },
  {
    "paper": {
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "name": null,
      "gsm": "110",
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "price": 1,
      "original_size": "A4",
      "ref": "4005329065767",
      "brand_model": {
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "name": "Elephant Skin"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "order_number": 860,
    "order_id": 0,
    "id": "dfd95c13-58b2-4a10-b683-b146b3a094af",
    "name": "Blank",
    "description": ""
  },
  {
    "order_number": 1151,
    "description": "",
    "name": "",
    "id": "dfe6a8ee-72cf-4c94-8f15-3ead70ea7564"
  },
  {
    "id": "dfe87c5f-fc2e-4c02-a8c1-4efddf82356f",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdfe87c5f-fc2e-4c02-a8c1-4efddf82356f%2F600x600%2Fdc5b10d0-09e6-4b4a-9412-f475ec15505a_600x600?alt=media&token=f2a77584-631a-4710-a13b-b6406cef415c",
        "reference": "stroke_photos/dfe87c5f-fc2e-4c02-a8c1-4efddf82356f/600x600/dc5b10d0-09e6-4b4a-9412-f475ec15505a_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fdfe87c5f-fc2e-4c02-a8c1-4efddf82356f%2Fdc5b10d0-09e6-4b4a-9412-f475ec15505a?alt=media&token=4d237bc7-65a7-4162-bc17-3ecc74057e18",
        "reference": "/stroke_photos/dfe87c5f-fc2e-4c02-a8c1-4efddf82356f/dc5b10d0-09e6-4b4a-9412-f475ec15505a"
      }
    },
    "order_number": 373,
    "name": "Not As Good As Previous",
    "description": "<p>But still not bad</p>",
    "order_id": 0,
    "tool": null,
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "price": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "brand": {},
      "name": null
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1182,
    "id": "e04f4545-a49a-44ec-b008-e4a02d4297d2"
  },
  {
    "tool": null,
    "order_number": 154,
    "name": "Blank",
    "paper": {
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "ref": "77725080001",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "gsm": "250",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "original_size": "A7",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "price": 1.35
    },
    "order_id": 0,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe08b0c03-113d-4b06-9d32-027d91e1b84d%2F600x600%2F56346d82-0d2d-428f-84e8-0baab3ed9607_600x600?alt=media&token=5349050e-3031-402e-a946-945d58279a58",
        "reference": "stroke_photos/e08b0c03-113d-4b06-9d32-027d91e1b84d/600x600/56346d82-0d2d-428f-84e8-0baab3ed9607_600x600"
      },
      "original": {
        "reference": "/stroke_photos/e08b0c03-113d-4b06-9d32-027d91e1b84d/56346d82-0d2d-428f-84e8-0baab3ed9607",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe08b0c03-113d-4b06-9d32-027d91e1b84d%2F56346d82-0d2d-428f-84e8-0baab3ed9607?alt=media&token=4f52f285-ef87-48d6-aa17-9c6c8c8e5181"
      }
    },
    "id": "e08b0c03-113d-4b06-9d32-027d91e1b84d"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1070,
    "id": "e0b8594f-41b7-4bc0-862e-09cfeeeeae9f"
  },
  {
    "order_number": 1139,
    "name": "",
    "description": "",
    "id": "e0f9bd36-4dcc-44e1-af53-27adc3bd5b57"
  },
  {
    "description": "",
    "order_number": 1042,
    "name": "",
    "id": "e1020574-7f62-4387-bcaa-83b01445df22"
  },
  {
    "id": "e1709661-b56d-4a1d-a2be-a50684672786",
    "paper": {
      "ref": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A4",
      "brand": {}
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/e1709661-b56d-4a1d-a2be-a50684672786/fdb2a6a4-0cef-49ab-9d41-19cd6a5d7554",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe1709661-b56d-4a1d-a2be-a50684672786%2Ffdb2a6a4-0cef-49ab-9d41-19cd6a5d7554?alt=media&token=72bd6b52-aec3-46b1-bb5a-375665c09537"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe1709661-b56d-4a1d-a2be-a50684672786%2F600x600%2Ffdb2a6a4-0cef-49ab-9d41-19cd6a5d7554_600x600?alt=media&token=6bcf6e80-1ad4-4b0f-8564-2f8e3e85c61f",
        "reference": "stroke_photos/e1709661-b56d-4a1d-a2be-a50684672786/600x600/fdb2a6a4-0cef-49ab-9d41-19cd6a5d7554_600x600"
      }
    },
    "description": "<p>Not very good, but upside-downs are not my forte...</p>",
    "name": "Upside White Stroke",
    "order_id": 0,
    "order_number": 482,
    "tool": null
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/e177f9b5-e3b7-4682-9eb1-6ac235c1371f/673bb89a-e6ed-4aad-a146-90a334344902",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe177f9b5-e3b7-4682-9eb1-6ac235c1371f%2F673bb89a-e6ed-4aad-a146-90a334344902?alt=media&token=686f64df-71cd-4701-856a-c9abb418baec"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe177f9b5-e3b7-4682-9eb1-6ac235c1371f%2F600x600%2F673bb89a-e6ed-4aad-a146-90a334344902_600x600?alt=media&token=499a9e8e-a9c4-4e42-b07c-f94d6133a3b2",
        "reference": "stroke_photos/e177f9b5-e3b7-4682-9eb1-6ac235c1371f/600x600/673bb89a-e6ed-4aad-a146-90a334344902_600x600"
      }
    },
    "tool": null,
    "description": "<p>No like the two endings. Switching to something else</p>",
    "id": "e177f9b5-e3b7-4682-9eb1-6ac235c1371f",
    "name": "Circliness 2",
    "paper": {
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "ref": "20180107",
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null,
      "brand": {},
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "original_size": "A7",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86"
    },
    "order_number": 340,
    "order_id": 0
  },
  {
    "id": "e18a10e1-76aa-47d1-bc04-a56bbb48e4fa",
    "order_number": 305,
    "tool": null,
    "name": "Unwilling Variability",
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/e18a10e1-76aa-47d1-bc04-a56bbb48e4fa/0161650f-bbae-485f-9f08-ce7c9bb39990",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe18a10e1-76aa-47d1-bc04-a56bbb48e4fa%2F0161650f-bbae-485f-9f08-ce7c9bb39990?alt=media&token=44192f51-da92-440e-ada1-5cbf66a77c80"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe18a10e1-76aa-47d1-bc04-a56bbb48e4fa%2F600x600%2F0161650f-bbae-485f-9f08-ce7c9bb39990_600x600?alt=media&token=38b7ebce-e191-40b8-8f68-fc3953b8c3b5",
        "reference": "stroke_photos/e18a10e1-76aa-47d1-bc04-a56bbb48e4fa/600x600/0161650f-bbae-485f-9f08-ce7c9bb39990_600x600"
      }
    },
    "description": "<p>Jesus</p>",
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "name": null,
      "ref": "20180107",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "original_size": "A7"
    }
  },
  {
    "description": "",
    "name": "",
    "order_number": 1183,
    "id": "e19d1fab-47ea-495c-9309-0a6f2e805e0f"
  },
  {
    "name": "Popf Vertical More Paint",
    "description": "",
    "paper": {
      "original_size": "A4",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": "",
      "ref": "44202129",
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {},
      "gsm": "200",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "price": null,
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      }
    },
    "id": "e1ebb0f1-7bbb-4967-9c89-79e13e531806",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/e1ebb0f1-7bbb-4967-9c89-79e13e531806/600x600/88beae85-2654-4627-b2c1-b023e55e3b52_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe1ebb0f1-7bbb-4967-9c89-79e13e531806%2F600x600%2F88beae85-2654-4627-b2c1-b023e55e3b52_600x600?alt=media&token=3fc478be-d728-435d-bd5c-a1844b7fb02d"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe1ebb0f1-7bbb-4967-9c89-79e13e531806%2F88beae85-2654-4627-b2c1-b023e55e3b52?alt=media&token=82658c47-cbad-4333-bb8b-4c12934bad7c",
        "reference": "/stroke_photos/e1ebb0f1-7bbb-4967-9c89-79e13e531806/88beae85-2654-4627-b2c1-b023e55e3b52"
      }
    },
    "order_id": 0,
    "order_number": 24,
    "tool": {}
  },
  {
    "description": "",
    "name": "Blank",
    "paper": {
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "ref": "30075105",
      "price": 19.3,
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "original_size": "75x105cm",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "gsm": "640",
      "name": "tinto",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      }
    },
    "order_id": 0,
    "order_number": 987,
    "id": "e216397f-32be-45d5-a445-3b47df6ebf1c"
  },
  {
    "paper": {
      "price": 1,
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      },
      "name": null,
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "name": "Elephant Skin",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        }
      },
      "ref": "4005329065767",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "gsm": "110",
      "original_size": "A4"
    },
    "order_id": 0,
    "order_number": 873,
    "description": "",
    "id": "e256e52b-82c0-4bf3-975d-769870f196bb",
    "name": "Blank"
  },
  {
    "tool": null,
    "name": "White Splodge Touch 7",
    "order_id": 0,
    "id": "e2975336-2a74-495a-83ac-095ab12e22e6",
    "paper": {
      "brand": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "gsm": null,
      "name": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": null,
      "price": null,
      "original_size": "A4"
    },
    "description": "<p>even less paint than less paint</p>",
    "order_number": 517,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/e2975336-2a74-495a-83ac-095ab12e22e6/600x600/7d0f6a3b-8b40-44ba-beab-a32d47b80e92_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe2975336-2a74-495a-83ac-095ab12e22e6%2F600x600%2F7d0f6a3b-8b40-44ba-beab-a32d47b80e92_600x600?alt=media&token=6bf4e294-e282-4db6-bc37-72a731f38a6f"
      },
      "original": {
        "reference": "/stroke_photos/e2975336-2a74-495a-83ac-095ab12e22e6/7d0f6a3b-8b40-44ba-beab-a32d47b80e92",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe2975336-2a74-495a-83ac-095ab12e22e6%2F7d0f6a3b-8b40-44ba-beab-a32d47b80e92?alt=media&token=3962a7ed-dc5e-4fa2-9ac3-e48c51ccd5ac"
      }
    }
  },
  {
    "order_number": 1186,
    "name": "",
    "description": "",
    "id": "e2a0eb78-98d7-4fb0-b48d-ef14688be040"
  },
  {
    "id": "e2bba0fa-8487-4a5b-ab51-2ed87d1b0d39",
    "paper": {
      "name": "",
      "original_size": "A4",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "price": null,
      "brand_model": {
        "name": "Accademia Designo",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "ref": "44202129",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "200",
      "paper_surface": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      }
    },
    "order_number": 81,
    "order_id": 0,
    "description": "",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe2bba0fa-8487-4a5b-ab51-2ed87d1b0d39%2Fc400a03e-6c06-4d96-8143-89cbf9ca63aa?alt=media&token=476f83e7-d743-4ff2-9437-d02acabba32a",
        "reference": "/stroke_photos/e2bba0fa-8487-4a5b-ab51-2ed87d1b0d39/c400a03e-6c06-4d96-8143-89cbf9ca63aa"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe2bba0fa-8487-4a5b-ab51-2ed87d1b0d39%2F600x600%2Fc400a03e-6c06-4d96-8143-89cbf9ca63aa_600x600?alt=media&token=a532debb-f2e9-4c63-9c05-dec169709295",
        "reference": "stroke_photos/e2bba0fa-8487-4a5b-ab51-2ed87d1b0d39/600x600/c400a03e-6c06-4d96-8143-89cbf9ca63aa_600x600"
      }
    },
    "name": "Terrible Arc",
    "tool": null
  },
  {
    "order_id": 0,
    "name": "Pfsmpsitb No Reload",
    "paper": {
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "gsm": "200",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "paper_surface": {},
      "ref": "44202129",
      "name": "",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "original_size": "A4",
      "price": null
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/e2d25b16-69cc-40a9-86f4-476f32865e18/f17cd7ae-ccac-403f-8936-524d15799b4f",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe2d25b16-69cc-40a9-86f4-476f32865e18%2Ff17cd7ae-ccac-403f-8936-524d15799b4f?alt=media&token=dcf86777-3d27-451c-8784-1f3e266622fe"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe2d25b16-69cc-40a9-86f4-476f32865e18%2F600x600%2Ff17cd7ae-ccac-403f-8936-524d15799b4f_600x600?alt=media&token=41e6105b-eb03-4124-ae89-eec71d8a631e",
        "reference": "stroke_photos/e2d25b16-69cc-40a9-86f4-476f32865e18/600x600/f17cd7ae-ccac-403f-8936-524d15799b4f_600x600"
      }
    },
    "id": "e2d25b16-69cc-40a9-86f4-476f32865e18",
    "order_number": 44,
    "tool": null,
    "description": ""
  },
  {
    "description": "dd",
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "name": "",
      "original_size": "A4",
      "ref": "44202129",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "price": null,
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "gsm": "200",
      "paper_surface": {},
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4"
    },
    "tool": {},
    "order_id": 0,
    "id": "e2dddfc2-6e67-4cee-9731-53cd33bac56f",
    "name": "Less Curve",
    "order_number": 6,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe2dddfc2-6e67-4cee-9731-53cd33bac56f%2Fc89880a5-e287-4345-896a-4c66dd049b41?alt=media&token=154597f2-7142-4d9f-8b77-61ed5d6e0488",
        "reference": "/stroke_photos/e2dddfc2-6e67-4cee-9731-53cd33bac56f/c89880a5-e287-4345-896a-4c66dd049b41"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/e2dddfc2-6e67-4cee-9731-53cd33bac56f/600x600/c89880a5-e287-4345-896a-4c66dd049b41_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe2dddfc2-6e67-4cee-9731-53cd33bac56f%2F600x600%2Fc89880a5-e287-4345-896a-4c66dd049b41_600x600?alt=media&token=2a97c356-d3dd-49a6-9c6a-43d9568befc1"
      }
    }
  },
  {
    "name": "",
    "order_number": 1198,
    "description": "",
    "id": "e2e69b2f-9d6b-4dd3-8199-5d446d39a324"
  },
  {
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/e2f8dc55-37a2-4b78-b717-fbbc5f954497/600x600/bbba79e3-5d40-4665-93b0-e65311b954eb_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe2f8dc55-37a2-4b78-b717-fbbc5f954497%2F600x600%2Fbbba79e3-5d40-4665-93b0-e65311b954eb_600x600?alt=media&token=efb79d48-b8cd-4de6-93e2-f1b958648da5"
      },
      "original": {
        "reference": "/stroke_photos/e2f8dc55-37a2-4b78-b717-fbbc5f954497/bbba79e3-5d40-4665-93b0-e65311b954eb",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe2f8dc55-37a2-4b78-b717-fbbc5f954497%2Fbbba79e3-5d40-4665-93b0-e65311b954eb?alt=media&token=250373e4-b7fc-4c41-9a37-5bbd673786c4"
      }
    },
    "order_number": 499,
    "description": "<p>I got the Zig-Zag, but lost the arc and lost the mosaic effect somehow.</p>",
    "order_id": 0,
    "id": "e2f8dc55-37a2-4b78-b717-fbbc5f954497",
    "paper": {
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "gsm": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "brand_model": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "original_size": "A4",
      "price": null,
      "ref": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b"
    },
    "name": "Zig-Zag White"
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/e326bb65-3cf2-4088-9e7c-85b59a77d1c1/5ed4b898-66a5-42d6-b291-f69425b87323",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe326bb65-3cf2-4088-9e7c-85b59a77d1c1%2F5ed4b898-66a5-42d6-b291-f69425b87323?alt=media&token=145a4f0d-f97d-48c9-9f20-739b689e321e"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe326bb65-3cf2-4088-9e7c-85b59a77d1c1%2F600x600%2F5ed4b898-66a5-42d6-b291-f69425b87323_600x600?alt=media&token=a708e946-282a-49f2-9bb8-3e55ce041ee5",
        "reference": "stroke_photos/e326bb65-3cf2-4088-9e7c-85b59a77d1c1/600x600/5ed4b898-66a5-42d6-b291-f69425b87323_600x600"
      }
    },
    "order_number": 496,
    "order_id": 0,
    "tool": null,
    "description": "<p>Yeah, very pleasant. Hmm.</p>",
    "paper": {
      "brand": {},
      "gsm": null,
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "ref": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "original_size": "A4",
      "brand_model": {},
      "price": null
    },
    "name": "Mosaics White Betterment",
    "id": "e326bb65-3cf2-4088-9e7c-85b59a77d1c1"
  },
  {
    "order_number": 1015,
    "name": "",
    "description": "",
    "id": "e3508a7e-24c6-4fed-b2d8-93b58df269b8"
  },
  {
    "paper": {
      "ref": "44202129",
      "price": null,
      "paper_surface": {},
      "gsm": "200",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": "",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "original_size": "A4",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4"
    },
    "order_number": 98,
    "tool": null,
    "order_id": 0,
    "description": "Actually not a bad arc",
    "name": "Just Something Slow",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/e3609e8c-bc90-4e09-8808-d9e565e3e8a4/600x600/f8f9decb-a8ee-4fed-88ac-7e8309a610c7_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe3609e8c-bc90-4e09-8808-d9e565e3e8a4%2F600x600%2Ff8f9decb-a8ee-4fed-88ac-7e8309a610c7_600x600?alt=media&token=b276304a-e4c5-4258-8135-917a1561cf3e"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe3609e8c-bc90-4e09-8808-d9e565e3e8a4%2Ff8f9decb-a8ee-4fed-88ac-7e8309a610c7?alt=media&token=a0ba6739-e7ad-447a-902a-96741807444b",
        "reference": "/stroke_photos/e3609e8c-bc90-4e09-8808-d9e565e3e8a4/f8f9decb-a8ee-4fed-88ac-7e8309a610c7"
      }
    },
    "id": "e3609e8c-bc90-4e09-8808-d9e565e3e8a4"
  },
  {
    "paper": {
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "brand_model": {},
      "brand": {},
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "ref": "70025010030",
      "price": null,
      "name": null,
      "gsm": "250"
    },
    "name": "Blank",
    "tool": null,
    "order_number": 609,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe36133e5-adee-4f0a-9589-53bf3c41c9b2%2F600x600%2Fa5754621-20db-4628-83fb-cd4da93ec170_600x600?alt=media&token=07f4c686-09dd-4015-8323-16b1fa77916e",
        "reference": "stroke_photos/e36133e5-adee-4f0a-9589-53bf3c41c9b2/600x600/a5754621-20db-4628-83fb-cd4da93ec170_600x600"
      },
      "original": {
        "reference": "/stroke_photos/e36133e5-adee-4f0a-9589-53bf3c41c9b2/a5754621-20db-4628-83fb-cd4da93ec170",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe36133e5-adee-4f0a-9589-53bf3c41c9b2%2Fa5754621-20db-4628-83fb-cd4da93ec170?alt=media&token=0ef4ab1a-10d9-45dc-ad4d-a92dd6a32756"
      }
    },
    "order_id": 0,
    "id": "e36133e5-adee-4f0a-9589-53bf3c41c9b2",
    "description": ""
  },
  {
    "paper": {
      "original_size": "A4",
      "price": null,
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "ref": "44202129",
      "gsm": "200",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "paper_surface": {},
      "name": ""
    },
    "order_id": 0,
    "order_number": 83,
    "tool": null,
    "name": "The Belly",
    "id": "e386c868-008f-4029-8521-198a620b0c14",
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/e386c868-008f-4029-8521-198a620b0c14/600x600/c9b841f5-883e-4cf2-b567-9261e95b9b93_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe386c868-008f-4029-8521-198a620b0c14%2F600x600%2Fc9b841f5-883e-4cf2-b567-9261e95b9b93_600x600?alt=media&token=d0a02c1a-128d-472f-85b8-55b0612ee7e5"
      },
      "original": {
        "reference": "/stroke_photos/e386c868-008f-4029-8521-198a620b0c14/c9b841f5-883e-4cf2-b567-9261e95b9b93",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe386c868-008f-4029-8521-198a620b0c14%2Fc9b841f5-883e-4cf2-b567-9261e95b9b93?alt=media&token=c482e17c-daa3-476e-9029-2005b9fd2ca8"
      }
    }
  },
  {
    "tool": null,
    "order_id": 0,
    "description": "",
    "order_number": 210,
    "paper": {
      "name": null,
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "price": 1.35,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "original_size": "A7",
      "ref": "77725080001",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      }
    },
    "name": "Large Less Mucho",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/e392a4d7-f8f0-4229-b54e-6c72afc20eed/600x600/459d0d91-e9b1-4824-a042-3bb496b915f6_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe392a4d7-f8f0-4229-b54e-6c72afc20eed%2F600x600%2F459d0d91-e9b1-4824-a042-3bb496b915f6_600x600?alt=media&token=21bb27bd-699e-4936-bc6c-bc6f9be58cd4"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe392a4d7-f8f0-4229-b54e-6c72afc20eed%2F459d0d91-e9b1-4824-a042-3bb496b915f6?alt=media&token=62935104-ba60-4601-ad6b-0276c8fe7227",
        "reference": "/stroke_photos/e392a4d7-f8f0-4229-b54e-6c72afc20eed/459d0d91-e9b1-4824-a042-3bb496b915f6"
      }
    },
    "id": "e392a4d7-f8f0-4229-b54e-6c72afc20eed"
  },
  {
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/e3b18827-bf12-4d54-9e16-257690829e46/600x600/d855ee00-4a84-4123-af25-00c2bb39dfff_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe3b18827-bf12-4d54-9e16-257690829e46%2F600x600%2Fd855ee00-4a84-4123-af25-00c2bb39dfff_600x600?alt=media&token=9b57bfdf-dd57-4081-aae5-d3e0f22ba14e"
      },
      "original": {
        "reference": "/stroke_photos/e3b18827-bf12-4d54-9e16-257690829e46/d855ee00-4a84-4123-af25-00c2bb39dfff",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe3b18827-bf12-4d54-9e16-257690829e46%2Fd855ee00-4a84-4123-af25-00c2bb39dfff?alt=media&token=4d8c2a0a-eab4-44eb-9ff9-21a10e01f524"
      }
    },
    "order_id": 0,
    "paper": {
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": 0.4,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {},
      "gsm": "250",
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "ref": "20170703",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7"
    },
    "tool": null,
    "order_number": 714,
    "description": "",
    "id": "e3b18827-bf12-4d54-9e16-257690829e46"
  },
  {
    "description": "",
    "name": "",
    "order_number": 1179,
    "id": "e3be94e5-aed4-49b6-b160-57352a33e501"
  },
  {
    "tool": null,
    "id": "e3fd8ee3-5882-4a85-ba92-1612ae9079e9",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe3fd8ee3-5882-4a85-ba92-1612ae9079e9%2F600x600%2F5caef441-d1d7-4366-a51d-526f0918a3ab_600x600?alt=media&token=59caf229-4804-4a84-af70-5cd40384f305",
        "reference": "stroke_photos/e3fd8ee3-5882-4a85-ba92-1612ae9079e9/600x600/5caef441-d1d7-4366-a51d-526f0918a3ab_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe3fd8ee3-5882-4a85-ba92-1612ae9079e9%2F5caef441-d1d7-4366-a51d-526f0918a3ab?alt=media&token=51a152c8-efc1-41b8-ba4a-45089ffadacc",
        "reference": "/stroke_photos/e3fd8ee3-5882-4a85-ba92-1612ae9079e9/5caef441-d1d7-4366-a51d-526f0918a3ab"
      }
    },
    "paper": {
      "ref": "77725080001",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "original_size": "A7",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "price": 1.35,
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "name": null,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso"
      }
    },
    "order_number": 756,
    "order_id": 0,
    "description": "",
    "name": "Blank"
  },
  {
    "order_number": 1008,
    "description": "",
    "name": "",
    "id": "e40e3829-8e0e-40e1-9c8b-4dfa2a6f3c31"
  },
  {
    "order_number": 169,
    "name": "Blank",
    "paper": {
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "ref": "77725080001",
      "original_size": "A7",
      "price": 1.35,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "gsm": "250",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "name": null
    },
    "id": "e4215bed-c918-4468-99b1-94bf5e18e645",
    "order_id": 0,
    "description": ""
  },
  {
    "id": "e4224310-4a9e-4c72-bbe5-118b5614145f",
    "description": "",
    "name": "Blank",
    "order_number": 595,
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/e4224310-4a9e-4c72-bbe5-118b5614145f/dbd3f475-6a88-4ed9-bae6-02dc82ddc159",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe4224310-4a9e-4c72-bbe5-118b5614145f%2Fdbd3f475-6a88-4ed9-bae6-02dc82ddc159?alt=media&token=e30c21bf-eb14-402e-a43f-cadfd0b2779a"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/e4224310-4a9e-4c72-bbe5-118b5614145f/600x600/dbd3f475-6a88-4ed9-bae6-02dc82ddc159_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe4224310-4a9e-4c72-bbe5-118b5614145f%2F600x600%2Fdbd3f475-6a88-4ed9-bae6-02dc82ddc159_600x600?alt=media&token=e119d2b8-914d-4fc1-a5f9-bd1e770c8ae1"
      }
    },
    "paper": {
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {},
      "price": null,
      "name": null,
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "ref": "70025010030",
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "order_id": 0
  },
  {
    "order_number": 661,
    "order_id": 0,
    "id": "e431eb0b-cd8f-4992-8499-9a494751cba5",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe431eb0b-cd8f-4992-8499-9a494751cba5%2F600x600%2Fc526b58d-1ba5-4fa6-bec2-a2df7324ce74_600x600?alt=media&token=30c042d9-86ce-4cab-baa5-76c336d19c87",
        "reference": "stroke_photos/e431eb0b-cd8f-4992-8499-9a494751cba5/600x600/c526b58d-1ba5-4fa6-bec2-a2df7324ce74_600x600"
      },
      "original": {
        "reference": "/stroke_photos/e431eb0b-cd8f-4992-8499-9a494751cba5/c526b58d-1ba5-4fa6-bec2-a2df7324ce74",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe431eb0b-cd8f-4992-8499-9a494751cba5%2Fc526b58d-1ba5-4fa6-bec2-a2df7324ce74?alt=media&token=549269d1-1067-4d2c-b03c-77e89df804bb"
      }
    },
    "paper": {
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "original_size": "75x105cm",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "ref": "30075105",
      "name": "tinto",
      "gsm": "640",
      "price": 19.3,
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      }
    },
    "name": "Blank",
    "description": "",
    "tool": null
  },
  {
    "name": "",
    "order_number": 1224,
    "description": "",
    "id": "e47ab1a4-9159-4570-a493-9bff7d47c570"
  },
  {
    "tool": null,
    "name": "Something White",
    "order_id": 0,
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {},
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A4",
      "gsm": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "brand_model": {},
      "ref": null,
      "name": null
    },
    "order_number": 484,
    "id": "e4d5d76e-c756-49d6-be81-d24ee792a681",
    "description": "<p>Actually not bad, I like it. Will do a few more</p>",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/e4d5d76e-c756-49d6-be81-d24ee792a681/600x600/eecfeab7-120b-4f97-b1ad-4645b6ebe15f_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe4d5d76e-c756-49d6-be81-d24ee792a681%2F600x600%2Feecfeab7-120b-4f97-b1ad-4645b6ebe15f_600x600?alt=media&token=8e5f0490-ee64-46ff-af9b-7b005d90fa94"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe4d5d76e-c756-49d6-be81-d24ee792a681%2Feecfeab7-120b-4f97-b1ad-4645b6ebe15f?alt=media&token=0275f477-2b77-46e7-8564-04c622d865ef",
        "reference": "/stroke_photos/e4d5d76e-c756-49d6-be81-d24ee792a681/eecfeab7-120b-4f97-b1ad-4645b6ebe15f"
      }
    }
  },
  {
    "description": "",
    "order_number": 1097,
    "name": "",
    "id": "e52912cc-44ff-4274-8d0e-617a1c489069"
  },
  {
    "tool": null,
    "description": "<p>I have to make few of those always - the wavy hair (!) Not the best one.</p>",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe52d965a-bc02-45cb-821b-70095b5c4db1%2Fff956c05-59a6-4a44-adb5-6439a4bf954b?alt=media&token=61645ae5-0937-4b06-b361-0deb168008fc",
        "reference": "/stroke_photos/e52d965a-bc02-45cb-821b-70095b5c4db1/ff956c05-59a6-4a44-adb5-6439a4bf954b"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/e52d965a-bc02-45cb-821b-70095b5c4db1/600x600/ff956c05-59a6-4a44-adb5-6439a4bf954b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe52d965a-bc02-45cb-821b-70095b5c4db1%2F600x600%2Fff956c05-59a6-4a44-adb5-6439a4bf954b_600x600?alt=media&token=6fbcbd78-ff44-4400-98b0-805209c4b31b"
      }
    },
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "brand": {},
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {},
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "gsm": null,
      "ref": null
    },
    "id": "e52d965a-bc02-45cb-821b-70095b5c4db1",
    "order_id": 0,
    "order_number": 545,
    "name": "Silver-On-Black Variable Pressure"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1139,
    "id": "e55ec227-58cc-4258-b6e8-0813c07d0671"
  },
  {
    "order_number": 1061,
    "description": "",
    "name": "",
    "id": "e5614dbc-7ddb-488e-a791-0828962b20fa"
  },
  {
    "order_number": 1162,
    "description": "",
    "name": "",
    "id": "e570f07a-410b-410e-ae59-ef24e560e451"
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe58843f7-c095-4864-81b6-4deb027fbc6f%2F600x600%2Ffb5a9779-7c3b-447b-8714-c75b35a723ff_600x600?alt=media&token=5bb9c01e-9ff6-4618-bc48-7001faba53f3",
        "reference": "stroke_photos/e58843f7-c095-4864-81b6-4deb027fbc6f/600x600/fb5a9779-7c3b-447b-8714-c75b35a723ff_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe58843f7-c095-4864-81b6-4deb027fbc6f%2Ffb5a9779-7c3b-447b-8714-c75b35a723ff?alt=media&token=83297dcf-cc27-42e3-b43f-1947d097612e",
        "reference": "/stroke_photos/e58843f7-c095-4864-81b6-4deb027fbc6f/fb5a9779-7c3b-447b-8714-c75b35a723ff"
      }
    },
    "id": "e58843f7-c095-4864-81b6-4deb027fbc6f",
    "description": "",
    "name": "Thinning Pressure Points",
    "order_number": 133,
    "paper": {
      "original_size": "A7",
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "20180107",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": "250",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "price": null
    },
    "order_id": 0,
    "tool": null
  },
  {
    "id": "e594bcf4-1184-4100-8a53-4dd3e1e37cff",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/e594bcf4-1184-4100-8a53-4dd3e1e37cff/600x600/b53901b2-feed-491d-8e0a-be07f39dfe29_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe594bcf4-1184-4100-8a53-4dd3e1e37cff%2F600x600%2Fb53901b2-feed-491d-8e0a-be07f39dfe29_600x600?alt=media&token=0d15df24-5236-4eb7-9d58-1d87a0b0ec33"
      },
      "original": {
        "reference": "/stroke_photos/e594bcf4-1184-4100-8a53-4dd3e1e37cff/b53901b2-feed-491d-8e0a-be07f39dfe29",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe594bcf4-1184-4100-8a53-4dd3e1e37cff%2Fb53901b2-feed-491d-8e0a-be07f39dfe29?alt=media&token=32dbde0e-c531-4961-bd5c-2873664b6c67"
      }
    },
    "tool": null,
    "order_number": 179,
    "name": "Ziggy",
    "description": "",
    "paper": {
      "original_size": "A7",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "gsm": "250",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "price": 1.35,
      "ref": "77725080001"
    }
  },
  {
    "name": "Mole",
    "tool": null,
    "paper": {
      "ref": "77725080001",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "price": 1.35,
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "name": null,
      "gsm": "250",
      "original_size": "A7"
    },
    "order_id": 0,
    "description": "I don't see strokes any less black by adding water, just getting a nice flat black. No idea where that mole came from.",
    "id": "e69c8771-4034-4815-9501-36d8cd0a0bc4",
    "order_number": 416,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe69c8771-4034-4815-9501-36d8cd0a0bc4%2Fc8b1711a-5b57-4b36-beb3-5907c8875479?alt=media&token=e17fe357-8c1e-4d84-a9e8-f6b060ac9007",
        "reference": "/stroke_photos/e69c8771-4034-4815-9501-36d8cd0a0bc4/c8b1711a-5b57-4b36-beb3-5907c8875479"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/e69c8771-4034-4815-9501-36d8cd0a0bc4/600x600/c8b1711a-5b57-4b36-beb3-5907c8875479_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe69c8771-4034-4815-9501-36d8cd0a0bc4%2F600x600%2Fc8b1711a-5b57-4b36-beb3-5907c8875479_600x600?alt=media&token=2b8cb93d-0379-487d-9251-508b97106fb4"
      }
    }
  },
  {
    "name": "Blank",
    "id": "e6aaa23c-ba4c-467b-9808-cfd38b00d2b1",
    "order_id": 0,
    "order_number": 843,
    "paper": {
      "color": {
        "name": "White Pearl",
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      },
      "ref": "70025010030",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "price": null,
      "brand": {},
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {},
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "description": ""
  },
  {
    "order_number": 1140,
    "name": "",
    "description": "",
    "id": "e6b99a34-f70e-4223-b7d5-23c5c0bf323b"
  },
  {
    "name": "Musically 6",
    "id": "e6c11d02-7d79-47a9-9cd7-00f7733d1008",
    "paper": {
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso"
      },
      "original_size": "A7",
      "ref": "77725080001",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "price": 1.35,
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      }
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe6c11d02-7d79-47a9-9cd7-00f7733d1008%2F600x600%2F4f592238-e16b-4272-b1cf-2a5c5deaf4d9_600x600?alt=media&token=3b380429-bb4a-4f80-b108-baaf10a1e5c5",
        "reference": "stroke_photos/e6c11d02-7d79-47a9-9cd7-00f7733d1008/600x600/4f592238-e16b-4272-b1cf-2a5c5deaf4d9_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe6c11d02-7d79-47a9-9cd7-00f7733d1008%2F4f592238-e16b-4272-b1cf-2a5c5deaf4d9?alt=media&token=6aef770e-c5be-48cf-b526-cf2f400419b1",
        "reference": "/stroke_photos/e6c11d02-7d79-47a9-9cd7-00f7733d1008/4f592238-e16b-4272-b1cf-2a5c5deaf4d9"
      }
    },
    "order_id": 0,
    "order_number": 241,
    "description": "Nice, nice....",
    "tool": null
  },
  {
    "tool": null,
    "id": "e6ccc34a-f0af-4da8-af67-3c353f3d0c8c",
    "name": "VP Bottom  Sharps",
    "order_number": 107,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/e6ccc34a-f0af-4da8-af67-3c353f3d0c8c/600x600/f446f31b-5f1f-481c-8635-13c9c1ee60ed_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe6ccc34a-f0af-4da8-af67-3c353f3d0c8c%2F600x600%2Ff446f31b-5f1f-481c-8635-13c9c1ee60ed_600x600?alt=media&token=a8f8552f-c019-4e3f-a05a-2f5a2a6fc4a5"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe6ccc34a-f0af-4da8-af67-3c353f3d0c8c%2Ff446f31b-5f1f-481c-8635-13c9c1ee60ed?alt=media&token=38a1902e-9b39-4860-bbdb-6a7577453c1c",
        "reference": "/stroke_photos/e6ccc34a-f0af-4da8-af67-3c353f3d0c8c/f446f31b-5f1f-481c-8635-13c9c1ee60ed"
      }
    },
    "description": "",
    "paper": {
      "price": null,
      "original_size": "A7",
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "20180107",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    },
    "order_id": 0
  },
  {
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "original_size": "A4",
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "price": null,
      "ref": null,
      "gsm": null,
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {}
    },
    "name": "Silver-On-Black Regular 8",
    "tool": null,
    "order_number": 533,
    "photos": {
      "original": {
        "reference": "/stroke_photos/e6dc930b-9ee4-409a-81e7-55283b393fef/706a144f-56cf-4f36-b016-ba9e4821499c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe6dc930b-9ee4-409a-81e7-55283b393fef%2F706a144f-56cf-4f36-b016-ba9e4821499c?alt=media&token=d1009777-629d-4117-a3b2-eb005cccf094"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/e6dc930b-9ee4-409a-81e7-55283b393fef/600x600/706a144f-56cf-4f36-b016-ba9e4821499c_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe6dc930b-9ee4-409a-81e7-55283b393fef%2F600x600%2F706a144f-56cf-4f36-b016-ba9e4821499c_600x600?alt=media&token=757e7dc9-d6ac-454c-98b2-a0aff5276f4b"
      }
    },
    "id": "e6dc930b-9ee4-409a-81e7-55283b393fef",
    "description": "<p>A real truthful stroke</p>",
    "order_id": 0
  },
  {
    "description": "",
    "order_number": 1127,
    "name": "",
    "id": "e6f4d755-66a3-4d4c-b38b-68274227d38e"
  },
  {
    "order_id": 0,
    "description": "",
    "order_number": 644,
    "name": "Blank",
    "id": "e719306c-b4de-4dd4-aec2-61a2ca35d076",
    "paper": {
      "price": null,
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand": {},
      "ref": "70025010030",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "original_size": "A7",
      "brand_model": {},
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": null
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe719306c-b4de-4dd4-aec2-61a2ca35d076%2Fa8d247e3-601d-4608-8960-c6a527321be6?alt=media&token=f36408db-9a88-4e30-b727-3eda74013a89",
        "reference": "/stroke_photos/e719306c-b4de-4dd4-aec2-61a2ca35d076/a8d247e3-601d-4608-8960-c6a527321be6"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/e719306c-b4de-4dd4-aec2-61a2ca35d076/600x600/a8d247e3-601d-4608-8960-c6a527321be6_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe719306c-b4de-4dd4-aec2-61a2ca35d076%2F600x600%2Fa8d247e3-601d-4608-8960-c6a527321be6_600x600?alt=media&token=6c1efc94-1ce4-49bf-8b07-6c843316217b"
      }
    },
    "tool": null
  },
  {
    "description": "<p>Or not</p>",
    "name": "Off To Great Start",
    "photos": {
      "original": {
        "reference": "/stroke_photos/e7390507-d887-4bc8-8bd7-ee121e5266d8/34014f75-781b-47c1-b6c4-86dae9f61f54",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe7390507-d887-4bc8-8bd7-ee121e5266d8%2F34014f75-781b-47c1-b6c4-86dae9f61f54?alt=media&token=eee16e73-8f11-4f0f-8cc0-c8ac8180b9b7"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe7390507-d887-4bc8-8bd7-ee121e5266d8%2F600x600%2F34014f75-781b-47c1-b6c4-86dae9f61f54_600x600?alt=media&token=76f3f263-9b75-48e7-b6c8-243677911dc0",
        "reference": "stroke_photos/e7390507-d887-4bc8-8bd7-ee121e5266d8/600x600/34014f75-781b-47c1-b6c4-86dae9f61f54_600x600"
      }
    },
    "tool": null,
    "paper": {
      "ref": "20180107",
      "original_size": "A7",
      "gsm": "250",
      "price": null,
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      }
    },
    "order_number": 294,
    "id": "e7390507-d887-4bc8-8bd7-ee121e5266d8",
    "order_id": 0
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/e7bcee34-e96f-46e3-9f30-04c409b55e69/143b5e46-2f20-4f9f-8d25-be48e4fc5980",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe7bcee34-e96f-46e3-9f30-04c409b55e69%2F143b5e46-2f20-4f9f-8d25-be48e4fc5980?alt=media&token=0954efe5-d08d-4ec2-b9d3-e35656c749bb"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/e7bcee34-e96f-46e3-9f30-04c409b55e69/600x600/143b5e46-2f20-4f9f-8d25-be48e4fc5980_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe7bcee34-e96f-46e3-9f30-04c409b55e69%2F600x600%2F143b5e46-2f20-4f9f-8d25-be48e4fc5980_600x600?alt=media&token=2d448ff2-4160-422e-a8a3-c84b1f73ec0d"
      }
    },
    "description": "",
    "order_id": 0,
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "gsm": "250",
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "price": 0.4,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "ref": "20170703"
    },
    "tool": null,
    "name": "Blank",
    "id": "e7bcee34-e96f-46e3-9f30-04c409b55e69",
    "order_number": 733
  },
  {
    "id": "e7c7e1d4-7204-41f9-abbf-4d46bbd1bd42",
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A4",
      "ref": "4005329065767",
      "price": 1,
      "brand_model": {
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2"
      },
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "gsm": "110",
      "name": null,
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      }
    },
    "name": "Blank",
    "order_number": 875,
    "description": "",
    "order_id": 0
  },
  {
    "id": "e7f03826-32ad-4eea-a61b-380609bc910d",
    "description": "",
    "paper": {
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "original_size": "A7",
      "price": 0.4,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "gsm": "250",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "20170703",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bristol Paper"
      }
    },
    "order_id": 0,
    "name": "Blank",
    "order_number": 804
  },
  {
    "name": "Blank",
    "description": "",
    "order_number": 855,
    "paper": {
      "name": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "ref": "4005329065767",
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "name": "Elephant Skin",
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        }
      },
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "original_size": "A4",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "110",
      "price": 1,
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701"
    },
    "order_id": 0,
    "id": "e83f98a8-739f-4c63-bb9c-908bd5f5d198"
  },
  {
    "order_id": 0,
    "tool": {},
    "description": "",
    "name": "Second Resemblance",
    "photos": {
      "original": {
        "reference": "/stroke_photos/e841fa76-3d49-4bad-9cb3-550f2f1c7973/fc5bc0d8-6e91-43d5-bcfd-33fd54dbc96f",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe841fa76-3d49-4bad-9cb3-550f2f1c7973%2Ffc5bc0d8-6e91-43d5-bcfd-33fd54dbc96f?alt=media&token=7a84eb0b-66ea-46c0-bb24-fc5ab4b7674d"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/e841fa76-3d49-4bad-9cb3-550f2f1c7973/600x600/fc5bc0d8-6e91-43d5-bcfd-33fd54dbc96f_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe841fa76-3d49-4bad-9cb3-550f2f1c7973%2F600x600%2Ffc5bc0d8-6e91-43d5-bcfd-33fd54dbc96f_600x600?alt=media&token=eb1993cd-ac04-45ef-8890-7f6b34133f0c"
      }
    },
    "order_number": 15,
    "id": "e841fa76-3d49-4bad-9cb3-550f2f1c7973",
    "paper": {
      "name": "",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "brand_model": {
        "name": "Accademia Designo",
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "price": null,
      "original_size": "A4",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "gsm": "200",
      "ref": "44202129",
      "paper_surface": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    }
  },
  {
    "order_number": 703,
    "order_id": 0,
    "tool": null,
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A7",
      "gsm": "250",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "price": 0.4,
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "ref": "20170703",
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      }
    },
    "description": "",
    "name": "Blank",
    "id": "e92372e4-5db9-4d9d-84c5-3034ad137149",
    "photos": {
      "original": {
        "reference": "/stroke_photos/e92372e4-5db9-4d9d-84c5-3034ad137149/76cd3c67-61a2-45de-a587-bd82fc95330d",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe92372e4-5db9-4d9d-84c5-3034ad137149%2F76cd3c67-61a2-45de-a587-bd82fc95330d?alt=media&token=9c099deb-811b-406d-b4cd-3edebd5d544c"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe92372e4-5db9-4d9d-84c5-3034ad137149%2F600x600%2F76cd3c67-61a2-45de-a587-bd82fc95330d_600x600?alt=media&token=0542fae4-581d-4e00-849b-d626831fedc1",
        "reference": "stroke_photos/e92372e4-5db9-4d9d-84c5-3034ad137149/600x600/76cd3c67-61a2-45de-a587-bd82fc95330d_600x600"
      }
    }
  },
  {
    "paper": {
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "original_size": "A7",
      "price": 1.35,
      "ref": "77725080001",
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "gsm": "250"
    },
    "name": "Blank",
    "id": "e96368ca-beb6-4156-b0a5-49844e3fbfd1",
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/e96368ca-beb6-4156-b0a5-49844e3fbfd1/5237cd72-749a-420f-9396-7a0e194146ce",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe96368ca-beb6-4156-b0a5-49844e3fbfd1%2F5237cd72-749a-420f-9396-7a0e194146ce?alt=media&token=4c291500-3c3b-4ca3-a211-c68a9dac5e0e"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/e96368ca-beb6-4156-b0a5-49844e3fbfd1/600x600/5237cd72-749a-420f-9396-7a0e194146ce_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fe96368ca-beb6-4156-b0a5-49844e3fbfd1%2F600x600%2F5237cd72-749a-420f-9396-7a0e194146ce_600x600?alt=media&token=1006f224-0cae-4e47-8a03-ba81588a5016"
      }
    },
    "order_id": 0,
    "tool": null,
    "order_number": 190
  },
  {
    "order_number": 1127,
    "description": "",
    "name": "",
    "id": "e9856778-5db9-484e-898b-dfff2a408012"
  },
  {
    "description": "",
    "order_number": 1131,
    "name": "",
    "id": "ea126bc2-0d10-4fa4-ba3b-eec195ff242a"
  },
  {
    "id": "ea20b4a4-6219-419e-b8ce-c284fee32cd0",
    "paper": {
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "name": null,
      "gsm": "250",
      "ref": "70025010030",
      "brand_model": {},
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      },
      "price": null,
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "order_id": 0,
    "name": "Blank",
    "order_number": 844,
    "description": ""
  },
  {
    "name": "",
    "order_number": 1138,
    "description": "",
    "id": "eaa0989a-0066-448c-ae4c-6a8b53a67726"
  },
  {
    "name": "",
    "description": "",
    "order_number": 1057,
    "id": "eac737f3-c3dd-461e-aefb-54d3c8ddaaca"
  },
  {
    "paper": {
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "original_size": "A7",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "gsm": "250",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "price": 1.35,
      "ref": "77725080001",
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      }
    },
    "order_id": 0,
    "id": "ead6576d-16a4-47f4-b0a3-4a0ad3316dd9",
    "name": "Press Less, Smooth More",
    "order_number": 199,
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fead6576d-16a4-47f4-b0a3-4a0ad3316dd9%2F600x600%2F94f2e2df-f705-4dc2-bdd0-98942f2a81aa_600x600?alt=media&token=81445ae4-39a7-47bd-9f57-c046b14448f1",
        "reference": "stroke_photos/ead6576d-16a4-47f4-b0a3-4a0ad3316dd9/600x600/94f2e2df-f705-4dc2-bdd0-98942f2a81aa_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fead6576d-16a4-47f4-b0a3-4a0ad3316dd9%2F94f2e2df-f705-4dc2-bdd0-98942f2a81aa?alt=media&token=39d239ee-4ca7-42c9-b337-f30efa5eb994",
        "reference": "/stroke_photos/ead6576d-16a4-47f4-b0a3-4a0ad3316dd9/94f2e2df-f705-4dc2-bdd0-98942f2a81aa"
      }
    },
    "description": ""
  },
  {
    "name": "",
    "order_number": 1137,
    "description": "",
    "id": "eae7bc43-a214-4c01-8c7c-1c58d502b5a6"
  },
  {
    "description": "",
    "order_number": 1219,
    "name": "",
    "id": "eb05dcda-5d3d-4920-8427-ad98171f6a48"
  },
  {
    "order_number": 796,
    "tool": null,
    "name": "Blank",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Feb26c28d-b2d5-49a7-b47e-0c45cb9de64a%2F2c44829e-852d-4a18-9173-50bd6b99b711?alt=media&token=4eed5de9-8f89-49d5-82a8-fcbada8a4e8c",
        "reference": "/stroke_photos/eb26c28d-b2d5-49a7-b47e-0c45cb9de64a/2c44829e-852d-4a18-9173-50bd6b99b711"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/eb26c28d-b2d5-49a7-b47e-0c45cb9de64a/600x600/2c44829e-852d-4a18-9173-50bd6b99b711_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Feb26c28d-b2d5-49a7-b47e-0c45cb9de64a%2F600x600%2F2c44829e-852d-4a18-9173-50bd6b99b711_600x600?alt=media&token=5bab3771-5193-4ea3-b2b4-81ee31261732"
      }
    },
    "order_id": 0,
    "id": "eb26c28d-b2d5-49a7-b47e-0c45cb9de64a",
    "paper": {
      "gsm": "250",
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "name": null,
      "price": 1.35,
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "ref": "77725080001",
      "original_size": "A7",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    },
    "description": ""
  },
  {
    "id": "eb62b253-cfdb-4c1b-be51-1398761763ed",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/eb62b253-cfdb-4c1b-be51-1398761763ed/600x600/6a8bfc99-7cd4-4291-9bb6-af373d90610e_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Feb62b253-cfdb-4c1b-be51-1398761763ed%2F600x600%2F6a8bfc99-7cd4-4291-9bb6-af373d90610e_600x600?alt=media&token=cd54eba9-1161-4ad6-aa21-74a5af4c5a3d"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Feb62b253-cfdb-4c1b-be51-1398761763ed%2F6a8bfc99-7cd4-4291-9bb6-af373d90610e?alt=media&token=d25252a8-c346-4e2d-a199-16c2d90ed8ee",
        "reference": "/stroke_photos/eb62b253-cfdb-4c1b-be51-1398761763ed/6a8bfc99-7cd4-4291-9bb6-af373d90610e"
      }
    },
    "name": "Silver Shot",
    "description": "<p>With more paint</p>",
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "20180107",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "price": null,
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand": {}
    },
    "order_id": 0,
    "order_number": 350
  },
  {
    "name": "",
    "order_number": 1080,
    "description": "",
    "id": "eb776859-a00a-418f-97d4-347eac4d13cf"
  },
  {
    "order_number": 1202,
    "name": "",
    "description": "",
    "id": "ebd5d74a-f541-45a0-a835-e731aa755b7f"
  },
  {
    "name": "",
    "order_number": 1120,
    "description": "",
    "id": "ebe36ad1-de46-478c-ad54-c8e681dc16f7"
  },
  {
    "name": "Blank",
    "id": "ec6d1c13-e3ce-4e88-865c-ae124a89cae4",
    "paper": {
      "name": "tinto",
      "original_size": "75x105cm",
      "price": 19.3,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "gsm": "640",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "ref": "30075105",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      }
    },
    "tool": null,
    "order_number": 673,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/ec6d1c13-e3ce-4e88-865c-ae124a89cae4/600x600/22379bf2-4c09-4012-8f83-b76c84172461_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fec6d1c13-e3ce-4e88-865c-ae124a89cae4%2F600x600%2F22379bf2-4c09-4012-8f83-b76c84172461_600x600?alt=media&token=5561c8a6-6c1e-400f-911a-292f749636d2"
      },
      "original": {
        "reference": "/stroke_photos/ec6d1c13-e3ce-4e88-865c-ae124a89cae4/22379bf2-4c09-4012-8f83-b76c84172461",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fec6d1c13-e3ce-4e88-865c-ae124a89cae4%2F22379bf2-4c09-4012-8f83-b76c84172461?alt=media&token=c10b6b09-d876-45ac-9a07-3ebe6612fa7a"
      }
    },
    "order_id": 0
  },
  {
    "description": "",
    "order_number": 1113,
    "name": "",
    "id": "ec82626d-8e7b-479b-8c6e-110d44ab1874"
  },
  {
    "description": "",
    "order_number": 1199,
    "name": "",
    "id": "ec9746f2-86f3-41f6-a9dc-4815a68ba2b9"
  },
  {
    "order_number": 702,
    "tool": null,
    "paper": {
      "brand": {},
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "ref": "20170703",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "original_size": "A7",
      "price": 0.4,
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "order_id": 0,
    "id": "ecee637a-f972-41ad-a577-ae27bbd0a5b4",
    "name": "Blank",
    "description": "",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/ecee637a-f972-41ad-a577-ae27bbd0a5b4/600x600/ae9347a6-7d2d-484f-b735-eb7461bbe356_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fecee637a-f972-41ad-a577-ae27bbd0a5b4%2F600x600%2Fae9347a6-7d2d-484f-b735-eb7461bbe356_600x600?alt=media&token=333539b8-65e8-45cf-8331-052892e5b4e2"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fecee637a-f972-41ad-a577-ae27bbd0a5b4%2Fae9347a6-7d2d-484f-b735-eb7461bbe356?alt=media&token=49663a56-c3e9-4969-a5ef-6af2687c6460",
        "reference": "/stroke_photos/ecee637a-f972-41ad-a577-ae27bbd0a5b4/ae9347a6-7d2d-484f-b735-eb7461bbe356"
      }
    }
  },
  {
    "description": "",
    "order_number": 1070,
    "name": "",
    "id": "ed021e3f-4967-4dfb-807d-513ca95e8c6e"
  },
  {
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/ed14b5e1-cf82-456d-ba1f-69bf5df7e323/f29953b4-f080-46e5-863b-bcdf8aa09954",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fed14b5e1-cf82-456d-ba1f-69bf5df7e323%2Ff29953b4-f080-46e5-863b-bcdf8aa09954?alt=media&token=9ec0c76f-4737-4f1d-a419-738fce1172e2"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/ed14b5e1-cf82-456d-ba1f-69bf5df7e323/600x600/f29953b4-f080-46e5-863b-bcdf8aa09954_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fed14b5e1-cf82-456d-ba1f-69bf5df7e323%2F600x600%2Ff29953b4-f080-46e5-863b-bcdf8aa09954_600x600?alt=media&token=21f973cf-61d0-4241-81a4-26e69ed6a93b"
      }
    },
    "id": "ed14b5e1-cf82-456d-ba1f-69bf5df7e323",
    "order_number": 148,
    "tool": null,
    "paper": {
      "original_size": "A7",
      "name": null,
      "price": 1.35,
      "ref": "77725080001",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      }
    },
    "name": "Blank",
    "description": ""
  },
  {
    "order_id": 0,
    "name": "Blank",
    "id": "ed9494fb-edc3-49a4-8901-fb5b51b9774e",
    "description": "",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fed9494fb-edc3-49a4-8901-fb5b51b9774e%2F600x600%2Fccbe63ee-01d4-49e0-a527-0cc1171ca40f_600x600?alt=media&token=d4d3eca4-91b2-4d88-b479-f8707c328fd1",
        "reference": "stroke_photos/ed9494fb-edc3-49a4-8901-fb5b51b9774e/600x600/ccbe63ee-01d4-49e0-a527-0cc1171ca40f_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fed9494fb-edc3-49a4-8901-fb5b51b9774e%2Fccbe63ee-01d4-49e0-a527-0cc1171ca40f?alt=media&token=47d9ba1f-cd87-4bb0-a1d0-b422aa1fe547",
        "reference": "/stroke_photos/ed9494fb-edc3-49a4-8901-fb5b51b9774e/ccbe63ee-01d4-49e0-a527-0cc1171ca40f"
      }
    },
    "paper": {
      "name": "tinto",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Artistico"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "75x105cm",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "ref": "30075105",
      "gsm": "640",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "price": 19.3,
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      }
    },
    "tool": null,
    "order_number": 677
  },
  {
    "name": "",
    "description": "",
    "order_number": 1189,
    "id": "ee0420ca-d0be-4759-a6e0-b395b21d08ba"
  },
  {
    "description": "",
    "order_number": 1176,
    "name": "",
    "id": "ee07cfff-5440-4a5b-82f3-e375baa3401e"
  },
  {
    "id": "ee1befa4-d35e-4db5-b37c-ad6ec860a75f",
    "description": "",
    "order_number": 123,
    "order_id": 0,
    "tool": null,
    "name": "Accidentally SIngle",
    "paper": {
      "gsm": "250",
      "name": null,
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "ref": "20180107",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "price": null
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fee1befa4-d35e-4db5-b37c-ad6ec860a75f%2F4648cd38-8beb-4e34-965d-c19ee817968d?alt=media&token=77f250d9-4a50-441b-b968-fc9a1b464e99",
        "reference": "/stroke_photos/ee1befa4-d35e-4db5-b37c-ad6ec860a75f/4648cd38-8beb-4e34-965d-c19ee817968d"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fee1befa4-d35e-4db5-b37c-ad6ec860a75f%2F600x600%2F4648cd38-8beb-4e34-965d-c19ee817968d_600x600?alt=media&token=7cb6396c-f907-4607-836c-08f80060bcee",
        "reference": "stroke_photos/ee1befa4-d35e-4db5-b37c-ad6ec860a75f/600x600/4648cd38-8beb-4e34-965d-c19ee817968d_600x600"
      }
    }
  },
  {
    "description": "",
    "order_number": 1069,
    "name": "",
    "id": "ee231868-981b-4baa-885f-9c494b1c94d2"
  },
  {
    "paper": {
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "name": null,
      "original_size": "A7",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "ref": "77725080001",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": 1.35,
      "gsm": "250",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      }
    },
    "description": "",
    "order_id": 0,
    "id": "ee825c8c-30ac-4e8d-a7cb-f4d8bb22d566",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fee825c8c-30ac-4e8d-a7cb-f4d8bb22d566%2F600x600%2F98519a82-5142-4e3e-af01-a7e11fa008bd_600x600?alt=media&token=fb4ef676-f9f6-4990-b175-2270c6e301c2",
        "reference": "stroke_photos/ee825c8c-30ac-4e8d-a7cb-f4d8bb22d566/600x600/98519a82-5142-4e3e-af01-a7e11fa008bd_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fee825c8c-30ac-4e8d-a7cb-f4d8bb22d566%2F98519a82-5142-4e3e-af01-a7e11fa008bd?alt=media&token=3cd459bb-b232-41af-8b5b-79d2a078b2d0",
        "reference": "/stroke_photos/ee825c8c-30ac-4e8d-a7cb-f4d8bb22d566/98519a82-5142-4e3e-af01-a7e11fa008bd"
      }
    },
    "order_number": 175,
    "name": "Blank"
  },
  {
    "order_number": 1087,
    "description": "",
    "name": "",
    "id": "ee88e0d4-f07f-46ea-a557-31cc73464b7f"
  },
  {
    "order_number": 1081,
    "name": "",
    "description": "",
    "id": "eec07621-552b-49d5-80e0-56e34d123ffa"
  },
  {
    "order_number": 1030,
    "description": "",
    "name": "",
    "id": "eec4d88a-5414-41a1-b6c8-c0ffeb8dba9b"
  },
  {
    "order_id": 0,
    "order_number": 1000,
    "name": "Blank",
    "description": "",
    "id": "eed83685-165f-4a23-b32d-f8b38ef997ac",
    "paper": {
      "gsm": "640",
      "original_size": "75x105cm",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "price": 19.3,
      "ref": "30075105",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "brand_model": {
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "name": "tinto",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      }
    }
  },
  {
    "tool": null,
    "description": "",
    "order_id": 0,
    "name": "Blank",
    "order_number": 749,
    "id": "eff6db0c-8531-4e77-a399-96c3b80cc84e",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Feff6db0c-8531-4e77-a399-96c3b80cc84e%2F600x600%2Fc906602d-2bba-4d30-b9ae-55dafc99f8c9_600x600?alt=media&token=6ebb81ab-a41c-495d-8a48-7fb82c9b625a",
        "reference": "stroke_photos/eff6db0c-8531-4e77-a399-96c3b80cc84e/600x600/c906602d-2bba-4d30-b9ae-55dafc99f8c9_600x600"
      },
      "original": {
        "reference": "/stroke_photos/eff6db0c-8531-4e77-a399-96c3b80cc84e/c906602d-2bba-4d30-b9ae-55dafc99f8c9",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Feff6db0c-8531-4e77-a399-96c3b80cc84e%2Fc906602d-2bba-4d30-b9ae-55dafc99f8c9?alt=media&token=e71fbb60-d337-4736-a82d-8ca6a1aa667a"
      }
    },
    "paper": {
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "original_size": "A7",
      "ref": "20170703",
      "price": 0.4,
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "gsm": "250",
      "brand": {},
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    }
  },
  {
    "name": "Blank",
    "order_number": 196,
    "tool": null,
    "id": "efffca77-d637-4eca-b56f-a910e36a91e6",
    "paper": {
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A7",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "gsm": "250",
      "ref": "77725080001",
      "name": null,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "price": 1.35
    },
    "description": "",
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/efffca77-d637-4eca-b56f-a910e36a91e6/01040b87-6afb-4a32-a3c9-e117e1600ee2",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fefffca77-d637-4eca-b56f-a910e36a91e6%2F01040b87-6afb-4a32-a3c9-e117e1600ee2?alt=media&token=cfa90e2b-cb24-4cc6-a3df-6abb2de2720f"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fefffca77-d637-4eca-b56f-a910e36a91e6%2F600x600%2F01040b87-6afb-4a32-a3c9-e117e1600ee2_600x600?alt=media&token=af1a9dcc-20d0-4415-8694-a67da8e60b45",
        "reference": "stroke_photos/efffca77-d637-4eca-b56f-a910e36a91e6/600x600/01040b87-6afb-4a32-a3c9-e117e1600ee2_600x600"
      }
    }
  },
  {
    "description": "<p>Fast &amp; bold without much thinking.. (!) Looks a bit like a sled</p>",
    "paper": {
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "name": null,
      "brand": {},
      "brand_model": {},
      "gsm": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": null,
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null
    },
    "order_id": 0,
    "id": "f036d1f3-a393-44ea-837b-250c8d9c80c3",
    "order_number": 487,
    "tool": null,
    "name": "White Hook Boldly",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff036d1f3-a393-44ea-837b-250c8d9c80c3%2F600x600%2F0a21dba9-d9ef-490e-9fe4-e6334be2f362_600x600?alt=media&token=65c178d6-2679-41a9-9947-4db976feb1b7",
        "reference": "stroke_photos/f036d1f3-a393-44ea-837b-250c8d9c80c3/600x600/0a21dba9-d9ef-490e-9fe4-e6334be2f362_600x600"
      },
      "original": {
        "reference": "/stroke_photos/f036d1f3-a393-44ea-837b-250c8d9c80c3/0a21dba9-d9ef-490e-9fe4-e6334be2f362",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff036d1f3-a393-44ea-837b-250c8d9c80c3%2F0a21dba9-d9ef-490e-9fe4-e6334be2f362?alt=media&token=f46c7070-3d0f-4f8c-964b-2a5002d5dbc9"
      }
    }
  },
  {
    "order_number": 347,
    "id": "f0858291-30f9-4137-97ea-fbe9ffd1e53c",
    "tool": null,
    "description": "<p>My first attempt with silver paint. Looks pale on white paper</p>",
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff0858291-30f9-4137-97ea-fbe9ffd1e53c%2F600x600%2F012ac560-9d51-4c67-a610-7fd1c962b63c_600x600?alt=media&token=22dd6cf6-e15e-48a1-b2f4-b10ebd168434",
        "reference": "stroke_photos/f0858291-30f9-4137-97ea-fbe9ffd1e53c/600x600/012ac560-9d51-4c67-a610-7fd1c962b63c_600x600"
      },
      "original": {
        "reference": "/stroke_photos/f0858291-30f9-4137-97ea-fbe9ffd1e53c/012ac560-9d51-4c67-a610-7fd1c962b63c",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff0858291-30f9-4137-97ea-fbe9ffd1e53c%2F012ac560-9d51-4c67-a610-7fd1c962b63c?alt=media&token=350bbe5d-2045-436d-becc-ba1ebcd774b9"
      }
    },
    "paper": {
      "original_size": "A7",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "ref": "20180107",
      "brand": {},
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "name": null,
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": null,
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      }
    },
    "name": "Silver"
  },
  {
    "name": "Silver-On-Black Loads Of Paint 2",
    "description": "<p>One more, less so (!) very nice indeed</p>",
    "paper": {
      "brand": {},
      "brand_model": {},
      "ref": null,
      "original_size": "A4",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": null,
      "price": null,
      "name": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "id": "f11b4c84-a7f0-46b7-addb-547044103ed6",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff11b4c84-a7f0-46b7-addb-547044103ed6%2F600x600%2Fa6e6ce83-6754-4317-be17-1c653d1e4641_600x600?alt=media&token=b3731737-3e3f-4c03-834e-b3f20607b618",
        "reference": "stroke_photos/f11b4c84-a7f0-46b7-addb-547044103ed6/600x600/a6e6ce83-6754-4317-be17-1c653d1e4641_600x600"
      },
      "original": {
        "reference": "/stroke_photos/f11b4c84-a7f0-46b7-addb-547044103ed6/a6e6ce83-6754-4317-be17-1c653d1e4641",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff11b4c84-a7f0-46b7-addb-547044103ed6%2Fa6e6ce83-6754-4317-be17-1c653d1e4641?alt=media&token=e2b23cfb-07db-4b59-bf4e-52c0a4de0743"
      }
    },
    "order_id": 0,
    "tool": null,
    "order_number": 531
  },
  {
    "paper": {
      "name": "tinto",
      "price": 19.3,
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "ref": "30075105",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "original_size": "75x105cm",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "gsm": "640",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      }
    },
    "id": "f12cfe4c-c740-4438-8a09-59a3d8def91e",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff12cfe4c-c740-4438-8a09-59a3d8def91e%2F600x600%2F23566ae1-73df-4a5b-b290-a368291fe5d9_600x600?alt=media&token=1c4ee726-6799-4a21-b395-cc04b4fa2746",
        "reference": "stroke_photos/f12cfe4c-c740-4438-8a09-59a3d8def91e/600x600/23566ae1-73df-4a5b-b290-a368291fe5d9_600x600"
      },
      "original": {
        "reference": "/stroke_photos/f12cfe4c-c740-4438-8a09-59a3d8def91e/23566ae1-73df-4a5b-b290-a368291fe5d9",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff12cfe4c-c740-4438-8a09-59a3d8def91e%2F23566ae1-73df-4a5b-b290-a368291fe5d9?alt=media&token=dc5f88bc-254d-45b6-adec-381070ffeb56"
      }
    },
    "description": "",
    "order_id": 0,
    "name": "Blank",
    "order_number": 663,
    "tool": null
  },
  {
    "order_number": 271,
    "id": "f14a15a3-d9c1-4578-885f-8015277c457d",
    "name": "Even Worse",
    "description": "<p>Maybe some other time</p>",
    "paper": {
      "name": null,
      "gsm": "250",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "ref": "20180107",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {},
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": null
    },
    "order_id": 0,
    "photos": {
      "original": {
        "reference": "/stroke_photos/f14a15a3-d9c1-4578-885f-8015277c457d/bb9f7285-4438-4104-b3b3-e9355d8107c6",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff14a15a3-d9c1-4578-885f-8015277c457d%2Fbb9f7285-4438-4104-b3b3-e9355d8107c6?alt=media&token=a360f032-d8e9-4ca8-989a-44013985f73a"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff14a15a3-d9c1-4578-885f-8015277c457d%2F600x600%2Fbb9f7285-4438-4104-b3b3-e9355d8107c6_600x600?alt=media&token=05b749c9-8d14-4410-b131-ebbe2a394ccc",
        "reference": "stroke_photos/f14a15a3-d9c1-4578-885f-8015277c457d/600x600/bb9f7285-4438-4104-b3b3-e9355d8107c6_600x600"
      }
    },
    "tool": null
  },
  {
    "name": "",
    "order_number": 1210,
    "description": "",
    "id": "f185d5be-bf33-4d74-8af0-31eaf146dc53"
  },
  {
    "description": "",
    "order_number": 828,
    "id": "f19780c2-fdea-4c88-9c89-866276017656",
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "ref": "70025010030",
      "price": null,
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {},
      "original_size": "A7",
      "color": {
        "hex": "#f5f5f5",
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28"
      }
    },
    "order_id": 0,
    "name": "Blank"
  },
  {
    "order_number": 455,
    "name": "Same Diagonal White",
    "description": "<p>Just another attempt at it. I don't like this arc. One more</p>",
    "tool": null,
    "order_id": 0,
    "paper": {
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "gsm": null,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "name": null,
      "ref": null,
      "brand_model": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A4",
      "brand": {},
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b"
    },
    "id": "f1bb3a57-172f-4383-a53f-783ca9836d44",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff1bb3a57-172f-4383-a53f-783ca9836d44%2F2589c1f2-1e72-4fa7-9723-5463650ac3ae?alt=media&token=3055e416-8ad2-42b0-92b1-b4b5fdc4e2a1",
        "reference": "/stroke_photos/f1bb3a57-172f-4383-a53f-783ca9836d44/2589c1f2-1e72-4fa7-9723-5463650ac3ae"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/f1bb3a57-172f-4383-a53f-783ca9836d44/600x600/2589c1f2-1e72-4fa7-9723-5463650ac3ae_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff1bb3a57-172f-4383-a53f-783ca9836d44%2F600x600%2F2589c1f2-1e72-4fa7-9723-5463650ac3ae_600x600?alt=media&token=c3fc0c40-d548-4cab-9b7f-3e3fb6759422"
      }
    }
  },
  {
    "order_id": 0,
    "name": "Blank",
    "paper": {
      "brand": {},
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "20170703",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand_model": {
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "price": 0.4,
      "original_size": "A7",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b"
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/f1cbaabf-4747-483b-9b53-eab4be34028a/01a2b970-09bd-40e5-8e0c-09117c03dcbf",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff1cbaabf-4747-483b-9b53-eab4be34028a%2F01a2b970-09bd-40e5-8e0c-09117c03dcbf?alt=media&token=3588c834-1d53-478a-bd36-0e4081802760"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/f1cbaabf-4747-483b-9b53-eab4be34028a/600x600/01a2b970-09bd-40e5-8e0c-09117c03dcbf_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff1cbaabf-4747-483b-9b53-eab4be34028a%2F600x600%2F01a2b970-09bd-40e5-8e0c-09117c03dcbf_600x600?alt=media&token=f365e0c4-23c6-4049-b73e-0e96c6c60ea6"
      }
    },
    "order_number": 748,
    "description": "",
    "tool": null,
    "id": "f1cbaabf-4747-483b-9b53-eab4be34028a"
  },
  {
    "description": "",
    "order_id": 0,
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "brand_model": {
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "name": "Elephant Skin",
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2"
      },
      "ref": "4005329065767",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "gsm": "110",
      "price": 1,
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      },
      "original_size": "A4",
      "name": null
    },
    "name": "Blank",
    "id": "f2274e2f-223d-4f3a-b2ab-36b501326d14",
    "order_number": 872
  },
  {
    "paper": {
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": null,
      "brand_model": {},
      "gsm": null,
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "price": null
    },
    "order_id": 0,
    "description": "",
    "name": "Blank",
    "order_number": 970,
    "id": "f22f611c-434b-414c-a3d6-53e8390dbf16"
  },
  {
    "description": "",
    "order_number": 1005,
    "name": "",
    "id": "f241aa2e-c07b-4ee6-a532-6401a96d9d3e"
  },
  {
    "id": "f25cef09-9ecd-43db-a612-95d9ece58495",
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/f25cef09-9ecd-43db-a612-95d9ece58495/600x600/b7fccf43-19f9-4759-bd7c-4610858b7288_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff25cef09-9ecd-43db-a612-95d9ece58495%2F600x600%2Fb7fccf43-19f9-4759-bd7c-4610858b7288_600x600?alt=media&token=6a12bf6d-91cf-4d42-bda6-1adc61eafadd"
      },
      "original": {
        "reference": "/stroke_photos/f25cef09-9ecd-43db-a612-95d9ece58495/b7fccf43-19f9-4759-bd7c-4610858b7288",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff25cef09-9ecd-43db-a612-95d9ece58495%2Fb7fccf43-19f9-4759-bd7c-4610858b7288?alt=media&token=c4cb289e-5b79-4cfa-9272-ee945ad569c5"
      }
    },
    "tool": null,
    "description": "",
    "order_number": 662,
    "paper": {
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Artistico"
      },
      "ref": "30075105",
      "original_size": "75x105cm",
      "price": 19.3,
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "name": "tinto",
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "gsm": "640",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      }
    },
    "order_id": 0
  },
  {
    "id": "f2677f95-ca43-4553-bdc3-299bb33c7ef8",
    "description": "",
    "paper": {
      "price": 1,
      "brand": {
        "id": "add34501-efff-4dce-97d7-5c380ab04d18",
        "name": "Heyda"
      },
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "110",
      "ref": "4005329065767",
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "original_size": "A4",
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "name": "Elephant Skin"
      },
      "name": null
    },
    "order_number": 898,
    "name": "Blank",
    "order_id": 0
  },
  {
    "name": "Mirror Not",
    "order_number": 429,
    "id": "f26f558f-6be1-498f-bc40-5ed9611bf27b",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff26f558f-6be1-498f-bc40-5ed9611bf27b%2F600x600%2Fd2257245-ab3f-4f41-8c90-c12f1a64c50a_600x600?alt=media&token=85918a2e-f0c8-4d02-9038-f86fdaf12697",
        "reference": "stroke_photos/f26f558f-6be1-498f-bc40-5ed9611bf27b/600x600/d2257245-ab3f-4f41-8c90-c12f1a64c50a_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff26f558f-6be1-498f-bc40-5ed9611bf27b%2Fd2257245-ab3f-4f41-8c90-c12f1a64c50a?alt=media&token=26859e45-03cc-43b5-8acb-fa1e0eb34171",
        "reference": "/stroke_photos/f26f558f-6be1-498f-bc40-5ed9611bf27b/d2257245-ab3f-4f41-8c90-c12f1a64c50a"
      }
    },
    "order_id": 0,
    "description": "What happens if I lay the brush, then lift up the base and lay it down on the other side of the tip? Not mirrored",
    "paper": {
      "ref": "77725080001",
      "original_size": "A7",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "gsm": "250",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "name": null,
      "price": 1.35
    },
    "tool": null
  },
  {
    "name": "",
    "order_number": 1019,
    "description": "",
    "id": "f2876452-2e4f-49bc-96a3-67e6376da803"
  },
  {
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/f298467d-36af-4724-a465-6d34b4d8d8b3/600x600/78289c84-f922-49ec-8321-c003a6b41f33_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff298467d-36af-4724-a465-6d34b4d8d8b3%2F600x600%2F78289c84-f922-49ec-8321-c003a6b41f33_600x600?alt=media&token=4e117fec-1e71-4405-8bd0-174c06d57c82"
      },
      "original": {
        "reference": "/stroke_photos/f298467d-36af-4724-a465-6d34b4d8d8b3/78289c84-f922-49ec-8321-c003a6b41f33",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff298467d-36af-4724-a465-6d34b4d8d8b3%2F78289c84-f922-49ec-8321-c003a6b41f33?alt=media&token=dff52e59-8e38-412c-9226-5c708fb7b5c4"
      }
    },
    "order_number": 665,
    "order_id": 0,
    "description": "",
    "id": "f298467d-36af-4724-a465-6d34b4d8d8b3",
    "tool": null,
    "name": "Blank",
    "paper": {
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": "tinto",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Artistico"
      },
      "price": 19.3,
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "ref": "30075105",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "gsm": "640",
      "original_size": "75x105cm",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163"
    }
  },
  {
    "name": "",
    "order_number": 1203,
    "description": "",
    "id": "f29cedb0-db48-4515-870a-7c28f5aed563"
  },
  {
    "order_id": 0,
    "description": "",
    "order_number": 841,
    "paper": {
      "name": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand_model": {},
      "gsm": "250",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A7",
      "ref": "70025010030",
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "color": {
        "name": "White Pearl",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5"
      }
    },
    "name": "Blank",
    "id": "f2b44b89-2cc8-4459-8216-142bc2ef48a7"
  },
  {
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": "70025010030",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "hex": "#f5f5f5",
        "name": "White Pearl"
      },
      "brand": {},
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand_model": {},
      "original_size": "A7",
      "gsm": "250",
      "price": null,
      "name": null
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff2ffdb72-b120-4af3-b3bd-c79ec0e0e8b4%2F75573e0c-ebf5-49a3-b31c-5419663965a4?alt=media&token=7c01e10b-4c51-41e8-b680-7260a8040758",
        "reference": "/stroke_photos/f2ffdb72-b120-4af3-b3bd-c79ec0e0e8b4/75573e0c-ebf5-49a3-b31c-5419663965a4"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/f2ffdb72-b120-4af3-b3bd-c79ec0e0e8b4/600x600/75573e0c-ebf5-49a3-b31c-5419663965a4_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff2ffdb72-b120-4af3-b3bd-c79ec0e0e8b4%2F600x600%2F75573e0c-ebf5-49a3-b31c-5419663965a4_600x600?alt=media&token=f0bf6841-c462-4216-8fbc-fa725c8be6ee"
      }
    },
    "order_number": 580,
    "id": "f2ffdb72-b120-4af3-b3bd-c79ec0e0e8b4",
    "description": "",
    "order_id": 0,
    "name": "Blank",
    "tool": null
  },
  {
    "order_id": 0,
    "id": "f3162c02-694f-4831-b6b2-b34c3f2d8441",
    "description": "",
    "paper": {
      "gsm": "250",
      "brand_model": {},
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "price": null,
      "brand": {},
      "original_size": "A7",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "ref": "70025010030",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea"
    },
    "name": "Blank",
    "order_number": 838
  },
  {
    "description": "What happens if I roll the brush in a line, but just touching the tip?",
    "name": "Corwn-Wannabe",
    "paper": {
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "ref": "77725080001",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "gsm": "250",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "name": null,
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        }
      },
      "price": 1.35,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A7",
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    },
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff39d3d03-dff3-4e82-81b0-176d93e5a024%2F841d11b9-c17c-498d-b2eb-ac8b9f178962?alt=media&token=42a9e599-6161-4621-be43-64c2893eede0",
        "reference": "/stroke_photos/f39d3d03-dff3-4e82-81b0-176d93e5a024/841d11b9-c17c-498d-b2eb-ac8b9f178962"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/f39d3d03-dff3-4e82-81b0-176d93e5a024/600x600/841d11b9-c17c-498d-b2eb-ac8b9f178962_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff39d3d03-dff3-4e82-81b0-176d93e5a024%2F600x600%2F841d11b9-c17c-498d-b2eb-ac8b9f178962_600x600?alt=media&token=a03c0374-41b0-490e-add6-8b96e9346ac6"
      }
    },
    "tool": null,
    "order_number": 430,
    "id": "f39d3d03-dff3-4e82-81b0-176d93e5a024",
    "order_id": 0
  },
  {
    "name": "",
    "description": "",
    "order_number": 1048,
    "id": "f3a8a0ff-a6a2-4108-8d61-5d825a6f2635"
  },
  {
    "id": "f3bd5974-c899-46eb-9fdc-2a77777859f4",
    "description": "",
    "order_id": 0,
    "name": "Btns No Reload",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff3bd5974-c899-46eb-9fdc-2a77777859f4%2F088e15cd-62b7-451d-968e-b30d6d310967?alt=media&token=cc47346d-a21a-4732-97e2-33d3cea21308",
        "reference": "/stroke_photos/f3bd5974-c899-46eb-9fdc-2a77777859f4/088e15cd-62b7-451d-968e-b30d6d310967"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff3bd5974-c899-46eb-9fdc-2a77777859f4%2F600x600%2F088e15cd-62b7-451d-968e-b30d6d310967_600x600?alt=media&token=8c610991-0c3f-4fdc-80ff-965e4fa4f2db",
        "reference": "stroke_photos/f3bd5974-c899-46eb-9fdc-2a77777859f4/600x600/088e15cd-62b7-451d-968e-b30d6d310967_600x600"
      }
    },
    "tool": null,
    "order_number": 47,
    "paper": {
      "ref": "44202129",
      "color": {
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5"
      },
      "name": "",
      "original_size": "A4",
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Accademia Designo"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "paper_surface": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "price": null,
      "gsm": "200",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4"
    }
  },
  {
    "description": "",
    "name": "Too Ambitious",
    "order_id": 0,
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/f3c81986-0dff-46cc-bd6a-0d5e1bf35bd9/bfb2a0a3-c9c4-4562-96ea-8fbadc9c8651",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff3c81986-0dff-46cc-bd6a-0d5e1bf35bd9%2Fbfb2a0a3-c9c4-4562-96ea-8fbadc9c8651?alt=media&token=40e4d503-d0c6-4cb0-b020-934c6809a332"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/f3c81986-0dff-46cc-bd6a-0d5e1bf35bd9/600x600/bfb2a0a3-c9c4-4562-96ea-8fbadc9c8651_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff3c81986-0dff-46cc-bd6a-0d5e1bf35bd9%2F600x600%2Fbfb2a0a3-c9c4-4562-96ea-8fbadc9c8651_600x600?alt=media&token=e7537dee-13b3-46d8-b5eb-65e0b5c8c707"
      }
    },
    "id": "f3c81986-0dff-46cc-bd6a-0d5e1bf35bd9",
    "order_number": 217,
    "paper": {
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "original_size": "A7",
      "ref": "77725080001",
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "price": 1.35,
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "gsm": "250"
    }
  },
  {
    "order_number": 923,
    "order_id": 0,
    "paper": {
      "name": null,
      "gsm": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black",
        "hex": "#2f2c28"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "original_size": "A4",
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "brand_model": {},
      "ref": null
    },
    "description": "",
    "name": "Blank",
    "id": "f3d9df74-6a6e-49b2-aec0-ad0d841509eb"
  },
  {
    "id": "f453cf22-8ba1-4f62-91f8-d0777f69c430",
    "paper": {
      "ref": "20170703",
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "gsm": "250",
      "brand": {},
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "original_size": "A7",
      "price": 0.4
    },
    "description": "",
    "tool": null,
    "name": "Blank",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff453cf22-8ba1-4f62-91f8-d0777f69c430%2F600x600%2F76e6eb43-2553-407f-81b3-5be2bf5e970b_600x600?alt=media&token=ef0a5d20-ee21-4894-b86d-4ecdf9ac959e",
        "reference": "stroke_photos/f453cf22-8ba1-4f62-91f8-d0777f69c430/600x600/76e6eb43-2553-407f-81b3-5be2bf5e970b_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff453cf22-8ba1-4f62-91f8-d0777f69c430%2F76e6eb43-2553-407f-81b3-5be2bf5e970b?alt=media&token=e480cef4-9714-4233-8ddf-add6a7aed1fc",
        "reference": "/stroke_photos/f453cf22-8ba1-4f62-91f8-d0777f69c430/76e6eb43-2553-407f-81b3-5be2bf5e970b"
      }
    },
    "order_id": 0,
    "order_number": 555
  },
  {
    "paper": {
      "gsm": "250",
      "brand": {},
      "price": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "ref": "20180107",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      }
    },
    "order_id": 0,
    "description": "<p>It is an improvement over the previous one, still same curve DNA</p>",
    "name": "The Younger Brother",
    "order_number": 287,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff45638b5-e4f3-4c3a-a4a6-8778ae457739%2F1378a248-a8ee-4e1e-9761-375a3d086996?alt=media&token=a6d7daf6-c6d0-4e7b-a10e-0b607c83428f",
        "reference": "/stroke_photos/f45638b5-e4f3-4c3a-a4a6-8778ae457739/1378a248-a8ee-4e1e-9761-375a3d086996"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/f45638b5-e4f3-4c3a-a4a6-8778ae457739/600x600/1378a248-a8ee-4e1e-9761-375a3d086996_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff45638b5-e4f3-4c3a-a4a6-8778ae457739%2F600x600%2F1378a248-a8ee-4e1e-9761-375a3d086996_600x600?alt=media&token=0ff62432-e8d0-4c2d-bfca-1fb67b6cea84"
      }
    },
    "id": "f45638b5-e4f3-4c3a-a4a6-8778ae457739",
    "tool": null
  },
  {
    "name": "",
    "order_number": 1227,
    "description": "",
    "id": "f4e7e65a-84cf-4617-869a-bcc1d830aeaa"
  },
  {
    "name": "Blank",
    "order_number": 669,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/f5100adb-f25e-4256-beed-5ecce2c5d0fb/600x600/20210a83-c33b-49d7-933a-22585e7a9378_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff5100adb-f25e-4256-beed-5ecce2c5d0fb%2F600x600%2F20210a83-c33b-49d7-933a-22585e7a9378_600x600?alt=media&token=ab86a554-d44e-4257-9ce8-09a3523cf01e"
      },
      "original": {
        "reference": "/stroke_photos/f5100adb-f25e-4256-beed-5ecce2c5d0fb/20210a83-c33b-49d7-933a-22585e7a9378",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff5100adb-f25e-4256-beed-5ecce2c5d0fb%2F20210a83-c33b-49d7-933a-22585e7a9378?alt=media&token=0c0cff7f-4e0b-4742-925f-ecc0cccab55f"
      }
    },
    "paper": {
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "name": "tinto",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "brand_model": {
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        },
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "ref": "30075105",
      "gsm": "640",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "75x105cm",
      "price": 19.3
    },
    "order_id": 0,
    "tool": null,
    "description": "",
    "id": "f5100adb-f25e-4256-beed-5ecce2c5d0fb"
  },
  {
    "order_id": 0,
    "id": "f544f06b-8b06-4bc9-9381-48fcfa930308",
    "order_number": 465,
    "paper": {
      "ref": null,
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "gsm": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "name": null,
      "price": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand": {},
      "brand_model": {},
      "original_size": "A4"
    },
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff544f06b-8b06-4bc9-9381-48fcfa930308%2F600x600%2F64019ed4-aff3-46fa-9574-1dff52c50717_600x600?alt=media&token=129a029c-fd47-4279-8570-751541b65d5d",
        "reference": "stroke_photos/f544f06b-8b06-4bc9-9381-48fcfa930308/600x600/64019ed4-aff3-46fa-9574-1dff52c50717_600x600"
      },
      "original": {
        "reference": "/stroke_photos/f544f06b-8b06-4bc9-9381-48fcfa930308/64019ed4-aff3-46fa-9574-1dff52c50717",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff544f06b-8b06-4bc9-9381-48fcfa930308%2F64019ed4-aff3-46fa-9574-1dff52c50717?alt=media&token=0c44415a-2b1a-4f9f-8383-d284042e6808"
      }
    },
    "description": "<p>Barely visible. Love it! This is what I was looking for!</p>",
    "tool": null,
    "name": "Reusing White Paint Finally"
  },
  {
    "paper": {
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A4",
      "gsm": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "price": null,
      "ref": null,
      "brand": {},
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {}
    },
    "tool": null,
    "description": "<p>Meh</p>",
    "id": "f5d9438b-20ae-4bf6-ab25-bb3ea7d44e4d",
    "order_id": 0,
    "order_number": 457,
    "name": "Average Diagonal White",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff5d9438b-20ae-4bf6-ab25-bb3ea7d44e4d%2F600x600%2Ffc2d7ca3-cd4a-458f-b2b0-a6532d40d27e_600x600?alt=media&token=11a67228-f9fe-4a06-9d00-c92cf35fcfc8",
        "reference": "stroke_photos/f5d9438b-20ae-4bf6-ab25-bb3ea7d44e4d/600x600/fc2d7ca3-cd4a-458f-b2b0-a6532d40d27e_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff5d9438b-20ae-4bf6-ab25-bb3ea7d44e4d%2Ffc2d7ca3-cd4a-458f-b2b0-a6532d40d27e?alt=media&token=fb08384b-4edc-4f7f-a7ec-27cb52bd1a13",
        "reference": "/stroke_photos/f5d9438b-20ae-4bf6-ab25-bb3ea7d44e4d/fc2d7ca3-cd4a-458f-b2b0-a6532d40d27e"
      }
    }
  },
  {
    "description": "",
    "paper": {
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "A7",
      "price": 1.35,
      "name": null,
      "gsm": "250",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "ref": "77725080001",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      }
    },
    "tool": null,
    "order_id": 0,
    "order_number": 157,
    "id": "f5fe578a-60b0-4a23-b904-2886bd6b91ee",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/f5fe578a-60b0-4a23-b904-2886bd6b91ee/600x600/4de304e9-3312-435b-b9e0-dd9253df9925_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff5fe578a-60b0-4a23-b904-2886bd6b91ee%2F600x600%2F4de304e9-3312-435b-b9e0-dd9253df9925_600x600?alt=media&token=70b3ff7a-1b84-453e-b47f-f611ead67cb3"
      },
      "original": {
        "reference": "/stroke_photos/f5fe578a-60b0-4a23-b904-2886bd6b91ee/4de304e9-3312-435b-b9e0-dd9253df9925",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff5fe578a-60b0-4a23-b904-2886bd6b91ee%2F4de304e9-3312-435b-b9e0-dd9253df9925?alt=media&token=3589dd38-38fd-4be4-b877-3b4c66fb1cc1"
      }
    },
    "name": "Blank"
  },
  {
    "paper": {
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "ref": "77725080001",
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "gsm": "250",
      "original_size": "A7",
      "brand_model": {
        "brand": {
          "name": "Fedrigoni",
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "price": 1.35,
      "name": null,
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "id": "f653050a-cb52-4966-bd2c-f52bb25421a1",
    "name": "Splodge",
    "tool": null,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff653050a-cb52-4966-bd2c-f52bb25421a1%2Ffef4f2c1-f6be-4242-9f0b-e0cb027c3eda?alt=media&token=07013443-9176-47dc-a6a9-19ddb607f7a7",
        "reference": "/stroke_photos/f653050a-cb52-4966-bd2c-f52bb25421a1/fef4f2c1-f6be-4242-9f0b-e0cb027c3eda"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/f653050a-cb52-4966-bd2c-f52bb25421a1/600x600/fef4f2c1-f6be-4242-9f0b-e0cb027c3eda_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff653050a-cb52-4966-bd2c-f52bb25421a1%2F600x600%2Ffef4f2c1-f6be-4242-9f0b-e0cb027c3eda_600x600?alt=media&token=f399a4a4-ad82-4881-b5ba-7b1a6b572b92"
      }
    },
    "order_id": 0,
    "order_number": 424,
    "description": "What happens if I press the brush and the spin it around the base? Splodge"
  },
  {
    "name": "Blank",
    "id": "f67cfcfb-8581-49ac-be5d-f90f96a7e694",
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "ref": "77725080001",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "price": 1.35,
      "original_size": "A7",
      "gsm": "250",
      "name": null
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/f67cfcfb-8581-49ac-be5d-f90f96a7e694/600x600/8f6693bf-c2bf-400d-b0b8-c6b7394858e7_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff67cfcfb-8581-49ac-be5d-f90f96a7e694%2F600x600%2F8f6693bf-c2bf-400d-b0b8-c6b7394858e7_600x600?alt=media&token=87eb1d5d-cd36-4de7-9588-a075262cc14f"
      },
      "original": {
        "reference": "/stroke_photos/f67cfcfb-8581-49ac-be5d-f90f96a7e694/8f6693bf-c2bf-400d-b0b8-c6b7394858e7",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff67cfcfb-8581-49ac-be5d-f90f96a7e694%2F8f6693bf-c2bf-400d-b0b8-c6b7394858e7?alt=media&token=51dcbda1-d808-4c2d-906b-a958a295a806"
      }
    },
    "tool": null,
    "order_id": 0,
    "description": "",
    "order_number": 785
  },
  {
    "order_number": 1067,
    "description": "",
    "name": "",
    "id": "f6c5cca0-fbc7-4f4d-89b1-d197309d809a"
  },
  {
    "order_number": 1134,
    "description": "",
    "name": "",
    "id": "f6d8bca7-de8c-4ede-a102-f9a9835a424e"
  },
  {
    "description": "",
    "order_number": 1014,
    "name": "",
    "id": "f74a5bbe-1cfb-4d8d-be7c-c3e4582b7ad7"
  },
  {
    "order_number": 1049,
    "name": "",
    "description": "",
    "id": "f74f8632-2e1e-4bde-a964-5863ee018674"
  },
  {
    "name": "Fuck Swirly, Double Down",
    "order_number": 330,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff7510d52-db72-4164-8421-61172a9fe3b0%2F600x600%2Fbfa9c3b1-c055-4720-a34d-efa324891259_600x600?alt=media&token=c3f8973f-d76b-4e27-93a0-5b5ddedab676",
        "reference": "stroke_photos/f7510d52-db72-4164-8421-61172a9fe3b0/600x600/bfa9c3b1-c055-4720-a34d-efa324891259_600x600"
      },
      "original": {
        "reference": "/stroke_photos/f7510d52-db72-4164-8421-61172a9fe3b0/bfa9c3b1-c055-4720-a34d-efa324891259",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff7510d52-db72-4164-8421-61172a9fe3b0%2Fbfa9c3b1-c055-4720-a34d-efa324891259?alt=media&token=5d5ecd7a-dff1-46ce-8722-1312c74fe63c"
      }
    },
    "tool": null,
    "description": "<p>Better than what I expected</p>",
    "paper": {
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "name": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": null,
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "brand": {},
      "original_size": "A7",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107"
    },
    "order_id": 0,
    "id": "f7510d52-db72-4164-8421-61172a9fe3b0"
  },
  {
    "description": "",
    "order_number": 1013,
    "name": "",
    "id": "f786945f-ebff-43a1-812b-5b93167d8b71"
  },
  {
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/f7b5bb9b-3aaf-4758-9f42-e4a4873c0ff8/600x600/99ad8f8e-dfc1-45e2-a97c-3099387b5b88_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff7b5bb9b-3aaf-4758-9f42-e4a4873c0ff8%2F600x600%2F99ad8f8e-dfc1-45e2-a97c-3099387b5b88_600x600?alt=media&token=27aee501-8e4d-4ada-9b32-4c073448738a"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff7b5bb9b-3aaf-4758-9f42-e4a4873c0ff8%2F99ad8f8e-dfc1-45e2-a97c-3099387b5b88?alt=media&token=597d9e9a-24d1-4edd-849e-f2d9711e8dbd",
        "reference": "/stroke_photos/f7b5bb9b-3aaf-4758-9f42-e4a4873c0ff8/99ad8f8e-dfc1-45e2-a97c-3099387b5b88"
      }
    },
    "description": "",
    "tool": null,
    "name": "Blank",
    "order_id": 0,
    "id": "f7b5bb9b-3aaf-4758-9f42-e4a4873c0ff8",
    "order_number": 696,
    "paper": {
      "price": 19.3,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "ref": "30075105",
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "gsm": "640",
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "name": "Artistico",
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "name": "tinto",
      "original_size": "75x105cm",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      }
    }
  },
  {
    "description": "",
    "order_number": 1010,
    "name": "",
    "id": "f7c8fa3e-762a-4c00-a287-e85283148882"
  },
  {
    "order_number": 461,
    "id": "f7fa4104-158a-4fb5-8686-b5203695df30",
    "tool": null,
    "description": "<p>Trying another one with the same paint</p>",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff7fa4104-158a-4fb5-8686-b5203695df30%2F600x600%2Fe1b54f7d-2270-4669-b26f-9caf11ac1a60_600x600?alt=media&token=dca02032-0bd9-46f7-b105-51b7a32615e9",
        "reference": "stroke_photos/f7fa4104-158a-4fb5-8686-b5203695df30/600x600/e1b54f7d-2270-4669-b26f-9caf11ac1a60_600x600"
      },
      "original": {
        "reference": "/stroke_photos/f7fa4104-158a-4fb5-8686-b5203695df30/e1b54f7d-2270-4669-b26f-9caf11ac1a60",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff7fa4104-158a-4fb5-8686-b5203695df30%2Fe1b54f7d-2270-4669-b26f-9caf11ac1a60?alt=media&token=37370b21-527f-4fc5-8e0e-97bf357d0e2e"
      }
    },
    "order_id": 0,
    "paper": {
      "color": {
        "hex": "#2f2c28",
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "brand": {},
      "name": null,
      "gsm": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A4",
      "price": null,
      "brand_model": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "name": "Reusing White Paint"
  },
  {
    "name": "House Challenge",
    "id": "f8783ceb-7133-4a99-acfd-5e150e547954",
    "order_id": 0,
    "paper": {
      "name": null,
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "ref": "77725080001",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "price": 1.35,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "original_size": "A7",
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "gsm": "250",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      }
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/f8783ceb-7133-4a99-acfd-5e150e547954/e883efde-f8ca-46c6-bc9e-60292ee42ae5",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff8783ceb-7133-4a99-acfd-5e150e547954%2Fe883efde-f8ca-46c6-bc9e-60292ee42ae5?alt=media&token=51474d1a-986e-4b15-9fcd-7f6d4f70b04f"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/f8783ceb-7133-4a99-acfd-5e150e547954/600x600/e883efde-f8ca-46c6-bc9e-60292ee42ae5_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff8783ceb-7133-4a99-acfd-5e150e547954%2F600x600%2Fe883efde-f8ca-46c6-bc9e-60292ee42ae5_600x600?alt=media&token=dcd253d7-836a-413f-9e4a-6417bb69311e"
      }
    },
    "order_number": 431,
    "tool": null,
    "description": "I was challenged to make a house with one stroke. Can't say I made it really. Not sure how I missed that roof..."
  },
  {
    "id": "f887394c-4f32-4fa6-9db7-f97f37fabe31",
    "name": "Lightbulb",
    "description": "Because I can",
    "order_number": 446,
    "order_id": 0,
    "paper": {
      "original_size": "A7",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null,
      "gsm": "250",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "ref": "77725080001",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": 1.35
    },
    "tool": null,
    "photos": {
      "original": {
        "reference": "/stroke_photos/f887394c-4f32-4fa6-9db7-f97f37fabe31/20fc3ed9-871e-4a95-93d4-2c5045fb0619",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff887394c-4f32-4fa6-9db7-f97f37fabe31%2F20fc3ed9-871e-4a95-93d4-2c5045fb0619?alt=media&token=a1ae1e70-ce5b-4b41-9f8f-a32e70d7359e"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff887394c-4f32-4fa6-9db7-f97f37fabe31%2F600x600%2F20fc3ed9-871e-4a95-93d4-2c5045fb0619_600x600?alt=media&token=90a4559e-02d3-427e-a73e-01c87feffa76",
        "reference": "stroke_photos/f887394c-4f32-4fa6-9db7-f97f37fabe31/600x600/20fc3ed9-871e-4a95-93d4-2c5045fb0619_600x600"
      }
    }
  },
  {
    "description": "",
    "order_number": 35,
    "paper": {
      "original_size": "A4",
      "price": null,
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "name": "",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4",
      "gsm": "200",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "44202129",
      "paper_surface": {},
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand_model": {
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53",
        "name": "Accademia Designo",
        "brand": {
          "id": "8dab2773-406e-4626-89a8-6bf555513912",
          "name": "Fabriano"
        }
      }
    },
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff893b942-bc6a-4018-9dba-9b9bbc9441e1%2F600x600%2Fc9da6cba-ecf0-4acc-b911-962feb2b914d_600x600?alt=media&token=9397f11b-1f8e-4bdd-8bd8-38da5ec06402",
        "reference": "stroke_photos/f893b942-bc6a-4018-9dba-9b9bbc9441e1/600x600/c9da6cba-ecf0-4acc-b911-962feb2b914d_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff893b942-bc6a-4018-9dba-9b9bbc9441e1%2Fc9da6cba-ecf0-4acc-b911-962feb2b914d?alt=media&token=9a43d70a-efaf-4891-8b4a-05c307f126bb",
        "reference": "/stroke_photos/f893b942-bc6a-4018-9dba-9b9bbc9441e1/c9da6cba-ecf0-4acc-b911-962feb2b914d"
      }
    },
    "id": "f893b942-bc6a-4018-9dba-9b9bbc9441e1",
    "name": "Brush Sideways",
    "order_id": 0
  },
  {
    "id": "f8a681c5-4218-4f41-91e0-5f129122b80f",
    "order_id": 0,
    "order_number": 906,
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "price": null,
      "ref": null,
      "name": null,
      "gsm": null,
      "color": {
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28",
        "name": "Black"
      },
      "brand_model": {},
      "original_size": "A4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "name": "Blank",
    "description": ""
  },
  {
    "description": "",
    "name": "Blank",
    "tool": null,
    "id": "f8a90d43-165c-402e-b988-f98950c23cf5",
    "paper": {
      "ref": "70025010030",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "price": null,
      "color": {
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand": {},
      "gsm": "250",
      "original_size": "A7",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "brand_model": {}
    },
    "order_id": 0,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff8a90d43-165c-402e-b988-f98950c23cf5%2F600x600%2F2ad8d69a-3dd2-4c9a-a412-374949be4a89_600x600?alt=media&token=3f0348a0-8b3e-47bf-a546-01ec3d7f5d0e",
        "reference": "stroke_photos/f8a90d43-165c-402e-b988-f98950c23cf5/600x600/2ad8d69a-3dd2-4c9a-a412-374949be4a89_600x600"
      },
      "original": {
        "reference": "/stroke_photos/f8a90d43-165c-402e-b988-f98950c23cf5/2ad8d69a-3dd2-4c9a-a412-374949be4a89",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff8a90d43-165c-402e-b988-f98950c23cf5%2F2ad8d69a-3dd2-4c9a-a412-374949be4a89?alt=media&token=e14945c6-8b87-4357-b6bd-41d3b019ff35"
      }
    },
    "order_number": 608
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/f8ddb8c4-8657-4543-b0a9-9ca861e737a0/7fe41178-bea5-4de3-84ea-ab4b283410c3",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff8ddb8c4-8657-4543-b0a9-9ca861e737a0%2F7fe41178-bea5-4de3-84ea-ab4b283410c3?alt=media&token=16db37f7-6855-4627-8a2f-7952f718c134"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/f8ddb8c4-8657-4543-b0a9-9ca861e737a0/600x600/7fe41178-bea5-4de3-84ea-ab4b283410c3_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff8ddb8c4-8657-4543-b0a9-9ca861e737a0%2F600x600%2F7fe41178-bea5-4de3-84ea-ab4b283410c3_600x600?alt=media&token=2bc4caac-878d-4b32-a708-7dad036ddd26"
      }
    },
    "tool": null,
    "order_number": 171,
    "description": "",
    "name": "Blank",
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "original_size": "A7",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "ref": "77725080001",
      "price": 1.35,
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      },
      "name": null,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "gsm": "250"
    },
    "id": "f8ddb8c4-8657-4543-b0a9-9ca861e737a0",
    "order_id": 0
  },
  {
    "paper": {
      "gsm": "640",
      "brand_model": {
        "name": "Artistico",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "price": 19.3,
      "name": "tinto",
      "ref": "30075105",
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "original_size": "75x105cm",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "name": "Blank",
    "tool": null,
    "order_number": 660,
    "order_id": 0,
    "description": "",
    "id": "f9317921-5bfe-4597-8eb1-cc3f11798389",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff9317921-5bfe-4597-8eb1-cc3f11798389%2F5534e665-837b-49b8-984f-d4711666f4ad?alt=media&token=0c8818f1-c2cf-4506-9fcb-6705b512b0c0",
        "reference": "/stroke_photos/f9317921-5bfe-4597-8eb1-cc3f11798389/5534e665-837b-49b8-984f-d4711666f4ad"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/f9317921-5bfe-4597-8eb1-cc3f11798389/600x600/5534e665-837b-49b8-984f-d4711666f4ad_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff9317921-5bfe-4597-8eb1-cc3f11798389%2F600x600%2F5534e665-837b-49b8-984f-d4711666f4ad_600x600?alt=media&token=ce4c7d86-208e-41b0-852e-4425ab96eb85"
      }
    }
  },
  {
    "description": "",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/f9389cf6-185a-4856-8e04-1ca75c631d1e/600x600/eaabb0ff-1953-44bb-9f82-fe32808661e4_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff9389cf6-185a-4856-8e04-1ca75c631d1e%2F600x600%2Feaabb0ff-1953-44bb-9f82-fe32808661e4_600x600?alt=media&token=bad6f6f4-7695-410e-81a6-8eec14e20ac6"
      },
      "original": {
        "reference": "/stroke_photos/f9389cf6-185a-4856-8e04-1ca75c631d1e/eaabb0ff-1953-44bb-9f82-fe32808661e4",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff9389cf6-185a-4856-8e04-1ca75c631d1e%2Feaabb0ff-1953-44bb-9f82-fe32808661e4?alt=media&token=0e58fd4a-ebfe-4d81-9907-1e08d62d0fd7"
      }
    },
    "id": "f9389cf6-185a-4856-8e04-1ca75c631d1e",
    "name": "Blank",
    "paper": {
      "paper_material": {
        "name": "Cotton",
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a"
      },
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "original_size": "75x105cm",
      "gsm": "640",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "paper_surface": {
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f",
        "name": "Cold Pressed (\"NOT\")"
      },
      "ref": "30075105",
      "price": 19.3,
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "name": "tinto",
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "name": "Artistico",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        }
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "order_id": 0,
    "order_number": 664
  },
  {
    "tool": null,
    "name": "Blank",
    "id": "f96d7947-46a4-4e34-ae78-425056901bd6",
    "order_id": 0,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff96d7947-46a4-4e34-ae78-425056901bd6%2F600x600%2Fdbc5cb2c-4df2-46bc-b126-77f7e8b92ae3_600x600?alt=media&token=d24cbff3-557e-496c-bd09-189c10466afe",
        "reference": "stroke_photos/f96d7947-46a4-4e34-ae78-425056901bd6/600x600/dbc5cb2c-4df2-46bc-b126-77f7e8b92ae3_600x600"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ff96d7947-46a4-4e34-ae78-425056901bd6%2Fdbc5cb2c-4df2-46bc-b126-77f7e8b92ae3?alt=media&token=f8e0483e-bebd-49e8-99cf-3a82eab05a3c",
        "reference": "/stroke_photos/f96d7947-46a4-4e34-ae78-425056901bd6/dbc5cb2c-4df2-46bc-b126-77f7e8b92ae3"
      }
    },
    "order_number": 737,
    "paper": {
      "gsm": "250",
      "price": 0.4,
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "ref": "20170703",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "brand": {},
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper"
      },
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      }
    }
  },
  {
    "order_number": 1095,
    "description": "",
    "name": "",
    "id": "f989e571-0d48-4295-9c83-38bbe324f6e5"
  },
  {
    "id": "fa34a08b-d086-43c5-8035-d0100489a25c",
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/fa34a08b-d086-43c5-8035-d0100489a25c/600x600/53ddf385-1fe4-425e-b3db-5152ca5c6c1d_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffa34a08b-d086-43c5-8035-d0100489a25c%2F600x600%2F53ddf385-1fe4-425e-b3db-5152ca5c6c1d_600x600?alt=media&token=55c522fb-bdd1-4d11-af78-209720ceb9fb"
      },
      "original": {
        "reference": "/stroke_photos/fa34a08b-d086-43c5-8035-d0100489a25c/53ddf385-1fe4-425e-b3db-5152ca5c6c1d",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffa34a08b-d086-43c5-8035-d0100489a25c%2F53ddf385-1fe4-425e-b3db-5152ca5c6c1d?alt=media&token=9b1c50fd-d5ae-4b60-b3b0-327107dc4247"
      }
    },
    "order_number": 120,
    "order_id": 0,
    "tool": null,
    "paper": {
      "brand": {},
      "ref": "20180107",
      "price": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "gsm": "250",
      "name": null,
      "original_size": "A7",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "description": "",
    "name": "Two Points Smooth Attempt"
  },
  {
    "order_number": 1209,
    "description": "",
    "name": "",
    "id": "fa53b18d-8ab8-4ef6-9a0b-b6066a3657b7"
  },
  {
    "description": "",
    "order_number": 867,
    "id": "fabac124-ce9a-45f0-8d26-40f07494a6c9",
    "order_id": 0,
    "name": "Blank",
    "paper": {
      "paper_material": {
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70",
        "name": "Unknown"
      },
      "gsm": "110",
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "name": null,
      "price": 1,
      "brand_model": {
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2",
        "brand": {
          "id": "add34501-efff-4dce-97d7-5c380ab04d18",
          "name": "Heyda"
        },
        "name": "Elephant Skin"
      },
      "ref": "4005329065767",
      "original_size": "A4",
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    }
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffb226000-4d55-4022-9093-2221989eb260%2F69580cf6-ba7a-4c8b-8a85-7986758e4711?alt=media&token=3f0513e1-c219-4558-b9de-82c5a70df5ec",
        "reference": "/stroke_photos/fb226000-4d55-4022-9093-2221989eb260/69580cf6-ba7a-4c8b-8a85-7986758e4711"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffb226000-4d55-4022-9093-2221989eb260%2F600x600%2F69580cf6-ba7a-4c8b-8a85-7986758e4711_600x600?alt=media&token=89b05270-efa8-49b4-a275-11cb1a9b5fd7",
        "reference": "stroke_photos/fb226000-4d55-4022-9093-2221989eb260/600x600/69580cf6-ba7a-4c8b-8a85-7986758e4711_600x600"
      }
    },
    "id": "fb226000-4d55-4022-9093-2221989eb260",
    "order_id": 0,
    "paper": {
      "price": null,
      "brand_model": {
        "name": "Bio Top",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "brand": {},
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "name": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "gsm": "250",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "ref": "20180107",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      }
    },
    "tool": null,
    "order_number": 127,
    "name": "Purshivo",
    "description": ""
  },
  {
    "tool": null,
    "paper": {
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "original_size": "A7",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "name": null,
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "ref": "77725080001",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "price": 1.35,
      "gsm": "250"
    },
    "id": "fb36631f-e8c1-4dfd-8078-fcd57419b69f",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffb36631f-e8c1-4dfd-8078-fcd57419b69f%2Fa19b7e5b-0240-4e41-9516-fdf640e41860?alt=media&token=d60d59f3-2708-4f6c-9d8e-2a0b84ffb10c",
        "reference": "/stroke_photos/fb36631f-e8c1-4dfd-8078-fcd57419b69f/a19b7e5b-0240-4e41-9516-fdf640e41860"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/fb36631f-e8c1-4dfd-8078-fcd57419b69f/600x600/a19b7e5b-0240-4e41-9516-fdf640e41860_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffb36631f-e8c1-4dfd-8078-fcd57419b69f%2F600x600%2Fa19b7e5b-0240-4e41-9516-fdf640e41860_600x600?alt=media&token=daaa305b-01ec-494f-83b2-dbfadc119751"
      }
    },
    "order_id": 0,
    "name": "Blank",
    "description": "",
    "order_number": 797
  },
  {
    "id": "fb3889d4-afb3-4849-803d-a27ac178d06f",
    "order_id": 0,
    "name": "Blank",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/fb3889d4-afb3-4849-803d-a27ac178d06f/600x600/16162fa8-0721-4a34-a134-3a087af23824_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffb3889d4-afb3-4849-803d-a27ac178d06f%2F600x600%2F16162fa8-0721-4a34-a134-3a087af23824_600x600?alt=media&token=4d19d624-fb29-4f49-ba82-8eefd9c4854f"
      },
      "original": {
        "reference": "/stroke_photos/fb3889d4-afb3-4849-803d-a27ac178d06f/16162fa8-0721-4a34-a134-3a087af23824",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffb3889d4-afb3-4849-803d-a27ac178d06f%2F16162fa8-0721-4a34-a134-3a087af23824?alt=media&token=a99fb226-b0b7-4207-a351-0d1f6f43e4f7"
      }
    },
    "description": "",
    "paper": {
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "original_size": "A7",
      "ref": "77725080001",
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "price": 1.35,
      "gsm": "250",
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "name": null
    },
    "order_number": 790
  },
  {
    "tool": null,
    "order_id": 0,
    "description": "",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffb7f44bd-e1a6-4e62-b122-33de73c4d51d%2F600x600%2F35705f65-332d-460e-87e8-4d5bd4100606_600x600?alt=media&token=1cc463af-8b46-4e3a-a08d-b7b14532aa11",
        "reference": "stroke_photos/fb7f44bd-e1a6-4e62-b122-33de73c4d51d/600x600/35705f65-332d-460e-87e8-4d5bd4100606_600x600"
      },
      "original": {
        "reference": "/stroke_photos/fb7f44bd-e1a6-4e62-b122-33de73c4d51d/35705f65-332d-460e-87e8-4d5bd4100606",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffb7f44bd-e1a6-4e62-b122-33de73c4d51d%2F35705f65-332d-460e-87e8-4d5bd4100606?alt=media&token=2a17d7d4-11a1-4594-88d0-92c89dbee628"
      }
    },
    "id": "fb7f44bd-e1a6-4e62-b122-33de73c4d51d",
    "order_number": 638,
    "paper": {
      "brand_model": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "price": null,
      "original_size": "A7",
      "color": {
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "ref": "70025010030"
    },
    "name": "Blank"
  },
  {
    "name": "",
    "order_number": 1123,
    "description": "",
    "id": "fb8d98e2-64a7-46b8-aa5f-f330781830a0"
  },
  {
    "id": "fbb19886-516b-46a5-8fa2-0e1cd23fc6fc",
    "order_number": 966,
    "description": "",
    "order_id": 0,
    "name": "Blank",
    "paper": {
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "name": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "gsm": null,
      "brand_model": {},
      "color": {
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "name": "Black"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "original_size": "A4",
      "brand": {},
      "ref": null
    }
  },
  {
    "photos": {
      "original": {
        "reference": "/stroke_photos/fbb50cf0-810e-47a2-bed1-a0066382a87e/2fbedc1b-eeae-4a88-b7b8-277c2965abee",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffbb50cf0-810e-47a2-bed1-a0066382a87e%2F2fbedc1b-eeae-4a88-b7b8-277c2965abee?alt=media&token=92503916-c113-42dd-a559-6addb4a3808a"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffbb50cf0-810e-47a2-bed1-a0066382a87e%2F600x600%2F2fbedc1b-eeae-4a88-b7b8-277c2965abee_600x600?alt=media&token=51d58616-95f1-433d-a1da-5359748c5d7b",
        "reference": "stroke_photos/fbb50cf0-810e-47a2-bed1-a0066382a87e/600x600/2fbedc1b-eeae-4a88-b7b8-277c2965abee_600x600"
      }
    },
    "order_number": 535,
    "paper": {
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null,
      "original_size": "A4",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "ref": null,
      "gsm": null,
      "name": null,
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "name": "Black",
        "hex": "#2f2c28",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559"
      },
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "brand": {},
      "brand_model": {}
    },
    "tool": null,
    "name": "Silver-On-Black Sideways",
    "description": "<p>Let's do brush sideways</p>",
    "id": "fbb50cf0-810e-47a2-bed1-a0066382a87e",
    "order_id": 0
  },
  {
    "order_number": 1065,
    "name": "",
    "description": "",
    "id": "fbea1756-80f5-469b-8eb0-3b58faa5faf3"
  },
  {
    "tool": null,
    "description": "",
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffbf2b3ca-9eb7-46c2-a5be-77ad11ce53c8%2F0423da3a-e9c6-4862-8613-61a715e1bd6c?alt=media&token=bf690402-03a3-44fc-b234-694fc318e665",
        "reference": "/stroke_photos/fbf2b3ca-9eb7-46c2-a5be-77ad11ce53c8/0423da3a-e9c6-4862-8613-61a715e1bd6c"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffbf2b3ca-9eb7-46c2-a5be-77ad11ce53c8%2F600x600%2F0423da3a-e9c6-4862-8613-61a715e1bd6c_600x600?alt=media&token=eec7089e-3485-4dfe-b814-2bf1b86fbeb2",
        "reference": "stroke_photos/fbf2b3ca-9eb7-46c2-a5be-77ad11ce53c8/600x600/0423da3a-e9c6-4862-8613-61a715e1bd6c_600x600"
      }
    },
    "order_number": 567,
    "name": "Blank",
    "order_id": 0,
    "id": "fbf2b3ca-9eb7-46c2-a5be-77ad11ce53c8",
    "paper": {
      "name": null,
      "ref": "20170703",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "brand": {},
      "original_size": "A7",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": 0.4,
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "gsm": "250",
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bristol Paper",
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      }
    }
  },
  {
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffc059efd-a449-47f2-84b7-a77ac10027b9%2F9e426d85-bfcb-49cb-8b23-196d72433d02?alt=media&token=ed568bdb-77dd-4c2b-b9da-614629e89b65",
        "reference": "/stroke_photos/fc059efd-a449-47f2-84b7-a77ac10027b9/9e426d85-bfcb-49cb-8b23-196d72433d02"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffc059efd-a449-47f2-84b7-a77ac10027b9%2F600x600%2F9e426d85-bfcb-49cb-8b23-196d72433d02_600x600?alt=media&token=fcb74735-41da-4aeb-8c1b-ccc17a77ac32",
        "reference": "stroke_photos/fc059efd-a449-47f2-84b7-a77ac10027b9/600x600/9e426d85-bfcb-49cb-8b23-196d72433d02_600x600"
      }
    },
    "paper": {
      "ref": "77725080001",
      "original_size": "A7",
      "gsm": "250",
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "price": 1.35,
      "paper_material": {
        "name": "ECF Pulp",
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419"
      },
      "paper_surface": {
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17",
        "name": "Semi-Rough"
      },
      "brand_model": {
        "name": "Tintoretto Gesso",
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        }
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb"
    },
    "name": "Blank",
    "tool": null,
    "order_number": 173,
    "description": "",
    "id": "fc059efd-a449-47f2-84b7-a77ac10027b9",
    "order_id": 0
  },
  {
    "name": "Blank",
    "paper": {
      "original_size": "A7",
      "name": null,
      "price": 0.4,
      "ref": "20170703",
      "brand_model": {
        "name": "Bristol Paper",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "gsm": "250",
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand": {},
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b"
    },
    "photos": {
      "original": {
        "reference": "/stroke_photos/fc389634-ee1a-4dfc-933c-2e0e30fdf88c/1dacdfaa-ad50-4823-8108-e8b29f997943",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffc389634-ee1a-4dfc-933c-2e0e30fdf88c%2F1dacdfaa-ad50-4823-8108-e8b29f997943?alt=media&token=b3fc27a4-0c4a-4711-8632-133a90c54a0a"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/fc389634-ee1a-4dfc-933c-2e0e30fdf88c/600x600/1dacdfaa-ad50-4823-8108-e8b29f997943_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffc389634-ee1a-4dfc-933c-2e0e30fdf88c%2F600x600%2F1dacdfaa-ad50-4823-8108-e8b29f997943_600x600?alt=media&token=034da2bf-8f77-40c3-bea1-92b6726dc821"
      }
    },
    "tool": null,
    "description": "",
    "id": "fc389634-ee1a-4dfc-933c-2e0e30fdf88c",
    "order_number": 711,
    "order_id": 0
  },
  {
    "paper": {
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "original_size": "A7",
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand": {},
      "ref": "20180107",
      "price": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "name": null,
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "hex": "#f5f5f5",
        "name": "White"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "gsm": "250"
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/fca6e5ff-4f99-420d-ad99-f9753c19fa97/600x600/fd053db7-fb64-430d-8953-af6b3ce35ecf_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffca6e5ff-4f99-420d-ad99-f9753c19fa97%2F600x600%2Ffd053db7-fb64-430d-8953-af6b3ce35ecf_600x600?alt=media&token=ce6f9f8d-8932-406f-a520-595279b626dc"
      },
      "original": {
        "reference": "/stroke_photos/fca6e5ff-4f99-420d-ad99-f9753c19fa97/fd053db7-fb64-430d-8953-af6b3ce35ecf",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffca6e5ff-4f99-420d-ad99-f9753c19fa97%2Ffd053db7-fb64-430d-8953-af6b3ce35ecf?alt=media&token=35f30d2e-df0f-455c-ac40-4cdf2df0c17e"
      }
    },
    "id": "fca6e5ff-4f99-420d-ad99-f9753c19fa97",
    "description": "<p>I really don't like that circle, I am scratching it with an X</p>",
    "tool": null,
    "name": "X",
    "order_id": 0,
    "order_number": 338
  },
  {
    "description": "",
    "id": "fce24bf5-67d6-4ed9-ad96-833b2d158633",
    "tool": null,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/fce24bf5-67d6-4ed9-ad96-833b2d158633/600x600/b97abe84-e0d3-4a67-903b-1f16e3f9e61a_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffce24bf5-67d6-4ed9-ad96-833b2d158633%2F600x600%2Fb97abe84-e0d3-4a67-903b-1f16e3f9e61a_600x600?alt=media&token=739b9981-9d84-492b-98cf-e374a3078377"
      },
      "original": {
        "reference": "/stroke_photos/fce24bf5-67d6-4ed9-ad96-833b2d158633/b97abe84-e0d3-4a67-903b-1f16e3f9e61a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffce24bf5-67d6-4ed9-ad96-833b2d158633%2Fb97abe84-e0d3-4a67-903b-1f16e3f9e61a?alt=media&token=61e49e74-0fbe-481b-bab7-1bc842d07b04"
      }
    },
    "name": "What's With Your...",
    "paper": {
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "price": 1.35,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled"
      },
      "brand_model": {
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "name": "Tintoretto Gesso"
      },
      "gsm": "250",
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "name": null,
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "original_size": "A7",
      "ref": "77725080001"
    },
    "order_number": 219,
    "order_id": 0
  },
  {
    "description": "",
    "order_id": 0,
    "tool": null,
    "paper": {
      "name": null,
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "gsm": "250",
      "original_size": "A7",
      "brand": {},
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "ref": "20180107",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "price": null,
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "id": "fd1f4f2e-1e83-4f65-ac7c-1dd536453ffe",
    "order_number": 128,
    "name": "Faux Fur Jacket",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffd1f4f2e-1e83-4f65-ac7c-1dd536453ffe%2F600x600%2Ffe8c6374-b0cc-4bf5-b24f-f0e44662d38e_600x600?alt=media&token=d2996f64-1bd7-47bf-b837-b198f0b963f7",
        "reference": "stroke_photos/fd1f4f2e-1e83-4f65-ac7c-1dd536453ffe/600x600/fe8c6374-b0cc-4bf5-b24f-f0e44662d38e_600x600"
      },
      "original": {
        "reference": "/stroke_photos/fd1f4f2e-1e83-4f65-ac7c-1dd536453ffe/fe8c6374-b0cc-4bf5-b24f-f0e44662d38e",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffd1f4f2e-1e83-4f65-ac7c-1dd536453ffe%2Ffe8c6374-b0cc-4bf5-b24f-f0e44662d38e?alt=media&token=2fc9f386-eebb-4d38-ae20-d9d01af5ce9d"
      }
    }
  },
  {
    "id": "fd23727d-1f6d-4eb9-b418-c3af16ca8805",
    "name": "Blank",
    "tool": null,
    "order_number": 624,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/fd23727d-1f6d-4eb9-b418-c3af16ca8805/600x600/b3366892-4f36-48e2-8928-9695e0051c25_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffd23727d-1f6d-4eb9-b418-c3af16ca8805%2F600x600%2Fb3366892-4f36-48e2-8928-9695e0051c25_600x600?alt=media&token=c90329e1-1487-4057-80b5-4b736292001d"
      },
      "original": {
        "reference": "/stroke_photos/fd23727d-1f6d-4eb9-b418-c3af16ca8805/b3366892-4f36-48e2-8928-9695e0051c25",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffd23727d-1f6d-4eb9-b418-c3af16ca8805%2Fb3366892-4f36-48e2-8928-9695e0051c25?alt=media&token=9200da46-c5d9-4c77-8a0e-ad539d0e6b6e"
      }
    },
    "paper": {
      "price": null,
      "gsm": "250",
      "brand": {},
      "ref": "70025010030",
      "name": null,
      "color": {
        "hex": "#f5f5f5",
        "id": "1ad9f81d-53da-4c65-afd8-df4e0a510a28",
        "name": "White Pearl"
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "id": "9df9d8af-8c65-4361-8d56-bf9328d933ea",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {},
      "original_size": "A7"
    },
    "description": "",
    "order_id": 0
  },
  {
    "tool": null,
    "id": "fd373510-2f78-4dd8-8db3-63b9fbb3bede",
    "name": "Blank",
    "description": "",
    "photos": {
      "original": {
        "reference": "/stroke_photos/fd373510-2f78-4dd8-8db3-63b9fbb3bede/2103c81b-39d3-42ae-95ce-a61f7a3d354b",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffd373510-2f78-4dd8-8db3-63b9fbb3bede%2F2103c81b-39d3-42ae-95ce-a61f7a3d354b?alt=media&token=8f0f9876-d12a-4390-aa3f-0641f1c2db46"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/fd373510-2f78-4dd8-8db3-63b9fbb3bede/600x600/2103c81b-39d3-42ae-95ce-a61f7a3d354b_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffd373510-2f78-4dd8-8db3-63b9fbb3bede%2F600x600%2F2103c81b-39d3-42ae-95ce-a61f7a3d354b_600x600?alt=media&token=d75de14d-55fc-4484-a49e-15b44b1c45d8"
      }
    },
    "order_id": 0,
    "paper": {
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "gsm": "250",
      "ref": "77725080001",
      "brand_model": {
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52",
        "name": "Tintoretto Gesso"
      },
      "price": 1.35,
      "name": null,
      "brand": {
        "name": "Fedrigoni",
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a"
      },
      "original_size": "A7",
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      }
    },
    "order_number": 776
  },
  {
    "name": "Short and Awkward",
    "id": "fd9691f9-9050-42cf-951a-fb4725d2942e",
    "paper": {
      "ref": "20180107",
      "brand_model": {
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        },
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7"
      },
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "original_size": "A7",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "name": null,
      "gsm": "250",
      "price": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      }
    },
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/fd9691f9-9050-42cf-951a-fb4725d2942e/600x600/e39a6499-753c-45db-a266-f2bf2845d63f_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffd9691f9-9050-42cf-951a-fb4725d2942e%2F600x600%2Fe39a6499-753c-45db-a266-f2bf2845d63f_600x600?alt=media&token=94eae1e3-9f2e-48d7-a162-3ff00d0944aa"
      },
      "original": {
        "reference": "/stroke_photos/fd9691f9-9050-42cf-951a-fb4725d2942e/e39a6499-753c-45db-a266-f2bf2845d63f",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffd9691f9-9050-42cf-951a-fb4725d2942e%2Fe39a6499-753c-45db-a266-f2bf2845d63f?alt=media&token=b4ea2fa1-7d93-47c7-a006-2d96c7a7ffe0"
      }
    },
    "order_number": 358,
    "tool": null,
    "order_id": 0,
    "description": "<p>Okay, giving up. Time for something else</p>"
  },
  {
    "paper": {
      "gsm": "640",
      "id": "04ce1d7c-10af-45f7-b7a9-aec756e31163",
      "ref": "30075105",
      "price": 19.3,
      "brand": {
        "id": "8dab2773-406e-4626-89a8-6bf555513912",
        "name": "Fabriano"
      },
      "original_size": "75x105cm",
      "paper_surface": {
        "name": "Cold Pressed (\"NOT\")",
        "id": "8e9e2199-6158-442e-beb2-9fd06a14da9f"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "brand_model": {
        "id": "35c1ddfd-c8ca-4a72-8c9e-1f51fe388f16",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "name": "Artistico"
      },
      "name": "tinto",
      "paper_material": {
        "id": "0c69c488-c7b8-4b5e-a413-6ada122af38a",
        "name": "Cotton"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      }
    },
    "id": "fdc37fbf-475d-4e18-83a4-bf2461794356",
    "order_number": 981,
    "description": "",
    "order_id": 0,
    "name": "Blank"
  },
  {
    "description": "<p>even less paint</p>",
    "photos": {
      "original": {
        "reference": "/stroke_photos/fde7bbda-74ff-424f-b626-221134332486/eb8ae0f5-8e63-4083-9615-5b0d847e1ac2",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffde7bbda-74ff-424f-b626-221134332486%2Feb8ae0f5-8e63-4083-9615-5b0d847e1ac2?alt=media&token=b2467a2b-03a6-42f3-912b-be416a94826e"
      },
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffde7bbda-74ff-424f-b626-221134332486%2F600x600%2Feb8ae0f5-8e63-4083-9615-5b0d847e1ac2_600x600?alt=media&token=eb6f0719-365f-4dc3-8c56-a3080af143f9",
        "reference": "stroke_photos/fde7bbda-74ff-424f-b626-221134332486/600x600/eb8ae0f5-8e63-4083-9615-5b0d847e1ac2_600x600"
      }
    },
    "order_number": 516,
    "tool": null,
    "paper": {
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "brand_model": {},
      "original_size": "A4",
      "gsm": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "price": null,
      "name": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "ref": null,
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      }
    },
    "name": "White Splodge Touch 6",
    "order_id": 0,
    "id": "fde7bbda-74ff-424f-b626-221134332486"
  },
  {
    "name": "Blank",
    "order_number": 897,
    "description": "",
    "paper": {
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "id": "4807e865-41fd-4eef-beb4-78aa9e189701",
      "paper_material": {
        "name": "Unknown",
        "id": "24882404-cfcb-43f0-aac5-2e33eee1ba70"
      },
      "name": null,
      "original_size": "A4",
      "ref": "4005329065767",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "brand": {
        "name": "Heyda",
        "id": "add34501-efff-4dce-97d7-5c380ab04d18"
      },
      "price": 1,
      "brand_model": {
        "name": "Elephant Skin",
        "brand": {
          "name": "Heyda",
          "id": "add34501-efff-4dce-97d7-5c380ab04d18"
        },
        "id": "a434e753-25ed-46d7-b3ec-59a0497c9ed2"
      },
      "gsm": "110"
    },
    "id": "fded16f6-5bd2-425d-9f4c-59cfbe0d13f2",
    "order_id": 0
  },
  {
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffe5ff7bb-b35a-4477-91c5-d3ab417d7a31%2F600x600%2F9ebeb15d-090e-4dcd-a505-86e71498948a_600x600?alt=media&token=b00aaa0b-4ca4-4787-a228-401fdd09381c",
        "reference": "stroke_photos/fe5ff7bb-b35a-4477-91c5-d3ab417d7a31/600x600/9ebeb15d-090e-4dcd-a505-86e71498948a_600x600"
      },
      "original": {
        "reference": "/stroke_photos/fe5ff7bb-b35a-4477-91c5-d3ab417d7a31/9ebeb15d-090e-4dcd-a505-86e71498948a",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffe5ff7bb-b35a-4477-91c5-d3ab417d7a31%2F9ebeb15d-090e-4dcd-a505-86e71498948a?alt=media&token=2220bbb7-301d-4cd7-81ff-5afdae83a79c"
      }
    },
    "description": "<p>Not every day is Easter</p>",
    "paper": {
      "name": null,
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "ref": "20180107",
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "gsm": "250",
      "brand_model": {
        "name": "Bio Top",
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        }
      },
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "price": null,
      "brand": {},
      "original_size": "A7",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      }
    },
    "order_number": 277,
    "name": "One More Gradual Fail",
    "id": "fe5ff7bb-b35a-4477-91c5-d3ab417d7a31",
    "order_id": 0,
    "tool": null
  },
  {
    "description": "",
    "order_id": 0,
    "paper": {
      "ref": "44202129",
      "brand_model": {
        "name": "Accademia Designo",
        "brand": {
          "name": "Fabriano",
          "id": "8dab2773-406e-4626-89a8-6bf555513912"
        },
        "id": "3cdb03eb-a473-44a8-a1e1-21c184d64a53"
      },
      "store": {
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "url": "https://www.slanchogled.com/"
      },
      "paper_surface": {},
      "name": "",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "brand": {
        "name": "Fabriano",
        "id": "8dab2773-406e-4626-89a8-6bf555513912"
      },
      "price": null,
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A4",
      "gsm": "200",
      "id": "7e6554da-d8ac-4e1d-8fbc-5860cf5878b4"
    },
    "tool": null,
    "name": "WOE HOT 6",
    "id": "fe64c6ad-7417-4ac8-ba95-cacadcd764c6",
    "order_number": 91,
    "photos": {
      "thumb_600x600": {
        "reference": "stroke_photos/fe64c6ad-7417-4ac8-ba95-cacadcd764c6/600x600/6a796357-11ae-439a-b21e-05529367b39c_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffe64c6ad-7417-4ac8-ba95-cacadcd764c6%2F600x600%2F6a796357-11ae-439a-b21e-05529367b39c_600x600?alt=media&token=17e43051-4a43-4360-82f4-80ee92b656bf"
      },
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffe64c6ad-7417-4ac8-ba95-cacadcd764c6%2F6a796357-11ae-439a-b21e-05529367b39c?alt=media&token=4c20d4be-7f09-416e-94c9-ff7e3bf28976",
        "reference": "/stroke_photos/fe64c6ad-7417-4ac8-ba95-cacadcd764c6/6a796357-11ae-439a-b21e-05529367b39c"
      }
    }
  },
  {
    "order_number": 392,
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffe7422ff-347a-41ca-8cab-c3b3251754c4%2F600x600%2Fac0f5202-dc3d-4fde-8e09-29bf633aca6d_600x600?alt=media&token=59e03345-02bd-4ace-b3c8-a95a15e9a659",
        "reference": "stroke_photos/fe7422ff-347a-41ca-8cab-c3b3251754c4/600x600/ac0f5202-dc3d-4fde-8e09-29bf633aca6d_600x600"
      },
      "original": {
        "reference": "/stroke_photos/fe7422ff-347a-41ca-8cab-c3b3251754c4/ac0f5202-dc3d-4fde-8e09-29bf633aca6d",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Ffe7422ff-347a-41ca-8cab-c3b3251754c4%2Fac0f5202-dc3d-4fde-8e09-29bf633aca6d?alt=media&token=68d922f4-0a59-4d9b-9bcd-a8f8d0f721d2"
      }
    },
    "id": "fe7422ff-347a-41ca-8cab-c3b3251754c4",
    "order_id": 0,
    "paper": {
      "store": {
        "url": "https://www.slanchogled.com/",
        "name": "Slanchogled",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "gsm": "250",
      "ref": "20180107",
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "brand_model": {
        "id": "3626d894-c770-46bd-b2f6-110222a3adc7",
        "name": "Bio Top",
        "brand": {
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56",
          "name": "Unknown"
        }
      },
      "id": "794bc840-7e84-4ffd-909c-142a54753e86",
      "paper_material": {
        "name": "Cellulose",
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392"
      },
      "original_size": "A7",
      "color": {
        "name": "White",
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "price": null,
      "brand": {}
    },
    "description": "<p>Add more silver</p>",
    "tool": null,
    "name": "Mixer 2"
  },
  {
    "name": "Blank",
    "description": "",
    "order_number": 909,
    "id": "fe9814ac-19e6-41dc-bb73-c7bedf3a9c46",
    "paper": {
      "price": null,
      "paper_surface": {
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b",
        "name": "Smooth"
      },
      "original_size": "A4",
      "ref": null,
      "brand_model": {},
      "store": {
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled"
      },
      "color": {
        "name": "Black",
        "id": "945904ee-7b03-4661-bab1-0aa60fd74559",
        "hex": "#2f2c28"
      },
      "name": null,
      "brand": {},
      "gsm": null,
      "id": "c83d44bd-c14a-422b-a38a-d7d27f68e57b",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      }
    },
    "order_id": 0
  },
  {
    "order_number": 1041,
    "description": "",
    "name": "",
    "id": "feae0f7d-a54e-4c5c-8e36-8941b540c3da"
  },
  {
    "order_number": 1036,
    "name": "",
    "description": "",
    "id": "fec2fe05-fd3e-4eae-a1b3-aade8d01a20b"
  },
  {
    "tool": null,
    "order_id": 0,
    "name": "Blank",
    "paper": {
      "price": 0.4,
      "store": {
        "id": "5459706d-16bb-4a90-a0a3-987bda200901",
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/"
      },
      "brand_model": {
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "name": "Bristol Paper"
      },
      "ref": "20170703",
      "color": {
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White",
        "hex": "#f5f5f5"
      },
      "gsm": "250",
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "name": null
    },
    "order_number": 725,
    "id": "ff30d3aa-541f-46e0-a24e-a0ccfe9becc9",
    "photos": {
      "thumb_600x600": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fff30d3aa-541f-46e0-a24e-a0ccfe9becc9%2F600x600%2F31cba3a4-9858-4aef-8294-365845a7f90d_600x600?alt=media&token=5f6eac19-2421-40c1-a55b-4bbf194b181c",
        "reference": "stroke_photos/ff30d3aa-541f-46e0-a24e-a0ccfe9becc9/600x600/31cba3a4-9858-4aef-8294-365845a7f90d_600x600"
      },
      "original": {
        "reference": "/stroke_photos/ff30d3aa-541f-46e0-a24e-a0ccfe9becc9/31cba3a4-9858-4aef-8294-365845a7f90d",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fff30d3aa-541f-46e0-a24e-a0ccfe9becc9%2F31cba3a4-9858-4aef-8294-365845a7f90d?alt=media&token=7eca6d3e-32a0-4b4e-a9cb-d80bc6e63667"
      }
    },
    "description": ""
  },
  {
    "description": "",
    "name": "",
    "order_number": 1223,
    "id": "ff91e4b0-ffe0-416a-b1cf-e758b3fc2c35"
  },
  {
    "tool": null,
    "name": "Blank",
    "order_id": 0,
    "photos": {
      "original": {
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fff9456c6-156c-4be1-b36e-dacde667a164%2Fa86eff4b-3f35-422f-b4d4-cf7bfe47b55d?alt=media&token=38f72036-2852-41d5-b8de-2d3a7dd65b1b",
        "reference": "/stroke_photos/ff9456c6-156c-4be1-b36e-dacde667a164/a86eff4b-3f35-422f-b4d4-cf7bfe47b55d"
      },
      "thumb_600x600": {
        "reference": "stroke_photos/ff9456c6-156c-4be1-b36e-dacde667a164/600x600/a86eff4b-3f35-422f-b4d4-cf7bfe47b55d_600x600",
        "download_url": "https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2Fff9456c6-156c-4be1-b36e-dacde667a164%2F600x600%2Fa86eff4b-3f35-422f-b4d4-cf7bfe47b55d_600x600?alt=media&token=4e145725-16b1-4f83-b6f0-6054aa37eef0"
      }
    },
    "description": "",
    "paper": {
      "price": 1.35,
      "ref": "77725080001",
      "color": {
        "hex": "#f5f5f5",
        "name": "White",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809"
      },
      "name": null,
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "id": "ab29e1db-a545-484e-8b90-5923b46679fb",
      "paper_surface": {
        "name": "Semi-Rough",
        "id": "d5abad9b-bc1c-4766-9f06-a2e64bd64a17"
      },
      "gsm": "250",
      "paper_material": {
        "id": "3a428cf0-f0e1-47fa-9367-78193e63f419",
        "name": "ECF Pulp"
      },
      "brand": {
        "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
        "name": "Fedrigoni"
      },
      "original_size": "A7",
      "brand_model": {
        "name": "Tintoretto Gesso",
        "brand": {
          "id": "3a6c7151-3f06-4c41-b62e-bcced934bb6a",
          "name": "Fedrigoni"
        },
        "id": "c0efd658-cdd7-4b82-926b-311395405f52"
      }
    },
    "id": "ff9456c6-156c-4be1-b36e-dacde667a164",
    "order_number": 769
  },
  {
    "id": "ffbfbd3d-ca8c-4aba-9351-558e64e8e12b",
    "name": "Blank",
    "description": "",
    "paper": {
      "paper_material": {
        "id": "4653e46b-6fab-47bc-a86f-f988ed806392",
        "name": "Cellulose"
      },
      "brand": {},
      "id": "57f0f9d6-0fb2-415c-8469-e8995636091b",
      "brand_model": {
        "id": "38e03eb0-08f6-4f86-ac70-3e08d3418854",
        "brand": {
          "name": "Unknown",
          "id": "b9b41730-792a-4f8f-b588-0a45d808fb56"
        },
        "name": "Bristol Paper"
      },
      "name": null,
      "paper_surface": {
        "name": "Smooth",
        "id": "d106107b-5ecc-4c5f-be85-753f3f6dae5b"
      },
      "original_size": "A7",
      "price": 0.4,
      "ref": "20170703",
      "store": {
        "name": "Slanchogled",
        "url": "https://www.slanchogled.com/",
        "id": "5459706d-16bb-4a90-a0a3-987bda200901"
      },
      "color": {
        "hex": "#f5f5f5",
        "id": "86c40147-cb3f-420d-8391-3f56551c7809",
        "name": "White"
      },
      "gsm": "250"
    },
    "order_id": 0,
    "order_number": 817
  }
]
