import { useState, useRef, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';
import { ShareAlt } from '@styled-icons/boxicons-solid';
import {
  ArrowBackOutline,
  ArrowForwardOutline,
} from '@styled-icons/evaicons-outline';
import { Close } from '@styled-icons/zondicons';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

import strokeApi from '../../../services/api/strokeApi';
import BoxWithText from '../../../common/components/BoxWithText';
import styles from '../../../common/styles';
import Wall from '../../../static/Wall.jpg';
import Frame from '../../../static/Frame.jpg';

import { getStorage, getDownloadURL, ref } from 'firebase/storage';

import tools from '~/src/static/tools.png';
import paper from '~/src/static/paper.png';
import paint from '~/src/static/paint.png';
import ItemRow from '../components/ItemRow';
import PaperRow from '../components/PaperRow';
import PaintRow from '../components/PaintRow';
import Loader from '../../../common/components/Loader';

import { Stroke } from '~/src/types/Types';
import RedDot from '~/src/common/components/RedDot';
import MyContext from '~/src/services/context';

export default function Stroke() {
  let navigate = useNavigate();
  const imageWallRef = useRef(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const context = useContext(MyContext);
  context.setTitle('Stroke Page');
  let { id } = useParams();
  const [showCoppyMessage, setShowCoppyMessage] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [stroke, setStroke] = useState<Stroke>(null);
  const [pcSound, setPCSound] = useState('');

  const imageWall = imageWallRef.current.offsetWidth;

  useEffect(() => {}, [imageWallRef]);

  const init = async (id) => {
    const res = await strokeApi.getStroke(id);

    setStroke(res);
  };

  const initAS = async () => {
    const storage = getStorage();
    const pathReference = ref(
      storage,
      `assets/paper-collection-sounds/${id}.wav`,
    );
    const url = await getDownloadURL(pathReference);
    setPCSound(url);
  };

  const goHome = () => {
    navigate('/paper-collection');
  };

  let url = pcSound;
  let audio = new Audio(url);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 2000) {
        setWindowWidth(2000);
      } else setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    return function unmount() {};
  }, []);

  useEffect(() => {
    init(Number(id));
    if (parseInt(id) < 1001) {
      initAS();
    }
  }, []);

  const [link, setLink] = useState(
    `https://www.perfectstrokeproject.com/stroke/${stroke?.order_number}`,
  );

  const copyToClipboard = () => {
    setShowCoppyMessage(true);
    setTimeout(() => setShowCoppyMessage(false), 3000);
  };
  const { innerWidth: width, innerHeight: height } = window;
  return (
    <PageWrapper className="stroke-popup">
      <Container style={{ maxWidth: 1800, paddingRight: 0, paddingLeft: 0 }}>
        <TopButtons>
          <div style={{ position: 'relative' }}>
            <BoxWithText
              iconComponent={<ShareIcon size={25} />}
              rev={false}
              text=""
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://www.perfectstrokeproject.com/stroke/${stroke?.order_number}`,
                );
                copyToClipboard();
              }}
              // onClick={() => navigator.clipboard.writeText(link)}
              // onClick={() => setShowIcons(!showIcons)}
            />
            {showCoppyMessage ? (
              <CoppyLink>Link copied to clipboard</CoppyLink>
            ) : null}
            {showIcons && (
              <WrapperSocialIcons>
                <FacebookShareButton
                  // url={stroke?.photos?.original?.download_url}
                  media="https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/assets%2FSantra.jpg?alt=media&token=4db6e9f9-9517-480b-a5ca-4ddd7fc47299"
                  url="https://www.perfectstrokeproject.com/stroke/131"
                  // url="https://www.youtube.com/watch?v=7Rk2BzeWQQE&list=RD7Rk2BzeWQQE&index=3"
                  quote="Check out this awesome website!"
                  hashtag="#endregionCheck out this awesome website!"
                >
                  <FacebookIcon
                    size={32}
                    media={stroke?.photos?.original?.download_url}
                  />
                </FacebookShareButton>
                <TwitterShareButton
                  hashtag="hashtag"
                  text="Check out this awesome website!"
                  url={stroke?.photos?.original?.download_url}
                >
                  <TwitterIcon size={32} />
                </TwitterShareButton>
                <ViberShareButton
                  hashtag="hashtag"
                  text="Check out this awesome website!"
                  url={stroke?.photos?.original?.download_url}
                >
                  <ViberIcon size={32} />
                </ViberShareButton>
                <WhatsappShareButton
                  hashtag="hashtag"
                  text="Check out this awesome website!"
                  url={stroke?.photos?.original?.download_url}
                >
                  <WhatsappIcon size={32} />
                </WhatsappShareButton>
                <FacebookMessengerShareButton
                  hashtag="hashtag"
                  text="Check out this awesome website!"
                  url={stroke?.photos?.original?.download_url}
                >
                  <FacebookMessengerIcon size={32} />
                </FacebookMessengerShareButton>
              </WrapperSocialIcons>
            )}
          </div>
          <Title>#{stroke?.order_number}</Title>
          <BoxWithText
            iconComponent={<XIcon size={25} />}
            rev={true}
            text=""
            onClick={goHome}
          />
        </TopButtons>
        <ImageWrapper>
          {stroke?.photos?.original?.download_url ? (
            <ImageRow>
              <div style={{ position: 'relative' }}>
                <img
                  className="img-fluid"
                  src={`${stroke?.photos?.original?.download_url}`}
                />
                {stroke?.purchase?.status === 'bought' && (
                  <RedDotWrapper>
                    <RedDot size="50px" color="red" />
                  </RedDotWrapper>
                )}
                {stroke?.purchase?.status === 'reserved' && (
                  <RedDotWrapper>
                    <RedDot size="50px" color="orange" />
                  </RedDotWrapper>
                )}
                <WrapperVS>
                  <SoundImage src="https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/assets%2FSantra.jpg?alt=media&token=4db6e9f9-9517-480b-a5ca-4ddd7fc47299" />
                  <TextVS>
                    Play Santra`s voice Interpretations of Brushtroke #
                    {stroke?.order_number}
                  </TextVS>
                  <ButtonPlay onClick={() => audio.play()} />
                </WrapperVS>
              </div>
            </ImageRow>
          ) : (
            <ImageLoader>
              <WrapperLoader>
                <Loader></Loader>
              </WrapperLoader>
            </ImageLoader>
          )}
        </ImageWrapper>
        <StrokeInfoWrapper>
          <BoxWithText
            iconComponent={<LeftArrow size={25} />}
            rev={false}
            text="previous"
            onClick={goHome}
          />
          {/*
 <TitleRow>
            <BoxWithText
              iconComponent={<RightArrow size={25} />}
              rev={true}
              text="new random"
              onClick={goNext}
            />
          </TitleRow> */}

          <Description>{stroke?.description || <>&nbsp;</>} </Description>
          <Description>{stroke?.name || <>&nbsp;</>} </Description>
        </StrokeInfoWrapper>
        <PictureWrapper>
          <Subtitle>Preview of Original</Subtitle>
          <div style={{ position: 'relative' }}>
            <img
              className="img-fluid"
              style={{ paddingBottom: 2, width: '100%' }}
              src={Frame}
            />
            <SmallPicOS
              style={{
                width: imageWall * 0.145,
                height: 'auto',
                padding: 0,
                backgroundColor: 'red',
                borderTop: `${imageWall * 0.002}px solid #C1C1C1`,
                borderLeft: `${imageWall * 0.002}px solid #C1C1C1`,
                borderRight: `${imageWall * 0.002}px solid #E7E7E7`,
                borderBottom: `${imageWall * 0.002}px solid #E7E7E7`,
              }}
              src={stroke?.photos?.thumb_600x600?.download_url}
            />
          </div>
          <Subtitle>Preview of 140x100cm Print</Subtitle>
          <ImageWallWrapper style={{ position: 'relative' }}>
            <ImageWall
              ref={imageWallRef}
              className="img-fluid"
              style={{ width: '100%' }}
              src={Wall}
            />
            <BigPicOS
              style={{
                width:
                  imageWall * 0.48 + 2 * imageWall * 0.005 + imageWall * 0.02,
                height: 'auto',
                borderTop: `${imageWall * 0.005}px solid #585858`,
                borderLeft: `${imageWall * 0.005}px solid black`,
                borderRight: `${imageWall * 0.005}px solid #585858`,
                borderBottom: `${imageWall * 0.005}px solid black`,
                padding: `${imageWall * 0.02}px`,
              }}
              src={stroke?.photos?.thumb_600x600?.download_url}
            />
          </ImageWallWrapper>
        </PictureWrapper>
        <TableWrapper>
          <div>
            <Row style={tableRow}>
              <IconWrapper>
                <img
                  style={{ height: 150, paddingBottom: 20 }}
                  className="img-fluid"
                  src={tools}
                />
                <div>TOOLS</div>
              </IconWrapper>
            </Row>
            <Row>
              <ItemRow />
            </Row>
            <Row style={tableRow}>
              <IconWrapper>
                <img
                  style={{ height: 150, paddingBottom: 20 }}
                  className="img-fluid"
                  src={paper}
                />
                <div>PAPER</div>
              </IconWrapper>
            </Row>
            <Row>{stroke?.paper && <PaperRow paper={stroke?.paper} />}</Row>
            <Row style={tableRow}>
              <IconWrapper>
                <img
                  style={{ height: 150, paddingBottom: 20 }}
                  className="img-fluid"
                  src={paint}
                />
                <div>PAINTS</div>
              </IconWrapper>
            </Row>
            <Row>
              {stroke?.related_paints.map((value, index) => {
                return <PaintRow key={index} paint={value} />;
              })}
            </Row>
          </div>
        </TableWrapper>
      </Container>
    </PageWrapper>
  );
}

const CoppyLink = styled.p`
  position: absolute;
  right: -30px;
  top: 20px;
  color: #373737;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  transform: translateX(100%);
`;

const ImageWallWrapper = styled.div`
  margin-left: -13%;
  margin-right: -4.5%;
  @media ${styles.device.tablet} {
    margin: 0;
  }
`;

const ImageWall = styled.img``;

const SmallPicOS = styled.img`
  position: absolute;
  top: 30%;
  left: 43%;
`;

const BigPicOS = styled.img`
  position: absolute;
  object-fit: contain;
  background-color: white;
  box-shadow: -9px 9px 20px 0px rgba(0, 0, 0, 0.35);
  top: 35%;
  left: 55%;
  transform: translate(-50%, -50%);
`;

const ImagesWrapper = styled.div`
  margin: 0 10px;
  @media ${styles.device.laptop} {
    margin: 0;
  }
`;

const SoundImage = styled.img`
  height: 50px;
  border-radius: 50%;
  width: 50px;
  object-fit: cover;
  margin-right: 15px;
`;

const TextVS = styled.p`
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  text-align: left;
  width: 170px;
`;

const ButtonPlay = styled.button`
  border: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #373737;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 13px 0 13px 20px;
    border-color: transparent transparent transparent red;
  }
`;

const WrapperSocialIcons = styled.div`
  position: absolute;
  top: 85px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  z-index: 9999;
  & button {
    margin-bottom: 10px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  aspect-ratio: 1.4;
`;

const ImageLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const WrapperVS = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: -15px;
  right: 15px;
  transform: translateY(100%);
  @media ${styles.device.laptop} {
    right: 0px;
  }
`;

const RedDotWrapper = styled.div`
  position: absolute;
  bottom: -15px;
  left: 15px;
  transform: translateY(100%);
  @media ${styles.device.laptop} {
    left: 0px;
  }
`;

const WrapperLoader = styled.div`
  font-size: 50px;
  aspect-ratio: 1.4;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const tableRow = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

const ShareIcon = styled(ShareAlt)`
  color: white;
`;
const XIcon = styled(Close)`
  color: white;
`;

const LeftArrow = styled(ArrowBackOutline)`
  color: white;
`;

const RightArrow = styled(ArrowForwardOutline)`
  color: white;
`;

const PageWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.97);
  z-index: 5;
  position: relative;
  text-align: center;
`;

const TopButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;

  @media ${styles.device.laptop} {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
`;

const ImageRow = styled.div`
  z-index: 10;
  position: relative;

  @media ${styles.device.laptop} {
    padding-left: ${styles.sizing.brandBoxSize}px;
    padding-right: ${styles.sizing.brandBoxSize}px;
    top: 0;
  }
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${styles.space.margin20}px;
  align-items: center;
`;

const StrokeInfoWrapper = styled.div`
  margin-top: ${styles.space.margin100}px;
  margin-bottom: ${styles.space.margin100}px;
`;

const Title = styled.h2`
  ${styles.texts.title};
`;

const TableWrapper = styled.div`
  overflow: hidden;
`;

const IconWrapper = styled.div`
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Subtitle = styled.h6`
  margin-top: ${styles.space.margin100}px;
  margin-bottom: 50px;
  font-size: 30px;
`;

const PictureWrapper = styled.div`
  margin-bottom: 70px;

  @media ${styles.device.laptop} {
    padding-left: 75px;
    padding-right: 75px;
  }
`;

const Description = styled.div``;
