import SquareLoader from 'react-spinners/SquareLoader';
import styled from 'styled-components';

interface Prop {
  size: string;
  color: string;
}

export default function Loader({ size, color }: Prop) {
  return (
    <RedDot style={{ width: size, height: size, backgroundColor: color }} />
  );
}

const RedDot = styled.div`
  border-radius: 50%;
`;
