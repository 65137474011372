import {
  DocumentSnapshot,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from 'firebase/firestore';
import _ from 'lodash';
import { DeltaObject } from '../types/Types';

export const transformLabels = (value: string): string => {
  const splitWord = _.startCase(value);
  const finalLabel = splitWord.replace('Id', ''); // Remove Id from string
  return finalLabel;
};

export const constantsToAutocomplete = (constant) => {
  const listOfOptions = [];
  Object.keys(constant).forEach((key) => {
    if (typeof constant[key] !== 'object') {
      listOfOptions.push({ label: key, value: constant[key] });
    } else {
      listOfOptions.push({
        label: constant[key].label,
        value: constant[key].value,
      });
    }
  });
  return listOfOptions;
};

export const fbDoc2obj = <T,>(
  doc: DocumentSnapshot<T> | QueryDocumentSnapshot<T>,
): T => {
  const item = {
    ...doc.data(),
    id: doc.id,
  } as T;
  return item;
};

export const fbDocs2objs = <T,>(items: QuerySnapshot<T>): T[] => {
  const allConverted = [] as T[];
  items.docs.forEach((item) => {
    const conv = fbDoc2obj(item);
    allConverted.push(conv);
  });
  return allConverted;
};

export const fbRelatedDocs2objs = <T,>(items: QuerySnapshot<T>): T[] => {
  const allConverted = [] as T[];
  items.docs.forEach((item) => {
    const conv = { ...item.data(), relationship_id: item.id };
    allConverted.push(conv);
  });
  return allConverted;
};

export const relatedObjs2objs = <T,>(items: T[]): T[] => {
  const allConverted = [] as T[];
  items.forEach((item) => {
    const conv = { ...item };
    delete conv.relationship_id;
    allConverted.push(conv);
  });
  return allConverted;
};

export const getDeltaOfRelated = (
  oldArray: unknown[],
  newArray: any[],
): DeltaObject => {
  let delta: DeltaObject = {
    add: [],
    update: [],
    delete: [],
  };

  oldArray.forEach((oldEl) => {
    const newEl = newArray.find((x) => x.id == oldEl.id);
    if (!newEl) {
      delta.delete.push(oldEl);
    } else {
      const without_rel = { ...oldEl };
      delete without_rel.relationship_id;
      const same = _.isEqual(without_rel, newEl);
      if (!same) {
        delta.update.push(newEl);
      }
    }
  });
  newArray.forEach((newEl) => {
    const oldEl = oldArray.find((x) => x.id == newEl.id);
    if (!oldEl) {
      delta.add.push(newEl);
    }
  });

  return delta;
};

export const getErrorMessages = (errors) => {
  if (errors) {
    const res = extractMessages(errors);
    return res;
  }
  return null;
};
export const extractMessages = (ob): string[] => {
  let messages: string[] = [];
  for (let i = 0; i < Object.keys(ob).length; i++) {
    const key = Object.keys(ob)[i];

    if (key == 'message') {
      messages.push(ob[key]);
      break;
    } else if (Object.prototype.hasOwnProperty.call(ob[key], 'message')) {
      messages.push(ob[key].message);
      break;
    } else {
      if (Array.isArray(ob[key])) {
        ob[key].forEach((element) => {
          const msgs3 = getErrorMessages(element);
          messages = messages.concat(msgs3);
        });
      } else {
        const msgs2 = getErrorMessages(ob[key]);
        messages = messages.concat(msgs2);
      }
    }
  }

  return messages;
};
