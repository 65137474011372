import React from 'react';
import styled from 'styled-components';
import styles from '../../../common/styles';
import DataItemCouple from './DataItemCouple';

import brush from '../../../static/tools_pic.png';

const ItemRow = () => {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image src={brush} />
      </ImageWrapper>
      <DataWrapper>
        <DataColumn>
          <DataItemCouple label="Brand" info="Escoda" />
          <DataItemCouple label="Model" info="Ultima" />
          <DataItemCouple label="Tool Type" info="Brush" />
        </DataColumn>
        <DataColumn>
          <DataItemCouple label="Shape" info="Round" />
          <DataItemCouple label="Size" info="12" />
          <DataItemCouple label="Hair Type" info="Hog Bristle" />
        </DataColumn>
        <DataColumn>
          <DataItemCouple label="Quality" info="Professional" />
          <DataItemCouple label="Price" info="$30" />
          <DataItemCouple label="Shop" info="Escoda.com" />
        </DataColumn>
      </DataWrapper>
    </Wrapper>
  );
};

export default ItemRow;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media ${styles.device.laptop} { 
    height: 300px;
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
    width: 100%;
    @media ${styles.device.laptop} {
      width: 382px;
  }
`;

const Image = styled.img`
width: 100%;
@media ${styles.device.laptop} {
      width: 382px;
  }
`;

const DataWrapper = styled.div`
  background-color: ${styles.colors.brandGrayLight};
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: left;
  padding-left: 20px;
  width: 100%;

  @media ${styles.device.laptop} {
    flex-direction: row;
    min-height: 240px;
    border-left: 3px ${styles.colors.white} solid;
  }
`;

const DataColumn = styled.div`
  flex-grow: 1;
  padding-left: 20px;


  @media ${styles.device.laptop} {
    border-right: 1px ${styles.colors.lightGray} solid;
  }
`;
