import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Hammer from 'hammerjs';

import * as THREE from 'three';
import { FORBIDDEN } from 'http-status-codes';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
import strokeApi from '../../services/api/strokeApi';
import { BUCKET_URL } from '../../common/constants';
import jsonStrokes from '../../config/strokes.json';
import homeImage from '../../static/splash-stroke.jpg';
import { forEachChild } from 'typescript';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

export default function Image({ canClick }) {
  const navigate = useNavigate();
  const [images, setImages] = useState<number[]>([]);
  const [scene] = useState(new THREE.Scene());
  const [raycaster] = useState(new THREE.Raycaster());
  const [mouse] = useState(new THREE.Vector2());
  const [camera] = useState(
    new THREE.PerspectiveCamera(
      25,
      window.innerWidth / window.innerHeight,
      0.1,
      100000,
    ),
  );

  const firstUpdate = useRef(true);
  let numberOfTouches = useRef(0);
  let invalidTap = useRef(false);
  let clientX = useRef(null);
  let clientY = useRef(null);
  let canvas = useRef(document.getElementById('canvas'));
  let hammer = useRef(null);

  const loader = new THREE.TextureLoader();
  loader.crossOrigin = '';
  // THREE.TextureLoader.prototype.crossOrigin = '';

  // let handleStart = useRef(function handleStart(evt) {
  //   console.log('handleStart', numberOfTouches.current);
  //   var touches = evt.changedTouches;

  //   for (var i = 0; i < touches.length; i++) {
  //     numberOfTouches.current++;
  //     if (i > 0) {
  //       continue;
  //     }
  //     clientX.current = touches[i].clientX;
  //     clientY.current = touches[i].clientY;
  //   }

  //   if (numberOfTouches.current > 1) {
  //     invalidTap.current = true;
  //   }

  // });

  // let moveHandle = useRef(function moveHandle(evt) {
  //   console.log('moveHandle');
  //   if (evt.target.nodeName == 'CANVAS') { evt.preventDefault(); }
  //   invalidTap.current = true;
  // });

  let endHandle = useRef(function endHandle(e) {
    mouse.x = (e.center.x / window.innerWidth) * 2 - 1;
    mouse.y = -(e.center.y / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);

    var intersects = raycaster.intersectObjects(scene.children);

    for (var i = 0; i < intersects.length; i++) {
      // intersects[i].object.material.color.set(0xff0000);
      // history.push(`/strokes/details/${intersects[i].object.orderId}`);

      navigate(`/stroke/${intersects[i].object.orderId}`);
    }

    // reset
    clientX.current = null;
    clientY.current = null;
    invalidTap.current = false;
    numberOfTouches.current = 0;
  });

  let onClickMouse = useRef(function (event) {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);

    // calculate objects intersecting the picking ray
    var intersects = raycaster.intersectObjects(scene.children);

    for (var i = 0; i < intersects.length; i++) {
      intersects[i].object.material.color.set(0xff0000);
      navigate(`/stroke/464`);
    }
  });

  useEffect(() => {
    loadImages();
    hammer.current = new Hammer(document.getElementById('canvas'));
    return function cleanup() {
      // document
      //   .getElementById('canvas')
      //   .removeEventListener('click', onClickMouse);
      // document
      //   .getElementById('canvas')
      //   .removeEventListener('touchstart', handleStart);
      // document
      //   .getElementById('canvas')
      //   .removeEventListener('touchmove', moveHandle);
      // document
      //   .getElementById('canvas')
      //   .removeEventListener('touchend', endHandle);
      // document
      //   .getElementById('canvas')
      //   .removeEventListener('touchcancel', endHandle);
    };
  }, []); //

  useEffect(() => {
    if (canClick) {
      hammer.current.on('tap', endHandle.current);
      hammer.current.on('press', endHandle.current);
      // document
      //   .getElementById('canvas')
      //   .addEventListener('click', onClickMouse.current, false);
      // document
      //   .getElementById('canvas')
      //   .addEventListener('touchstart', handleStart.current, false);
      // document
      //   .getElementById('canvas')
      //   .addEventListener('touchmove', moveHandle.current, false);
      // document
      //   .getElementById('canvas')
      //   .addEventListener('touchend', endHandle.current, false);
      // document
      //   .getElementById('canvas')
      //   .addEventListener('touchcancel', endHandle.current, false);
    } else {
      // document
      //   .getElementById('canvas')
      //   .removeEventListener('click', onClickMouse.current);
      hammer.current.off('tap', endHandle.current);
      hammer.current.off('press', endHandle.current);
      // document
      //   .getElementById('canvas')
      //   .removeEventListener('touchstart', handleStart.current);
      // document
      //   .getElementById('canvas')
      //   .removeEventListener('touchmove', moveHandle.current);
      // document
      //   .getElementById('canvas')
      //   .removeEventListener('touchend', endHandle.current);
      // document
      //   .getElementById('canvas')
      //   .removeEventListener('touchcancel', endHandle.current);
    }
  }, [canClick]);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    start();
  }, [images]);

  const myRef = React.createRef();

  const strokeWidth = 100;
  const strokeHeight = strokeWidth / 1.33;

  const loadImages = async () => {
    const ids = jsonStrokes.map((x) => x.order_number);
    const idsThatHaveImages = ids.filter((x) => x <= 800);
    setImages(idsThatHaveImages);
  };

  const start = async () => {
    THREE.ImageUtils.crossOrigin = '';

    var renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.getElementById('canvas').appendChild(renderer.domElement);
    renderer.setClearColor('#f7f7f7', 1);

    // camera
    camera.position.z = 600;
    camera.position.y = 600;
    camera.position.x = 0;

    var Y_AXIS = new THREE.Vector3(0, 0, 1);

    camera.lookAt(0, 0, 0);

    // scene
    scene.add(camera); //ADDED
    camera.rotateOnAxis(Y_AXIS, 0.9);
    var axesHelper = new THREE.AxesHelper(50);
    // The X axis is red. The Y axis is green. The Z axis is blue.

    axesHelper.size = 4;
    scene.add(axesHelper);

    // plane
    const numberOfCardsBySide = 20;
    const margin = 10;
    const initialX = (-(strokeWidth + margin) * numberOfCardsBySide) / 2;
    const initialZ = (-(strokeHeight + margin) * numberOfCardsBySide) / 2;

    const tempImages = [...images];
    for (let x = 0; x < numberOfCardsBySide; x++) {
      for (let z = 0; z < numberOfCardsBySide; z++) {
        let randomId = Math.floor(Math.random() * tempImages.length);
        const imageOrderNumber = tempImages[randomId];
        tempImages.splice(randomId, 1);
        const strokeObject = jsonStrokes.find(
          (x) => x.order_number === imageOrderNumber,
        );
        let randomImage = strokeObject?.photos?.thumb_600x600?.download_url;
        //  randomImage = `https://natureconservancy-h.assetsadobe.com/is/image/content/dam/tnc/nature/en/photos/WOPA160517_D056-resized.jpg?crop=864%2C0%2C1728%2C2304&wid=600&hei=800&scl=2.88`;
        // let randomImage = `https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F4fae8359-4f3a-45c7-9ddd-2383605027ea%2F600x600%2Fa999c199-0233-4da8-9cf1-94aac635f550_600x600?alt=media&token=b6f6489b-2a4e-4ca6-96cf-b5c68108380d`;
        // let randomImage = `https://storage.googleapis.com/thank-you-project.appspot.com/stroke_photos/00150067-1d83-4fd8-ad1e-cb5bc57ea23d/600x600/12e98ec6-8bb4-4e9a-846d-3de1abdddb4b_600x600`;
        // let randomImage = `https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/stroke_photos%2F72d72822-b06a-4139-8f2e-74ed02c01f1e%2F600x600%2F723dc042-b1e2-419e-86d4-352aedbf8502_600x600?alt=media&token=986ecbd8-eab5-45f2-9397-a8ebcda8cdbf`;

        loader.load(
          // resource URL
          randomImage,

          // onLoad callback
          function (texture) {
            // in this example we create the material when the texture is loaded
            const material = new THREE.MeshBasicMaterial({
              map: texture,
            });
            material.map.needsUpdate = true; //ADDED

            var plane = new THREE.Mesh(
              new THREE.PlaneGeometry(strokeWidth, strokeHeight),
              material,
            );

            // plane.overdraw = true;
            plane.position.z = initialZ + z * (strokeHeight + margin);
            plane.position.x = initialX + x * (strokeWidth + margin);
            plane.rotation.x = -Math.PI / 2;
            plane.orderId = imageOrderNumber;
            scene.add(plane);
          },

          // onProgress callback currently not supported
          undefined,

          // onError callback
          function (err) {
            console.error('X: ', imageOrderNumber);
          },
        );
      }
    }

    // contronls
    var controls = new OrbitControls(camera, renderer.domElement);

    // var controls = new TrackballControls(camera, renderer.domElement);
    // controls.panSpeed = 0.13;
    controls.zoomSpeed = 1.2;
    // controls.noRotate = true;
    controls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN,
      RIGHT: THREE.MOUSE.ROTATE,
    };
    controls.touches.ONE = THREE.TOUCH.PAN;

    // // Clicks
    // let mouseVector = new THREE.Vector3();
    // mouseVector.x = 2 * (e.clientX / window.innerWidth) - 1;
    // mouseVector.y = 1 - 2 * (e.clientY / window.innerHeight);

    // var raycaster = projector.pickingRay(mouseVector.clone(), camera);
    // var intersects = raycaster.intersectObjects(cubes.children);

    // for (var i = 0; i < intersects.length; i++) {
    //   var intersection = intersects[i],
    //     obj = intersection.object;

    //   obj.material.color.setRGB(1.0 - i / intersects.length, 0, 0);
    // }

    // New Clicks

    // Animation
    var animate = function () {
      requestAnimationFrame(animate);
      // plane.rotation.x += 0.01;
      // plane.rotation.y += 0.01;
      controls.update();
      renderer.render(scene, camera);
    };
    animate();
  };

  const downloadNewJson = async () => {
    ///  CODE TO DOWNLOAD JSON FILE
    //////////////////////////////
    var res = await strokeApi.getStrokes();
    if (res.length > 0) {
      const filename = 'updated-strokes.json';
      // Convert the data object to a JSON string
      const jsonString = JSON.stringify(res, null, 2);
      // Create a blob object with the JSON content
      const blob = new Blob([jsonString], { type: 'text/plain' });
      // Create a download link element
      const downloadLink = document.createElement('a');
      downloadLink.download = filename;
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.onclick = function () {
        // Remove the download link after the file has been downloaded
        setTimeout(function () {
          URL.revokeObjectURL(blob);
          downloadLink.removeAttribute('href');
        }, 0);
      };
      // Append the download link to the body
      document.body.appendChild(downloadLink);
      // Simulate a click on the download link
      downloadLink.click();
    }
  };

  const updateDownloadUrl = async () => {
    const strokes = await strokeApi.getStrokes();
    strokes.forEach(async (stroke) => {
      if (!stroke.photos?.thumb_600x600?.reference || !stroke.id) {
      } else {
        const storage = getStorage();
        const pathReference = ref(
          storage,
          stroke?.photos?.thumb_600x600?.reference,
        );
        const url = await getDownloadURL(pathReference);
        await strokeApi.updateStroke(stroke.id, url);
      }
    });
  };

  const updatePurchase = async (id: number) => {
    // get stroke by order_number
    const str = await strokeApi.getStroke(id);
    if (str != null && str.id) {
      const p = {
        anonymous: false,
        date: new Date(),
        status: 'bought',
        internalDescription: 'adrians sister',
        buyer: {
          email: 'unknown@mgpepe.com',
          firstName: 'Albena',
          lastName: 'Apostolova',
        },
      };
      // update stroke
      await strokeApi.updatePurchase(str.id, p);
    }
  };

  useEffect(() => {
    // updateDownloadUrl();
    // downloadNewJson();
    // updatePurchase(7);
  }, []);

  return <div id="canvas" ref={myRef}></div>;
}
