import { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

import styles from '../../../common/styles';
import Footer from '../../../common/components/Footer';
import MyContext from '../../../services/context';
import WhitePad from '~/src/common/components/WhitePad';
import TitleComponent from '~/src/common/components/TitleComponent';
import { Link } from 'react-router-dom';

export default function About() {
  const context = useContext(MyContext);
  context.setShowStrokePlain(false);
  context.setTitle('About');
  return (
    <WhitePad>
      <TitleComponent title="About Perfect Stroke" />
      <p>
        The Perfect Stroke Project (PSP) is a dream. It started as a dream of a
        single person to see more people helping each other. And so this one
        person started creating brushtrokes that symbolized a promise that one
        human will help another even if it is in the slightest way.
      </p>
      <p>
        The collection grew to 1300 but the perfect shape, color and medium
        never came. Yet one thing became obvious - the idea that every stroke
        represents "helping someone" is perfect.
      </p>
      <p>
        And so the Perfect Stroke Project became an initiative that urges people
        to give a little thought about how they can help someone in need. It
        also became a laboratory for exploring and designing most efficient and
        practical means to help.
      </p>
      <p>
        The foundation behind PSP is funded not by donations but by selling the
        art of Petar Petrov. Proceeds are then either redirected to specific
        NGOs or used to bring help from idea to life.
      </p>
      <p>
        The art that made all this possible started from traditional acrylic on
        paper. It evolved pretty quickly to electronics, AI, Installations,
        physics concepts, and other experiments.
      </p>
      <p>
        The simplest way to support the cause would be to buy a brushtroke -
        they range from $1 to $10,000 currently. But help can take many shapes
        and if buying art is not your cup, but you still want to be part of this
        dream, please check out{' '}
        <Link to="/get-involved">how to get involved</Link> page.
      </p>
    </WhitePad>
  );
}

const Wrapper = styled(Container)`
  position: relative;
  z-index: 100;
  background-color: ${styles.colors.white};
  max-width: 1800px;
`;
