import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import { Helmet } from 'react-helmet';
import styles from '../../../common/styles';

import MyContext from '../../../services/context';
import homeImage from '../../../static/splash-stroke.jpg';
import TitleComponent from '~/src/common/components/TitleComponent';
import WhitePad from '~/src/common/components/WhitePad';

interface InstagramType {
  media_url: string;
  id: string;
}

export default function Stroke() {
  const [images, setImages] = useState([]);
  const firstImage = images.slice(0, 4);
  const [windowHeight, setWindowHeight] = useState(0);
  const context = useContext(MyContext);
  context.setShowStrokePlain(false);
  context.setTitle('Home');

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const accessToken =
      'IGQVJYRmkzY2xhcXlwTjhsam53dU5mQUQwLXliWVZA0QUpuWXpkcTk0c2t3OUtvNGJCaW0tRE9hY0tkWWFmQzJLcWxsdTZASRUI1aGY5UXJEa1MwTlhyUFV1OVVjb0JNRE1wQWppRmdfR0d4Nk1obWJDNQZDZD'; // Replace with your actual access token
    fetch(
      `https://graph.instagram.com/me/media?fields=media_url,id&limit=100&access_token=${accessToken}`,
    )
      .then((response) => response.json())
      .then((data) => {
        const onlyStrokes = data.data.filter((image: InstagramType) => {
          return (
            image.id !== '18030913021303096' &&
            image.id !== '17955877594360211' &&
            image.id !== '17872714912983241' &&
            image.id !== '17933531215426140'
          );
        });
        // Get 4 random images:
        const randomImages = onlyStrokes
          .sort(() => 0.5 - Math.random())
          .slice(0, 4);
        setImages(randomImages);
      });
  }, []);

  useEffect(() => {
    return function unmount() {};
  }, []);

  useEffect(() => {}, [images]);

  return (
    <>
      <WhitePad>
        <WrapperImage
          style={{
            height: windowHeight * 0.7,
          }}
        >
          <HomeImage src={homeImage} />
        </WrapperImage>
        {/* <TitleComponent title="The Brushstroke = One Human Helping Another" /> */}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Title style={{ whiteSpace: 'nowrap' }}>The Brushstroke</Title>
          <Title style={{ margin: '0 6px' }}> = </Title>
          <Title style={{ textAlign: 'left' }}>One Human Helping Another</Title>
        </div>

        <br />
        <br />
        <TitleComponent title="Dear Visitor," Heading="h3" />
        <p>
          A stroke is the simplest touch you can do with a brush and normally
          doesn't carry much meaning. These brushstrokes, however, are loaded
          with significance and they all mean a single thing -{' '}
          <b>one human helping another</b>. And so this entire project is all
          about help, human help.
        </p>

        <p>
          For now, browse around and enjoy the{' '}
          <Link to="/paper-collection">collection of 1300 brushstrokes</Link>{' '}
          that change paper, brushes, colors, pigments as well as the{' '}
          <Link to="/experimental-projects">experimental projects</Link>, which
          are probably{' '}
          <b>
            <i>
              <u>things</u>
            </i>
          </b>{' '}
          you haven't seen before.
        </p>

        <p>
          When start wondering, "What is this all about?", then it is time to
          head for the <Link to="/about">about</Link> page where the concept is
          fully explained.
        </p>

        <p>
          If you later choose to be part of it, you can find out how to{' '}
          <Link to="/get-involved">get involved</Link>.
        </p>
      </WhitePad>
      <WrapperGallery>
        {images.map((image) => (
          <ImageGallery
            src={image.media_url}
            key={image.id}
            alt="Instagram Image"
          />
        ))}
      </WrapperGallery>
      <WhitePad>
        <p></p>
      </WhitePad>
    </>
  );
}

const Title = styled.h1``;

const ImageGallery = styled.img`
  height: auto;
  min-width: 50%;

  @media ${styles.device.tablet} {
    height: auto;
    min-width: 25%;
  }
`;

const WrapperGallery = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow: hidden;
`;

const WrapperImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const HomeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
