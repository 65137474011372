import styled from 'styled-components';

const WhitepadComp = styled.div`
  background-color: #fff;
  min-height: 500px;
  padding-top: 50px;
  padding-bottom: 50px;
`;

interface Props {
  children?: React.ReactNode;
  isFluid?: boolean;
}

export default function WhitePad({ children, isFluid }: Props): JSX.Element {
  return (
    <WhitepadComp className={`container${isFluid ? '-fluid' : ''} `}>
      <div className="row">
        <div className="col-sm">{children}</div>
      </div>
    </WhitepadComp>
  );
}
