import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MyContext from '../../../services/context';

export default function PaperCollection() {
  const context = useContext(MyContext);
  context.setShowStrokePlain(true);
  context.setTitle('Paper Collection');
  return (
    <Container>
      <Row>
        <Col></Col>
      </Row>
    </Container>
  );
}
