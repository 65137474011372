import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';

import About from '../scenes/about/screens/About';
import Charity from '../scenes/charity/screens/GetInvolved';
import Experimental from '../scenes/home/screens/Experimental';
import Home from '../scenes/home/screens/Home';
import Subscribe from '../scenes/home/screens/Subscribe';
import PaperCollection from '../scenes/paperColection/screens/PaperCollection';
import Stroke from '../scenes/stroke/screens/Stroke';
// import {  RequireAuth } from './RequireAuth';

const AppRoutes = (): JSX.Element => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/about" element={<About />} />
    <Route path="/get-involved" element={<Charity />} />
    <Route path="/stroke/:id" element={<Stroke />} />
    <Route path="/paper-collection" element={<PaperCollection />} />
    <Route path="/experimental-projects" element={<Experimental />} />
    <Route path="/subscribe" element={<Subscribe />} />
    {/* <Route
                path="/users/create"
                element={
                  <RequireAuth>
                    <UserEdit />
                  </RequireAuth>
                }
              /> */}
  </Routes>
);

export default AppRoutes;
