import React, {useState} from 'react';
import styled from 'styled-components';
import styles from '../styles';
import FilterColors from './FilterColors';
import {Accordion, Card} from 'react-bootstrap';
import { Plus, Minus } from '@styled-icons/feather';

const FilterDropdownSubmenu = (props) => {
  const [isFullHeight, setIsFullHeigth] = useState(false)
  const toggleAccordion = () => {
setIsFullHeigth(!isFullHeight)
  }
  return (
  <Accordion>
  <Card style={headerWrapper}>
    <Accordion.Toggle onClick={toggleAccordion} style={header} as={Card.Header} eventKey="0">
{props.item}
    {isFullHeight ? (   <MinusIcon />): ( <PlusIcon/>)}
   
 
    </Accordion.Toggle>


        <Accordion.Collapse style={collapseWrapper} eventKey="0">
        <div style={cardBodyWrapper}>
        <Card.Body style={cardBody}>{props.item === 'color' ? (<div><FilterColors /></div>):(<div>content</div>)}</Card.Body>
        </div>
      </Accordion.Collapse>

  </Card>
</Accordion>
  );
};



const header = {
color: styles.colors.white,
backgroundColor: styles.colors.brandGrayDark,
// borderBottom: '1px solid #585858',
cursor: 'pointer',
marginLeft: 30,
paddingBottom: 0,
paddingTop: 0,
borderBottom: '1px solid #7a7a7a',
marginBottom: 0,
paddingLeft: 0
}

const cardBody = {
 // padding: '5px 0',
width: 175
}

const cardBodyWrapper = {
 // paddingLeft:  30,
  backgroundColor: styles.colors.mineShaft,
}

const headerWrapper = {
    backgroundColor: styles.colors.brandGrayDark,
    border: 'none'
}

const collapseWrapper = {
    color: styles.colors.white,
    backgroundColor: styles.colors.brandGrayDark,
}

const PlusIcon = styled(Plus)`
width: 15px;
height: 15px;
float: right;
color: #7a7a7a;
position: relative;
top: 5px;
`;

const MinusIcon = styled(Minus)`
width: 15px;
height: 15px;
float: right;
color: #7a7a7a;
top: 5px;
`;

export default FilterDropdownSubmenu;
