import React, { useEffect, useState } from 'react';
// import Container from

import Container from 'react-bootstrap/Container';
import Filter from './Filter';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavDropdown from 'react-bootstrap/NavDropdown';
import styled from 'styled-components';

import MyContext from '../../services/context';
import logo from '../../static/logo-white.svg';

const Navigation = () => {
  const [expanded, setExpanded] = useState(false);
  const [activeLink, setActiveLink] = useState('home');

  return (
    <Navbar
      expanded={expanded}
      onToggle={(isExpanded) => setExpanded(isExpanded)}
      bg="dark"
      variant="dark"
      expand="lg"
      style={{ zIndex: 99999 }}
    >
      <Container>
        <Nav.Link
          className="d-lg-none"
          as={Link}
          href="/"
          to="/"
          onClick={() => {
            setExpanded(false);
            setActiveLink('home');
          }}
        >
          <Logo src={logo} />
        </Nav.Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="m-auto justify-content-between"
            style={{ width: '100%' }}
          >
            <Nav.Link
              active={activeLink === 'home'}
              className="d-none d-lg-block"
              as={Link}
              href="/"
              to="/"
              onClick={() => {
                setExpanded(false);
                setActiveLink('home');
              }}
            >
              <Logo src={logo} />
            </Nav.Link>
            <Nav.Item className="d-flex flex-column flex-lg-row">
              <Nav.Link
                active={activeLink === 'paper-collection'}
                className="d-flex align-items-center"
                as={Link}
                href="/paper-collection"
                to="/paper-collection"
                onClick={() => {
                  setExpanded(false);
                  setActiveLink('paper-collection');
                }}
              >
                Paper Collection
              </Nav.Link>
              <Nav.Link
                active={activeLink === 'experimental'}
                className="d-flex align-items-center"
                as={Link}
                href="/experimental-projects"
                to="/experimental-projects"
                onClick={() => {
                  setExpanded(false);
                  setActiveLink('experimental');
                }}
              >
                Experimental Projects
              </Nav.Link>
            </Nav.Item>
            <hr style={{ height: 3, color: '#fff' }} />
            <Nav.Item className="d-flex flex-column flex-lg-row">
              <Nav.Link
                active={activeLink === 'about'}
                className="d-flex align-items-center"
                as={Link}
                href="/about"
                to="/about"
                onClick={() => {
                  setExpanded(false);
                  setActiveLink('about');
                }}
              >
                About
              </Nav.Link>

              <Nav.Link
                active={activeLink === 'involved'}
                className="d-flex align-items-center"
                as={Link}
                href="/get-involved"
                to="/get-involved"
                onClick={() => {
                  setExpanded(false);
                  setActiveLink('involved');
                }}
              >
                Get Involved
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const Logo = styled.img`
  height: 40px;
`;

export default Navigation;
