import React from 'react';
// import Container from
import styled from 'styled-components';

import styles from '../styles';

const BrandBox = ({ children, onClick }) => {
  return <Box onClick={onClick}>{children}</Box>;
};

const Box = styled.button`
  background-color: ${styles.colors.brandGrayDark};
  width: ${styles.sizing.brandBoxSize}px;
  height: ${styles.sizing.brandBoxSize}px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 0;
`;
export default BrandBox;
