import React, { useState } from 'react';
import styled from 'styled-components';
import FilterDropdown from './FilterDropdown';
import styles from '../styles';
import { Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

import { FilterList } from '@styled-icons/material-sharp';

export default function Filter() {
  const toolsItems = ['brand', 'model', 'brush type', 'shape', 'price', 'shop'];
  const paintItems = ['color', 'model', 'brush type', 'shape', 'price', 'shop'];
  const paperItems = ['brand', 'model', 'brush type', 'shape', 'price', 'shop'];
  const [isFullHeight, setIsFullHeigth] = useState(false);
  const toggleAccordion = () => {
    setIsFullHeigth(!isFullHeight);
  };

  return (
    <Wrapper>
      {/* <Accordion>
        <Card style={isFullHeight ? headerWrapperWithHeight : headerWrapper}>
          <Accordion.Toggle
            onClick={toggleAccordion}
            style={isFullHeight ? toggledHeader : header}
            as={Card.Header}
            eventKey="0"
          >
            <FilterIcon />
            FILTER
          </Accordion.Toggle>
          <div style={{ overflowY: 'scroll' }}>
            <Accordion.Collapse style={collapseWrapper} eventKey="0">
              <Card.Body style={cardBody}>
                <FilterDropdown
                  isFullHeight={isFullHeight}
                  dropdownHeader="TOOLS"
                  dropdownItems={toolsItems}
                />
              </Card.Body>
            </Accordion.Collapse>
            <Accordion.Collapse style={collapseWrapper} eventKey="0">
              <Card.Body style={cardBody}>
                <FilterDropdown
                  isFullHeight={isFullHeight}
                  dropdownHeader="PAINT"
                  dropdownItems={paintItems}
                />
              </Card.Body>
            </Accordion.Collapse>
            <Accordion.Collapse style={collapseWrapper} eventKey="0">
              <Card.Body style={cardBody}>
                <FilterDropdown
                  isFullHeight={isFullHeight}
                  dropdownHeader="PAPER"
                  dropdownItems={paperItems}
                />
              </Card.Body>
            </Accordion.Collapse>
          </div>
        </Card>
      </Accordion> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  float: right;
`;

const header = {
  color: styles.colors.white,
  paddingLeft: 50,
  paddingRight: 50,
  paddingTop: 14.5,
  paddingBottom: 14.5,
  marginBottom: 0,
  fontWeight: 'bold',
  cursor: 'pointer',
};

const toggledHeader = {
  color: styles.colors.white,
  paddingLeft: 50,
  paddingRight: 50,
  paddingTop: 13.5,
  paddingBottom: 13.5,
  marginBottom: 0,
  fontWeight: 'bold',
  borderBottom: '1px solid gray',
  cursor: 'pointer',
};

const FilterIcon = styled(FilterList)`
  position: relative;
  top: -1px;
  height: 23px;
  margin-right: 10px;
`;

const headerWrapper = {
  backgroundColor: styles.colors.brandGrayDark,
};

const headerWrapperWithHeight = {
  backgroundColor: styles.colors.brandGrayDark,
  height: '100vh',
  overflowY: 'scroll',
};

const collapseWrapper = {
  color: styles.colors.white,
};

const cardBody = {
  padding: 0,
};
