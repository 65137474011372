import { API_URL } from '../../common/constants';
import {
  collection,
  documentId,
  getDocs,
  query,
  where,
  QuerySnapshot,
  updateDoc,
  doc,
} from 'firebase/firestore';
import { Stroke } from '~/src/types/Types';
import { fbDocs2objs } from '~/src/utils/Misc';
import { db } from '../../config/firebase';

export default {
  getStroke: async (id: number) => {
    const q = query(collection(db, 'strokes'), where('order_number', '==', id));
    const pigmSS = (await getDocs(q)) as QuerySnapshot<Stroke>;
    const strokesL = fbDocs2objs(pigmSS);
    if (strokesL.length > 0) {
      const ourStroke = strokesL[0];
      const q = query(collection(db, `strokes/${ourStroke.id}/related_paints`));
      const paintSS = (await getDocs(q)) as QuerySnapshot<Paint>;
      const paints = fbDocs2objs(paintSS);
      ourStroke.related_paints = paints;
      return ourStroke;
    }
    return null;
  },
  getStrokes: async () => {
    const q = query(collection(db, 'strokes'));
    const strokesSS = (await getDocs(q)) as QuerySnapshot<Stroke>;
    const strokesL = fbDocs2objs(strokesSS);
    return strokesL;
  },
  updateStroke: async (strokeId: string, downloadUrl: string) => {
    const strokeRef = doc(db, 'strokes', strokeId);
    await updateDoc(strokeRef, {
      'photos.thumb_600x600.download_url': downloadUrl,
    });
  },
  updatePurchase: async (strokeId: string, purchase) => {
    const strokeRef = doc(db, 'strokes', strokeId);
    await updateDoc(strokeRef, {
      purchase: purchase,
    });
  },
};
