import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';

import WhitePad from '~/src/common/components/WhitePad';
import MyContext from '../../../services/context';
import TitleComponent from '~/src/common/components/TitleComponent';
import RedDot from '~/src/common/components/RedDot';
import BoxWithText from '~/src/common/components/BoxWithText';
import {
  ArrowBackOutline,
  ArrowForwardOutline,
} from '@styled-icons/evaicons-outline';
import styles from '~/src/common/styles';

const videoMp4 = 'https://www.w3schools.com/html/mov_bbb.mp4';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <NextArrow
      className={className}
      style={{
        ...style,
        background: '#373737',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={onClick}
    >
      <BoxWithText
        style={{ zIndex: 99999 }}
        iconComponent={<RightArrow size={25} />}
        rev={false}
        onClick={onClick}
      />
    </NextArrow>
  );
}

const RightArrow = styled(ArrowForwardOutline)`
  color: white;
  z-index: 99999;
  right: -50px;
`;

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <PrevArrow
      className={className}
      style={{
        ...style,
        background: '#373737',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        left: '-50px',
      }}
      onClick={onClick}
    >
      <BoxWithText
        style={{ zIndex: 99999 }}
        className={className}
        iconComponent={<LeftArrow size={25} />}
        rev={false}
        onClick={onClick}
      />
    </PrevArrow>
  );
}

const LeftArrow = styled(ArrowBackOutline)`
  color: white;
  z-index: 99999;
  left: -50px;
`;
const PrevArrow = styled.div`
  &.slick-prev:before {
    font-size: 0px !important;
  }
  $.slick-prev {
    left: -40px !important;
  }
`;

const NextArrow = styled.div`
  &.slick-next:before {
    font-size: 0px !important;
  }
  &.slick-next {
    right: -50px !important;
  }
`;

export default function Experimental() {
  const context = useContext(MyContext);
  context.setShowStrokePlain(false);
  context.setTitle('Experimental');
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState('100%');

  useEffect(() => {
    if (window.innerWidth < 800) {
      setWidth('100%');
    } else {
      setWidth(`calc(100% - 150px)`);
    }
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  return (
    <WhitePad>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <WrapperStatus>
          <RedDot size="35px" color="orange" />
        </WrapperStatus>
        <TitleComponent title="Carpet" />
      </div>
      <p>
        Hand-crafted wool carpet with long black bristles. Struggling 3d printed
        miniatures become increasingly happier Thanks to the big black help
      </p>
      <WrapperSlider>
        <Slider style={{ width: '100%', margin: 'auto' }} {...settings}>
          <div>
            <Img100 src="https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/assets%2Fexperimental-projects%2FIMG_20210212_155150.jpg?alt=media&token=21b30ae1-d995-4811-b70b-1fe02057ce3c" />
          </div>
          <div>
            <Img100 src="https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/assets%2Fexperimental-projects%2FIMG_20210212_155142.jpg?alt=media&token=469b94f6-8a7f-4d9a-b259-36abeaf2fe54" />
          </div>
          <div>
            <Img100 src="https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/assets%2Fexperimental-projects%2F3%20figures.jpg?alt=media&token=c3f224c7-80f5-4cf6-886a-6312b1d55b1a" />
          </div>
          <div>
            <Img100 src="https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/assets%2Fexperimental-projects%2F1%20figure.jpg?alt=media&token=1f508eac-fbf4-4a3c-a337-0056842b1dd8" />
          </div>
          <div>
            <Img100 src="https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/assets%2Fexperimental-projects%2FIMG_20201213_215804.jpg?alt=media&token=557ff656-c5bd-41eb-8530-8a2cdba583fc" />
          </div>
        </Slider>
      </WrapperSlider>
      <br />
      <br />
      <br />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <WrapperStatus>
          <RedDot size="35px" color="red" />
        </WrapperStatus>
        <TitleComponent title="Digital Brushstrokes" />
      </div>
      <p>Digital e-paper painting, that fetches another stroke every 30s</p>
      <Img100 src="https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/assets%2Fexperimental-projects%2FDigital-Epaper.jpg?alt=media&token=f4bf4c6d-d811-408e-8d71-4f807a0c7a80" />
      <br />
      <br />
      <br />
      <TitleComponent title="Lego Stroke" />
      <p>
        Lego Stroke - genuine lego parts make a stroke of help with a touch of
        engineering gone bad...or genius.
      </p>
      <Img100 src="https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/assets%2Fexperimental-projects%2FIMG_20210123_154027.jpg?alt=media&token=ec143f0d-4f52-4a6e-a790-fd1a3561f1c5" />
      <br />
      <br />
      <br />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <WrapperStatus>
          <RedDot size="35px" color="red" />
        </WrapperStatus>
        <TitleComponent title="Wooden Stroke" />
      </div>
      <p>
        Hand-crafted wooden stroke, in raisin, with mate back, glossy front.
      </p>
      <Img100 src="https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/assets%2Fexperimental-projects%2FIMG_20201230_165356.jpg?alt=media&token=4e69f607-41b5-4f34-9658-34fe1f5ca684" />
      <br />
      <br />
      <br />
      <TitleComponent title="Levitating Stroke" />
      <p>Prototype stage</p>
      <p>
        Levitating Stroke super black paint. It floats in the Space and doesn’t
        look it has a shape due to lack of reflections of the paint
      </p>

      <WrapperSlider>
        <Slider style={{ width: '100%', margin: 'auto' }} {...settings}>
          <div>
            <Img100 src="https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/assets%2Fexperimental-projects%2Flevitating-gif.gif?alt=media&token=ff4cffcb-df7f-4740-a4b0-5fdd01c207ee" />
          </div>
          <div>
            <Img100 src="https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/assets%2Fexperimental-projects%2FIMG_20220925_223958.jpg?alt=media&token=166efd01-0724-4df5-8c73-df3c2b29e132" />
          </div>
        </Slider>
      </WrapperSlider>
      <br />
      <br />
      <br />
      <TitleComponent title="Worlds together" />
      <p>
        Collaboration with a Brazilian artist @higuchi where I draw a world, and
        give him to finish, and he draws another world of his and gives me to
        finish it.
      </p>
      <Img100 src="https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/assets%2Fexperimental-projects%2FIMG_20201206_142358.jpg?alt=media&token=705db836-bd89-40bb-86ce-558024f409ba" />
      <br />
      <br />
      <br />
      <TitleComponent title="Easter Eggs" />
      <p>Easter Egg, if you catch my drift</p>
      <Img100 src="https://firebasestorage.googleapis.com/v0/b/thank-you-project.appspot.com/o/assets%2Fexperimental-projects%2FIMG_20200419_100905.jpg?alt=media&token=d226aa86-30d4-43d4-b1fc-ff1ac54386cc" />
    </WhitePad>
  );
}

const WrapperSlider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const WrapperStatus = styled.div`
  margin-right: 15px;
`;

const Img100 = styled.img`
  width: 100%;
`;
