import React from 'react';
// import Container from
import styled from 'styled-components';

import styles from '../styles';
import BrandBox from './BrandBox';

const BoxWithText = ({ iconComponent, text, rev, onClick }) => {
  return (
    <Wrapper reverse={rev}>
      <BrandBox onClick={onClick}>{iconComponent}</BrandBox>
      {text && <TextDiv>{text}</TextDiv>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 99999;
`;

const TextDiv = styled.div`
  padding: 25px;
  height: ${styles.sizing.brandBoxSize} px;
  display: none;

  @media ${styles.device.laptop} {
    display: block;
  }
`;
export default BoxWithText;
