import React from 'react';
import styled from 'styled-components';
import styles from '../../../common/styles';

interface Props {
  label: string;
  info: string | undefined | null;
  hexCode?: string;
}

const DataItemCouple = ({ label, info, hexCode }: Props) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      {label === 'Color' && <Color hexCode={hexCode} />}

      <Info>{info}</Info>
    </Wrapper>
  );
};

export default DataItemCouple;

const Wrapper = styled.div`
  @media ${styles.device.laptop} {
    padding-bottom: 20px;
  }
`;

interface ColorProps {
  hexCode: string;
}

const Color = styled.span<ColorProps>`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props?.hexCode || '#FFFFFF'};
  display: inline-block;
  margin-right: 10px;
  border: 1px ${styles.colors.black} solid;
`;

const Info = styled.span.attrs({ style: styles.texts.regular })``;
const Label = styled.div.attrs({ style: styles.texts.label })``;
